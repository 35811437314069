import { UUID, WorkflowStep } from './core'

export enum NodeType {
    Source = 'SourceNode',
    Transfer = 'TransferNode',
    Condition = 'ConditionNode',
    Target = 'TargetNode',
}

export enum EdgeType {
    // DEFAULT
    DefaultEdge = 'DefaultEdge',

    // IF
    ThenEdge = 'ThenEdge',
    ElseEdge = 'ElseEdge',
}

export enum HandleType {
    Source = 'source',
    Then = 'then',
    Else = 'else',
    Target = 'target',
    Default = 'default',
}

export enum StatusType {
    Info = 'info',
    Warning = 'warning',
    Success = 'success',
    Error = 'error',
}

export interface Connection {
    source: UUID | undefined
    target: UUID | undefined
    sourceHandle: HandleType | undefined
    targetHandle: HandleType | undefined
}

export interface AdaptSelectDataOption {
    value?: string
    label?: string
    key?: string
    displayValue?: string
}

export interface NodeData {
    step: WorkflowStep
    readonly: boolean
}
