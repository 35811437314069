import React from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { Divider, Text } from '@chakra-ui/layout'
import { AccordionsHeader } from 'components/Accordion/AccordionsHeader'
import AccordionItemsShippingInfo from 'pages/OrderDetailsPage/content/AccordionItemsShippingInfo'
import { accordionItemsShippingOptions } from 'pages/OrderDetailsPage/mock'
import { orderStore } from 'stores'
import { FilterIcon } from 'icons'
import {
    AccordionStyled,
    FiltersBox,
    ItemsShippingInfoBox,
    ShowFiltersButton,
    ShowFiltersButtonText,
    TitleBox,
} from './styles'

const ItemsShippingInfo = observer(() => {
    const { orderSourceExternalOrderId, number, orderSourceId } =
        orderStore.orderInfo
    const itemsShippingOrder = toJS(orderStore.itemsShippingOrder)

    const originatingSource =
        orderStore.externalSource.find(source => source.id === orderSourceId)
            ?.name || ''

    return (
        <ItemsShippingInfoBox>
            <TitleBox>
                <Text variant="h2">
                    Master Order {orderSourceExternalOrderId} (ICEP Order
                    Number)
                </Text>
                <Text variant="h4">
                    Originating Source: {originatingSource}
                </Text>
            </TitleBox>
            <Divider margin="8px 0" />
            <FiltersBox>
                <Text variant="h3" fontSize="20px">
                    DOM Order {number}
                </Text>
                <ShowFiltersButton variant="outlineWhiteBlack">
                    <FilterIcon boxSize="10px" />
                    <ShowFiltersButtonText>Show Filters</ShowFiltersButtonText>
                </ShowFiltersButton>
            </FiltersBox>
            <AccordionsHeader options={accordionItemsShippingOptions} />
            <AccordionStyled>
                {itemsShippingOrder.map((order, index) => {
                    return (
                        <AccordionItemsShippingInfo
                            key={index}
                            order={order}
                        />
                    )
                })}
            </AccordionStyled>
        </ItemsShippingInfoBox>
    )
})

export default ItemsShippingInfo
