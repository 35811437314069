import React, { ChangeEvent, Dispatch, FC, useEffect, useState } from 'react'
import { format } from 'date-fns'
import {
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    ModalCloseButton,
} from '@chakra-ui/react'
import { CustomInput } from 'components/baseComponents/Input'
import { ISavedOrderFilters } from 'types/api'
import { notificationStore } from 'stores'
import { dateFormat } from '../mock'
import {
    CloseButton,
    ConfirmButton,
    ModalHeaderText,
    ModalBodyStyled,
    ModalInputText,
} from './styles'

interface IConfirmModal {
    isOpen: boolean
    setOpenModal: Dispatch<boolean>
}

export const SaveFiltersModal: FC<IConfirmModal> = ({
    isOpen,
    setOpenModal,
}) => {
    const [value, setValue] = useState('')
    const [isInvalid, setIsInvalid] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setValue(event.target.value)
    }

    const handleClose = (): void => {
        setOpenModal(false)
        setValue('')
    }

    const handleSave = (): void => {
        if (!value) {
            setIsInvalid(true)
            setErrorMessage('Please enter a name')
            return
        }

        const getOrderFilters = localStorage.getItem('orderFilters') || '{}'
        const getSavedOrderFilters = localStorage.getItem('savedOrderFilters') || '[]'

        try {
            const orderFilters = JSON.parse(getOrderFilters)
            const savedOrderFilters = JSON.parse(getSavedOrderFilters)
            const createdDate = format(new Date(), dateFormat)
            const findFilter = savedOrderFilters.find(({ name }: ISavedOrderFilters) => name === value)

            if (findFilter) {
                setIsInvalid(true)
                setErrorMessage('A filter with this name already exists')
                return
            }

            const savedFilters = [
                ...savedOrderFilters,
                {
                    ...orderFilters,
                    createdDate,
                    name: value,
                }
            ]

            setIsInvalid(false)
            setErrorMessage('')
            setValue('')

            localStorage.setItem('savedOrderFilters', JSON.stringify(savedFilters))
            handleClose()
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    useEffect(() => {
        if (value.length > 200) {
            setIsInvalid(true)
            setErrorMessage('Max name length is 200 characters')
        } else {
            setIsInvalid(false)
            setErrorMessage('')
        }
    }, [value])

    return (
        <Modal isOpen={isOpen} onClose={handleClose}>
            <ModalOverlay />
            <ModalContent alignSelf="center" backgroundColor="blueWhite.900">
                <ModalHeader>
                    <ModalHeaderText>Save Filters Configuration</ModalHeaderText>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBodyStyled alignSelf="center" textAlign="center">
                    <ModalInputText>Filters Configuration Name</ModalInputText>
                    <CustomInput
                        errorMessage={errorMessage}
                        border={isInvalid ? '2px solid' : '1px solid'}
                        borderColor={isInvalid ? 'red.500' : 'color.850'}
                        value={value}
                        onChange={handleChange}
                        variant="modal"
                        placeholder="The name of the saved filter"
                    />
                </ModalBodyStyled>
                <ModalFooter marginTop="10px" justifyContent="right">
                    <CloseButton onClick={handleClose}>Cancel</CloseButton>
                    <ConfirmButton onClick={handleSave} isDisabled={isInvalid}>Save</ConfirmButton>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
