import React, { FC } from 'react'
import { useRangePickerContext } from 'components/DateRangePicker/context'
import { dateRangeStore } from 'components/DateRangePicker/store'
import { CenterClickedCellStyled } from '../../../styles'

interface ClickedCellLayoutProps {
    date: Date
    selectDate: (date: Date) => void
}

export const ClickedCellLayout: FC<ClickedCellLayoutProps> = ({
    children,
    date,
    selectDate,
}) => {
    const { onChangeRange } = useRangePickerContext()

    return (
        <CenterClickedCellStyled
            onClick={() => {
                dateRangeStore.selectDate(date)
                onChangeRange(dateRangeStore.getRangeResult())
                selectDate(date)
            }}
        >
            {children}
        </CenterClickedCellStyled>
    )
}
