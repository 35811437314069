import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const DownArrowIcon: FC<IconProps> = props => {
    return (
        <Icon
            width="4"
            height="11"
            viewBox="0 0 4 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.49585 0.847037C6.49585 0.379232 5.98748 -2.04491e-08 5.36038 0C4.73328 2.04454e-08 4.22491 0.379232 4.22491 0.847037L4.22491 7.27187L2.85194 6.24767C2.40852 5.91688 1.68957 5.91688 1.24615 6.24767C0.802717 6.57845 0.802717 7.11477 1.24615 7.44556L4.53216 9.89686C4.73932 10.0616 5.03374 10.1645 5.36038 10.1645C5.56582 10.1645 5.75852 10.1237 5.92478 10.0526C6.01026 10.016 6.09073 9.97055 6.16373 9.91609L9.47552 7.44556C9.91895 7.11477 9.91895 6.57845 9.47552 6.24767C9.03209 5.91688 8.31316 5.91688 7.86973 6.24767L6.49585 7.27255L6.49585 0.847037Z"
                fill="currentColor"
            />
        </Icon>
    )
}
