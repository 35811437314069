import { makeAutoObservable } from 'mobx'
import { PagingFilters, UserRole } from 'types/api'
import { UsersApi } from 'api/users'
import { ICurrentRoles } from 'api/models'

interface User {
    id: string
    email: string
    firstName: string
    lastName: string
    roles: string
    currentPass: string
    newPass: string
    repeatPass: string
}

const defaultFilters: PagingFilters = {
    page: 1,
    size: 10,
}

const defaultRoles = {
    isTenantAdmin: false,
    isApprover: false,
    isSupport: false,
    isCreator: false,
    isSuperAdmin: false,
}

export class UserStore {
    public auth: boolean = false
    public name: string = ''
    public roles: UserRole[] = []
    public currentRoles: ICurrentRoles = defaultRoles
    public usersList: User[] = []
    public filters: PagingFilters = defaultFilters
    public pages: number = 0

    constructor() {
        makeAutoObservable(this)
    }

    public setUser(name: string, roles: UserRole[]) {
        this.auth = true
        this.name = name
        this.roles = roles
        this.currentRoles = this.setCurrentRoles(roles)
    }

    public clearUser() {
        this.auth = false
        this.name = ''
        this.roles = []
        this.currentRoles = defaultRoles
    }

    public async getUsers(): Promise<User[]> {
        const { items: users = {}, totalPages } = await UsersApi.GetUsers(
            this.filters
        )

        this.init(users)
        this.setUpPages(totalPages)
        return users
    }

    public setCurrentRoles = (roles: UserRole[]) => {
        const isApprover = roles.includes(UserRole.RulesApprover)
        const isSupport = roles.includes(UserRole.Support)
        const isCreator = roles.includes(UserRole.RulesCreator)
        const isTenantAdmin = roles.includes(UserRole.TenantAdmin)
        const isSuperAdmin = roles.includes(UserRole.SuperAdmin)
        return {
            isApprover,
            isSupport,
            isCreator,
            isTenantAdmin,
            isSuperAdmin,
        }
    }

    private setUpPages(pages: number) {
        this.pages = pages
    }

    public setFilters(filters: Partial<PagingFilters>) {
        if (!filters.page) {
            this.filters = { ...this.filters, ...filters, page: 1 }
        } else {
            this.filters = { ...this.filters, ...filters }
        }
        this.getUsers()
    }

    private init(UsersList: User[]) {
        this.usersList = UsersList
    }
}
