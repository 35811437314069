import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Text } from '@chakra-ui/layout'
import { observer } from 'mobx-react-lite'
import { Center, Divider, IconButton } from '@chakra-ui/react'
import { toJS } from 'mobx'
import { ConfirmModal } from 'components/Modal/ConfirmModal'
import { rulesSetsStore, userStore } from 'stores'
import { routes } from 'navigation/routes'
import { RectangleIcon } from '../../utils'
import { DeleteTrashIcon, EditPencilIcon, ViewIcon } from 'icons'
import { ItemBox, RectangleIconBox, RulesSetsBox } from './styles'

interface IRulesSetsItem {
    id: string
    name: string
}

export const RulesSetsItem: FC<IRulesSetsItem> = observer(({ id, name }) => {
    const [isOpen, setIsOpen] = useState(false)
    const navigate = useNavigate()

    const { isCreator } = toJS(userStore.currentRoles)

    const getOrderSource = rulesSetsStore.findOrderSourceByWowId(id)

    const onEdit = (): void => navigate(`${routes.editRuleSet}/${id}`)

    const onDelete = async (): Promise<void> =>
        await rulesSetsStore.delete(id, getOrderSource)

    const onOpen = (): void => setIsOpen(true)

    const onClose = (): void => setIsOpen(false)

    return (
        <RulesSetsBox>
            <RectangleIconBox>
                <RectangleIcon iconType={getOrderSource ? 'green' : 'gray'} />
            </RectangleIconBox>
            <ItemBox flexGrow={1}>
                <Text variant="h6">{name}</Text>
            </ItemBox>
            <ItemBox flexGrow={2}>
                <Text variant="h6">
                    {getOrderSource ? getOrderSource.name : '-'}
                </Text>
            </ItemBox>
            <ItemBox display="flex" justifyContent="end" flexGrow={1}>
                <IconButton
                    aria-label="Show Navigation Icon"
                    variant="squircleWhiteBlueIcon"
                    disabled={!isCreator}
                    onClick={onOpen}
                    icon={<DeleteTrashIcon boxSize="30px" color="color.100" />}
                />
                <Center height="20px" margin="0 10px" alignSelf="center">
                    <Divider orientation="vertical" />
                </Center>
                {isCreator ? (
                    <IconButton
                        aria-label="Show Navigation Icon"
                        variant="squircleWhiteBlueIcon"
                        onClick={onEdit}
                        icon={
                            <EditPencilIcon boxSize="30px" color="color.100" />
                        }
                    />
                ) : (
                    <IconButton
                        aria-label="Show Navigation Icon"
                        variant="squircleWhiteBlueIcon"
                        onClick={onEdit}
                        icon={<ViewIcon boxSize="30px" color="color.100" />}
                    />
                )}
            </ItemBox>
            <ConfirmModal
                isOpen={isOpen}
                onClose={onClose}
                confirmFunc={onDelete}
                headerText="Delete Rule Set"
                bodyText={`Do you really want to delete Rule Set "${name}"`}
                buttonText="Yes"
                colorButton="red.500"
            />
        </RulesSetsBox>
    )
})
