import { colors, semanticTokens } from 'theme/colors'
import { Theme } from 'types/core'

export const getThemeColor = (theme: string, themeColor: string): string => {
    const { colors: semanticTokensColor } = semanticTokens
    const colorKey = semanticTokensColor[themeColor]
    const themeModeColor = theme === Theme.Dark ? colorKey._dark : colorKey.default
    const [colorName, colorCode] = themeModeColor.split('.')

    return colorCode ? colors[colorName][+colorCode] as string : colors[colorName] as string
}
