import React, { FC } from 'react'
import { AccordionHeaderBox, AccordionHeaderItem } from 'pages/OrderDetailsPage/styles'

export const AccordionsHeader: FC = () => {
    return (
        <AccordionHeaderBox>
            <AccordionHeaderItem flexGrow={2} />
            <AccordionHeaderItem flexGrow={1}>Date</AccordionHeaderItem>
            <AccordionHeaderItem flexGrow={1}>Time</AccordionHeaderItem>
            <AccordionHeaderItem flexGrow={5}>Status</AccordionHeaderItem>
            <AccordionHeaderItem flexGrow={0.3} />
        </AccordionHeaderBox>
    )
}
