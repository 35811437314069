import React, { FC } from 'react'
import { Box } from '@chakra-ui/layout'
import { Modal, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import { ModalCloseButton, ModalHeaderText, ValueText, ModalBodyStyled } from '../../styles'

interface StepInfoModalProps {
    isOpen: boolean
    onClose: () => void
    selectedValues: string[]
}

export const StepInfoModal: FC<StepInfoModalProps> = ({ isOpen, onClose, selectedValues }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent alignSelf="center" backgroundColor="gray.710">
                <ModalHeader>
                    <ModalHeaderText>Selected Values</ModalHeaderText>
                </ModalHeader>
                <ModalBodyStyled>
                    <Box
                        maxHeight="165px"
                        overflow="auto"
                        sx={{
                            '&::-webkit-scrollbar': {
                                width: '8px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: 'gray.500',
                                borderRadius: '10px',
                                minHeight: '62px',
                            }
                        }}
                    >
                        {selectedValues.map((value) => (
                            <ValueText key={value}>{value}</ValueText>
                        ))}
                    </Box>
                </ModalBodyStyled>
                <ModalFooter justifyContent="center">
                    <ModalCloseButton onClick={onClose}>Close</ModalCloseButton>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
