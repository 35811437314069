import { WorkflowIdsValueDto } from 'types/api'
import { UUID } from 'types/core'
import { wowsInstance } from './instances'

export class WowsAPI {
    static async getAll(page: number = 1, size: number = 10, name?: string) {
        const filter = name ? `&filter=${name}` : ''
        const { data = {} } = await wowsInstance.get(
            `?page=${page}&size=${size}${filter}`
        )
        return data
    }

    static async addNew(name: string, description?: string) {
        const { data = {} } = await wowsInstance.post(``, {
            name,
            description,
        })

        return data
    }

    static async simulate(orderId: string, workflowIds: string[]) {
        const { data = {} } = await wowsInstance.post(
            `$simulate`,
            {
                orderId,
                workflowIds,
            }
        )
        return data
    }


    static async deleteById(wowId: UUID) {
        return await wowsInstance.delete(`/${wowId}`)
    }

    static async getById(wowId: UUID) {
        const { data = {} } = await wowsInstance.get(`/${wowId}`)
        return data
    }

    static async putWorkflowsById(workflows: UUID[], wowId: UUID) {
        return await wowsInstance.put<WorkflowIdsValueDto>(
            `/${wowId}/workflows`,
            {
                workflows,
            }
        )
    }

    static async putById(wowId: UUID, name: string, description: string) {
        return await wowsInstance.put<WorkflowIdsValueDto>(
            `/${wowId}`,
            {
                name,
                description,
            }
        )
    }
}
