import { Center, Text } from '@chakra-ui/layout'
import { DisabledCellData } from 'components/DateRangePicker/types'
import React, { FC } from 'react'

interface DisabledCellProps {
    cellData: DisabledCellData
}

export const DisabledCell: FC<DisabledCellProps> = ({ cellData }) => {
    return (
        <Center width="32px" height="32px">
            <Text variant="paragraphsmall" color="gray.400">
                {cellData.day}
            </Text>
        </Center>
    )
}
