import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const PencilIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 18 18"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M17.5043 4.9933L15.8835 6.61408C15.7183 6.77932 15.4511 6.77932 15.2858 6.61408L11.3833 2.71155C11.218 2.54631 11.218 2.27911 11.3833 2.11387L13.0041 0.493089C13.6615 -0.164363 14.7303 -0.164363 15.3913 0.493089L17.5043 2.60608C18.1652 3.26353 18.1652 4.33233 17.5043 4.9933ZM9.99104 3.50612L0.758577 12.7386L0.0132301 17.0103C-0.0887278 17.5868 0.41403 18.0861 0.990619 17.9876L5.2623 17.2388L14.4948 8.00633C14.66 7.84109 14.66 7.57389 14.4948 7.40864L10.5922 3.50612C10.4235 3.34088 10.1563 3.34088 9.99104 3.50612ZM4.36226 11.9475C4.16889 11.7542 4.16889 11.4448 4.36226 11.2514L9.77657 5.83709C9.96994 5.64372 10.2793 5.64372 10.4727 5.83709C10.6661 6.03046 10.6661 6.33984 10.4727 6.53321L5.05839 11.9475C4.86502 12.1409 4.55563 12.1409 4.36226 11.9475ZM3.09306 14.9043H4.78064V16.1805L2.51296 16.5778L1.41955 15.4844L1.81683 13.2167H3.09306V14.9043Z"
                fill="currentColor"
            />
        </Icon>

    )
}
