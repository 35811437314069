import { Text } from '@chakra-ui/layout'
import { SelectedCellData } from 'components/DateRangePicker/types'
import React, { FC } from 'react'
import { ClickedCellLayout } from './ClickedCellLayout'
import { CenterStyled, BoxStyled } from '../../../styles'

interface SelectedCellProps {
    cellData: SelectedCellData
    selectDate: (date: Date) => void
}

export const SelectedCell: FC<SelectedCellProps> = ({
    cellData,
    selectDate,
}) => {
    const day = cellData.date.getDate()

    if (cellData.direction === 'none') {
        return (
            <ClickedCellLayout date={cellData.date} selectDate={selectDate}>
                <CenterStyled>
                    <Text variant="paragraphsmall">{day}</Text>
                </CenterStyled>
            </ClickedCellLayout>
        )
    }

    const marginRange =
        cellData.direction === 'left' ? '2px 0px 2px 2px' : '2px 2px 2px 0px'
    const marginBlock =
        cellData.direction === 'left' ? '0px 2px 0px 0px' : '0px 0px 0px 2px'
    const borderRadius =
        cellData.direction === 'left' ? '50% 0 0 50%' : '0 50% 50% 0'

    return (
        <ClickedCellLayout date={cellData.date} selectDate={selectDate}>
            <BoxStyled margin={marginRange} borderRadius={borderRadius}>
                <CenterStyled margin={marginBlock}>
                    <Text variant="paragraphsmall">{day}</Text>
                </CenterStyled>
            </BoxStyled>
        </ClickedCellLayout>
    )
}
