import React, { ChangeEvent, FC, RefObject, useEffect, useState } from 'react'
import { Button } from '@chakra-ui/button'
import { observer } from 'mobx-react-lite'
import {
    Text,
    Drawer,
    IconButton,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
} from '@chakra-ui/react'
import { CustomInput } from 'components/baseComponents/Input'
import { RenderFilters } from 'pages/HomePage/content/OrderStatusTracking/Filters/ShowFilters/RenderFilters'
import { SelectedFilters } from '../SelectedFilters'
import { filters } from 'pages/HomePage/content/OrderStatusTracking/Filters/ShowFilters/mock'
import { getFiltersNames } from 'pages/HomePage/content/OrderStatusTracking/Filters/utils'
import { orderStore } from 'stores'
import {
    HeaderBox,
    FiltersBox,
    EditButtons,
    FiltersHeader,
    ModalInputText,
    DrawerBodyStyled,
    ArrowBackIconStyled,
    DetectedChangesText,
} from './styles'

interface IEditFiltersDrawer {
    isOpen: boolean
    onClose: () => void
    btnRef: RefObject<HTMLHeadingElement>
}

export const EditFiltersDrawer: FC<IEditFiltersDrawer> = observer(({
    isOpen,
    onClose,
    btnRef,
}) => {
    const [value, setValue] = useState('')
    const [isNameInvalid, setIsNameInvalid] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [filtersNames, setFiltersNames] = useState<string[]>([])

    const isFiltersChanged = orderStore.isEditFiltersChanged()
    const editFilters = orderStore.editFilters
    const savedFilters = orderStore.savedFilters
    const isDateInvalid = orderStore.isDateInvalid(true)

    const handleClose = (): void => {
        setValue(editFilters.name)
        onClose()
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setValue(event.target.value)
    }

    const handleSave = (): void => {
        const editedFilter = { ...editFilters, name: value }
        const updateFilters = savedFilters.map((savedFilter) => {
            if (savedFilter.name === editFilters.name) {
                return editedFilter
            }
            return savedFilter
        })

        orderStore.setSavedFilters(updateFilters)
        localStorage.setItem('savedOrderFilters', JSON.stringify(updateFilters))
        handleClose()
    }

    const handleClearAll = async (): Promise<void> => {
        await orderStore.clearFilters('all')
    }

    const getFiltersNumber = (): number => {
        const {
            createdFrom,
            createdTo,
            deliveryFrom,
            deliveryTo,
            statuses,
            ShipmentProviderCodes,
            orderSourceIds,
            orderNumber,
        } = editFilters

        let amount = 0
        if (createdFrom || createdTo) amount++
        if (deliveryFrom || deliveryTo) amount++

        amount =
            amount +
            +!!statuses.length +
            +!!ShipmentProviderCodes?.length +
            +!!orderSourceIds.length +
            +!!orderNumber
        return amount
    }

    useEffect(() => {
        setValue(editFilters.name)
    }, [editFilters.name])

    useEffect(() => {
        return () => {
            orderStore.clearEditFilters()
        }
    }, [])

    useEffect(() => {
        setFiltersNames(
            getFiltersNames(savedFilters, editFilters.name)
        )
    }, [editFilters.name, savedFilters])

    useEffect(() => {
        if (!value) {
            setIsNameInvalid(true)
            setErrorMessage('Please enter a name')
        } else if (filtersNames.includes(value)) {
            setIsNameInvalid(true)
            setErrorMessage('A filter with this name already exists')
        } else if (value.length > 200) {
            setIsNameInvalid(true)
            setErrorMessage('Max name length is 200 characters')
        } else {
            setIsNameInvalid(false)
            setErrorMessage('')
        }
    }, [filtersNames, value])

    return (
        <Drawer
            size="sm"
            placement="right"
            isOpen={isOpen}
            onClose={handleClose}
            finalFocusRef={btnRef}
        >
            <DrawerOverlay />
            <DrawerContent backgroundColor="blueWhite.1000">
                <DrawerCloseButton margin="20px 20px 0 0" />
                <DrawerHeader>
                    <HeaderBox>
                        <IconButton
                            aria-label="Arrow Left Icon"
                            variant="hoveredMediumWhite"
                            marginRight="10px"
                            onClick={handleClose}
                            icon={<ArrowBackIconStyled />}
                        />
                        <Text variant="h4Normal">Back</Text>
                    </HeaderBox>
                </DrawerHeader>
                <DrawerBodyStyled>
                    <ModalInputText>Filters Configuration Name</ModalInputText>
                    <CustomInput
                        variant="modal"
                        value={value}
                        onChange={handleChange}
                        errorMessage={errorMessage}
                        placeholder="The name of the saved filter"
                        border={isNameInvalid ? '2px solid' : '1px solid'}
                        borderColor={isNameInvalid ? 'red.500' : 'color.850'}
                    />
                    <FiltersBox>
                        <FiltersHeader>
                            <Text variant="h3Small">Filters</Text>
                            {getFiltersNumber() > 1 && (
                                <Button
                                    variant="underline"
                                    margin="0"
                                    paddingTop="7px"
                                    onClick={handleClearAll}
                                >
                                    Clear All
                                </Button>
                            )}
                        </FiltersHeader>
                        {isFiltersChanged && (
                            <DetectedChangesText>
                                Changes was detected, please click "Save Changes" to save them
                            </DetectedChangesText>
                        )}
                        <SelectedFilters filter={editFilters} isSavedFilters={false} />
                        {filters.map(filter =>
                            <RenderFilters
                                key={filter.value}
                                filter={filter}
                                isEditFilters
                            />
                        )}
                    </FiltersBox>
                    <EditButtons>
                        <Button
                            variant="transparent"
                            marginRight="12px"
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="blue"
                            isDisabled={isDateInvalid || isNameInvalid}
                            onClick={handleSave}
                        >
                            Save Changes
                        </Button>
                    </EditButtons>
                </DrawerBodyStyled>
            </DrawerContent>
        </Drawer>
    )
})
