import React, { FC } from 'react'
import { format } from 'date-fns'
import { IAuditOrder } from 'types/core'
import { transformStatus } from 'pages/OrderDetailsPage/utils'
import { AccordionInfoBox, AccordionInfoItem } from 'pages/OrderDetailsPage/styles'

interface AccordionInfoProps {
    order: IAuditOrder
    title?: string
    status?: string
    error?: boolean
}

export const AccordionInfo: FC<AccordionInfoProps> = ({
    order,
    title,
    status,
    error,
}) => {
    const { timestamp, status: { description } } = order

    return (
        <AccordionInfoBox borderColor={error ? 'red.500' : 'transparent'}>
            <AccordionInfoItem textTransform="capitalize" variant="h4" flexGrow={2}>
                {title || transformStatus(description)}
            </AccordionInfoItem>
            <AccordionInfoItem variant="linksmall" flexGrow={1}>
                {timestamp ? format(new Date(timestamp), 'LLL. dd, yyyy') : '-'}
            </AccordionInfoItem>
            <AccordionInfoItem variant="linksmall" flexGrow={1}>
                {timestamp ? format(new Date(timestamp), "hh:mm:ss aaaaa'm'") : '-'}
            </AccordionInfoItem>
            <AccordionInfoItem textTransform="capitalize" variant="linksmall" flexGrow={5}>
                {status || transformStatus(description)}
            </AccordionInfoItem>
            <AccordionInfoItem flexGrow={0.3} />
        </AccordionInfoBox>
    )
}
