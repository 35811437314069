import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { toJS } from 'mobx'
import { DatePickers } from 'pages/ReportsPage/DatePickers'
import { ReportsData } from 'pages/ReportsPage/ReportsTable'
import { NumericPagination } from 'components/NumericPagination'
import { ReportType } from 'types/core'
import { shippingCostReportStore, userStore } from 'stores'
import {
    FlexFilter,
    GridContainer,
    GridFilter,
    GridItems,
    GridPagination,
    GridTable,
    SubmitButton,
    TextItems,
    TextRecords,
    UserName,
} from '../styles'

export const ShippingCostReport = observer(() => {
    const [fromDate, setFromDate] = useState<Date | null>(null)
    const [toDate, setToDate] = useState<Date | null>(null)
    const [status, setStatus] = useState<Boolean>(false)

    const shipppingReportItems = toJS(
        shippingCostReportStore.shippingCostReportData
    )
    const curUser = toJS(userStore.name)

    useEffect(() => {
        return () => {
            shippingCostReportStore.clean()
        }
    }, [])

    const shippingCostSubmitDetails = async () => {
        shippingCostReportStore.reloadPages()
        await shippingCostReportStore.getShippingCostReports(
            fromDate?.toLocaleDateString() || '',
            toDate?.toLocaleDateString() || ''
        )
        setStatus(true)
    }

    return (
        <>
            <GridContainer>
                <GridFilter>
                    <FlexFilter>
                        <DatePickers
                            getfromDate={setFromDate}
                            getToDate={setToDate}
                        />
                        <SubmitButton onClick={shippingCostSubmitDetails}>
                            Submit
                        </SubmitButton>
                        <UserName>{curUser}</UserName>
                    </FlexFilter>
                </GridFilter>
                <GridTable>
                    {status &&
                        (!!shipppingReportItems?.length ? (
                            <ReportsData
                                reportType={ReportType.shippingFeeReport}
                            />
                        ) : (
                            <TextRecords>No Records Found</TextRecords>
                        ))}
                </GridTable>
                <GridItems>
                    {status && !!shipppingReportItems?.length && (
                        <TextItems>
                            {shippingCostReportStore?.totalItems} Items
                        </TextItems>
                    )}
                </GridItems>
                <GridPagination>
                    <NumericPagination
                        pages={shippingCostReportStore.pages}
                        activePage={shippingCostReportStore.filters.page}
                        onChangePage={page => {
                            shippingCostReportStore.setFilters({ page })
                            shippingCostReportStore.getShippingCostReports(
                                fromDate?.toLocaleDateString() || '',
                                toDate?.toLocaleDateString() || ''
                            )
                        }}
                    />
                </GridPagination>
            </GridContainer>
        </>
    )
})
