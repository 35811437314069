import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import { Box } from '@chakra-ui/layout'
import { DataContainer } from './data'
import { kpiStore } from 'stores'
import { DataBox } from './styles'

export const SourceData = observer(() => {
    const sourceData = toJS(kpiStore.orderSources)

    return (
        <Box>
            {Array.isArray(sourceData) && !!sourceData.length && (
                <>
                    {sourceData.map((data, index) => (
                        <DataBox key={index}>
                            <DataContainer
                                source={data.source}
                                myData={[data.percentage, data.growth]}
                            />
                        </DataBox>
                    ))}
                </>
            )}
        </Box>
    )
})