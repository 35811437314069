import React, { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { CellProps, Column } from 'react-table'
import { useForm } from 'react-hook-form'
import { toJS } from 'mobx'
import { Button, ButtonGroup, useDisclosure } from '@chakra-ui/react'
import { Box, Flex } from '@chakra-ui/layout'
import { authenticationSchemaStore, tenantsStore } from 'stores'
import { useCustomToast } from 'hooks'
import {
    AuthSchema,
    FieldFormatForm,
    FieldIndexForm,
    FieldNameForm,
    FieldTypeForm,
    IAuthenticationSchema,
    LocationSecretIdSubCategory,
} from 'types/core'
import { AuthenticationSchemaAPI } from 'api/authenticationSchema'
import { StatusType } from 'types/chart'
import CustomModal from 'components/TableComponents/TableModal'
import { NumericPagination } from 'components/NumericPagination'
import EditableTable, { EditingRows } from 'components/TableComponents/Table'
import AuthenticationSchemaInputs from 'components/TableComponents/TableModal/ModalFormInputs/AuthenticationSchemaInputs'
import { ModalState } from 'components/TableComponents/mock'
import { ConfirmModal } from 'components/Modal/ConfirmModal'
import { errorObjTransformString } from 'utils/objectTransform'

type SubmitFormResponse = AuthSchema &
    FieldNameForm &
    FieldIndexForm &
    FieldFormatForm &
    FieldTypeForm

export const AdminAuthenticationSchemaPage = observer(() => {
    const customToast = useCustomToast()
    const [editingRow, setEditingRow] = useState<EditingRows>({})
    const [deleteRowIndex, setDeleteRowIndex] = useState<number>(0)
    const [dataToEdit, setDataToEdit] = useState<IAuthenticationSchema | null>(
        null
    )
    const data: IAuthenticationSchema[] = toJS(authenticationSchemaStore.data)
    const { tenantId } = tenantsStore
    const [modalState, setModalState] = useState<ModalState>()

    const { isOpen, onOpen, onClose } = useDisclosure()
    const {
        isOpen: isOpenDelete,
        onOpen: onOpenDelete,
        onClose: onCloseDelete,
    } = useDisclosure()

    const {
        handleSubmit,
        register,
        control,
        formState: { errors, isSubmitting },
        reset,
        setValue,
    } = useForm()

    const columns: Column[] = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                editable: true,
            },
            {
                Header: 'Secret Id',
                accessor: 'secretName',
            },
            {
                Header: 'Location',
                accessor: 'locations',
            },
            {
                Header: 'Actions',
                accessor: 'edit',
                Cell: ({ row, onEdit, openDeleteModal }: CellProps<object>) => {
                    return (
                        <>
                            <ButtonGroup variant="outline">
                                <Button
                                    size="xs"
                                    variant="ghost"
                                    colorScheme="blue"
                                    onClick={_props => {
                                        onOpen()
                                        setModalState(ModalState.Edit)
                                        onEdit(row.id)
                                    }}
                                >
                                    Edit
                                </Button>
                                <Button
                                    size="xs"
                                    variant="ghost"
                                    colorScheme="blue"
                                    onClick={_props => {
                                        openDeleteModal(row.id)
                                    }}
                                >
                                    Delete
                                </Button>
                            </ButtonGroup>
                        </>
                    )
                },
            },
        ],
        [onOpen]
    )

    useEffect(() => {
        tenantId && authenticationSchemaStore.fetch(tenantId)

        return () => {
            authenticationSchemaStore.clean()
        }
    }, [tenantId])

    const prepareLocationData = (values: SubmitFormResponse) => {
        let preparedData = {}

        if (
            values.secretIdSubCategory === LocationSecretIdSubCategory.API_KEY
        ) {
            preparedData = {
                apiKey: {
                    type: values.apiKeyType,
                    name: values.apiKeyName,
                    format: values.apiKeyFormat,
                    index: values.apiKeyIndex,
                },
            }
        } else if (
            values.secretIdSubCategory ===
            LocationSecretIdSubCategory.RESOURCE_OWNER
        ) {
            preparedData = {
                clientId: {
                    type: values.clientIdType,
                    name: values.clientIdName,
                    format: values.clientIdFormat,
                    index: values.clientIdIndex,
                },
                clientSecret: {
                    type: values.clientSecretType,
                    name: values.clientSecretName,
                    format: values.clientSecretFormat,
                    index: values.clientSecretIndex,
                },
                username: {
                    type: values.clientUsernameType,
                    name: values.clientUsernameName,
                    format: values.clientUsernameFormat,
                    index: values.clientUsernameIndex,
                },
                password: {
                    type: values.clientPasswordType,
                    name: values.clientPasswordName,
                    format: values.clientPasswordFormat,
                    index: values.clientPasswordIndex,
                },
            }
        }

        return preparedData
    }

    const onEdit = (id: number): void => {
        setDataToEdit(data[id])
    }

    const onSubmit = (values: SubmitFormResponse) => {
        if (modalState === ModalState.Add) {
            AuthenticationSchemaAPI.addAuthenticationSchema(
                tenantsStore.tenantId,
                values.name,
                values.secretId,
                prepareLocationData(values)
            )
                .then(() => {
                    authenticationSchemaStore.fetch(tenantId)
                    setDataToEdit(null)
                    onClose()
                    reset()
                })
                .catch(error => {
                    if (error.response?.status === 400) {
                        customToast({
                            title: 'Error',
                            description: errorObjTransformString(
                                error.response.data.errors
                            ),
                            status: StatusType.Error,
                        })
                    } else if (error.response?.status === 409) {
                        customToast({
                            title: 'Error',
                            description: 'Name already exists',
                            status: StatusType.Error,
                        })
                    } else {
                        customToast({
                            title: 'Error',
                            description: 'Something went wrong.',
                            status: StatusType.Error,
                        })
                    }
                })
        } else if (modalState === ModalState.Edit && dataToEdit) {
            AuthenticationSchemaAPI.updateAuthenticationSchema({
                ...dataToEdit,
                name: values.name,
                secretId: values.secretId,
                locations: prepareLocationData(values),
            })
                .then(() => {
                    authenticationSchemaStore.fetch(tenantId)
                    setDataToEdit(null)
                    onClose()
                })
                .catch(error => {
                    if (error.response?.status === 400) {
                        customToast({
                            title: 'Error',
                            description: errorObjTransformString(
                                error.response.data.errors
                            ),
                            status: StatusType.Error,
                        })
                    } else if (error.response?.status === 409) {
                        customToast({
                            title: 'Error',
                            description: 'Name already exists',
                            status: StatusType.Error,
                        })
                    } else {
                        customToast({
                            title: 'Error',
                            description: 'Something went wrong.',
                            status: StatusType.Error,
                        })
                    }
                })
        }
    }

    const onDeleteRow = () => {
        AuthenticationSchemaAPI.deleteAuthenticationSchema(data[deleteRowIndex])
            .then(() => {
                onCloseDelete()
                authenticationSchemaStore.fetch(tenantId)
            })
            .catch(error => {
                if (error.response?.status === 400) {
                    customToast({
                        title: 'Error',
                        description: error?.response.data,
                        status: StatusType.Error,
                    })
                }
                else {
                    customToast({
                        title: 'Error',
                        description: 'Something went wrong.',
                        status: StatusType.Error,
                    })
                }
            })
    }

    const openDeleteModal = (rowIndex: number) => {
        setDeleteRowIndex(rowIndex)
        onOpenDelete()
    }

    return (
        <div>
            <Box padding="31px 0">
                <Flex color="white" overflowY={'scroll'}>
                    <Box flex="1">
                        <EditableTable
                            data={data}
                            columns={columns}
                            editingRows={editingRow}
                            setEditingRows={setEditingRow}
                            onEdit={onEdit}
                            openDeleteModal={openDeleteModal}
                        />
                    </Box>
                </Flex>
            </Box>
            <Button
                size="sm"
                variant="outline"
                colorScheme="blue"
                onClick={() => {
                    setModalState(ModalState.Add)
                    onOpen()
                }}
            >
                Add
            </Button>
            <CustomModal
                modalTitle={
                    modalState === ModalState.Add
                        ? 'Add Authentication Schema'
                        : 'Edit Authentication Schema'
                }
                isOpen={isOpen}
                onClose={() => {
                    setDataToEdit(null)
                    onClose()
                }}
                onSubmit={onSubmit}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
            >
                <AuthenticationSchemaInputs
                    modalState={modalState}
                    data={dataToEdit}
                    errors={errors}
                    register={register}
                    control={control}
                    reset={reset}
                    setValue={setValue}
                />
            </CustomModal>
            <NumericPagination
                marginTop="24px"
                pages={authenticationSchemaStore.pages}
                activePage={authenticationSchemaStore.filters.page}
                onChangePage={page => {
                    authenticationSchemaStore.setFilters({ page })
                    authenticationSchemaStore.fetch(tenantId)
                }}
            />
            <ConfirmModal
                isOpen={isOpenDelete}
                onClose={onCloseDelete}
                confirmFunc={onDeleteRow}
                headerText="Delete Authentication Schema"
                bodyText="Are you sure?"
                buttonText="Delete"
                colorButton="red.500"
            />
        </div>
    )
})
