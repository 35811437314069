import React, { FC } from 'react'
import { useForm } from 'react-hook-form'
import {
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from '@chakra-ui/react'
import { CounteredInput } from 'components/CounteredInput'
import { notificationStore, tenantsStore } from 'stores'
import {
    TENANT_CHILD_NAME_LENGTH,
    TENANT_CHILD_DOMAIN_LENGTH,
} from 'constants/rules'
import {
    ModalCloseButton,
    ModalConfirmButton,
    ModalHeaderText,
    ModalBodyStyled,
    TextStyled,
} from './style'

interface tenantTreeChildModel {
    name: string
    domain: string
}

interface IConfirmModal {
    isOpen: boolean
    onClose: () => void
    headerText: string
    buttonText: string
}

export const CreateTenantModal: FC<IConfirmModal> = ({
    isOpen,
    onClose,
    headerText,
    buttonText,
}) => {
    const {
        handleSubmit,
        register,
        watch,
        reset,
        formState: { errors },
    } = useForm<tenantTreeChildModel>()

    const selectedTenant = tenantsStore.selectedTenant

    const ruleTenantNameRemaining =
        TENANT_CHILD_NAME_LENGTH - watch('name', '')?.length ||
        TENANT_CHILD_NAME_LENGTH
    const ruleTenantDomainRemaining =
        TENANT_CHILD_DOMAIN_LENGTH - watch('domain', '')?.length ||
        TENANT_CHILD_DOMAIN_LENGTH

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent alignSelf="center" backgroundColor="color.900">
                <form
                    onSubmit={handleSubmit(item => {
                        if (selectedTenant?.id) {
                            tenantsStore
                                .saveTenantChildrenTenant(
                                    selectedTenant.id,
                                    item
                                ).then(() => {
                                    onClose()
                                    reset()
                                }).catch(err => {
                                    notificationStore.triggerErrorToast(err)
                                })
                        } else {
                            tenantsStore
                                .saveTenantRootTenant(item)
                                .then(() => {
                                    onClose()
                                    reset()
                                }).catch(err => {
                                    notificationStore.triggerErrorToast(err)
                                })
                        }
                    })}
                >
                    <ModalHeader>
                        <ModalHeaderText>{headerText}</ModalHeaderText>
                    </ModalHeader>
                    <ModalBodyStyled textAlign="left">
                        <TextStyled>Name</TextStyled>
                        <CounteredInput
                            placeholder="Enter Tenant name..."
                            size="sm"
                            marginTop="8px"
                            remaining={ruleTenantNameRemaining}
                            isInvalid={!!errors.name}
                            description={errors.name?.message}
                            {...register('name', {
                                required:
                                    'This field cannot be blank. Please enter an Authority URL to continue.',
                                maxLength: {
                                    value: TENANT_CHILD_NAME_LENGTH,
                                    message: `This field max length is ${TENANT_CHILD_NAME_LENGTH}`,
                                },
                            })}
                        />
                        <TextStyled>Domains</TextStyled>
                        <CounteredInput
                            placeholder="Enter one or several domains (comma separated)..."
                            size="sm"
                            marginTop="8px"
                            remaining={ruleTenantDomainRemaining}
                            isInvalid={!!errors.domain}
                            description={errors.domain?.message}
                            {...register('domain', {
                                required:
                                    'This field cannot be blank. Please enter at least one domain name to continue.',
                                maxLength: {
                                    value: TENANT_CHILD_DOMAIN_LENGTH,
                                    message: `This field max length is ${TENANT_CHILD_DOMAIN_LENGTH}`,
                                },
                            })}
                        />
                    </ModalBodyStyled>
                    <ModalFooter justifyContent="right">
                        <ModalCloseButton onClick={onClose}>
                            Close
                        </ModalCloseButton>
                        <ModalConfirmButton type="submit" color="white">
                            {buttonText}
                        </ModalConfirmButton>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    )
}
