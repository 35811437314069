import type { SystemStyleObject } from '@chakra-ui/theme-tools'

const baseStyle: SystemStyleObject = {
    opacity: 1,
    borderColor: 'divider',
}

const variantEditor: SystemStyleObject = {
    opacity: 1,
    borderStyle: 'solid',
    borderColor: 'editorDivider',
}

const variantSolid: SystemStyleObject = {
    borderStyle: 'solid',
}

const variantDashed: SystemStyleObject = {
    borderStyle: 'dashed',
}

const variants = {
    solid: variantSolid,
    dashed: variantDashed,
    editor: variantEditor,
}

const defaultProps = {
    variant: 'solid',
}

export const Divider = {
    baseStyle,
    variants,
    defaultProps,
}
