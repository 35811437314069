import React, { Dispatch, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { MultiValue, SingleValue } from 'react-select'
import { useColorMode } from '@chakra-ui/react'
import { CustomSelect } from 'components/baseComponents/Select'
import { rulesSetsStore } from 'stores'
import { adaptToSelect } from '../../utils'
import { ISelectOption } from 'types/core'
import { reactSelectStyles } from './styles'

interface ICustomSelectContainer {
    value: SingleValue<ISelectOption>
    setValue: Dispatch<SingleValue<ISelectOption>>
    isDisabled: boolean
}

export const CustomSelectContainer: FC<ICustomSelectContainer> = observer(
    ({ value, setValue, isDisabled }) => {
        const { colorMode } = useColorMode()

        const orderSources = rulesSetsStore.orderSources

        const handleChange = (
            selectedValue:
                | SingleValue<ISelectOption>
                | MultiValue<ISelectOption>
        ) => {
            if (
                selectedValue &&
                'value' in selectedValue &&
                selectedValue.value
            ) {
                setValue(selectedValue)
            } else {
                setValue(null)
            }
        }

        return (
            <CustomSelect
                selectValue={value}
                options={adaptToSelect(orderSources)}
                placeholder="Choose Order Source"
                onChange={handleChange}
                customStyles={reactSelectStyles(colorMode)}
                isClearable
                isDisabled={isDisabled}
            />
        )
    }
)
