import React, { DragEventHandler, useRef, useState } from 'react'
import { Box, BoxProps } from '@chakra-ui/layout'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import ReactFlow, {
    Connection,
    Edge,
    Elements,
    Node,
    OnLoadFunc,
    OnLoadParams,
    ReactFlowProvider,
    XYPosition,
} from 'react-flow-renderer'
import { editoreStore } from 'stores'
import { Connection as ChartConnection } from 'types/chart'
import { StepMetadata } from 'types/core'
import { edgeTypes } from '../edges/edgeTypes'
import { nodeTypes } from '../nodes/nodeTypes'

export const DiagramEditor = observer<BoxProps, HTMLDivElement>(
    (props, ref) => {
        const reactFlowWrapper = useRef<HTMLDivElement>(null!)

        const [reactFlowInstance, setReactFlowInstance] =
            useState<OnLoadParams>(null!)

        const elements = toJS(editoreStore.diagramStore.elements)
        const workflow = editoreStore.workflowStore.workflow

        const isDraft = workflow?.status === 'draft'

        const onConnect = (connection: Connection | Edge) => {
            if (!isDraft) return

            const { source, target, sourceHandle, targetHandle } =
                connection as ChartConnection
            editoreStore.diagramStore.connect({
                source,
                target,
                sourceHandle,
                targetHandle,
            })
        }

        const onElementsRemove = (elements: Elements) => {
            // diagramStore.removeElements(elements)
        }

        const onLoad: OnLoadFunc = loadParams =>
            setReactFlowInstance(loadParams)

        const onDragOver: DragEventHandler = event => {
            event.preventDefault()
            event.dataTransfer.dropEffect = 'move'
        }

        const getPosition = (x: number, y: number): XYPosition | null => {
            if (!reactFlowWrapper?.current) {
                return null
            }

            const reactFlowBounds =
                reactFlowWrapper.current.getBoundingClientRect()

            return reactFlowInstance.project({
                x: x - reactFlowBounds.left,
                y: y - reactFlowBounds.top,
            })
        }

        const onNodeDragStop = (event: React.MouseEvent, node: Node) => {
            event.preventDefault()
            event.stopPropagation()

            editoreStore.diagramStore.moveStep(node.id, node.position)
        }

        const onDrop: DragEventHandler = event => {
            if (!isDraft) return

            event.preventDefault()

            const raw: string = event.dataTransfer.getData(
                'application/reactflow'
            )

            const metadata: StepMetadata = JSON.parse(raw)

            const position = getPosition(event.clientX, event.clientY)

            if (position) {
                editoreStore.diagramStore.createStep(metadata, position)
            }
        }

        return (
            <Box {...props} width="100%" height="100%" ref={ref}>
                <ReactFlowProvider>
                    <ReactFlow
                        ref={reactFlowWrapper}
                        elements={elements}
                        onConnect={onConnect}
                        onElementsRemove={onElementsRemove}
                        onLoad={onLoad}
                        onDrop={onDrop}
                        onDragOver={onDragOver}
                        onNodeDragStop={onNodeDragStop}
                        nodeTypes={nodeTypes}
                        edgeTypes={edgeTypes}
                        nodesDraggable={isDraft}
                        nodesConnectable={isDraft}
                    ></ReactFlow>
                </ReactFlowProvider>
            </Box>
        )
    },
    {
        forwardRef: true,
    }
)
