import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

export const adaptationGMT = (MUIDate: MaterialUiPickersDate, type: string) => {
    if (!MUIDate) return MUIDate

    const date = new Date(MUIDate)

    date.setHours(0, 0, 0, 0)

    if (type === 'to') date.setDate(date.getDate() + 1)

    return date
}
