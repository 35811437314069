import { makeAutoObservable } from 'mobx'
import { PagingFilters } from 'types/api'
import { OrderClientAPI } from 'api/orderClient'
import { ShippingCost } from 'types/core'

const defaultFilters: PagingFilters = {
    page: 1,
    size: 10,
}

export class ShippingCostReportStore {
    public filters: PagingFilters = defaultFilters
    public pages: number = 0
    public shippingCostReportData: ShippingCost[] = []
    public totalPages: number = 0
    public totalItems: number = 0
    public currentPage: number = 0

    constructor() {
        makeAutoObservable(this)
    }

    public async getShippingCostReports(dateFrom: string, dateTo: string) {
        try {
            const data = await OrderClientAPI.shipmentCost({
                dateFrom,
                dateTo,
                page: this.filters.page,
                size: this.filters.size,
            })
            this.initShipigReport(data?.items)
            this.initTotalPages(data?.totalPages)
            this.initTotalItems(data?.totalItems)
        } catch (err) {
            this.shippingCostReportData = []
        }
    }
    private initShipigReport(reports: ShippingCost[]) {
        this.shippingCostReportData = reports
    }

    private initTotalPages(totalPages: number) {
        this.pages = totalPages
    }

    private initTotalItems(totalItems: number) {
        this.totalItems = totalItems
    }

    private initFilters(filter: PagingFilters) {
        this.filters = filter
    }

    public reloadPages() {
        this.initFilters(defaultFilters)
    }

    public setFilters(filters: Partial<PagingFilters>) {
        if (!filters.page) {
            this.filters = { ...this.filters, ...filters, page: 1 }
        } else {
            this.filters = { ...this.filters, ...filters }
        }
    }

    public clean() {
        this.initFilters(defaultFilters)
        this.initTotalPages(0)
        this.initTotalItems(0)
        this.shippingCostReportData = []
    }
}
