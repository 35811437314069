import React, { useEffect, useState } from 'react'
import { toJS } from 'mobx'
import { Box, Heading, Text } from '@chakra-ui/layout'
import { observer } from 'mobx-react-lite'
import { orderStore } from 'stores'
import { Spinner } from '@chakra-ui/react'
import { AccordionsHeader } from '../../components/Accordion/AccordionsHeader'
import { NumericPagination } from 'components/NumericPagination'
import { CustomAccordion } from 'components/baseComponents/Accordion'
import { accordionHeaderOptionsOrderSimulate } from '../HomePage/mock'
import { CustomAccordionButton } from '../../components/Accordion/AccordionButton'
import { CustomAccordionPanel } from '../../components/Accordion/AccordionPanel'
import { IOrder } from 'types/core'
import { AccordionStyled } from '../HomePage/styles'

const orderPageType = 'RulesSimulatePage'

export const RulesSimulate = observer(() => {
    const [opened, setOpened] = useState<number[]>([])
    const orders = toJS(orderStore.orders)
    const page = toJS(orderStore.filters.page)
    const loading = toJS(orderStore.loading)
    const isDasboard = false

    const childOrderHandler = (order: IOrder) =>
        orderStore.splitOrderHierarchy(order, orderPageType)

    useEffect(() => {
        setOpened([])
    }, [page])

    useEffect(() => {
        orderStore.searchSimulate()
        orderStore.getOrderSources()
        return () => {
            orderStore.clean()
        }
    }, [])

    useEffect(() => {
        orderStore.initStatus(isDasboard)
        // Need for improvement. Warnings console
        /* eslint-disable */
    }, [])

    useEffect(() => {
        orderStore.searchSimulate()
        orderStore.splitCounter(0)
    }, [orderStore.isSplitCounter])

    return (
        <Box padding="31px 0">
            <Heading as="h1" variant="h1" marginBottom="24px">
                Rules Simulate
            </Heading>
            {loading ? (
                <Box width="100%" display="flex" justifyContent="center">
                    <Spinner size="xl" />
                </Box>
            ) : (
                <Box>
                    {!!orders.length ? (
                        <>
                            <AccordionsHeader
                                options={accordionHeaderOptionsOrderSimulate}
                            />
                            <AccordionStyled
                                allowMultiple
                                allowToggle
                                index={opened}
                                onChange={(expandedIndex: number[]) =>
                                    setOpened(expandedIndex)
                                }
                            >
                                {orders.map((order, i) => {
                                    const isOnlyOne = orders.length === 1
                                    const isFirst = i === 0
                                    const isLast = i === orders.length - 1

                                    return (
                                        <CustomAccordion
                                            key={order.id}
                                            AccordionButtonContent={
                                                <CustomAccordionButton
                                                    order={order}
                                                    isOpen={opened.includes(i)}
                                                    workflowEvents={order.workflowEvents}
                                                    isWorkFlowTable={true}
                                                    isIconDashboard={false}
                                                    isOnlyOne={isOnlyOne}
                                                    isFirst={isFirst}
                                                    isLast={isLast}
                                                    childOrderHandler={childOrderHandler}
                                                    isOrderSimulated={true}
                                                />
                                            }
                                            AccordionPanelContent={
                                                <CustomAccordionPanel
                                                    order={order}
                                                    splitOrderChildData={order}
                                                    childOrderHandler={childOrderHandler}
                                                    isOrderSimulated={true}
                                                />
                                            }
                                        />
                                    )
                                })}
                            </AccordionStyled>
                            <NumericPagination
                                marginTop="24px"
                                pages={orderStore.pages}
                                activePage={orderStore.localFilters.page}
                                onChangePage={page =>
                                    orderStore.setPages({ page }, true, true)
                                }
                            />
                        </>
                    ) : (
                        <Text>You have no simulations yet!</Text>
                    )}
                </Box>
            )}
        </Box>
    )
})
