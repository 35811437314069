import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Link as DomLink } from 'react-router-dom'
import { format } from 'date-fns'
import { truncate } from 'lodash'
import { Center, HStack, useDisclosure } from '@chakra-ui/react'
import { Link, Text } from '@chakra-ui/layout'
import { orderStore } from 'stores'
import { IOrder, IWorkflowEvents, IWorkflowEventsKeys } from 'types/core'
import { routes } from 'navigation/routes'
import { StatusTag } from 'components/StatusTag'
import { RetryButton } from 'components/RetryReason'
import { TooltipTextCut } from 'components/TooltipTextCut'
import { ComponentModal } from 'components/Modal/ComponentModal'
import { TooltipWithButton } from 'components/TooltipWithButton'
import { RetryChildCheckbox } from 'components/RetryCheckbox/ChildCheckbox'
import { RectangleIcon } from './RectangleIcon'
import { getBorderRadius } from 'utils/getBorderRadius'
import { stringOrDash } from 'utils/stringOrDash'
import { SplitIcon } from 'icons'
import { mapOrderStatusToMessage, mapOrderStatusToTagStatus, getWorkflowEventsValues } from './utils'
import {
    AccordionButtonBox,
    AccordionButtonItem,
    BackgroundBlock,
    CustomTooltip,
    DeliveryDate,
    DistributionSource,
    PurchaseOrder,
    RectangleIconBox,
    ArrowDownIconStyled,
    ArrowRightIconStyled,
    ExternalSource,
    CenterTooltipStatus,
    GridStyled,
} from '../styles'
import { MoreButton } from 'components/TooltipWithButton/styles'

interface CustomAccordionButtonProps {
    order: IOrder
    isOpen: boolean
    workflowEvents?: IWorkflowEvents[]
    isWorkFlowTable?: boolean
    toRetry?: (orderID: string) => boolean
    handleCheckChange?: (orderID: string) => void
    isFiltersOpen?: boolean
    isIconDashboard?: boolean
    isOnlyOne: boolean
    isFirst?: boolean
    isLast?: boolean
    childOrderHandler?: (order: IOrder) => void
    isOrderSimulated?: boolean
}

export const CustomAccordionButton: FC<CustomAccordionButtonProps> = observer(({
    order: {
        status,
        id,
        orderSourceExternalOrderId,
        expectedDeliveryDate,
        created,
        orderShipments = [],
        reason,
        customerCode,
        orderSourceId,
        isUpdateChild,
        isUpdateParent,
    },
    isOpen,
    workflowEvents = [],
    isWorkFlowTable = false,
    toRetry = () => false,
    handleCheckChange = () => {},
    isIconDashboard,
    isFiltersOpen,
    isOnlyOne,
    isFirst,
    isLast,
    order,
    childOrderHandler,
    isOrderSimulated
}) => {
    const externalSource =
        orderStore.externalSource.find(source => source.id === orderSourceId)
            ?.name || ''

    const {
        isOpen: isOpenTooltip,
        onOpen: onOpenTooltip,
        onClose: onCloseTooltip,
    } = useDisclosure()

    const {
        isOpen: isOpenModal,
        onOpen: onOpenModal,
        onClose: onCloseModal,
    } = useDisclosure()

    const tagStatus = mapOrderStatusToTagStatus(status)
    const tagMessage = mapOrderStatusToMessage(status)

    const isCheckWorkflow = isWorkFlowTable && isOrderSimulated

    if (order.newOrderId) id = order.newOrderId

    return (
        <AccordionButtonBox
            borderRadius={getBorderRadius(isOnlyOne, isFirst, isLast)}
            onClick={() => {
                if (childOrderHandler) childOrderHandler(order)
            }}
        >
            <RectangleIconBox>
                <RectangleIcon status={tagStatus} />
            </RectangleIconBox>
            <GridStyled>
                <AccordionButtonItem display="flex" colSpan={3}>
                    {isOpen ? <ArrowDownIconStyled /> : <ArrowRightIconStyled />}
                    <DeliveryDate color="orderTracking.200">
                        {created
                            ? format(new Date(created), "MM/dd/yyyy hh:mm aaaaa'm'")
                            : '-'}
                    </DeliveryDate>
                </AccordionButtonItem>
                <AccordionButtonItem>
                    <Link
                        display="flex"
                        alignItems="center"
                        to={`${routes.orderDetails}/${id}`}
                        as={DomLink}
                    >
                        <PurchaseOrder>
                            {stringOrDash(orderSourceExternalOrderId)}
                        </PurchaseOrder>
                    </Link>
                </AccordionButtonItem>
                {isCheckWorkflow && (
                    <AccordionButtonItem colSpan={2}>
                        <Text variant="h5Light">
                            {getWorkflowEventsValues(workflowEvents, IWorkflowEventsKeys.WorkflowName)}
                        </Text>
                    </AccordionButtonItem>
                )}
                {isCheckWorkflow && (
                    <AccordionButtonItem colSpan={2}>
                        <Text variant="h5Light">
                            {getWorkflowEventsValues(workflowEvents, IWorkflowEventsKeys.WorkflowType)}
                        </Text>
                    </AccordionButtonItem>
                )}
                <AccordionButtonItem colSpan={isWorkFlowTable ? 2 : 3}>
                    <ExternalSource>{externalSource}</ExternalSource>
                </AccordionButtonItem>
                <AccordionButtonItem colSpan={2}>
                    <DeliveryDate>
                        {expectedDeliveryDate
                            ? format(new Date(expectedDeliveryDate), 'MM/dd/yy')
                            : '-'}
                    </DeliveryDate>
                </AccordionButtonItem>
                <AccordionButtonItem onMouseLeave={onCloseTooltip}>
                    <CustomTooltip
                        placement="top"
                        label={
                            <TooltipWithButton
                                value={reason}
                                cutLength={300}
                                onCloseTooltip={onCloseTooltip}
                            >
                                <MoreButton
                                    onClick={e => {
                                        e.stopPropagation()
                                        onCloseTooltip()
                                        onOpenModal()
                                    }}
                                >
                                    More
                                </MoreButton>
                            </TooltipWithButton>
                        }
                        isOpen={isOpenTooltip}
                        pointerEvents="auto"
                    >
                        <CenterTooltipStatus
                            onMouseEnter={onOpenTooltip}
                        >
                            <StatusTag
                                status={tagStatus}
                                text={tagMessage}
                                backgroundColor="blueWhite.999"
                            />
                        </CenterTooltipStatus>
                    </CustomTooltip>
                </AccordionButtonItem>
                <AccordionButtonItem colSpan={isWorkFlowTable ? 2 : 3}>
                    <TooltipTextCut maxTextLength={20} text={customerCode}>
                        <Text variant="h5Light">
                            {customerCode
                                ? truncate(customerCode, { length: 20 })
                                : '-'
                            }
                        </Text>
                    </TooltipTextCut>
                </AccordionButtonItem>
                <AccordionButtonItem>
                    <BackgroundBlock>
                        <DistributionSource>
                            {stringOrDash((orderShipments?.length &&
                                (orderShipments[0].shipper?.name || orderShipments[0]?.providerCode))
                            )}
                        </DistributionSource>
                    </BackgroundBlock>
                </AccordionButtonItem>
                {!isWorkFlowTable && (
                    <AccordionButtonItem colSpan={1}>
                        {isUpdateChild && (
                            <Center>
                                <SplitIcon boxSize="15px" />
                            </Center>
                        )}
                    </AccordionButtonItem>
                )}
                {!isWorkFlowTable && (
                    <AccordionButtonItem colSpan={1}>
                        {!!isIconDashboard && tagStatus === 'error' && (
                            <HStack gap="1">
                                {!!isFiltersOpen &&
                                    <RetryChildCheckbox
                                        orderID={id}
                                        toRetry={toRetry}
                                        handleCheckChange={handleCheckChange}
                                    />
                                }
                                <RetryButton orderID={id} />
                            </HStack>
                        )}
                    </AccordionButtonItem>
                )}
            </GridStyled>
            <ComponentModal
                isOpen={isOpenModal}
                onClose={onCloseModal}
                headerText="Details"
                scrollBehavior="inside"
                reactComponent={reason}
            />
        </AccordionButtonBox>
    )
})
