import domtoimage from 'dom-to-image'
import { useCallback, useRef } from 'react'

type ScreenshotFormat = 'png' | 'jpeg' | 'svg'

export function useScreenshot() {
    const ref = useRef<HTMLDivElement>(null!)

    const makeImage = useCallback(async (format: ScreenshotFormat = 'png') => {
        const node = ref.current
        switch (format) {
            case 'png':
                return await domtoimage.toPng(node, { quality: 1 })
            case 'jpeg':
                return await domtoimage.toJpeg(node)
            case 'svg':
                return await domtoimage.toSvg(node)
        }
    }, [])

    const makeBlob = useCallback(async () => {
        return await domtoimage.toBlob(ref.current)
    }, [])

    return { ref, makeImage, makeBlob }
}
