import { StylesConfig } from 'react-select'
import { getThemeColor } from 'utils/getThemeColor'
import { ISelectOption } from 'types/core'
import { pseudoSelectors } from 'components/styles'

export const reactSelectStyles = (
    theme: string
): StylesConfig<ISelectOption> => ({
    container: styles => ({
        ...styles,
        boxSizing: 'border-box',
        width: '200px',
    }),
    dropdownIndicator: (styles, state) => ({
        ...styles,
        transition: 'all .2s ease',
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
    }),
    valueContainer: styles => ({
        ...styles,
        padding: '0 8px',
    }),
    indicatorsContainer: styles => ({
        ...styles,
        '& > div': {
            padding: '0 8px',
        },
    }),
    menuList: styles => ({
        ...styles,
        backgroundColor: getThemeColor(theme, 'blueWhite.950'),
        borderRadius: '0px 0px 6px 6px',
        padding: 0,
    }),
    menu: styles => ({
        ...styles,
        borderRadius: '0px 0px 6px 6px',
        marginTop: 0,
        backgroundColor: 'inherit',
        height: '25px',
    }),
    placeholder: styles => ({
        ...styles,
        color: 'color.500',
        fontSize: '12px',
        lineHeight: '17px',
    }),
    control: (styles, state) => ({
        ...styles,
        ...pseudoSelectors(
            'border',
            `1px solid${getThemeColor(theme, 'color.600')}`
        ),
        backgroundColor: getThemeColor(theme, 'blueWhite.900'),
        borderRadius: state.selectProps.menuIsOpen ? '6px 6px 0 0' : '6px',
        color: getThemeColor(theme, 'color.100'),
        minHeight: '32px',
        maxHeight: '32px',
        boxShadow: 'none',
        border: `1px solid ${getThemeColor(theme, 'color.875')}`,
    }),
    input: styles => ({
        ...styles,
        color: getThemeColor(theme, 'color.100'),
        fontSize: '12px',
        lineHeight: '17px',
    }),
    singleValue: styles => ({
        ...styles,
        color: getThemeColor(theme, 'color.100'),
        fontSize: '14px',
        lineHeight: '17px',
    }),
    option: (styles, state) => ({
        ...styles,
        ...pseudoSelectors(
            'backgroundColor',
            getThemeColor(theme, 'blueWhite.910')
        ),
        backgroundColor: state.isSelected
            ? getThemeColor(theme, 'blueWhite.970')
            : getThemeColor(theme, 'blueWhite.950'),
        color: getThemeColor(theme, 'color.100'),
        fontSize: '14px',
        lineHeight: '17px',
    }),
})
