import { Box } from '@chakra-ui/layout'
import { GradientCellData } from 'components/DateRangePicker/types'
import React, { FC } from 'react'

interface GradientCellProps {
    cellData: GradientCellData
}

export const GradientCell: FC<GradientCellProps> = ({ cellData }) => {
    const gradient =
        cellData.direction === 'left'
            ? 'linear-gradient(90deg, rgba(177, 177, 177, 0) 0%, #B1B1B1 100%)'
            : 'linear-gradient(90deg, #B1B1B1 0%, rgba(177, 177, 177, 0) 100%)'

    return (
        <Box width="32px" height="32px">
            <Box
                width="32px"
                height="28px"
                background={gradient}
                margin="2px 0px"
            />
        </Box>
    )
}
