import React, { FC } from 'react'
import { NodeBox, NodeBoxMedium, NodeName } from './styles'
import { ParameterInput, ParameterInputProps } from './ParameterInput'

export const NodeParameter: FC<ParameterInputProps> = props => {
    switch (props.type) {
        case 'bool':
            return (
                <NodeBoxMedium
                    key={props.id}
                    display="flex"
                    alignSelf="flex-end"
                    marginBottom="6px"
                >
                    <ParameterInput {...props} />
                    <NodeName>{props.description}</NodeName>
                </NodeBoxMedium>
            )
        case 'toggle':
            return (
                <NodeBoxMedium key={props.id}>
                    <NodeName>{props.description}</NodeName>
                    <ParameterInput {...props} />
                </NodeBoxMedium>
            )
        default:
            return (
                <NodeBox key={props.id}>
                    <NodeName>{props.description}</NodeName>
                    <ParameterInput {...props} />
                </NodeBox>
            )
    }
}
