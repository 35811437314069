import React, { FC } from 'react'
import { Button, ButtonGroup } from '@chakra-ui/react'
import { CellProps } from 'react-table'

const ActionsCell: FC<CellProps<any>> = ({
    editingRows,
    onCancelEdit,
    row,
    onEdit,
    onSave,
    openDeleteModal,
}): JSX.Element => {
    if (editingRows[row.id]) {
        return (
            <ButtonGroup variant="outline">
                <Button
                    size="xs"
                    mr="1"
                    onClick={_props => {
                        onCancelEdit(row.id, row)
                    }}
                >
                    Cancel
                </Button>
                <Button
                    size="xs"
                    mr="1"
                    onClick={_props => {
                        openDeleteModal(row.id)
                    }}
                >
                    Delete
                </Button>
                <Button
                    size="xs"
                    mr="1"
                    onClick={_props => {
                        onSave(row.id)
                    }}
                >
                    Save
                </Button>
            </ButtonGroup>
        )
    }
    return (
        <Button
            size="xs"
            variant="ghost"
            colorScheme="blue"
            onClick={_props => {
                onEdit(row.id)
            }}
        >
            Edit
        </Button>
    )
}

export default ActionsCell
