import {
    IntegrationChannelsResponse,
    OrderFlows,
    PagingFilters,
} from '../types/api'
import { orderFlowsInstance, tenantsInstance } from './instances'
import { IntegrationChannelTableView } from '../types/core'

export class IntegrationChannelsApi {
    static async getIntegrationChannel(
        tenantId: string,
        { page, size }: PagingFilters
    ): Promise<IntegrationChannelsResponse> {
        const response = await tenantsInstance.get<IntegrationChannelsResponse>(
            `/${tenantId}/integration-channels-v2?page=${page}&size=${size}`
        )
        return response.data
    }

    static async updateIntegrationChannel(
        data: IntegrationChannelTableView
    ): Promise<IntegrationChannelsResponse> {
        const preparedData = this.prepareIntegrationChannelData(data)

        const response = await tenantsInstance.put<IntegrationChannelsResponse>(
            `/${data.tenantId}/integration-channels-v2/${data.id}`,
            preparedData
        )
        return response.data
    }

    static async createIntegrationChannel(
        tenantId: string,
        data: IntegrationChannelTableView
    ): Promise<IntegrationChannelsResponse> {
        const preparedData = this.prepareIntegrationChannelData(data)

        const response =
            await tenantsInstance.post<IntegrationChannelsResponse>(
                `/${tenantId}/integration-channels-v2`,
                preparedData
            )
        return response.data
    }

    static async removeIntegrationChannel(data: IntegrationChannelTableView) {
        const response = await tenantsInstance.delete(
            `/${data.tenantId}/integration-channels-v2/${data.id}`
        )

        return response.data
    }

    static async getOrderFlows(): Promise<OrderFlows[]> {
        const response = await orderFlowsInstance.get<OrderFlows[]>('')

        return response.data
    }

    static prepareIntegrationChannelData(data: IntegrationChannelTableView) {
        const routingRule = data.routingRule?.location

        const defaultMappingConfiguration = data.mapperId !== 'Proxy' ? { type: 'Mapper', mapperId: data.mapperId, } : { type: 'Proxy', mapperId: null, }

        const defaultChannelData = {
            name: data.name,
            type: data.type,
            resourceType: data.resourceType,
            relativeUrl: data.relativeUrl,
            downstreamURL: data.downstreamUrl,
            httpMethods: data.httpMethods,
            authenticationSchemeId: data.authenticationSchemeId,
            mappingConfiguration: defaultMappingConfiguration,
        }

        const channelDataWithRoutingRule = {
            ...defaultChannelData,
            routingRule: {
                ...data.routingRule,
                ignoreCase: !!data.routingRule?.ignoreCase,
            },
        }

        return routingRule ? channelDataWithRoutingRule : defaultChannelData
    }
}
