import { Box, Flex, Text, Grid } from '@chakra-ui/layout'
import { Tooltip, GridItem, Center } from '@chakra-ui/react'
import { chakra } from 'utils/chakra'
import { SimpleArrowDownIcon, SimpleArrowRightIcon } from 'icons'

export const DetailsInfoBox = chakra(Box, {})

export const GridStyled = chakra(Grid, {
    gap: 5,
    width: "100%",
    height: 'fit-content',
    templateColumns: 'repeat(22, 1fr)',
})

export const AccordionButtonBox = chakra(Flex, {
    width: '100%',
    height: 'fit-content',
    minHeight: '48px',
    backgroundColor: 'blueWhite.950',
    borderRadius: '6px',
    border: '1px solid',
    borderColor: 'blueWhite.25',
    alignItems: 'center',
    cursor: 'auto',
    padding: '2px 15px 2px 5px',
    textAlign: 'start',
})

export const RectangleIconBox = chakra(Box, {
    position: 'relative',
    right: '5px',
})

export const AccordionButtonItem = chakra(GridItem, {
    alignSelf: 'center',
    colSpan: 3,
})

export const PurchaseOrder = chakra(Text, {
    variant: 'paragraph',
    color: 'blue.300',
})

export const ArrowDownIconStyled = chakra(SimpleArrowDownIcon, {
    boxSize: '15px',
    color: 'mintgreen.600',
    marginRight: '5px',
    alignSelf: 'center',
})

export const ArrowRightIconStyled = chakra(SimpleArrowRightIcon, {
    boxSize: '15px',
    color: 'mintgreen.600',
    marginRight: '5px',
    alignSelf: 'center',
})

export const DeliveryDate = chakra(Text, {
    variant: 'paragraph',
    alignSelf: 'center',
})

export const BackgroundBlock = chakra(Flex, {
    position: 'relative',
    minHeight: '22px',
    backgroundColor: 'grayBlue.990',
    borderRadius: '6px',
    padding: '5px 12px',
    maxWidth: 'fit-content',
})

export const DistributionSource = chakra(Text, {
    variant: 'supertitlebold',
    display: 'flex',
})

export const AccordionPanelBox = chakra(Flex, {
    justifyContent: 'space-between',
})

export const LeftBox = chakra(Box, {
    margin: '0 20px',
    width: '35%',
})

export const HeadingBox = chakra(Flex, {
    width: '100%',
    justifyContent: 'space-between',
    margin: '13px 0 8px 0',
})

export const Details = chakra(Text, {
    variant: 'h5',
    alignSelf: 'center',
})

export const DetailsBox = chakra(Flex, {
    margin: '12px 0',
    justifyContent: 'space-between',
})

export const DistSourceNumberBox = chakra(Flex, {
    margin: '12px 0 22px 0',
    justifyContent: 'space-between',
})

export const InfoBox = chakra(Flex, {
    flexDirection: 'column',
    width: '100px',
    textAlign: 'start',
})

export const HeadingText = chakra(Text, {
    variant: 'paragraphsmall',
    color: 'color.400',
})

export const InfoText = chakra(Text, {
    variant: 'paragraph',
    textTransform: 'capitalize',
})

export const RightBox = chakra(Box, {
    margin: '20px',
    width: '55%',
    alignSelf: 'center',
})

export const AccordionTitleBox = chakra(Flex, {
    width: '100%',
    height: 'fit-content',
    backgroundColor: 'blueWhite.990',
    borderRadius: '6px',
    display: 'flex',
    padding: '2px 15px 2px 15px',
    alignItems: 'center',
    cursor: 'auto',
})

export const AccordionHeaderItem = chakra(Text, {
    textAlign: 'left',
    variant: 'tableHeader',
})

export const AccordionHeaderRetryBox = chakra(Text, {
    textAlign: 'center',
    variant: 'paragraphsmall',
})

export const CustomTooltip = chakra(Tooltip, {
    position: 'relative',
    _after: {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: '50%',
        transform: 'translate(-50%, 100%)',
        width: '100%',
        height: '10px',
    },
})

export const ExternalSource = chakra(Text, {
    variant: "h5Light"
})

export const CenterTooltipStatus = chakra(Center, {
    justifyContent: "flex-start",
    width: "100px"
})
