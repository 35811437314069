import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const FilterIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 10 6"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M4 6H6V5H4V6ZM0.5 0V1H9.5V0H0.5ZM2 3.5H8V2.5H2V3.5Z"
                fill="currentColor"
            />
        </Icon>
    )
}
