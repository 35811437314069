import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { observer } from 'mobx-react-lite'
import { Column } from 'react-table'
import { Box, Flex } from '@chakra-ui/layout'
import { Button, useDisclosure } from '@chakra-ui/react'
import { toJS } from 'mobx'
import { IAuthApiKey } from 'types/api'
import { AuthApiKey } from 'api/authApiKey'
import { StatusType } from 'types/chart'
import {
    authApiKeyStore,
    notificationStore,
    secretsStore,
    tenantsStore,
} from 'stores'
import AuthApiKeyInputs from 'components/TableComponents/TableModal/ModalFormInputs/AuthApiKeyInputs'
import EditableTable, { EditingRows } from 'components/TableComponents/Table'
import { NumericPagination } from 'components/NumericPagination'
import ActionsCell from 'components/TableComponents/ActionsCell'
import CustomModal from 'components/TableComponents/TableModal'
import { ConfirmModal } from 'components/Modal/ConfirmModal'
import { errorObjTransformString } from 'utils/objectTransform'

interface IEditingRowsData {
    name: string
    apiKey: string
}

const editRowsData = {
    name: '',
    apiKey: '',
}

export const AdminAuthApiKeyPage = observer(() => {
    const customToast = notificationStore.toast
    const [editingRows, setEditingRows] = useState<EditingRows>({})
    const [editingRowsData, setEditingRowsData] = useState<IEditingRowsData>(editRowsData)
    const [deleteRowIndex, setDeleteRowIndex] = useState<number>(0)
    const data: IAuthApiKey[] = toJS(authApiKeyStore.data)
    const { tenantId } = tenantsStore

    const { isOpen, onOpen, onClose } = useDisclosure()
    const {
        isOpen: isOpenDelete,
        onOpen: onOpenDelete,
        onClose: onCloseDelete,
    } = useDisclosure()

    const {
        handleSubmit,
        register,
        reset,
        formState: { errors, isSubmitting },
    } = useForm()

    const columns: Column[] = useMemo(
        () => [
            {
                Header: 'Tenant Id',
                accessor: 'tenantId',
            },
            {
                Header: 'Name',
                accessor: 'name',
                editable: true,
            },
            {
                Header: 'Api key',
                accessor: 'apiKey',
                editable: true,
            },
            {
                Header: 'Actions',
                accessor: 'edit',
                Cell: ActionsCell,
            },
        ],
        []
    )

    useEffect(() => {
        tenantId && authApiKeyStore.fetch(tenantId)

        return () => {
            authApiKeyStore.clean()
        }
    }, [tenantId])

    const onEdit = (id: number): void => {
        if (id) {
            setEditingRows({ [id]: true })
            setEditingRowsData({
                name: data[id].name,
                apiKey: data[id].apiKey,
            })
        }
    }

    const onCancelEdit = (id: number | string): void => {
        updateMyData(+id, 'name', editingRowsData.name)
        updateMyData(+id, 'apiKey', editingRowsData.apiKey)
        setEditingRows({ [id]: false })
    }

    const onSubmit = (data: IAuthApiKey) => {
        AuthApiKey.createAuthApiKey(tenantId, data)
            .then(() => {
                authApiKeyStore.fetch(tenantId)
                secretsStore.fetchApiKeys(tenantId)
                onClose()
                reset()
            })
            .catch(error => {
                if (error.response?.status === 400) {
                    customToast({
                        title: 'Error',
                        description: errorObjTransformString(error.response.data.errors),
                        status: StatusType.Error,
                    })
                } else if (error.response?.status === 409) {
                    customToast({
                        title: 'Error',
                        description: 'Name already exists',
                        status: StatusType.Error,
                    })
                } else {
                    customToast({
                        title: 'Error',
                        description: 'Something went wrong.',
                        status: StatusType.Error,
                    })
                }
            })
    }

    const onSave = (rowIndex: number): void => {
        AuthApiKey.updateAuthApiKey(data[rowIndex])
            .then(() => {
                setEditingRows({ [rowIndex]: false })
                secretsStore.fetchApiKeys(tenantId)
            })
            .catch(error => {
                if (error.response?.status === 400) {
                    customToast({
                        title: 'Error',
                        description: errorObjTransformString(error.response.data.errors),
                        status: StatusType.Error,
                    })
                } else if (error.response?.status === 409) {
                    customToast({
                        title: 'Error',
                        description: 'Name already exists',
                        status: StatusType.Error,
                    })
                } else {
                    customToast({
                        title: 'Error',
                        description: 'Something went wrong.',
                        status: StatusType.Error,
                    })
                }
            })
    }

    const updateMyData = (
        rowIndex: number,
        columnId: string,
        value: string
    ): void => {
        authApiKeyStore.updateAuthApiKey(rowIndex, columnId, value)
    }

    const onDeleteRow = () => {
        AuthApiKey.removeAuthApiKey(data[deleteRowIndex])
            .then(() => {
                onCancelEdit(deleteRowIndex)
                onCloseDelete()
                authApiKeyStore.fetch(tenantId)
            })
            .catch(error => {
                if (error.response?.status === 400) {
                    customToast({
                        title: 'Error',
                        description: error?.response.data,
                        status: StatusType.Error,
                    })
                }
                else {
                    customToast({
                        title: 'Error',
                        description: 'Something went wrong.',
                        status: StatusType.Error,
                    })
                }
            })
    }

    const openDeleteModal = (rowIndex: number) => {
        setDeleteRowIndex(rowIndex)
        onOpenDelete()
    }

    return (
        <div>
            <Box padding="31px 0">
                <Flex color="white" overflowY={'scroll'}>
                    <Box flex="1">
                        <EditableTable
                            data={data}
                            columns={columns}
                            editingRows={editingRows}
                            setEditingRows={setEditingRows}
                            updateMyData={updateMyData}
                            onEdit={onEdit}
                            onSave={onSave}
                            onCancelEdit={onCancelEdit}
                            openDeleteModal={openDeleteModal}
                        />
                    </Box>
                </Flex>
            </Box>
            <Button
                size="sm"
                variant="outline"
                colorScheme="blue"
                onClick={onOpen}
            >
                Add
            </Button>
            <CustomModal
                modalTitle={'Create Auth Api Key'}
                isOpen={isOpen}
                onClose={onClose}
                onSubmit={onSubmit}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
            >
                <AuthApiKeyInputs errors={errors} register={register} />
            </CustomModal>
            <NumericPagination
                marginTop="24px"
                pages={authApiKeyStore.pages}
                activePage={authApiKeyStore.filters.page}
                onChangePage={page => {
                    authApiKeyStore.setFilters({ page })
                    authApiKeyStore.fetch(tenantId)
                }}
            />
            <ConfirmModal
                isOpen={isOpenDelete}
                onClose={onCloseDelete}
                confirmFunc={onDeleteRow}
                headerText="Delete Auth Api Key"
                bodyText="Are you sure?"
                buttonText="Delete"
                colorButton="red.500"
            />
        </div>
    )
})
