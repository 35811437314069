import { Flex, Grid, Text } from '@chakra-ui/layout'
import { Editable } from '@chakra-ui/editable'
import { Accordion } from '@chakra-ui/accordion'
import { Button } from '@chakra-ui/button'
import { chakra } from 'utils/chakra'

export const EditableStyled = chakra(Editable, {
    color: 'color.100',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '16px',
    marginBottom: '30px',
})

export const PaletteBox = chakra(Flex, {
    backgroundColor: 'blueWhite.960',
    borderRadius: '10px',
    padding: '15px 9px',
    cursor: 'pointer',
    wordBreak: 'break-all',
})

export const GridStyled = chakra(Grid, {
    backgroundColor: 'color.900',
    height: '100%',
    gap: '16px',
    templateRows: 'min-content 1fr',
})

export const ResultsForText = chakra(Text, {
    variant: 'paragraphsmall',
    textAlign: 'center',
    margin: '12px 0',
    maxWidth: '160px',
})

export const ClearSearchButton = chakra(Button, {
    variant: 'unstyled',
    borderRadius: '20px',
    padding: '0px 5px',
    height: '20px',
    fontWeight: 'medium',
    fontSize: '12px',
    lineHeight: '16px',
    color: 'color.100',
    _hover: {
        border: '1px solid',
        borderColor: 'color.100',
    },
})

export const AccordionStyled = chakra(Accordion, {
    variant: 'unstyled',
    overflow: 'auto',
    height: '100%',
    padding: '0px 8px 8px 8px',
    sx: {
        '&::-webkit-scrollbar': { width: '6px' },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'scrollbar.75',
            borderRadius: '8px',
            minHeight: '56px',
        },
    },
})
