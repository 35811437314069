import React, { FC } from 'react'
import { RetryParentCheckbox } from 'components/RetryCheckbox/ParentCheckbox'
import { retryOption } from 'pages/HomePage/mock'
import {
    AccordionButtonItem,
    AccordionTitleBox,
    AccordionHeaderItem,
    AccordionHeaderRetryBox,
    GridStyled,
} from '../styles'

interface IOptions {
    title: string
    colSpan: number
}

interface IAccordionsHeader {
    options: IOptions[]
    setRetryOrders?: React.Dispatch<React.SetStateAction<string[]>>
    isFiltersOpen?: boolean
    isSplitPanel?: boolean
}

export const AccordionsHeader: FC<IAccordionsHeader> = ({
    options,
    setRetryOrders = () => [],
    isFiltersOpen,
    isSplitPanel
}) => {
    const headerOptions = !!isFiltersOpen && !isSplitPanel ? [...options, retryOption] : options

    return (
        <AccordionTitleBox>
            <GridStyled>
                {headerOptions.map(({ title, colSpan }) => (
                    title === 'Retry' ? (
                        <AccordionButtonItem
                            key={title}
                            textAlign="left"
                            colSpan={colSpan}
                        >
                            <AccordionHeaderRetryBox>
                                <RetryParentCheckbox setRetryOrders={setRetryOrders} />
                            </AccordionHeaderRetryBox>
                        </AccordionButtonItem>
                    ) : (
                        <AccordionButtonItem
                            key={title}
                            textAlign="left"
                            colSpan={colSpan}
                        >
                            <AccordionHeaderItem>
                                {title}
                            </AccordionHeaderItem>
                        </AccordionButtonItem>
                    )
                ))}
            </GridStyled>
        </AccordionTitleBox>
    )
}
