import { makeAutoObservable } from 'mobx'
import { CustomeAttributeAPI } from 'api/customAttribute'
import { PagingFilters } from 'types/api'
import {
    IEditCategoryItemDetails,
    ISaveCategoryReqObj,
    UUID,
    ISelectOption,
    ICreateCategorySource,
    IAttributeRequest,
    ICategory,
    IFieldAttributeList,
    ISaveAttributeList,
    IselectCategoryOption,
    IAttributeSource,
} from 'types/core'
import { notificationStore } from 'stores'
import { StatusType } from 'types/chart'

interface IConfigurationFilters {
    page: number
    size: number
    name: string
}

const defaultFilters: IConfigurationFilters = {
    page: 1,
    size: 10,
    name: '',
}

export class CustomeAttributeStore {
    public editCategoryItemData: IEditCategoryItemDetails =
        {} as IEditCategoryItemDetails
    public categorySoucesList: ISelectOption[] = []
    public selectCategoryItems: IselectCategoryOption[] = []
    public attributeListItem: IFieldAttributeList[] = []
    public categoryList: IAttributeSource[] = []
    public filters: IConfigurationFilters = defaultFilters
    public pages: number = 0
    public errorMessage: unknown | string = ''

    constructor() {
        makeAutoObservable(this)
    }

    public async attrributeCategoryList() {
        try {
            const { items } = await CustomeAttributeAPI.getAttributeCategory()
            this.categorySelect(items)
        } catch (err) {
            this.selectCategoryItems = []
        }
    }

    private async categorySelect(data: ICategory[]) {
        this.selectCategoryItems = []
        data?.forEach((item: ICategory) =>
            this.selectCategoryItems.push({
                id: item?.attributeSourceName,
                value: item?.categoryId,
                label: item?.categoryName,
            })
        )
    }

    public async getAttributeListItem(category: IAttributeRequest) {
        try {
            const { items, totalPages } =
                await CustomeAttributeAPI.attributeListItem(
                    category?.label,
                    category?.id,
                    this.filters?.name,
                    this.filters?.page,
                    this.filters?.size
                )
            this.initAttributeListItem(items)
            this.setUpPages(totalPages)
        } catch (err) {
            this.attributeListItem = []
        }
    }

    public async saveCategoty(reqObj: ISaveAttributeList) {
        try {
            const reposens = await CustomeAttributeAPI.saveAttributeConfig(
                reqObj
            )
            if (reposens === 'Success') {
                notificationStore.toast({
                    title: 'Success',
                    description: `Action Completed Successfully`,
                    status: StatusType.Success,
                })
            }
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    public async createCategorySources() {
        try {
            const { items } =
                await CustomeAttributeAPI.getCreateCategorySources()
            this.selectSourceName(items)
        } catch (err) {
            this.categorySoucesList = []
            notificationStore.triggerErrorToast(err)
        }
    }

    public selectSourceName(data: ICreateCategorySource[]) {
        this.categorySoucesList = []
        data &&
            data?.length &&
            data?.forEach(source =>
                this.categorySoucesList.push({
                    value: source.id,
                    label: source.name,
                })
            )
    }

    public async saveNewCatgeoryItem(reqObj: ISaveCategoryReqObj) {
        try {
            await CustomeAttributeAPI.saveNewCategory(reqObj)
            this.errorMessage = 'success'
        } catch (err) {
            this.errorMessage = err
            notificationStore.triggerErrorToast(err)
        }
    }

    public async search(): Promise<void> {
        try {
            const { items: categoryList = [], totalPages } =
                await CustomeAttributeAPI.getAll(
                    this.filters.page,
                    this.filters.size,
                    this.filters.name
                )
            this.init(categoryList)
            this.setUpPages(totalPages)
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    public async getEditableCategoryItemDetails(id: string) {
        try {
            const data = await CustomeAttributeAPI.getEditCategoryDetails(id)
            this.initEditcategoryItemDetails(data)
        } catch (err) {
            this.editCategoryItemData = {} as IEditCategoryItemDetails
            notificationStore.triggerErrorToast(err)
        }
    }

    public async updateCategoryItem(reqObj: { id: UUID; body: object }) {
        try {
            await CustomeAttributeAPI.saveEditCategoryItem(
                reqObj.id,
                reqObj.body
            )
            this.errorMessage = 'success'
        } catch (err) {
            this.errorMessage = err
            notificationStore.triggerErrorToast(err)
        }
    }

    private async initAttributeListItem(data: IFieldAttributeList[]) {
        this.attributeListItem = data
    }

    private async initEditcategoryItemDetails(data: IEditCategoryItemDetails) {
        this.editCategoryItemData = data
    }

    private init(categoryList: IAttributeSource[]): void {
        this.categoryList = categoryList
    }

    public async searchByName(
        value: string,
        shipper?: IAttributeRequest
    ): Promise<void> {
        this.initFilters({ ...this.filters, name: value })
        await this.search()
        if (shipper?.label !== '')
            await this.getAttributeListItem(shipper as IAttributeRequest)
    }

    private setUpPages(pages: number): void {
        this.pages = pages
    }

    public async setPages(
        filters: Partial<IConfigurationFilters>
    ): Promise<void> {
        if (!filters.page) {
            this.initFilters({ ...this.filters, ...filters, page: 1 })
        } else {
            this.initFilters({ ...this.filters, ...filters })
        }
        await this.search()
        await this.getAttributeListItem({} as IAttributeRequest)
    }

    private initFilters(filters: IConfigurationFilters): void {
        this.filters = filters
    }

    public setFilters(filters: Partial<PagingFilters>) {
        if (!filters.page) {
            this.filters = { ...this.filters, ...filters, page: 1 }
        } else {
            this.filters = { ...this.filters, ...filters }
        }
    }

    public clean() {
        this.editCategoryItemData = {} as IEditCategoryItemDetails
        this.attributeListItem = []
        this.errorMessage = ''
    }

    public reloadPages(){
        this.initFilters(defaultFilters)
    }
}
