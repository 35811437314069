import { Flex } from '@chakra-ui/layout'
import { chakra } from 'utils/chakra'

export const TenantsBox = chakra(Flex, {
    flexDirection: 'column',
    padding: '31px 0',
    width: '100%',
})

export const TreeBox = chakra(Flex, {
    flexDirection: 'column',
    flex: '1',
})

export const TabsBox = chakra(Flex, {
    flexDirection: 'column',
    borderLeft: '1px solid',
    flex: '2',
})


