import { Flex, Text } from '@chakra-ui/layout'
import { chakra } from 'utils/chakra'

export const CheckboxBox = chakra(Flex, {
    alignItems: 'center',
})

export const CheckboxTitle = chakra(Text, {
    variant: 'paragraphsmall',
    marginLeft: '4px',
})
