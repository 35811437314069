import React, { FC } from 'react'
import { SelectedDate } from './SelectedDate'
import { SelectedCheckboxes } from './SelectedCheckboxes'
import { SelectedNumber } from './SelectedNumber'
import { IOrderFilters } from 'types/api'
import { isValidDate } from 'pages/HomePage/content/OrderStatusTracking/Filters/utils'
import { SelectedFiltersBox } from './styles'

interface ISelectedFilters {
    filter: IOrderFilters
    isSavedFilters: boolean
}

export const SelectedFilters: FC<ISelectedFilters> = ({ filter, isSavedFilters }) => {
    const {
        createdFrom,
        createdTo,
        deliveryFrom,
        deliveryTo,
        statuses = [],
        ShipmentProviderCodes = [],
        orderSourceIds = [],
        orderNumber,
    } = filter

    return (
        <SelectedFiltersBox>
            {isValidDate(createdFrom, createdTo) && (
                <SelectedDate
                    from={createdFrom}
                    to={createdTo}
                    filterName="created"
                    isSavedFilters={isSavedFilters}
                    orderNumber={orderNumber}
                />
            )}
            {isValidDate(deliveryFrom, deliveryTo) && (
                <SelectedDate
                    from={deliveryFrom}
                    to={deliveryTo}
                    filterName="delivery"
                    isSavedFilters={isSavedFilters}
                    orderNumber={orderNumber}
                />
            )}
            {!!statuses.length && (
                <SelectedCheckboxes
                    filterValue="status"
                    filterName="status"
                    isSavedFilters={isSavedFilters}
                    statuses={statuses}
                    ShipmentProviderCodes={ShipmentProviderCodes}
                    orderSourceIds={orderSourceIds}
                    orderNumber={orderNumber}
                />
            )}
            {!!ShipmentProviderCodes.length && (
                <SelectedCheckboxes
                    filterValue="distributionSource"
                    filterName="distribution source"
                    isSavedFilters={isSavedFilters}
                    statuses={statuses}
                    ShipmentProviderCodes={ShipmentProviderCodes}
                    orderSourceIds={orderSourceIds}
                    orderNumber={orderNumber}
                />
            )}
            {!!orderSourceIds.length && (
                <SelectedCheckboxes
                    filterValue="externalSource"
                    filterName="external source"
                    isSavedFilters={isSavedFilters}
                    statuses={statuses}
                    ShipmentProviderCodes={ShipmentProviderCodes}
                    orderSourceIds={orderSourceIds}
                    orderNumber={orderNumber}
                />
            )}
            {!!orderNumber && (
                <SelectedNumber
                    isSavedFilters={isSavedFilters}
                    orderNumber={orderNumber}
                />
            )}
        </SelectedFiltersBox>
    )
}
