import React, { useEffect } from 'react'
import { FormControl, FormErrorMessage } from '@chakra-ui/form-control'
import { useNavigate, useParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { Button } from '@chakra-ui/button'
import { observer } from 'mobx-react-lite'
import {
    Box,
    Divider,
    Flex,
    Grid,
    Heading,
    Text,
    Center,
} from '@chakra-ui/layout'
import { Radio, RadioGroup } from '@chakra-ui/radio'
import { RuleHeaderButton } from 'components/diagrams/EditorSidebar/content/RuleHeaderButton'
import { CounteredTextarea } from 'components/CounteredTextarea'
import { DiagramView } from 'components/diagrams/DiagramView'
import { CanvasHeader } from 'components/CanvasHeader'
import { approvalStore, dashboardStore, notificationStore } from 'stores'
import { routes } from 'navigation/routes'
import { StatusType } from 'types/chart'
import { ArrowRightIcon } from 'icons'
import {
    PageGrid,
    SidebarContainer,
    SidebarBox,
    RuleHeaderButtonStack,
    EditorBox,
} from './styles'

const MESSAGE_BOUND = 500

interface ApprovalForm {
    action: 'approve' | 'reject'
    message: string
}

export const ApprovalPage = observer(() => {
    const { id } = useParams<'id'>()
    const customToast = notificationStore.toast
    const navigate = useNavigate()

    const {
        register,
        handleSubmit,
        watch,
        trigger,
        control,
        formState: { errors },
    } = useForm<ApprovalForm>({
        mode: 'onChange',
        reValidateMode: 'onChange',
    })

    const submitterMessageRemaining =
        MESSAGE_BOUND - watch('message', '').length
    const isApprove = watch('action') === 'approve'
    const workflow = approvalStore.workflowStore.workflow

    useEffect(() => {
        if (isApprove) {
            trigger('message')
        }
    }, [isApprove, trigger])

    useEffect(() => {
        if (id) {
            approvalStore.init(id)
        }

        return () => {
            approvalStore.clean()
        }
    }, [id])

    const onNextStep = async (formData: ApprovalForm) => {
        if (!workflow) return

        const creatorName = workflow.createdByUser ? `${workflow.createdByUser.firstName} ${workflow.createdByUser.lastName}` : '-'

        try {
            if (formData.action === 'approve') {
                await approvalStore.approve(formData.message)
                dashboardStore.notify(
                    `You have approved ${creatorName}’s rule “${workflow.name}”`
                )
            } else {
                await approvalStore.reject(formData.message)
                dashboardStore.notify(
                    `You have rejected ${creatorName}’s rule “${workflow.name}”`
                )
            }
            navigate(routes.home)
        } catch (err) {
            customToast({
                title: 'Info',
                description: JSON.stringify(err),
                status: StatusType.Info,
            })
        }
    }

    return (
        <Box height="95vh">
            <PageGrid>
                <SidebarBox>
                    <Grid height="100%" templateRows="min-content 1fr">
                        <RuleHeaderButtonStack>
                            {!!approvalStore.workflowStore.workflow && (
                                <RuleHeaderButton
                                    readonly={true}
                                    name={workflow?.name || ''}
                                    description={workflow?.description || ''}
                                />
                            )}
                        </RuleHeaderButtonStack>
                        <SidebarContainer>
                            <Text variant="h4" marginBottom="8px">
                                Approve/Reject {'&'} Comment
                            </Text>
                            <Divider marginBottom="33px" />

                            <Controller
                                control={control}
                                name="action"
                                rules={{
                                    required: 'You must approve or reject',
                                }}
                                render={({ field }) => (
                                    <FormControl
                                        marginBottom="33px"
                                        isInvalid={!!errors.action}
                                    >
                                        <RadioGroup size="sm" {...field}>
                                            <Radio value="approve">
                                                <Text color="color.100">
                                                    Approve
                                                </Text>
                                            </Radio>
                                            <Radio value="reject">
                                                <Text color="color.100">
                                                    Reject {'&'} Return
                                                </Text>
                                            </Radio>
                                        </RadioGroup>
                                        <FormErrorMessage
                                            fontSize="12px"
                                            lineHeight="14px"
                                        >
                                            {errors?.action?.message}
                                        </FormErrorMessage>
                                    </FormControl>
                                )}
                            />
                            <Text variant="supertitle" marginBottom="8px">
                                Message for Submitter{' '}
                                {isApprove && '(optional)'}
                            </Text>
                            <Box flex="1" maxHeight="300px">
                                <CounteredTextarea
                                    resize="none"
                                    size="sm"
                                    placeholder="Enter message..."
                                    remaining={submitterMessageRemaining}
                                    height="100%"
                                    {...register('message', {
                                        required: isApprove
                                            ? false
                                            : 'Message is required',
                                        maxLength: {
                                            value: MESSAGE_BOUND,
                                            message: `This field max length is ${MESSAGE_BOUND}`,
                                        },
                                    })}
                                    isInvalid={!!errors.message}
                                    description={errors.message?.message}
                                />
                            </Box>
                        </SidebarContainer>
                    </Grid>
                </SidebarBox>
                <EditorBox>
                    <Box marginBottom="8px">
                        <Text variant="supertitle">Approve Rule</Text>
                        <Heading variant="h2">Logic Canvas</Heading>
                    </Box>
                    <CanvasHeader>
                        {workflow?.name}
                    </CanvasHeader>
                    <Center
                        padding="0 30px"
                        backgroundColor="color.810"
                    >
                        <Divider variant="editor" />
                    </Center>
                    {!!workflow && <DiagramView id={workflow.id} />}
                </EditorBox>
                <Box gridArea="footer">
                    <Flex direction="row-reverse">
                        <Button
                            variant="blue"
                            rightIcon={<ArrowRightIcon boxSize="9px" color="white" />}
                            onClick={handleSubmit(onNextStep)}
                        >
                            Submit
                        </Button>
                    </Flex>
                </Box>
            </PageGrid>
        </Box>
    )
})
