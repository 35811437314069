import { Button, ButtonGroup } from '@chakra-ui/button'
import { Box, Center, Flex, Grid, Spacer, Text } from '@chakra-ui/layout'
import { AngleIcon } from 'icons'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { FC } from 'react'
import { useRangePickerContext } from '../context'
import { dateRangeStore } from '../store'
import { Calendar } from './Calendar'
import { YearsBody } from './YearsBody'
import { ButtonStyled, CenterCalenderBodyStyled, CalenderBodyFlexStyled } from '../../styles'

interface CalendarBodyProps {
    onClose: () => void
    onRightYear: () => void
    onLeftYear: () => void
    selectDate: (date: Date) => void
    clearAllDates: () => void
    showLeftYears: boolean
    showRightYears: boolean
    isDateRangePicker: boolean
}

export const CalendarBody: FC<CalendarBodyProps> = observer(
    ({
        isDateRangePicker,
        onClose,
        onLeftYear,
        onRightYear,
        clearAllDates,
        selectDate,
        showLeftYears,
        showRightYears,
    }) => {
        const { onChangeRange } = useRangePickerContext()

        const leftMonth = toJS(dateRangeStore.leftMonth)
        const rightMonth = toJS(dateRangeStore.rightMonth)

        const nextMonth = () => dateRangeStore.nextMonth()
        const previousMonth = () => dateRangeStore.prevMonth()
        const leftMonthText = dateRangeStore.leftMonthData.name
        const leftYearText = dateRangeStore.leftMonthData.year
        const rightMonthText = dateRangeStore.rightMonthData.name
        const rightYearText = dateRangeStore.rightMonthData.year

        return (
            <>
                <Grid templateColumns="1fr 1fr" gap="40px">
                    {!showLeftYears && (
                        <Box>
                            <Flex>
                                <Center onClick={onLeftYear}>
                                    <Text>
                                        {`${leftMonthText} ${leftYearText}`}
                                    </Text>
                                </Center>
                                <CenterCalenderBodyStyled
                                    flex="1"
                                    role="button"
                                    onClick={previousMonth}
                                >
                                    <AngleIcon direction="left" boxSize="8px" />
                                </CenterCalenderBodyStyled>
                                <CenterCalenderBodyStyled
                                    role="button"
                                    onClick={nextMonth}
                                >
                                    <AngleIcon
                                        direction="right"
                                        boxSize="8px"
                                    />
                                </CenterCalenderBodyStyled>
                            </Flex>
                            <Calendar
                                selectDate={selectDate}
                                cells={leftMonth}
                            />
                        </Box>
                    )}
                    {showLeftYears && (
                        <Box>
                            <YearsBody
                                onYearClick={onLeftYear}
                                direction="Left"
                            />
                        </Box>
                    )}
                    {isDateRangePicker && (
                        <>
                            {!showRightYears && (
                                <Box>
                                    <Flex>
                                        <Center onClick={onRightYear}>
                                            <Text>
                                            {`${rightMonthText} ${rightYearText}`}
                                            </Text>
                                        </Center>
                                        <CenterCalenderBodyStyled
                                            flex="1"
                                            role="button"
                                            onClick={previousMonth}
                                        >
                                            <AngleIcon
                                                direction="left"
                                                boxSize="8px"
                                            />
                                        </CenterCalenderBodyStyled>
                                        <CenterCalenderBodyStyled
                                            role="button"
                                            onClick={nextMonth}
                                        >
                                            <AngleIcon
                                                direction="right"
                                                boxSize="8px"
                                            />
                                        </CenterCalenderBodyStyled>
                                    </Flex>
                                    <Calendar
                                        selectDate={selectDate}
                                        cells={rightMonth}
                                    />
                                </Box>
                            )}
                            {showRightYears && (
                                <Box>
                                    <YearsBody
                                        onYearClick={onRightYear}
                                        direction="Right"
                                    />
                                </Box>
                            )}
                        </>
                    )}
                </Grid>
                <CalenderBodyFlexStyled>
                    <Spacer />
                    <ButtonGroup gap="2">
                        <Button
                            size="xs"
                            variant="outline"
                            onClick={() => {
                                clearAllDates()
                                dateRangeStore.clearAll()
                                onChangeRange(dateRangeStore.getRangeResult())
                            }}
                        >
                            Clear All
                        </Button>
                        <ButtonStyled size="xs" onClick={onClose}>
                            Apply Filters
                        </ButtonStyled>
                    </ButtonGroup>
                </CalenderBodyFlexStyled>
            </>
        )
    }
)
