import { ModalBody, ModalHeader, Divider } from '@chakra-ui/react'
import { Text } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/button'
import { CrossSmallIcon } from 'icons'
import { chakra } from 'utils/chakra'

export const ModalBodyStyled = chakra(ModalBody, {
    padding: '0 25px',
    variant: 'modal',
    backgroundColor: 'blueWhite.999',
    color: 'color.100',
    textAlign: 'center',
    marginBottom: '16px',
    sx: {
        '&::-webkit-scrollbar': {
            width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'scrollbar.75',
                borderRadius: '8px',
                maxHeight: '56px',
        }
    }
})

export const ModalCloseButton = chakra(Button, {
    variant: 'modal',
    color: 'color.100',
    _hover: {
        background: 'color.150'
    }
})

export const ModalHeaderText = chakra(Text, {
    variant: 'h3',
})

export const CrossSmallIconStyled = chakra(CrossSmallIcon, {
    role: 'button',
    cursor: 'pointer',
    boxSize: '16px',
    color: 'color.100',
})

export const ModalHeaderStyled = chakra(ModalHeader, {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '10px',
})

export const DividerStyled = chakra(Divider, {
    width: '95%',
    alignSelf: 'center',
    marginBottom: '16px',
})
