import React, { FC } from 'react'
import { FormControl } from '@chakra-ui/form-control'
import { InputProps, InputGroup, InputLeftAddon } from '@chakra-ui/input'
import { Flex, Spacer } from '@chakra-ui/layout'
import { InputStyled, TextStyled } from './styles'

interface CounteredInputWithAddonProps extends InputProps {
    description?: string
    remaining?: number
    addon: string
}

export const CounteredInputWithAddon: FC<CounteredInputWithAddonProps> = React.forwardRef<
    HTMLInputElement,
    CounteredInputWithAddonProps
>(({ description, remaining = 30, ...props }, ref) => {
    const isInvalid = props.isInvalid

    return (
        <FormControl
            width={props.width}
            height={props.height}
            isInvalid={isInvalid}
        >
            <InputGroup
                border={isInvalid ? '3px solid #FF5A43' : '1px solid borderInput.100'}
                borderRadius="8px"
                marginTop={props.marginTop}
            >
                <InputLeftAddon
                    children={props.addon}
                    height='auto'
                    borderRadius={isInvalid ? '5px 0 0 5px' : '7px 0 0 7px'}
                />
                <InputStyled
                    ref={ref}
                    borderRadius="0 8px 8px 0"
                    marginTop="0"
                    {...props}
                />
            </InputGroup>
            <Flex marginTop="9px">
                <TextStyled
                    color={isInvalid ? '#FF5A43' : 'color.100'}
                >
                    {description}
                </TextStyled>
                <Spacer />
                <TextStyled
                    color={isInvalid ? '#FF5A43' : 'color.100'}
                >
                    {remaining}
                </TextStyled>
            </Flex>
        </FormControl>
    )
})
