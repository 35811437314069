import { makeAutoObservable } from 'mobx'
import { notificationStore } from 'stores'
import { WowsAPI } from 'api/wows'
import { UUID, Wow } from 'types/core'

export class WowStore {
    public wow: Wow = {} as Wow

    constructor() {
        makeAutoObservable(this)
    }

    public async fetch(id: UUID): Promise<Wow> {
        try {
            const wow = await WowsAPI.getById(id)
            this.init(wow)
            return wow
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }

        return {} as Wow
    }

    public async changeWorkflows(wowId: UUID, workflows: UUID[]): Promise<void> {
        try {
            await WowsAPI.putWorkflowsById(workflows, wowId)
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    public async createSimulate(orderId: UUID, workflowIds: string[]): Promise<void> {
        try {
            await WowsAPI.simulate(orderId, workflowIds)
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    public async getById(wowId: UUID): Promise<void> {
        try {
            const wow = await WowsAPI.getById(wowId)
            this.init(wow)
        } catch (error) {
            notificationStore.triggerErrorToast(error)
        }
    }

    public async create(name: string, description: string) {
        return await WowsAPI.addNew(name, description)
    }

    public async edit(wowId: UUID, name: string, description: string) {
        return await WowsAPI.putById(wowId, name, description)
    }

    private init(wow: Wow): void {
        this.wow = wow
    }

    public clean(): void {
        this.wow = {} as Wow
    }
}
