import { tenantsInstance } from './instances'
import { AuthApiKeyResponse, IAuthApiKey, PagingFilters } from '../types/api'

export class AuthApiKey {
    static async getAuthApiKeys(
        tenantId: string,
        { page, size }: PagingFilters
    ): Promise<AuthApiKeyResponse> {
        const response = await tenantsInstance.get(
            `/${tenantId}/auth/api-keys?page=${page}&size=${size}`
        )

        return response.data
    }

    static async createAuthApiKey(
        tenantId: string,
        data: IAuthApiKey
    ): Promise<IAuthApiKey> {
        const response = await tenantsInstance.post<IAuthApiKey>(
            `/${tenantId}/auth/api-keys`,
            {
                name: data.name,
                apiKey: data.apiKey,
            }
        )

        return response.data
    }

    static async updateAuthApiKey(data: IAuthApiKey) {
        const response = await tenantsInstance.put(
            `/${data.tenantId}/auth/api-keys/${data.id}`,
            {
                name: data.name,
                apiKey: data.apiKey,
            }
        )

        return response.data || {}
    }

    static async removeAuthApiKey(data: IAuthApiKey) {
        const response = await tenantsInstance.delete(
            `/${data.tenantId}/auth/api-keys/${data.id}`
        )

        return response.data || {}
    }
}
