import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { observer } from 'mobx-react-lite'
import { Button } from '@chakra-ui/react'
import { Box } from '@chakra-ui/layout'
import { CheckboxStyled, SelectStyled, TextStyled } from './index'
import { CounteredInput } from 'components/CounteredInput'
import { options } from './mock'
import { getErrorMessage, getValidationSchema } from 'utils/getValidationSchema'
import { tenantsStore } from 'stores'
import { ITenantTree } from 'types/core'
import { SaveIcon } from 'icons'
import { DividerStyled, SaveButtonBox } from '../Tabs/style'

const RoutingRule = observer(() => {
    const selectedTenant = tenantsStore.selectedTenant

    const {
        register,
        control,
        reset,
        watch,
        formState: { errors },
        handleSubmit,
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: selectedTenant,
    })
    const routingRuleType = watch('location')

    const onSubmit = async (values: ITenantTree) => {
        await tenantsStore.saveTenantRoutingRule(values)
    }

    useEffect(() => {
        reset(selectedTenant)
        // Need for improvement. Warnings console
        /* eslint-disable */
    }, [selectedTenant])

    return (
        <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <SaveButtonBox>
                    <Button
                        type="submit"
                        variant="blue"
                        size="sm"
                    >
                        <SaveIcon w={3} h={3} color="white" />
                    </Button>
                </SaveButtonBox>
                <DividerStyled />
                <TextStyled>Type</TextStyled>
                <Controller
                    control={control}
                    name="location"
                    rules={{
                        required:
                            'This field cannot be blank. Please select a type to continue',
                    }}
                    key="location"
                    render={({ field: { onChange, value, ref } }) => (
                        <SelectStyled
                            onChange={e => onChange(e.target.value)}
                            value={value || ''}
                            ref={ref}
                            marginBottom="14px"
                        >
                            <option hidden disabled value="">
                                Select type
                            </option>
                            {options.map(item => (
                                <option key={item.value} value={item.label}>
                                    {item.label}
                                </option>
                            ))}
                        </SelectStyled>
                    )}
                />
                <TextStyled>Name</TextStyled>
                <CounteredInput
                    placeholder="Enter name..."
                    size="sm"
                    marginTop="8px"
                    disabled={!routingRuleType}
                    showRemaining={false}
                    isInvalid={!!errors.routingRuleName}
                    description={errors.routingRuleName?.message}
                    {...register('routingRuleName', {
                        required:
                            'This field cannot be blank. Please enter a name to continue.',
                        validate: value =>
                            getValidationSchema(routingRuleType, value) ||
                            getErrorMessage(routingRuleType),
                    })}
                />

                <Controller
                    control={control}
                    name="ignoreCase"
                    defaultValue={false}
                    render={({ field: { onChange, value, ref } }) => (
                        <CheckboxStyled
                            onChange={onChange}
                            ref={ref}
                            isChecked={value}
                            defaultChecked={value}
                            checked={value}
                        >
                            Ignore Case
                        </CheckboxStyled>
                    )}
                />
            </form>
        </Box>
    )
})

export default RoutingRule
