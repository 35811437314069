import React, { ReactElement, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { userStore } from 'stores'
import { JWTPayload } from 'types/api'
import { getPayload, isAdminUser, isSupportUser } from 'utils/jwt'
import { routes } from './routes'
import { observer } from 'mobx-react-lite'

interface IPrivateRoute {
    children: ReactElement
    checkAdminPage?: boolean
}

export const PrivateRoute = observer(({
    children,
    checkAdminPage = false,
}: IPrivateRoute) => {
    const isAuthenticated = !!localStorage.getItem('authToken')
    const token = localStorage.getItem('authToken')

    useEffect(() => {
        if (token) {
            const payload: JWTPayload = getPayload(token)
            userStore.setUser(payload.name, payload.role || [])
        } else {
            userStore.clearUser()
        }
    }, [token])

    if (checkAdminPage && token) {
        const payload: JWTPayload = getPayload(token)
        const isAdmin = checkAdminPage && isAdminUser(payload.role)
        const isSupport = checkAdminPage && isSupportUser(payload.role)

        return (isAuthenticated && isAdmin) || (isAuthenticated && isSupport) ? (
                children
            ) : (
                <Navigate to={routes.home} />
            )
    } else {
        return isAuthenticated ? children : <Navigate to={routes.login} />
    }
})
