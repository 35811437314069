export const getMinMaxValues = (
    isDecimal: boolean,
    isFeeParam: boolean,
    displayAs?: string | null
): number[] => {
    const feeMinValue = isFeeParam ? -1000000 : 0
    const lbOrFeeValues = displayAs === 'LB' ? [1, 10000] : [feeMinValue, 1000000]

    return isDecimal ? lbOrFeeValues : [1, 2147483647]
}
