import { addMinutes, format, subMonths, subWeeks, subYears } from 'date-fns'

export const dateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS"

const timeZoneOffset = new Date().getTimezoneOffset()
const pastWeek = format(
    addMinutes(subWeeks(new Date(), 1), timeZoneOffset),
    dateFormat
)
const pastMonth = format(
    addMinutes(subMonths(new Date(), 1), timeZoneOffset),
    dateFormat
)
const pastYear = format(
    addMinutes(subYears(new Date(), 1), timeZoneOffset),
    dateFormat
)

export const timeRangeOptions = [
    {
        value: pastWeek,
        label: 'Past week',
    },
    {
        value: pastMonth,
        label: 'Past month',
    },
    {
        value: pastYear,
        label: 'Past year',
    },
    {
        value: '',
        label: 'All time',
    },
]

export const retryOption = {
    title: 'Retry',
    colSpan: 1,
}

export const accordionHeaderOptions = [
    {
        title: 'Date and Time',
        colSpan: 3,
    },
    {
        title: 'Source Sys Reference No.',
        colSpan: 3,
    },
    {
        title: 'Ext. Source',
        colSpan: 3,
    },
    {
        title: 'Delivery Date',
        colSpan: 2,
    },
    {
        title: 'Status',
        colSpan: 3,
    },
    {
        title: 'Customer',
        colSpan: 3,
    },
    {
        title: 'Distribution Source',
        colSpan: 3,
    },
]

export const accordionHeaderOptionsOrderSimulate = [
    {
        title: 'Date and Time',
        colSpan: 3,
    },
    {
        title: 'Source Sys Reference No.',
        colSpan: 3,
    },
    {
        title: 'Workflow Name',
        colSpan: 2,
    },
    {
        title: 'Workflow Type',
        colSpan: 2,
    },
    {
        title: 'Ext. Source',
        colSpan: 2,
    },
    {
        title: 'Delivery Date',
        colSpan: 2,
    },
    {
        title: 'Status',
        colSpan: 3,
    },
    {
        title: 'Customer',
        colSpan: 2,
    },
    {
        title: 'Distribution Source',
        colSpan: 3,
    },
]

export const selectAllOption = { value: '*', label: 'All Statuses' }

export const rulesStatusOptions = [
    {
        value: 'approved',
        label: 'Approved',
    },
    {
        value: 'obsoleted',
        label: 'Obsolete',
    },
    {
        value: 'rejected',
        label: 'Rejected',
    },
    {
        value: 'submitted-for-approval',
        label: 'Pending',
    },
    {
        value: 'draft',
        label: 'Draft',
    },
]
