import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const SourceIndicatorIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 40 40"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            {...props}
        >
            <g filter="url(#filter0_d_5666_98398)">
                <circle cx="20" cy="16" r="16" fill="#0C79F1"/>
            </g>
            <g filter="url(#filter1_d_5666_98398)">
                <rect x="11" y="11.9888" width="14.5668" height="10.9824" transform="rotate(-24.275 11 11.9888)" fill="url(#pattern0)" shapeRendering="crispEdges"/>
            </g>
            <g filter="url(#filter2_d_5666_98398)">
                <rect x="11" y="11.9888" width="14.5668" height="10.9824" transform="rotate(-24.275 11 11.9888)" fill="#4774A5" style={{ mixBlendMode: 'screen' }} shapeRendering="crispEdges"/>
            </g>
            <g filter="url(#filter3_d_5666_98398)">
                <path d="M10.0898 11.9524L16.9992 26" stroke="#818181" strokeWidth="3" strokeLinecap="round"/>
            </g>
            <defs>
                <filter id="filter0_d_5666_98398" x="0" y="0" width="40" height="40" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5666_98398"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5666_98398" result="shape"/>
                </filter>
                <filter id="filter1_d_5666_98398" x="10" y="6" width="19.793" height="18" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="1"/>
                    <feGaussianBlur stdDeviation="0.5"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5666_98398"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5666_98398" result="shape"/>
                </filter>
                <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <use xlinkHref="#image0_5666_98398" transform="matrix(0.015625 0 0 0.0207247 0 -0.16319)"/>
                </pattern>
                <filter id="filter2_d_5666_98398" x="10" y="6" width="19.793" height="18" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="1"/>
                    <feGaussianBlur stdDeviation="0.5"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5666_98398"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5666_98398" result="shape"/>
                </filter>
                <filter id="filter3_d_5666_98398" x="7.58984" y="10.4521" width="11.9102" height="19.0483" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="1"/>
                    <feGaussianBlur stdDeviation="0.5"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5666_98398"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5666_98398" result="shape"/>
                </filter>
                <image id="image0_5666_98398" width="64" height="64" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAzklEQVR4nO3QwQnAMAwEQfXfdFKEAhPjNfg9q5uZeTZ/+37g8wDt8wDt8wDt8wDt8wDt8wDt8wDt8wDt8wDt8wDt8wDt8wDt8wDt8wDt8wDrH3/A0m8AHaD9BtAB2m8AHaD9BtAB2m8AHaD9BtAB2m8AHaD9Bjj9gA98HqB9HqB9HqB9HqB9HqB9HqB9HqB9HqB9HqB9HqB9HqB9HqB9HqB9HqB9HmD94w9Y+g2gA7TfADpA+w2gA7TfADpA+w2gA7TfADpA+w2gA7R//QAvT6baSvcRhoMAAAAASUVORK5CYII="/>
            </defs>
        </Icon>
    )
}
