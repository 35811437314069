import React, { useEffect } from 'react'
import { FormControl, FormLabel, Input, FormErrorMessage } from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'
import { TableForm } from 'types/core'
import { getInputOptions } from '../mock'
import { IDataCustomerShipment } from '../index'

interface ICustomerShipperInputs extends TableForm {
    isShipmentMethod: boolean
    setFormValue: (name: string, value: unknown, config?: Object) => void
    reset: () => void
    isSettingsModal: boolean
    isCreateModal: boolean
    data: IDataCustomerShipment
}

const CustomerShipperInputs = observer(({
   errors,
   register,
   setFormValue,
   reset,
   isShipmentMethod,
   isSettingsModal,
   data,
   isCreateModal
}: ICustomerShipperInputs) => {
    const options = getInputOptions(isShipmentMethod, isSettingsModal, data.type === 'Predefined')

    useEffect(() => {
        if (!isCreateModal) {
            setFormValue('name', data.name)
            setFormValue('code', data.code)
            setFormValue('ecommerceCode', data.ecommerceCode)
            setFormValue('orderSourceCode', data.orderSourceCode)
            setFormValue('connectionSettings', data?.connectionSettings)
            setFormValue('description', data.description)
        } else reset()

        return () => {
            reset()
        }
        // Need for improvement. Warnings console
        /* eslint-disable */
    }, [])

    return (
        <>
            {options.map(nameField => (
                <FormControl key={nameField.value} isInvalid={!!errors[nameField.value]} mt={3}>
                    <FormLabel htmlFor={nameField.value}>{nameField.label}</FormLabel>
                    {nameField.value === 'code' ?
                        <Input
                            id={nameField.value}
                            placeholder={nameField.label}
                            textTransform='uppercase'
                            {...register(nameField.value, {
                                required: 'This is required',
                                minLength: {
                                    value: nameField.minLength,
                                    message: `Minimum length should be ${nameField.minLength}`,
                                },
                                maxLength: {
                                    value: nameField.maxLength,
                                    message: `Max length should be ${nameField.maxLength}`,
                                }
                            })}
                        />
                    :
                        <Input
                            id={nameField.value}
                            placeholder={nameField.label}
                            {...register(nameField.value, {
                                required: 'This is required',
                                minLength: {
                                    value: nameField.minLength,
                                    message: `Minimum length should be ${nameField.minLength}`,
                                },
                                maxLength: {
                                    value: nameField.maxLength,
                                    message: `Max length should be ${nameField.maxLength}`,
                                }
                            })}
                        />
                    }
                    <FormErrorMessage>
                        {errors[nameField.value] && errors[nameField.value].message}
                    </FormErrorMessage>
                </FormControl>
            ))}
        </>
    )
})

export default CustomerShipperInputs
