import { FC } from 'react'
import {
    useDisclosure,
    Box,
    Center,
} from '@chakra-ui/react'
import { RetryReasonModal } from './ReasonModal'
import { RetryArrowIcon } from 'icons'
import { RetryIconButton } from './styles'
import { orderStore } from 'stores'

interface IRetryButtonProps {
    orderID: string
}

export const RetryButton: FC<IRetryButtonProps> = ({
    orderID,
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure()

    const onSubmit = (reason: string) => {
        orderStore.retryOrders([orderID], reason)
        onClose()
    }

    return(
        <Box onClick={e => e.stopPropagation()}>
            <RetryIconButton
                variant="ghost"
                aria-label="retry"
                onClick={onOpen}
                _hover={{ background: "blueWhite.980" }}
            >
                <Center width="23px">
                    <RetryArrowIcon />
                </Center>
            </RetryIconButton>
            <RetryReasonModal
                isOpen={isOpen}
                onSubmit={onSubmit}
                onClose={onClose}
            />
        </Box>
    )
}
