import { Button, Center, Input, InputLeftElement, Text } from '@chakra-ui/react'
import { Textarea } from '@chakra-ui/textarea'
import { Box, Flex } from '@chakra-ui/layout'
import { chakra } from 'utils/chakra'
import { CalendarIcon } from 'icons/Calendar'

export const pseudoSelectors = (
    property: string,
    value: string = 'transparent'
) => ({
    ':hover': {
        [property]: value,
    },
})

export const TextStyled = chakra(Text, {
    fontSize: '12px',
    lineHeight: '14px',
})

export const InputStyled = chakra(Input, {
    variant: 'unstyled',
    backgroundColor: 'blueWhite.950',
    borderRadius: '8px',
    padding: '9px 16px',
    color: 'color.100',
    _disabled: {
        color: 'gray.500',
    },
})

export const TextareaStyled = chakra(Textarea, {
    variant: 'unstyled',
    height: '100%',
    borderRadius: '8px',
    padding: '9px 16px',
    color: 'color.100',
    backgroundColor: 'blueWhite.950',
})

export const CanvasHeaderContainer = chakra(Center, {
    backgroundColor: 'color.810',
    height: '32px',
    padding: '7px',
})

export const CanvasHeaderText = chakra(Text, {
    variant: 'quotewhite',
    color: 'color.100',
    marginX: '12px',
})

export const CenterStyled = chakra(Center, {
    width: '28px',
    height: '28px',
    borderRadius: '50%',
    backgroundColor: 'blue.200',
    _hover: {
        border: '1px solid gray.300',
    },
})

export const CenterTodayCellStyled = chakra(Center, {
    width: '28px',
    height: '28px',
    borderRadius: '50%',
    backgroundColor: 'gray.400',
    _hover: {
        border: '1px solid gray.300',
    },
})

export const CenterRangedCellStyled = chakra(Center, {
    width: '32px',
    height: '28px',
    margin: '2px 0px',
    backgroundColor: 'gray.400',
})

export const CenterDefaultCellStyled = chakra(Center, {
    width: '28px',
    height: '28px',
    borderRadius: '50%',
    backgroundColor: '1px solid gray.400',
})

export const CenterClickedCellStyled = chakra(Center, {
    width: '32px',
    height: '32px',
    cursor: 'pointer',
})

export const CenterYearsStyled = chakra(Center, {
    width: '56px',
    height: '56px',
    borderRadius: '50%',
    backgroundColor: 'gray.400',
    _hover: {
        border: '1px solid',
    },
})

export const CenterCalenderBodyStyled = chakra(Center, {
    width: '16px',
    height: '16px',
    cursor: 'pointer',
    flex: '1',
})

export const CalenderInputStyled = chakra(Input, {
    padding: '8px 10px 8px 28px',
    marginTop: '4px',
})

export const CalenderFlexStyled = chakra(Flex, {
    padding: '8px 15px 8px 15px',
    marginTop: '4px',
    cursor: 'pointer',
})

export const CalenderBodyFlexStyled = chakra(Flex, {
    minWidth: 'max-content',
    alignItems: 'center',
    gap: '2',
    marginTop: '16px',
})

export const InputLeftElementStyled = chakra(InputLeftElement, {
    paddingTop: '5px',
    pointerEvents: 'none',
})

export const CalendarIconStyled = chakra(CalendarIcon, {
    color: 'gray.300',
})

export const BoxStyled = chakra(Box, {
    width: '30px',
    height: '28px',
    backgroundColor: 'gray.400',
})

export const ButtonStyled = chakra(Button, {
    marginLeft: '8px',
})

export const CenterClearDateStyled = chakra(Center, {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    backgroundColor: 'gray.400',
    marginTop: '18px',
})
