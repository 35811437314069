import React, { FC } from 'react'
import { Link as DomLink } from 'react-router-dom'
import { ChakraProps } from '@chakra-ui/react'
import { Box } from '@chakra-ui/layout'
import { Link } from '@chakra-ui/layout'
import { routes } from 'navigation/routes'
import { ArrowRightIcon } from 'icons'
import { ResultsName, ItemText, ViewResultsText, ViewResultsBox, ItemBox } from './styles'

interface SearchResultsProps extends ChakraProps{
    resultName: string
    items: string[]
    handleClear: () => void
}

export const SearchResults: FC<SearchResultsProps> = ({
    resultName,
    items,
    handleClear,
    ...props
}) => {
    return (
        <Box {...props}>
            <ResultsName>{resultName}</ResultsName>
            {!items.length && <ItemText marginLeft="16px">The search has not given any results...</ItemText>}
            {items.slice(0, 5).map(item => <ItemBox key={item}><ItemText>{item}</ItemText></ItemBox>)}
            {items.length > 5 && (
                <Link
                    as={DomLink}
                    to={routes.home}
                    onClick={handleClear}
                >
                    <ViewResultsBox>
                        <ViewResultsText>View All Results</ViewResultsText>
                        <ArrowRightIcon boxSize="9px" color="blue.300" />
                    </ViewResultsBox>
                </Link>
            )}
        </Box>
    )
}
