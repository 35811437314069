import { makeAutoObservable } from 'mobx'
import { sortBy } from 'lodash'
import { WowStore } from './WowStore'
import { notificationStore, workflowsStore, wowStore } from 'stores'
import { WorkflowsStore } from './WorkflowsStore'
import { reorderArray } from 'utils/operations'
import { WowsAPI } from 'api/wows'
import { ShortWorkflow, UUID } from 'types/core'

export class PrioritizeStore {
    public editRules: UUID[] = []
    public defaultRules: UUID[] = []
    public workflows: ShortWorkflow[] = []
    public wowStore: WowStore
    public workflowsStore: WorkflowsStore

    constructor() {
        this.wowStore = wowStore
        this.workflowsStore = workflowsStore
        makeAutoObservable(this)
    }

    public async fetch(id: UUID): Promise<void> {
        try {
            if (id) {
                const { workflows: wowWorkflows = [] } = await this.wowStore.fetch(id)
                this.initDefaultRules(wowWorkflows.map(({ id }) => id))
            }

            const workflows = await this.workflowsStore.fetch()
            const filterWorkflows = workflows.filter(({ id }) => this.defaultRules.includes(id))
            const sortWorkflows = sortBy(filterWorkflows, ({ id }) => {
                return this.defaultRules.indexOf(id) !== -1 ? this.defaultRules.indexOf(id) : filterWorkflows.length
            })

            this.initWorkflows(sortWorkflows)
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    public startEdit(selected: UUID[]): void {
        this.initEditRules(selected)
    }

    public toggleOne(id: UUID): void {
        if (this.editRules.includes(id)) {
            this.initEditRules(
                this.editRules.filter(ruleId => ruleId !== id)
            )
        } else {
            this.initEditRules([...this.editRules, id])
        }
    }

    public checkAll(ids: UUID[]): void {
        this.initEditRules(ids)
    }

    public uncheckAll(): void {
        this.initEditRules([])
    }

    public endEdit(): void {
        this.initDefaultRules(this.editRules)
        this.initWorkflows(
            this.workflowsStore.workflows.filter(({ id }) => this.defaultRules.includes(id))
        )
    }

    public prioritize(startIndex: number, endIndex: number): void {
        if (!this.workflows) return

        const prioritize = reorderArray(
            this.workflows,
            startIndex,
            endIndex,
        )

        this.initWorkflows(prioritize)
        this.initDefaultRules(prioritize.map(({ id }) => id))
    }

    public async putWorkflowsById(id: UUID) {
        await WowsAPI.putWorkflowsById(this.defaultRules, id)
    }

    public removeItem(workflowId: UUID): void {
        if (!this.workflows) return

        this.initWorkflows(
            this.workflows.filter(workflow => workflow.id !== workflowId)
        )
        this.initDefaultRules(
            this.defaultRules.filter(ruleId => ruleId !== workflowId)
        )
    }

    public updateWorkflowVersion(selectedWorkflow: ShortWorkflow): void {
        const updatedWorkflows = this.workflows.map(workflow => {
            if (workflow.workflowHeaderId === selectedWorkflow.workflowHeaderId) return selectedWorkflow

            return workflow
        })
        this.initWorkflows(updatedWorkflows)
        this.initDefaultRules(updatedWorkflows.map(({ id }) => id))
    }

    private initWorkflows(workflows: ShortWorkflow[]): void {
        this.workflows = workflows
    }

    private initDefaultRules(roles: UUID[]): void {
        this.defaultRules = roles
    }

    private initEditRules(editRules: UUID[]): void {
        this.editRules = editRules
    }

    public clean(): void {
        this.wowStore.clean()
        this.initEditRules([])
        this.initDefaultRules([])
        this.initWorkflows([])
    }
}
