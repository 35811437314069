import { StylesConfig } from 'react-select'
import { ISelectOption } from 'types/core'
import { getThemeColor } from 'utils/getThemeColor'
import { pseudoSelectors } from 'components/styles'

export const reactSelectStyles = (
    theme: string,
    width = '140px',
    marginRight = '16px',
    menuHeight = 'auto'
): StylesConfig<ISelectOption> => ({
    container: styles => ({
        ...styles,
        boxSizing: 'border-box',
        marginRight,
        width,
        maxHeight: '32px',
    }),
    dropdownIndicator: (styles, state) => ({
        ...styles,
        ...pseudoSelectors('color', 'color.100'),
        transition: 'all .2s ease',
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
        color: getThemeColor(theme, 'color.100'),
    }),
    valueContainer: styles => ({
        ...styles,
        padding: '0 8px',
    }),
    indicatorsContainer: styles => ({
        ...styles,
        '& > div': {
            padding: '0 8px',
        },
    }),
    menuList: styles => ({
        ...styles,
        height: menuHeight,
        backgroundColor: getThemeColor(theme, 'color.600'),
        borderRadius: '0px 0px 6px 6px',
        padding: 0,
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.45)',
    }),
    menu: styles => ({
        ...styles,
        borderRadius: '0px 0px 6px 6px',
        marginTop: 0,
        backgroundColor: 'inherit',
    }),
    placeholder: styles => ({
        ...styles,
        color: getThemeColor(theme, 'sidebarIconColor'),
        fontSize: '12px',
        lineHeight: '14px',
    }),
    control: (styles, state) => ({
        ...styles,
        ...pseudoSelectors('border', '1px solid'),
        ...pseudoSelectors('borderColor', getThemeColor(theme, 'dividerHover')),
        backgroundColor: 'transparent',
        borderRadius: state.selectProps.menuIsOpen ? '6px 6px 0 0' : '6px',
        color: getThemeColor(theme, 'color.100'),
        minHeight: '32px',
        maxHeight: '32px',
        boxShadow: 'none',
        border: `1px solid ${getThemeColor(theme, 'divider')}`,
    }),
    input: styles => ({
        ...styles,
        color: getThemeColor(theme, 'color.100'),
        fontSize: '12px',
        lineHeight: '14px',
    }),
    singleValue: styles => ({
        ...styles,
        color: getThemeColor(theme, 'color.100'),
        fontSize: '12px',
        lineHeight: '14px',
    }),
    option: (styles, state) => ({
        ...styles,
        ...pseudoSelectors(
            'backgroundColor',
            getThemeColor(theme, 'color.920')
        ),
        backgroundColor: state.isSelected
            ? getThemeColor(theme, 'blueWhite.940')
            : getThemeColor(theme, 'color.900'),
        color: getThemeColor(theme, 'color.100'),
        fontSize: '12px',
        lineHeight: '14px',
    }),
})
