import React, { FC, ReactNode, useEffect } from 'react'
import { Center, Flex, Spacer, Text } from '@chakra-ui/layout'
import { useColorMode, useTheme } from '@chakra-ui/system'
import { useDisclosure } from '@chakra-ui/hooks'
import { Portal } from '@chakra-ui/react'
import { AnimatePresence, motion, useAnimation } from 'framer-motion'
import { CrossSmallIcon } from 'icons'
import { getThemeColor } from 'utils/getThemeColor'
import { Theme } from 'types/core'
import { MiniMenuBox } from './styles'

interface MiniMenuButtonProps {
    icon: JSX.Element
    iconColor: string
    title: string
    children?: ReactNode
}

const HEADER_SPEED = 0.3
const CONTENT_SPEED = 0.5
const ANIMATION_SPEED = HEADER_SPEED + CONTENT_SPEED + 0.1

export const MiniMenuButton: FC<MiniMenuButtonProps> = ({
    icon,
    iconColor,
    title,
    children,
}) => {
    const { isOpen, onToggle } = useDisclosure()
    const { colorMode } = useColorMode()
    const theme = useTheme()
    const controls = useAnimation()

    useEffect(() => {
        if (isOpen) controls.start('open')
        else controls.start('close')
    }, [isOpen, controls])

    return (
        <MiniMenuBox>
            <motion.div
                variants={{
                    open: { zIndex: 10 },
                    close: {
                        zIndex: 3,
                        transition: { delay: ANIMATION_SPEED },
                    },
                }}
                initial="close"
                animate={controls}
                style={{ position: 'relative', width: 56, height: 56 }}
            >
                <Center
                    width="56px"
                    height="56px"
                    borderRadius="50%"
                    backgroundColor={iconColor}
                    cursor="pointer"
                    role="button"
                    onClick={onToggle}
                >
                    {icon}
                </Center>
            </motion.div>

            <motion.div
                variants={{
                    open: {
                        width: 225,
                        zIndex: 8,
                        transition: {
                            duration: HEADER_SPEED,
                            delay: 0,
                        },
                    },
                    close: {
                        width: 144,

                        transitionEnd: {
                            zIndex: 1,
                        },
                        transition: {
                            duration: HEADER_SPEED,
                            delay: CONTENT_SPEED,
                        },
                    },
                }}
                initial="close"
                animate={controls}
                style={{
                    position: 'relative',
                    height: 35,
                    backgroundColor: getThemeColor(colorMode, 'color.900'),
                    marginTop: -45,
                    marginLeft: 27,
                    paddingLeft: 37,
                    border:
                        colorMode === Theme.Dark
                            ? ''
                            : `1px solid ${theme.colors.gray[200]}`,
                    borderRadius: 100,
                    fontSize: '12px',
                    lineHeight: '14px',
                    textTransform: 'uppercase',
                    textAlign: 'left',
                    cursor: 'pointer',
                }}
            >
                <Flex height={35} onClick={onToggle}>
                    <Center>
                        <Text variant="supertitle">{title}</Text>
                    </Center>
                    <Spacer />
                    <motion.div
                        variants={{
                            open: { opacity: 1 },
                            close: { opacity: 0 },
                        }}
                        initial="close"
                        animate={controls}
                        transition={{
                            duration: HEADER_SPEED,
                        }}
                    >
                        <Center height="100%" marginRight="13px">
                            <CrossSmallIcon boxSize="9px" color="white" />
                        </Center>
                    </motion.div>
                </Flex>
            </motion.div>
            <motion.div
                variants={{
                    open: {
                        height: 'auto',
                        zIndex: 9,
                        transition: {
                            duration: CONTENT_SPEED,
                            delay: HEADER_SPEED,
                        },
                    },
                    close: {
                        height: 0,
                        transitionEnd: {
                            zIndex: 2,
                        },
                        transition: {
                            duration: CONTENT_SPEED,
                            delay: 0,
                        },
                    },
                }}
                initial="close"
                animate={controls}
                style={{
                    position: 'relative',
                    width: 210,
                    marginLeft: 27,
                    overflow: 'hidden',
                    backgroundColor: getThemeColor(colorMode, 'blueWhite.950'),
                    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)',
                    borderRadius: '0px 0px 10px 10px',
                }}
            >
                {children}
            </motion.div>
            <AnimatePresence>
                {isOpen && (
                    <Portal>
                        <motion.div
                            variants={{
                                open: {
                                    opacity: 0.4,
                                    visibility: 'visible',
                                },
                                close: {
                                    opacity: 0,
                                    transitionEnd: {
                                        visibility: 'hidden',
                                    },
                                },
                            }}
                            initial="close"
                            exit="close"
                            animate="open"
                            transition={{
                                duration: ANIMATION_SPEED,
                            }}
                            style={{
                                backgroundColor: 'black',
                                position: 'fixed',
                                top: 0,
                                width: '100vw',
                                height: '100vh',
                                zIndex: 5,
                                opacity: 0.4,
                            }}
                            onClick={onToggle}
                        />
                    </Portal>
                )}
            </AnimatePresence>
        </MiniMenuBox>
    )
}
