import { tenantsIdentityInstance, tenantsInstance } from './instances'
import { UUID } from 'types/core'
import {
    IRoutingRuleResponse,
    UiLoginFlow,
    RoutingMapOptionsResponse,
} from 'types/api'

interface tenantTreeChildModel {
    name: string
    domain: string
}

interface ISsoTenantSettings {
    clientId?: string
    authorityUrl?: string
    uiLoginFlow?: UiLoginFlow
}

interface IGeneralSettingsForm {
    authenticationType: string | null
    hasAuth: boolean
    hasMasterData: boolean
}

export interface IRoutingRuleForm {
    location?: string
    name?: string
    ignoreCase?: boolean
}

export class TenantsApi {
    static async getRootTenants() {
        const { data = {} } = await tenantsIdentityInstance.get('')
        return data
    }

    static async getAuthenticationTypes() {
        const { data = {} } = await tenantsIdentityInstance.get(
            'authentication-types'
        )
        return data
    }

    static async getChildren(tenantId: string | number) {
        const { data = {} } = await tenantsIdentityInstance.get(
            `${tenantId}/tenants?size=100&page=1`
        )
        return data
    }

    static async addRoot(rootTenant: tenantTreeChildModel) {
        const { data = {} } = await tenantsIdentityInstance.post(``, {
            ...rootTenant,
        })
        return data
    }

    static async addChildren(
        tenantId: string | number,
        childTenant: tenantTreeChildModel
    ) {
        const { data = {} } = await tenantsIdentityInstance.post(
            `${tenantId}/tenants`,
            { ...childTenant }
        )
        return data
    }

    static async deleteTenant(tenantId: string | number) {
        return await tenantsIdentityInstance.delete(`${tenantId}`)
    }

    static async updateTenantUserRoles(
        tenantId: UUID,
        userId: UUID,
        roles: string[]
    ) {
        const { data = {} } = await tenantsIdentityInstance.post(
            `${tenantId}/users/${userId}/roles`,
            roles
        )
        return data
    }

    static async getSettings(tenantId: UUID) {
        const { data = {} } = await tenantsIdentityInstance.get(
            `${tenantId}/settings`
        )
        return data
    }

    static async saveSettings(
        tenantId: UUID,
        tenantSettings: IGeneralSettingsForm
    ) {
        const { data = {} } = await tenantsIdentityInstance.put(
            `${tenantId}/settings`,
            tenantSettings
        )
        return data
    }

    static async getSsoSettings(tenantId: UUID, authType: string) {
        const { data = {} } = await tenantsIdentityInstance.get(
            `${tenantId}/sso/${authType}/settings`
        )
        return data
    }

    static async saveRoutingRule(
        tenantId: UUID,
        routingRule: IRoutingRuleForm
    ) {
        const { data = {} } = await tenantsInstance.put(
            `${tenantId}/routing/rule`,
            routingRule
        )
        return data
    }

    static async getRoutingRule(tenantId: UUID) {
        const { data } = await tenantsInstance.get<IRoutingRuleResponse>(
            `${tenantId}/routing/rule`
        )
        return data
    }

    static async saveTenantSettings(
        tenantId: UUID,
        name: string,
        allowPermissionsInheritance: boolean,
        enableSubTenantRouting: boolean
    ) {
        return tenantsIdentityInstance.put(`${tenantId}`, {
            name,
            allowPermissionsInheritance,
            enableSubTenantRouting,
        })
    }

    static async saveSsoSettings(
        tenantId: UUID,
        authType: string,
        ssoTenantSettings: ISsoTenantSettings
    ) {
        return await tenantsIdentityInstance.put(
            `${tenantId}/sso/${authType}/settings`,
            ssoTenantSettings
        )
    }

    static async getAccessHierarchy() {
        const { data = [] } = await tenantsIdentityInstance.get(
            'access-hierarchy'
        )
        return data
    }

    static async getRoutingMapOptions(tenantId: UUID) {
        const { data } =
            await tenantsIdentityInstance.get<RoutingMapOptionsResponse>(
                `${tenantId}/hierarchy`
            )
        return data
    }
}
