import { Box, Flex, Text } from '@chakra-ui/layout'
import { Accordion } from '@chakra-ui/accordion'
import { Button } from '@chakra-ui/button'
import { chakra } from 'utils/chakra'

export const ItemsShippingInfoBox = chakra(Box, {
    borderRadius: '16px',
    padding: '24px',
    backgroundColor: 'color.900',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)',
})

export const TitleBox = chakra(Flex, {
    justifyContent: 'space-between',
})

export const FiltersBox = chakra(Flex, {
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '32px',
    marginBottom: '24px',
})

export const ShowFiltersButton = chakra(Button, {
    height: '25px',
    marginRight: '8px',
    padding: '2px 8px',
})

export const ShowFiltersButtonText = chakra(Text, {
    variant: 'h6',
    marginLeft: '10px',
})

export const AccordionStyled = chakra(Accordion, {
    margin: '8px 0',
    variant: 'unstyled',
})

export const AccordionInfoItem = chakra(Text, {
    textAlign: 'left',
    flexBasis: 1,
    flexGrow: 1,
})

export const AccordionInfoBox = chakra(Box, {
    display: 'flex',
    alignItems: 'center',
    cursor: 'auto',
    width: '100%',
    height: '40px',
    padding: '8px 17px 8px 23px',
    borderRadius: '6px',
    backgroundColor: 'blueWhite.960',
    margin: '8px 0',
})

export const DeliveryMethodBox = chakra(Box, {
    as: 'span',
    textTransform: 'uppercase',
    backgroundColor: 'grayBlue.990',
    padding: '4px 12px',
    width: 'fit-content',
    borderRadius: '6px',
    fontWeight: 'bold',
    fontSize: '12px',
})

