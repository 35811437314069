import React, { FC, useState } from 'react'
import { truncate } from 'lodash'
import { observer } from 'mobx-react-lite'
import { Flex, Spacer } from '@chakra-ui/react'
import { AccordionItem } from '@chakra-ui/accordion'
import { Switch } from '@chakra-ui/switch'
import { Text } from '@chakra-ui/layout'
import { CustomerShipper } from 'types/api'
import { customerShipperStore } from 'stores'
import { TooltipTextCut } from 'components/TooltipTextCut'
import { EditIcon, SettingsIcon, SimpleArrowDownIcon, SimpleArrowUpIcon } from 'icons'
import { MAX_LENGTH_VALUE_SHOW } from 'constants/customerShipper'
import { getCustomerShipperOptions } from '../mock'
import { AccordionButtonBtn, AccordionContainer } from '../styles'

interface IShipperAccordion {
    isOpen: boolean
    shipmentProvider: CustomerShipper
    tenantId: string
    onAddShipperOpen: () => void
    isEnabled: boolean
    isOpenMethods: string
    setOpened: (value: number) => void
}

export const ShipperAccordion: FC<IShipperAccordion> = observer(({
     isOpen,
     shipmentProvider,
     tenantId,
     onAddShipperOpen,
     isEnabled,
     isOpenMethods,
     setOpened,
 }) => {
    const [enabled, setEnabled] = useState<boolean>(isEnabled)
    const options = getCustomerShipperOptions(shipmentProvider)

    const changeEnable = (e:  React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation()
        if (enabled) {
            customerShipperStore.changeShipmentProviderDeactivate(tenantId, shipmentProvider.code)
                .then(() => setEnabled(!enabled))
        } else if (!enabled) {
            customerShipperStore.changeShipmentProviderActivate(tenantId, shipmentProvider.code)
                .then(() => setEnabled(!enabled))
        }
    }

    const openEditModal = (e: React.MouseEvent, isSettingsModal: boolean, valueCheck: boolean) => {
        if (valueCheck) {
            e.stopPropagation()
            customerShipperStore.changeTypeModal(isSettingsModal)
            customerShipperStore.changeTypeReq(false)
            customerShipperStore.changeShipmentProviderItem(shipmentProvider)
            if (isOpenMethods !== shipmentProvider.code) setOpened(-1)
            onAddShipperOpen()
        }
    }

    const transformText = (value: string, index: number, options: { length: number }) => {
        const updateText = index === 1 ? value.toUpperCase() : value
        return !value ? '-' : truncate(updateText, options)
    }

    return (
        <AccordionItem margin='8px 0' width='100%'>
            <AccordionContainer>
                {options.map((option, index) => {
                    return (
                        <AccordionItem key={index} margin="0 3px">
                            <TooltipTextCut maxTextLength={MAX_LENGTH_VALUE_SHOW} text={option}>
                               <Text align="left">
                                   {transformText(option, index, { length: MAX_LENGTH_VALUE_SHOW })}
                               </Text>
                            </TooltipTextCut>
                        </AccordionItem>
                    )
                })}
                <Flex textAlign="left">
                    <Switch
                        isChecked={enabled}
                        onChange={(e) => changeEnable(e)}
                        variant='switchBoolean'
                    />
                </Flex>
                <Flex alignItems="center">
                    {shipmentProvider.type === 'Custom' &&
                        <AccordionButtonBtn
                            onClick={(e) => openEditModal(e, false, true)}
                            cursor='pointer'
                            color='color.100'
                        >
                            <EditIcon/>
                        </AccordionButtonBtn>
                    }
                    <AccordionButtonBtn
                        onClick={(e) => openEditModal(e, true, enabled)}
                        cursor={!enabled ? 'not-allowed' : 'pointer'}
                        color={!enabled ? 'color.75' : 'color.100'}
                    >
                        <SettingsIcon/>
                    </AccordionButtonBtn>
                    <Spacer />
                    {isOpen ? (
                        <SimpleArrowDownIcon
                            boxSize='15px'
                            color='color.100'
                            marginRight='5px'
                            marginTop='2px'
                        />
                    ) : (
                        <SimpleArrowUpIcon
                            boxSize='15px'
                            color='blue'
                            marginTop='8px'
                            marginRight='3px'
                        />
                    )}
                </Flex>
            </AccordionContainer>
        </AccordionItem>
    )
})
