import { makeAutoObservable } from 'mobx'
import {
    CustomerBaseData, CustomerBaseDataSettings,
    CustomerShipmentMethods,
    CustomerShipper,
} from 'types/api'
import { CustomerShipperApi } from 'api/customerShipper'
import { notificationStore } from './index'


const defaultProviderData: CustomerShipper = {
    name: '',
    code: '',
    type: '',
    isEnabled: false,
    ecommerceCode: '',
    orderSourceCode: '',
    connectionSettings: '',
    methods: []
}

const defaultMethodsData: CustomerShipmentMethods = {
    name: '',
    code: '',
    description: '',
    isEnabled: false,
    ecommerceCode: '',
    orderSourceCode: '',
}

export class CustomerShipperStore {
    public shipmentProviderList: CustomerShipper[] = []
    public shipmentProviderItem: CustomerShipper  = defaultProviderData
    public shipmentMethodsItem: CustomerShipmentMethods = defaultMethodsData
    public isSettingsModal: boolean = false
    public isCreateModal: boolean = false

    constructor() {
        makeAutoObservable(this)
    }

    public async fetchShipmentProvider(tenantId: string) {
        try {
            const response = await CustomerShipperApi.getShipmentProvider(tenantId)
            this.initShipmentProviderList(response)

        } catch (err) {
            this.initShipmentProviderList([])
        }
    }

    public async createShipmentProvider(
        tenantId: string,
        data: CustomerBaseData,
        closeModal: () => void
    ) {
        try {
            await CustomerShipperApi.postShipmentProvider(tenantId, data)
            await this.fetchShipmentProvider(tenantId)
            closeModal()
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }


    public async changeShipmentProvider(
        tenantId: string,
        shipmentProviderCode:string,
        data: CustomerBaseData,
        closeModal: () => void
    ) {
        try {
            await CustomerShipperApi.putShipmentProvider(tenantId, shipmentProviderCode, data)
            await this.fetchShipmentProvider(tenantId)
            closeModal()
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    public async changeShipmentProviderSettings(
        tenantId: string,
        shipmentProviderCode: string,
        data: CustomerBaseDataSettings,
        closeModal: () => void
    ) {
        try {
            await CustomerShipperApi.putShipmentProviderSettings(tenantId, shipmentProviderCode, data)
            await this.fetchShipmentProvider(tenantId)
            closeModal()
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    public async changeShipmentProviderActivate(
        tenantId: string,
        shipmentProviderCode: string
    ) {
        try {
            await CustomerShipperApi.putShipmentProviderActivate(tenantId, shipmentProviderCode)
            await this.fetchShipmentProvider(tenantId)
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    public async changeShipmentProviderDeactivate(
        tenantId: string,
        shipmentProviderCode: string
    ) {
        try {
            await CustomerShipperApi.putShipmentProviderDeactivate(tenantId, shipmentProviderCode)
            await this.fetchShipmentProvider(tenantId)
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    public async createShipmentMethod(
        tenantId: string,
        shipmentProviderCode: string,
        data: CustomerBaseData,
        closeModal: () => void
    ) {
        try {
            await CustomerShipperApi.postShipmentMethod(tenantId, shipmentProviderCode, data)
            await this.fetchShipmentProvider(tenantId)
            closeModal()
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    public async changeShipmentMethod(
        tenantId: string,
        shipmentProviderCode: string,
        shipmentMethodCode: string,
        data: CustomerBaseData,
        closeModal: () => void
    ) {
        try {
            await CustomerShipperApi.putShipmentMethod(tenantId, shipmentProviderCode, shipmentMethodCode, data)
            await this.fetchShipmentProvider(tenantId)
            closeModal()
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    public async changeShipmentMethodSettings(
        tenantId: string,
        shipmentProviderCode: string,
        shipmentMethodCode: string,
        data: CustomerBaseDataSettings,
        closeModal: () => void
    ) {
        try {
            await CustomerShipperApi.putShipmentMethodSettings(tenantId, shipmentProviderCode, shipmentMethodCode, data)
            await this.fetchShipmentProvider(tenantId)
            closeModal()
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    public async changeShipmentMethodActivate(
        tenantId: string,
        shipmentProviderCode: string,
        shipmentMethodCode: string
    ) {
        try {
            await CustomerShipperApi.putShipmentMethodActivate(tenantId, shipmentProviderCode, shipmentMethodCode)
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    public async changeShipmentMethodDeactivate(
        tenantId: string,
        shipmentProviderCode: string,
        shipmentMethodCode: string
    ) {
        try {
            await CustomerShipperApi.putShipmentMethodDeactivate(tenantId, shipmentProviderCode, shipmentMethodCode)
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    public changeTypeModal(value: boolean) {
        this.isSettingsModal = value
    }

    public changeTypeReq(value: boolean) {
        this.isCreateModal = value
    }

    public changeShipmentProviderItem(data: CustomerShipper) {
        this.shipmentProviderItem = data
    }

    public changeShipmentMethodsItem(data: CustomerShipmentMethods) {
        this.shipmentMethodsItem = data
    }

    private initShipmentProviderList(response: CustomerShipper[]) {
        this.shipmentProviderList = response
    }

    public clean(): void {
        this.changeShipmentProviderItem(defaultProviderData)
        this.changeShipmentMethodsItem(defaultMethodsData)
    }

    public cleanAll(): void {
        this.clean()
        this.initShipmentProviderList([])
    }
}
