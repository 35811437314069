import { makeAutoObservable } from 'mobx'
import { WorkflowsStore } from 'stores/WorkflowsStore'
import { ShortWorkflow } from 'types/core'

export class ApprovalsStore {
    public workflowsStore: WorkflowsStore

    public get approvals(): ShortWorkflow[] {
        return this.workflowsStore.workflows
    }

    constructor() {
        this.workflowsStore = new WorkflowsStore()

        makeAutoObservable(this)
    }

    public async fetch() {
        await this.workflowsStore.fetch()
        if (!this.workflowsStore.workflows.length && this.workflowsStore.filters.page > 1) {
            this.workflowsStore.filters.page = this.workflowsStore.filters.page - 1
            await this.fetch()
        }
    }

    public clean() {
        this.workflowsStore.clean()
    }
}
