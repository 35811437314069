import React, { ChangeEvent, FC, KeyboardEventHandler } from 'react'
import { Center, Divider, IconButton, InputGroup, InputRightElement } from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'
import { CrossSmallIcon, SearchIcon } from 'icons'
import { SearchInput } from './styles'

interface IInputSearchSquare {
    value: string | number,
    handleClear: () => void
    handleChange: (value: ChangeEvent<HTMLInputElement>) => void
    handleKeypress?: KeyboardEventHandler<HTMLInputElement>
    handleSearch: () => void
    placeholder: string
}

export const InputSearchSquare: FC<IInputSearchSquare> = observer(({
    value,
    handleClear,
    handleChange,
    handleSearch,
    placeholder,
    handleKeypress = () => {},
}) => {
    return (
        <InputGroup width="400px">
            <SearchInput
                type="text"
                value={value}
                onChange={handleChange}
                placeholder={placeholder}
                onKeyPress={handleKeypress}
            />
            <InputRightElement width={value ? '65px' : '42px'} height="32px">
                {!!value && (
                    <Center>
                        <IconButton
                            onClick={handleClear}
                            aria-label="Order Search Cross Small Icon"
                            variant="hoveredSmallWhite"
                            marginRight="5px"
                            icon={
                                <CrossSmallIcon padding="3px" boxSize="15px" />
                            }
                        />
                    </Center>
                )}
                <Center height="20px">
                    <Divider borderColor="blue.75" orientation="vertical" />
                </Center>
                <IconButton
                    onClick={handleSearch}
                    aria-label="Orders Search Icon"
                    variant="searchIcon"
                    icon={<SearchIcon boxSize="18px" />}
                />
            </InputRightElement>
        </InputGroup>
    )
})
