export const trueQueryParams = (object: object): string => {
    const getUrl = Object.entries(object).reduce((acc: string[], keyValue, index) => {
        const key = keyValue[0]
        const value = keyValue[1]

        if (value) {
            const needAmpersand = !index ? '?' : '&'
            acc.push(`${needAmpersand}${key}=${value}`)
        }

        return acc
    }, [])

    return getUrl.join('')
}
