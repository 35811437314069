import React, { Dispatch, FC, ReactElement, SetStateAction } from 'react'
import { IconButton } from '@chakra-ui/react'
import { Flex } from '@chakra-ui/layout'
import { useGetPath } from '../../hooks'
import { currentPage } from './utils'
import { IconBox, IconBoxText, SidebarItemText, SidebarTooltip } from './styles'

interface OpenedSidebarItemProps {
    name: string
    id: string
    icon: ReactElement
    handleClick: (name: string) => () => void
    isOpen: boolean
    setIsOpen: Dispatch<SetStateAction<boolean>>
    pageHeight: number
}

export const SidebarItem: FC<OpenedSidebarItemProps> = ({
    name,
    id,
    icon,
    handleClick,
    isOpen,
    setIsOpen,
    pageHeight,
}) => {
    const { fullUrl } = useGetPath()
    const isActive = currentPage(id, fullUrl)
    const isShowIcon = id === 'showHide'

    const setVariant = (isShowIcon: boolean) => {
        if (isShowIcon) return 'squircleWhiteIcon'
        return isActive ? 'squircleActiveIcon' : 'squircleGrayIcon'
    }

    if (id === 'showHide') {
        return (
            <SidebarTooltip
                hasArrow
                label={isOpen ? 'Hide Navigation' : 'Show Navigation'}
            >
                <IconBox
                    marginBottom={pageHeight < 580 ? '7px' : '15px'}
                    marginTop={pageHeight < 580 ? '7px' : '30px'}
                    alignSelf="self-start"
                    transform={isOpen ? 'rotate(0deg)' : 'rotate(180deg)'}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <IconButton
                        aria-label="Show Navigation Icon"
                        variant={setVariant(isShowIcon)}
                        icon={icon}
                    />
                </IconBox>
            </SidebarTooltip>
        )
    }

    if (isOpen) {
        return (
            <Flex flex={1} alignItems="center">
                <IconButton
                    aria-label={`${name} Icon`}
                    variant={
                        isActive ? 'sidebarOpenActiveIcon' : 'sidebarOpenGrayIcon'
                    }
                    onClick={handleClick(name)}
                    icon={
                        <Flex>
                            <IconBoxText>{icon}</IconBoxText>
                            <SidebarItemText variant={isActive ? 'h4' : 'h4Normal'}>
                                {name}
                            </SidebarItemText>
                        </Flex>
                    }
                />
            </Flex>
        )
    } else {
        return (
            <SidebarTooltip hasArrow label={name}>
                <IconBox
                    flex={1}
                    alignSelf="self-start"
                    onClick={handleClick(name)}
                >
                    <IconButton
                        aria-label={`${name} Icon`}
                        variant={setVariant(isShowIcon)}
                        icon={icon}
                    />
                </IconBox>
            </SidebarTooltip>
        )
    }
}
