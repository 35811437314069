import { ParentStepDto, PortType } from 'types/api'
import { LayoutData, UUID } from 'types/core'
import { stepsInstance } from './instances'

export class StepsAPI {
    static async updateLayout(stepId: UUID, layout: LayoutData) {
        await stepsInstance.put(`/${stepId}`, { layout })
    }

    static async deleteById(id: UUID) {
        await stepsInstance.delete(`/${id}`)
    }

    static async changeParent(
        stepId: UUID,
        parentId: UUID | null = null,
        portType: PortType = PortType.Default
    ) {
        return await stepsInstance.post<ParentStepDto>(
            `/${stepId}/$change-parent`,
            {
                parentStepId: parentId,
                order: portType,
            }
        )
    }
}
