import React, { useState, useRef } from 'react'
import { FormControl, Input, ListItem } from '@chakra-ui/react'
import { Spinner } from '@chakra-ui/spinner'
import { observer } from 'mobx-react-lite'
import { paramStore } from 'stores'
import { ICsvImportedPopupData, ICsvImport } from 'types/core'
import { subStringOfString } from './utils'
import { ParametersAPI } from 'api/parameters'
import { PopupForImport } from './PopupForImport'
import { PopupButton } from './PopupButton'
import { ComponentModal } from 'components/Modal/ComponentModal'
import { AlertValidationMessage } from './AlertValidationMessage'
import { CsvBrowseButton, FileName, SizeCsvValidationText } from './styles'

interface ICsv {
    value: number
    label: string
}

interface ICsvApiResponse {
    items: ICsvFilter[]
}

interface ICsvFilter {
    key: string
    displayValue: string
}

const stringLength = 15

export const CsvImportRuleBlock = observer(() => {
    const [loading, setLoading] = useState(false)
    const [openPopupMessage, setOpenPopupMessage] = useState(false)
    const [alertValidationMsg, setAlertValidationMsg] = useState('')
    const [csvExport, setCsvExport] = useState<string[]>([])
    
    const [csvFileName, setCsvFileName] = useState('')
    const [availableItems, setAvailableItems] = useState<ICsvImport[]>([])
    const [openPopup, setOpenPopup] = useState(false)
    const fileInputRef = useRef<HTMLInputElement>(null)

    const onClosePopupMessage = (): void => setOpenPopupMessage(false)

    const multiBrowseId = paramStore?.isAllAvailableDataId

    const onClosePopup = (): void => setOpenPopup(false) 

    const fileInputHandler = () => fileInputRef.current?.click()

    const importCsvDataAndCheckFromApi = (csvDFile: File) => {
        const reader = new FileReader()
        if (csvDFile) {
            reader.onload = function (event) {
                const resultOfCsvValues: string | ArrayBuffer =
                    event?.target?.result || ''
                if(typeof resultOfCsvValues === 'string') {
                    const lines = resultOfCsvValues
                        .split('\n')
                        .filter((el: string | null) => !!el)
                    const matchedData: ICsv[] = []
                    const notMatchedData: ICsvImport[] = []
                    const paramValueData: string[] = []
                    const dataValues: ICsvApiResponse[] = []

                    lines.forEach((value: string, index: number) => {
                        const currentlineNew = value.replace(/(\r\n|\n|\r)/gm, '').split(',')
                        
                        if (currentlineNew[0].trim().length > 0) {
                            paramValueData.push(currentlineNew[0])
                            matchedData.push({ value: index, label: currentlineNew[0] })
                            notMatchedData.push({ key: currentlineNew[0] })
                        }
                    })
                
                    if (paramValueData) {
                        ParametersAPI.getAvailableValues(
                            multiBrowseId,
                            paramValueData
                        ).then(response => {
                            dataValues.push(response)
                            preparedFinalMatchedValue(dataValues, matchedData, notMatchedData)
                            setLoading(false)
                            const fileName = csvDFile ? csvDFile.name : ''
                            const csvName = subStringOfString(fileName, stringLength)
                            setCsvFileName(csvName)
                        })
                    }
                }
            }
        }
        reader.readAsText(csvDFile)
    }

    const validationCsv = (csvDFile: File, csvType: string) => {
        const csvSize = csvDFile ? csvDFile.size / 1024 : ''
        const csvEmpty = csvDFile ? csvDFile.size : ''
        let msg: string = ''
        if (csvSize && csvSize > 1000)
            msg = 'Please upload csv less than/equal to 1MB'
        if (csvEmpty && csvEmpty <= 2) msg = 'Csv is empty'
        if (csvType !== 'csv') msg = 'Please upload file in Csv Format'
        return msg
    }
    
    const csvImportHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length) {
            const csvDFile = e.target.files[0]
            const csvType = csvDFile.name.split('.').pop() || '';
            
            const validateMessage = validationCsv(
                csvDFile,
                csvType
            )

            if (validateMessage) {
                setOpenPopupMessage(true)
                setCsvFileName('')
                setAlertValidationMsg(validateMessage)
                e.target.value = ''
            } else {
                setLoading(true)
                importCsvDataAndCheckFromApi(csvDFile)
            }
        }
    }

    const preparedFinalMatchedValue = (
        dataValues: ICsvApiResponse[],
        matchedData: ICsv[],
        notMatchedData: ICsvImport[]
    ) => {
        if (dataValues.length) {
                const getDataValues = dataValues[0].items
                
                const matched = matchedData.filter(matchedValue =>
                    getDataValues.some(
                        (matchAttr: ICsvFilter) =>
                            matchedValue.label === matchAttr.key
                    )
                )
                const finalMatchedValue: ICsvImportedPopupData[] = []

                matched.forEach(outerElement => {
                    getDataValues.forEach((element: ICsvFilter) => {
                        if (outerElement.label === element.key) {
                            finalMatchedValue.push({
                                value: element.key,
                                label: element.displayValue,
                            })
                        }
                    })
                })
                paramStore.storeAllAvailableValues(finalMatchedValue)
                
                const notMatched = notMatchedData.filter(
                    notMatchValue =>
                        !getDataValues.some(
                            (notMatchAttr: ICsvFilter) =>
                                notMatchValue.key === notMatchAttr.key
                        )
                )
                if (notMatched.length) {
                    setAvailableItems(notMatched)
                    setCsvExport(notMatched.map(({ key }) => key))
                    setOpenPopup(true)
                }
        }
    }
    return (
        <>
            {loading ? (
                <Spinner size="lg" />
            ) : (
                <>
                <FormControl>
                    <Input
                        hidden
                        ref={fileInputRef}
                        type="file"
                        onChange={csvImportHandler}
                    />
                    <CsvBrowseButton onClick={fileInputHandler}>
                        Import CSV
                    </CsvBrowseButton>
                    {csvFileName && <FileName>{csvFileName}</FileName>}
                    <SizeCsvValidationText>
                        <ListItem>
                          Max 1 mb allowed
                        </ListItem>
                    </SizeCsvValidationText>
                    {openPopup && (
                        <ComponentModal
                            size="xl"
                            isOpen={openPopup}
                            onClose={onClosePopup}
                            headerText="These items are not available in Item Specific List"
                            scrollBehavior="inside"
                            reactComponent={
                                <PopupForImport
                                    comparedValue={availableItems}
                                />
                            }
                            footerComponent={
                                <PopupButton
                                    onClose={onClosePopup}
                                    notMatchedExport={csvExport}
                                />
                            }
                        />
                    )}
                </FormControl>
                {openPopupMessage ? (
                    <AlertValidationMessage
                        validateCsvDataMsg={alertValidationMsg}
                        isOpen={openPopupMessage}
                        onClose={onClosePopupMessage}
                    />
                ) : null}
            </>
            )}
        </>
    )
})
