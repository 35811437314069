import { Flex } from '@chakra-ui/react'
import { ModalBody } from '@chakra-ui/modal'
import { chakra } from 'utils/chakra'

export const IconBox = chakra(Flex, {
    cursor: 'pointer',
    zIndex: '5',
})

export const HeaderBox = chakra(Flex, {
    backgroundColor: 'color.810',
    height: 'fit-content',
    padding: '40px 30px 8px 24px',
    justifyContent: 'space-between',
})

export const ModalBodyStyled = chakra(ModalBody, {
    width: '100%',
    height: '100%',
    padding: '0px',
    borderRadius: '16px',
})
