import React, { ChangeEventHandler, FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { InputSearch } from 'components/baseComponents/InputSearchCircle'
import { SearchResults } from './SearchResults'
import { searchResults } from './mock'
import { InputBox, SearchResultsBox } from './styles'

export const Input: FC = () => {
    const [searchValue, setSearchValue] = useState('')
    const { pathname } = useLocation()

    const handleChange: ChangeEventHandler<HTMLInputElement> = (event): void => {
        setSearchValue(event.target.value)
    }

    const handleClear = (): void => setSearchValue('')

    useEffect(() => {
        setSearchValue('')
    }, [pathname])

    return (
        <InputBox>
            <InputSearch
                clearable
                placeholder="Search by Rule Nickname, Short Description, or Tag..."
                searchValue={searchValue}
                handleClear={handleClear}
                onChange={handleChange}
                borderColor="borderInput.150"
            />
            {!!searchValue && (
                <SearchResultsBox>
                    <SearchResults
                        resultName="Rules"
                        items={searchResults.rules}
                        handleClear={handleClear}
                    />
                    <SearchResults
                        resultName="Orders"
                        items={searchResults.orders}
                        handleClear={handleClear}
                        marginTop="32px"
                    />
                </SearchResultsBox>
            )}
        </InputBox>
    )
}
