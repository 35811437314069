import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Center } from '@chakra-ui/layout'
import { useDisclosure } from '@chakra-ui/react'
import { MultiValueGenericProps } from 'react-select'
import { OptionData } from 'components/baseComponents/Selects'
import { TooltipWithButton } from 'components/TooltipWithButton'
import { MoreButton } from 'components/TooltipWithButton/styles'
import { MultiValue } from 'pages/HomePage/content/Dashboard/widgets/MyRules/MultiSelect/styles'
import { editoreStore } from 'stores'
import { colors } from 'theme/colors'
import { CustomTooltip } from './styles'

export const MultiValueContainer = observer(({
    selectProps: { value: selectedValues, isDisabled },
    data: { value, label },
}: MultiValueGenericProps<OptionData[]>) => {
    const {
        isOpen: isOpenTooltip,
        onOpen: onOpenTooltip,
        onClose: onCloseTooltip,
    } = useDisclosure()
    if (!selectedValues || !Array.isArray(selectedValues)) return <></>
    const selectedValuesLabels = selectedValues.map(({ label }) => label)
    const currentIndex = selectedValues.findIndex(val => val.value === value)

    useEffect(() => {
        editoreStore.diagramStore.changeModalData(selectedValuesLabels)
    }, [selectedValuesLabels])


    if (currentIndex < 1) {
        const cutLabel = label.length > 30 ? `${ label.slice(0, 30) }...` : label
        return (
            <CustomTooltip
                placement="top"
                label={
                    <TooltipWithButton
                        selectedValues={selectedValuesLabels}
                        cutLength={300}
                        onCloseTooltip={onCloseTooltip}
                    >
                        <MoreButton
                            onClick={e => {
                                e.stopPropagation()
                                onCloseTooltip()
                                editoreStore.diagramStore.handleModalDetails(true)
                            }}
                        >
                            More
                        </MoreButton>
                    </TooltipWithButton>
                }
                isOpen={isOpenTooltip}
                pointerEvents="auto"
            >
                <Center
                    justifyContent="flex-start"
                    onMouseEnter={onOpenTooltip}
                >
                    <MultiValue color={isDisabled ? colors.gray['100'] : 'white'}>
                        {cutLabel}
                    </MultiValue>
                </Center>
            </CustomTooltip>
        )
    }
    if (currentIndex === selectedValues.length - 1) return (
        <MultiValue color={isDisabled ? colors.gray['100'] : 'white'}>
            &nbsp;+ {selectedValues.length - 1}
        </MultiValue>
    )
    return <></>
})
