import React, { useEffect, useState } from 'react'
import { Flex } from '@chakra-ui/layout'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { Tabs } from '@chakra-ui/react'
import { tenantsStore } from 'stores'
import { TenantTree } from 'components/TenantsComponents/TenantTree'
import { FormComponent, TabsList } from 'components/TenantsComponents/Tabs'
import { NotSelectedTenantPage } from 'components/NoSelectedTenant'
import { HeadingStyled, TabsBox, TenantsBox, TreeBox } from './styles'

export const AdminTenantsPage = observer(() => {
    const [tabIndex, setTabIndex] = useState(0)
    const selectedTenant = toJS(tenantsStore.selectedTenant)

    const handleTabsChange = (index: number) => setTabIndex(index)

    useEffect(() => {
        tenantsStore.getAuthTypes()
    }, [])

    return (
        <TenantsBox>
            <HeadingStyled>Admin Tenants Page</HeadingStyled>
            <Flex color="white">
                <TreeBox>
                    <HeadingStyled>Tenants Tree</HeadingStyled>
                    <TenantTree showManagementButtons />
                </TreeBox>
                <TabsBox borderLeftColor="divider">
                    <HeadingStyled marginLeft="15px">
                        {selectedTenant?.name}
                    </HeadingStyled>
                    {!!selectedTenant.id && (
                        <Tabs
                            index={tabIndex}
                            defaultIndex={0}
                            onChange={handleTabsChange}
                            variant="line"
                        >
                            <TabsList />
                            <FormComponent
                                handleTabsChange={handleTabsChange}
                            />
                        </Tabs>
                    )}
                    {!selectedTenant.id && <NotSelectedTenantPage />}
                </TabsBox>
            </Flex>
        </TenantsBox>
    )
})
