export const errorStatuses: string[] = [
    'ProcessingInitError',
    'Error',
    'PreProcessingError',
    'RuleProcessingError',
    'CompletionError',
    'ValidateAddressError',
    'ErrorSentToECC',
    'ErrorAcknowledgedByECC',
    'ErrorShipment',
    'ErrorSentToIcep',
    'PostProcessingError',
    'Failed',
    'DeliveryError',
]

export const orderErrorStatuses: string[] = [
    'processingInitError',
    'error',
    'preProcessingError',
    'ruleProcessingError',
    'completionError',
    'validateAddressError',
    'errorSentToECC',
    'errorAcknowledgedByECC',
    'errorShipment',
    'errorSentToIcep',
    'postProcessingError',
    'failed',
    'deliveryError'
]