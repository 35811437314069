import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const EyeIcon: FC<IconProps> = props => {
    return (
        <Icon viewBox="0 0 16 11" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M8 2.11364C10.5841 2.11364 12.8886 3.56591 14.0136 5.86364C12.8886 8.16136 10.5909 9.61364 8 9.61364C5.40909 9.61364 3.11136 8.16136 1.98636 5.86364C3.11136 3.56591 5.41591 2.11364 8 2.11364ZM8 0.75C4.59091 0.75 1.67955 2.87045 0.5 5.86364C1.67955 8.85682 4.59091 10.9773 8 10.9773C11.4091 10.9773 14.3205 8.85682 15.5 5.86364C14.3205 2.87045 11.4091 0.75 8 0.75ZM8 4.15909C8.94091 4.15909 9.70455 4.92273 9.70455 5.86364C9.70455 6.80455 8.94091 7.56818 8 7.56818C7.05909 7.56818 6.29545 6.80455 6.29545 5.86364C6.29545 4.92273 7.05909 4.15909 8 4.15909ZM8 2.79545C6.30909 2.79545 4.93182 4.17273 4.93182 5.86364C4.93182 7.55455 6.30909 8.93182 8 8.93182C9.69091 8.93182 11.0682 7.55455 11.0682 5.86364C11.0682 4.17273 9.69091 2.79545 8 2.79545Z"
                fill="currentColor"
            />
        </Icon>
    )
}
