import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const CategoryCustomBlockIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 12C2 6.579 6.579 2 12 2C17.421 2 22 6.579 22 12C22 15.19 20.408 18.078 18 19.924V20H17.898C16.23 21.245 14.187 22 12 22C9.813 22 7.77 21.245 6.102 20H6V19.924C3.592 18.078 2 15.189 2 12ZM9.12347 17.236C8.59154 17.6639 8.22136 18.2604 8.074 18.927C9.242 19.604 10.584 20 12 20C13.416 20 14.758 19.604 15.926 18.927C15.7785 18.2605 15.4082 17.6641 14.8764 17.2362C14.3445 16.8083 13.6827 16.5744 13 16.573H11C10.3173 16.5742 9.6554 16.808 9.12347 17.236ZM15.7677 15.4117C16.5877 15.9574 17.2286 16.7329 17.61 17.641C19.077 16.182 20 14.176 20 12C20 7.663 16.337 4 12 4C7.663 4 4 7.663 4 12C4 14.176 4.923 16.182 6.39 17.641C6.77144 16.7329 7.41227 15.9574 8.23227 15.4117C9.05227 14.866 10.015 14.5742 11 14.573H13C13.985 14.5742 14.9477 14.866 15.7677 15.4117ZM7.99869 9.99479C7.99869 7.71479 9.71869 5.99479 11.9987 5.99479C14.2787 5.99479 15.9987 7.71479 15.9987 9.99479C15.9987 12.2748 14.2787 13.9948 11.9987 13.9948C9.71869 13.9948 7.99869 12.2748 7.99869 9.99479ZM9.99869 9.99479C9.99869 11.1728 10.8207 11.9948 11.9987 11.9948C13.1767 11.9948 13.9987 11.1728 13.9987 9.99479C13.9987 8.81679 13.1767 7.99479 11.9987 7.99479C10.8207 7.99479 9.99869 8.81679 9.99869 9.99479Z"
                fill="white"
            />
        </Icon>
    )
}
