import React, { FC, useRef } from 'react'
import {
    Box,
    BoxProps,
    Center,
    Collapse,
    Text,
    useDisclosure,
    useMultiStyleConfig,
    useOutsideClick,
} from '@chakra-ui/react'
import { DropDownIcon } from 'icons'
import { OptionData } from '../types'
import {
    ButtonsBox,
    IconBox,
    OptionsBox,
    SelectBox,
    SelectInput,
} from '../styles'

interface SelectProps extends Omit<BoxProps, 'onChange'> {
    placeholder?: string
    readonly?: boolean
    options: OptionData[]
    value: OptionData | null
    onChange: (option: OptionData) => void
    isDisabled: boolean
}

export const Select: FC<SelectProps> = ({
    placeholder = '',
    readonly = false,
    options,
    value,
    onChange,
    isDisabled,
    ...props
}) => {
    const ref = useRef<HTMLDivElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)
    const { isOpen, onClose, onToggle } = useDisclosure()
    const styles = useMultiStyleConfig('DropdownSelect', { isOpen })

    const isDisabledColor = isDisabled ? 'gray.100' : 'white'
    const borderRadius = isOpen ? '8px 8px 0 0' : '8px'

    const handleClick = () => {
        if (!readonly && !isDisabled) onToggle()
    }

    useOutsideClick({
        ref,
        handler: () => {
            onClose()
        },
    })

    return (
        <SelectBox
            ref={ref}
            onClick={handleClick}
            {...props}
        >
            <SelectInput
                variant="nodeSelect"
                color={isDisabledColor}
                _placeholder={{ color: isDisabledColor }}
                borderRadius={borderRadius}
                isDisabled={isDisabled}
                ref={inputRef}
                placeholder={placeholder}
                isReadOnly={true}
                value={value ? value.label : ''}
            />

            {!readonly && !isDisabled ? (
                <ButtonsBox>
                    <IconBox>
                        <DropDownIcon
                            openned={isOpen}
                            boxSize="18px"
                            color="white"
                        />
                    </IconBox>
                </ButtonsBox>
            ) : null}
            {!isDisabled && (
                <OptionsBox>
                    <Collapse in={isOpen}>
                        <Box {...(styles.container as BoxProps)} padding="0">
                            {options.map(option => (
                                <Center
                                    key={option.value}
                                    {...(styles.option as BoxProps)}
                                    onClick={() => {
                                        onChange(option)
                                    }}
                                    backgroundColor="blueWhite.950"
                                    _hover={{ backgroundColor: 'blueWhite.995' }}
                                >
                                    <Text isTruncated>{option.label}</Text>
                                </Center>
                            ))}
                            {options.length === 0 ? (
                                <Center backgroundColor="blueWhite.950" {...(styles.empty as BoxProps)}>
                                    <Text isTruncated>No options</Text>
                                </Center>
                            ) : null}
                        </Box>
                    </Collapse>
                </OptionsBox>
            )}
        </SelectBox>
    )
}
