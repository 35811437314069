import React, { useState } from 'react'
import { Button, FormLabel, InputRightElement } from '@chakra-ui/react'
import { FormControl, FormErrorMessage } from '@chakra-ui/form-control'
import { Input, InputGroup } from '@chakra-ui/input'
import { TableForm } from 'types/core'
import { tenantsStore } from '../../../../stores'

const ResourceOwnersAuthInputs = ({ errors, register }: TableForm) => {
    const [show, setShow] = useState(false)
    const handleButtonClick = () => setShow(!show)

    return (
        <>
            <FormControl>
                <FormLabel htmlFor="tenantId">Tenant Id</FormLabel>
                <Input
                    id="tenantId"
                    placeholder="Tenant Id"
                    value={tenantsStore.tenantId}
                    disabled
                />
            </FormControl>
            <FormControl isInvalid={!!errors.name} mt={5}>
                <FormLabel htmlFor="name">Name</FormLabel>
                <Input
                    id="name"
                    placeholder="name"
                    {...register('name', {
                        required: 'This is required',
                        minLength: {
                            value: 4,
                            message: 'Minimum length should be 4',
                        },
                    })}
                />
                <FormErrorMessage>
                    {errors.name && errors.name.message}
                </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.clientId} mt={5}>
                <FormLabel htmlFor="clientId">Client Id</FormLabel>
                <Input
                    id="clientId"
                    placeholder="Client Id"
                    {...register('clientId', {
                        required: 'This is required',
                        minLength: {
                            value: 4,
                            message: 'Minimum length should be 4',
                        },
                    })}
                />
                <FormErrorMessage>
                    {errors.clientId && errors.clientId.message}
                </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.clientSecret} mt={5}>
                <FormLabel htmlFor="clientSecret">Client secret</FormLabel>
                <Input
                    id="clientSecret"
                    placeholder="Client secret"
                    {...register('clientSecret', {
                        required: 'This is required',
                        minLength: {
                            value: 4,
                            message: 'Minimum length should be 4',
                        },
                    })}
                />
                <FormErrorMessage>
                    {errors.clientSecret && errors.clientSecret.message}
                </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.username} mt={5}>
                <FormLabel htmlFor="username">Username</FormLabel>
                <Input
                    id="username"
                    placeholder="Username"
                    {...register('username', {
                        required: 'This is required',
                        minLength: {
                            value: 4,
                            message: 'Minimum length should be 4',
                        },
                    })}
                />
                <FormErrorMessage>
                    {errors.username && errors.username.message}
                </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.password} mt={5} mb={5}>
                <FormLabel htmlFor="password">Password</FormLabel>
                <InputGroup size="md">
                    <Input
                        id="password"
                        placeholder="Password"
                        type={show ? 'text' : 'password'}
                        {...register('password', {
                            required: 'This is required',
                            minLength: {
                                value: 4,
                                message: 'Minimum length should be 4',
                            },
                        })}
                    />
                    <InputRightElement width={70}>
                        <Button
                            variant="blue"
                            padding="20px"
                            onClick={handleButtonClick}>
                            {show ? 'Hide' : 'Show'}
                        </Button>
                    </InputRightElement>
                </InputGroup>
                <FormErrorMessage>
                    {errors.password && errors.password.message}
                </FormErrorMessage>
            </FormControl>
        </>
    )
}

export default ResourceOwnersAuthInputs
