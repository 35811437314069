import React, { KeyboardEvent, useCallback, useEffect, useState } from 'react'
import { useColorModeValue } from '@chakra-ui/react'
import { parse as queryStringParse } from 'query-string'
import { Link as DomLink, useLocation, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Step, Steps, useSteps } from 'chakra-ui-steps'
import { Button } from '@chakra-ui/button'
import { Divider, Link } from '@chakra-ui/layout'
import { Message } from 'components/baseComponents/Message'
import { LoginInput } from './Input'
import { routes } from 'navigation/routes'
import { AuthAPI } from 'api/auth'
import { StatusType } from 'types/chart'
import { loginErrorMessage, steps, validateEmail } from '../mock'
import { secondStep } from 'constants/steps'
import { userStore } from 'stores'
import { AzureAuthService } from 'services/azureAuth'
import { getPayload } from 'utils/jwt'
import { AzureAuth, JWTPayload } from 'types/api'
import {
    Container,
    ForgotPasswordText,
    InputBox,
    InputsBox,
    InputText,
    LinkBox,
    LoginBox,
    LoginHeading,
    LoginHelpText,
    LoginHelpTextBox,
    LoginSubheading,
    StepsButtonsBox,
} from '../styles'

interface LoginProps {
    email: string
    password: string
}

export const LoginPage = () => {
    const { hash } = useLocation()
    const [isMessageOpen, setIsMessageOpen] = useState(false)
    const [authType, setAuthType] = useState('')
    const navigate = useNavigate()
    const { search } = useLocation()
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset: resetForm,
        watch,
    } = useForm<LoginProps>()
    const { nextStep, prevStep, reset, activeStep } = useSteps({
        initialStep: 0,
    })
    const colorScheme = useColorModeValue('telegram', 'blue')

    const onPrev = () => {
        prevStep()
        setAuthType('')
        resetForm({
            email: watch('email') || '',
        })
        setIsMessageOpen(false)
    }

    const onLoginPassword = async ({ email, password }: LoginProps) => {
        try {
            const res = await AuthAPI.login(email, password)
            const token: string = res.accessToken
            const refreshToken: string = res.refreshToken
            const decoded: JWTPayload = getPayload(token)
            localStorage.setItem('authToken', token)
            localStorage.setItem('refreshToken', refreshToken)
            localStorage.setItem('authType', authType)
            userStore.setUser(decoded.name, decoded.role || [])

            setIsMessageOpen(false)
            navigate(routes.home)
        } catch (err) {
            setIsMessageOpen(true)
            resetForm({ email: email, password: '' })
        }
    }

    const onLoginAzureRedirect = useCallback(async () => {
        nextStep()
        setAuthType('azure_ad')
        setIsMessageOpen(false)
        try {
            await AzureAuthService.redirectLoginHandler()
            navigate(routes.home)
        } catch (err) {
            setAuthType('')
            reset()
            setIsMessageOpen(true)
            localStorage.removeItem('authToken')
            localStorage.removeItem('refreshToken')
            localStorage.removeItem('authType')
            localStorage.removeItem('clientId')
            localStorage.removeItem('authorityUrl')
            localStorage.removeItem('uiLoginFlow')
        }
        return
        // Need for improvement. Warnings console
        /* eslint-disable */
    }, [navigate])

    const onLoginAzurePopup = async (
        settings: AzureAuth,
        authenticationType: string,
        email: string
    ) => {
        nextStep()
        setAuthType(authenticationType)
        setIsMessageOpen(false)
        await AzureAuthService.auth(settings, authenticationType, email)
        navigate(routes.home)
        return
    }

    const onGetConfiguration = async ({ email }: LoginProps) => {
        try {
            const {
                authenticationType = 'password',
                settings = {} as AzureAuth,
            } = await AuthAPI.getAccountConfiguration(email)
            if (authenticationType === 'azure_ad')
                await onLoginAzurePopup(settings, authenticationType, email)

            setAuthType(authenticationType)
            nextStep()
            setIsMessageOpen(false)
        } catch (err) {
            setAuthType('')
            reset()
            setIsMessageOpen(true)
        }
    }

    const hardReloginAzure = useCallback(async () => {
        setAuthType('azure_ad')
        nextStep()
        try {
            await AzureAuthService.hardRelogin()
            navigate(routes.home)
        } catch (err) {
            localStorage.removeItem('authToken')
            localStorage.removeItem('refreshToken')
            localStorage.removeItem('authType')
            localStorage.removeItem('clientId')
            localStorage.removeItem('authorityUrl')
            localStorage.removeItem('uiLoginFlow')
            navigate(routes.login)
            setAuthType('')
            setIsMessageOpen(true)
            reset()
        }
    }, [navigate])

    const handleKeypress = async (event: KeyboardEvent<object>) => {
        if (event.key === 'Enter') {
            activeStep === steps.length - 1
                ? await handleSubmit(onLoginPassword)()
                : await handleSubmit(onGetConfiguration)()
        }
    }

    useEffect(() => {
        const { hardRelogin = 'false' } = queryStringParse(search)
        if (hardRelogin === 'true') hardReloginAzure()

        return () => {
            reset()
        }
    }, [search, hardReloginAzure])

    useEffect(() => {
        if (hash) onLoginAzureRedirect()
        if (localStorage.getItem('authToken')) navigate(routes.home)
    }, [hash, navigate, onLoginAzureRedirect])

    return (
        <Container>
            <LoginBox>
                <Steps
                    colorScheme={colorScheme}
                    paddingLeft="10px"
                    activeStep={activeStep}
                >
                    {steps.map(({ label, value }) => {
                        const isSecondStep = value === secondStep
                        const isAuthPassword = authType === 'password'
                        const isAuthAzureAd = authType === 'azure_ad'

                        return (
                            <Step label={label} key={value}>
                                <LoginHeading>Welcome Back</LoginHeading>
                                <LoginSubheading>
                                    Lucas DOM Login
                                </LoginSubheading>
                                <Message
                                    title="Account Not Recognized"
                                    isOpen={isMessageOpen}
                                    setOpen={setIsMessageOpen}
                                    status={StatusType.Error}
                                    description={loginErrorMessage}
                                />
                                {isSecondStep && isAuthAzureAd ? (
                                    <div>Azure AD auth...</div>
                                ) : (
                                    <>
                                        <InputsBox>
                                            <InputBox margin="16px 0">
                                                <InputText>EMAIL</InputText>
                                                <LoginInput
                                                    autoFocus
                                                    variant="login"
                                                    type="email"
                                                    placeholder="Enter your company email"
                                                    onKeyPress={handleKeypress}
                                                    isDisabled={
                                                        isSecondStep &&
                                                        isAuthPassword
                                                    }
                                                    errorMessage={
                                                        errors.email?.message
                                                    }
                                                    borderError={!!errors.email}
                                                    register={register}
                                                    validate={value => {
                                                        if (!value)
                                                            return 'This field cannot be left blank'
                                                        if (
                                                            !validateEmail(
                                                                value
                                                            )
                                                        )
                                                            return 'Please enter a valid email address format: "abc@gmail.com"'
                                                        return true
                                                    }}
                                                />
                                            </InputBox>
                                            {isSecondStep && isAuthPassword && (
                                                <InputBox margin="16px 0 8px 0">
                                                    <InputText>
                                                        PASSWORD
                                                    </InputText>
                                                    <LoginInput
                                                        autoFocus
                                                        variant="login"
                                                        type="password"
                                                        placeholder="Enter your company password"
                                                        onKeyPress={handleKeypress}
                                                        errorMessage={
                                                            errors.password
                                                                ?.message
                                                        }
                                                        borderError={
                                                            !!errors.password
                                                        }
                                                        register={register}
                                                        validate={value =>
                                                            !!value ||
                                                            'This field cannot be left blank'
                                                        }
                                                    />
                                                </InputBox>
                                            )}
                                        </InputsBox>
                                        {isSecondStep && isAuthPassword && (
                                            <LinkBox>
                                                <Link
                                                    to={routes.forgotPassword}
                                                    as={DomLink}
                                                >
                                                    <ForgotPasswordText>
                                                        Forgot your password?
                                                    </ForgotPasswordText>
                                                </Link>
                                            </LinkBox>
                                        )}
                                    </>
                                )}
                            </Step>
                        )
                    })}
                </Steps>
                <StepsButtonsBox>
                    {authType !== 'azure_ad' && (
                        <>
                            <Button
                                size="sm"
                                variant="ghost"
                                mr={4}
                                onClick={onPrev}
                                isDisabled={activeStep === 0}
                            >
                                Prev
                            </Button>
                            <Button
                                size="sm"
                                variant="blue"
                                onClick={
                                    activeStep === steps.length - 1
                                        ? handleSubmit(onLoginPassword)
                                        : handleSubmit(onGetConfiguration)
                                }
                            >
                                {activeStep === steps.length - 1
                                    ? 'Login to DOM'
                                    : 'Next'}
                            </Button>
                        </>
                    )}
                </StepsButtonsBox>
                <Divider margin="32px 0" />
                <LoginHelpTextBox>
                    <LoginHelpText>Don’t have a DOM account yet?</LoginHelpText>
                    <LoginHelpText>
                        Contact your IT Administrator to sign up.
                    </LoginHelpText>
                </LoginHelpTextBox>
            </LoginBox>
        </Container>
    )
}
