import React, { FC } from 'react'
import {
    Modal,
    ModalContent,
    ModalOverlay,
    ModalFooter,
} from '@chakra-ui/react'
import {
    ModalBodyStyled,
    ModalHeaderText,
    CrossSmallIconStyled,
    ModalHeaderStyled,
    DividerStyled
} from './styles'

interface IComponentModal {
    isOpen: boolean
    onClose: () => void
    headerText: string
    reactComponent: React.ReactNode
    scrollBehavior?: 'inside' | 'outside'
    size?: string
    footerComponent?: React.ReactNode
}

export const ComponentModal: FC<IComponentModal> = ({
    isOpen,
    onClose,
    headerText,
    reactComponent,
    scrollBehavior = 'inside',
    size = '4xl',
    footerComponent,
}) => {
    return (
        <Modal
            size={size}
            isOpen={isOpen}
            onClose={onClose}
            scrollBehavior={scrollBehavior}
        >
            <ModalOverlay />
            <ModalContent alignSelf="center" backgroundColor="blueWhite.999">
                <ModalHeaderStyled>
                    <ModalHeaderText>{headerText}</ModalHeaderText>
                    <CrossSmallIconStyled onClick={onClose} />
                </ModalHeaderStyled>
                <DividerStyled />
                <ModalBodyStyled>
                    {reactComponent}
                </ModalBodyStyled>
                <ModalFooter>{footerComponent}</ModalFooter>
            </ModalContent>
        </Modal>
    )
}
