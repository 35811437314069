import React, { ChangeEvent, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { SingleValue } from 'react-select'
import { Box, Divider, Stack, Text, Link } from '@chakra-ui/layout'
import { Link as DomLink, useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { toJS } from 'mobx'
import { FormControl, FormLabel, Switch } from '@chakra-ui/react'
import { routes } from 'navigation/routes'
import { CounteredInput } from 'components/CounteredInput'
import { NAME_LENGTH } from 'constants/rulesSets'
import { CustomSelectContainer as Select } from './content/Select'
import { ISelectOption } from 'types/core'
import { customeAttributeStore } from 'stores'
import {
    BottomButtonsBox,
    BottomRightButtons,
    BoxStyled,
    InputsBox,
    NavTitle,
    OutlinedButton,
    SetRulesButton,
    Title,
    TitleBox,
    MainContainer,
} from './styles'

interface RulesSetsForm {
    name: string
}

export const CreateNewCategoryPage = observer(() => {
    const [orderSource, setOrderSource] =
        useState<SingleValue<ISelectOption>>(null)
    const [status, setStatus] = useState<boolean>(false)

    const { id = '' } = useParams()
    const {
        register,
        watch,
        setValue,
        formState: { errors },
    } = useForm<RulesSetsForm>({ mode: 'onChange', reValidateMode: 'onChange' })

    const nameRemaining = NAME_LENGTH - watch('name', '')?.length
    const pageType = id ? 'Edit ' : 'Create '
    const editCategoryData = toJS(customeAttributeStore.editCategoryItemData)

    const navigate = useNavigate()
    const onCancel = (): void => navigate(routes.categoryList)

    const onCreate = async () => {
        await customeAttributeStore.saveNewCatgeoryItem({
            body: {
                name: watch('name'),
                attributeSourceName: orderSource ? orderSource.label : null,
                status: true,
            },
        })
        if (toJS(customeAttributeStore.errorMessage) === 'success')
            navigate(routes.categoryList)
    }

    const onEdit = async () => {
        await customeAttributeStore.updateCategoryItem({
            id: id,
            body: {
                name: watch('name'),
                attributeSourceName: orderSource ? orderSource.label : null,
                status: status,
            },
        })
        if (toJS(customeAttributeStore.errorMessage) === 'success')
            navigate(routes.categoryList)
    }

    useEffect(() => {
        customeAttributeStore.createCategorySources()
        setStatus(editCategoryData?.status)
        // Need for improvement. Warnings console
        /* eslint-disable */
    }, [editCategoryData?.categoryId])

    useEffect(() => {
        customeAttributeStore.getEditableCategoryItemDetails(id)
        return () => {
            customeAttributeStore.clean()
        }
    }, [id])

    useEffect(() => {
        if (editCategoryData?.categoryId && id) {
            setValue('name', editCategoryData.categoryName)
            setOrderSource({
                value: editCategoryData?.categoryId,
                label: editCategoryData?.attributeSourceName,
            })
        }
    }, [editCategoryData?.categoryId && editCategoryData?.attributeSourceName])

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setStatus(e?.target?.checked)
    }

    return (
        <MainContainer>
            <Stack
                as={Box}
                spacing={{ base: 8, md: 14 }}
                py={{ base: 20, md: 4 }}
            >
                <Stack>
                    <BoxStyled>
                        <TitleBox>
                            <NavTitle>
                                <Link to={routes.categoryList} as={DomLink}>
                                    Category
                                </Link>
                                {' >'} {pageType} Category
                            </NavTitle>
                            <Title>{pageType} Category</Title>
                        </TitleBox>
                        <Divider margin="8px 0" />
                        <InputsBox>
                            <Text variant="paragraphsmall">Name</Text>
                            <CounteredInput
                                placeholder="Enter Name..."
                                size="sm"
                                width="430px"
                                marginTop="8px"
                                _disabled={{ color: 'inherit' }}
                                remaining={nameRemaining}
                                isInvalid={!!errors.name}
                                description={errors.name?.message}
                                {...register('name', {
                                    required:
                                        'This field cannot be blank. Please enter a nickname to continue.',
                                    maxLength: {
                                        value: NAME_LENGTH,
                                        message: `This field max length is ${NAME_LENGTH}`,
                                    },
                                })}
                            />
                            <Text marginBottom="8px" variant="paragraphsmall">
                                Source Name
                            </Text>
                            <Select
                                value={orderSource}
                                setValue={setOrderSource}
                            />
                        </InputsBox>
                        {id && (
                            <FormControl display="flex" alignItems="center">
                                <FormLabel htmlFor="email-alerts" mb="0">
                                    Enable
                                </FormLabel>
                                <Switch
                                    variant="switchBoolean"
                                    onChange={handleChange}
                                    isChecked={status}
                                />
                            </FormControl>
                        )}
                        <Divider margin="8px 0" />
                        <BottomButtonsBox>
                            <BottomRightButtons>
                                <OutlinedButton onClick={onCancel}>
                                    Cancel
                                </OutlinedButton>
                                <SetRulesButton
                                    disabled={!watch('name')}
                                    onClick={id ? onEdit : onCreate}
                                >
                                    {id ? 'Edit ' : 'Save '} Category
                                </SetRulesButton>
                            </BottomRightButtons>
                        </BottomButtonsBox>
                    </BoxStyled>
                </Stack>
            </Stack>
        </MainContainer>
    )
})
