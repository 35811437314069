import { onTimeOrderInstance, onTimeShippingInstance } from "./instances";
export class KPIAPI {
    static async getTotalShippers(frequency: string) {
        const { data = {} } = await onTimeOrderInstance.get(`?frequency=${frequency}`)
        return data
    }

    static async getOrderSources(filter: string) {
        const { data = [] } = await onTimeShippingInstance.get(`?filter=${filter}`)
        return data
    }
}
