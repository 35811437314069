import { Grid, Flex, Box } from '@chakra-ui/layout'
import { chakra } from 'utils/chakra'

export const GridStyles = chakra(Grid, {
    height: '100%',
    templateColumns: '205px auto',
    templateRows: 'auto max-content',
    gridTemplateAreas: `"sidebar editor" "footer  footer"`,
    rowGap: '32px',
    padding: '32px',
    overflow: 'auto',
})

export const EditorBox = chakra(Flex, {
    marginTop: '25px',
    gridArea: 'editor',
    direction: 'column',
})

export const EditorSidebarBox = chakra(Box, {
    as: 'aside',
    width: '176px',
    overflow: 'auto',
    gridArea: 'sidebar',
})
