import React from 'react'
import {
    AdminPagesIcon,
    ChannelsIcon,
    CreateNewRuleIcon,
    DashboardIcon,
    HideNavigationIcon,
    LogOutIcon,
    MyRulesIcon,
    OrderStatusTrackingIcon,
    RuleApprovalsIcon,
    RulePrioritizationIcon,
    Simulate,
    UserPagesIcon,
    ReportIcon,
    UsersIcon,
    AttributeConfigurationIcon,
    FeatureFlagsIcon,
} from 'icons'

const iconProps = {
    boxSize: '20px',
}

const showHide = {
    id: 'showHide',
    name: 'Show Navigation',
    icon: <HideNavigationIcon boxSize="22px" />,
}

const logOut = {
    id: 'logOut',
    name: 'Log Out',
    icon: <LogOutIcon {...iconProps} />,
}

export const sidebarTopListIcons = [
    showHide,
    {
        id: 'dashboard',
        name: 'Dashboard',
        icon: <DashboardIcon {...iconProps} />,
    },
    {
        id: 'myRules',
        name: 'My Rules',
        icon: <MyRulesIcon {...iconProps} />,
    },
    {
        id: 'rulePrioritization',
        name: 'Rule Prioritization',
        icon: <RulePrioritizationIcon {...iconProps} />,
    },
    {
        id: 'ruleApprovals',
        name: 'Rule Approvals',
        icon: <RuleApprovalsIcon {...iconProps} />,
    },
    {
        id: 'reports',
        name: 'Reports',
        icon: <ReportIcon {...iconProps} />,
    },
    {
        id: 'orderStatusTracking',
        name: 'Order Status Tracking',
        icon: <OrderStatusTrackingIcon {...iconProps} />,
    },
    {
        id: 'rulesSimulate',
        name: 'Rules Simulate',
        icon: <Simulate {...iconProps} />,
    },
    {
        id: 'attributeConfiguration',
        name: 'Attribute Configuration',
        icon: <AttributeConfigurationIcon {...iconProps} />,
    },
]

export const sidebarBottomListIcons = [
    {
        id: 'createNewRule',
        name: 'Create new rule',
        icon: <CreateNewRuleIcon {...iconProps} />,
    },
    logOut,
]

export const sidebarBottomListIconsWithAdminBtn = [
    {
        id: 'adminPages',
        name: 'Admin Pages',
        icon: <AdminPagesIcon {...iconProps} />,
    },
    {
        id: 'createNewRule',
        name: 'Create new rule',
        icon: <CreateNewRuleIcon {...iconProps} />,
    },
    logOut,
]

export const sidebarSupportBottomListIcons = [
    {
        id: 'adminPages',
        name: 'Admin Pages',
        icon: <AdminPagesIcon {...iconProps} />,
    },
    logOut,
]

export const sidebarAdminTopListIcons = [
    showHide,
    {
        id: 'tenants',
        name: 'Tenants',
        icon: <DashboardIcon {...iconProps} />,
    },
    {
        id: 'users',
        name: 'Users',
        icon: <UsersIcon {...iconProps} />,
    },
    {
        id: 'gateway',
        name: 'Gateway',
        icon: <ChannelsIcon {...iconProps} />,
    },
    {
        id: 'featureFlags',
        name: 'Feature Flags',
        icon: <FeatureFlagsIcon {...iconProps} />,
    },
]

export const sidebarSupportTopListIcons = [
    showHide,
    {
        id: 'tenants',
        name: 'Tenants',
        icon: <DashboardIcon {...iconProps} />,
    },
    {
        id: 'users',
        name: 'Users',
        icon: <UsersIcon {...iconProps} />,
    },
]

export const sidebarAdminBottomListIcons = [logOut]

export const sidebarAdminBottomListIconsWithAdminBtn = [
    {
        id: 'userPages',
        name: 'User Pages',
        icon: <UserPagesIcon {...iconProps} />,
    },
    logOut,
]
