import { Button, ModalContent } from '@chakra-ui/react'
import { Flex, Text, Center } from '@chakra-ui/layout'
import { CrossSmallIcon } from 'icons'
import { chakra } from 'utils/chakra'

export const EmptyRulesBox = chakra(Flex, {
    flexDirection: 'column',
    marginBottom: '20px',
})

export const InfoBox = chakra(Flex, {
    marginLeft: '10px',
    minWidth: '405px',
    justifyContent: 'space-between',
})

export const CrossSmallIconStyled = chakra(CrossSmallIcon, {
    boxSize: '20px',
    role: 'button',
    cursor: 'pointer',
    marginLeft: '5px',
})

export const PrioritizeItemBox = chakra(Flex, {
    width: '100%',
    height: '100%',
    padding: '0 25px',
    borderRadius: '8px',
    alignItems: 'center',
    backgroundColor: 'blueWhite.950',
    boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05)',
})

export const ModalContentStyled = chakra(ModalContent, {
    alignSelf: 'center',
    maxWidth: '770px',
    backgroundColor: 'blueWhite.950',
})

export const ItemsBox = chakra(Flex, {
    flexDirection: 'column',
    maxHeight: '330px',
    minHeight: '235px',
    overflow: 'auto',
    padding: '0 20px',
    sx: {
        '&::-webkit-scrollbar': {
            width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'scrollbar.75',
                borderRadius: '8px',
                minHeight: '56px',
        }
    }
})

export const EmptyRulesText = chakra(Text, {
    variant: 'h4',
    textAlign: 'center',
    marginTop: '20px',
})

export const SaveButton = chakra(Button, {
    variant: 'blue',
    height: '40px',
})

export const OutlinedButton = chakra(Button, {
    height: '40px',
    backgroundColor: 'transparent',
    color: 'buttonOutlineColor',
    border: 'solid 2px',
    borderColor: 'blue.200',
    marginRight: '7px',
    _hover: {
        color: 'white',
        backgroundColor: 'blue.200',
    },
    _active: {
        backgroundColor: 'blue.200',
    },
})

export const StyledButton = chakra(Button, {
    maxWidth: "140px",
    maxHeight: "33px",
    variant: "blueFixed"
})

export const CenterOrder = chakra(Center, {
    width: '38px',
    height: '38px',
    borderRadius: '16px',
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '20px',
    color: 'color.950',
})

export const RuleBox = chakra(Flex, {
    alignItems: 'center',
    cursor: 'auto',
    maxWidth: '705px',
    height: '40px',
    padding: '8px 20px 8px 15px',
    border: '1px solid',
    borderColor: 'blueWhite.25',
})

export const RuleItem = chakra(Flex, {
    textAlign: 'left',
    flexBasis: 1,
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '14px',
    paddingRight: '10px',
})

export const RulesBox = chakra(Flex, {
    flexDirection: 'column',
    maxHeight: '360px',
    overflow: 'auto',
    sx: {
        '&::-webkit-scrollbar': {
            width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'scrollbar.75',
            borderRadius: '8px',
            minHeight: '56px',
        }
    }
})
