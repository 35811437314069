import Icon, { IconProps } from '@chakra-ui/icon'
import React, { FC } from 'react'

export const DoubleMenuDotsIcon: FC<IconProps> = props => {
    return (
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 13 18"
            {...props}
        >
            <path
                d="M2.58887 4C2.98443 4 3.37111 3.8827 3.70001 3.66294C4.02891 3.44318 4.28525 3.13082 4.43663 2.76537C4.588 2.39992 4.62761 1.99778 4.55044 1.60982C4.47327 1.22186 4.28279 0.865492 4.00308 0.585787C3.72338 0.306082 3.36701 0.115601 2.97905 0.0384303C2.59109 -0.0387401 2.18895 0.000866562 1.8235 0.152242C1.45805 0.303617 1.14569 0.559962 0.925929 0.88886C0.706166 1.21776 0.588868 1.60444 0.588868 2C0.588868 2.53043 0.799582 3.03914 1.17466 3.41421C1.54973 3.78929 2.05844 4 2.58887 4ZM2.58887 14C2.19331 14 1.80663 14.1173 1.47773 14.3371C1.14883 14.5568 0.892484 14.8692 0.741109 15.2346C0.589734 15.6001 0.550127 16.0022 0.627298 16.3902C0.704468 16.7781 0.89495 17.1345 1.17466 17.4142C1.45436 17.6939 1.81073 17.8844 2.19869 17.9616C2.58665 18.0387 2.98878 17.9991 3.35423 17.8478C3.71969 17.6964 4.03204 17.44 4.25181 17.1111C4.47157 16.7822 4.58887 16.3956 4.58887 16C4.58887 15.4696 4.37815 14.9609 4.00308 14.5858C3.62801 14.2107 3.1193 14 2.58887 14ZM2.58887 7C2.19331 7 1.80663 7.1173 1.47773 7.33706C1.14883 7.55682 0.892484 7.86918 0.741109 8.23463C0.589734 8.60009 0.550127 9.00222 0.627298 9.39018C0.704468 9.77814 0.89495 10.1345 1.17466 10.4142C1.45436 10.6939 1.81073 10.8844 2.19869 10.9616C2.58665 11.0387 2.98878 10.9991 3.35423 10.8478C3.71969 10.6964 4.03204 10.44 4.25181 10.1111C4.47157 9.78224 4.58887 9.39556 4.58887 9C4.58887 8.46957 4.37815 7.96086 4.00308 7.58579C3.62801 7.21071 3.1193 7 2.58887 7Z"
                fill="currentColor"
            />
            <path
                d="M10.5889 4C10.9844 4 11.3711 3.8827 11.7 3.66294C12.0289 3.44318 12.2853 3.13082 12.4366 2.76537C12.588 2.39992 12.6276 1.99778 12.5504 1.60982C12.4733 1.22186 12.2828 0.865492 12.0031 0.585787C11.7234 0.306082 11.367 0.115601 10.979 0.0384303C10.5911 -0.0387401 10.189 0.000866562 9.8235 0.152242C9.45805 0.303617 9.14569 0.559962 8.92593 0.88886C8.70617 1.21776 8.58887 1.60444 8.58887 2C8.58887 2.53043 8.79958 3.03914 9.17466 3.41421C9.54973 3.78929 10.0584 4 10.5889 4ZM10.5889 14C10.1933 14 9.80663 14.1173 9.47773 14.3371C9.14883 14.5568 8.89248 14.8692 8.74111 15.2346C8.58973 15.6001 8.55013 16.0022 8.6273 16.3902C8.70447 16.7781 8.89495 17.1345 9.17466 17.4142C9.45436 17.6939 9.81073 17.8844 10.1987 17.9616C10.5866 18.0387 10.9888 17.9991 11.3542 17.8478C11.7197 17.6964 12.032 17.44 12.2518 17.1111C12.4716 16.7822 12.5889 16.3956 12.5889 16C12.5889 15.4696 12.3782 14.9609 12.0031 14.5858C11.628 14.2107 11.1193 14 10.5889 14ZM10.5889 7C10.1933 7 9.80663 7.1173 9.47773 7.33706C9.14883 7.55682 8.89248 7.86918 8.74111 8.23463C8.58973 8.60009 8.55013 9.00222 8.6273 9.39018C8.70447 9.77814 8.89495 10.1345 9.17466 10.4142C9.45436 10.6939 9.81073 10.8844 10.1987 10.9616C10.5866 11.0387 10.9888 10.9991 11.3542 10.8478C11.7197 10.6964 12.032 10.44 12.2518 10.1111C12.4716 9.78224 12.5889 9.39556 12.5889 9C12.5889 8.46957 12.3782 7.96086 12.0031 7.58579C11.628 7.21071 11.1193 7 10.5889 7Z"
                fill="currentColor"
            />
        </Icon>
    )
}
