import { GetDataFunction, ScrollerCache, ScrollerSettings } from './types'

export class ScrollerService<Data> {
    protected settings: ScrollerSettings
    protected cache: ScrollerCache<Data> = {}
    protected getData!: GetDataFunction<Data>

    protected bufferedItems: number
    protected toleranceHeight: number
    protected totalCount: number = Number.POSITIVE_INFINITY
    protected scroll: number = 0

    protected data: Data[] = []
    protected viewportHeight: number
    protected paddingTop: number = 0
    protected paddingBottom: number = 0

    constructor(settings: ScrollerSettings) {
        this.settings = settings
        this.viewportHeight = this.settings.amount * this.settings.itemHeight
        this.bufferedItems = this.settings.amount + 2 * this.settings.tolerance
        this.toleranceHeight =
            this.settings.tolerance * this.settings.itemHeight
    }

    public async init(getData: GetDataFunction<Data>, initScroll: number) {
        this.cache = {}
        this.getData = getData

        await this.scrollTo(initScroll)
    }

    public async scrollTo(scroll: number) {
        this.scroll = scroll

        await this.displayData()
    }

    public getDisplayData() {
        const result = {
            data: this.data,
            viewportHeight: this.viewportHeight,
            paddingTop: this.paddingTop,
            paddingBottom: this.paddingBottom,
        }

        return result
    }

    protected async displayData() {
        const [start, end] = this.getBounds()

        const startPage = this.getStartPage(start)
        const pageStart = this.getStartOnPage(start)
        const pageEnd = this.getEndOnPage(pageStart)

        const isPageOverflow = pageEnd >= this.settings.pageSize

        await this.fetchPages(startPage, isPageOverflow ? 2 : 1)

        if (isPageOverflow) {
            const firstPageData = this.getCachedPage(startPage).slice(pageStart)
            const secondPageData = this.getCachedPage(startPage + 1).slice(
                0,
                pageEnd - this.settings.pageSize
            )
            this.data = [...firstPageData, ...secondPageData]
        } else {
            this.data = this.getCachedPage(startPage).slice(pageStart, pageEnd)
        }

        this.paddingTop = this.calcPaddingTop(start)
        this.paddingBottom = this.calcPaddingBottom(end)
    }

    protected async fetchPages(startPage: number, pageCount: number) {
        const pages: number[] = []

        for (let page = startPage; page < startPage + pageCount; page++) {
            if (!this.isCached(page)) {
                pages.push(page)
            }
        }

        if (pages.length === 0) {
            return
        }

        const getPages = await Promise.all(
            pages.map(page => this.getData(page, this.settings.pageSize))
        )

        getPages.forEach((pageRes, index) => {
            this.cache[pages[index]] = pageRes.data
            this.totalCount = pageRes.count
        })
    }

    protected getBounds(): [number, number] {
        const start = Math.max(
            0,
            Math.floor(
                (this.scroll - this.toleranceHeight) / this.settings.itemHeight
            )
        )
        const end = Math.min(start + this.bufferedItems, this.totalCount)

        return [start, end]
    }

    protected getCachedPage(page: number) {
        return this.cache[page]
    }

    protected getStartPage(start: number) {
        return Math.floor(start / this.settings.pageSize) + 1
    }

    protected getStartOnPage(start: number) {
        return start % this.settings.pageSize
    }

    protected getEndOnPage(startOnPage: number) {
        return startOnPage + this.bufferedItems
    }

    protected calcPaddingTop(start: number) {
        return start * this.settings.itemHeight
    }

    protected calcPaddingBottom(end: number) {
        return (this.totalCount - end) * this.settings.itemHeight
    }

    protected isCached(page: number): boolean {
        return !!this.cache[page]
    }
}
