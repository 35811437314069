import { CustomerShipmentMethods, CustomerShipper } from 'types/api'
import {
    SHIPPER_CODE_MAX_LENGTH,
    SHIPPER_CODE_MIN_LENGTH,
    SHIPPER_NAME_MIN_LENGTH,
    SHIPPER_NAME_MAX_LENGTH,
    SHIPPER_DESCRIPTION_MAX_LENGTH,
    SHIPPER_DESCRIPTION_MIN_LENGTH,
    SHIPPER_ECOMMERCE_CODE_MAX_LENGTH,
    SHIPPER_ECOMMERCE_CODE_MIN_LENGTH,
    SHIPPER_ORDER_SOURCE_CODE_MAX_LENGTH,
    SHIPPER_ORDER_SOURCE_CODE_MIN_LENGTH,
    SHIPPER_CONNECTION_SETTINGS_MAX_LENGTH,
    SHIPPER_CONNECTION_SETTINGS_MIN_LENGTH,
} from 'constants/customerShipper'

export const accordionHeaderOptions = [
    'Code',
    'Name',
    'Type',
    'Ecommerce Code',
    'Order Source Code',
    'Enable',
    'Action',
]

export const accordionMethodHeaderOptions = [
    'Method Code',
    'Method Name',
    'Method Ecommerce Code',
    'Method Order Source Code',
    'Method Description',
    'Enable',
    'Action',
]

const provideValues = [
    {
        value: 'code',
        label: 'Code',
        maxLength: SHIPPER_CODE_MAX_LENGTH,
        minLength: SHIPPER_CODE_MIN_LENGTH
    },
    {
        value: 'name',
        label: 'Name',
        maxLength: SHIPPER_NAME_MAX_LENGTH,
        minLength: SHIPPER_NAME_MIN_LENGTH,
    },
]
const methodsValues = [
    {
        value: 'code',
        label: 'Code',
        maxLength: SHIPPER_CODE_MAX_LENGTH,
        minLength: SHIPPER_CODE_MIN_LENGTH
    },
    {
        value: 'name',
        label: 'Name',
        maxLength: SHIPPER_NAME_MAX_LENGTH,
        minLength: SHIPPER_NAME_MIN_LENGTH,
    },
    {
        value: 'description',
        label: 'Description',
        maxLength: SHIPPER_DESCRIPTION_MAX_LENGTH,
        minLength: SHIPPER_DESCRIPTION_MIN_LENGTH,
    },
]
const settingsProvideValues = [
    {
        value: 'ecommerceCode',
        label: 'Ecommerce Code',
        maxLength: SHIPPER_ECOMMERCE_CODE_MAX_LENGTH,
        minLength: SHIPPER_ECOMMERCE_CODE_MIN_LENGTH,
    },
    {
        value: 'orderSourceCode',
        label: 'Order Source Code',
        maxLength: SHIPPER_ORDER_SOURCE_CODE_MAX_LENGTH,
        minLength: SHIPPER_ORDER_SOURCE_CODE_MIN_LENGTH,
    },
    {
        value: 'connectionSettings',
        label: 'Connection Settings',
        maxLength: SHIPPER_CONNECTION_SETTINGS_MAX_LENGTH,
        minLength: SHIPPER_CONNECTION_SETTINGS_MIN_LENGTH,
    },
]
const settingsMethodsValues = [
    {
        value: 'ecommerceCode',
        label: 'Ecommerce Code',
        maxLength: SHIPPER_ECOMMERCE_CODE_MAX_LENGTH,
        minLength: SHIPPER_ECOMMERCE_CODE_MIN_LENGTH,
    },
    {
        value: 'orderSourceCode',
        label: 'Order Source Code',
        maxLength: SHIPPER_ORDER_SOURCE_CODE_MAX_LENGTH,
        minLength: SHIPPER_ORDER_SOURCE_CODE_MIN_LENGTH,
    },
]

export const getInputOptions = (isShipmentMethod: boolean, isSettingsModal: boolean, isProvider?: boolean) => {
    if (isShipmentMethod && isSettingsModal) return settingsMethodsValues
    if (isShipmentMethod && !isSettingsModal) return methodsValues
    if (!isShipmentMethod && isSettingsModal) return isProvider ? settingsProvideValues : settingsMethodsValues
    if (!isShipmentMethod && !isSettingsModal) return provideValues
    return []
}

export const getCustomerShipperOptions = (shipmentProvider: CustomerShipper): string[] => [
        shipmentProvider.code,
        shipmentProvider.name,
        shipmentProvider.type,
        shipmentProvider.ecommerceCode,
        shipmentProvider.orderSourceCode,
]

export const getShipmentMethodOptions = (shipmentMethod: CustomerShipmentMethods): string[] => [
        shipmentMethod.code,
        shipmentMethod.name,
        shipmentMethod.ecommerceCode,
        shipmentMethod.orderSourceCode,
        shipmentMethod.description
]
