import React, { FC } from 'react'
import { Flex, Text } from '@chakra-ui/layout'
import { observer } from 'mobx-react-lite'
import { format } from 'date-fns'
import { toJS } from 'mobx'
import { StatusTag } from 'components/StatusTag'
import { orderStore } from 'stores'
import { stringOrDash } from 'utils/stringOrDash'
import {
    mapOrderStatusToMessage,
    mapOrderStatusToTagStatus,
} from 'components/Accordion/AccordionButton/utils'
import {
    OrderIdText,
    OrderInfoBox,
    OrderInfoText,
    OrderPlacedBox,
    StatusBox,
} from 'pages/OrderDetailsPage/styles'

export const OrderInfo: FC = observer(() => {
    const orderInfo = toJS(orderStore.orderInfo)
    const tagStatus = mapOrderStatusToTagStatus(orderInfo.status)
    const tagMessage = mapOrderStatusToMessage(orderInfo.status)

    return (
        <OrderInfoBox>
            <Flex alignSelf="self-end">
                <OrderIdText>{stringOrDash(orderInfo.id)}</OrderIdText>
                <StatusBox>
                    <StatusTag
                        status={tagStatus}
                        text={tagMessage}
                        backgroundColor="color.900"
                    />
                </StatusBox>
            </Flex>
            <OrderPlacedBox>
                <OrderInfoText>ORDER PLACED</OrderInfoText>
                <Text variant="h3">
                    {orderInfo.created ? format(new Date(orderInfo.created), 'MM/dd/yyyy') : '-'}
                </Text>
            </OrderPlacedBox>
        </OrderInfoBox>
    )
})
