import { Flex, Text } from '@chakra-ui/layout'
import { chakra } from 'utils/chakra'

export const SelectionBox = chakra(Flex, {})
export const SelectBox = chakra(Flex, {})

export const ShowResultsBox = chakra(Flex, {
    marginBottom: '24px',
})

export const FiltersBox = chakra(Flex, {
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    margin: '32px 0',
})

export const IconsBox = chakra(Flex, {
    alignItems: 'center',
})

export const ShowResults = chakra(Text, {
    variant: 'paragraphsmall',
    alignSelf: 'center',
    marginRight: '16px',
})
