import { Flex } from '@chakra-ui/react'
import { chakra } from 'utils/chakra'

export const KeyDetailsBox = chakra(Flex, {
    backgroundColor: 'color.900',
    marginTop: '8px',
    padding: '16px',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)',
    justifyContent: 'space-between',
    height: '438px',
})

export const LeftContent = chakra(Flex, {
    flexDirection: 'column',
})

export const RightContent = chakra(Flex, {

})
