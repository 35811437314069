import { useState, useEffect, Dispatch, SetStateAction } from 'react'

type SetValue<T> = Dispatch<SetStateAction<T>>

function getLocalStorageValue<T>(key: string, defaultValue: T): T {
    const savedValue = localStorage.getItem(key)
    try {
        return savedValue ? (JSON.parse(savedValue) as T) : defaultValue
    } catch {
        return defaultValue
    }
}

export function removeLocalStorageValue(key: string): void {
    localStorage.removeItem(key)
}

export const useLocalStorage = <T>(
    key: string,
    defaultValue: T
): [T, SetValue<T>] => {
    const [value, setValue] = useState(() => {
        return getLocalStorageValue(key, defaultValue)
    })

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value))

        return () => removeLocalStorageValue(key)
    }, [key, value])

    return [value, setValue]
}
