import React, { FC } from 'react'
import { StatusTag } from 'components/StatusTag'
import { WorkflowRunStatus, WorkflowStatus, OrderStatus } from 'types/core'
import { mapWorkflowStatus } from './utils'
import { userStatusTransform } from 'utils/userStatusTransform'

export type IconStatus = 'success' | 'warning' | 'error'

interface RuleStatusProps {
    status: WorkflowStatus | WorkflowRunStatus | OrderStatus
}

export const RuleStatus: FC<RuleStatusProps> = ({ status }) => {
    if (!status) {
        return  (
            <StatusTag
                status="error"
                text="-"
                backgroundColor="color.900"
            />
        )
    }

    const statusType = mapWorkflowStatus(status)

    return (
        <StatusTag
            status={statusType}
            text={userStatusTransform(status)}
            backgroundColor="color.900"
        />
    )
}
