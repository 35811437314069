import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const SuccessIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M12 2L3.33974 7V17L12 22L20.6603 17V7L12 2Z" fill="#10AD10"/>
            <g filter="url(#filter0_d_10883_15386)">
                <g filter="url(#filter1_d_10883_15386)">
                    <path d="M10.8334 13.8509L16.1954 8.48828L17.0208 9.31311L10.8334 15.5005L7.12109 11.7882L7.94593 10.9634L10.8334 13.8509Z" fill="url(#paint0_linear_10883_15386)"/>
                </g>
            </g>
            <defs>
                <filter id="filter0_d_10883_15386" x="4" y="5" width="16" height="16" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="1"/>
                    <feGaussianBlur stdDeviation="0.5"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10883_15386"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_10883_15386" result="shape"/>
                </filter>
                <filter id="filter1_d_10883_15386" x="6.12109" y="8.48828" width="11.9004" height="9.01172" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="1"/>
                    <feGaussianBlur stdDeviation="0.5"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10883_15386"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_10883_15386" result="shape"/>
                </filter>
                <linearGradient id="paint0_linear_10883_15386" x1="12.071" y1="8.48828" x2="12.071" y2="15.5005" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white"/>
                    <stop offset="1" stopColor="#EFEFEF"/>
                </linearGradient>
            </defs>
        </Icon>
    )
}
