import { StepMetadatasAPI } from 'api/stepMetadatas'
import { makeAutoObservable } from 'mobx'
import { StepMetadata } from 'types/core'

export class StepsMetadataStore {
    metadatas: StepMetadata[] | null = null

    constructor() {
        makeAutoObservable(this)
    }

    public async fetch() {
        const metadatas = await StepMetadatasAPI.getAll()
        this.init(metadatas)
    }

    public clean() {
        this.metadatas = null
    }

    private init(metadatas: StepMetadata[]) {
        this.metadatas = metadatas
    }
}
