import { makeAutoObservable } from 'mobx'

export class SizeStore {
    public pageHeight: number = window.innerHeight

    constructor() {
        makeAutoObservable(this)
    }

    public async setPageHeight(height: number) {
        this.pageHeight = height
    }
}
