import { Text } from '@chakra-ui/layout'
import { RangedCellData } from 'components/DateRangePicker/types'
import React, { FC } from 'react'
import { ClickedCellLayout } from './ClickedCellLayout'
import { CenterRangedCellStyled } from '../../../styles'

interface RangedCellProps {
    cellData: RangedCellData
    selectDate: (date: Date) => void
}

export const RangedCell: FC<RangedCellProps> = ({ cellData, selectDate }) => {
    const day = cellData.date.getDate()

    return (
        <ClickedCellLayout date={cellData.date} selectDate={selectDate}>
            <CenterRangedCellStyled>
                <Text variant="paragraphsmall">{day}</Text>
            </CenterRangedCellStyled>
        </ClickedCellLayout>
    )
}
