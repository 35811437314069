import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { routes } from '../navigation/routes'
import { AzureAuthService } from '../services/azureAuth'
import { AuthAPI } from './auth'
import { ITokenRefreshCallback } from 'types/utils'

const RULES_PORT = 8080
const IDENTITY_PORT = 8089
const CONFIGURATION_PORT = 8087
const ORDERS_PORT = 8090
const KPI_PORT = 8099
const CUSTOM_ATTRIBUTE_PORT = 8093
const FEATURE_FLAG_PORT = 8096
const CUSTOMER_SHIPPER_PORT = 8098

function getBaseRulesUrl(): string {
    if (process.env.NODE_ENV === 'production') {
        return '/api/rules-engine'
    } else {
        return `http://localhost:${RULES_PORT}`
    }
}

function getBaseIdentityUrl(): string {
    if (process.env.NODE_ENV === 'production') {
        return '/api/identity'
    } else {
        return `http://localhost:${IDENTITY_PORT}`
    }
}

function getBaseConfigurationUrl(): string {
    if (process.env.NODE_ENV === 'production') {
        return '/api/configuration'
    } else {
        return `http://localhost:${CONFIGURATION_PORT}`
    }
}

function getBaseOrdersUrl(): string {
    if (process.env.NODE_ENV === 'production') {
        return '/api/orders'
    } else {
        return `http://localhost:${ORDERS_PORT}`
    }
}

function getKPIUrl(): string {
    if (process.env.NODE_ENV === 'production') {
        return '/api/report/kpis'
    } else {
        return `http://localhost:${KPI_PORT}/kpis`
    }
}

function getReportUrl(): string {
    if (process.env.NODE_ENV === 'production') {
        return '/api/report'
    } else {
        return `http://localhost:${KPI_PORT}`
    }
}

function getCustumAttribute(): string {
    if (process.env.NODE_ENV === 'production') {
        return '/api/customattributes'
    } else {
        return `http://localhost:${CUSTOM_ATTRIBUTE_PORT}`
    }
}

function getFeatureFlagUrl(): string {
    if (process.env.NODE_ENV === 'production') {
        return '/api/feature-flag'
    } else {
        return `http://localhost:${FEATURE_FLAG_PORT}`
    }
}

function getCustomerShipperUrl(): string {
    if (process.env.NODE_ENV === 'production') {
        return '/api/customer-shipper'
    } else {
        return `http://localhost:${CUSTOMER_SHIPPER_PORT}`
    }
}

const BASE_RULES_URL = getBaseRulesUrl()
const BASE_IDENTITY_URL = getBaseIdentityUrl()
const BASE_CONFIGURATION_URL = getBaseConfigurationUrl()
const BASE_ORDERS_URL = getBaseOrdersUrl()
const BASE_KPI_URL = getKPIUrl()
const BASE_REPORT_URL = getReportUrl()
const BASE_CUSTUM_ATTRIBUTE_URL = getCustumAttribute()
const BASE_FEATURE_FLAG_PORT = getFeatureFlagUrl()
const BASE_CUSTOMER_SHIPPER_PORT = getCustomerShipperUrl()

let isRefreshing: boolean = false
let refreshSubscribers: ITokenRefreshCallback[] = []

const subscribeTokenRefresh = (cb: ITokenRefreshCallback): void => {
    refreshSubscribers.push(cb)
}

const onRefreshed = (token: string): void => {
    refreshSubscribers.map((cb: ITokenRefreshCallback) => cb(token))
}

function createInstance(
    url: string,
    baseUrl: string = BASE_RULES_URL,
    config: AxiosRequestConfig & { baseURL?: never } = {}
): AxiosInstance {
    const instance = axios.create({
        baseURL: baseUrl + url,
        ...config,
    })

    instance.interceptors.request.use(config => {
        const authToken = localStorage.getItem('authToken')
        if (authToken && config.url !== '/refresh')
            config.headers.Authorization = `Bearer ${authToken}`
        return config
    })

    instance.interceptors.response.use(
        config => config,
        async error => {
            const {
                config,
                response: { status },
            } = error
            const originalRequest = config

            if (originalRequest.url === '/refresh' && status === 401) {
                localStorage.removeItem('authToken')
                localStorage.removeItem('refreshToken')
                localStorage.removeItem('authType')
                localStorage.removeItem('orderFilters')
                document.location.reload()
            }

            if (
                status === 401 &&
                localStorage.getItem('authType') === 'password'
            ) {
                if (!isRefreshing) {
                    isRefreshing = true
                    AuthAPI.refreshAuth().then(
                        ({ accessToken, refreshToken }) => {
                            localStorage.setItem('authToken', accessToken)
                            localStorage.setItem('refreshToken', refreshToken)

                            isRefreshing = false
                            onRefreshed(accessToken)
                            refreshSubscribers = []
                        }
                    )
                }
                return new Promise(resolve => {
                    subscribeTokenRefresh((token: string) => {
                        originalRequest.headers.Authorization =
                            'Bearer ' + token
                        resolve(axios(originalRequest))
                    })
                })
            }

            if (
                status === 401 &&
                localStorage.getItem('authType') === 'azure_ad'
            ) {
                try {
                    await AzureAuthService.silentRelogin()
                } catch (err) {
                    localStorage.removeItem('authToken')
                    document.location.href = `${document.location.origin}${routes.login}?hardRelogin=true`
                }
            }
            return Promise.reject(error)
        }
    )
    return instance
}

export const rulesInstance = createInstance('/')
export const stepMetadatasInstance = createInstance('/step-metadatas')
export const stepsInstance = createInstance('/steps')
export const parametersInstance = createInstance('/parameters')
export const pocTriggerInstance = createInstance('/poc-trigger')
export const workflowsInstance = createInstance('/workflows')
export const wowsInstance = createInstance('/wows')
export const auditOrder = createInstance('/audit/orders')

export const usersInstance = createInstance('/api/identity/users')

export const authInstance = createInstance('/account', BASE_IDENTITY_URL)
export const usersIdentityInstance = createInstance('/users', BASE_IDENTITY_URL)
export const rolesInstance = createInstance('/roles', BASE_IDENTITY_URL)
export const tenantsIdentityInstance = createInstance(
    '/tenants',
    BASE_IDENTITY_URL
)

export const ordersInstance = createInstance('/orders', BASE_ORDERS_URL)
export const shippersInstance = createInstance('/shippers', BASE_ORDERS_URL)
export const orderSourcesInstance = createInstance(
    '/order-sources',
    BASE_ORDERS_URL
)
export const orderShipmentsInstance = createInstance(
    '/order-shipments',
    BASE_ORDERS_URL
)
export const orderSimulateInstance = createInstance(
    '/simulated-order-sources',
    BASE_ORDERS_URL
)

export const packagesInstance = createInstance('/packages', BASE_ORDERS_URL)

export const orderFlowsInstance = createInstance(
    '/resource-types',
    BASE_CONFIGURATION_URL
)
export const onTimeOrderInstance = createInstance(
    '/on-time-order',
    BASE_KPI_URL
)
export const onTimeShippingInstance = createInstance(
    '/on-time-shipping',
    BASE_KPI_URL
)
export const dailyCountOrderInstance = createInstance(
    '/order-daily-throughput',
    BASE_KPI_URL
)
export const tenantsInstance = createInstance(
    '/tenants',
    BASE_CONFIGURATION_URL
)
export const mappingMetadataInstance = createInstance(
    '/mapping-metadata',
    BASE_CONFIGURATION_URL
)

export const featureFlagsMetadataInstance = createInstance(
    '/mapping-metadata',
    BASE_CONFIGURATION_URL
)

export const configurationsInstance = createInstance(
    '/configurations',
    BASE_CONFIGURATION_URL
)

export const reportInstence = createInstance(
    '/reports/ontime-shipping',
    BASE_REPORT_URL
)
export const reportsShipperInstence = createInstance(
    '/reports/shippers',
    BASE_REPORT_URL
)

export const reportsMenuList = createInstance('/reports', BASE_REPORT_URL)
export const reportsProductPerformSourcesInstence = createInstance(
    '/reports/sources',
    BASE_REPORT_URL
)
export const reportsProductPerformItemsInstence = createInstance(
    '/reports/performance-by-sources',
    BASE_REPORT_URL
)
export const reportProductPerformanceBySales = createInstance(
    '/reports/product-performance-by-sales',
    BASE_REPORT_URL
)
export const reportsSourceInstance = createInstance(
    '/reports/sources',
    BASE_REPORT_URL
)
export const reportProdPerformanceByTotalOrders = createInstance(
    '/reports/orders-shipped-by-shippers',
    BASE_REPORT_URL
)
export const reportErrors = createInstance('/reports/errors', BASE_REPORT_URL)

export const attributeCategoryInstence = createInstance(
    '/categories',
    BASE_CUSTUM_ATTRIBUTE_URL
)

export const attributeItemsListInstence = createInstance(
    '/attributes',
    BASE_CUSTUM_ATTRIBUTE_URL
)

export const categorySaveInstence = createInstance(
    '/attributes/bulk',
    BASE_CUSTUM_ATTRIBUTE_URL
)

export const categoryInstance = createInstance(
    '/categories',
    BASE_CUSTUM_ATTRIBUTE_URL
)

export const createCategorySourcesInstence = createInstance(
    '/categories/sources',
    BASE_CUSTUM_ATTRIBUTE_URL
)

export const saveNewCategoryInstence = createInstance(
    '/categories',
    BASE_CUSTUM_ATTRIBUTE_URL
)

export const editCategoryItemInstence = createInstance(
    '/categories',
    BASE_CUSTUM_ATTRIBUTE_URL
)

export const saveEditCategoryInstence = createInstance(
    '/categories',
    BASE_CUSTUM_ATTRIBUTE_URL
)

export const featureFlagsInstence = createInstance(
    '',
    BASE_FEATURE_FLAG_PORT
)
export const customerShipperInstence = createInstance(
    '',
    BASE_CUSTOMER_SHIPPER_PORT
)
