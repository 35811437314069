import { makeAutoObservable, runInAction } from 'mobx'
import { DailyOrderAPI } from "api/dailyorder"
import * as echarts from 'echarts'
import { CsvDataFormat, graph, OrderCount, ShipperDetails, timeIntervel } from 'types/core'
import { defaulitCpountGraph, defaulitTimeIntervel, defaultCsvData } from '../pages/HomePage/content/Dashboard/widgets/KPIMetrics/OrderThroughputCard/Chart/utils'

export class DailyOrderStore {
    public dailyCountGraph: graph[] = []
    public csvDataFormat: CsvDataFormat[] = []
    public xaxisTimeInterevels: timeIntervel = []

    constructor() {
        makeAutoObservable(this)
    }

    private dynamicColor() {
        const r = Math.floor(Math.random() * 255)
        const g = Math.floor(Math.random() * 255)
        const b = Math.floor(Math.random() * 255)
        return "rgb(" + r + "," + g + "," + b + ")"
    }

    public async getOrderSources() {
        try {
            const data = await DailyOrderAPI.getTotalOrder()
            this.dailyOrder(data)
            if (data && data.length) {
                runInAction(() => {
                    data.map((x: ShipperDetails) => (
                        this.dailyCountGraph.push({
                            name: x.shipperName,
                            type: 'line',
                            smooth: true,
                            lineStyle: {
                                width: 3
                            },
                            showSymbol: false,
                            areaStyle: {
                                opacity: 1,
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 1,
                                    color: this.dynamicColor()
                                },
                                {
                                    offset: 1,
                                    color: this.dynamicColor()
                                }
                                ])
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            data: x.orderCounts.map((y: OrderCount) => y.count)
                        })
                    ))
                })

            } else {
                runInAction(() => {
                    this.initDailyCountGraph(defaulitCpountGraph)
                })
            }
            return this.dailyCountGraph
        }
        catch (err) {
            this.dailyOrder([])
            this.initDailyCountGraph(defaulitCpountGraph)
        }
    }

    public dailyOrder(orders: ShipperDetails[]) {
        this.exportAsCsv(orders)
        if (orders.length) {
            this.xaxisTimeInterevels = orders[0].orderCounts.map(x => x.time)
        }
        else {
            this.xaxisTimeInterevels = defaulitTimeIntervel
        }

    }

    public exportAsCsv(order: ShipperDetails[]) {
        if (order.length) {
            order.map((x: ShipperDetails) =>
                x.orderCounts.map((y: OrderCount) =>
                    this.csvDataFormat.push(
                        { ShipperName: x.shipperName, Count: y.count, Time: y.time },
                    )
                ))
        }
        else {
            this.initCsvDataFormat(defaultCsvData)
        }
        return this.csvDataFormat
    }

    public clean() {
        this.initDailyCountGraph ([])
        this.initCsvDataFormat([])
    }

    private initDailyCountGraph(dailyCountGraph: graph[]) {
        this.dailyCountGraph = dailyCountGraph
    }

    private initCsvDataFormat(csvDataFormat: CsvDataFormat[]) {
        this.csvDataFormat = csvDataFormat
    }
}
