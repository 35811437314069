import {
    createCategorySourcesInstence,
    editCategoryItemInstence,
    saveEditCategoryInstence,
    saveNewCategoryInstence,
    categoryInstance,
    attributeCategoryInstence,
    attributeItemsListInstence,
    categorySaveInstence,
} from './instances'
import { ISaveCategoryReqObj, UUID, ISaveAttributeList } from 'types/core'

export class CustomeAttributeAPI {
    static async getAttributeCategory(
        page: number = 1,
        size: number = 10,
        name: string = ''
    ) {
        const filter = name ? name : ''
        const { data = {} } = await attributeCategoryInstence.get(
            `?filter=${filter}&pageIndex=${page}&pageSize=${size}`
        )
        return data
    }

    static async attributeListItem(
        categoryName: string,
        sourceName?: string,
        attributeName?: string,
        page?: number,
        size?: number
    ) {
        const filter = attributeName ? attributeName : ''
        const categoryNameItem = categoryName ? categoryName : ''
        const sourceNameItem = sourceName ? sourceName : categoryName
        const { data = {} } = await attributeItemsListInstence.get(
            `?categoryName=${categoryNameItem}&sourceName=${sourceNameItem}&filter=${filter}&pageIndex=${page}&pageSize=${size}`
        )
        return data
    }

    static async saveAttributeConfig(reqObj: ISaveAttributeList) {
        const { data = {} } = await categorySaveInstence.put(``, reqObj)
        return data
    }

    static async getAll(page: number = 1, size: number = 10, name?: string) {
        const filter = name ? `${name}` : ''
        const { data = {} } = await categoryInstance.get(
            `?filter=${filter}&pageIndex=${page}&pageSize=${size}`
        )
        return data
    }

    static async getCreateCategorySources() {
        const { data = {} } = await createCategorySourcesInstence.get('')
        return data
    }

    static async saveNewCategory(reqObj: ISaveCategoryReqObj) {
        const { data = {} } = await saveNewCategoryInstence.post(``, reqObj)
        return data
    }

    static async getEditCategoryDetails(Id: string) {
        const { data = {} } = await editCategoryItemInstence.get(`/${Id}`)
        return data
    }

    static async saveEditCategoryItem(id: UUID, body: object) {
        const { data = {} } = await saveEditCategoryInstence.put(`/${id}`, {
            body: body,
        })
        return data
    }
}
