import React, { useEffect } from 'react'
import { Navigation } from 'navigation'
import { useCustomToast } from './hooks'
import { notificationStore, sizeStore } from 'stores'

export function App() {
    const customToast = useCustomToast()

    const updateDimensions = () => sizeStore.setPageHeight(window.innerHeight)

    useEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => window.removeEventListener('resize', updateDimensions)
    }, [])

    useEffect(() => {
        notificationStore.setToast(customToast)
        return () => notificationStore.clearToast()
    }, [customToast])

    return <Navigation />
}
