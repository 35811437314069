import React, { FC } from 'react'
import { CellProps } from 'react-table'
import { Button } from '@chakra-ui/react'
import { userStore } from 'stores'
import { isAdminUser } from 'utils/jwt'

export const BtnDeleteFeatureFlagsCell: FC<CellProps<boolean[]>> = ({ openDeleteModal,  row })  => {
    const roles = userStore.roles

    return (
        <Button
            size="xs"
            variant="ghost"
            colorScheme="blue"
            disabled={!isAdminUser(roles)}
            onClick={() => openDeleteModal(row.values.id)}
            >
            Delete
        </Button>
    )
}

export default BtnDeleteFeatureFlagsCell
