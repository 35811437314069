import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import 'assets/fonts'
import React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import ReactDOM from 'react-dom'
import { theme } from 'theme'
import { App } from './App'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
    <React.StrictMode>
        <ChakraProvider theme={theme}>
            <DndProvider backend={HTML5Backend}>
                <ColorModeScript
                    initialColorMode={theme.config.initialColorMode}
                />
                <App />
            </DndProvider>
        </ChakraProvider>
    </React.StrictMode>,
    document.getElementById('root')
)

reportWebVitals()
