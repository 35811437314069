import { Flex } from '@chakra-ui/layout'
import { Text } from '@chakra-ui/layout'
import { chakra } from 'utils/chakra'

export const SearchResultsBox = chakra(Flex, {
    flexDirection: 'column',
    position: 'absolute',
    top: '36px',
    right: '2px',
    maxHeight: '472px',
    width: '376px',
    padding: '24px 16px',
    borderRadius: '6px',
    backgroundColor: 'color.900',
    boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.45)',
})

export const InputBox = chakra(Flex, {
    position: 'relative',
    flexDirection: 'column',
    width: '380px',
    minWidth: '300px',
    margin: '0 5px',
})

export const ResultsName = chakra(Text, {
    variant: 'paragraphsmall',
    textTransform: 'uppercase',
    margin: '0 8px 8px 8px',
    color: 'searchResultsName',
})

export const ItemText = chakra(Text, {
    variant: 'paragraphsmall',
})

export const ViewResultsText = chakra(Text, {
    variant: 'h6',
    color: 'blue.300',
    marginRight: '10px',
})

export const ViewResultsBox = chakra(Flex, {
    alignItems: 'center',
    width: 'fit-content',
    height: '30px',
    borderRadius: '6px',
    padding: '0 8px',
    _hover: {
        backgroundColor: 'blueWhite.915',
    },
})

export const ItemBox = chakra(Flex, {
    alignItems: 'center',
    width: 'fit-content',
    height: '30px',
    borderRadius: '6px',
    padding: '0 8px',
    margin: '0 8px',
    cursor: 'pointer',
    _hover: {
        backgroundColor: 'blueWhite.915',
    },
})
