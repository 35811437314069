import React, {
    ChangeEventHandler,
    Dispatch,
    FC,
    useEffect,
    useState,
} from 'react'
import { InputSearch } from 'components/baseComponents/InputSearchCircle'
import { useDebounce } from 'hooks'

interface SearchInputProps {
    value: string
    onChange: Dispatch<string>
}

export const Input: FC<SearchInputProps> = ({ value, onChange }) => {
    const [localSearchValue, setLocalSearchValue] = useState(value)

    useEffect(() => {
        setLocalSearchValue(value)
    }, [value])

    const updateSearchValue = useDebounce((newValue: string) => {
        onChange(newValue)
    }, 800)

    const handleChangeSearchValue: ChangeEventHandler<HTMLInputElement> = event => {
        const value = event.target.value
        setLocalSearchValue(value)
        updateSearchValue(value)
    }

    return (
        <InputSearch
            placeholder="Search by Rule Nickname..."
            searchValue={localSearchValue}
            onChange={handleChangeSearchValue}
            width="40%"
            marginLeft="10px"
        />
    )
}
