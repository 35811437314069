import React from 'react'
import { RhombusWarningIcon, CircleSuccessIcon, CircleErrorIcon } from 'icons'
import { StepStatus } from '../types/core'

export const setCircleIcon = (status: StepStatus) => {
    switch(status) {
        case StepStatus.Started:
        case StepStatus.Success:
            return <CircleSuccessIcon boxSize="30px" />
        case StepStatus.Waiting:
            return <RhombusWarningIcon boxSize="30px" />
        case StepStatus.Failed:
            return <CircleErrorIcon boxSize="30px" />
        default:
            return <CircleErrorIcon boxSize="30px" />
    }
}
