import { extendTheme } from '@chakra-ui/react'
import { components } from './components'
import { colors, semanticTokens } from './colors'

export const theme = extendTheme({
    fonts: {
        body: 'Rubik, sans-serif',
        heading: 'Rubik, sans-serif',
    },
    config: {
        useSystemColorMode: false,
        initialColorMode: 'dark',
    },
    colors,
    semanticTokens,
    components,
})
