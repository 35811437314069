import { useTheme } from '@chakra-ui/system'
import React, { FC, HtmlHTMLAttributes } from 'react'
import {
    EdgeProps,
    EdgeText,
    EdgeTextProps,
    getBezierPath,
    getEdgeCenter,
    getMarkerEnd,
} from 'react-flow-renderer'

interface EdgeLayoutProps {
    edge: EdgeProps
    path?: HtmlHTMLAttributes<SVGPathElement>
    text?: Partial<EdgeTextProps>
}

export const EdgeLayout: FC<EdgeLayoutProps> = ({
    edge: {
        id,
        sourceX,
        sourceY,
        targetX,
        targetY,
        sourcePosition,
        targetPosition,
        data,
        arrowHeadType,
        markerEndId,
    },
    path: { style, ...path } = {},
    text = {},
}) => {
    const edgePath = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    })
    const markerEnd = getMarkerEnd(arrowHeadType, markerEndId)
    const [centerX, centerY] = getEdgeCenter({
        sourceX,
        sourceY,
        targetX,
        targetY,
        sourcePosition,
        targetPosition,
    })
    const theme = useTheme()

    return (
        <>
            <path
                id={id}
                style={{
                    strokeWidth: '5px',
                    ...style,
                }}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
                {...path}
            />
            <EdgeText
                x={centerX}
                y={centerY}
                label={data?.label}
                labelStyle={{ fontSize: '16px', fill: 'white' }}
                labelBgStyle={{ fill: theme.colors.gray[900] }}
                labelBgPadding={[15, 5]}
                labelBgBorderRadius={20}
                {...text}
            />
        </>
    )
}
