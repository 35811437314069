import { ISemanticTokens, IThemeColors } from 'types/core'

export const colors: IThemeColors = {
    gray: {
        50: '#FCFCFF',
        75: '#D3DAE0',
        100: '#C2C2CB',
        150: '#EAEAEA',
        200: '#F0F0F0',
        250: '#EEEEEE',
        300: '#D9D9D9',
        325: '#A2A4A6',
        350: '#DFE0EB',
        360: '#9FA6B1',
        400: '#B1B1B1',
        450: '#919191',
        500: '#5C5C5C',
        550: '#6B6B6B',
        600: '#464646',
        650: '#393939',
        675: '#D8D8D8',
        710: '#3A3A3A',
        750: '#2E2E2E',
        800: '#2D2D2D',
        900: '#232323',
        950: '#212121',
        990: '#12161E',
        1000: '#0C0C0C',
        1050: '#1A0F0F',
        1100: '#EEF3F8',
    },
    denim: '#4774A5',
    peony: {
        500: '#FA78CD',
        600: '#F575C8',
    },
    bluebell: {
        100: '#8A78FA',
    },
    sherbet: '#FF8743',
    green: {
        100: '#10AD10',
        200: '#086808',
    },
    yellow: {
        100: '#FCB61A',
        200: '#FFCE39',
        300: '#EBB700',
        400: '#FAB23D',
    },
    mintgreen: {
        500: '#84E296', // default
        600: '#50D669', // hover
        700: '#36AF4D', // active
        800: '#3C9273', // hover pagination
    },
    red: {
        500: '#FF5A43',
        600: '#FC1313',
        700: '#B50303',
    },
    blue: {
        25: '#E3E4ED',
        50: '#EAF4FE',
        60: '#EDF1F5',
        70: '#E6E8F0',
        75: '#656A90',
        100: '#F6F8FB',
        125: '#7C9CBF',
        150: '#F1F5F9',
        200: '#027AFF',
        250: '#C8DEF629',
        300: '#0C79F1',
        350: '#0074C0',
        400: '#0868D0',
        800: '#54637B',
        900: '#2A3B56',
        910: '#384B69',
        920: '#2E4364',
        930: '#24324A',
        950: '#1E293D',
        960: '#0F1F38',
        990: '#091529',
        1000: '#0D182C',
    },
    AlphaWhite: {
        50: 'rgba(162, 164, 166, 0.5)',
        100: 'rgba(217, 217, 217, 0.6)',
    },
    white: 'white',
    black: 'black',
}

export const semanticTokens: ISemanticTokens = {
    colors: {
        // black & white
        'color.50': {
            default: 'white',
            _dark: 'black',
        },
        'color.100': {
            default: 'black',
            _dark: 'white',
        },
        // grey
        'color.75': {
            default: 'gray.75',
            _dark: 'gray.650',
        },
        'color.150': {
            default: 'gray.100',
            _dark: 'gray.500',
        },
        'color.200': {
            default: 'black',
            _dark: 'gray.200',
        },
        'color.225': {
            default: 'gray.200',
            _dark: 'gray.500',
        },
        'color.250': {
            default: 'gray.150',
            _dark: 'gray.800',
        },
        'color.300': {
            default: 'black',
            _dark: 'gray.300',
        },
        'color.360': {
            default: 'black',
            _dark: 'gray.360',
        },
        'color.400': {
            default: 'gray.600',
            _dark: 'gray.400',
        },
        'color.450': {
            default: 'gray.550',
            _dark: 'gray.300',
        },
        'color.475': {
            default: 'gray.550',
            _dark: 'gray.100',
        },
        'color.500': {
            default: 'white',
            _dark: 'gray.500',
        },
        'color.550': {
            default: 'black',
            _dark: 'gray.600',
        },
        'color.600': {
            default: 'white',
            _dark: 'gray.600',
        },
        'color.800': {
            default: 'gray.50',
            _dark: 'gray.800',
        },
        'color.810': {
            default: 'white',
            _dark: 'blue.800',
        },
        'color.850': {
            default: 'gray.600',
            _dark: 'blue.800',
        },
        'color.875': {
            default: 'gray.675',
            _dark: 'blue.800',
        },
        'color.900': {
            default: 'white',
            _dark: 'blue.900',
        },
        'color.920': {
            default: 'blue.50',
            _dark: 'blue.920',
        },
        'color.940': {
            default: 'gray.900',
            _dark: 'gray.400',
        },
        'color.950': {
            default: 'gray.900',
            _dark: 'white',
        },
        //blue-black
        'blueBlack.75': {
            default: '`black`',
            _dark: 'blue.75',
        },
        'blueBlack.200': {
            default: 'gray.300',
            _dark: 'blue.75',
        },
        'blueBlack.300': {
            default: 'gray.300',
            _dark: 'blue.900',
        },
        'blueBlack.990': {
            default: 'black',
            _dark: 'blue.990',
        },
        //blue-white
        'blueWhite.25': {
            default: 'blue.25',
            _dark: 'blue.75',
        },
        'blueWhite.60': {
            default: 'blue.60',
            _dark: 'blue.900',
        },
        'blueWhite.200': {
            default: 'blue.200',
            _dark: 'white',
        },
        'blueWhite.900': {
            default: 'white',
            _dark: 'blue.900',
        },
        'blueWhite.910': {
            default: 'blue.50',
            _dark: 'blue.900',
        },
        'blueWhite.915': {
            default: 'blue.50',
            _dark: 'blue.920',
        },
        'blueWhite.920': {
            default: 'white',
            _dark: 'blue.920',
        },
        'blueWhite.930': {
            default: 'white',
            _dark: 'blue.930',
        },
        'blueWhite.940': {
            default: 'blue.200',
            _dark: 'blue.950',
        },
        'blueWhite.950': {
            default: 'white',
            _dark: 'blue.950',
        },
        'blueWhite.955': {
            default: 'gray.100',
            _dark: 'blue.950',
        },
        'blueWhite.960': {
            default: 'blue.150',
            _dark: 'blue.950',
        },
        'blueWhite.970': {
            default: 'blue.200',
            _dark: 'blue.990',
        },
        'blueWhite.980': {
            default: 'blue.70',
            _dark: 'blue.930',
        },
        'blueWhite.990': {
            default: 'blue.150',
            _dark: 'blue.990',
        },
        'blueWhite.995': {
            default: 'blue.50',
            _dark: 'blue.990',
        },
        'blueWhite.999': {
            default: 'white',
            _dark: 'blue.990',
        },
        'blueWhite.1000': {
            default: 'white',
            _dark: 'blue.1000',
        },
        'blueWhite.1100': {
            default: 'blue.1000',
            _dark: 'gray.1100',
        },
        //blue-grey
        'grayBlue.50': {
            default: 'blue.50',
            _dark: 'gray.650',
        },
        'grayBlue.100': {
            default: 'blue.50',
            _dark: 'gray.750',
        },
        'grayBlue.150': {
            default: 'blue.150',
            _dark: 'gray.800',
        },
        'grayBlue.200': {
            default: 'blue.100',
            _dark: 'gray.800',
        },
        'grayBlue.250': {
            default: 'blue.200',
            _dark: 'gray.800',
        },
        'grayBlue.300': {
            default: 'blue.150',
            _dark: 'gray.500',
        },
        'grayBlue.350': {
            default: 'blue.150',
            _dark: 'gray.600',
        },
        'grayBlue.400': {
            default: 'blue.200',
            _dark: 'white',
        },
        'grayBlue.700': {
            default: 'blue.50',
            _dark: 'gray.710',
        },
        'grayBlue.710': {
            default: 'blue.50',
            _dark: 'blue.75',
        },
        'grayBlue.720': {
            default: 'blue.50',
            _dark: 'blue.910',
        },
        'dateRangePicker.700': {
            default: 'white',
            _dark: 'gray.700',
        },
        'grayBlue.950': {
            default: 'gray.300',
            _dark: 'blue.950',
        },
        'grayBlue.990': {
            default: 'gray.250',
            _dark: 'blue.990',
        },
        // mintgreen-blue
        mintgreenBlue: {
            default: 'blue.300',
            _dark: 'mintgreen.500',
        },
        // orderTracking
        'orderTracking.100': {
            default: 'white',
            _dark: 'gray.800',
        },
        'orderTracking.150': {
            default: 'blue.400',
            _dark: 'gray.300',
        },
        'orderTracking.200': {
            default: 'blue.300',
            _dark: 'white',
        },
        'orderTracking.300': {
            default: 'gray.250',
            _dark: 'gray.800',
        },
        'orderTracking.400': {
            default: 'blue.200',
            _dark: 'mintgreen.800',
        },
        'orderTracking.500': {
            default: 'white',
            _dark: 'peony.500',
        },
        'orderTracking.600': {
            default: 'white',
            _dark: 'denim',
        },
        'orderTracking.700': {
            default: 'black',
            _dark: 'white',
        },
        //Reports
        'reports.100': {
            default: 'white',
            _dark: 'blue.900',
        },
        'reports.200': {
            default: 'black',
            _dark: 'blue.950',
        },
        'report.300': {
            default: 'white',
            _dark: 'black',
        },
        'report.400': {
            default: 'white',
            _dark: 'gray.800',
        },
        // ruleVersioning
        ruleVersioningHeader: {
            default: 'blue.150',
            _dark: 'blue.900',
        },
        ruleVersioningHover: {
            default: 'blue.25',
            _dark: 'blue.950',
        },
        // divider
        divider: {
            default: 'gray.300',
            _dark: 'blue.75',
        },
        dividerHover: {
            default: 'gray.360',
            _dark: 'blue.250',
        },
        editorDivider: {
            default: 'gray.300',
            _dark: 'blue.990',
        },
        // buttons
        buttonBg: {
            default: 'blue.200',
            _dark: 'mintgreen.500',
        },
        buttonSolidColor: {
            default: 'white',
            _dark: 'gray.900',
        },
        buttonOutlineColor: {
            default: 'blue.200',
            _dark: 'white',
        },
        outlineWithBlackHover: {
            default: 'blue.100',
            _dark: 'blue.950',
        },
        //icons
        iconButtonOutline: {
            default: 'white',
            _dark: 'gray.900',
        },
        listIconBg: {
            default: 'blue.50',
            _dark: 'gray.200',
        },
        listIconColor: {
            default: 'blue.200',
            _dark: 'gray.710',
        },
        viewButton: {
            default: 'blue.50',
            _dark: 'blue.900',
        },
        sidebarIconColor: {
            default: 'black',
            _dark: 'gray.450',
        },
        sidebarIconHover: {
            default: 'blue.300',
            _dark: 'white',
        },
        sidebarOpenActive: {
            default: 'blue.50',
            _dark: 'gray.600',
        },
        // border
        'borderInput.100': {
            default: 'gray.100',
            _dark: 'gray.600',
        },
        'borderInput.150': {
            default: 'gray.100',
            _dark: 'blue.900',
        },
        'borderInput.200': {
            default: 'gray.350',
            _dark: 'blue.960',
        },
        borderGatewayTabs: {
            default: 'gray.100',
            _dark: 'gray.710',
        },
        // background
        bgGray800: {
            default: 'white',
            _dark: 'gray.800',
        },
        bgGray700: {
            default: 'white',
            _dark: 'gray.710',
        },
        // RuleApprovalsCard
        backgroundRuleHeader: {
            default: 'black',
            _dark: 'blue.990',
        },
        // text
        infoText: {
            default: 'gray.500',
            _dark: 'gray.400',
        },
        searchInputText: {
            default: 'gray.500',
            _dark: 'gray.300',
        },
        searchResultsName: {
            default: 'gray.600',
            _dark: 'white',
        },
        adminGatewayTabs: {
            default: 'gray.600',
            _dark: 'white',
        },
        // scrollbar
        'scrollbar.75': {
            default: 'gray.75',
            _dark: 'blue.75',
        },
        //RulesModalOverlay
        modalOverlay: {
            default: 'AlphaWhite.50',
            _dark: 'AlphaWhite.100',
        },
    },
}
