import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Text } from '@react-pdf/renderer'
import { Style } from '@react-pdf/types'

interface IPdfProps {
    data?: string | number | Date
    cell1?: Style
    cell2?: Style
    cell3?: Style
}

export const Cell1: FC<IPdfProps> = observer(
    ({ data, cell1, cell2, cell3 }) => {
        return (
            <>
                {cell1 && <Text style={cell1}>{data}</Text>}
                {cell2 && <Text style={cell2}>{data}</Text>}
                {cell3 && <Text style={cell3}>{data}</Text>}
            </>
        )
    }
)
