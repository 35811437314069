import { useEffect, useState } from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { NumericPagination } from 'components/NumericPagination'
import { DatePickers } from 'pages/ReportsPage/DatePickers'
import { ReportsData } from 'pages/ReportsPage/ReportsTable'
import { ExportButton } from 'pages/ReportsPage/ExportButton'
import { getCurrentDate } from 'pages/ReportsPage/utils'
import { CustomSelectContainer } from './select'
import {
    onTimeShippingReportHeader,
    onTimeShippingReportRow,
    onTimeShippingReportStyles,
} from 'pages/ReportsPage/PDFStyles/ontimeShippingPDFStyle'
import { ReportType } from 'types/core'
import { onTimeShippingReportStore } from 'stores'
import {
    FlexFilter,
    GridContainer,
    GridFilter,
    GridItems,
    GridPagination,
    GridTable,
    GridUser,
    SubmitButton,
    TextItems,
    TextRecords,
} from '../styles'

export const OnTimeShippingReport = observer(() => {
    const [shiperName, setShiperName] = useState<string>('')
    const [fromDate, setFromDate] = useState<Date | null>(null)
    const [toDate, setToDate] = useState<Date>()
    const [status, setStatus] = useState<Boolean>(false)

    const ontimeShippingdata = toJS(
        onTimeShippingReportStore?.onTimeShippingdataTabe
    )
    const reportsItem = toJS(onTimeShippingReportStore?.toTotalItems)
    const exportItem = toJS(
        onTimeShippingReportStore?.onTimeShippingReportExport
    )

    useEffect(() => {
        onTimeShippingReportStore.getShiperNames()
        return () => {
            onTimeShippingReportStore.clean()
        }
    }, [])

    const submitDetails = async () => {
        onTimeShippingReportStore.reloadPages()
        await onTimeShippingReportStore.getReports(
            fromDate?.toLocaleDateString() || '',
            toDate?.toLocaleDateString() || '',
            shiperName
        )
        setStatus(true)
    }

    const exportDetails = async () => {
        await onTimeShippingReportStore.getExport(
            fromDate?.toLocaleDateString() || '',
            toDate?.toLocaleDateString() || '',
            shiperName
        )
    }

    const onExport = () => {
        exportDetails()
    }

    return (
        <GridContainer>
            <GridUser>
                <CustomSelectContainer onChange={setShiperName} />
            </GridUser>
            <GridFilter>
                <FlexFilter>
                    <DatePickers
                        getfromDate={setFromDate}
                        getToDate={setToDate}
                    />
                    <SubmitButton onClick={submitDetails}>Submit</SubmitButton>
                    <ExportButton
                        data={exportItem}
                        onExport={onExport}
                        reportName={ReportType.onTimeShipping}
                        fileName={`On_Time_Shipping_Report_ ${
                            fromDate?.toLocaleDateString() || '--'
                        } to ${toDate?.toLocaleDateString() || '--'}__(${
                            getCurrentDate() || '--'
                        })`}
                        curDate={getCurrentDate()}
                        totalItems={
                            onTimeShippingReportStore?.toTotalItems?.totalItems
                        }
                        columns={onTimeShippingReportHeader}
                        pdfStyles={onTimeShippingReportStyles}
                        rowStyles={onTimeShippingReportRow}
                    />
                </FlexFilter>
            </GridFilter>
            <GridTable>
                {status &&
                    (!!ontimeShippingdata?.length ? (
                        <ReportsData reportType={ReportType.onTimeShipping} />
                    ) : (
                        <TextRecords>No Records Found</TextRecords>
                    ))}
            </GridTable>
            <GridItems>
                {status && !!ontimeShippingdata?.length && (
                    <TextItems>{reportsItem?.totalItems} Items</TextItems>
                )}
            </GridItems>
            <GridPagination>
                <NumericPagination
                    pages={onTimeShippingReportStore.pages}
                    activePage={onTimeShippingReportStore.filters.page}
                    onChangePage={page => {
                        onTimeShippingReportStore.setFilters({ page })
                        onTimeShippingReportStore.getReports(
                            fromDate?.toLocaleDateString() || '',
                            toDate?.toLocaleDateString() || '',
                            shiperName
                        )
                    }}
                />
            </GridPagination>
        </GridContainer>
    )
})
