import { Dispatch, FC, SetStateAction, useState } from 'react'
import { useColorMode } from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'
import { MultiValue, SingleValue } from 'react-select'
import { toJS } from 'mobx'
import { CustomSelect } from 'components/baseComponents/Select'
import { checkRoleForConfiguration } from 'utils/jwt'
import {
    IAttributeRequest,
    IFieldCategory,
    IselectCategoryOption,
    ISelectOption,
} from 'types/core'
import { customeAttributeStore, userStore } from 'stores'
import { reactSelectStyles } from './styles'

interface IUpdatedSelectedCategory {
    label: string
    value: string
}

interface ISelectOptionProps {
    datacategary?: IselectCategoryOption[]
    type?: string
    onChange:
        | Dispatch<SetStateAction<IAttributeRequest>>
        | ((e: IUpdatedSelectedCategory) => void)
    fieldCategory?: IFieldCategory[]
}

export const CustomSelectContainer: FC<ISelectOptionProps> = observer(
    ({ datacategary, type, onChange, fieldCategory }) => {
        const [selectValue, setSelectValue] =
            useState<SingleValue<ISelectOption>>(null)
        const { colorMode } = useColorMode()
        const currentRole = toJS(userStore.currentRoles)

        const getDataLabels = () => {
            let labels: ISelectOption[] = []

            if (type === 'category') {
                return datacategary
            } else {
                fieldCategory?.forEach((item: IFieldCategory) => {
                    labels.push({
                        value: item.categoryId,
                        label: item.categoryName,
                    })
                })
                return labels
            }
        }

        const handleChange = (
            selectedValue:
                | SingleValue<ISelectOption>
                | MultiValue<ISelectOption>
        ) => {
            if (
                selectedValue &&
                'value' in selectedValue &&
                selectedValue.value
            ) {
                customeAttributeStore.reloadPages()
                setSelectValue(selectedValue)
                onChange(selectedValue)
            }
        }

        const selectedItems = () => {
            let selectValueLabel = { value: '', label: '' }

            if (!selectValue && !!fieldCategory?.length) {
                fieldCategory?.forEach(
                    (category: IFieldCategory) =>
                        category.isSelected &&
                        ((selectValueLabel.value = category.categoryId),
                        (selectValueLabel.label = category.categoryName))
                )
                return selectValueLabel
            } else {
                return selectValue
            }
        }

        return (
            <CustomSelect
                selectValue={selectedItems()}
                options={getDataLabels() || []}
                placeholder="Attributes Category"
                onChange={handleChange}
                customStyles={reactSelectStyles(colorMode)}
                isDisabled={type !== 'category' && !checkRoleForConfiguration(currentRole)}
            />
        )
    }
)
