import { Flex, Heading, Box } from '@chakra-ui/layout'
import { chakra } from 'utils/chakra'

export const UsersBox = chakra(Flex, {
    flexDirection: 'column',
    padding: '31px 0',
    width: '100%',
})

export const TreeBox = chakra(Flex, {
    flexDirection: 'column',
    flex: '1'
})

export const TabsBox = chakra(Flex, {
    flexDirection: 'column',
    borderLeft: '1px solid',
    borderLeftColor: 'divider',
    flex: '2',
})

export const HeadingStyled = chakra(Heading, {
    as: 'h2',
    variant: 'h2',
    marginBottom: '24px'
})

export const BoxStyled = chakra(Box, {
    marginLeft: '10px'
})

export const ButtonBoxStyled = chakra(Box, {
    marginBottom: '10px'
})

export const FlexStyled = chakra(Flex, {
    flexDirection: 'column',
    marginLeft: '15px'
})

