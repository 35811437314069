import React, { Dispatch, FC, SetStateAction } from 'react'
import { Center, IconButton } from '@chakra-ui/react'
import { Button } from '@chakra-ui/button'
import { observer } from 'mobx-react-lite'
import { CollapseCheckbox } from './CollapseCheckbox'
import { workflowVersionsStore } from 'stores'
import { CrossSmallIcon } from 'icons'
import {
    ShowFiltersBox,
    HeaderBox,
    HeaderText,
    ContentBox,
    BottomBox,
    DetectedChangesText,
} from 'pages/HomePage/content/OrderStatusTracking/Filters/ShowFilters/styles'

interface IShowStatusFilter {
    isOpen: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    isRuleVersionSelection: boolean
}

export const ShowStatusFilter: FC<IShowStatusFilter> = observer(
    ({ isOpen, setOpen, isRuleVersionSelection }) => {
        const isFiltersChanged = workflowVersionsStore.isFiltersChanged()

        const handleClose = (): void => setOpen(false)

        const handleApply = async (): Promise<void> => {
            workflowVersionsStore.applyStatuses()
            isRuleVersionSelection
                ? await workflowVersionsStore.fetchWorkflowByHeaderId()
                : await workflowVersionsStore.fetch()
            handleClose()
        }

        return (
            <>
                {isOpen && (
                    <ShowFiltersBox>
                        <HeaderBox>
                            <HeaderText>Filter</HeaderText>
                            <Center>
                                <IconButton
                                    onClick={handleClose}
                                    aria-label="Cross Small Icon"
                                    variant="hoveredMediumWhite"
                                    icon={
                                        <CrossSmallIcon
                                            padding="5px"
                                            boxSize="25px"
                                        />
                                    }
                                />
                            </Center>
                        </HeaderBox>
                        {isFiltersChanged && (
                            <DetectedChangesText>
                                Changes was detected, please click "Apply
                                Filters" to search
                            </DetectedChangesText>
                        )}
                        <ContentBox>
                            <CollapseCheckbox />
                        </ContentBox>
                        <BottomBox>
                            <Button
                                variant="transparent"
                                marginRight="12px"
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="blue"
                                isDisabled={!isFiltersChanged}
                                onClick={handleApply}
                            >
                                Apply Filters
                            </Button>
                        </BottomBox>
                    </ShowFiltersBox>
                )}
            </>
        )
    }
)
