import { ReportType } from 'types/core'

export const setCsvHeader = (reportName: string) => {
    switch (reportName) {
        case ReportType.errorStatusReport:
            return [
                { label: 'Created Date', key: 'created' },
                { label: 'Source No.', key: 'orderSourceExternalOrderId' },
                { label: 'Ext. Source', key: 'orderSource' },
                { label: 'Delivery Date', key: 'expectedDeliveryDate' },
                { label: 'Status', key: 'status' },
                { label: 'Customer', key: 'customerCode' },
                { label: 'Distribution Source', key: 'distributionSource' },
            ]
        case ReportType.onTimeShipping:
            return [
                { label: 'Order Id (Source)', key: 'sourceId' },
                { label: 'Order Id (DOM)', key: 'orderId' },
                { label: 'Order Date', key: 'orderDate' },
                { label: 'Order Status', key: 'orderStatusDescription' },
                { label: 'Delivery (Exp)', key: 'expectedDelivery' },
                { label: 'Delivery (Act)', key: 'actualDelivery' },
            ]
        case ReportType.performanceReceivedBySalesChannel:
            return [
                { label: 'Order Date', key: 'orderDate' },
                { label: 'Quantity Received', key: 'quantityReceived' },
                { label: 'Quantity Cancelled', key: 'quantityCancelled' },
                { label: 'Quantity Open', key: 'quantityOpen' },
                { label: 'Quantity Completed', key: 'quantityCompleted' },
                { label: 'Source Name', key: 'sourceName' },
            ]
        case ReportType.performanceSalesOverDuration:
            return [
                { label: 'SKU/EIN', key: 'sku' },
                { label: 'Item Description', key: 'itemDescription' },
                { label: 'Qty', key: 'quantity' },
                { label: '% Of Orders(Avg)', key: 'percentageOfOrders' },
                { label: 'Source Name', key: 'sourceName' },
            ]
        case ReportType.performanceShippedByShippers:
            return [
                { label: 'Shipper', key: 'shipper' },
                { label: 'Method', key: 'method' },
                { label: 'Qty', key: 'quantity' },
                { label: '% Of Orders(Avg)', key: 'orderPercentage' },
            ]
        default:
            return []
    }
}
