import { startCase } from 'lodash'

export const userStatusTransform = (status: string) => {
    switch (status) {
        case 'approved':
            return 'Approved'
        case 'obsoleted':
            return 'Obsolete'
        case 'rejected':
            return 'Rejected'
        case 'submitted-for-approval':
            return 'Pending'
        case 'draft':
            return 'Draft'
        default:
            return startCase(status)
    }
}

export const statusColorTransform = (status: string) => {
    switch (status) {
        case 'approved':
            return 'green.200'
        case 'obsoleted':
            return 'blue.75'
        case 'rejected':
            return 'red.700'
        case 'submitted-for-approval':
            return 'yellow.400'
        case 'draft':
            return 'peony.600'
        default:
            return 'peony.600'
    }
}

export const textColorTransform = (status: string) => {
    switch (status) {
        case 'approved':
        case 'obsoleted':
        case 'rejected':
            return 'white'
        case 'submitted-for-approval':
        case 'draft':
            return 'gray.1050'
        default:
            return 'gray.1050'
    }
}
