import { Box, BoxProps } from '@chakra-ui/react'
import { GetDataFunction, ScrollerSettings } from 'components/VirtualScroller'
import { useDebounce, useLocalStore } from 'hooks'
import { observer } from 'mobx-react-lite'
import React, { FC, useCallback, useEffect, useRef } from 'react'
import { OptionData } from '..'
import { RisenScrollerService } from './service'
import { MultiSelectScrollStore } from './store'

interface MultiSelectVirtualScrollerProps {
    container: BoxProps
    settings: ScrollerSettings
    get: GetDataFunction<OptionData>
    render: (data: OptionData) => JSX.Element
    risen: OptionData[]
    empty?: JSX.Element | null
}

export const MultiSelectVirtualScroller: FC<MultiSelectVirtualScrollerProps> =
    observer(({ container, settings, get, render, risen, empty = null }) => {
        const viewportRef = useRef<HTMLDivElement>(null)
        const scrollStore = useLocalStore(() => {
            const scrollerService = new RisenScrollerService(settings)
            return new MultiSelectScrollStore(scrollerService)
        })

        useEffect(() => {
            scrollStore.init(get, risen, viewportRef.current?.scrollTop || 0)
        }, [get, risen, scrollStore])

        const scrollHandler = useCallback(
            (scrollTop: number) => {
                scrollStore.scrollTo(scrollTop)
            },
            [scrollStore]
        )

        const debouncedScroll = useDebounce(scrollHandler, 50)

        return (
            <Box
                {...container}
                ref={viewportRef}
                overflowY="auto"
                height="min-content"
                maxHeight={scrollStore.scrollStore.viewportHeight}
                onScroll={event => {
                    debouncedScroll(event.currentTarget.scrollTop)
                }}
            >
                <Box height={scrollStore.scrollStore.paddingTop} />
                {scrollStore.scrollStore.data.length !== 0
                    ? scrollStore.scrollStore.data.map(row => render(row))
                    : empty}
                <Box height={scrollStore.scrollStore.paddingBottom} />
            </Box>
        )
    })
