import { ParametersAPI } from 'api/parameters'
import { notificationStore } from 'stores'
import { OptionData } from 'components/baseComponents/Selects'
import { GetDataResult } from 'components/VirtualScroller'
import { UUID } from 'types/core'

interface AvailableValues {
    parameterId: UUID
    search?: string | string[]
    page?: number
    size?: number
    dependencies?: object
}

export class AvailableValuesService {

    public static async getValues({
        parameterId,
        search,
        page,
        size,
        dependencies,
    }: AvailableValues): Promise<GetDataResult<OptionData>> {
        const getSearchValue = (search: AvailableValues["search"]) => {
            if (search) {
                return Array.isArray(search) ? search : [search]
            }
        }
        const searchValue = getSearchValue(search)
        const response = await ParametersAPI.getAvailableValues(
            parameterId,
            searchValue,
            page,
            size,
            dependencies,
        )

        return {
            data: response.items.map(item => ({
                label: item.displayValue,
                value: item.key,
            })),
            count: response.totalItems,
            totalPages: response.totalPages,
        }
    }

    public static async changeValue(parameterId: UUID, value: string) {
        try {
            return await ParametersAPI.changeValue(parameterId, value)
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }
}
