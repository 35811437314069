import {
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
} from '@chakra-ui/accordion'
import { Box, Text } from '@chakra-ui/layout'
import { FC } from 'react'

interface AccordionContentProps {
    icon: JSX.Element
    title: string
}

export const AccordionContent: FC<AccordionContentProps> = ({
    icon,
    title,
    children,
}) => {
    return (
        <AccordionItem>
            <AccordionButton>
                {icon}
                <Box flex="1" textAlign="left" marginLeft="8px">
                    <Text>{title}</Text>
                </Box>
                <AccordionIcon />
            </AccordionButton>

            <AccordionPanel>{children}</AccordionPanel>
        </AccordionItem>
    )
}
