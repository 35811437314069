import {
    orderSimulateInstance,
    orderSourcesInstance,
    orderShipmentsInstance,
    ordersInstance,
    packagesInstance,
    customerShipperInstence,
} from './instances'
import {
    IOrder,
    IOrderShipment,
    IShipmentStatusHistory,
    UUID,
    IOrderExternalSource,
    ISimulateOrder,
    IOrderDistributionSource,
} from 'types/core'
import {
    IOrderFilters,
    ShippingCostRequest,
    ShippingCostResponse,
} from 'types/api'
import { PagedResponse } from './models'

export class OrderClientAPI {
    static async searchOrder(
        filters: IOrderFilters,
        isSimulated?: boolean
    ): Promise<PagedResponse<IOrder>> {
        const {
            page,
            size,
            createdFrom,
            createdTo,
            deliveryFrom,
            deliveryTo,
            statuses,
            ShipmentProviderCodes,
            orderSourceIds,
            orderNumber,
        } = filters

        const reqData =  {
            createdFrom,
            createdTo,
            deliveryFrom,
            deliveryTo,
            statuses,
            ShipmentProviderCodes,
            orderSourceIds,
            orderNumber,
            isSimulated,
        }

        const response = await ordersInstance.post(
            `/search?page=${page}&size=${size}`,
            reqData
        )
        return response.data || {}
    }

    static async searchOrderById(id: UUID): Promise<IOrder> {
        const response = await ordersInstance.get(`${id}`)
        return response.data || {}
    }

    static async createVirtualOrder(id: string, data: ISimulateOrder) {
        const response = await orderSimulateInstance.post(`/${id}/orders`, data)
        return response.data || {}
    }

    static async getOrderSources(): Promise<IOrderExternalSource[]> {
        const response = await orderSourcesInstance.get('')
        return response.data || []
    }

    static async getShippers(): Promise<IOrderDistributionSource[]> {
        const response = await customerShipperInstence.get('/active-shipment-providers')
        return response.data || []
    }

    static async shipmentCost({
        dateFrom,
        dateTo,
        page,
        size,
    }: ShippingCostRequest) {
        const response = await orderShipmentsInstance.get<ShippingCostResponse>(
            `/total-shipment-cost`,
            {
                params: {
                    dateFrom,
                    dateTo,
                    page,
                    size,
                },
            }
        )
        return response.data
    }

    static async searchOrderShipmentById(id: UUID): Promise<IOrderShipment> {
        const response = await orderShipmentsInstance.get(`${id}`)
        return response.data
    }

    static async getShipmentStatusHistoryByPackageId(packageId: UUID): Promise<IShipmentStatusHistory[]> {
        const response = await packagesInstance.get(
            `${packageId}/shipment-status-history`
        )
        return response.data
    }
}
