import { Box, Flex, Text } from '@chakra-ui/layout'
import { Accordion } from '@chakra-ui/accordion'
import { chakra } from 'utils/chakra'

export const ClearBox = chakra(Flex, {
    cursor: 'pointer',
    alignItems: 'center',
    padding: '4px 8px',
    borderRadius: '6px',
    _hover: {
        backgroundColor: 'gray.600'
    }
})

export const ClearText = chakra(Text, {
    variant: 'h6',
})

export const IconBox = chakra(Flex, {
    alignItems: 'center',
})

export const AccordionStyled = chakra(Accordion, {
    margin: '8px 0',
    variant: 'unstyled',
})

export const BoxStyled = chakra(Box, {
    borderRadius: '16px',
    padding: '24px',
    backgroundColor: 'color.900',
    boxShadow: '0px 6px 26px rgba(16, 30, 115, 0.06)',
})

export const AccordionMain = chakra(Box, {
    margin: '8px 0px 0px 10px',
})

export const BoxTitle = chakra(Text, {
    fontWeight: '500',
    margin: '1px 0px 0px 10px',
    fontSize: '15px',
})

export const SplitBoxWrap = chakra(Flex, {
    margin: '6px',
})

export const SplitStyled = chakra(Box, {
    border: '3px solid',
    borderColor: 'bluebell.100',
    borderRadius: '10px',
    margin: '0px 0px 0px 15px',
})

