import React, { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import { Box, Divider, Text } from '@chakra-ui/layout'
import { AccordionsHeader } from '../../AccordionsHeader'
import { auditOrderStore } from 'stores'
import { renderAccordion } from 'pages/OrderDetailsPage/utils'
import {
    AuditTrailBox,
    CollapseAllButton,
    AuditTrailHeader,
    AccordionStyled,
    NoData,
} from 'pages/OrderDetailsPage/styles'

export const AuditTrail: FC = observer(() => {
    const [opened, setOpened] = useState<number[]>([])
    const { id } = useParams()
    const auditOrders = toJS(auditOrderStore.auditOrders)

    useEffect(() => {
        if (id) auditOrderStore.getOrders(id)

        return () => {
            auditOrderStore.clean()
        }
    }, [id])

    if (!auditOrders.length) return <NoData>No Data</NoData>

    return (
        <AuditTrailBox>
            <AuditTrailHeader>
                <Text variant="h2">Audit Trail</Text>
                <CollapseAllButton
                    disabled={!opened.length}
                    onClick={() => setOpened([])}
                >
                    Collapse All
                </CollapseAllButton>
            </AuditTrailHeader>
            <Divider margin="8px 0 16px 0" />
            <AccordionsHeader />
            <AccordionStyled
                allowToggle
                allowMultiple
                index={opened}
                onChange={(expandedIndex: number[]) => setOpened(expandedIndex)}
            >
                {auditOrders.map((auditOrder) => {
                    return (
                        <Box key={auditOrder.id}>
                            {renderAccordion(auditOrder, opened)}
                        </Box>
                    )
                })}
            </AccordionStyled>
            <Divider margin="16px 0 0 0" />
        </AuditTrailBox>
    )
})
