import { makeAutoObservable } from 'mobx'
import { notificationStore } from 'stores'
import { WorkflowsService } from 'services/workflows'
import { ChangedWorkflow, ShortWorkflow, UUID, Workflow } from 'types/core'

export class WorkflowStore {
    public workflow: Workflow | null = null

    constructor() {
        makeAutoObservable(this)
    }

    public async fetch(id: UUID): Promise<Workflow> {
        const workflow = await WorkflowsService.get(id)
        this.init(workflow)
        return workflow
    }

    private init(workflow: Workflow) {
        this.workflow = workflow
    }

    public async changeWorkflow(changedWorkflow: ChangedWorkflow) {
        if (!this.workflow) {
            return
        }
        this.workflow = { ...this.workflow, ...changedWorkflow }
        try {
            await WorkflowsService.change(
                this.workflow as unknown as ShortWorkflow,
                changedWorkflow
            )
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    public clean() {
        this.workflow = null
    }
}
