import React, { Dispatch, FC, SetStateAction } from 'react'
import { observer } from 'mobx-react-lite'
import { Button } from '@chakra-ui/button'
import { Center, IconButton } from '@chakra-ui/react'
import { RenderFilters } from './RenderFilters'
import { filters } from './mock'
import { CrossSmallIcon } from 'icons'
import { orderStore } from 'stores'
import {
    ShowFiltersBox,
    HeaderBox,
    HeaderText,
    ContentBox,
    BottomBox,
    DetectedChangesText,
} from './styles'

interface IShowFilters {
    isOpen: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    isSimulated?: boolean
}

export const ShowFilters: FC<IShowFilters> = observer(({
    isOpen,
    setOpen,
    isSimulated,
}) => {
    const isFiltersChanged = orderStore.isFiltersChanged()
    const isDateInvalid = orderStore.isDateInvalid()

    const handleClose = (): void => setOpen(false)

    const handleApply = async (): Promise<void> => {
        await orderStore.applyFilters(isSimulated)
        handleClose()
    }

    return (
        <>
            {isOpen && (
                <ShowFiltersBox>
                    <HeaderBox>
                       <HeaderText>Filters</HeaderText>
                        <Center>
                            <IconButton
                                onClick={handleClose}
                                aria-label="Cross Small Icon"
                                variant="hoveredMediumWhite"
                                icon={
                                    <CrossSmallIcon padding="5px" boxSize="25px" />
                                }
                            />
                        </Center>
                    </HeaderBox>
                    {isFiltersChanged && (
                        <DetectedChangesText>
                            Changes was detected, please click "Apply Filters" to search
                        </DetectedChangesText>
                    )}
                    <ContentBox>
                        {filters.map(filter =>
                            <RenderFilters
                                key={filter.value}
                                filter={filter}
                                isEditFilters={false}
                            />
                        )}
                    </ContentBox>
                    <BottomBox>
                        <Button
                            variant="transparent"
                            marginRight="12px"
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="blue"
                            isDisabled={isDateInvalid}
                            onClick={handleApply}
                        >
                            Apply Filters
                        </Button>
                    </BottomBox>
                </ShowFiltersBox>
            )}
        </>
    )
})
