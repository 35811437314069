import React, { useEffect, useMemo, useState } from 'react'
import { Button, useDisclosure } from '@chakra-ui/react'
import { Box, Flex } from '@chakra-ui/layout'
import { observer } from 'mobx-react-lite'
import { useForm } from 'react-hook-form'
import { Column } from 'react-table'
import { toJS } from 'mobx'
import AuthNoAuthInputs from 'components/TableComponents/TableModal/ModalFormInputs/AuthNoAuthInputs'
import EditableTable, { EditingRows } from 'components/TableComponents/Table'
import { NumericPagination } from 'components/NumericPagination'
import ActionsCell from 'components/TableComponents/ActionsCell'
import CustomModal from 'components/TableComponents/TableModal'
import { authNoAuthStore, notificationStore, tenantsStore } from 'stores'
import { AuthNoAuth } from 'types/core'
import { AuthNoAuthApi } from 'api/authNoAuth'
import { StatusType } from 'types/chart'
import { ConfirmModal } from 'components/Modal/ConfirmModal'

const AuthNoAuthPage = observer(() => {
    const customToast = notificationStore.toast
    const data = toJS(authNoAuthStore.data)
    const { tenantId } = tenantsStore
    const [editingRows, setEditingRows] = useState<EditingRows>({})
    const [editingRowsName, setEditingRowsName] = useState('')
    const [deleteRowIndex, setDeleteRowIndex] = useState<number>(0)

    const { isOpen, onOpen, onClose } = useDisclosure()
    const {
        isOpen: isOpenDelete,
        onOpen: onOpenDelete,
        onClose: onCloseDelete,
    } = useDisclosure()

    const {
        handleSubmit,
        register,
        reset,
        formState: { errors, isSubmitting },
    } = useForm()

    const onCloseModal = () => {
        onClose()
        reset()
    }

    const onEdit = (id: number): void => {
        if (id) {
            setEditingRows({ [id]: true })
            setEditingRowsName(data[id].name)
        }
    }
    const onCancelEdit = (id: number): void => {
        updateMyData(+id, 'name', editingRowsName)
        setEditingRows({ [id]: false })
    }

    const onSave = (rowIndex: number): void => {
        AuthNoAuthApi.updateAuthNoAuth(data[rowIndex])
            .then(() => {
                setEditingRows({ [rowIndex]: false })
            })
            .catch(error => {
                if (error.response?.status === 400) {
                    customToast({
                        title: 'Error',
                        description: 'Name must not be empty.',
                        status: StatusType.Error,
                    })
                } else if (error.response?.status === 409) {
                    customToast({
                        title: 'Error',
                        description: 'Name already exists.',
                        status: StatusType.Error,
                    })
                } else {
                    customToast({
                        title: 'Error',
                        description: 'Something went wrong.',
                        status: StatusType.Error,
                    })
                }
            })
    }

    const onSubmit = (data: AuthNoAuth) => {
        AuthNoAuthApi.createAuthNoAuth(tenantId, data)
            .then(() => {
                authNoAuthStore.fetch(tenantId)
                onCloseModal()
            })
            .catch(error => {
                if (error.response?.status === 400) {
                    customToast({
                        title: 'Error',
                        description: 'Name must not be empty.',
                        status: StatusType.Error,
                    })
                } else if (error.response?.status === 409) {
                    customToast({
                        title: 'Error',
                        description: 'Name already exists',
                        status: StatusType.Error,
                    })
                } else {
                    customToast({
                        title: 'Error',
                        description: 'Something went wrong.',
                        status: StatusType.Error,
                    })
                }
            })
    }

    const onDeleteRow = () => {
        AuthNoAuthApi.removeAuthNoAuth(data[deleteRowIndex])
            .then(() => {
                onCancelEdit(deleteRowIndex)
                onCloseDelete()
                authNoAuthStore.fetch(tenantId)
            })
            .catch(() => {
                customToast({
                    title: 'Error',
                    description: 'Something went wrong.',
                    status: StatusType.Error,
                })
            })
    }

    const openDeleteModal = (rowIndex: number) => {
        setDeleteRowIndex(rowIndex)
        onOpenDelete()
    }

    const updateMyData = (
        rowIndex: number,
        columnId: string,
        value: string
    ): void => {
        authNoAuthStore.updateData(rowIndex, columnId, value)
    }

    const columns: Column[] = useMemo(
        () => [
            {
                Header: 'Tenant Id',
                accessor: 'tenantId',
            },
            {
                Header: 'Name',
                accessor: 'name',
                editable: true,
            },
            {
                Header: 'Actions',
                accessor: 'edit',
                Cell: ActionsCell,
            },
        ],
        []
    )

    useEffect(() => {
        tenantId && authNoAuthStore.fetch(tenantId)

        return () => {
            authNoAuthStore.clean()
        }
    }, [tenantId])

    return (
        <div>
            <Box padding="31px 0">
                <Flex color="white" overflowY="scroll">
                    <Box flex="1">
                        <EditableTable
                            data={data}
                            columns={columns}
                            editingRows={editingRows}
                            setEditingRows={setEditingRows}
                            updateMyData={updateMyData}
                            onEdit={onEdit}
                            onSave={onSave}
                            openDeleteModal={openDeleteModal}
                            onCancelEdit={onCancelEdit}
                        />
                    </Box>
                </Flex>
            </Box>
            <Button
                size="sm"
                variant="outline"
                colorScheme="blue"
                onClick={onOpen}
            >
                Add
            </Button>
            <CustomModal
                modalTitle={'Create AuthNoAuth'}
                isOpen={isOpen}
                onClose={onCloseModal}
                onSubmit={onSubmit}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
            >
                <AuthNoAuthInputs errors={errors} register={register} />
            </CustomModal>
            <NumericPagination
                marginTop="24px"
                pages={authNoAuthStore.pages}
                activePage={authNoAuthStore.filters.page}
                onChangePage={page => {
                    authNoAuthStore.setFilters({ page })
                    authNoAuthStore.fetch(tenantId)
                }}
            />
            <ConfirmModal
                isOpen={isOpenDelete}
                onClose={onCloseDelete}
                confirmFunc={onDeleteRow}
                headerText="Delete Auth No Auth"
                bodyText="Are you sure?"
                buttonText="Delete"
                colorButton="red.500"
            />
        </div>
    )
})

export default AuthNoAuthPage
