import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const SimpleArrowRightIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.20584 12.7949C5.48045 13.0684 5.92172 13.0684 6.19529 12.7949L10.8365 8.15336C11.0545 7.93555 11.0545 7.58358 10.8365 7.36578L6.19529 2.72405C5.92172 2.45038 5.48045 2.45038 5.20583 2.72405C4.93227 2.99783 4.93227 3.4391 5.20583 3.71277L9.25064 7.7624L5.20059 11.8117C4.93227 12.08 4.93227 12.5265 5.20584 12.7949Z"
                fill="currentColor"
            />
        </Icon>
    )
}
