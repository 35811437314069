import { chakra } from 'utils/chakra'
import { BaseLayout } from './BaseLayout'

export const ScreenLayout = chakra(BaseLayout, {
    baseBox: {
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
    },
    contentBox: {
        flex: '1 1 auto',
        overflow: 'auto',
    },
})
