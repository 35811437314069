import {
    AccountInfo,
    AuthenticationResult,
    PublicClientApplication,
} from '@azure/msal-browser'
import { AzureAuth, JWTPayload, UiLoginFlow } from 'types/api'
import { AuthAPI } from 'api/auth'
import { routes } from 'navigation/routes'
import { userStore } from '../stores'
import { getPayload } from '../utils/jwt'

interface IAccountInfo extends AccountInfo {
    idTokenClaims?: {
        aud?: string
    }
}

export class AzureAuthService {
    private static loginURL: string = `${document.location.origin}${routes.login}`

    private static getLoginRequest(email?: string) {
        const loginConfig = email
            ? {
                  extraQueryParameters: { domain_hint: 'organizations' },
                  loginHint: email,
              }
            : {}

        return {
            scopes: ['User.Read'],
            ...loginConfig,
        }
    }

    private static getMSALConfig(clientId: string, authorityUrl: string) {
        return {
            auth: {
                clientId,
                authority: authorityUrl,
                redirectUri: this.loginURL,
                postLogoutRedirectUri: this.loginURL,
            },
        }
    }

    public static async auth(
        { clientId, authorityUrl, uiLoginFlow }: AzureAuth,
        authType: string,
        email?: string
    ) {
        if (
            uiLoginFlow !== UiLoginFlow.Popup &&
            uiLoginFlow !== UiLoginFlow.Redirect
        )
            throw new Error('Wrong UI Login Flow')
        const msalInstance = new PublicClientApplication(
            this.getMSALConfig(clientId, authorityUrl)
        )
        const handleResponse = (
            redirectResponse: AuthenticationResult | null
        ) => {
            if (!redirectResponse) {
                msalInstance.loginRedirect(this.getLoginRequest(email))
                localStorage.setItem('clientId', clientId)
                localStorage.setItem('authorityUrl', authorityUrl)
            }
        }

        if (uiLoginFlow === UiLoginFlow.Redirect)
            msalInstance.handleRedirectPromise().then(handleResponse)

        if (uiLoginFlow === UiLoginFlow.Popup) {
            const loginResponse = await msalInstance.loginPopup(
                this.getLoginRequest(email)
            )
            const accessTokenAzureAD: string = loginResponse?.accessToken || ''
            const { accessToken = '' } = await AuthAPI.getAccountTokenAAD(
                accessTokenAzureAD
            )
            if (accessTokenAzureAD && accessToken) {
                const decoded: JWTPayload = getPayload(accessToken)
                userStore.setUser(decoded.name, decoded.role || [])
                localStorage.setItem('authToken', accessToken)
                localStorage.setItem('authType', authType)
                localStorage.setItem('clientId', clientId)
                localStorage.setItem('authorityUrl', authorityUrl)
                localStorage.setItem('uiLoginFlow', uiLoginFlow)
            }
        }
    }

    public static async silentRelogin() {
        const clientId = localStorage.getItem('clientId') || ''
        const authorityUrl = localStorage.getItem('authorityUrl') || ''

        const msalInstance = new PublicClientApplication(
            this.getMSALConfig(clientId, authorityUrl)
        )
        const account =
            msalInstance
                .getAllAccounts()
                .find(
                    ({ idTokenClaims }: IAccountInfo) =>
                        idTokenClaims && idTokenClaims.aud === clientId
                ) || ({} as AccountInfo)
        const accessTokenRequest = { scopes: ['User.Read'], account }
        const accessTokenResponse = await msalInstance.acquireTokenSilent(
            accessTokenRequest
        )
        const accessTokenAzureAD = accessTokenResponse.accessToken || ''
        const { accessToken = '' } = await AuthAPI.getAccountTokenAAD(
            accessTokenAzureAD
        )

        if (accessTokenAzureAD && accessToken) {
            localStorage.setItem('authToken', accessToken)
            document.location.reload()
        }
    }

    public static hardRelogin() {
        return this.auth(
            {
                clientId: localStorage.getItem('clientId') || '',
                authorityUrl: localStorage.getItem('authorityUrl') || '',
                uiLoginFlow: localStorage.getItem('uiLoginFlow') as UiLoginFlow,
            },
            localStorage.getItem('authType') || ''
        )
    }

    public static async logOut() {
        const clientId = localStorage.getItem('clientId') || ''
        const authorityUrl = localStorage.getItem('authorityUrl') || ''
        const uiLoginFlow = localStorage.getItem('uiLoginFlow') || ''
        const msalInstance = new PublicClientApplication(
            this.getMSALConfig(clientId, authorityUrl)
        )
        const account =
            msalInstance
                .getAllAccounts()
                .find(
                    ({ idTokenClaims }: IAccountInfo) =>
                        idTokenClaims && idTokenClaims.aud === clientId
                ) || ({} as AccountInfo)

        if (uiLoginFlow === UiLoginFlow.Popup)
            await msalInstance.logoutPopup({ account })
        if (uiLoginFlow === UiLoginFlow.Redirect) {
            localStorage.removeItem('authToken')
            localStorage.removeItem('refreshToken')
            localStorage.removeItem('authType')
            localStorage.removeItem('clientId')
            localStorage.removeItem('authorityUrl')
            localStorage.removeItem('uiLoginFlow')
            localStorage.removeItem('orderFilters')

            await msalInstance.logoutRedirect({ account })
        }
    }

    public static async redirectLoginHandler() {
        const clientId = localStorage.getItem('clientId') || ''
        const authorityUrl = localStorage.getItem('authorityUrl') || ''
        const msalInstance = new PublicClientApplication(
            this.getMSALConfig(clientId, authorityUrl)
        )
        const loginResponse = await msalInstance.handleRedirectPromise()
        const accessTokenAzureAD: string = loginResponse?.accessToken || ''
        const { accessToken = '' } = await AuthAPI.getAccountTokenAAD(
            accessTokenAzureAD
        )

        if (accessTokenAzureAD && accessToken) {
            const decoded: JWTPayload = getPayload(accessToken)
            userStore.setUser(decoded.name, decoded.role || [])
            localStorage.setItem('authToken', accessToken)
            localStorage.setItem('authType', 'azure_ad')
            localStorage.setItem('clientId', clientId)
            localStorage.setItem('authorityUrl', authorityUrl)
            localStorage.setItem('uiLoginFlow', UiLoginFlow.Redirect)
        }
    }

    public static async getAuthTokenAzureAD() {
        const clientId = localStorage.getItem('clientId') || ''
        const authorityUrl = localStorage.getItem('authorityUrl') || ''
        const msalInstance = new PublicClientApplication(
            this.getMSALConfig(clientId, authorityUrl)
        )
        const account =
            msalInstance
                .getAllAccounts()
                .find(
                    ({ idTokenClaims }: IAccountInfo) =>
                        idTokenClaims && idTokenClaims.aud === clientId
                ) || ({} as AccountInfo)

        const accessTokenRequest = { scopes: ['User.Read'], account }

        const { accessToken = '' } = await msalInstance.acquireTokenSilent(
            accessTokenRequest
        )

        return accessToken
    }
}
