import { Box, Flex, Text } from '@chakra-ui/layout'
import { PlusEllipseIcon } from 'icons'
import { chakra } from 'utils/chakra'

export const NumberInputBox = chakra(Flex, {
    position: 'relative',
    alignItems: 'center',
    marginTop: '8px',
})

export const QuantityText = chakra(Text, {
    variant: 'paragraphsmall',
})

export const DisplayAsText = chakra(Text, {
    as: 'span',
    variant: 'smalllinklight',
    color: 'gray.400',
})

export const IconBox = chakra(Box, {
    position: 'relative',
    left: '18px',
    bottom: '2px',
    zIndex: 2,
})

export const PlusEllipseIconStyled = chakra(PlusEllipseIcon, {
    color: 'white',
    boxSize: '36px',
    margin: '0 5px',
})

export const OrderQuantityBox = chakra(Flex, {
    boxSize: '12px',
})