import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { Box, Button, Checkbox, Select, Text } from '@chakra-ui/react'
import { CounteredInput } from 'components/CounteredInput'
import { CounteredInputWithAddon } from 'components/CounteredInputWithAddons'
import { SaveIcon } from 'icons'
import { ITenantTree } from 'types/core'
import { chakra } from 'utils/chakra'
import { tenantsStore } from 'stores'
import { TENANT_DOMAIN_LENGTH, TENANT_NAME_LENGTH } from 'constants/rules'
import { DividerStyled, SaveButtonBox } from '../Tabs/style'

export const TextStyled = chakra(Text, {
    fontSize: 'md',
    marginBottom: '10px',
})

export const CheckboxStyled = chakra(Checkbox, {
    color: 'color.100',
    margin: '5px 10px 15px 0px',
})

export const SelectStyled = chakra(Select, {
    color: 'searchInputText',
})

export const GeneralSettingsComponent = observer(() => {
    const authTypes = toJS(tenantsStore.authTypes)
    const isRootTenant = toJS(tenantsStore.isRootTenant)
    const selectedTenant = tenantsStore.selectedTenant

    const {
        register,
        control,
        reset,
        watch,
        formState: { errors },
        handleSubmit,
    } = useForm<ITenantTree>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: selectedTenant,
    })

    const onSubmit = async (values: ITenantTree) => {
        await tenantsStore.saveTenantSettingsData(values)
    }

    const ruleNameRemaining = TENANT_NAME_LENGTH - watch('name', '').length
    const ruleDomainRemaining =
        TENANT_DOMAIN_LENGTH - watch('domain', '').length
    const hasAuth = watch('hasAuth')

    useEffect(() => {
        reset(selectedTenant)
        // Need for improvement. Warnings console
        /* eslint-disable */
    }, [selectedTenant])

    return (
        <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <SaveButtonBox>
                    <Button
                        type="submit"
                        variant="blue"
                        size="sm"
                    >
                        <SaveIcon w={3} h={3} color="white" />
                    </Button>
                </SaveButtonBox>
                <DividerStyled />
                <TextStyled>Name</TextStyled>
                <CounteredInput
                    variant="outline"
                    placeholder="Enter tenant name..."
                    size="sm"
                    marginTop="8px"
                    remaining={ruleNameRemaining}
                    isInvalid={!!errors.name}
                    description={errors.name?.message}
                    {...register('name', {
                        required:
                            'This field cannot be blank. Please enter a tenant name to continue.',
                        maxLength: {
                            value: TENANT_NAME_LENGTH,
                            message: `This field max length is ${TENANT_NAME_LENGTH}`,
                        },
                    })}
                />
                <TextStyled>Domain</TextStyled>
                <CounteredInputWithAddon
                    variant="outline"
                    addon="@"
                    placeholder="Enter domain..."
                    size="sm"
                    remaining={ruleDomainRemaining}
                    isInvalid={!!errors.domain}
                    description={errors.domain?.message}
                    isDisabled
                    {...register('domain', {
                        required:
                            'This field cannot be blank. Please enter a domain to continue.',
                        maxLength: {
                            value: TENANT_DOMAIN_LENGTH,
                            message: `This field max length is ${TENANT_DOMAIN_LENGTH}`,
                        },
                    })}
                />
                <Controller
                    control={control}
                    key="hasMasterData"
                    name="hasMasterData"
                    defaultValue={false}
                    render={({ field: { onChange, value, ref } }) => (
                        <CheckboxStyled
                            onChange={onChange}
                            ref={ref}
                            isChecked={value}
                            defaultChecked={value}
                            checked={value}
                        >
                            Has Master Data
                        </CheckboxStyled>
                    )}
                />

                <Controller
                    control={control}
                    key="hasAuth"
                    name="hasAuth"
                    defaultValue={false}
                    render={({ field: { ref, onChange, value } }) => (
                        <CheckboxStyled
                            ref={ref}
                            onChange={onChange}
                            disabled={isRootTenant}
                            defaultChecked={value}
                            isChecked={value}
                            checked={value}
                        >
                            Has Authentication
                        </CheckboxStyled>
                    )}
                />

                <Controller
                    control={control}
                    key="allowPermissionsInheritance"
                    name="allowPermissionsInheritance"
                    defaultValue={false}
                    render={({ field: { onChange, value, ref } }) => (
                        <CheckboxStyled
                            onChange={onChange}
                            ref={ref}
                            isChecked={value}
                            defaultChecked={value}
                            checked={value}
                        >
                            Permissions Inheritance
                        </CheckboxStyled>
                    )}
                />
                <Controller
                    control={control}
                    key="enableSubTenantRouting"
                    name="enableSubTenantRouting"
                    defaultValue={false}
                    render={({ field: { onChange, value, ref } }) => (
                        <CheckboxStyled
                            onChange={onChange}
                            ref={ref}
                            isChecked={value}
                            defaultChecked={value}
                            checked={value}
                        >
                            Sub-tenant Routing
                        </CheckboxStyled>
                    )}
                />
                {hasAuth && (
                    <>
                        <TextStyled>Authentication type</TextStyled>
                        <Controller
                            control={control}
                            name="authenticationType"
                            key="authenticationType"
                            render={({ field: { onChange, value, ref } }) => (
                                <SelectStyled
                                    onChange={e => onChange(e.target.value)}
                                    value={value || ''}
                                    ref={ref}
                                >
                                    <option hidden disabled value="">
                                        Select authentication type
                                    </option>
                                    {!!authTypes.length &&
                                        authTypes.map(item => (
                                            <option
                                                key={item.code}
                                                value={item.code}
                                            >
                                                {item.name}
                                            </option>
                                        ))}
                                </SelectStyled>
                            )}
                        />
                    </>
                )}
            </form>
        </Box>
    )
})
