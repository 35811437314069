import { makeAutoObservable } from 'mobx'
import { isEqual } from 'lodash'
import { ShortWorkflow, WorkflowSubmitForApproval, CreateRuleForm } from 'types/core'
import { WorkflowsFilters, WorkflowsFiltersOptional } from 'types/api'
import { WorkflowsService } from 'services/workflows'
import { notificationStore } from './index'
import {
    SUBMIT_FOR_APPROVAL,
    APPROVE,
    REJECT,
    OBSOLETE,
    NEW_VERSION,
    MODIFY,
} from 'components/RuleHistory/mock'

const defaultFilters = {
    page: 1,
    size: 4,
    utcModifiedFrom: '',
    utcModifiedTo: '',
    statuses: '',
    filter: '',
    workflowHeaderId: '',
    workflowVersion: 0,
    isInWow: false,
}

export class WorkflowVersionsStore {
    public workflowVersions: ShortWorkflow[] = []
    public selectedWorkflow: ShortWorkflow = {} as ShortWorkflow
    public filters: WorkflowsFilters = defaultFilters
    public statuses: string = ''
    public pages: number = 0

    constructor() {
        makeAutoObservable(this)
    }

    public async fetch(): Promise<void> {
        const {
            items = [],
            totalPages,
        } = await WorkflowsService.getAll(this.filters)
        this.initPages(totalPages)
        this.init(items)
        if (items[0]) this.initSelectedWorkflow(items[0])
    }

    public async fetchWorkflowByHeaderId(): Promise<void> {
        try {
            const {
                items = [],
                totalPages,
            } = await WorkflowsService.getByWorkflowHeaderId(this.filters)
            this.initPages(totalPages)
            this.init(items)
        } catch (err){
            notificationStore.triggerErrorToast(err)
        }

    }

    public setFilters(filters: WorkflowsFiltersOptional): void {
        this.initFilters({ ...this.filters, ...filters })
    }

    public setStatuses(statuses: string): void {
        this.initStatuses(statuses)
    }

    public applyStatuses(): void {
        this.setFilters({ statuses: this.statuses })
    }

    public setSelectedWorkflow(selectedWorkflow: ShortWorkflow): void {
        this.initSelectedWorkflow(selectedWorkflow)
    }

    public async submitForApproval(comment?: string): Promise<void> {
        if (this.selectedWorkflow.approvedByUser) {
            const approval: WorkflowSubmitForApproval = {
                approverId: this.selectedWorkflow.approvedByUser.id,
                startDateTime: this.selectedWorkflow.modifiedAt,
                comment,
            }
            await WorkflowsService.submitForApproval(
                this.selectedWorkflow.id,
                approval
            )
        }
    }

    public async approve(message?: string): Promise<void> {
        await WorkflowsService.approve(this.selectedWorkflow.id, message)
    }

    public async reject(message?: string): Promise<void> {
        await WorkflowsService.reject(this.selectedWorkflow.id, message)
    }

    public async obsolete(message?: string): Promise<void> {
        await WorkflowsService.obsolete(this.selectedWorkflow.id, message)
    }

    public async modify(message?: string): Promise<void> {
        await WorkflowsService.modify(this.selectedWorkflow.id, message)
    }

    public async actionSubmit(actionName: string, values?: CreateRuleForm): Promise<void> {
        if (actionName === SUBMIT_FOR_APPROVAL) {
            await this.submitForApproval(values?.comment)
        }
        if (actionName === APPROVE) {
            await this.approve(values?.message)
        }
        if (actionName === REJECT) {
            await this.reject(values?.message)
        }
        if (actionName === OBSOLETE) {
            await this.obsolete(values?.message)
        }
        if (actionName === MODIFY) {
            await this.modify(values?.message)
        }
        if (actionName === NEW_VERSION && values) {
            await WorkflowsService.add({
                name: values.name,
                description: values.description,
                workflowHeaderId: this.selectedWorkflow.workflowHeaderId,
                basedOnWorkflowId: this.selectedWorkflow.id,
            })
        }
    }

    public isFiltersChanged(): boolean {
        return !isEqual(this.statuses, this.filters.statuses)
    }

    public isFiltersApplied(): boolean {
        return !!this.filters.workflowVersion
            || !!this.filters.statuses
            || this.filters.isInWow
    }

    private init(workflowVersions: ShortWorkflow[]) {
        this.workflowVersions = workflowVersions
    }

    private initStatuses(statuses: string) {
        this.statuses = statuses
    }

    private initFilters(filters: WorkflowsFilters) {
        this.filters = filters
    }

    private initSelectedWorkflow(selectedWorkflow: ShortWorkflow) {
        this.selectedWorkflow = selectedWorkflow
    }

    private initPages(pages: number) {
        this.pages = pages
    }

    public clearFilters() {
        this.initFilters({
            ...defaultFilters,
            workflowHeaderId: this.filters.workflowHeaderId,
        })
        this.initStatuses('')
    }

    public clean() {
        this.init([])
        this.initSelectedWorkflow({} as ShortWorkflow)
        this.initFilters(defaultFilters)
        this.initPages(0)
        this.initStatuses('')
    }
}
