import axios from 'axios';
import { makeAutoObservable } from 'mobx'
import { AuditClientAPI } from 'api/auditClient'
import { ParametersAPI } from '../api/parameters'
import { AvailableValuesResponse } from 'types/api'
import {
    IAuditOrder,
    IWorkflowRuns,
    UUID,
    IRunValues,
    WorkflowRunStatus,
} from 'types/core'

interface IRunValuesType {
    [key: UUID]: IRunValues
}

export class AuditOrderStore {
    public auditOrders: IAuditOrder[] = []
    public workflowRuns: IWorkflowRuns[] = []
    public isWorkflowRunsFailed: boolean = false
    public runValues: IRunValuesType = {}

    constructor() {
        makeAutoObservable(this)
    }

    public async getOrders(id: UUID): Promise<IAuditOrder[]> {
        const auditOrders = await AuditClientAPI.getOrders(id)
        this.initOrders(auditOrders)
        return auditOrders
    }

    private initOrders(auditOrders: IAuditOrder[]) {
        this.auditOrders = auditOrders || []
    }

    public async getWorkflowRuns(id: UUID): Promise<IWorkflowRuns[]> {
        const { runs: workflowRuns = [] } = await AuditClientAPI.getWorkflowRuns(id)
        this.initWorkflowRuns(workflowRuns)
        return workflowRuns
    }

    private initWorkflowRuns(workflowRuns: IWorkflowRuns[]) {
        this.workflowRuns = workflowRuns || []
        const isError = this.workflowRuns.find(({ workflow }) =>
            workflow.status === WorkflowRunStatus.Failed && (workflow.type === 'Rules' || !workflow.type)
        )
        if (isError) this.isWorkflowRunsFailed = true
    }

    public async getRunValues(id: UUID, stepId: UUID): Promise<IRunValues> {
        const runValues = await AuditClientAPI.getRunValues(id, stepId)
        if (runValues.parameters) runValues.parameters = await Promise.all(this.getSelectedParameters(runValues))
        this.initRunValues(runValues, stepId)
        return runValues
    }

    private initRunValues(runValues: IRunValues, stepId: UUID) {
        this.runValues = { ...this.runValues, [stepId]: runValues } || {}
    }

    private getSelectedParameters(runValues: IRunValues) {
        return runValues.parameters.map(async ({ isSelect, name, value, id }) => {
            if (isSelect && value && value.length) {
                let result: AvailableValuesResponse
                try {
                    result = await ParametersAPI.getAvailableValues(id, Array.isArray(value) ? value : [value])
                } catch (e) {
                    const runValue = { value: '', isSelect, name, id }
                    if (axios.isAxiosError(e) && e.response && e.response.status === 404) runValue.value = 'The rule was changed'
                    return runValue
                }

                const itemsToValues = result && result.items ? result.items.map(({ displayValue }) => displayValue) : null
                return { value: itemsToValues, isSelect, name, id }
            }
            return { isSelect, name, value, id }
        })
    }

    public clean() {
        this.auditOrders = []
        this.workflowRuns = []
        this.isWorkflowRunsFailed = false
        this.runValues = {}
    }
}
