import React from 'react'
import { CategorySplitBlockIcon, ErrorIcon, SuccessIcon, WarningIcon, OrderCancelIcon } from 'icons'
import { IconStatus } from './mock'
import { SplitBlockIcon } from 'icons/style'
import { IconBox, SplitStyled } from './styles'

export const getIcons = (status: IconStatus, height: number, color: string) => {

    switch (status) {
        case 'success':
            return (
                <IconBox>
                    <SuccessIcon boxSize="23px" />
                </IconBox>
            )
        case 'warning':
            return (
                <IconBox left="-13px" >
                    <WarningIcon boxSize="26px" />
                </IconBox>
            )
        case 'error':
            return (
                <IconBox>
                    <ErrorIcon boxSize="27px" />
                </IconBox>
            )
        case 'split':
            return (
                <IconBox>
                    <SplitBlockIcon>
                        <CategorySplitBlockIcon boxSize="13px" />
                    </SplitBlockIcon>
                </IconBox>
            )
        case 'orderSplitError':
            return (
                <IconBox>
                    <SplitStyled>
                        <CategorySplitBlockIcon boxSize="13px" />
                    </SplitStyled>
                </IconBox>
            )
        case 'cancelCompleted':
            return (
                <IconBox>
                    <OrderCancelIcon cancelColor={color} boxSize="20px" />
                </IconBox>
            )
        case 'cancelRequested':
            return (
                <IconBox>
                    <OrderCancelIcon cancelColor={color} boxSize="20px" />
                </IconBox>
            )
        case 'cancelFailed':
            return (
                <IconBox>
                    <OrderCancelIcon cancelColor={color} boxSize="20px" />
                </IconBox>
            )
        default:
            return (
                <IconBox>
                    <ErrorIcon boxSize="21px" />
                </IconBox>
            )
    }
}
