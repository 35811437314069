import { Box, HStack, Text } from '@chakra-ui/layout'
import { IconButton, Link } from '@chakra-ui/react'
import { chakra } from 'utils/chakra'

export const NavBox = chakra(Box, {
    width: '200px',
    paddingTop: '20px',
    marginLeft: '-10px',
    borderRight: '1px solid color.100',
    bg: 'color.900',
})

export const ReportList = chakra(Box, {
    marginTop: '10px',
    minHeight: '100vh',
    maxHeight: '100vh',
    overflowY: 'scroll',
})

export const ReportItem = chakra(HStack, {
    width: '180px',
    height: '40px',
    paddingTop: '10px',
    paddingLeft: '20px',
    paddingRight: '8px',
    paddingBottom: '8px',
    marginTop: '5px',
    fontSize: '10px',
    padding: '5px 0 5px 5px',
})

export const ClearIconBox = chakra(IconButton, {
    variant: 'hoveredMediumGray',
    position: 'absolute',
    right: '16px',
    top: '7px',
    zIndex: 1,
})

export const AllReportBox = chakra(Box, {
    paddingLeft: '15px',
    paddingBottom: '12px',
})

export const NavLink = chakra(Link, {
    fontSize: '12px',
    lineHeight: '14px',
})

export const SearchBox = chakra(Box, {
    padding: '4px',
    position: 'relative',
    display: 'inline-flex',
})

export const TextBox = chakra(Text, {
    margin: '5px',
})
