import { Box, Flex, Heading } from '@chakra-ui/layout'
import { chakra } from 'utils/chakra'


export const TenantsBox = chakra(Flex, {
    flexDirection: 'column',
    padding: "31px 0",
    width: "100%",
})

export const TreeBox = chakra(Flex, {
    flexDirection: 'column',
    flex: '1',
    minWidth: '20%',
    maxWidth: '20%',
})

export const TabsBox = chakra(Flex, {
    flexDirection: 'column',
    flex: '2',
})

export const HeadingStyled = chakra(Heading, {
    as: 'h2',
    variant: 'h2',
    marginBottom: '24px',
})

export const FeatureFlagsGlobal = chakra(Box, {
    padding: '31px 0',
})

export const FeatureFlagsBoxColor = chakra(Flex, {
    minWidth: '750px',
    maxWidth: '1000px',
    color: 'white',
})

export const FeatureFlagsBox = chakra(Flex, {
    flex: '1',
})

export const FeatureFlagsRightBox = chakra(Box, {
    padding: '31px 0',
    borderLeft: '1px solid',
    borderLeftColor: 'divider',
})

export const FeatureFlagsHeader = chakra(Heading, {
    as: 'h2',
    variant: 'h2',
    marginBottom: '24px',
})
