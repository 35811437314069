import { useEffect, useState } from 'react'
import { Box, Flex, Spacer, Text } from '@chakra-ui/layout'
import { CardContainer, ContentContainer, NameContainer } from './styles'
import { CustomSelectContainer } from './Select'
import { Chart } from './Chart'
import { Center } from '@chakra-ui/react'
import { kpiStore } from 'stores'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { ExportMenuOption } from '../ExportMenu'
import { Data } from 'react-csv/components/CommonPropTypes'

interface SelectOption {
    value: string
    label: string
}
export const SLACard = observer(() => {
    const timeFrames: SelectOption[] = [
        {
            value: 'W',
            label: 'This week',
        },
        {
            value: 'M',
            label: 'This month',
        },
        {
            value: 'Q',
            label: 'This quarter',
        },
        {
            value: 'Y',
            label: 'This year',
        },
    ]

    const [updateTimeFrame, setUpdateTimeFrame] = useState(
        timeFrames.length ? timeFrames[0].value : ''
    )

    useEffect(() => {
        kpiStore.getShipperData(updateTimeFrame)
        return () => {
            kpiStore.clean()
        }
    }, [updateTimeFrame])

    const shipperData = toJS(kpiStore.shipperData)

    return (
        <CardContainer>
            <NameContainer>
                <Flex>
                    <Text color="white" variant="h5">
                        Orders Delivered by Shippers
                    </Text>
                    <Spacer />
                    <Center>
                        <ExportMenuOption
                            data={
                                shipperData as unknown as
                                    | string
                                    | Data
                                    | (() => string | Data)
                            }
                            fileName="OrdersDelivered"
                            id="ordersDelivered"
                        />
                    </Center>
                </Flex>
            </NameContainer>
            <ContentContainer id="ordersDelivered">
                <Box>
                    <Box marginTop={'4px'}>
                        <CustomSelectContainer
                            placeholder={timeFrames.length ? timeFrames[0].label : ''}
                            options={timeFrames}
                            onChange={setUpdateTimeFrame}
                        />
                    </Box>
                    <Chart />
                </Box>
            </ContentContainer>
        </CardContainer>
    )
})
