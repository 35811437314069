import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const CheckIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 18 13"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M7.00072 10.1735L16.1927 0.980469L17.6077 2.39447L7.00072 13.0015L0.636719 6.63747L2.05072 5.22347L7.00072 10.1735Z"
                fill="currentColor"
            />

        </Icon>
    )
}
