import { Box, Center, Flex, HStack, Input, Text } from '@chakra-ui/react'
import { chakra } from 'utils/chakra'

export const SelectBox = chakra(Box, {
    position: 'relative',
    height: '26px',
})

export const SelectInput = chakra(Input, {
    height: '100%',
})

export const ButtonsBox = chakra(HStack, {
    position: 'absolute',
    height: '100%',
    top: '0px',
    right: '4px',
    spacing: '4px',
    margin: '0 5px',
})

export const IconBox = chakra(Center, {
    cursor: 'pointer',
})

export const OptionsBox = chakra(Box, {
    position: 'absolute',
    width: '100%',
    top: '100%',
    left: '0px',
    zIndex: '3',
    backgroundColor: 'blueWhite.950',
})

export const MultiSelectTextBox = chakra(Center, {
    height: '100%',
    marginRight: '5px',
    justifyContent: 'left',
})

export const MultiSelectValueBox = chakra(Flex, {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
})

export const MultiSelectValueText = chakra(Text, {
    variant: 'paragraphsmall',
    wordBreak: 'keep-all',
    isTruncated: true,
})
