import { Text } from '@chakra-ui/layout'
import { TodayCellData } from 'components/DateRangePicker/types'
import React, { FC } from 'react'
import { ClickedCellLayout } from './ClickedCellLayout'
import { CenterTodayCellStyled } from '../../../styles'

interface TodayCellProps {
    cellData: TodayCellData
    selectDate: (date: Date) => void
}

export const TodayCell: FC<TodayCellProps> = ({ cellData, selectDate }) => {
    const day = cellData.date.getDate()

    return (
        <ClickedCellLayout date={cellData.date} selectDate={selectDate}>
            <CenterTodayCellStyled>
                <Text variant="paragraphsmall">{day}</Text>
            </CenterTodayCellStyled>
        </ClickedCellLayout>
    )
}
