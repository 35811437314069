import { makeAutoObservable } from 'mobx'
import { ReportAPI } from 'api/reports'
import { PagingFilters } from 'types/api'
import {
    IProductPerformanceByTotalOrderReport,
    IShippersData,
    ITotalOrdersShippedByShipper,
} from 'types/core'

const defaultFilters: PagingFilters = {
    page: 1,
    size: 10,
}

export class ProdPerfByTotalOrdersReportStore {
    public sourceLabels: IShippersData[] = []
    public filters: PagingFilters = defaultFilters
    public pages: number = 0
    public totalOrdersReport: IProductPerformanceByTotalOrderReport[] = []
    public totalItems: number = 0
    public performanceShiperByShiperExport: ITotalOrdersShippedByShipper[] = []

    constructor() {
        makeAutoObservable(this)
    }

    public async prodPerfByTotalOrderReport(fromDate: string, todate: string) {
        try {
            const data =
                await ReportAPI.getProductPerformanceByTotalOrdersReport(
                    fromDate,
                    todate,
                    this.filters
                )
            this.init(data?.items)
            this.initTotalCount(data?.totalItems)
            this.initPages(data?.totalPages)
        } catch (err) {
            this.totalOrdersReport = []
            this.totalItems = 0
        }
    }

    public async prodPerfByTotalOrderReportExport(
        fromDate: string,
        todate: string
    ) {
        try {
            const data =
                await ReportAPI.getProductPerformanceByTotalOrdersReportExport(
                    fromDate,
                    todate
                )
            if (!!data) {
                this.initExportData(data?.items)
            }
        } catch (err) {
            this.performanceShiperByShiperExport = []
        }
    }

    private initTotalCount(items: number) {
        this.totalItems = items
    }

    private init(items: IProductPerformanceByTotalOrderReport[]) {
        this.totalOrdersReport = items || []
    }

    private initExportData(exportItems: ITotalOrdersShippedByShipper[]) {
        this.performanceShiperByShiperExport = exportItems || []
    }

    private initPages(pages: number) {
        this.pages = pages
    }

    private initFilters(filter: PagingFilters) {
        this.filters = filter
    }

    public setFilters(filters: Partial<PagingFilters>) {
        if (!filters.page) {
            this.filters = { ...this.filters, ...filters, page: 1 }
        } else {
            this.filters = { ...this.filters, ...filters }
        }
    }

    public reloadPages() {
        this.initFilters(defaultFilters)
    }

    public clean() {
        this.initFilters(defaultFilters)
        this.initPages(0)
        this.initExportData([])
        this.init([])
        this.initTotalCount(0)
    }
}
