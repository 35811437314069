import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const FloppyDiskIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 14 14"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M2.1267 13.4016C1.7129 13.4016 1.35501 13.2506 1.05305 12.9486C0.751081 12.6466 0.600098 12.2888 0.600098 11.875V2.14494C0.600098 1.71995 0.751081 1.35648 1.05305 1.05451C1.35501 0.752545 1.7129 0.601562 2.1267 0.601562H9.91071C10.112 0.601562 10.3105 0.643502 10.5063 0.727382C10.702 0.811261 10.8669 0.925896 11.0011 1.07129L12.9304 3.00051C13.0758 3.13472 13.1904 3.29968 13.2743 3.4954C13.3582 3.69112 13.4001 3.88964 13.4001 4.09095V11.875C13.4001 12.2888 13.2491 12.6466 12.9471 12.9486C12.6452 13.2506 12.2817 13.4016 11.8567 13.4016H2.1267ZM11.8567 4.14127L9.86039 2.14494H2.1267V11.875H11.8567V4.14127ZM6.99171 10.7677C7.46143 10.7677 7.86406 10.6028 8.19957 10.2729C8.53509 9.94293 8.70285 9.53752 8.70285 9.05661C8.70285 8.58688 8.53789 8.18426 8.20796 7.84874C7.87804 7.51323 7.47262 7.34547 6.99171 7.34547C6.52199 7.34547 6.11936 7.51043 5.78385 7.84036C5.44833 8.17028 5.28057 8.5757 5.28057 9.05661C5.28057 9.52633 5.44553 9.92896 5.77546 10.2645C6.10538 10.6 6.5108 10.7677 6.99171 10.7677ZM3.78752 5.38269H8.19957C8.42325 5.38269 8.60779 5.30999 8.75318 5.1646C8.89857 5.01921 8.97126 4.84027 8.97126 4.62778V3.80576C8.97126 3.59326 8.89857 3.41432 8.75318 3.26893C8.60779 3.12354 8.42325 3.05084 8.19957 3.05084H3.78752C3.57502 3.05084 3.39608 3.12354 3.25069 3.26893C3.1053 3.41432 3.0326 3.59326 3.0326 3.80576V4.62778C3.0326 4.84027 3.1053 5.01921 3.25069 5.1646C3.39608 5.30999 3.57502 5.38269 3.78752 5.38269ZM2.1267 4.14127V11.875V2.14494V4.14127Z"
                fill="currentColor"
            />
        </Icon>
    )
}
