import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const RectangleErrorIcon: FC<IconProps> = props => {
    return (
        <Icon
            width="11" height="33"
            viewBox="0 0 11 33"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect
                x="-11"
                y="0.511719"
                width="22"
                height="32"
                rx="6"
                fill="#FC1313"
            />
        </Icon>
    )
}
