export const routes = {
    home: '/',
    login: '/login',
    orderDetails: '/order-details',
    forgotPassword: '/forgot-password',
    editor: '/editor',
    createNewRule: '/create',
    createNewRuleSet: '/prioritize/create',
    changeRule: '/change',
    editRuleSet: '/prioritize/edit',
    prioritize: '/prioritize',
    approval: '/approval',
    submitApproval: '/submit',
    shippingCost: '/shipping-cost',
    admin: '/admin',
    users: '/admin/users',
    tenants: '/admin/tenants',
    channels: '/admin/channels',
    resourceOwnersAuth: '/admin/resource-owners-auth',
    authNoAuth: '/admin/authNoAuth',
    authApiKey: '/admin/auth-api-key',
    gateway: '/admin/gateway',
    featureFlags: '/admin/feature-flags',
    ruleSimulate: '/rule-simulate',
    authenticationSchema: '/admin/authentication-schema',
    reports: '/reports',
    createNewCategory: '/createNewCategory',
    editCategoy: '/editCategoy',
    categoryList:'/categoryList',
    attributeConfiguration: '/attributeConfiguration',
}
