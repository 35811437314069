import { Flex } from '@chakra-ui/layout'
import { chakra } from 'utils/chakra'

export const RuleSimulateBlock = chakra(Flex, {
    flexDirection: 'column',
    padding: '15px 15px',
    variant: 'modal',
    color: 'color.100',
})

