import { makeAutoObservable } from 'mobx'
import { ScrollerService } from './service'
import { GetDataFunction } from './types'

export class ScrollerStore<Data> {
    private scrollerService: ScrollerService<Data>

    public data: Data[] = []
    public viewportHeight: number = 0
    public paddingTop: number = 0
    public paddingBottom: number = 0

    constructor(scrollerService: ScrollerService<Data>) {
        this.scrollerService = scrollerService
        makeAutoObservable(this)
    }

    public async init(getData: GetDataFunction<Data>, initScroll: number) {
        await this.scrollerService.init(getData, initScroll)

        this.getData()
    }

    public async scrollTo(scroll: number) {
        await this.scrollerService.scrollTo(scroll)

        this.getData()
    }

    public getData() {
        const { data, viewportHeight, paddingTop, paddingBottom } =
            this.scrollerService.getDisplayData()

        this.data = data
        this.viewportHeight = viewportHeight
        this.paddingTop = paddingTop
        this.paddingBottom = paddingBottom
    }
}
