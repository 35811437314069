export const dateFormat = 'MM.dd.yyyy'

export const retryTooltipText = 
    'Once you click on the Retry All button, all selected ' +
    'failed orders will be retried.\n\n' +
    'The prcoess will be executed in the backend, so there ' +
    'will be some delay refreshing the data in the frontend. ' +
    'We will notify you once the process is completed.'
    
    export const errorStatuses: string[] = [
        'ProcessingInitError',
        'Error',
        'PreProcessingError',
        'RuleProcessingError',
        'CompletionError',
        'ValidateAddressError',
        'ErrorSentToECC',
        'ErrorAcknowledgedByECC',
        'ErrorShipment',
        'ErrorSentToIcep',
        'PostProcessingError',
        'Failed',
        'DeliveryError',
        'SplitError'
    ]