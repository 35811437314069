import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const RuleHeaderIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 29 26"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g filter="url(#filter0_d)">
                <rect
                    x="2"
                    y="1"
                    width="24.9333"
                    height="22"
                    rx="2"
                    fill="url(#paint0_linear)"
                />
                <line
                    x1="6"
                    y1="6"
                    x2="23"
                    y2="6"
                    stroke="black"
                    strokeWidth="2"
                />
                <line
                    x1="6"
                    y1="12"
                    x2="16"
                    y2="12"
                    stroke="black"
                    strokeWidth="2"
                />
                <line
                    x1="6"
                    y1="18"
                    x2="16"
                    y2="18"
                    stroke="black"
                    strokeWidth="2"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d"
                    x="0"
                    y="0"
                    width="28.9333"
                    height="26"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        result="shape"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear"
                    x1="14.4667"
                    y1="4.78125"
                    x2="14.4667"
                    y2="23"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#F4F4F4" />
                </linearGradient>
            </defs>
        </Icon>
    )
}
