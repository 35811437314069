import { Box, Heading, Text } from '@chakra-ui/layout'
import React, { FC } from 'react'

export const ErrorPage: FC = ({ children }) => {
    return (
        <Box>
            <Heading as="h1" textAlign="center">
                Error
            </Heading>
            <Text textAlign="center">{children}</Text>
        </Box>
    )
}
