import { JWTPayload, UserRole } from '../types/api'
import { SUPER_ADMIN, SUPPORT, TENANT_ADMIN } from '../constants/userRoles'
import { ICurrentRoles } from 'api/models'

function decodeBase64(str: string) {
    return decodeURIComponent(
        Array.prototype.map
            .call(atob(str), function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join('')
    )
}

export function getPayload(token: string) {
    return token ? JSON.parse(decodeBase64(token.split('.')[1])) : {}
}

export const checkRole = (role: string): boolean => {
    const token = localStorage.getItem('authToken')

    if (token) {
        const payload: JWTPayload = getPayload(token)
        const payloadRoles = payload.role

        return Array.isArray(payloadRoles)
            ? !!payloadRoles.find((payloadRole) => payloadRole === role)
            : payloadRoles === role
    }

    return false
}

export const isAdminUser = (userList?: UserRole[] | string) => {
    if (typeof userList === 'string') {
        return userList === SUPER_ADMIN || userList === TENANT_ADMIN
    }

    return !!userList?.find(
        (role: string) => role === SUPER_ADMIN || role === TENANT_ADMIN
    )
}

export const isSupportUser = (userList?: UserRole[] | string) => {
    if (typeof userList === 'string') {
        return userList === SUPPORT
    }

    return !!userList?.find((role: string) => role === SUPPORT)
}

export const getTenantId = () => {
    const token = localStorage.getItem('authToken')
    if (token) return getPayload(token)['luc:tenantid']
}

export const checkRoleForConfiguration = (currentRole: ICurrentRoles) =>
    currentRole.isTenantAdmin ||
    currentRole.isSuperAdmin ||
    currentRole.isCreator
