import { Flex, Box, Tooltip } from '@chakra-ui/react'
import { Text } from '@chakra-ui/layout'
import { chakra } from 'utils/chakra'

export const SidebarBox = chakra(Flex, {
    padding: '48px 0 10px 0',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    backgroundColor: 'blueWhite.950',
    transition: 'all 100ms ease-in-out',
    zIndex: '12',
    position: 'fixed',
    top: '0',
})

export const ItemsBox = chakra(Flex, {
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'blueWhite.950',
    flex: '1',
})

export const SidebarTooltip = chakra(Tooltip, {
    bg: 'blueWhite.900',
    borderRadius: '4px',
    placement: 'right',
    padding: '10px 10px 10px 15px',
    color: 'color.100',
    boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.25)',
})

export const IconBox = chakra(Flex, {
    alignItems: 'center',
    margin: '0 24px',
})

export const IconBoxText = chakra(Box, {
    marginLeft: '10px',
})

export const SidebarItemText = chakra(Text, {
    marginLeft: '14px',
})
