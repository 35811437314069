import { makeAutoObservable } from 'mobx'
import { IntegrationChannelsApi } from '../api/integrationChannels'
import {
    IntegrationChannel,
    IntegrationChannelTableView,
    ISelectOption,
} from 'types/core'
import { PagingFilters } from '../types/api'
import { AuthenticationSchemaStore } from './AuthenticationSchemaStore'
import { TenantsStore } from './TenantsStore'
import { notificationStore } from './index'
import { MappingStore } from './MappingStore'

const defaultFilters: PagingFilters = {
    page: 1,
    size: 10,
}

export class IntegrationChannelStore {
    public data: IntegrationChannelTableView[] = []
    public filters: PagingFilters = defaultFilters
    public pages: number = 0

    constructor(
        public authenticationSchemaStore: AuthenticationSchemaStore,
        public mappingStore: MappingStore,
        public tenantsStore: TenantsStore
    ) {
        makeAutoObservable(this)
    }

    public async fetch(id: string) {
        try {
            const response = await IntegrationChannelsApi.getIntegrationChannel(
                id,
                this.filters
            )
            await this.init(response.items)
            this.setUpPages(response.totalPages)
        } catch (err) {
            notificationStore.triggerErrorToast(err)
            await this.init([])
        }
    }

    private async init(items: IntegrationChannel[]) {
        await Promise.all([
            this.authenticationSchemaStore.getAllAuthSchemas(this.tenantsStore.tenantId),
            this.mappingStore.getAllMappings(this.tenantsStore.tenantId),
        ])

        const updatedData = items.map(channel => {
            const authSchemeName = this.authenticationSchemaStore.dataChannels.find(
                authSchema => channel.authenticationSchemeId === authSchema.id
            )?.name || ''

            const mappingName = this.mappingStore.mappingList.find(
                mapping => channel.mappingConfiguration.mapperId === mapping.id
            )?.name || ''

            return {
                ...channel,
                authSchemeName,
                mappingConfiguration: mappingName,
                downstreamUrl: channel.downstreamURL,
                mapperId: channel.mappingConfiguration.mapperId,
            }
        })

        this.data = updatedData
    }

    public updateData(
        rowIndex: number,
        columnId: string,
        value: string | ISelectOption
    ) {
        this.data = this.data.map((row, index) => {
            if (index === rowIndex) {
                if (columnId === 'mappingConfiguration') {
                    return {
                        ...this.data[rowIndex],
                        mappingConfiguration:
                            typeof value === 'string' ? value : value?.label,
                        mapperId:
                            typeof value === 'string' ? value : value?.value,
                    }
                }

                return {
                    ...this.data[rowIndex],
                    [columnId]: value,
                }
            }
            return row
        })
    }

    private setUpPages(totalPages: number) {
        this.pages = totalPages
    }

    public setFilters(filters: Partial<PagingFilters>) {
        if (!filters.page) {
            this.filters = { ...this.filters, ...filters, page: 1 }
        } else {
            this.filters = { ...this.filters, ...filters }
        }
    }

    public clean() {
        this.filters = defaultFilters
        this.authenticationSchemaStore.clean()
        this.mappingStore.cleanData()
    }
}
