import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const SaveIcon: FC<IconProps> = props => {
    return (
        <Icon viewBox="0 0 300 300" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path fill="currentColor" d="M288.865,65.628L232.917,9.681c-4.239-4.239-9.988-6.62-15.983-6.62h-9.329v83.63c0,5.599-4.539,10.138-10.138,10.138
            H62.548c-5.599,0-10.138-4.539-10.138-10.138V3.06H22.603C10.12,3.06,0,13.18,0,25.664v244.158
            c0,12.483,10.12,22.603,22.603,22.603h250.279c12.483,0,22.603-10.12,22.603-22.603V81.611
            C295.485,75.617,293.104,69.867,288.865,65.628z"/>
	        <path fill="currentColor" d="M164.076,76.829h17.333c3.498,0,6.333-2.836,6.333-6.333V23.829c0-3.498-2.835-6.333-6.333-6.333h-17.333
		    c-3.498,0-6.333,2.835-6.333,6.333v46.667C157.743,73.993,160.579,76.829,164.076,76.829z"/>
        </Icon>
    )
}
