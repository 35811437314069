import { OrderFilters } from 'types/core'

export const filters = [
    { value: 'created', name: OrderFilters.Created },
    { value: 'status', name: OrderFilters.Status },
    { value: 'delivery', name: OrderFilters.Delivery },
    { value: 'distributionSource', name: OrderFilters.DistributionSource },
    { value: 'externalSource', name: OrderFilters.ExternalSource },
]

export const statuses = [
    {
        id: '1',
        name: 'Order Received',
        enumValue: ['Created'],
    },
    {
        id: '2',
        name: 'Rules Pre-Processing',
        enumValue: ['PreProcessingComplete'],
    },
    {
        id: '3',
        name: 'Rules Processing',
        enumValue: ['ProcessingRule'],
    },
    {
        id: '4',
        name: 'Order Sent to ECC',
        enumValue: ['SentToECC'],
    },
    {
        id: '5',
        name: 'Order Received by ECC',
        enumValue: ['AcknowledgedByECC'],
    },
    {
        id: '6',
        name: 'Order Shipping',
        enumValue: ['Shipment', 'SentToIcep', 'PostProcessingComplete'],
    },
    {
        id: '7',
        name: 'Order Shipped',
        enumValue: ['Shipping'],
    },
    {
        id: '8',
        name: 'Order Delivered',
        enumValue: ['Delivered'],
    },
    {
        id: '9',
        name: 'Partial Order Delivered',
        enumValue: ['PartiallyDelivered'],
    },
    {
        id: '10',
        name: 'Order Complete',
        enumValue: ['Completed'],
    },
    {
        id: '11',
        name: 'Error - Processing Init Failed',
        enumValue: ['ProcessingInitError'],
    },
    {
        id: '12',
        name: 'Error - WoW Error',
        enumValue: [
            'Error',
            'PreProcessingError',
            'RuleProcessingError',
            'CompletionError',
        ],
    },
    {
        id: '13',
        name: 'Error - Invalid Address',
        enumValue: ['ValidateAddressError'],
    },
    {
        id: '14',
        name: 'Error - ECC',
        enumValue: ['ErrorSentToECC'],
    },
    {
        id: '15',
        name: 'Error - ECC Ack',
        enumValue: ['ErrorAcknowledgedByECC'],
    },
    {
        id: '16',
        name: 'Error - ECC Shipping',
        enumValue: ['ErrorShipment'],
    },
    {
        id: '17',
        name: 'Error - ICEP Ack',
        enumValue: ['ErrorSentToIcep'],
    },
    {
        id: '18',
        name: 'Error - Rules Engine',
        enumValue: ['PostProcessingError', 'Failed'],
    },
    {
        id: '19',
        name: 'Order Delivery Error',
        enumValue: ['DeliveryError'],
    },
    {
        id: '20',
        name: 'Order Split',
        enumValue: ['Split'],
    },
    {
        id: '21',
        name: 'Order Split Error',
        enumValue: ['SplitError'],
    },
    {
        id: '22',
        name: 'Cancel Requested',
        enumValue: ['CancelRequested'],
    },
    {
        id: '23',
        name: 'Cancel Completed',
        enumValue: ['CancelCompleted'],
    },
    {
        id: '24',
        name: 'Cancel Failed',
        enumValue: ['CancelFailed'],
    },
]
