import React, { useEffect, useState } from 'react'
import { toJS } from 'mobx'
import { orderStore } from 'stores'
import { observer } from 'mobx-react-lite'
import { Box } from '@chakra-ui/layout'
import { simulateStatus } from 'constants/ordersStatus'
import { CustomAccordion } from 'components/baseComponents/Accordion'
import { AccordionsHeader } from 'components/Accordion/AccordionsHeader'
import { CustomAccordionButton } from 'components/Accordion/AccordionButton'
import { CustomAccordionPanel } from 'components/Accordion/AccordionPanel'
import { IOrder } from 'types/core'
import { accordionHeaderOptionsOrderSimulate } from 'pages/HomePage/mock'
import { AccordionStyled } from 'pages/HomePage/styles'
import { ShowRuleSimulateButton, ShowRuleSimulateButtonBox, SpinnerStyled } from './styles'

interface IShowRuleSimulate {
    onClose(): void
}

const orderPageType = 'orderRuleSimulate'

export const ShowRuleSimulate = observer(({ onClose }: IShowRuleSimulate) => {
    const timeIntervalReqOrders = 5000
    const [opened, setOpened] = useState<number[]>([])
    const [splitOrderChildData, setSplitOrderChildData] = useState<IOrder | null>(null)
    const orderInfo = toJS(orderStore.orderInfo)
    const simulateId = toJS(orderStore.simulateOrderId)
    const checkSimulate = simulateStatus.find(el => orderInfo?.status?.toLowerCase() === el.toLowerCase())
    const checkSplitCount = orderStore.isSplitCounter

    const childOrderHandler = (order: IOrder) => {
        orderStore.splitOrderHierarchy(order, orderPageType)
        if(splitOrderChildData === null)
            setSplitOrderChildData(order)
    }

    useEffect(() => {
        orderStore.searchById(simulateId)

        const interval = setInterval(() => {
            orderStore.searchById(simulateId)
        }, timeIntervalReqOrders)

        if (checkSimulate) {
            clearInterval(interval)
        }

        return () => {
            clearInterval(interval)
        }
    }, [checkSimulate, simulateId])

    useEffect(() => {
        orderStore.splitCounter(0)
    }, [checkSplitCount])

    if (checkSimulate) {
        return (
            <Box>
                <AccordionsHeader options={accordionHeaderOptionsOrderSimulate} />
                <AccordionStyled
                    allowMultiple
                    allowToggle
                    index={opened}
                    onChange={(expandedIndex: number[]) =>
                        setOpened(expandedIndex)
                    }
                >
                    {orderInfo.workflowEvents?.length  ? (
                        orderInfo.workflowEvents.map((orderWorkflow, i) => {
                            const isOnlyOne = orderInfo.workflowEvents.length === 1
                            const isFirst = i === 0
                            const isLast = i === orderInfo.workflowEvents.length - 1

                            return (
                                <CustomAccordion
                                    key={orderWorkflow.workflowId}
                                    AccordionButtonContent={
                                        <CustomAccordionButton
                                            order={orderInfo}
                                            isOpen={opened.includes(i)}
                                            workflowEvents={orderInfo.workflowEvents}
                                            isWorkFlowTable={true}
                                            isOnlyOne={isOnlyOne}
                                            isFirst={isFirst}
                                            isLast={isLast}
                                            childOrderHandler={childOrderHandler}
                                            isOrderSimulated={true}
                                        />
                                    }
                                    AccordionPanelContent={
                                        <CustomAccordionPanel
                                            order={orderInfo}
                                            splitOrderChildData={splitOrderChildData}
                                            childOrderHandler={childOrderHandler}
                                            isOrderSimulated={true}
                                        />
                                    }
                                />
                            )
                        })
                    ) : (
                        <CustomAccordion
                            AccordionButtonContent={
                                <CustomAccordionButton
                                    order={orderInfo}
                                    isOpen={opened.includes(1)}
                                    workflowEvents={orderInfo.workflowEvents}
                                    isWorkFlowTable={true}
                                    isOnlyOne
                                    childOrderHandler={childOrderHandler}
                                />
                            }
                            AccordionPanelContent={
                                <CustomAccordionPanel
                                    order={orderInfo}
                                    childOrderHandler={childOrderHandler}
                                    splitOrderChildData={splitOrderChildData}
                                 />
                            }
                        />
                    )}
                </AccordionStyled>
                <ShowRuleSimulateButtonBox>
                    <ShowRuleSimulateButton
                        type="button"
                        variant="blue"
                        onClick={onClose}
                    >
                        Cancel
                    </ShowRuleSimulateButton>
                </ShowRuleSimulateButtonBox>
            </Box>
        )
    } else {
        return (
            <Box>
                <SpinnerStyled />
                <ShowRuleSimulateButtonBox>
                    <ShowRuleSimulateButton
                        type="button"
                        variant="blue"
                        onClick={onClose}
                    >
                        Cancel
                    </ShowRuleSimulateButton>
                </ShowRuleSimulateButtonBox>
            </Box>
        )
    }
})
