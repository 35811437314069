import { makeAutoObservable, runInAction } from 'mobx'
import { OrderClientAPI } from 'api/orderClient'
import { ShippingCost } from 'types/core'

const pageSize = 10

export class ShippingCostStore {
    public shippingCosts: ShippingCost[] = []
    public totalPages: number = 1
    public totalItems: number = 1
    public currentPage: number = 1

    constructor() {
        makeAutoObservable(this)
    }

    public async fetch() {
        this.fetchData()
    }

    public async setPage(page: number) {
        this.currentPage = page
        this.fetchData()
    }

    public clean() {
        this.shippingCosts = []
    }

    private async fetchData() {
        const {
            items = [],
            totalItems = 0,
            totalPages = 0,
        } = await OrderClientAPI.shipmentCost({
            page: this.currentPage,
            size: pageSize,
        })
        items.forEach(ship => {
            ship.placedDate = new Date(ship.placedDate).toLocaleDateString()
        })
        runInAction(() => {
            this.shippingCosts = items
            this.totalItems = totalItems
            this.totalPages = totalPages
        })
    }
}
