export const getShowingItems = (
    totalItems: number,
    activePage: number,
    items: number,
) => {
    if (activePage === 1) return `1-${items}`

    const showingOf = items % 10 === 0 ? items * activePage : `${activePage - 1}${items}`

    return `${activePage - 1}1-${showingOf}`
}
