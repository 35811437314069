import React, { FC } from 'react'
import { HeaderBox, HeaderItem } from '../styles'

interface IAccordionsHeader {
    options: string[]
}

export const ItemsHeader: FC<IAccordionsHeader> = ({ options}) => {
    return (
        <HeaderBox>
            {options.map((option: string) => (
                <HeaderItem key={option} flexGrow={1}>
                    {option}
                </HeaderItem>
            ))}
        </HeaderBox>
    )
}
