import { UUID } from 'types/core'

export enum UserState {
    Active = 'Active',
    Disabled = 'Disabled',
}

export enum UserRole {
    SuperAdmin = 'SuperAdmin',
    TenantAdmin = 'TenantAdmin',
    RulesCreator = 'RulesCreator',
    RulesApprover = 'RulesApprover',
    Support = 'Support',
}

export interface IUser {
    id: UUID
    firstName: string
    lastName: string
    email: string
    state: UserState
}

export interface ICurrentRoles {
    isTenantAdmin: boolean
    isApprover: boolean
    isSupport: boolean
    isCreator: boolean
    isSuperAdmin: boolean
}

