import React, { useEffect } from 'react'
import { toJS } from 'mobx'
import { Box, Grid, Stack } from '@chakra-ui/layout'
import { observer } from 'mobx-react-lite'
import { AddBlocks } from './content/AddBlocks'
import { MoreStepsButton } from './content/MoreStepsButton'
import { RuleHeaderButton } from './content/RuleHeaderButton'
import { editoreStore, stepsMetadataStore } from 'stores'

export const EditorSidebar = observer(() => {
    const workflow = toJS(editoreStore.workflowStore.workflow)

    useEffect(() => {
        stepsMetadataStore.fetch()
        return () => {
            stepsMetadataStore.clean()
        }
    }, [])

    return (
        <Grid height="100%" templateRows="min-content 1fr">
            <Stack spacing="70px">
                <MoreStepsButton activeStep={2} />
                {!!workflow && (
                    <RuleHeaderButton
                        name={workflow.name}
                        description={workflow.description}
                        onChangeName={newName => {
                            editoreStore.workflowStore.changeWorkflow({ name: newName })
                        }}
                        onChangeDescription={newDescription => {
                            editoreStore.workflowStore.changeWorkflow(
                                { description: newDescription },
                            )
                        }}
                    />
                )}
            </Stack>
            <Box overflow="auto" marginTop="150px" >
                <AddBlocks />
            </Box>
        </Grid>
    )
})
