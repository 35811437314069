import { Box, Image, Center } from '@chakra-ui/react'
import { chakra } from 'utils/chakra'

export const PreviewBox = chakra(Box, {
    width: '317px',
    height: '196px',
    backgroundColor: 'transparent',
    borderRadius: '8px',
    marginTop: '8px',
    position: 'relative',
    boxShadow: '0px 4px 4px rgba(101, 106, 144, 0.26)',
})

export const ImageBox = chakra(Image, {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    borderRadius: '8px',
})

export const CenterIcon = chakra(Center, {
    position: 'absolute',
    right: '-8px',
    bottom: '-8px',
    backgroundColor: 'denim',
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    cursor: 'pointer',
})
