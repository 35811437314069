import React, { useContext } from 'react'
import { RangePickerResult } from './types'

interface PickerContext {
    onChangeRange: (range: RangePickerResult) => void
}

const rangePickerContext = React.createContext<PickerContext>(undefined!)

export const RangePickerContextProvider = rangePickerContext.Provider

export function useRangePickerContext(): PickerContext {
    return useContext(rangePickerContext)
}
