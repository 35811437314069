import React, { useEffect, useState } from 'react'
import { SingleValue, MultiValue } from 'react-select'
import { Controller } from 'react-hook-form'
import { Select, FormLabel } from '@chakra-ui/react'
import { FormControl, FormErrorMessage } from '@chakra-ui/form-control'
import { Input } from '@chakra-ui/input'
import { isArray } from '@chakra-ui/utils'
import { toJS } from 'mobx'
import { ModalState } from '../../../mock'
import { ApiKeyLocation } from './Locations/ApiKeyLocation'
import { ResourceOwnerLocation } from './Locations/ResourceOwnerLocation'
import { authenticationSchemaStore, tenantsStore } from 'stores'
import {
    IAuthenticationSchema,
    LocationSecretIdSubCategory,
    TableForm,
} from 'types/core'

interface IAuthenticationSchemaInputs extends TableForm {
    data?: IAuthenticationSchema | null
    reset: (
        values?: Record<string, any>,
        options?: Record<string, boolean>
    ) => void
    modalState?: string
    setValue: (name: string, value: unknown, config?: Object) => void
}

const AuthenticationSchemaInputs = ({
    errors,
    register,
    data,
    control,
    reset,
    modalState,
    setValue,
}: IAuthenticationSchemaInputs) => {
    const [secretIdSubCategory, setSecretIdSubCategory] = useState('')

    const tenantSecret = toJS(authenticationSchemaStore.authSchemeSecrets)
    const checkLocationType = (id?: string, isFirst = false) => {
        if (id) {
            const subcategory = isFirst ? tenantSecret.find(option => option.id === id)?.subcategory : id
            if (subcategory) setSecretIdSubCategory(subcategory)
            setValue('secretIdSubCategory', subcategory)
        }
    }

    useEffect(() => {
        if (modalState !== ModalState.Add && data) {
            checkLocationType(data.secretId, true)
        }
        // Need for improvement. Warnings console
        /* eslint-disable */
    }, [])

    useEffect(() => {
        let defaultValues = {}
        let subCategory
        if (tenantSecret.length) {
            subCategory = tenantSecret?.find(el => el.id === data?.secretId)?.subcategory
        }
        const apiKey = data?.locations?.apiKey
        const clientId = data?.locations?.clientId
        const clientSecret = data?.locations?.clientSecret
        const username = data?.locations?.username
        const password = data?.locations?.password

        if (apiKey) {
            defaultValues = {
                name: data?.name,
                secretId: data?.secretId,
                apiKeyType: apiKey.type,
                apiKeyName: apiKey.name,
                apiKeyFormat: apiKey.format,
                apiKeyIndex: apiKey.index,
            }
        } else if (clientId && clientSecret && username && password) {
            defaultValues = {
                name: data?.name,
                secretId: data?.secretId,
                clientIdType: clientId?.type,
                clientIdName: clientId?.name,
                clientIdFormat: clientId?.format,
                clientIdIndex: clientId?.index,
                clientSecretType: clientSecret?.type,
                clientSecretName: clientSecret?.name,
                clientSecretFormat: clientSecret?.format,
                clientSecretIndex: clientSecret?.index,
                clientUsernameType: username?.type,
                clientUsernameName: username?.name,
                clientUsernameFormat: username?.format,
                clientUsernameIndex: username?.index,
                clientPasswordType: password?.type,
                clientPasswordName: password?.name,
                clientPasswordFormat: password?.format,
                clientPasswordIndex: password?.index,
            }
        } else if (subCategory === 'no-auth') {
            defaultValues = {
                name: data?.name,
                secretId: data?.secretId,
            }
        }

        reset({
            ...defaultValues,
        })
    }, [data])

    const renderLocations = () => {
        if (secretIdSubCategory === LocationSecretIdSubCategory.API_KEY) {
            return (
                <ApiKeyLocation
                    errors={errors}
                    control={control}
                    register={register}
                />
            )
        } else if (
            secretIdSubCategory === LocationSecretIdSubCategory.RESOURCE_OWNER
        ) {
            return (
                <ResourceOwnerLocation
                    errors={errors}
                    control={control}
                    register={register}
                />
            )
        }   else if ( secretIdSubCategory === LocationSecretIdSubCategory.AUTH_NO_AUTH ) {
            return null
        } else {
            return null
        }
    }

    return (
        <>
            <FormControl>
                <Input type="hidden" {...register('secretIdSubCategory')} />
            </FormControl>
            <FormControl>
                <FormLabel htmlFor="tenantId">Tenant Id</FormLabel>
                <Input
                    id="tenantId"
                    placeholder="Tenant Id"
                    value={tenantsStore.tenantId}
                    disabled
                />
            </FormControl>
            <FormControl isInvalid={!!errors.name} mt={5}>
                <FormLabel htmlFor="name">Name</FormLabel>
                <Input
                    id="name"
                    placeholder="name"
                    {...register('name', {
                        required: 'This is required',
                        minLength: {
                            value: 4,
                            message: 'Minimum length should be 4',
                        },
                    })}
                />
                <FormErrorMessage>
                    {errors.name && errors.name.message}
                </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.secretId} mt={5} mb={5}>
                <FormLabel htmlFor="secretId">Secret Id</FormLabel>
                <Controller
                    name="secretId"
                    control={control}
                    rules={{ required: 'This is required' }}
                    render={({ field }) => {
                        let result = undefined
                        if (modalState !== ModalState.Add && tenantSecret.length) {
                            const valueSecret = tenantSecret.find(
                                option => {
                                    return option.id === field.value
                                }
                            )
                            result = valueSecret?.id
                        }

                        return (
                            <Select
                                placeholder="Select..."
                                onChange={e => {
                                    if(!!e.target) {
                                        if(!!e.target.value) {
                                            checkLocationType(tenantSecret.find(
                                                option => option.id === e.target.value
                                            )?.subcategory, false)
                                        }
                                        field.onChange(e.target.value)
                                    }}}
                                value={result}
                            >
                                {tenantSecret.map(item => (
                                    <option key={item.id} value={item.id}>
                                        {`${item.name} (${item.subcategory})`}
                                    </option>
                                ))}
                            </Select>
                        )
                    }}
                />
                <FormErrorMessage>
                    {errors.secretId && errors.secretId.message}
                </FormErrorMessage>
            </FormControl>
            {renderLocations()}
        </>
    )
}

function isSingleValue<T>(o: SingleValue<T> | MultiValue<T>): o is SingleValue<T> {
    return !isArray(o)
}

export default AuthenticationSchemaInputs
