import React, { FC } from 'react'
import { SimpleGrid, Box } from '@chakra-ui/layout'
import { AccordionRetrieHeaderBox } from 'pages/OrderDetailsPage/styles'

export const RetriesHistoryOfHeader: FC = () => {
    return (
       <AccordionRetrieHeaderBox>
           <SimpleGrid columns={4} spacing={0}>
                <Box paddingLeft={5}>Retried At</Box>
                <Box marginRight={35}>Retries Reason</Box>
           </SimpleGrid>
        </AccordionRetrieHeaderBox>
    )
}
