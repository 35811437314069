import { StepCategory, StepMetadata } from '../types/core'

interface IGroupByCategory {
    [category: string]: StepMetadata[]
}

export function reorderArray<T>(
    list: T[],
    startIndex: number,
    endIndex: number
): T[] {
    const result = [...list]
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
}

export function groupByCategory(array: StepMetadata[]) {
    return array.reduce((result: IGroupByCategory, current) => {
        const group = current.category
        result[group].push(current)

        return result
    }, {
        [StepCategory.Variable]: [],
        [StepCategory.MasterData]: [],
        [StepCategory.DistributionSource]: [],
        [StepCategory.CustomBlock]: [],
        [StepCategory.SplitOrderBlock]: []
    })
}
