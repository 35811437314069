import React from 'react'
import { Link as DomLink } from 'react-router-dom'
import { Link } from '@chakra-ui/layout'
import { routes } from 'navigation/routes'
import { Wow } from 'types/core'
import { InfoValue } from '../styles'

export const renderWows = (wows: Wow[], needLink?: boolean, isBold?: boolean) => {
    const variant = isBold ? 'linkBold' : 'paragraphSmallUnderline'
    const padding = isBold ? '2px 20px 0 7px' : '0'

    if (!wows.length) {
        return (
            <InfoValue
                variant={isBold ? 'linkBold' : 'paragraphSmall500'}
            >
                N/A
            </InfoValue>
        )
    }

    if (needLink) {
        return wows.map((wow, index) =>
            <Link
                key={wow.id}
                to={`${routes.editRuleSet}/${wow.id}`}
                as={DomLink}
            >
                <InfoValue padding={padding} variant={variant}>
                    {wow.name}{index === wows.length - 1 ? '' : ', '}
                </InfoValue>
            </Link>
        )
    }

    return wows.map((wow, index) =>
        <InfoValue
            key={wow.id}
            variant={variant}
            padding={padding}
        >
            {index + 1}. {wow.name}
        </InfoValue>
    )
}
