import { makeAutoObservable, runInAction } from 'mobx'
import { KPIAPI } from 'api/kpi'
import { notificationStore } from './index'
import { OrderSourceData, ShipperData, ShipperInfo } from 'types/core'

const defaultShippers: ShipperData = {
    shippers: [{
        name: '',
        value: 0
    }],
    totalOrders: 0
}

export class KPIStore {
    public shipperData: ShipperData = defaultShippers
    public orderSources: OrderSourceData[] = []

    constructor() {
        makeAutoObservable(this)
    }

    public async getShipperData(frequency: string) {
        try {
            const data = await KPIAPI.getTotalShippers(frequency)
            if (data.shipper && data.shipper.length) {
                runInAction(() => {
                    data.shipper.map((x: ShipperInfo) => (
                        this.shipperData.shippers.push({
                            name: x.shipperName,
                            value: x.count
                        })
                    ))
                    this.shipperData.totalOrders = data.totalOrders
                })
            }
            else {
                this.initShipperData(defaultShippers)
            }

            return this.shipperData
        }
        catch (err) {
            this.initShipperData(defaultShippers)
            notificationStore.triggerErrorToast(err)
        }
    }

    public async getOrderSources(filter: string) {
        try {
            const data = await KPIAPI.getOrderSources(filter)
            runInAction(() => {
                this.initOrderSources(data)
            })
            return this.orderSources
        }
        catch (err) {
            this.initOrderSources([])
            notificationStore.triggerErrorToast(err)
        }
    }

    public clean() {
        this.shipperData.shippers = [{
            name: '',
            value: 0
        }]
    }

    private initShipperData(shipperData: ShipperData) {
        this.shipperData = shipperData
    }

    private initOrderSources(orderSources: OrderSourceData[]) {
        this.orderSources = orderSources
    }

}
