import React, { useEffect, useMemo, useState } from 'react'
import { toJS } from 'mobx'
import { truncate } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { Popover, PopoverTrigger } from '@chakra-ui/popover'
import { Avatar, PopoverBody, Spinner } from '@chakra-ui/react'
import SwitchControlTree from './SwitchControlTree'
import { AuthAPI } from 'api/auth'
import { useGetPath } from 'hooks'
import { getTenantId } from 'utils/jwt'
import { StatusType } from 'types/chart'
import { routes } from 'navigation/routes'
import { getAzureToken } from 'utils/getAzureToken'
import { notificationStore, tenantsStore } from 'stores'
import { SimpleArrowDownIcon, SimpleArrowUpIcon } from 'icons'
import {
    PopoverContainer,
    SwitchControlContainer,
    TextContainer,
} from './styles'

export const TenantSwitchControl = observer(() => {
    const [loading, setLoading] = useState(false)
    const { fullUrl } = useGetPath()
    const navigate = useNavigate()

    const currentTenantId = getTenantId()
    const tenantsAccessHierarchy = toJS(tenantsStore.tenantsAccessHierarchy)
    const initialTenantsAccessHierarchy = toJS(tenantsStore.initialTenantsAccessHierarchy)

    const tenantName = useMemo(() => {
        const currentTenant = initialTenantsAccessHierarchy.find(
            node => node.tenantId === currentTenantId
        )
        return currentTenant?.tenantName || ''
        // Need for improvement. Warnings console
        /* eslint-disable */
    }, [currentTenantId, initialTenantsAccessHierarchy.length])

    const onSelect = async (e: React.MouseEvent, tenantId: string) => {
        e.stopPropagation()
        const authTokenAzureAD = await getAzureToken()
        try {
            if (authTokenAzureAD) {
                setLoading(true)
                const { accessToken, refreshToken } =
                    await AuthAPI.exchangeCurrentToken(
                        tenantId,
                        authTokenAzureAD
                    )

                localStorage.setItem('authToken', accessToken)
                localStorage.setItem('refreshToken', refreshToken)
            } else {
                setLoading(true)
                const { accessToken, refreshToken } =
                    await AuthAPI.exchangeCurrentToken(tenantId)

                localStorage.setItem('authToken', accessToken)
                localStorage.setItem('refreshToken', refreshToken)
            }
            setLoading(false)
            fullUrl === routes.home ? window.location.reload() : navigate(routes.home)
        } catch (e) {
            setLoading(false)
            notificationStore.toast({
                title: 'Error',
                description: 'Something went wrong.',
                status: StatusType.Error,
            })
        }
    }

    useEffect(() => {
        tenantsStore.getTenantsAccessHierarchy()
    }, [])

    return (
        <Popover autoFocus={false}>
            {({ isOpen }) => (
                <>
                    <PopoverTrigger>
                        <SwitchControlContainer>
                            {loading && <Spinner marginRight="15px" />}
                            <Avatar
                                size="sm"
                                backgroundColor="grayBlue.720"
                                color="color.100"
                                name={tenantName[0]}
                            />
                            <TextContainer>
                                {truncate(tenantName, { length: 20 })}
                                {isOpen ? (
                                    <SimpleArrowUpIcon marginLeft="9px" />
                                ) : (
                                    <SimpleArrowDownIcon marginLeft="7px" />
                                )}
                            </TextContainer>
                        </SwitchControlContainer>
                    </PopoverTrigger>
                    <PopoverContainer>
                        <PopoverBody>
                            <SwitchControlTree
                                tree={tenantsAccessHierarchy}
                                onSelect={onSelect}
                            />
                        </PopoverBody>
                    </PopoverContainer>
                </>
            )}
        </Popover>
    )
})
