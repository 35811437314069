import React, { ChangeEvent, FC, KeyboardEvent, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { InputSearchSquare } from 'components/baseComponents/InputSearchSquare'
import { orderStore } from 'stores'

export const Input: FC = observer(() => {
    const [value, setValue] = useState('')

    const orderNumber = orderStore.filters.orderNumber

    const handleClear = async (): Promise<void> => {
        setValue('')
        if (orderNumber) await orderStore.searchByNumber('')
    }

    const handleChange = (value: ChangeEvent<HTMLInputElement>): void => {
        setValue(value.target.value)
    }

    const handleSearch = async (): Promise<void> => {
        await orderStore.searchByNumber(value)
    }

    const handleKeypress = async (event: KeyboardEvent<object>): Promise<void> => {
        if (event.key === 'Enter') {
            await handleSearch()
        }
    }

    useEffect(() => {
        setValue(orderNumber)
    }, [orderNumber])

    return (
        <InputSearchSquare
            value={value}
            placeholder="Search by Sales Order Number or Customer Order Number or Source Order Number..."
            handleClear={handleClear}
            handleChange={handleChange}
            handleSearch={handleSearch}
            handleKeypress={handleKeypress}
        />
    )
})
