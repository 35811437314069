import { makeAutoObservable } from 'mobx'
import { ReportAPI } from 'api/reports'
import {
    IItem,
    IOrderShipping,
    IReports,
    IShiperNames,
    IReportSearchList,
    IShippersData,
} from 'types/core'
import { PagingFilters } from 'types/api'

const defaultFilters: PagingFilters = {
    page: 1,
    size: 10,
}

export class OnTimeShippingReportStore {
    public onTimeShippingShippers: IShippersData[] = []
    public filters: PagingFilters = defaultFilters
    public pages: number = 0
    public onTimeShippingdataTabe: IOrderShipping[] = []
    public toTotalItems: IReports = {} as IReports
    public reportListItems: IReportSearchList[] = []
    public onTimeShippingReportExport: IOrderShipping[] = []
    public defaultReportType: string = ''

    constructor() {
        makeAutoObservable(this)
    }

    public async searchReportsList(filter: string) {
        try {
            const data = await ReportAPI.getMenuReports(filter)
            this.defaultReportType = !!data && !data.length ? '' : data[0].type
            this.initMenuReportsItems(data)
        } catch (err) {
            this.reportListItems = []
        }
    }

    private initMenuReportsItems(data: IReportSearchList[]) {
        this.reportListItems = data || []
    }

    public async getShiperNames() {
        try {
            const data = await ReportAPI.shipperNames()
            this.preparevaluesandLabels(data)
        } catch (err) {
            this.onTimeShippingShippers = []
        }
    }

    public async getReports(
        fromDate: string,
        todate: string,
        shipername: string
    ) {
        try {
            const data = await ReportAPI.getTotalReports(
                fromDate,
                todate,
                shipername,
                this.filters
            )
            this.initTotalItems(data)
            this.initPages(data?.totalPages)
            this.init(data)
        } catch (err) {
            this.init({} as IReports)
        }
    }

    public async getExport(
        fromDate: string,
        toDate: string,
        shipperName: string
    ) {
        try {
            const data = await ReportAPI.getOnTimeShippersExport(
                fromDate,
                toDate,
                shipperName
            )
            this.initExportData(data)
        } catch (err) {
            this.initExportData({} as IReports)
        }
    }

    private initExportData(data: IReports) {
        this.onTimeShippingReportExport =
            (!!data &&
                !!data?.items &&
                data?.items?.map((item: IItem) => item.orderShipping).flat()) ||
            []
    }

    private init(data: IReports) {
        this.onTimeShippingdataTabe =
            (!!data &&
                !!data?.items &&
                data?.items?.map((item: IItem) => item.orderShipping).flat()) ||
            []
    }

    private async preparevaluesandLabels(data: IShiperNames) {
        this.onTimeShippingShippers = []
        !!data &&
            data?.shippers?.forEach(shiper =>
                this.onTimeShippingShippers?.push({
                    value: shiper?.shipperName,
                    label: shiper?.shipperName,
                })
            )
    }

    private initTotalItems(items: IReports) {
        this.toTotalItems = items
    }

    private initPages(pages: number) {
        this.pages = pages
    }

    private initFilters(filter: PagingFilters) {
        this.filters = filter
    }

    public setFilters(filters: Partial<PagingFilters>) {
        if (!filters.page) {
            this.filters = { ...this.filters, ...filters, page: 1 }
        } else {
            this.filters = { ...this.filters, ...filters }
        }
    }

    public reloadPages() {
        this.initFilters(defaultFilters)
    }

    public clean() {
        this.initFilters(defaultFilters)
        this.initPages(0)
        this.init({} as IReports)
        this.initTotalItems({} as IReports)
        this.initExportData({} as IReports)
    }
}
