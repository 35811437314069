import React, { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { Center } from '@chakra-ui/react'
import { Box, Container, Divider, Stack } from '@chakra-ui/layout'
import { NumericPagination } from 'components/NumericPagination'
import { Input } from './content/Input'
import { RulesSetsHeader } from 'pages/RulesSetsPage/content/RulesSetsHeader'
import { RulesSetsItem } from 'pages/RulesSetsPage/content/RulesSetsItem'
import { routes } from 'navigation/routes'
import { rulesSetsStore, userStore } from 'stores'
import { BoxStyled, FiltersBox, RuleSetButton, Title, TitleBox } from './styles'

export const RulesSetsPage: FC = observer(() => {
    const navigate = useNavigate()
    const rulesSets = toJS(rulesSetsStore.rulesSets)
    const { isCreator } = toJS(userStore.currentRoles)

    const onCreateNew = (): void => navigate(routes.createNewRuleSet)

    useEffect(() => {
        rulesSetsStore.search()
        rulesSetsStore.wowOrderSourceMappings()
        rulesSetsStore.getOrderSources()
        return () => {
            rulesSetsStore.clean()
        }
    }, [])

    useEffect(() => {
        if (!rulesSets.length && rulesSetsStore.pages > 0) {
            rulesSetsStore.setPages({ page: rulesSetsStore.filters.page - 1 })
        }
    }, [rulesSets])

    return (
        <Container maxWidth="100%" minWidth="1xl" margin="0" padding="0">
            <Stack
                as={Box}
                spacing={{ base: 8, md: 14 }}
                py={{ base: 20, md: 4 }}
            >
                <Stack>
                    <BoxStyled>
                        <TitleBox>
                            <Title>Rule Sets</Title>
                        </TitleBox>
                        <Divider margin="8px 0" />
                        <FiltersBox>
                            <Input />
                            <Center marginRight="10px">
                                <RuleSetButton
                                    onClick={onCreateNew}
                                    disabled={!isCreator}
                                >
                                    Create New Rule Set
                                </RuleSetButton>
                            </Center>
                        </FiltersBox>
                        {!!rulesSets.length && (
                            <>
                                <RulesSetsHeader />
                                {rulesSets.map(({ id, name }) => (
                                    <RulesSetsItem
                                        key={id}
                                        id={id}
                                        name={name}
                                    />
                                ))}
                                <NumericPagination
                                    marginTop="24px"
                                    pages={rulesSetsStore.pages}
                                    activePage={rulesSetsStore.filters.page}
                                    onChangePage={page =>
                                        rulesSetsStore.setPages({ page })
                                    }
                                />
                            </>
                        )}
                    </BoxStyled>
                </Stack>
            </Stack>
        </Container>
    )
})
