import React, { Dispatch, FC, SetStateAction } from 'react'
import { CrossSmallIcon, ImageIcon, ListIcon } from 'icons'
import { dashboardStore } from 'stores'
import { Input } from './Input'
import { CustomSelectContainer as Select } from '../Select'
import { MultiSelectContainer as MultiSelect } from '../MultiSelect'
import { ClearBox, IconBox, ClearText } from 'pages/HomePage/styles'
import {
    FiltersBox,
    IconsBox,
    SelectBox,
    SelectionBox,
    ShowResults,
    ShowResultsBox,
} from './styles'

interface FiltersProps {
    searchValue: string
    setSearchValue: Dispatch<SetStateAction<string>>
    setTimeScopeFrom: Dispatch<SetStateAction<string>>
    setStatuses: Dispatch<SetStateAction<string>>
}

export const Filters: FC<FiltersProps> = ({
    searchValue,
    setSearchValue,
    setTimeScopeFrom,
    setStatuses,
}) => {
    const handleClear = () => setSearchValue('')

    return (
        <>
            <FiltersBox>
                <SelectionBox>
                    <IconsBox>
                        <IconBox margin="0 6px 0 0">
                            <ListIcon boxSize="30px" />
                        </IconBox>
                        <IconBox margin="0 0 0 6px">
                            <ImageIcon boxSize="14px" />
                        </IconBox>
                    </IconsBox>
                    <SelectBox alignItems="center" margin="0 0 0 24px">
                        <Select onChange={setTimeScopeFrom} />
                        <MultiSelect onChange={setStatuses} />
                    </SelectBox>
                </SelectionBox>
                <Input value={searchValue} onChange={setSearchValue} />
            </FiltersBox>
            {searchValue !== '' ? (
                <ShowResultsBox>
                    <ShowResults>
                        {`Showing results for: "${searchValue}" (${dashboardStore.myRulesStore.workflows.length})`}
                    </ShowResults>
                    <ClearBox onClick={handleClear}>
                        <ClearText>Clear</ClearText>
                        <IconBox margin="0 0 0 8px">
                            <CrossSmallIcon
                                cursor="pointer"
                                role="button"
                                boxSize="9px"
                                color="white"
                            />
                        </IconBox>
                    </ClearBox>
                </ShowResultsBox>
            ) : null}
        </>
    )
}
