import React, { FC, useEffect } from 'react'
import { isEqual } from 'lodash'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import { selectParamStores } from 'stores'
import { Select } from 'components/baseComponents/Selects'
import { DependsOnMappings, Parameter, ParameterMetadata, UUID } from 'types/core'
import { dependsOnParameters } from 'components/diagrams/nodes/NodeLayout/NodeParameter/params/utils'
import { RequiredText } from 'components/diagrams/nodes/NodeLayout/NodeParameter/params/styles'

interface InternalProps {
    id: UUID
    stepId: UUID
    placeholder?: string
    readonly: boolean
    isDraft: boolean
    value: string
    description: string
    dependsOnMappings: DependsOnMappings[]
    stepParameters: Parameter[]
    nodeParameters: ParameterMetadata[]
}

export const Internal: FC<InternalProps> = observer(({
    id,
    stepId,
    placeholder,
    readonly,
    isDraft,
    description,
    dependsOnMappings,
    stepParameters,
    nodeParameters,
}) => {
    const store = selectParamStores.stores[stepId]?.internal?.[id]

    const { dependencies, isDisabled, requiredMappings } = dependsOnParameters(dependsOnMappings, nodeParameters, stepParameters)

    useEffect(() => {
        if (store && !isEqual(store.dependencies, dependencies)) {
            store.setDependencies(dependencies)
        }
    }, [dependencies, store])

    if (!store) return null

    return (
        <>
            <Select
                maxWidth="310px"
                placeholder={placeholder}
                isDisabled={isDisabled || !isDraft}
                readonly={readonly}
                value={toJS(store.value)}
                options={toJS(store.options)}
                onChange={option => store.selectValue(option)}
            />
            {!!requiredMappings.length && (
                <RequiredText>
                    Parameter "{description}" is disabled, please fill required parameters: "{requiredMappings.join(', ')}"
                </RequiredText>
            )}
        </>

    )
})
