export const SHIPPER_NAME_MAX_LENGTH = 100
export const SHIPPER_ECOMMERCE_CODE_MAX_LENGTH = 40
export const SHIPPER_ORDER_SOURCE_CODE_MAX_LENGTH = 40
export const SHIPPER_CODE_MAX_LENGTH = 40
export const SHIPPER_DESCRIPTION_MAX_LENGTH = 2000
export const SHIPPER_CONNECTION_SETTINGS_MAX_LENGTH = 2000
export const SHIPPER_NAME_MIN_LENGTH = 2
export const SHIPPER_ECOMMERCE_CODE_MIN_LENGTH = 2
export const SHIPPER_ORDER_SOURCE_CODE_MIN_LENGTH = 2
export const SHIPPER_CODE_MIN_LENGTH = 2
export const SHIPPER_DESCRIPTION_MIN_LENGTH = 4
export const SHIPPER_CONNECTION_SETTINGS_MIN_LENGTH = 4
export const MAX_LENGTH_VALUE_SHOW = 20
