import { Accordion, Box, Button, Center } from '@chakra-ui/react'
import { Grid, Heading, Text } from '@chakra-ui/layout'
import { chakra } from 'utils/chakra'

export const CustomerShipperContainer = chakra(Box, {
    backgroundColor: 'color.900',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.25)',
    borderRadius: '6px',
    padding: '10px',
})

export const CustomerShipperHeader = chakra(Heading, {
    as: 'h2',
    variant: 'h2',
    padding: '8px',
})

export const AccordionHeaderBox = chakra(Grid, {
    templateColumns: 'repeat(7, 1fr)',
    gap: '2',
    alignItems: 'center',
    padding: '8px',
})

export const AccordionHeaderText = chakra(Text, {
    variant: 'tableHeader',
    textAlign: 'left',
    fontSize: '14px',
    color: 'color.475',
})

export const AccordionStyled = chakra(Accordion, {
    marginBottom: '24px',
    variant: 'unstyled',
})

export const AccordionContainer = chakra(Grid, {
    templateColumns: 'repeat(7, 1fr)',
    width: '100%',
    minHeight: '40px',
    backgroundColor: 'blueWhite.950',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.25)',
    borderRadius: '6px',
    alignItems: 'center',
    cursor: 'auto',
    padding: '2px 2px 2px 10px',
})

export const AccordionButtonBtn = chakra(Box, {
    textAlign: 'left',
    marginRight: '6px',
    marginLeft: '6px',
    variant: 'paragraphsmall',
})

export const ShipmentMethodContainer = chakra(Grid, {
    templateColumns: 'repeat(7, 1fr)',
    width: '100%',
    minHeight: '38px',
    backgroundColor: 'blueWhite.940',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.25)',
    borderRadius: '6px',
    alignItems: 'center',
    cursor: 'auto',
    padding: '2px 10px 2px 0px',
    marginBottom: '8px',
    marginTop: '5px',
    border: '1px solid',
    borderColor: 'blue.75',
})

export const ButtonStyled = chakra(Button, {
    minWidth: '90px',
    maxWidth: '340px',
    height: '26px',
    variant: 'blue',
    color: 'white',
})

export const ShipmentMethodsTitle = chakra(Text, {
    variant: 'h3',
    fontWeight: '500',
    paddingLeft: '12px',
})

export const MethodTitleTable = chakra(Grid, {
    width: '100%',
    templateColumns: 'repeat(7, 1fr)',
    gap: '2',
    alignItems: 'center',
    padding: '8px',
    backgroundColor: 'blueWhite.999',
    borderRadius: '10px',
})

export const CustomerShipperPanelGlobalBox = chakra(Box, {
    backgroundColor: 'blueWhite.999',
    padding: '12px',
})

export const CustomerShipperPanelBox = chakra(Box, {
    bg: 'blueBlack.300',
    padding: '12px',
    borderRadius: '10px',
    marginBottom: '16px',
})

export const SvgBG = chakra(Center, {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    backgroundColor: 'sherbet',
    paddingTop: '2px',
})
