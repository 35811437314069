import React, { useEffect } from 'react'
import { Checkbox, CheckboxGroup, Collapse, useDisclosure } from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'
import { SimpleArrowDownIcon, SimpleArrowRightIcon } from 'icons'
import { workflowVersionsStore } from 'stores'
import { statuses } from './mock'
import { userStatusTransform } from 'utils/userStatusTransform'
import {
    CheckboxesBox,
    CollapseBox,
    FilterText,
} from 'pages/HomePage/content/OrderStatusTracking/Filters/ShowFilters/styles'

export const CollapseCheckbox = observer(() => {
    const { isOpen, onToggle } = useDisclosure()

    const filterStatuses = workflowVersionsStore.statuses

    const handleChange = (values: string[] | number[]) => {
        const transformStatuses = values.join(',')
        workflowVersionsStore.setStatuses(transformStatuses)
    }

    const getSelected = (): string[] => {
        if (!filterStatuses) return []
        return filterStatuses.split(',')
    }

    useEffect(() => {
        onToggle()
        // Need for improvement. Warnings console
        /* eslint-disable */
    }, [])

    return (
        <>
            <CollapseBox onClick={onToggle}>
                {isOpen ? (
                    <SimpleArrowDownIcon
                        boxSize="15px"
                        color="blue.300"
                        marginRight="5px"
                    />
                ) : (
                    <SimpleArrowRightIcon
                        boxSize="15px"
                        color="color.100"
                        marginRight="5px"
                    />
                )}
                <FilterText>Status</FilterText>
            </CollapseBox>
            <Collapse in={isOpen} animateOpacity>
                <CheckboxGroup
                    onChange={handleChange}
                    value={getSelected()}
                >
                    <CheckboxesBox>
                        {statuses.map(status =>
                            <Checkbox
                                key={status}
                                value={status}
                                margin="5px 5px 5px 20px"
                            >
                                {userStatusTransform(status)}
                            </Checkbox>
                        )}
                    </CheckboxesBox>
                </CheckboxGroup>
            </Collapse>
        </>
    )
})
