import React from 'react'
import { Text } from '@chakra-ui/layout'
import { FormControl, FormErrorMessage } from '@chakra-ui/form-control'
import { Select, FormLabel } from '@chakra-ui/react'
import { Input } from '@chakra-ui/input'
import { TableForm } from 'types/core'
import { Controller } from 'react-hook-form'
import {
    locationFormatOptions,
    locationIndexOptions,
    locationTypeOptions,
} from '../../../../mock'

export interface ILocationForm extends TableForm {
    fields: Array<string>
    header?: string
    nameFieldTitle: string
}

export const LocationForm = ({
    fields,
    errors,
    control,
    register,
    header,
    nameFieldTitle,
}: ILocationForm) => {

    return (
        <>
            {header && (
                <Text
                    style={{
                        fontSize: 18,
                        marginTop: 45,
                        marginBottom: 10,
                        fontWeight: 'bold',
                    }}
                >
                    {header}
                </Text>
            )}
            <FormControl isInvalid={!!errors[fields[0]]}>
                <FormLabel htmlFor={fields[0]}>Type</FormLabel>
                <Controller
                    name={fields[0]}
                    control={control}
                    rules={{ required: 'This is required' }}
                    render={({ field }) => (
                        <Select
                            placeholder="Select..."
                            {...field}
                            onChange={e => field.onChange(e?.target.value)}
                            value={locationTypeOptions.find(
                                option => option.value === field?.value
                            )?.value}
                        >
                            {locationTypeOptions.map(item => (
                                <option key={item.value} value={item.label}>
                                    {item.value}
                                </option>
                            ))}
                        </Select>
                    )}
                />
                <FormErrorMessage>
                    {errors[fields[0]] && errors[fields[0]].message}
                </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors[fields[1]]} mt={5}>
                <FormLabel htmlFor={fields[1]}>{nameFieldTitle}</FormLabel>
                <Input
                    id={fields[1]}
                    placeholder={nameFieldTitle}
                    {...register(fields[1])}
                />
                <FormErrorMessage>
                    {errors[fields[1]] && errors[fields[1]].message}
                </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors[fields[2]]} mt={5}>
                <FormLabel htmlFor={fields[2]}>Format</FormLabel>
                <Controller
                    name={fields[2]}
                    control={control}
                    rules={{ required: 'This is required' }}
                    render={({ field }) => (
                        <Select
                            placeholder="Select..."
                            {...field}
                            onChange={e => field.onChange(e?.target.value)}
                            value={locationFormatOptions.find(
                                option => option.value === field?.value
                            )?.value}
                        >
                            {locationFormatOptions.map(item => (
                                <option key={item.value} value={item.label}>
                                    {item.value}
                                </option>
                            ))}
                        </Select>
                    )}
                />
                <FormErrorMessage>
                    {errors[fields[2]] && errors[fields[2]].message}
                </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors[fields[3]]} mt={5} mb={5}>
                <FormLabel htmlFor={fields[3]}>Index</FormLabel>
                <Controller
                    name={fields[3]}
                    control={control}
                    render={({ field }) => (
                        <Select
                            placeholder="Select..."
                            {...field}
                            onChange={e => field.onChange(e?.target.value)}
                            value={locationIndexOptions.find(
                                option =>
                                    option.value === field?.value?.toString()
                            )?.value}
                        >
                            {locationIndexOptions.map(item => (
                                <option key={item.value} value={item.label}>
                                    {item.value}
                                </option>
                            ))}
                        </Select>
                    )}
                />
                <FormErrorMessage>
                    {errors[fields[3]] && errors[fields[3]].message}
                </FormErrorMessage>
            </FormControl>
        </>
    )
}
