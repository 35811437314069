import React from 'react'
import { observer } from 'mobx-react-lite'
import { TabsComponent } from 'components/Tabs'
import { featureFlagsTabsList } from 'constants/admin'
import { HeadingStyled, TenantsBox, TabsBox } from './styles'

export const AdminFeatureFlags = observer(() => {
    return (
        <TenantsBox>
            <HeadingStyled>Admin Feature Flags</HeadingStyled>
            <TabsBox width="90%">
                <TabsComponent
                    listTabs={featureFlagsTabsList}
                    name='activeFeatureFlagsTab'
                />
            </TabsBox>
        </TenantsBox>
    )
})
