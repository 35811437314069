import React, { FC } from 'react'
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { useLocalStorage } from 'hooks/useLocalStorage'

interface ITabsComponent {
    listTabs: IListTab[]
    name: string
}

interface IListTab {
    headTab: string
    bodyTab: React.ReactElement
}

export const TabsComponent: FC<ITabsComponent> = ({ listTabs, name }) => {
    const [tabIndex, setTabIndex] = useLocalStorage(name, 0)

    const handleTabsChange = (index: number) => {
        setTabIndex(index)
    }

    return (
        <Tabs
            variant="line"
            index={tabIndex}
            defaultIndex={0}
            onChange={handleTabsChange}
            isLazy
        >
            <TabList marginLeft="16px">
                {listTabs.map((el: IListTab) => (
                    <Tab marginRight="32px" key={el.headTab}>
                        {el.headTab}
                    </Tab>
                ))}
            </TabList>
            <TabPanels>
                {listTabs.map((el: IListTab, index: number) => (
                    <TabPanel key={index}>{el.bodyTab}</TabPanel>
                ))}
            </TabPanels>
        </Tabs>
    )
}
