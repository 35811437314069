import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import {
    BoxProps,
    Center,
    Divider,
    Flex,
    IconButton,
    Spacer,
    Text,
    VStack,
} from '@chakra-ui/react'
import { CrossSmallIcon, MenuDotsIcon } from 'icons'
import { ShippingRateCard } from './ShippingRateCard'
import { SLACard } from './SLA Chart'
import { OrderThroughputCard } from './OrderThroughputCard'
import { KPIBox } from './styles'

export const KPI: FC<BoxProps> = observer(props => {
    return (
        <KPIBox{...props}>
            <Flex>
                <Text color="color.300" variant="h2" marginBottom="8px">
                    KPI Metrics
                </Text>
                <Spacer />
                <Center>
                    <IconButton
                        aria-label="Menu Dots Icon"
                        variant="hoveredSmallWhite"
                        icon={<MenuDotsIcon padding="5px" boxSize="18px" />}
                    />
                </Center>
                <Center paddingLeft="3px">
                    <IconButton
                        aria-label="Cross Small Icon"
                        variant="hoveredSmallWhite"
                        icon={<CrossSmallIcon padding="5px" boxSize="18px" />}
                    />
                </Center>
            </Flex>
            <Divider marginTop="8px" />
            <Flex marginBottom="16px">
                <Spacer />
            </Flex>
            <VStack width="100%" spacing="16px">
                <ShippingRateCard />
                <SLACard />
                <OrderThroughputCard />
            </VStack>
        </KPIBox>
    )
})
