import React, { ChangeEvent, FC, KeyboardEvent, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { InputSearchSquare } from 'components/baseComponents/InputSearchSquare'
import { workflowVersionsStore } from 'stores'

interface IInput {
    isRuleVersionSelection: boolean
}

export const Input: FC<IInput> = observer(({isRuleVersionSelection = false}) => {
    const [value, setValue] = useState<string>('')

    const workflowVersion = workflowVersionsStore.filters.workflowVersion

    const handleClear = async (): Promise<void> => {
        setValue('')
        if (workflowVersion) {
            workflowVersionsStore.setFilters({ workflowVersion: 0 })
            isRuleVersionSelection ? await workflowVersionsStore.fetchWorkflowByHeaderId() : await workflowVersionsStore.fetch()
        }
    }

    const handleChange = (value: ChangeEvent<HTMLInputElement>): void => {
        const toNumber = Number(value.target.value)
        if (!isNaN(toNumber)) {
            setValue(value.target.value)
        }
    }

    const handleSearch = async (): Promise<void> => {
        workflowVersionsStore.setFilters({ workflowVersion: Number(value) })
        isRuleVersionSelection ? await workflowVersionsStore.fetchWorkflowByHeaderId() : await workflowVersionsStore.fetch()
    }

    const handleKeypress = async (event: KeyboardEvent<object>): Promise<void> => {
        if (event.key === 'Enter') {
            await handleSearch()
        }
    }

    useEffect(() => {
        if (!workflowVersion) {
            setValue('')
        }
    }, [workflowVersion])

    return (
        <InputSearchSquare
            value={value}
            placeholder="Search by version..."
            handleClear={handleClear}
            handleChange={handleChange}
            handleSearch={handleSearch}
            handleKeypress={handleKeypress}
        />
    )
})
