import { useState } from 'react'
import {Text} from '@chakra-ui/layout'
import {  Center, IconButton, Link, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import * as htmlToImage from 'html-to-image'
import { MenuDotsIcon, DownloadIcon } from 'icons'
import { FC, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { CSVLink } from 'react-csv'
import { shipperDataToString } from '../SLA Chart/Chart/utils'
import { ShipperData } from 'types/core'
import { Data } from 'react-csv/components/CommonPropTypes'


interface ChartDataProps {
    data: string | Data | (() => string | Data),
    fileName: string,
    id: string
}

export const ExportMenuOption: FC<ChartDataProps> = observer(({
    data,
    fileName,
    id
}) => {

    const csvRef = useRef<HTMLAnchorElement>(null)
    const [csv, updatecsv] = useState(false)

    useEffect(() => {
        if (csvRef.current) {
            csvRef?.current?.click()
            updatecsv(false)
        }
    }, [csv]);

    const htmlDownload = (type: string) => {
        htmlToImage.toPng(document.getElementById(id)!)
            .then(function (dataUrl) {
                const link = document.createElement('a')
                link.href = dataUrl
                link.download = type
                document.body.appendChild(link)
                link.click()
            })
    }
    const csvReport = {
        data: fileName === 'OrdersDelivered' ? shipperDataToString(data as unknown as ShipperData) : data,
        filename: fileName
    };
    const onMenuClick = () => {
        updatecsv(true)
    }
    return (
        <>
            <Center>
                <Menu matchWidth>
                    <MenuButton
                        as={IconButton}
                        aria-label="More options"
                        icon={
                            <MenuDotsIcon
                                padding="5px"
                                boxSize="18px"
                                color={'white'}
                            />
                        }
                        variant="hoveredSmallWhite"
                    />
                    <MenuList minW='0' width='200px'>
                        <MenuItem
                            icon={<DownloadIcon />}
                            onClick={() => htmlDownload(fileName)}
                        >
                            <Text fontWeight={'thin'}>
                                {'Export as PNG'}
                            </Text>
                        </MenuItem>
                        <MenuItem
                            icon={<DownloadIcon />}
                            onClick={onMenuClick}
                        >
                            <Text fontWeight={'thin'}>
                                {'Export as CSV'}
                            </Text>
                        </MenuItem>
                    </MenuList>
                </Menu>
            </Center>
            {csv && <CSVLink {...csvReport}><Link ref={csvRef}></Link></CSVLink>}

        </>
    )
})
