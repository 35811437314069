import React, { useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Box, Flex, Text } from '@chakra-ui/layout'
import { toJS } from 'mobx'
import { secretsStore, tenantsStore } from 'stores'
import EditableTable from 'components/TableComponents/Table'
import { NumericPagination } from 'components/NumericPagination'
import {
    columnsApiKey,
    columnsResourceOwner,
} from 'components/TableComponents/mock'

const AdminSecretsPage = observer(() => {
    const dataApiKeys = toJS(secretsStore.dataApiKeys)
    const dataResourceOwners = toJS(secretsStore.dataResourceOwners)
    const { tenantId } = tenantsStore
    const columnsAuthApiKey = useMemo(() => columnsApiKey, [])
    const columnsAuthResourceOwner = useMemo(() => columnsResourceOwner, [])

    useEffect(() => {
        tenantId && secretsStore.fetchApiKeys(tenantId)
        tenantId && secretsStore.fetchResourceOwners(tenantId)

        return () => {
            secretsStore.clean()
        }
    }, [tenantId])

    return (
        <div>
            <Box padding="31px 0">
                <Text marginBottom="24px">Auth Api Key</Text>
                <Flex color="white" overflowY={'scroll'}>
                    <Box flex="1">
                        <EditableTable
                            data={dataApiKeys}
                            columns={columnsAuthApiKey}
                        />
                    </Box>
                </Flex>
            </Box>
            <NumericPagination
                marginTop="24px"
                pages={secretsStore.pagesApiKeys}
                activePage={secretsStore.filtersApiKeys.page}
                onChangePage={page => {
                    secretsStore.setFiltersApiKeys({ page })
                    secretsStore.fetchApiKeys(tenantId)
                }}
            />
            <Box padding="31px 0">
                <Text marginBottom="24px">Auth Resource Owner</Text>
                <Flex color="white" overflowY={'scroll'}>
                    <Box flex="1">
                        <EditableTable
                            data={dataResourceOwners}
                            columns={columnsAuthResourceOwner}
                        />
                    </Box>
                </Flex>
            </Box>
            <NumericPagination
                marginTop="24px"
                pages={secretsStore.pagesResourceOwners}
                activePage={secretsStore.filtersResourceOwners.page}
                onChangePage={page => {
                    secretsStore.setFiltersResourceOwners({ page })
                    secretsStore.fetchResourceOwners(tenantId)
                }}
            />
        </div>
    )
})

export default AdminSecretsPage
