import { Box, Heading } from '@chakra-ui/layout'
import React, { useEffect } from 'react'
import { dashboardStore } from 'stores'
import { Dashboard } from './content/Dashboard'
import { DashboardNotification } from './content/DashboardNotification'

export const HomePage = () => {
    useEffect(() => () => {
        dashboardStore.clearNotification()
    })

    return (
        <Box padding="31px 0">
            <Heading as="h1" variant="h1" marginBottom="24px">
                My DOM Dashboard
            </Heading>

            <DashboardNotification />

            <Dashboard />
        </Box>
    )
}
