import React, { FC, useEffect, useState } from 'react'
import { Button } from '@chakra-ui/button'
import { Box } from '@chakra-ui/layout'
import { Card } from './Card'
import { ArrowLeftIcon, ArrowRightCardIcon } from 'icons'
import { CardsBox } from './styles'

export interface ICard {
    id: string | number
    topValue: string | number | null
    middleValue?: string | number | null
    bottomValue?: string | number | null
}

interface CardProps {
    cards: ICard[]
    onDelete?: (topValue: string | number | null) => void
    middleHeader?: string
    bottomHeader?: string
}

export const Cards: FC<CardProps> = ({
    cards,
    onDelete,
    middleHeader,
    bottomHeader,
}) => {
    const [isFirstCard, setIsFirstCard] = useState(true)
    const [isLastCard, setIsLastCard] = useState(false)
    const [shownCards, setShownCards] = useState<ICard[]>([])
    const [cardOffset, setCardOffset] = useState(0)

    const onBack = () => setCardOffset(prev => prev - 1)
    const onForward = () => setCardOffset(prev => prev + 1)

    useEffect(() => {
        if (!cards.length) return
        if (cards.length <= 3) {
            setIsFirstCard(true)
            setIsLastCard(true)
            setShownCards(cards)
            return
        }
        setShownCards(cards.slice(cardOffset, cardOffset + 3))
    }, [cardOffset, cards])

    useEffect(() => {
        if (!cards.length || !shownCards.length) return
        const isFirst = cards[0].id === shownCards[0].id
        const isLast =
            cards[cards.length - 1].id === shownCards[shownCards.length - 1].id

        setIsFirstCard(isFirst)
        setIsLastCard(isLast)
    }, [shownCards, cards])

    return (
        <>
            {!!cards.length && (
                <CardsBox>
                    <Button
                        variant="flipArrows"
                        disabled={isFirstCard}
                        onClick={onBack}
                    >
                        <Box>
                            <ArrowLeftIcon
                                boxSize="11px"
                                color="color.100"
                            />
                        </Box>
                    </Button>
                    {shownCards.map(({ id, topValue, middleValue, bottomValue }: ICard) =>
                        <Card
                            key={id}
                            id={id}
                            topValue={topValue}
                            middleValue={middleValue}
                            bottomValue={bottomValue}
                            middleHeader={middleHeader}
                            bottomHeader={bottomHeader}
                            onDelete={onDelete}
                        />
                    )}
                    <Button
                        variant="flipArrows"
                        disabled={isLastCard}
                        onClick={onForward}
                    >
                        <Box>
                            <ArrowRightCardIcon
                                boxSize="11px"
                                color="color.100"
                            />
                        </Box>
                    </Button>
                </CardsBox>
            )}
        </>
    )
}
