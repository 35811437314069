import React, { FC } from 'react'
import { ICsvImport } from 'types/core'
import { ListItem } from '@chakra-ui/react'
import { ListStyle } from './styles'

interface PopupForImportProps {
    comparedValue: ICsvImport[]
}

export const PopupForImport: FC<PopupForImportProps> = ({ comparedValue }) => {
    return (
        <ListStyle>
            {comparedValue.length > 0 &&
                comparedValue.map((data: ICsvImport) => (
                    <ListItem key={data.key}>{data.key}</ListItem>
                ))}
        </ListStyle>
    )
}