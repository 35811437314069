import React, { useEffect, useState } from 'react'
import { toJS } from 'mobx'
import AceEditor from 'react-ace'
import Select, { SingleValue } from 'react-select'
import 'brace/mode/json'
import 'brace/theme/twilight'
import 'brace/theme/github'
import 'brace/snippets/json'
import 'brace/ext/language_tools'
import {
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useColorMode,
} from '@chakra-ui/react'
import { Flex } from '@chakra-ui/layout'
import { mappingStore, notificationStore } from 'stores'
import { StatusType } from 'types/chart'
import { ISelectOption } from 'types/core'
import {
    ModalBodyStyled,
    ModalCloseButton,
    ModalConfirmButton,
} from 'components/Modal/ConfirmModal/style'
import { reactSelectStyles } from 'components/baseComponents/Select/styles'

interface IJsonEditorModal {
    item: string
    data: object
    openModal: boolean
    handleToggleModal: () => void
    handleSave: (value: object) => void
    handleSaveCopy: (value: object) => void
    theme: string
}

const optionJsonEditor = {
    enableBasicAutocompletion: true,
    enableLiveAutocompletion: true,
    enableSnippets: true,
    showLineNumbers: true,
    tabSize: 2,
    wrap: true,
}

export const JsonEditorModal = ({
    data,
    openModal,
    handleToggleModal,
    handleSave,
    theme,
    item,
    handleSaveCopy,
}: IJsonEditorModal) => {
    const { colorMode } = useColorMode()

    const [jsonModalData, setJsonModalData] = useState(JSON.stringify(data))
    const mappingOptions = toJS(mappingStore.mappingOptions)

    const setDefaultJsonData = (data: object) => {
        try {
            const formattedData = JSON.stringify(data, null, 2)
            setJsonModalData(formattedData)
        } catch (e) {
            setJsonModalData('')
        }
    }

    useEffect(() => {
        setDefaultJsonData(data)
    }, [data])

    const handleSaveData = () => {
        try {
            JSON.parse(jsonModalData)
            handleSave(JSON.parse(jsonModalData))
            handleSaveCopy(JSON.parse(jsonModalData))
            handleToggleModal()
        } catch (e) {
            notificationStore.toast({
                title: 'Error',
                description: 'This not JSON',
                status: StatusType.Error,
            })
        }
    }

    const handleChangeOption = (option: SingleValue<ISelectOption>) => {
        try {
            const jsonData = JSON.stringify(JSON.parse(option?.value as string), null, 2)
            setJsonModalData(jsonData)
        } catch (e) {
            notificationStore.toast({
                title: 'Error',
                description: 'An error occurred',
                status: StatusType.Error,
            })
        }
    }

    const onClose = () => {
        setDefaultJsonData(data)
        handleToggleModal()
    }

    return (
        <Modal isOpen={openModal} size="2xl" onClose={onClose}>
            <ModalOverlay />
            <ModalContent alignSelf="center" backgroundColor="bgGray700">
                <ModalHeader>
                    <Flex justifyContent="center">
                        <Select
                            placeholder="Select..."
                            isMulti={false}
                            options={mappingOptions}
                            onChange={handleChangeOption}
                            styles={reactSelectStyles(colorMode, '500px', '0')}
                        />
                    </Flex>
                </ModalHeader>
                <ModalBodyStyled textAlign="center">
                    <AceEditor
                        mode="json"
                        theme={theme === 'dark' ? 'twilight' : 'github'}
                        name={`'json-editor '${item}`}
                        fontSize={14}
                        width="642px"
                        showPrintMargin
                        showGutter
                        highlightActiveLine
                        value={jsonModalData}
                        setOptions={optionJsonEditor}
                        editorProps={{ $blockScrolling: true }}
                        readOnly={true}
                    />
                </ModalBodyStyled>
                <ModalFooter justifyContent="right">
                    <ModalCloseButton variant="blue" onClick={onClose}>
                        Close
                    </ModalCloseButton>
                    <ModalConfirmButton variant="blue" onClick={handleSaveData}>
                        Save
                    </ModalConfirmButton>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

