import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { format } from 'date-fns'
import { IOrderFilters, ISavedOrderFilters } from 'types/api'
import { statuses as filterStatuses } from './ShowFilters/mock'

export const mapErrors = filterStatuses.reduce(
    (acc: string[], { name }): string[] => {
        const checkIsError = /error|failed/gim.test(name)
        if (checkIsError) acc.push(name)
        return acc
    },
    []
)

export const mapStatuses = (): string[] => {
    const map: string[] = []
    mapErrors.forEach(value => {
        const findEnumValue = filterStatuses.find(({ name }) => name === value)
        if (findEnumValue) map.push(...findEnumValue.enumValue)
    })

    return map
}

export const getSelectedFilters = (filter: IOrderFilters): string | number => {
    const {
        createdFrom,
        createdTo,
        deliveryFrom,
        deliveryTo,
        statuses,
        ShipmentProviderCodes,
        orderSourceIds,
    } = filter
    const getStatuses = filterStatuses.filter(({ enumValue }) =>
        statuses.some(value => enumValue.includes(value))
    )
    let selectedFilters = 0

    if (createdFrom || createdTo) selectedFilters++
    if (deliveryFrom || deliveryTo) selectedFilters++

    selectedFilters =
        selectedFilters +
        (getStatuses?.length || 0) +
        (ShipmentProviderCodes?.length || 0) +
        (orderSourceIds?.length || 0)

    return selectedFilters || ''
}

export const isInvalid = (date: MaterialUiPickersDate): boolean | void => {
    if (date) {
        if (new Date(date).toString() === 'Invalid Date') return true
        if (date < new Date('01/01/1900')) return true
        if (date > new Date('01/01/2100')) return true
    }
}

export const isValidDate = (
    dateFrom: MaterialUiPickersDate,
    dateTo: MaterialUiPickersDate
): boolean => {
    if (!dateFrom && !dateTo) return false
    if (isInvalid(dateFrom) || isInvalid(dateTo)) return false

    return !(
        dateFrom &&
        dateTo &&
        new Date(format(new Date(dateFrom), 'MM/dd/yyyy')) > dateTo
    )
}

export const getFiltersNames = (
    savedFilters: ISavedOrderFilters[],
    editFiltersName: string
) => {
    return savedFilters.reduce((acc: string[], { name }) => {
        if (name !== editFiltersName) {
            acc.push(name)
        }
        return acc
    }, [])
}
