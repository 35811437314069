import React, { FC } from 'react'
import {
    userStatusTransform,
    statusColorTransform,
    textColorTransform,
} from 'utils/userStatusTransform'
import { TagBox, TagText } from './styles'

interface IRuleStatusTag {
    status: string
    backgroundColor?: string
    textColor?: string
}

export const RuleStatusTag: FC<IRuleStatusTag> = ({
    status,
    backgroundColor,
    textColor,
}) => {
    const mapStatus = userStatusTransform(status)
    const mapBoxColor = backgroundColor || statusColorTransform(status)
    const mapTextColor = textColor || textColorTransform(status)

    return (
        <TagBox backgroundColor={mapBoxColor}>
            <TagText color={mapTextColor}>
                {mapStatus}
            </TagText>
        </TagBox>
    )
}
