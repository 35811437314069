import React, { FC, ReactElement } from 'react'
import { Tooltip } from '@chakra-ui/react'

interface ITooltipTextCut {
    maxTextLength: number
    text?: string | number
    children?: ReactElement
}

export const TooltipTextCut: FC<ITooltipTextCut> = ({ text, maxTextLength, children }) => {
    if (text && text.toString().length < maxTextLength && children) return children

    return (
        <Tooltip label={text}>
            {children}
        </Tooltip>
    )
}
