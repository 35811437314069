import React, { ChangeEvent, FC, useState } from 'react'
import { CellProps } from 'react-table'
import { Switch } from '@chakra-ui/switch'
import { userStore } from 'stores'
import { isAdminUser } from 'utils/jwt'

export const CheckBoxFeatureFlagsCell: FC<CellProps<boolean[]>> = ({ value: initialValue, updateMyData, column, row })  => {
    const [value, setValue] = useState(initialValue)
    const roles = userStore.roles

    const onChange = (event: ChangeEvent<HTMLInputElement>) =>{
        updateMyData(row.index, column.id, event.target.checked)
        setValue(event.target.checked)
    }

    return <Switch
        variant='switchBoolean'
        isDisabled={!isAdminUser(roles)}
        isChecked={value}
        onChange={onChange}
    />
}

export default CheckBoxFeatureFlagsCell
