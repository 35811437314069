import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const PaginationArrowLeftIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 7 12"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.63375 0.234906C6.31934 -0.0783006 5.81413 -0.0783007 5.50093 0.234906L0.187205 5.54899C-0.0624006 5.79835 -0.0624006 6.20132 0.187205 6.45069L5.50092 11.765C5.81413 12.0783 6.31934 12.0783 6.63375 11.765C6.94696 11.4516 6.94696 10.9463 6.63375 10.633L2.00285 5.9966L6.63975 1.36053C6.94696 1.05332 6.94696 0.542113 6.63375 0.234906Z"
                fill="currentColor"
            />
        </Icon>
    )
}

