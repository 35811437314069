import { makeAutoObservable } from 'mobx'
import { isEqual, isEmpty } from 'lodash'
import { editoreStore, notificationStore } from 'stores'
import { OptionData } from 'components/baseComponents/Selects'
import { AvailableValuesService } from 'services/availableValues'
import { IDependencies, UUID } from 'types/core'

export class InternalParamStore {
    private parameterId: UUID
    private stepId: UUID
    private defaultSelected: string

    public options: OptionData[] = []
    public value: OptionData | null = null
    public dependencies: IDependencies = {}

    constructor(parameterId: UUID, stepId: UUID, defaultSelected: string) {
        this.parameterId = parameterId
        this.stepId = stepId
        this.defaultSelected = defaultSelected
        makeAutoObservable(this)
    }

    public async init() {
        try {
            const dependencies = isEmpty(this.dependencies)
                ? undefined
                : this.dependencies

            const availableValues = await AvailableValuesService.getValues({
                parameterId: this.parameterId,
                dependencies,
            })

            this.initOptions(availableValues.data)
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    public async selectValue(option: OptionData | null) {
        if (this.value === option) return

        const newValue = option?.value || 'null'
        editoreStore.diagramStore.changeParameterValue(this.parameterId, this.stepId, newValue)

        this.value = option || null
        await this.saveValue()
    }

    public async setDependencies(dependencies: IDependencies) {
        this.initDependencies(dependencies)
        try {
            await this.selectValue(null)
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
        await this.init()
    }

    private initDependencies(dependencies: IDependencies) {
        this.dependencies = dependencies
    }

    private initOptions(options: OptionData[]) {
        if (!isEqual(this.options, options)) {
            this.options = options
            const selected = this.options.find(option => option.value === this.defaultSelected)
            this.value = selected || null
        }
    }

    private async saveValue() {
        await AvailableValuesService.changeValue(this.parameterId, this.value!.value)
    }
}
