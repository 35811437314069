import { AzureAuthService } from 'services/azureAuth'

export const getAzureToken = async () => {
    let authTokenAzureAD
    try {
        authTokenAzureAD = await AzureAuthService.getAuthTokenAzureAD()
    } catch (e) {
        authTokenAzureAD = ''
    }
    return authTokenAzureAD
}
