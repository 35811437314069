import { Grid } from '@chakra-ui/layout'
import { chakra } from 'utils/chakra'

export const ApproverDashboardGrid = chakra(Grid, {
    templateColumns: '1fr 280px',
    templateRows: 'repeat(2, auto)',
    templateAreas: `
        "rules  approvals"
        "orders kpi   "
    `,
    gap: '15px',
})

export const PlainUserDashboardGrid = chakra(Grid, {
    templateColumns: '1fr',
    templateRows: 'repeat(2, auto)',
    templateAreas: `
       "rules kpi"
        "orders kpi"
    `,
    gap: '15px',
})
