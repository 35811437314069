import React, { FC } from 'react'
import { Center, IconButton } from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'
import { orderStore } from 'stores'
import { CrossSmallIcon } from 'icons'
import { wordWrap } from 'utils/wordWrap'
import { SelectedFilter, FilterName, FilterValues, TooltipStyled } from './styles'

interface ISelectedNumber {
    isSavedFilters: boolean
    orderNumber: string
}

export const SelectedNumber: FC<ISelectedNumber> = observer(({
    isSavedFilters,
    orderNumber,
}) => {
    const handleClear = async (): Promise<void> => await orderStore.clearFilters('orderNumber')

    return (
        <TooltipStyled
            label={
                orderNumber.length > 30
                    ? wordWrap(orderNumber, 45)
                    : ''
            }
        >
            <SelectedFilter backgroundColor="blueWhite.990" >
                <FilterName>Order number:</FilterName>
                <FilterValues>
                    {orderNumber.length > 30 ? `${ orderNumber.slice(0, 30) }...` : orderNumber}
                </FilterValues>
                {!isSavedFilters && (
                    <Center>
                        <IconButton
                            onClick={handleClear}
                            aria-label="Order Filter Cross Small Icon"
                            variant="hoveredMediumWhite"
                            marginLeft="10px"
                            icon={
                                <CrossSmallIcon padding="3px" boxSize="20px" />
                            }
                        />
                    </Center>
                )}
            </SelectedFilter>
        </TooltipStyled>
    )
})
