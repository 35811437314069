import React, { useEffect, useMemo, useState } from 'react'
import { Column } from 'react-table'
import { observer } from 'mobx-react-lite'
import { useForm } from 'react-hook-form'
import { Button, useDisclosure } from '@chakra-ui/react'
import { Flex } from '@chakra-ui/layout'
import { featureFlagStore, notificationStore, tenantsStore, userStore } from 'stores'
import { StatusType } from 'types/chart'
import { isAdminUser } from 'utils/jwt'
import { columnsFeatureFlagsPerTenant } from 'components/TableComponents/mock'
import EditableTable from 'components/TableComponents/Table'
import { TenantTree } from 'components/TenantsComponents/TenantTree'
import CustomModal from 'components/TableComponents/TableModal'
import { NumericPagination } from 'components/NumericPagination'
import { ConfirmModal } from 'components/Modal/ConfirmModal'
import { AddFeatureFlag } from '../AddFeatureFlag'
import {
    FeatureFlagsBox,
    FeatureFlagsBoxColor,
    FeatureFlagsHeader,
    FeatureFlagsRightBox,
    TreeBox,
} from '../styles'

export const TenantsFlagsTab = observer(() => {
    const [deleteRowIndex, setDeleteRowIndex] = useState<string | number>('')
    const { isOpen, onOpen, onClose } = useDisclosure()
    const {
        isOpen: isOpenDelete,
        onOpen: onOpenDelete,
        onClose: onCloseDelete,
    } = useDisclosure()

    const { tenantId } = tenantsStore
    const roles = userStore.roles
    const data = featureFlagStore.tenantsList
    const customToast = notificationStore.toast

    const {
        handleSubmit,
        register,
        reset,
        formState: { errors, isSubmitting },
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
    })

    useEffect(() => {
        if (tenantId) featureFlagStore.fetchTenants(tenantId)
        return () => {
            featureFlagStore.cleanTenants()
        }
    }, [tenantId])

    const columns: Column[] = useMemo(() => columnsFeatureFlagsPerTenant, [])

    const updateMyData = (rowIndex: number, columnId: string, value: string) => {
        const updateData = data[rowIndex]
        featureFlagStore.changeTenants(tenantId, {...updateData, [columnId]: value})
            .then(() => featureFlagStore.fetchTenants(tenantId))
    }

    const openDeleteModal = (rowIndex: number | string) => {
        setDeleteRowIndex(rowIndex)
        onOpenDelete()
    }

    const onDelete = () => {
        if (typeof deleteRowIndex === 'string')
        featureFlagStore.deleteTenants(deleteRowIndex, tenantId)
            .then(() => featureFlagStore.fetchTenants(tenantId))
            .finally(() => onCloseDelete())
    }

    const onSubmit = (data: { name: string, enable: boolean, isHierarchical: boolean }) => {
        featureFlagStore.createFeatureFlagTenant(tenantId, data.name, data.enable, data.isHierarchical)
            .then(() => {
                featureFlagStore.fetchTenants(tenantId)
                reset()
                onClose()
            })
            .catch((error) => {
                customToast({
                    title: 'Error',
                    description: error.response.data,
                    status: StatusType.Error,
                })
            })
    }

    return (
        <Flex>
            <TreeBox>
                <FeatureFlagsHeader>
                    Tenants Tree
                </FeatureFlagsHeader>
                <TenantTree />
            </TreeBox>
            <FeatureFlagsRightBox>
                <FeatureFlagsBoxColor>
                    <FeatureFlagsBox>
                        <EditableTable
                            data={data}
                            columns={columns}
                            updateMyData={updateMyData}
                            openDeleteModal={openDeleteModal}
                        />
                    </FeatureFlagsBox>
                </FeatureFlagsBoxColor>
                <Button
                    margin='31px 20px'
                    size="sm"
                    variant="outline"
                    colorScheme="blue"
                    onClick={onOpen}
                    disabled={!isAdminUser(roles)}
                >
                    Add
                </Button>
                <CustomModal
                    modalTitle='Create New Feature Flag'
                    isOpen={isOpen}
                    onClose={onClose}
                    onSubmit={onSubmit}
                    handleSubmit={handleSubmit}
                    isSubmitting={isSubmitting}
                >
                    <AddFeatureFlag
                        errors={errors}
                        register={register}
                        isGlobal={false}
                    />
                </CustomModal>
                <NumericPagination
                    marginTop="24px"
                    pages={featureFlagStore.pages}
                    activePage={featureFlagStore.filters.page}
                    onChangePage={page => {
                        featureFlagStore.setFilters({ page })
                        featureFlagStore.fetchTenants(tenantId)
                    }}
                />
                <ConfirmModal
                    isOpen={isOpenDelete}
                    onClose={onCloseDelete}
                    confirmFunc={onDelete}
                    headerText="Delete Feature Flag"
                    bodyText="Are you sure?"
                    buttonText="Delete"
                    colorButton="red.500"
                />
            </FeatureFlagsRightBox>
        </Flex>
    )
})
