import { Flex, Text } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/button'
import { CustomInput } from 'components/baseComponents/Input'
import { chakra } from 'utils/chakra'

export const TenantsBox = chakra(Flex, {
    margin: '20px 0',
})

export const ButtonStyled = chakra(Button, {
    height: '26px',
    marginLeft: '3px',
})

export const SelectBox = chakra(Flex, {
    alignItems: 'center',
    marginLeft: '20px',
    marginTop: '20px',
})

export const GroupIdInput = chakra(CustomInput, {
    color: 'color.100',
    fontSize: '12px',
    lineHeight: '14px',
    width: '285px',
    borderRadius: '6px',
    alignItems: 'center',
    justifyContent: 'center',
    height: '32px',
    marginTop: '20px',
    backgroundColor: 'color.900',
    _hover: {
        boxShadow: '0 0 3px #5C5C5C',
    },
    _focus: {},
})

export const Title = chakra(Text, {
    variant: 'h5',
})

export const GroupIdContainer = chakra(Flex, {
    flexDirection: 'column',
})

export const SelectContainer = chakra(Flex, {
    flexDirection: 'column',
})
