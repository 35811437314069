import { Table, Thead, Tbody, Tr } from '@chakra-ui/react'
import { useTable, Column } from 'react-table'
import { TableData, TableHead } from './styles'

export type EditingRows = { [id: string]: boolean }

export type ReportsTableProps<Data extends object> = {
    data: Data[]
    columns: Column<Data>[]
}

const ReportsTable = <Data extends object>({
    data,
    columns,
}: ReportsTableProps<Data>) => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({
            columns,
            data,
        })

    return (
        <Table {...getTableProps()}>
            <Thead>
                {headerGroups.map(headerGroup => (
                    <Tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <TableHead
                                {...column.getHeaderProps({
                                    style: { width: column.width },
                                })}
                            >
                                {column.render('Header')}
                            </TableHead>
                        ))}
                    </Tr>
                ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row)
                    return (
                        <Tr {...row.getRowProps()}>
                            {row.cells.map(cell => (
                                <TableData
                                    {...cell.getCellProps({
                                        style: {
                                            width: cell.column.width,
                                        },
                                    })}
                                >
                                    {cell.render('Cell')}
                                </TableData>
                            ))}
                        </Tr>
                    )
                })}
            </Tbody>
        </Table>
    )
}

export default ReportsTable
