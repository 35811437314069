import { Box, Center, Container } from '@chakra-ui/react'
import { chakra } from 'utils/chakra'

export const PageBox = chakra(Container, {
    minWidth: 'container.md',
    padding: '15px 10px',
})

export const TitleHeader = chakra(Center, {
    position: 'absolute',
    width: '100%',
    left: '0px',
    top: '0px',
})

export const PaginationButton = chakra(Box, {
    cursor: 'pointer',
    width: '8px',
    height: '8px',
    borderRadius: '50%',
})
