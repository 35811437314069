import { makeAutoObservable } from 'mobx'
import { DataNode, Key } from 'rc-tree/lib/interface'
import { TenantsApi } from 'api/tenants'
import { errorObjTransformString } from 'utils/objectTransform'
import { notificationStore } from 'stores'
import { IRoutingRuleResponse, JWTPayload, UiLoginFlow } from 'types/api'
import { StatusType } from 'types/chart'
import {
    IRoutingRule,
    ITenantsAccessHierarchy,
    ITenantTree,
    UUID,
} from 'types/core'
import { getPayload } from 'utils/jwt'
import { onLogOut } from 'utils/onLogOut'

interface tenantTreeChildModel {
    name: string
    domain: string
}

interface authTypes {
    name: string
    code: string
}

export class TenantsStore {
    public tenantsHierarchy: DataNode[] = []
    public tenantsAccessHierarchy: ITenantsAccessHierarchy[] = []
    public initialTenantsAccessHierarchy: ITenantsAccessHierarchy[] = []
    public initialTenants: ITenantTree[] = []
    public selectedTenant: ITenantTree = {} as ITenantTree
    public routingRule: IRoutingRule = {} as IRoutingRule
    public expandedKeys: Key[] = []
    public tenantsTreeSettings: ITenantTree[] = []
    public tenantId: string = ''
    public authTypes: authTypes[] = []
    public uiLoginFlow: UiLoginFlow[] = [
        UiLoginFlow.Popup,
        UiLoginFlow.Redirect,
    ]
    public isRootTenant: boolean = false
    public isAzureAD: boolean = false

    constructor() {
        makeAutoObservable(this)
    }

    public async setSelectedTenant(tenantId: UUID) {
        const basicSettings =
            this.tenantsTreeSettings.find(item => item.id === tenantId) ||
            ({} as ITenantTree)

        this.updateSelectedTenant(basicSettings)

        this.isAzureAD = false
        this.setIsRootTenant(tenantId)

        if (tenantId) {
            const settings = await this.getTenantSettingsData(tenantId)
            if (settings && settings.authenticationType === 'azure_ad') {
                const ssoSettings = await this.getTenantAdditionalSettingsData(
                    tenantId,
                    settings.authenticationType
                )
                this.updateSelectedTenant({
                    ...this.selectedTenant,
                    ...this.routingRule,
                    ...settings,
                    ...ssoSettings,
                })
            } else {
                this.updateSelectedTenant({
                    ...this.selectedTenant,
                    ...this.routingRule,
                    ...settings,
                })
            }
        }
    }

    private setIsRootTenant(tenantId: string) {
        this.isRootTenant = !!this.initialTenants.find(
            ({ id }) => id === tenantId
        )
    }

    public updateSelectedTenant(tenant: ITenantTree) {
        this.selectedTenant = tenant
        this.setTenantId(tenant.id)
    }

    public setIsAzureAD(value: string | null) {
        this.isAzureAD = value === 'azure_ad'
    }

    public async fetch() {
        try {
            const response = await TenantsApi.getRootTenants()
            this.init(response.items)
        } catch (err) {
            this.init([])
        }
    }

    public async getTenantChildrenTenants(tenantId: string | number) {
        try {
            const response = await TenantsApi.getChildren(tenantId)
            this.updateTenantsHierarchy(tenantId, response.items)
        } catch (err) {
            throw err
        }
    }

    public async saveTenantRootTenant(tenant: tenantTreeChildModel) {
        await TenantsApi.addRoot(tenant)
        await this.fetch()
    }

    public async saveTenantChildrenTenant(
        tenantId: string,
        childTenant: tenantTreeChildModel
    ) {
        const response = await TenantsApi.addChildren(tenantId, childTenant)
        this.displayTenantChild(tenantId, response)
        this.tenantsTreeSettings.push(response)
    }

    public async deleteSelectedTenant(tenantId: string) {
        try {
            const response = await TenantsApi.deleteTenant(tenantId)
            if (response.status === 204) {
                this.removeChild(this.tenantsHierarchy, tenantId)
            }
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    public async getAuthTypes() {
        try {
            const response = await TenantsApi.getAuthenticationTypes()
            this.initAuthTypes(response)
        } catch (err) {
            throw err
        }
    }

    public async getTenantSettingsData(tenantId: string) {
        try {
            const response = await TenantsApi.getSettings(tenantId)
            this.setIsAzureAD(response?.authenticationType)
            return response
        } catch (err) {
            throw err
        }
    }

    public async getTenantAdditionalSettingsData(
        tenantId: string,
        authType: string
    ) {
        try {
            return await TenantsApi.getSsoSettings(tenantId, authType)
        } catch (err) {
            console.error(err)
        }
    }

    public async getTenantRoutingRule(tenantId: string) {
        try {
            const response = await TenantsApi.getRoutingRule(tenantId)
            this.setRoutingRule(response)
        } catch (err) {
            this.setRoutingRule({} as IRoutingRuleResponse)
        }
    }

    public async saveTenantRoutingRule({
        id,
        location,
        ignoreCase,
        routingRuleName,
    }: ITenantTree) {
        try {
            await TenantsApi.saveRoutingRule(id, {
                name: routingRuleName,
                location,
                ignoreCase,
            })
        } catch (err: any | unknown) {
            if (err.response?.status === 400) {
                notificationStore.toast({
                    title: 'Error',
                    description: errorObjTransformString(
                        err.response.data.errors
                    ),
                    status: StatusType.Error,
                })
            }
        }
    }

    private updateTenantTreeName = (
        tenantId: UUID,
        name: string,
        parent: DataNode[]
    ): DataNode[] => {
        return parent.map(parent => {
            if (parent.key === tenantId) {
                return { ...parent, title: name }
            } else {
                return parent.children
                    ? {
                          ...parent,
                          children: this.updateTenantTreeName(
                              tenantId,
                              name,
                              parent.children
                          ),
                      }
                    : parent
            }
        })
    }

    public async saveTenantSettingsData(val: ITenantTree) {
        const values = val.hasAuth ? val : { ...val, authenticationType: null }
        const currentTenantId = values.id

        this.updateSelectedTenant(values)

        try {
            await TenantsApi.saveTenantSettings(
                currentTenantId,
                values.name,
                values.allowPermissionsInheritance,
                values.enableSubTenantRouting
            )

            this.tenantsHierarchy = this.updateTenantTreeName(
                currentTenantId,
                values.name,
                this.tenantsHierarchy
            )

            this.tenantsTreeSettings = this.tenantsTreeSettings.map(item => {
                return item.id === currentTenantId
                    ? {
                          ...item,
                          name: values.name,
                          allowPermissionsInheritance:
                              values.allowPermissionsInheritance,
                          enableSubTenantRouting: values.enableSubTenantRouting,
                      }
                    : item
            })
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }

        try {
            await TenantsApi.saveSettings(currentTenantId, {
                hasMasterData: values.hasMasterData,
                hasAuth: values.hasAuth,
                authenticationType: values.authenticationType,
            })
            this.setIsAzureAD(values.authenticationType)
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    public async saveSsoSettings({
        id,
        authenticationType,
        clientId,
        authorityUrl,
        uiLoginFlow,
    }: ITenantTree) {
        try {
            if (authenticationType) {
                await TenantsApi.saveSsoSettings(id, authenticationType, {
                    clientId,
                    authorityUrl,
                    uiLoginFlow,
                })
            } else {
                notificationStore.toast({
                    title: 'Error',
                    description: 'Authentication Type are empty',
                    status: StatusType.Error,
                })
            }
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    public async getTenantsAccessHierarchy() {
        try {
            const response = await TenantsApi.getAccessHierarchy()
            await this.initTenantsAccessHierarchy(response)
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    private async initTenantsAccessHierarchy(data: ITenantsAccessHierarchy[]) {
        const token = localStorage.getItem('authToken')
        if (token) {
            const payload: JWTPayload = getPayload(token)
            const findSelectedTenant = data.find(({ tenantId }) => tenantId === payload['luc:tenantid'])
            if (!findSelectedTenant) await onLogOut()
        }

        this.initialTenantsAccessHierarchy = data
        this.tenantsAccessHierarchy = data.filter(node => {
            const isParentTenantExist = data.find(
                n => n.tenantId === node.parentTenantId
            )?.tenantId

            node.children = data.filter(n => n.parentTenantId === node.tenantId)

            return !isParentTenantExist ? node : false
        })
    }

    private init(initialTenants: ITenantTree[]) {
        const tenantTree = initialTenants.map(item => ({
            key: item.id,
            title: item.name,
        }))
        this.initialTenants = initialTenants
        this.tenantsTreeSettings = initialTenants
        this.tenantsHierarchy = tenantTree
    }

    private addChild = (
        id: string | number,
        children: DataNode[] | DataNode,
        tree: DataNode[]
    ) => {
        tree.forEach((item: DataNode) => {
            if (item.key === id) {
                if (Array.isArray(children)) {
                    item.children = children
                } else if (item.children) {
                    item.children.push(children)
                }
            } else if (item.children) {
                this.addChild(id, children, item.children)
            }
        })
    }

    private removeChild = (tree: DataNode[] | undefined, tenantId: string) => {
        tree?.forEach((item: DataNode, index) => {
            if (item.key === tenantId) {
                return tree.splice(index, 1)
            } else {
                this.removeChild(item.children, tenantId)
            }
        })
        return tree
    }

    private updateTenantsHierarchy(
        tenantId: string | number,
        children: ITenantTree[]
    ) {
        const childNode = children.map(item => ({
            key: item.id,
            title: item.name,
        }))

        children.forEach(child => {
            const isExist = this.tenantsTreeSettings.find(
                ({ id }) => id === child.id
            )
            if (!isExist) this.tenantsTreeSettings.push(child)
        })

        this.addChild(tenantId, childNode, this.tenantsHierarchy)
    }

    private displayTenantChild(tenantId: string | number, child: ITenantTree) {
        const childNode = {
            key: child.id,
            title: child.name,
        }

        this.addChild(tenantId, childNode, this.tenantsHierarchy)
    }

    private initAuthTypes(authTypes: authTypes[]) {
        this.authTypes = authTypes
    }

    public setTenantId(id: string) {
        this.tenantId = id
    }

    public setExpandedKeys(expandedKeys: Key[]) {
        this.expandedKeys = expandedKeys
    }

    public setRoutingRule(routingRule: IRoutingRuleResponse) {
        this.routingRule = {
            routingRuleName: routingRule.name,
            location: routingRule.location,
            ignoreCase: routingRule.ignoreCase,
        }
    }

    public clearSelectedTenant() {
        this.selectedTenant = {} as ITenantTree
    }
}
