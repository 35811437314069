import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const ReportIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path 
                d="M9.5 12H5.5C5.23478 12 4.98043 12.1054 4.79289 12.2929C4.60536 12.4804 4.5 12.7348 4.5 13C4.5 13.2652 4.60536 13.5196 4.79289 13.7071C4.98043 13.8946 5.23478 14 5.5 14H9.5C9.76522 14 10.0196 13.8946 10.2071 13.7071C10.3946 13.5196 10.5 13.2652 10.5 13C10.5 12.7348 10.3946 12.4804 10.2071 12.2929C10.0196 12.1054 9.76522 12 9.5 12ZM13.5 2H12.32C12.1137 1.41645 11.7319 0.910998 11.2271 0.552938C10.7222 0.194879 10.1189 0.00173951 9.5 0H7.5C6.88106 0.00173951 6.2778 0.194879 5.77293 0.552938C5.26807 0.910998 4.88631 1.41645 4.68 2H3.5C2.70435 2 1.94129 2.31607 1.37868 2.87868C0.816071 3.44129 0.5 4.20435 0.5 5V17C0.5 17.7956 0.816071 18.5587 1.37868 19.1213C1.94129 19.6839 2.70435 20 3.5 20H13.5C14.2956 20 15.0587 19.6839 15.6213 19.1213C16.1839 18.5587 16.5 17.7956 16.5 17V5C16.5 4.20435 16.1839 3.44129 15.6213 2.87868C15.0587 2.31607 14.2956 2 13.5 2ZM6.5 3C6.5 2.73478 6.60536 2.48043 6.79289 2.29289C6.98043 2.10536 7.23478 2 7.5 2H9.5C9.76522 2 10.0196 2.10536 10.2071 2.29289C10.3946 2.48043 10.5 2.73478 10.5 3V4H6.5V3ZM14.5 17C14.5 17.2652 14.3946 17.5196 14.2071 17.7071C14.0196 17.8946 13.7652 18 13.5 18H3.5C3.23478 18 2.98043 17.8946 2.79289 17.7071C2.60536 17.5196 2.5 17.2652 2.5 17V5C2.5 4.73478 2.60536 4.48043 2.79289 4.29289C2.98043 4.10536 3.23478 4 3.5 4H4.5V5C4.5 5.26522 4.60536 5.51957 4.79289 5.70711C4.98043 5.89464 5.23478 6 5.5 6H11.5C11.7652 6 12.0196 5.89464 12.2071 5.70711C12.3946 5.51957 12.5 5.26522 12.5 5V4H13.5C13.7652 4 14.0196 4.10536 14.2071 4.29289C14.3946 4.48043 14.5 4.73478 14.5 5V17ZM11.5 8H5.5C5.23478 8 4.98043 8.10536 4.79289 8.29289C4.60536 8.48043 4.5 8.73478 4.5 9C4.5 9.26522 4.60536 9.51957 4.79289 9.70711C4.98043 9.89464 5.23478 10 5.5 10H11.5C11.7652 10 12.0196 9.89464 12.2071 9.70711C12.3946 9.51957 12.5 9.26522 12.5 9C12.5 8.73478 12.3946 8.48043 12.2071 8.29289C12.0196 8.10536 11.7652 8 11.5 8Z" 
                fill="currentColor"
            />
        </Icon>
    )
}