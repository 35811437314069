import React from 'react'
import { orderShippedMock } from 'pages/OrderDetailsPage/mock'
import { OrderShippedPanelBox, OrderShippedPanelItem } from 'pages/OrderDetailsPage/styles'

export const AccordionOrderShippedPanel = () => {
    return (
        <>
            {orderShippedMock.map(({ id, date, time, status }) => (
                <OrderShippedPanelBox key={id}>
                    <OrderShippedPanelItem variant="h6" color="mintgreen.500" flexGrow={2}>{id}</OrderShippedPanelItem>
                    <OrderShippedPanelItem variant="paragraph" flexGrow={1}>{date}</OrderShippedPanelItem>
                    <OrderShippedPanelItem variant="paragraph" flexGrow={1}>{time}</OrderShippedPanelItem>
                    <OrderShippedPanelItem variant="paragraph" flexGrow={5}>{status}</OrderShippedPanelItem>
                    <OrderShippedPanelItem flexGrow={0.3} />
                </OrderShippedPanelBox>
            ))}
        </>
    )
}
