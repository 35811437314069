import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const CircleDot: FC<IconProps> = props => {
    return (
        <Icon viewBox="0 4 30 30" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M 16 4 C 9.382813 4 4 9.382813 4 16 C 4 22.617188 9.382813 28 16 28 C 22.617188 28 28 22.617188 28 16 C 28 9.382813 22.617188 4 16 4 Z M 16 6 C 21.535156 6 26 10.464844 26 16 C 26 21.535156 21.535156 26 16 26 C 10.464844 26 6 21.535156 6 16 C 6 10.464844 10.464844 6 16 6 Z M 16 13 C 14.34375 13 13 14.34375 13 16 C 13 17.65625 14.34375 19 16 19 C 17.65625 19 19 17.65625 19 16 C 19 14.34375 17.65625 13 16 13 Z"
                fill="currentColor"
            />
        </Icon>
    )
}
