export const Input = {
    parts: ['field', 'addon'],
    variants: {
        calendar: {
            field: {
                color: 'color.100',
                fontSize: '12px',
                lineHeight: '14px',
                backgroundColor: 'color.900',
                border: '1px solid #464646',
                borderRadius: '6px',
                _placeholder: {
                    color: 'color.100',
                },
            },
        },
        searchBlocks: {
            field: {
                backgroundColor: 'transparent',
                height: '30px',
                border: '1px solid',
                borderColor: 'borderInput.200',
                borderRadius: '20px',
                color: 'color.100',
                fontSize: '12px',
                lineHeight: '14px',
                _placeholder: {
                    color: 'searchInputText',
                },
            },
        },
        login: {
            field: {
                color: 'color.100',
                fontSize: '12px',
                lineHeight: '14px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                padding: '9px 16px',
                width: '380px',
                height: '32px',
                background: 'blueWhite.950',
                border: '1px solid',
                borderColor: 'blue.800',
                boxSizing: 'border-box',
                borderRadius: '8px',
                flex: 'none',
                order: 1,
                alignSelf: 'stretch',
                flexGrow: 0,
                margin: '2px 0px',
                _placeholder: {
                    fontSize: '12px',
                    lineHeight: '14px',
                    color: 'blue.800',
                    flex: 'none',
                    order: 0,
                    flexGrow: 0,
                    margin: '0px 10px',
                },
            },
        },
        modal: {
            field: {
                color: 'color.100',
                fontWeight: '400',
                fontSize: '16px',
                lineHeight: '20px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                padding: '9px 16px',
                width: '390px',
                height: '44px',
                background: 'blueWhite.950',
                border: '1px solid',
                borderColor: 'color.850',
                boxSizing: 'border-box',
                borderRadius: '4px',
                flex: 'none',
                order: 1,
                alignSelf: 'stretch',
                flexGrow: 0,
                margin: '2px 0px',
                _placeholder: {
                    fontWeight: '400',
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: '#5C5C5C',
                    flex: 'none',
                    order: 0,
                    flexGrow: 0,
                    margin: '0px 10px',
                },
            },
        },
        node: {
            field: {
                height: '30px',
                background: 'blue.990',
                border: '1px solid',
                borderColor: 'gray.500',
                boxSizing: 'border-box',
                borderRadius: '8px',
                fontSize: '12px',
                lineHeight: '14px',
                color: 'white',
            },
        },
        nodeSelect: {
            field: {
                height: '30px',
                background: 'blue.990',
                boxSizing: 'border-box',
                borderRadius: '8px',
                fontSize: '12px',
                lineHeight: '14px',
                color: 'white',
                paddingLeft: '7px',
            },
        },
    },
}
