import { Center, Flex, Spacer, Text } from '@chakra-ui/layout'
import { Collapse } from '@chakra-ui/transition'
import { CrossSmallIcon } from 'icons'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { dashboardStore } from 'stores'
import { chakra } from 'utils/chakra'

const NotificationContainer = chakra(Flex, {
    backgroundColor: 'denim',
    padding: '8px 16px',
    marginBottom: '15px',
    borderRadius: '6px',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)',
})

export const DashboardNotification = observer(() => {
    return (
        <Collapse in={!!dashboardStore.notification}>
            <NotificationContainer>
                <Text>
                    <Text fontWeight="bold" as="span">
                        Success
                    </Text>
                    : {dashboardStore.notification}
                </Text>
                <Spacer />
                <Center>
                    <CrossSmallIcon
                        color="white"
                        cursor="pointer"
                        boxSize="10px"
                        onClick={() => dashboardStore.clearNotification()}
                    />
                </Center>
            </NotificationContainer>
        </Collapse>
    )
})
