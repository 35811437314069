import { Center, Grid, Text } from '@chakra-ui/layout'
import { CellData } from 'components/DateRangePicker/types'
import React, { FC } from 'react'
import { DayCell } from './DayCell'

const weekNames = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']

interface CalendarProps {
    cells: CellData[]
    selectDate: (date: Date) => void
}

export const Calendar: FC<CalendarProps> = ({ cells, selectDate }) => {
    return (
        <Grid templateColumns="repeat(7, 32px)">
            {weekNames.map(name => (
                <Center key={name} height="32px">
                    <Text variant="paragraphsmall">{name}</Text>
                </Center>
            ))}
            {cells.map((cell, index) => (
                <DayCell key={index} cellData={cell} selectDate={selectDate} />
            ))}
        </Grid>
    )
}
