import React, { DragEventHandler, FC } from 'react'
import { Center, Text } from '@chakra-ui/layout'
import { MenuDotsIcon } from 'icons'
import { StepMetadata } from 'types/core'
import { PaletteBox } from '../styles'

interface PaletteNodeProps {
    metadata: StepMetadata
}

export const PaletteNode: FC<PaletteNodeProps> = ({ metadata }) => {
    const onDragStart: DragEventHandler = event => {
        const serialized = JSON.stringify(metadata)
        event.dataTransfer.setData('application/reactflow', serialized)
        event.dataTransfer.effectAllowed = 'move'
    }

    return (
        <PaletteBox
            draggable
            onDragStart={onDragStart}
        >
            <Center>
                <MenuDotsIcon
                    isVertical
                    boxSize="32px"
                    color="color.940"
                />
            </Center>
            <Center>
                <Text variant="h5">{metadata.name}</Text>
            </Center>
        </PaletteBox>
    )
}
