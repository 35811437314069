import { HandleComponentProps } from 'react-flow-renderer/dist/components/Handle'
import { Position } from 'react-flow-renderer'
import { StepCategory, WorkflowStep } from 'types/core'

export const ports: HandleComponentProps[] = [
    {
        type: 'source',
        position: Position.Right,
    },
]

export const mockSourceStep: WorkflowStep = {
    id: '',
    stepMetadataId: '',
    stepMetadata: {
        id: '',
        name: 'Order Recieved',
        category: StepCategory.Source,
        description: 'All Sales Organizations',
        outputs: 1,
        parameterMetadata: [],
    },
    layout: {
        x: 0,
        y: 0,
    },
    order: 0,
    parameters: [],
    steps: [],
}
