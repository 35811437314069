import React, { FC } from 'react'
import { Tooltip } from '@chakra-ui/react'
import { ICard } from 'components/Cards'
import { stringOrDash } from 'utils/stringOrDash'
import {
    CardBox,
    BlueDetails,
    HeadingText,
    InfoBox,
    InfoText,
    CardText,
    HeaderBox,
    CrossSmallIconStyled,
    MiddleBox,
} from './styles'

interface ICardProps extends ICard {
    onDelete?: (topValue: string | number | null) => void
    middleHeader?: string
    bottomHeader?: string
}

export const Card: FC<ICardProps> = ({
    id,
    topValue,
    middleValue,
    bottomValue,
    middleHeader,
    bottomHeader,
    onDelete,
}) => {
    return (
        <CardBox>
            <HeaderBox>
                <BlueDetails>{stringOrDash(topValue)}</BlueDetails>
                {!!onDelete && (
                    <CrossSmallIconStyled onClick={() => onDelete(id)} />
                )}
            </HeaderBox>
            <MiddleBox>
                {!!middleHeader && (
                    <HeadingText>{middleHeader}</HeadingText>
                )}
                <Tooltip label={stringOrDash(middleValue)}>
                    <CardText>{stringOrDash(middleValue)}</CardText>
                </Tooltip>
            </MiddleBox>
            <InfoBox>
                {!!bottomHeader && (
                    <HeadingText>{bottomHeader}</HeadingText>
                )}
                <InfoText>{stringOrDash(bottomValue)}</InfoText>
            </InfoBox>
        </CardBox>
    )
}
