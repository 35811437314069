import { ruleType } from 'components/diagrams/nodes/NodeLayout/NodeParameter/params/MultiSelect/utils'

export const getItemName = (
    label: string,
    value: string,
    selctedRule: string
) => {
    if (label === value) {
        return label
    } else if (ruleType(selctedRule)) {
        return `${label}`
    } else {
        return `${label} (${value})`
    }
}
