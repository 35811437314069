import { Divider, ModalBody } from '@chakra-ui/react'
import { Box, Text } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/button'
import { chakra } from 'utils/chakra'

export const ModalBodyStyled = chakra(ModalBody, {
    padding: '0 15px',
    variant: 'modal',
    color: 'buttonSolidColor',
})

export const ModalCloseButton = chakra(Button, {
    variant: 'variantUnderline'
})

export const ModalConfirmButton = chakra(Button, {
    variant: 'blue',
    marginLeft: '5px',
    width: 'auto'
})

export const ModalHeaderText = chakra(Text, {
    variant: 'h3',
    textAlign: 'center',
    color: 'color.100'
})

export const ModalBodyText = chakra(Text, {
    variant: 'h3',
    textAlign: 'center',
    color: 'color.100'
})

export const TextStyled = chakra(Text, {
    fontSize: 'md',
    marginBottom: '10px',
    color: 'color.100'
})

export const DividerStyled = chakra(Divider, {
    marginTop: '20px',
    marginBottom: '20px',
    borderColor: 'divider',
})

export const TreeBox = chakra(Box, {
    maxHeight: '55vh',
    overflow: 'auto',
})
