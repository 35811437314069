import React, { FC } from 'react'
import {
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from '@chakra-ui/react'
import { tenantsStore } from 'stores'
import {
    ModalCloseButton,
    ModalConfirmButton,
    ModalHeaderText,
    ModalBodyStyled,
    ModalBodyText,
} from './style'


interface IConfirmModal {
    isOpen: boolean
    onClose: () => void
    bodyText: string
    tenantId: string
    headerText: string
    buttonText: string
}

export const DeleteTenantModal: FC<IConfirmModal> = ({
    isOpen,
    onClose,
    headerText,
    bodyText,
    tenantId,
    buttonText,
}) => {
    const onDelete = async (): Promise<void> => {
        await tenantsStore.deleteSelectedTenant(tenantId)
        onClose()
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent alignSelf="center" backgroundColor="color.900">
                <ModalHeader>
                    <ModalHeaderText>{headerText}</ModalHeaderText>
                </ModalHeader>
                <ModalBodyStyled textAlign="left">
                    <ModalBodyText>{bodyText}</ModalBodyText>
                </ModalBodyStyled>
                <ModalFooter justifyContent="right">
                    <ModalCloseButton onClick={onClose}>
                        Close
                    </ModalCloseButton>
                    <ModalConfirmButton color="white" onClick={onDelete}>
                        {buttonText}
                    </ModalConfirmButton>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
