import React, { useEffect, useMemo, useState } from 'react'
import { Button, useDisclosure } from '@chakra-ui/react'
import { Box, Flex } from '@chakra-ui/layout'
import { observer } from 'mobx-react-lite'
import { useForm } from 'react-hook-form'
import { Column } from 'react-table'
import { toJS } from 'mobx'
import ChannelsInputs from 'components/TableComponents/TableModal/ModalFormInputs/ChannelsInputs'
import EditableTable from 'components/TableComponents/Table'
import CustomModal from 'components/TableComponents/TableModal'
import { NumericPagination } from 'components/NumericPagination'
import { ConfirmModal } from 'components/Modal/ConfirmModal'
import { columnsChannel, ModalState } from 'components/TableComponents/mock'
import { IntegrationChannelTableView, ISelectOption } from 'types/core'
import { IntegrationChannelsApi } from 'api/integrationChannels'
import { StatusType } from 'types/chart'
import {
    authenticationSchemaStore,
    integrationChannelStore,
    mappingStore,
    notificationStore,
    tenantsStore,
} from 'stores'
import { errorObjTransformString } from 'utils/objectTransform'

export const AdminChannelsPage = observer(() => {
    const customToast = notificationStore.toast
    const data = toJS(integrationChannelStore.data)
    const authSchemaData = toJS(authenticationSchemaStore.data)
    const mappingData = toJS(mappingStore.mappingList)

    const { tenantId } = tenantsStore

    const [modalState, setModalState] = useState<ModalState>('' as ModalState)
    const [deleteRowIndex, setDeleteRowIndex] = useState<number>(0)

    const { isOpen, onOpen, onClose } = useDisclosure()
    const {
        isOpen: isOpenDelete,
        onOpen: onOpenDelete,
        onClose: onCloseDelete,
    } = useDisclosure()

    const {
        handleSubmit,
        register,
        control,
        setValue,
        reset,
        resetField,
        getValues,
        watch,
        formState: { errors, isSubmitting },
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
    })

    const columns: Column[] = useMemo(() => columnsChannel, [])

    useEffect(() => {
        tenantId && integrationChannelStore.fetch(tenantId)
        return () => {
            integrationChannelStore.clean()
        }
    }, [tenantId])

    const onEdit = (id: number): void => {
        setModalState(ModalState.Edit)
        reset({ ...data[id] })
        onOpen()
    }

    const updateMyData = (
        rowIndex: number,
        columnId: string,
        value: string | ISelectOption
    ): void => {
        integrationChannelStore.updateData(rowIndex, columnId, value)
    }

    const onDeleteRow = () => {
        IntegrationChannelsApi.removeIntegrationChannel(data[deleteRowIndex])
            .then(() => {
                onCloseDelete()
                integrationChannelStore.fetch(tenantId)
            })
            .catch(error => {
                customToast({
                    title: 'Error',
                    description: 'Something went wrong.',
                    status: StatusType.Error,
                })
            })
    }

    const onAdd = () => {
        reset({})
        setModalState(ModalState.Add)
        onOpen()
    }

    const openDeleteModal = (rowIndex: number) => {
        setDeleteRowIndex(rowIndex)
        onOpenDelete()
    }

    const onSubmit = (data: IntegrationChannelTableView) => {
        if (modalState === ModalState.Add) {
            IntegrationChannelsApi.createIntegrationChannel(tenantId, data)
                .then(() => {
                    integrationChannelStore.fetch(tenantId)
                    reset()
                    onClose()
                })
                .catch(error => {
                    if (error.response?.status === 400) {
                        customToast({
                            title: 'Error',
                            description: errorObjTransformString(
                                error.response.data.errors
                            ),
                            status: StatusType.Error,
                        })
                    } else if (error.response?.status === 409) {
                        customToast({
                            title: 'Error',
                            description: error.response.data,
                            status: StatusType.Error,
                        })
                    } else {
                        customToast({
                            title: 'Error',
                            description: 'Something went wrong.',
                            status: StatusType.Error,
                        })
                    }
                })
        } else {
            IntegrationChannelsApi.updateIntegrationChannel(data)
                .then(() => {
                    integrationChannelStore.fetch(tenantId)
                    reset()
                    onClose()
                })
                .catch(error => {
                    if (error.response?.status === 400) {
                        customToast({
                            title: 'Error',
                            description: errorObjTransformString(
                                error.response.data.errors
                            ),
                            status: StatusType.Error,
                        })
                    } else if (error.response?.status === 409) {
                        customToast({
                            title: 'Error',
                            description: error.response.data,
                            status: StatusType.Error,
                        })
                    } else {
                        customToast({
                            title: 'Error',
                            description: 'Something went wrong.',
                            status: StatusType.Error,
                        })
                    }
                })
        }
    }

    return (
        <div>
            <Box padding="31px 0">
                <Flex color="white" overflowY={'scroll'}>
                    <Box flex="1">
                        <EditableTable
                            data={data}
                            columns={columns}
                            updateMyData={updateMyData}
                            onEdit={onEdit}
                            openDeleteModal={openDeleteModal}
                        />
                    </Box>
                </Flex>
            </Box>
            <Button
                size="sm"
                variant="outline"
                colorScheme="blue"
                onClick={onAdd}
            >
                Add
            </Button>
            <CustomModal
                modalTitle={
                    modalState === ModalState.Add
                        ? 'Create Integration Channels'
                        : 'Edit Integration Channels'
                }
                isOpen={isOpen}
                onClose={onClose}
                onSubmit={onSubmit}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
            >
                <ChannelsInputs
                    isCreateChannels={modalState === ModalState.Add}
                    errors={errors}
                    register={register}
                    control={control}
                    setValue={setValue}
                    resetField={resetField}
                    getValues={getValues}
                    watch={watch}
                    authSchemaData={authSchemaData}
                    mappingData={mappingData}
                />
            </CustomModal>
            <NumericPagination
                marginTop="24px"
                pages={integrationChannelStore.pages}
                activePage={integrationChannelStore.filters.page}
                onChangePage={page => {
                    integrationChannelStore.setFilters({ page })
                    integrationChannelStore.fetch(tenantId)
                }}
            />
            <ConfirmModal
                isOpen={isOpenDelete}
                onClose={onCloseDelete}
                confirmFunc={onDeleteRow}
                headerText="Delete Channel"
                bodyText="Are you sure?"
                buttonText="Delete"
                colorButton="red.500"
            />
        </div>
    )
})
