import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const BellIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 17 20"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13 13.3333V6.5C13 4.01472 10.9853 2 8.5 2C6.01472 2 4 4.01472 4 6.5V13.3333C4 13.7015 4.29848 14 4.66667 14H12.3333C12.7015 14 13 13.7015 13 13.3333ZM2 13.3333C2 13.5635 2.02917 13.7869 2.08401 14H1C0.447715 14 0 14.4477 0 15C0 15.5523 0.447715 16 1 16H4.66667H12.3333H16C16.5523 16 17 15.5523 17 15C17 14.4477 16.5523 14 16 14H14.916C14.9708 13.7869 15 13.5635 15 13.3333V6.5C15 2.91015 12.0899 0 8.5 0C4.91015 0 2 2.91015 2 6.5V13.3333ZM7.25 17.5C6.55964 17.5 6 18.0596 6 18.75C6 19.4404 6.55964 20 7.25 20H9.75C10.4404 20 11 19.4404 11 18.75C11 18.0596 10.4404 17.5 9.75 17.5H7.25Z"
                fill="currentColor"
            />
        </Icon>
    )
}
