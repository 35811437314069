import React from 'react'
import { truncate } from 'lodash'
import { LabelRow } from './styles'

export const renderLabelRow = (values: string[], cutLength: number) =>
    values.map(value => (
        <LabelRow key={value}>
            <span>{truncate(value, { length: cutLength })}</span>
        </LabelRow>
    ))
