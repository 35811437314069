import { Flex } from '@chakra-ui/layout'
import { chakra } from 'utils/chakra'

export const WidgetLayoutBox = chakra(Flex, {
    direction: 'column',
    padding: '30px 20px',
    borderRadius: '16px',
    backgroundColor: 'color.900',
    boxShadow: '0px 6px 26px rgba(16, 30, 115, 0.06)',
})

export const TitleBox = chakra(Flex, {
    height: '28px',
    alignItems: 'center',
})
