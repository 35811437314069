import React, { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { addMinutes, format, subMonths } from 'date-fns'
import {
    AspectRatio,
    Box,
    BoxProps,
    Button,
    Center,
    IconButton,
    SimpleGrid,
    Stack,
    useDisclosure,
} from '@chakra-ui/react'
import { NumericPagination } from 'components/NumericPagination'
import { PreviewDialogModal } from 'components/diagrams/PreviewDialogModal'
import { MyRuleCard } from './MyRuleCard'
import { Filters } from './Filters'
import { WidgetLayout } from '../../layout/WidgetLayout'
import { ArrowRightIcon, CrossSmallIcon, MenuDotsIcon, PlusIcon } from 'icons'
import { routes } from 'navigation/routes'
import { dashboardStore, notificationStore, userStore } from 'stores'
import { dateFormat } from 'pages/HomePage/mock'
import { UUID } from 'types/core'
import { StatusType } from 'types/chart'

export const MyRules: FC<BoxProps> = observer(props => {
    const timeZoneOffset = new Date().getTimezoneOffset()
    const date = addMinutes(subMonths(new Date(), 1), timeZoneOffset)
    const defaultDateFrom = format(date, dateFormat)
    const [timeScopeFrom, setTimeScopeFrom] = useState(defaultDateFrom)
    const [statuses, setStatuses] = useState('')
    const [searchValue, setSearchValue] = useState('')
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [viewedWorkflowId, setViewedWorkflowId] = useState<string>('')
    const navigate = useNavigate()

    const { isCreator } = toJS(userStore.currentRoles)
    const workflows = dashboardStore.myRulesStore.workflows

    const getViewedWorkflowName = () => {
        const findViewedWorkflow = workflows.find(({ id }) => id === viewedWorkflowId)
        return findViewedWorkflow ? findViewedWorkflow.name : ''
    }

    const onPrioritize = (): void => navigate(routes.prioritize)

    const onAddNew = (): void => navigate(routes.createNewRule)

    const onDelete = async (workflowId: UUID, workflowName: string): Promise<void> => {
        try {
            await dashboardStore.myRulesStore.deleteWorkflowById(workflowId)
            await dashboardStore.approvalsStore.fetch()

            notificationStore.toast({
                title: 'Success',
                description: `Successfully delete workflow "${workflowName}"`,
                status: StatusType.Success,
            })
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    const onView = (workflowId: UUID): void => {
        setViewedWorkflowId(workflowId)
        onOpen()
    }

    const onChange = (workflowId: UUID): void => {
        navigate(`${routes.changeRule}/${workflowId}`)
    }

    const onEdit = (workflowId: UUID): void => {
        navigate(`${routes.editor}/${workflowId}`)
    }

    useEffect(() => {
        const timeScopeTo = format(
            addMinutes(new Date(), timeZoneOffset),
            dateFormat
        )

        dashboardStore.myRulesStore.setFilters({
            utcModifiedFrom: timeScopeFrom,
            utcModifiedTo: timeScopeTo,
            statuses,
            filter: searchValue,
            size: 6,
        })

        return () => {
            dashboardStore.myRulesStore.clean()
        }
    }, [timeScopeFrom, timeZoneOffset, statuses, searchValue])

    return (
        <WidgetLayout
            title="My Rules"
            widgetBox={props}
            actions={
                <Stack direction="row" height="20px" spacing="0">
                    <Center marginRight="10px">
                        <Button
                            size="sm"
                            variant="blueSmall"
                            leftIcon={<PlusIcon boxSize="8px" />}
                            onClick={onAddNew}
                            disabled={!isCreator}
                        >
                            Create New Rule
                        </Button>
                    </Center>
                    <Center>
                        <Button
                            size="sm"
                            variant="outlineSmall"
                            rightIcon={
                                <ArrowRightIcon
                                    boxSize="8px"
                                    color="blue.200"
                                    margin="0 3px"
                                />
                            }
                            onClick={onPrioritize}
                        >
                            Go to Rules Dashboard
                        </Button>
                    </Center>
                    <Center paddingLeft="18px">
                        <IconButton
                            aria-label="Menu Dots Icon"
                            variant="hoveredSmallWhite"
                            icon={<MenuDotsIcon padding="5px" boxSize="18px" />}
                        />
                    </Center>
                    <Center paddingLeft="3px">
                        <IconButton
                            aria-label="Cross Small Icon"
                            variant="hoveredSmallWhite"
                            icon={
                                <CrossSmallIcon padding="5px" boxSize="18px" />
                            }
                        />
                    </Center>
                </Stack>
            }
        >
            <Box flex="1 0 auto">
                <Filters
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    setTimeScopeFrom={setTimeScopeFrom}
                    setStatuses={setStatuses}
                />
                <SimpleGrid columns={3} gap="8px">
                    {workflows.map(workflow => (
                        <AspectRatio key={workflow.id} ratio={16 / 9}>
                            <MyRuleCard
                                workflow={workflow}
                                onEdit={() => onEdit(workflow.id)}
                                onChange={() => onChange(workflow.id)}
                                onView={() => onView(workflow.id)}
                                onDelete={() => onDelete(workflow.id, workflow.name)}
                            />
                        </AspectRatio>
                    ))}
                </SimpleGrid>
            </Box>
            <NumericPagination
                marginTop="24px"
                pages={dashboardStore.myRulesStore.pages}
                activePage={dashboardStore.myRulesStore.filters.page}
                paginationItems={5}
                onChangePage={page =>
                    dashboardStore.myRulesStore.setFilters({ page })
                }
            />
            <PreviewDialogModal
                isOpen={isOpen}
                onClose={onClose}
                viewId={viewedWorkflowId}
                title={getViewedWorkflowName()}
            />
        </WidgetLayout>
    )
})
