import { FC, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Checkbox } from '@chakra-ui/react'
import { orderStore } from 'stores'
import { errorStatuses } from './mock'

interface ICheckboxProps {
    setRetryOrders: React.Dispatch<string[]>
}

export const RetryParentCheckbox: FC<ICheckboxProps> = observer(({
    setRetryOrders
}) => {
    const [ isChecked, updateChecked ] = useState(false)

    const checkAll = async () => {

        const retryOrders = await orderStore.searchErrors(errorStatuses)
        setRetryOrders(retryOrders.map((order) => order.id))      
        orderStore.checkCountErrorStatus(retryOrders.length)
    }
    
    const uncheckAll = () => {
        setRetryOrders([])
        orderStore.checkCountErrorStatus(0)
    }

    const handleChange = () => {
        if(!isChecked) {
            updateChecked(true)
            checkAll()
        }
        else {
            updateChecked(false)
            uncheckAll()
        }
    }

    return (
        <Checkbox
            isChecked={isChecked}
            onChange={handleChange}
        >
        </Checkbox>
    )
})