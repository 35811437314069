import EChartsReact from 'echarts-for-react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { kpiStore } from 'stores'
import { useColorMode } from '@chakra-ui/react'
import { ChartBox } from './styles'
import { getChartOption } from './utils'



export const Chart = observer(()=>{

    const shipperData = toJS(kpiStore.shipperData)
    const chartHeight = 220 + Math.ceil(shipperData.shippers.length / 3) * 30
    const { colorMode } = useColorMode()


    return (
        <ChartBox>
            <EChartsReact
                option={getChartOption(shipperData.totalOrders, shipperData.shippers, colorMode)}
                style={{ height: chartHeight }}
            />
        </ChartBox>
    )
})