import React from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { Divider } from '@chakra-ui/layout'
import { routes } from 'navigation/routes'
import { useGetPath, useLocalStorage } from 'hooks'
import { JWTPayload } from 'types/api'
import { onLogOut } from 'utils/onLogOut'
import { getPayload, isAdminUser, isSupportUser } from 'utils/jwt'
import {
    sidebarAdminBottomListIcons,
    sidebarAdminBottomListIconsWithAdminBtn,
    sidebarAdminTopListIcons,
    sidebarBottomListIcons,
    sidebarBottomListIconsWithAdminBtn,
    sidebarSupportBottomListIcons,
    sidebarSupportTopListIcons,
    sidebarTopListIcons,
} from './mock'
import { SidebarItem } from './SidebarItem'
import { sizeStore } from 'stores'
import { SidebarBox, ItemsBox} from './styles'

export const Sidebar = observer(() => {
    const [isOpen, setIsOpen] = useLocalStorage('isOpenPanel', false)
    const token = localStorage.getItem('authToken')
    const navigate = useNavigate()
    const { baseUrl } = useGetPath()

    const pageHeight = sizeStore.pageHeight

    const isAdmin = () => {
        if (token) {
            const payload: JWTPayload = getPayload(token)
            return isAdminUser(payload.role)
        }
        return false
    }

    const isSupport = () => {
        if (token) {
            const payload: JWTPayload = getPayload(token)
            return isSupportUser(payload.role)
        }
        return false
    }

    const isAdminIcons = isAdmin() ? sidebarAdminTopListIcons : sidebarSupportTopListIcons
    const sidebarTopIcons = baseUrl === routes.admin ? isAdminIcons : sidebarTopListIcons

    const onPrioritize = () => navigate(routes.prioritize)
    const onDashboard = () => navigate(routes.home)
    const onAddNew = () => navigate(routes.createNewRule)
    const onUsers = () => navigate(routes.users)
    const onTenants = () => navigate(routes.tenants)
    const onChannels = () => navigate(routes.channels)
    const onResourceOwnersAuth = () => navigate(routes.resourceOwnersAuth)
    const onAuthApiKey = () => navigate(routes.authApiKey)
    const onAuthNoAuth = () => navigate(routes.authNoAuth)
    const onGateway = () => navigate(routes.gateway)
    const onFeatureFlags = () => navigate(routes.featureFlags)
    const onRulesSimulate = () => navigate(routes.ruleSimulate)
    const onReports = () => navigate(routes.reports)
    const onAttributeConfiguration = () => navigate(routes.attributeConfiguration)

    const checkAdminBottomSidebar = () => {
        if (baseUrl === routes.admin) {
            return isAdmin() || isSupport()
                ? sidebarAdminBottomListIconsWithAdminBtn
                : sidebarAdminBottomListIcons
        } else {
            return isAdmin()
                ? sidebarBottomListIconsWithAdminBtn
                : isSupport()
                ? sidebarSupportBottomListIcons
                : sidebarBottomListIcons
        }
    }

    const sidebarBottomIcons = checkAdminBottomSidebar()
    const allIcons = sidebarTopIcons.length + sidebarBottomIcons.length
    const maxHeightByIcons = `${allIcons * 60}px`
    const minHeightByIcons = `${allIcons * 39}px`

    const handleClick = (name: string) => {
        switch (name) {
            case 'Dashboard':
                return onDashboard
            case 'Rule Prioritization':
                return onPrioritize
            case 'Create new rule':
                return onAddNew
            case 'Log Out':
                return onLogOut
            case 'Users':
                return onUsers
            case 'Tenants':
                return onTenants
            case 'Channels':
                return onChannels
            case 'Resource Owners Auth':
                return onResourceOwnersAuth
            case 'Auth No Auth':
                return onAuthNoAuth
            case 'Auth Api Key':
                return onAuthApiKey
            case 'Gateway':
                return onGateway
            case 'Feature Flags':
                return onFeatureFlags
            case 'User Pages':
                return onDashboard
            case 'Admin Pages':
                return onTenants
            case 'Rules Simulate':
                return onRulesSimulate
            case 'Reports':
                return onReports
            case 'Attribute Configuration':
                return onAttributeConfiguration
            default:
                return () => {}
        }
    }

    return (
        <>
            <SidebarBox
                style={{
                    maxWidth: isOpen ? '275px' : '85px',
                    minWidth: isOpen ? '270px' : '80px',
                }}
            >
                <ItemsBox
                    maxHeight={maxHeightByIcons}
                    minHeight={minHeightByIcons}
                >
                    {sidebarTopIcons.map(({ id, name, icon }) => (
                        <SidebarItem
                            key={id}
                            id={id}
                            name={name}
                            icon={icon}
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            handleClick={handleClick}
                            pageHeight={pageHeight}
                        />
                    ))}
                    <Divider
                        width={isOpen ? '210px' : '60px'}
                        margin={pageHeight < 580 ? '0' : '20px 0'}
                    />
                    {sidebarBottomIcons.map(({ id, name, icon }) => (
                        <SidebarItem
                            key={id}
                            id={id}
                            name={name}
                            icon={icon}
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            handleClick={handleClick}
                            pageHeight={pageHeight}
                        />
                    ))}
                </ItemsBox>
            </SidebarBox>
        </>
    )
})
