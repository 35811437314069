import React, { useEffect, useMemo, useState } from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { mappingStore, tenantsStore } from 'stores'
import { useDisclosure } from '@chakra-ui/react'
import { Box, Flex } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/react'
import { columnsMapping } from 'components/TableComponents/mock'
import { NumericPagination } from 'components/NumericPagination'
import EditableTable from 'components/TableComponents/Table'
import { MappingJson } from './Mapping'

const AdminMappingPage = observer(() => {
    const data = toJS(mappingStore.mappingList)
    const { tenantId } = tenantsStore
    const [mappingId, setMappingId] = useState('')
    const { isOpen, onOpen, onClose } = useDisclosure()
    const columns = useMemo(() => columnsMapping, [])

    useEffect(() => {
        if (!isOpen && tenantId) {
            mappingStore.fetch(tenantId).then(() => {})
            onClose()
        }
        // Need for improvement. Warnings console
        /* eslint-disable */
    }, [tenantId, isOpen])

    const onCloseMappingComponent = () => {
        setMappingId('')
        onClose()
    }

    const onSaveId = (id: string) => setMappingId(id)

    const onDeleteMapping = (mappingId: string) => {
        mappingStore.deleteMapping(tenantId, mappingId).finally(() => {
            isOpen ? onClose() : mappingStore.fetch(tenantId)
        })
    }

    return (
        <div>
            {isOpen ? (
                <MappingJson
                    mappingId={mappingId}
                    onCloseMappingComponent={onCloseMappingComponent}
                    onDeleteMapping={onDeleteMapping}
                />
            ) : (
                <>
                    <Box padding="31px 0">
                        <Flex color="white" overflowY="scroll">
                            <Box flex="1">
                                <EditableTable
                                    data={data}
                                    columns={columns}
                                    onOpen={onOpen}
                                    onSaveData={onSaveId}
                                    onDelete={onDeleteMapping}
                                />
                            </Box>
                        </Flex>
                    </Box>
                    <Button
                        size="sm"
                        variant="outline"
                        colorScheme="blue"
                        onClick={onOpen}
                    >
                        Add
                    </Button>
                    <NumericPagination
                        marginTop="24px"
                        pages={mappingStore.pages}
                        activePage={mappingStore.filters.page}
                        onChangePage={page => {
                            mappingStore.setFilters({ page })
                            mappingStore.fetch(tenantId)
                        }}
                    />
                </>
            )}
        </div>
    )
})

export default AdminMappingPage
