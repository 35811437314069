import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const CategorySplitBlockIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_284_1391)">
                <path
                    d="M14 11.3333H10.402C9.86605 11.3333 9.33802 11.204 8.86266 10.9565C8.3873 10.7089 7.97865 10.3504 7.67133 9.91133L7.32867 9.422C7.02135 8.98291 6.6127 8.6244 6.13734 8.37685C5.66198 8.12931 5.13395 8.00004 4.598 8H2"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14 4.6665H10.4033C9.86704 4.66648 9.33866 4.79585 8.86305 5.04364C8.38743 5.29143 7.97863 5.65032 7.67133 6.08984L7.33133 6.5765C7.0241 7.01593 6.6154 7.37476 6.13991 7.62255C5.66442 7.87033 5.13618 7.99975 4.6 7.99984H2"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12 6.6665L14 4.6665L12 2.6665"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12 13.3335L14 11.3335L12 9.3335"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_284_1391">
                    <rect width="16" height="13" fill="white" />
                </clipPath>
            </defs>
        </Icon>
    )
}
