import { Box, Flex, Text, Link } from '@chakra-ui/layout'
import { Center, ModalBody } from '@chakra-ui/react'
import { Accordion } from '@chakra-ui/accordion'
import { Button } from '@chakra-ui/button'
import { chakra } from 'utils/chakra'

export const BackButtonBox = chakra(Flex, {
    marginTop: '10px'
})

export const BackButtonLink = chakra(Link, {
    display: 'flex',
    alignItems: 'center',
})

export const BackButtonText = chakra(Text, {
    variant: 'h5',
    color: 'blue.300',
    marginLeft: '4px',
})

export const AuditTrailBox = chakra(Box, {
    padding: '16px',
    borderRadius: '6px',
    backgroundColor: 'color.900',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)',
})

export const RetriesHistoryBox = chakra(Box, {
    padding: '16px',
    borderRadius: '6px',
    backgroundColor: 'color.900',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)',
})

export const OrderInfoText = chakra(Text, {
    color: 'color.100',
    marginBottom: '6px',
    variant: 'paragraphSmall400',
})

export const OrderInfoBox = chakra(Flex, {
    justifyContent: 'space-between',
    margin: '43px 0 36px 0',
})

export const OrderDetailsPageBox = chakra(Box, {
    margin: '24px 45px',
})

export const OrderIdText = chakra(Text, {
    variant: 'h1',
})

export const StatusBox = chakra(Center, {
    minHeight: '22px',
    minWidth: '138px',
    margin: '0 30px',
})

export const OrderPlacedBox = chakra(Box, {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
})

export const AccordionInfoBox = chakra(Box, {
    display: 'flex',
    alignItems: 'center',
    cursor: 'auto',
    width: '100%',
    height: '40px',
    padding: '8px 20px 8px 15px',
    border: '1px solid',
    borderRadius: '6px',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)',
    backgroundColor: 'blueWhite.950',
    margin: '8px 0',
})

export const AccordionButtonBox = chakra(Box, {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    cursor: 'auto',
    width: '100%',
    height: '40px',
    padding: '8px 20px 8px 15px',
    backgroundColor: 'blueWhite.950',
    borderRadius: '6px',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)',
})

export const AccordionButtonItem = chakra(Text, {
    textAlign: 'left',
    flexBasis: 1,
})

export const AccordionButtonItemBox = chakra(Box, {
    textAlign: 'left',
    flexBasis: 1,
})

export const RectangleIconBox = chakra(Box, {
    position: 'absolute',
    left: '0.5px',
})

export const AccordionPanelBox = chakra(Box, {
    padding: '14px 0 14px 14px',
    backgroundColor: 'color.900',
})

export const RulesEnginePanelBox = chakra(Box, {
    width: '100%',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    padding: '15px 20px 0 15px',
})

export const RulesEnginePanelItem = chakra(Box, {
    alignSelf: 'start',
    textAlign: 'left',
    flexBasis: 1,
})

export const OrderShippedPanelBox = chakra(Box, {
    width: '100%',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    padding: '25px 10px 5px 10px',
})

export const OrderShippedPanelItem = chakra(Text, {
    textAlign: 'left',
    flexBasis: 1,
    margin: '20px 0',
})

export const BlueDetails = chakra(Text, {
    variant: 'h6',
    color: 'blue.300',
    marginRight: '8px',
    cursor: 'pointer',
})

export const HeadingText = chakra(Text, {
    variant: 'paragraphsmall',
    color: 'gray.400',
})

export const InfoText = chakra(Text, {
    variant: 'paragraph',
    margin: '4px 0',
})

export const BlockName = chakra(Text, {
    alignSelf: 'center',
    variant: 'h5',
    marginLeft: '6px',
})

export const AccordionStyled = chakra(Accordion, {
    margin: '8px 0',
    variant: 'unstyled',
})

export const CollapseAllButton = chakra(Button, {
    variant: 'outlineWhite',
})

export const AuditTrailHeader = chakra(Flex, {
    justifyContent: 'space-between',
    height: '28px',
})

export const RetriesHistoryHeader = chakra(Flex, {
    justifyContent: 'space-between',
    height: '28px',
})

export const AccordionRetrieHeaderBox = chakra(Box, {
    height: 'fit-content',
    backgroundColor: 'blueWhite.990',
})

export const AccordionRetrieHeaderItem = chakra(Text, {
    textAlign: 'left',
    flexBasis: 1,
    variant: 'paragraphsmall',
})

export const AccordionHeaderBox = chakra(Box, {
    width: '100%',
    height: '28px',
    backgroundColor: 'blueWhite.990',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'auto',
    padding: '8px 20px 8px 15px',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)',
})

export const AccordionHeaderItem = chakra(Text, {
    textAlign: 'left',
    flexBasis: 1,
    variant: 'paragraphsmall',
})

export const AccordionInfoItem = chakra(Text, {
    textAlign: 'left',
    flexBasis: 1,
})

export const NoData = chakra(Text, {
    variant: 'h2',
    textAlign: 'center',
})

export const ModalBodyStyled = chakra(ModalBody, {
    width: '90%',
    padding: '8px',
    alignSelf: 'center',
    backgroundColor: 'gray.900',
})

export const ModalCloseButton = chakra(Button, {
    variant: 'modal',
    color: 'white',
})

export const ModalHeaderText = chakra(Text, {
    variant: 'h3',
    textAlign: 'center',
})

export const ValueText = chakra(Text, {
    variant: 'paragraph',
    marginBottom: '16px',
})
