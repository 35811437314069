import { Box, Flex, Text } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/react'
import { SimpleArrowUpIcon, SimpleArrowDownIcon } from 'icons'
import { chakra } from 'utils/chakra'

export const HeaderBox = chakra(Box, {
    width: '99%',
    height: '28px',
    backgroundColor: 'ruleVersioningHeader',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.25)',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'default',
    padding: '8px 10px 8px 10px',
})

export const HeaderItem = chakra(Text, {
    textAlign: 'left',
    flexBasis: 1,
    variant: 'paragraphsmall',
})

export const ItemBox = chakra(Flex, {
    width: '99%',
    minHeight: '40px',
    backgroundColor: 'ruleVersioningHeader',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.25)',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '8px 10px 8px 10px',
    margin: '8px 0',
})

export const ItemCell = chakra(Flex, {
    alignSelf: 'center',
    alignItems: 'flex-start',
    textAlign: 'start',
    wordBreak: 'break-all',
    paddingRight: '5px',
})

export const InfoDisplayBox = chakra(Flex, {
    flexDirection: 'column',
})

export const InfoHeaderBox = chakra(Flex, {
    justifyContent: 'space-between',
    padding: '0 10px',
})

export const ActionBox = chakra(Flex, {
    cursor: 'pointer',
})

export const InfoItemsBox = chakra(Flex, {
    padding: '0 10px',
    marginTop: '6px',
    marginBottom: '16px',
})

export const InfoItemStyled = chakra(Flex, {
    flexDirection: 'column',
    alignItems: 'start',
})

export const InfoName = chakra(Text, {
    variant: 'paragraphsmall',
    color: 'infoText',
})

export const InfoValue = chakra(Text, {
    variant: 'linkBold',
    textTransform: 'capitalize',
    textAlign: 'start',
    padding: '2px 20px 0 7px',
})

export const ArrowUpIcon = chakra(SimpleArrowUpIcon, {
    boxSize: '15px',
    color: 'blue.300',
    margin: '9px 0 0 5px',
    _hover: {
        color: 'color.50',
    },
})

export const ArrowDownIcon = chakra(SimpleArrowDownIcon, {
    boxSize: '15px',
    color: 'color.100',
    _hover: {
        color: 'color.50',
    },
})

export const FiltersBox = chakra(Flex, {
    justifyContent: 'space-between',
})

export const ActionItemBox = chakra(Flex, {
    _hover: {
        color: 'color.475',
    }
})

export const AssignedBox = chakra(Flex, {
    alignSelf: 'center',
    marginRight: '25px',
})

export const StatusText = chakra(Text, {
    variant: 'h5',
    marginLeft: '10px',
})

export const ClearButtonBox = chakra(Flex, {
    justifyContent: 'flex-end',
    marginBottom: '20px',
    padding: '0 10px',
})

export const NoItemsBox = chakra(Flex, {
    marginTop: '15px',
    justifyContent: 'center',
})

export const ViewText = chakra(Text, {
    variant: 'h4LineHeight',
    alignSelf: 'flex-start',
    _hover: {
        color: 'color.475',
    },
})

export const ActionInputsBox = chakra(Flex, {
    flexDirection: 'column',
    alignSelf: 'center',
})

export const InputTitle = chakra(Text, {
    variant: 'supertitle',
    marginTop: '10px',
    alignSelf: 'flex-start',
})

export const ActionsMenuBox = chakra(Flex, {
    flexDirection: 'column',
    padding: '24px',
    position: 'absolute',
    width: '220px',
    minHeight: '150px',
    maxHeight: '350px',
    color: 'color.100',
    backgroundColor: 'blueWhite.900',
    boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.45)',
    borderRadius: '6px',
    top: '111px',
    right: '1px',
    zIndex: '1',
})

export const StyledButton = chakra(Button, {
    variant: 'blueFixed',
    width: 'inherit',
    padding: '12px 16px',
    fontSize: '14px',
    fontWeight: 700,
    borderRadius: '6px',
})
