import React, { FC } from 'react'
import { BoxProps, Flex, Spacer } from '@chakra-ui/layout'

export const GapLayout: FC<BoxProps> = ({ children, ...props }) => {
    return (
        <Flex direction="column" height="100%" {...props}>
            {children}
        </Flex>
    )
}

interface GapProps {
    size: string
}

export const Gap: FC<GapProps> = ({ size }) => {
    return <Spacer flexShrink={0} flexBasis={size} />
}
