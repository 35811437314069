import { Input, InputGroup, InputLeftElement } from '@chakra-ui/input'
import { Box, Center, Divider, Text } from '@chakra-ui/layout'
import { CrossSmallIcon, SearchIcon } from 'icons'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { stepsMetadataStore } from 'stores'
import { StepMetadata } from 'types/core'
import { groupByCategory } from 'utils/operations'
import { Palette } from '../Palette'
import { AccordionContent } from './AccordionContent'
import { categoriesIcons } from 'utils/categoriesIcons'
import {
    AccordionStyled,
    ClearSearchButton,
    GridStyled,
    ResultsForText,
} from '../../styles'

interface GroupedMetadata {
    [category: string]: StepMetadata[]
}

function searchByCategories(
    groupedMetadatas: GroupedMetadata,
    searchPattern: string
): GroupedMetadata {
    const entries = Object.entries(groupedMetadatas)
    const regexp = new RegExp(searchPattern, 'i')

    return entries.reduce<GroupedMetadata>((result, [category, metadatas]) => {
        result[category] = metadatas.filter(
            value => value.name.search(regexp) !== -1
        )

        return result
    }, {})
}

export const AddBlocks = observer(() => {
    const [search, setSearch] = useState<string>('')

    const metadata = toJS(stepsMetadataStore.metadatas) || []
    const groupedMetadata = groupByCategory(metadata)
    const isSearching = search !== ''
    const searchedMetadata: GroupedMetadata = isSearching
        ? searchByCategories(groupedMetadata, search)
        : groupedMetadata

    return (
        <GridStyled>
            <Box padding="8px 8px 0px 8px">
                <Text marginBottom="15px" variant="h4">
                    Add Blocks
                </Text>
                <Divider marginBottom="16px" />
                <InputGroup>
                    <InputLeftElement
                        pointerEvents="none"
                        children={
                            <Center marginTop="-8px">
                                <SearchIcon boxSize="21px" />
                            </Center>
                        }
                    />
                    <Input
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        placeholder="Search blocks..."
                        variant="searchBlocks"
                    />
                </InputGroup>

                {isSearching ? (
                    <Box>
                        <ResultsForText>
                            Results for "{search}"
                        </ResultsForText>
                        <Center>
                            <ClearSearchButton
                                rightIcon={
                                    <CrossSmallIcon
                                        boxSize="10px"
                                        color="color.100"
                                    />
                                }
                                onClick={() => setSearch('')}
                            >
                                Clear Search
                            </ClearSearchButton>
                        </Center>
                    </Box>
                ) : null}
            </Box>
            <AccordionStyled allowToggle>
                {Object.entries(searchedMetadata).map(([category, mtdt]) => (
                    <AccordionContent
                        key={category}
                        icon={categoriesIcons[category]}
                        title={
                            isSearching
                                ? `${category} (${mtdt.length})`
                                : category
                        }
                    >
                        <Box margin="8px 0px">
                            <Palette metadatas={mtdt} />
                        </Box>
                    </AccordionContent>
                ))}
            </AccordionStyled>
        </GridStyled>
    )
})
