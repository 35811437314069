import { pickBy } from 'lodash'

interface IObj {
    [key: string]: any
}

type IValueOf<Obj> = Obj[keyof Obj]
type IReturnObj = IValueOf<IObj>

export const deleteEmptyObjectValues = (obj: IObj): IReturnObj => {
    return pickBy(obj, (val) => {
        if (typeof val === 'string') return !!val
        return true
    })
}
