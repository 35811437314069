import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const PaginationArrowRightIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 7 12"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.36625 11.7651C0.680657 12.0783 1.18587 12.0783 1.49908 11.7651L6.8128 6.45101C7.0624 6.20165 7.0624 5.79868 6.8128 5.54931L1.49908 0.234995C1.18587 -0.078332 0.680658 -0.078332 0.366251 0.234995C0.0530438 0.548442 0.0530438 1.05365 0.366251 1.36698L4.99715 6.00341L0.36025 10.6395C0.0530434 10.9467 0.0530433 11.4579 0.36625 11.7651Z"
                fill="currentColor"
            />
        </Icon>
    )
}
