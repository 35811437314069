import { colors } from 'theme/colors'

export type IconStatus =
    | 'success'
    | 'warning'
    | 'error'
    | 'split'
    | 'orderSplitError'
    | 'cancelFailed'
    | 'cancelRequested'
    | 'cancelCompleted'

export const statusColor: Record<IconStatus, string> = {
    success: colors.green[100],
    warning: colors.yellow[200],
    error: colors.red[600],
    cancelCompleted: colors.green[100],
    cancelRequested: colors.yellow[200],
    cancelFailed: colors.red[600],
    split: colors.bluebell[100],
    orderSplitError: colors.red[600],
}
