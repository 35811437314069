import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const CrossSmallIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 10 10"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.975541 0.975541C0.741226 1.20986 0.741226 1.58975 0.975541 1.82407L4.15128 4.9998L0.975541 8.17554C0.741226 8.40986 0.741226 8.78975 0.975541 9.02407C1.20986 9.25838 1.58975 9.25838 1.82407 9.02407L4.9998 5.84833L8.17554 9.02407C8.40986 9.25838 8.78975 9.25838 9.02407 9.02407C9.25838 8.78975 9.25838 8.40986 9.02407 8.17554L5.84833 4.9998L9.02407 1.82407C9.25838 1.58975 9.25838 1.20986 9.02407 0.975541C8.78975 0.741226 8.40986 0.741226 8.17554 0.975541L4.9998 4.15128L1.82407 0.975541C1.58975 0.741226 1.20986 0.741226 0.975541 0.975541Z"
                fill="currentColor"
            />
        </Icon>
    )
}
