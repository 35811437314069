import { makeAutoObservable } from 'mobx'
import { WorkflowsStore } from 'stores/WorkflowsStore'
import { ApprovalsStore } from 'stores/DashboardStore/ApprovalsStore'

export class DashboardStore {
    public notification: string | null = null
    public myRulesStore: WorkflowsStore
    public approvalsStore: ApprovalsStore

    constructor() {
        this.myRulesStore = new WorkflowsStore()
        this.approvalsStore = new ApprovalsStore()

        makeAutoObservable(this)
    }

    public notify(message: string) {
        this.notification = message
    }

    public clearNotification() {
        this.notification = null
    }
}
