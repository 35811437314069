import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const ArrowRightIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 8 8"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M6.086 3.50033L3.404 0.818328L4.111 0.111328L8 4.00033L4.111 7.88933L3.404 7.18233L6.086 4.50033H0V3.50033H6.086Z"
                fill="currentColor"
            />
        </Icon>
    )
}
