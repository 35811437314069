import React, { FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import {
    BoxProps,
    Center,
    Divider,
    Flex,
    IconButton,
    Spacer,
} from '@chakra-ui/react'
import { NumericPagination } from 'components/NumericPagination'
import { MultiSelectContainer as MultiSelect } from '../MyRules/MultiSelect'
import { RuleApprovalsCard } from './content/RuleApprovalsCard'
import { dashboardStore } from 'stores'
import { CrossSmallIcon, MenuDotsIcon } from 'icons'
import { RuleApprovalsBox, RuleApprovalsCardBox, TitleRuleApprovals } from './styles'

const approvalsStore = dashboardStore.approvalsStore

export const RuleApprovals: FC<BoxProps> = observer(props => {
    const page = approvalsStore.workflowsStore.filters.page

    useEffect(() => {
        approvalsStore.workflowsStore.setFilters({
            size: 2,
        })

        return () => {
            approvalsStore.clean()
        }
    }, [])

    return (
        <RuleApprovalsBox {...props}>
            <Flex marginBottom="14px">
                <TitleRuleApprovals>
                    Rule Approvals
                </TitleRuleApprovals>
                <Spacer />
                <Center>
                    <IconButton
                        aria-label="Menu Dots Icon"
                        variant="hoveredSmallWhite"
                        icon={<MenuDotsIcon
                            padding="5px"
                            boxSize="18px"
                        />}
                    />
                </Center>
                <Center paddingLeft="3px">
                    <IconButton
                        aria-label="Cross Small Icon"
                        variant="hoveredSmallWhite"
                        icon={<CrossSmallIcon
                            padding="5px"
                            boxSize="18px"
                        />}
                    />
                </Center>
            </Flex>
            <Divider borderColor="blueWhite.25" marginBottom="32px" />
            <Flex marginBottom="16px">
                <MultiSelect
                    onChange={statuses => {
                        approvalsStore.workflowsStore.setFilters({ statuses })
                    }}
                />
            </Flex>
            <RuleApprovalsCardBox>
                {approvalsStore.approvals.map((workflow, index) => (
                    <RuleApprovalsCard
                        key={workflow.id}
                        workflow={workflow}
                        marginTop={index ? '25px' : '0'}
                    />
                ))}
            </RuleApprovalsCardBox>
            <NumericPagination
                marginTop="auto"
                pages={approvalsStore.workflowsStore.pages}
                activePage={page}
                paginationItems={5}
                onChangePage={page =>
                    approvalsStore.workflowsStore.setFilters({ page })
                }
            />
        </RuleApprovalsBox>
    )
})
