import React, { FC } from 'react'
import { useColorMode } from '@chakra-ui/react'
import Icon, { IconProps } from '@chakra-ui/icon'
import { Theme } from 'types/core'

export const SplitIcon: FC<IconProps> = props => {
    const { colorMode } = useColorMode()

    return (
        <Icon
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.2359 1.25199C16.2359 2.19904 16.2165 2.50942 16.1564 2.52628C16.1126 2.53846 15.8148 2.59558 15.4946 2.65314C14.2672 2.87385 12.7795 3.48981 11.6517 4.24423C10.6587 4.90846 9.50815 6.03616 8.90717 6.93417L8.74965 7.16956L8.37011 6.64802C7.89385 5.99346 6.92581 5.01878 6.26625 4.52981C4.60986 3.30173 2.74572 2.61955 0.620786 2.46385L0 2.41833V3.6725V4.92667L0.366105 4.96404C2.66862 5.19891 4.20307 5.88462 5.60299 7.30443C6.29363 8.00475 6.76339 8.66077 7.13955 9.45007L7.40079 9.99815L7.16674 10.4943C6.41358 12.0911 4.97418 13.5397 3.42432 14.2606C2.49632 14.6923 1.66199 14.9038 0.366105 15.036L0 15.0733V16.3187V17.5641H0.207311C0.321344 17.5641 0.772576 17.5219 1.21012 17.4704C3.17193 17.2392 4.8776 16.5422 6.39887 15.35C8.00362 14.0925 9.09869 12.5565 9.90775 10.4285C10.4105 9.10629 10.9227 8.29404 11.84 7.36475C13.0133 6.17597 14.4796 5.37776 15.9972 5.10173L16.2359 5.05827V6.24712C16.2359 6.90097 16.2475 7.4359 16.2616 7.4359C16.2757 7.4359 17.1226 6.59404 18.1436 5.56513L20 3.6943L18.1662 1.84718C17.1575 0.831219 16.3106 0 16.2841 0C16.2576 0 16.2359 0.563398 16.2359 1.25199ZM10.4756 11.2016C10.4057 11.4819 9.76334 12.8063 9.51331 13.1855C9.2876 13.5279 9.28632 13.5335 9.39749 13.7049C9.61607 14.0421 10.8091 15.1661 11.4015 15.593C12.541 16.4144 14.1555 17.1088 15.4719 17.3436C15.8046 17.403 16.1126 17.4615 16.1564 17.4737C16.2165 17.4906 16.2359 17.801 16.2359 18.748C16.2359 19.4366 16.2576 20 16.2841 20C16.3106 20 17.1575 19.1688 18.1662 18.1528L20 16.3057L18.1436 14.4349C17.1226 13.406 16.2757 12.5641 16.2616 12.5641C16.2475 12.5641 16.2359 13.0978 16.2359 13.75C16.2359 14.4023 16.2104 14.9359 16.1792 14.9359C16.0128 14.9359 14.8666 14.5961 14.4564 14.4251C13.0329 13.832 11.649 12.6912 10.8876 11.4835C10.7301 11.2335 10.5827 11.0282 10.5602 11.0273C10.5378 11.0264 10.4997 11.1048 10.4756 11.2016Z"
                fill="#027AFF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.4756 11.2016C10.4057 11.482 9.76336 12.8063 9.51333 13.1856C9.28761 13.5279 9.28634 13.5336 9.39751 13.705C9.61609 14.0421 10.8091 15.1661 11.4015 15.5931C12.541 16.4145 14.1555 17.1088 15.4719 17.3436C15.8046 17.403 16.1126 17.4616 16.1564 17.4738C16.2165 17.4906 16.236 17.801 16.236 18.7481C16.236 19.4366 16.2576 20 16.2841 20C16.3106 20 17.1575 19.1688 18.1662 18.1529L20 16.3057L18.1437 14.4349C17.1226 13.406 16.2757 12.5641 16.2616 12.5641C16.2475 12.5641 16.236 13.0978 16.236 13.75C16.236 14.4023 16.2104 14.9359 16.1792 14.9359C16.0129 14.9359 14.8666 14.5961 14.4564 14.4252C13.033 13.832 11.649 12.6912 10.8877 11.4835C10.7301 11.2335 10.5827 11.0282 10.5603 11.0274C10.5378 11.0265 10.4997 11.1049 10.4756 11.2016Z"
                fill={colorMode === Theme.Dark ? 'white' : 'black'}
            />
        </Icon>
    )
}
