import { checkboxAnatomy as parts } from '@chakra-ui/anatomy'

const baseStyle = {
    control: {
        border: '1px solid',
        boxSizing: 'border-box',
        borderColor: 'color.100',
        borderRadius: '3px',
        _checked: {
            color: 'white',
            backgroundColor: 'blue.200',
        },
    },
}

export const Checkbox = {
    parts: parts.keys,
    baseStyle,
}
