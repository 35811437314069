import { rolesInstance, tenantsIdentityInstance } from 'api/instances'
import { ITenantsRolesData } from 'types/core'

export class TenantsGroupMappingAPI {
    static async getTenantRoles() {
        const { data = [] } = await rolesInstance.get('')
        return data
    }

    static async getAll(tenantsId: string, authenticationType: string) {
        const { data = [] } = await tenantsIdentityInstance.get(`/${tenantsId}/sso/${authenticationType}/group-mapping`)
        return data
    }

    static async saveAll(
        tenantsId: string,
        authenticationType: string,
        tenantsRoles: ITenantsRolesData[],
    ) {
        await tenantsIdentityInstance.post(`/${tenantsId}/sso/${authenticationType}/group-mapping`, tenantsRoles)
    }

    static async addNew(tenantsId: string, authenticationType: string, groupId: string, roleName: string) {
        return await tenantsIdentityInstance.post(`/${tenantsId}/sso/${authenticationType}/group-mapping`, {
            groupId,
            roleName,
        })
    }
}
