import React, { FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Collapse, useDisclosure } from '@chakra-ui/react'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { DateRangePicker } from 'components/DateRangePicker'
import { dateRangeStore } from 'components/DateRangePicker/store'
import { orderStore } from 'stores'
import { IRenderOrderFilters } from 'types/core'
import { SimpleArrowDownIcon, SimpleArrowRightIcon } from 'icons'
import { CollapseBox, DatePickersBox, FilterText } from './styles'

export const CollapseDate: FC<IRenderOrderFilters> = observer(
    ({ filter, isEditFilters }) => {
        const { isOpen, onToggle } = useDisclosure()

        const handleSaveFrom = (date: MaterialUiPickersDate) => {
            orderStore.setFilters(date, filter.value, 'dateFrom')
        }

        const handleSaveTo = (date: MaterialUiPickersDate) => {
            orderStore.setFilters(date, filter.value, 'dateTo')
        }

        const onDatePickerChange = () => {
            const FromDate = dateRangeStore.selectedStartDate
            const ToDate = dateRangeStore.selectedEndDate
            handleSaveFrom(FromDate)
            handleSaveTo(ToDate)
        }

        useEffect(() => {
            return () => {
                orderStore.setLocalStorageFilters()
            }
        }, [])

        return (
            <>
                <CollapseBox onClick={onToggle}>
                    {isOpen ? (
                        <SimpleArrowDownIcon
                            boxSize="15px"
                            color="blue.300"
                            marginRight="5px"
                        />
                    ) : (
                        <SimpleArrowRightIcon
                            boxSize="15px"
                            color="color.100"
                            marginRight="5px"
                        />
                    )}
                    <FilterText>{filter.name}</FilterText>
                </CollapseBox>
                <Collapse in={isOpen} animateOpacity>
                    <DatePickersBox>
                        <DateRangePicker
                            filterType={filter.value}
                            dateRangePickerSwitch={true}
                            marginTop="8px"
                            onChangeRange={onDatePickerChange}
                        />
                    </DatePickersBox>
                </Collapse>
            </>
        )
    }
)
