import { ModalBody } from '@chakra-ui/react'
import { Text } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/button'
import { chakra } from 'utils/chakra'

export const ModalBodyStyled = chakra(ModalBody, {
    padding: '0 15px',
    variant: 'modal',
    color: 'color.100',
})

export const CloseButton = chakra(Button, {
    variant: 'transparent',
    marginRight: '12px',
})

export const ConfirmButton = chakra(Button, {
    variant: 'blue',
})

export const ModalHeaderText = chakra(Text, {
    variant: 'h3',
    alignSelf: 'start',
    marginTop: '10px',
})

export const ModalInputText = chakra(Text, {
    variant: 'h4LineHeight',
    textAlign: 'start',
})
