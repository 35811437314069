import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { InputSearchSquare } from 'components/baseComponents/InputSearchSquare'
import { IAttributeRequest } from 'types/core'
import { customeAttributeStore } from 'stores'

interface AttributeCategory {
    shipper: object
}

export const Search: FC<AttributeCategory> = observer(shipper => {
    const [value, setValue] = useState('')

    const handleClear = (): void => {
        setValue('')
    }

    useEffect(() => {
        if (!value)
            customeAttributeStore.searchByName(
                value,
                shipper?.shipper as IAttributeRequest
            )
        // Need for improvement. Warnings console
        /* eslint-disable */
    }, [value])

    const handleChange = (value: ChangeEvent<HTMLInputElement>): void => {
        setValue(value.target.value)
    }

    const handleSearch = async (): Promise<void> => {
        await customeAttributeStore.searchByName(
            value,
            shipper?.shipper as IAttributeRequest
        )
    }

    return (
        <InputSearchSquare
            value={value}
            placeholder="Search"
            handleClear={handleClear}
            handleChange={handleChange}
            handleSearch={handleSearch}
        />
    )
})
