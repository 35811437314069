import { FC } from 'react'
import { Button } from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'
import { CSVLink } from 'react-csv'
import { csvDataFormat, changeDate } from './utils'
import { dateOnlyFormatter } from '../utils'
import { setCsvHeader } from './csvHeader'
import {
    IPPSalesOverDuration,
    IProductPerformanceItem,
    IReportData,
} from 'types/core'
import { exportButtonStyle } from './styles'

interface IExportProps {
    data?: IReportData[]
    performanceByTotalOrderData?: IProductPerformanceItem[]
    productSalesOverDuration?: IPPSalesOverDuration[]
    fileName: string
    totalItems: number
    reportName: string
}

export const CSVExport: FC<IExportProps> = observer(
    ({
        data,
        performanceByTotalOrderData,
        productSalesOverDuration,
        fileName,
        totalItems,
        reportName,
    }) => {
        const headers = setCsvHeader(reportName)

        !!data?.length &&
            data?.forEach(order => {
                order.created = changeDate(order.created, true)
                order.expectedDeliveryDate = dateOnlyFormatter(
                    order.expectedDeliveryDate
                )
            })

        !!performanceByTotalOrderData?.length &&
            performanceByTotalOrderData?.forEach(source => {
                source?.orderPerformance.forEach(order => {
                    order.orderDate = dateOnlyFormatter(order.orderDate)
                })
            })

        const csvReport = {
            data: csvDataFormat(
                data || [],
                performanceByTotalOrderData || [],
                productSalesOverDuration || [],
                reportName
            ),
            filename: fileName,
        }

        return (
            <CSVLink {...csvReport} headers={headers}>
                <Button disabled={!totalItems} style={exportButtonStyle}>
                    Export as CSV
                </Button>
            </CSVLink>
        )
    }
)
