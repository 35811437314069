import React, { FC, useEffect } from 'react'
import ReactFlow, { ReactFlowProvider } from 'react-flow-renderer'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import { edgeTypes } from '../edges/edgeTypes'
import { nodeTypes } from '../nodes/nodeTypes'
import { previewStore, stepsMetadataStore } from 'stores'
import { UUID } from 'types/core'
import { DiagramViewBox } from './styles'

interface DiagramViewProps {
    id: UUID
}

export const DiagramView: FC<DiagramViewProps> = observer(({ id }) => {
    const elements = toJS(previewStore.diagramStore.elements)

    useEffect(() => {
        previewStore.init(id)
        stepsMetadataStore.fetch()

        return () => {
            previewStore.clean()
            stepsMetadataStore.clean()
        }
    }, [id])

    return (
        <ReactFlowProvider>
            <DiagramViewBox>
                <ReactFlow
                    elements={elements}
                    nodeTypes={nodeTypes}
                    edgeTypes={edgeTypes}
                    nodesDraggable={false}
                    nodesConnectable={false}
                ></ReactFlow>
            </DiagramViewBox>
        </ReactFlowProvider>
    )
})
