import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Input, Select } from '@chakra-ui/react'
import { Box } from '@chakra-ui/layout'
import { expType, IEditData } from './EditModal'
import { toJS } from 'mobx'
import { mappingStore } from 'stores'
import { IFunction, IParametersFunc } from 'types/api'
import { EditModalErrorBox, ParamEditContainer, ParamEditModalBox, ParamEditModalBoxGlobal } from '../styles'

interface IParamsEditModal {
    data: IParamsData
    baseData: IEditData
    arrKeysParam: string[]
    setData: Dispatch<SetStateAction<object>>
    arrKeysExpressions: string[]
}

interface IParamsData {
    expression: IExpression
    name: string
}

interface IExpression {
    parameters: IParamsData[]
    type: string
    value: string
}

const expTypeMax = ['value', 'path']
const expTypeSupportsExpressions = ['value']

export const ParamsEditModal = observer(({
         data,
         arrKeysParam,
         baseData,
         setData,
         arrKeysExpressions,
     }: IParamsEditModal) => {
    const [paramData, setParamsData] = useState(data)
    const [isPathError, setPathError] = useState(false)
    const func = toJS(mappingStore.function)

    useEffect(() => {
        setParamsData(data)
    }, [data])

    const isSupportsExpressions = (): boolean => {
        if (arrKeysExpressions.length) {
            const result = arrKeysExpressions.reduce((
                acc: (IFunction | IParametersFunc)[] | boolean,
                path,
                index,
            ) => {
                const findByPath = Array.isArray(acc)
                    ? acc.find((el: IFunction | IParametersFunc) => el.name === path)
                    : false
                if (findByPath) {
                    const parameters = index === 0 && 'parameters' in findByPath && findByPath.parameters
                    const supportsExpressions = 'supportsExpressions' in findByPath && findByPath.supportsExpressions
                    return parameters || supportsExpressions
                }
                return acc
            }, func)
            return !!result
        }
        return false
    }

    const expressionSelectParams = isSupportsExpressions() ? arrKeysParam.length < 3 ? expType : expTypeMax : expTypeSupportsExpressions

    const updParams = (updateData: string, isFirstItem?: boolean) => {
        const objParam = isFirstItem ? func[0] : func.find(el => el.name === updateData)
        return objParam?.parameters?.map((el) => {
            return { name: el.name, expression: { parameters: null, type: 'value', value: '' } }
        })
    }

    const handleChange = (value: string, type: string, name: string) => {
        let copyObj: IParamsData | IEditData = { ...baseData }
        if (arrKeysParam.length) {
            arrKeysParam.reduce((acc: any, path, index) => {
                if (acc.expression.parameters.length && arrKeysParam.length - 1 !== index) {
                    return acc.expression.parameters.find((el: IParamsData) => el.name === path)
                } else {
                    let obj = acc.expression.parameters.find((el: IParamsData) => el.name === name)
                    let updateParams = updParams(value)
                    if (value === 'function' && type === 'type') {
                        setPathError(false)
                        updateParams = updParams(type, true)
                        obj.expression = { parameters: updateParams, [type]: value, value: func[0].name }
                    } else if (value !== 'function' && type === 'type') {
                        setPathError(false)
                        obj.expression = { parameters: null, [type]: value, value: '' }
                    } else {
                        if (updateParams) {
                            obj.expression = { ...obj.expression, parameters: updateParams, [type]: value }
                        } else {
                            if (obj.expression.type.toLowerCase() === 'path') {
                                const result = value.match(/^[$.]{2}[a-z0-9._-]{1,100}$/)
                                setPathError(result === null)
                                obj.expression = { ...obj.expression, [type]: value }
                            } else {
                                obj.expression = { ...obj.expression, [type]: value }
                            }
                        }
                    }
                }
                return acc
            }, copyObj)
        }

        setData({ ...copyObj, expression: { ...paramData.expression, [type]: value } })
    }

    return (
        <ParamEditModalBoxGlobal>
            <Box>
                <Box display='flex'>
                    <Box textAlign='left'>Name:</Box>
                    <Box textAlign='left' paddingLeft='5px'>
                        {paramData?.name}
                    </Box>
                </Box>

                <Box textAlign='left'>Expression:</Box>
                {!!paramData.expression ? (
                    <Box paddingLeft='10px'>
                        <ParamEditModalBox>
                            <Box textAlign='left' paddingRight='5px'>Value:</Box>
                            <EditModalErrorBox>
                                {paramData?.expression?.type?.toLowerCase() ===
                                'function' ? (
                                    <Select
                                        value={paramData?.expression?.value}
                                        onChange={value => handleChange(value.target.value, 'value', paramData?.name)}
                                    >
                                        {func.map((el: { name: string }) => (
                                                <option key={el.name} value={el.name}>
                                                    {el.name}
                                                </option>
                                            ),
                                        )}
                                    </Select>
                                ) : (
                                    <Input
                                        size='sm'
                                        value={paramData?.expression.value}
                                        placeholder='key'
                                        onChange={(
                                            value: ChangeEvent<HTMLInputElement>,
                                        ) => handleChange(value.target.value, 'value', paramData?.name)}
                                    />
                                )}

                                {isPathError && (
                                    <Box color='red.500'>
                                        This field must start with "$.".
                                    </Box>
                                )}
                            </EditModalErrorBox>
                        </ParamEditModalBox>
                        <ParamEditModalBox>
                            <Box textAlign='left' paddingRight='5px'>Type:</Box>
                            <Select
                                value={paramData?.expression.type?.toLowerCase()}
                                onChange={value => handleChange(value.target.value, 'type', paramData?.name)}
                            >
                                {expressionSelectParams.map((el: string) => (
                                    <option key={el} value={el}>
                                        {el.toLowerCase()}
                                    </option>
                                ))}
                            </Select>
                        </ParamEditModalBox>
                        <Box display='flex'>
                            <Box textAlign='left' paddingRight='5px'>Parameters:</Box>
                            <ParamEditContainer>
                                {(!!data.expression &&
                                    Array.isArray(data.expression.parameters)) ? (
                                    data.expression.parameters.map(
                                        (el: IParamsData, index: number) => (
                                            <ParamsEditModal
                                                baseData={baseData}
                                                arrKeysParam={[
                                                    ...arrKeysParam,
                                                    el.name,
                                                ]}
                                                arrKeysExpressions={[
                                                    data.expression.value,
                                                    el.name,
                                                ]}
                                                data={el}
                                                setData={setData} />
                                        ),
                                    )
                                ) : (
                                    <Box textAlign='left' paddingLeft='5px'>null</Box>
                                )}
                            </ParamEditContainer>
                        </Box>
                    </Box>
                ) : (
                    <Box textAlign='left' paddingLeft='5px'>null</Box>
                )}
            </Box>
        </ParamEditModalBoxGlobal>
    )
})
