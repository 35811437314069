import React, { FC, useState } from 'react'
import { truncate } from 'lodash'
import { observer } from 'mobx-react-lite'
import { Box, Text } from '@chakra-ui/layout'
import { AccordionItem, Flex } from '@chakra-ui/react'
import { Switch } from '@chakra-ui/switch'
import { EditIcon, SettingsIcon } from 'icons'
import { CustomerShipmentMethods, CustomerShipper } from 'types/api'
import { customerShipperStore } from 'stores'
import { TooltipTextCut } from 'components/TooltipTextCut'
import { MAX_LENGTH_VALUE_SHOW } from 'constants/customerShipper'
import { getShipmentMethodOptions } from '../mock'
import { AccordionButtonBtn, ShipmentMethodContainer } from '../styles'

interface IShipmentMethodBox {
    shipmentMethod: CustomerShipmentMethods
    tenantId: string
    shipper: CustomerShipper
    onAddShipmentMethodOpen: () => void
}

export const ShipmentMethodBox: FC<IShipmentMethodBox> = observer(({
  shipmentMethod,
  tenantId,
  shipper,
  onAddShipmentMethodOpen,
}) => {
    const [enabled, setEnabled] = useState<boolean>(shipmentMethod.isEnabled)

    const options = getShipmentMethodOptions(shipmentMethod)
    const isEnabled = enabled && shipper.isEnabled

    const changeEnable = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation()
        if (enabled) {
            customerShipperStore.changeShipmentMethodDeactivate(tenantId, shipper.code, shipmentMethod.code)
                .then(() => setEnabled(!enabled))
        } else if (!enabled) {
            customerShipperStore.changeShipmentMethodActivate(tenantId, shipper.code, shipmentMethod.code)
                .then(() => setEnabled(!enabled))
        }
    }

    const openEditModal = (e: React.MouseEvent, isSettingsModal: boolean, valueCheck: boolean) => {
        if (valueCheck) {
            e.stopPropagation()
            customerShipperStore.changeTypeModal(isSettingsModal)
            customerShipperStore.changeTypeReq(false)
            customerShipperStore.changeShipmentMethodsItem(shipmentMethod)
            onAddShipmentMethodOpen()
        }
    }

    return (
        <ShipmentMethodContainer>
            {options.map((option, index) => (
                <Box key={index}  paddingLeft='5px'>
                    <AccordionItem>
                        <TooltipTextCut  maxTextLength={MAX_LENGTH_VALUE_SHOW} text={option}>
                            <Text>
                                {option
                                    ? truncate(option.toString(), { length: MAX_LENGTH_VALUE_SHOW })
                                    : '-'
                                }
                            </Text>
                        </TooltipTextCut>
                    </AccordionItem>
                </Box>
            ))}
            <Flex justifyContent="center">
                <Switch
                    disabled={!shipper.isEnabled}
                    isChecked={enabled}
                    onChange={(e) => changeEnable(e)}
                    variant='switchBoolean'
                />
            </Flex>
            <Flex justifyContent="center">
                {shipper.type === 'Custom' &&
                    <AccordionButtonBtn
                        onClick={(e) => openEditModal(e, false, shipper.isEnabled)}
                        cursor={!shipper.isEnabled ? 'not-allowed' : 'pointer'}
                        color={!shipper.isEnabled ? 'color.75' : 'color.100'}
                    >
                        <EditIcon/>
                    </AccordionButtonBtn>
                }
                <AccordionButtonBtn
                    onClick={(e) => openEditModal(e, true, isEnabled)}
                    cursor={!isEnabled ? 'not-allowed' : 'pointer'}
                    color={!isEnabled ? 'color.75' : 'color.100'}
                >
                    <SettingsIcon />
                </AccordionButtonBtn>
            </Flex>
        </ShipmentMethodContainer>
    )
})
