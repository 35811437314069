import { Button, ModalContent, Tag, List, ButtonGroup } from '@chakra-ui/react'
import { chakra } from 'utils/chakra'

export const FileName = chakra(Tag, {
    verticalAlign: 'sub',
    bg: 'none',
    marginLeft: '10px',
})

export const CsvBrowseButton = chakra(Button, {
    variant: 'blueFixed',
    width: '100px',
    height: '30px',
    _hover: { bg: 'blue.990' },
    fontWeight: '400',
    margin: '10px 0px 3px 0px',
})

export const ModalContentStyled = chakra(ModalContent, {
    height: '300px',
    alignSelf: 'center',
})

export const ListStyle = chakra(List, {
    listStyleType: 'disc',
    textAlign: 'initial'
})

export const ButtonStyled = chakra(Button, {
    width: '100%',
    variant: 'blueFixed',
})

export const ButtonGroupStyled = chakra(ButtonGroup, {
    spacing: '3',
})

export const SizeCsvValidationText = chakra(List, {
   color: 'gray.400',
   margin: '5px 0px 5px 20px',
   listStyleType: 'disc',
})