import React, { Dispatch, SetStateAction, useMemo } from 'react'
import { useTable, Column } from 'react-table'
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'
import EditableCell from '../EditableCell'

export type EditingRows = { [id: string]: boolean }

export type EditableTableProps<Data extends object> = {
    data: Data[]
    columns: Column<Data>[]
    editingRows?: EditingRows
    setEditingRows?: Dispatch<SetStateAction<EditingRows>>
    updateMyData?: (rowIndex: number, columnId: string, value: string) => void
    onCancelEdit?: (id: number) => void
    onEdit?: (id: number) => void
    onSave?: (rowIndex: number) => void
    openDeleteModal?: (rowIndex: number) => void
    onOpen?: () => void
    onSaveData?: (id: string) => void
    onDelete?: (mappingId: string) => void
}

const EditableTable = <Data extends object>({
    data,
    columns,
    editingRows,
    setEditingRows,
    updateMyData,
    onCancelEdit,
    onEdit,
    onSave,
    openDeleteModal,
    onOpen,
    onSaveData,
    onDelete,
}: EditableTableProps<Data>) => {
    const defaultColumn = useMemo(
        () => ({
            Cell: EditableCell,
        }),
        []
    )

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({
            columns,
            data,
            defaultColumn,
            editingRows,
            setEditingRows,
            onEdit,
            onCancelEdit,
            updateMyData,
            onSave,
            openDeleteModal,
            onOpen,
            onSaveData,
            onDelete,
        })
    return (
        <Table {...getTableProps()}>
            <Thead>
                {headerGroups.map(headerGroup => (
                    <Tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <Th
                                {...column.getHeaderProps()}
                                whiteSpace="break-spaces"
                            >
                                {column.render('Header')}
                            </Th>
                        ))}
                    </Tr>
                ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row)
                    return (
                        <Tr {...row.getRowProps()}>
                            {row.cells.map(cell => (
                                <Td
                                    {...cell.getCellProps()}
                                    color="adminGatewayTabs"
                                >
                                    {cell.render('Cell')}
                                </Td>
                            ))}
                        </Tr>
                    )
                })}
            </Tbody>
        </Table>
    )
}

export default EditableTable
