import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const WarningIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 24 25"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_7788_107535)">
                <path d="M12 2.5L1.6077 20.5H22.3923L12 2.5Z" fill="#FFCE39"/>
                <g filter="url(#filter0_d_7788_107535)">
                    <g filter="url(#filter1_d_7788_107535)">
                        <path d="M7.625 15.625C7.14375 15.625 6.75 16.0187 6.75 16.5C6.75 16.9812 7.14375 17.375 7.625 17.375C8.10625 17.375 8.5 16.9812 8.5 16.5C8.5 16.0187 8.10625 15.625 7.625 15.625ZM16.375 15.625C15.8937 15.625 15.5 16.0187 15.5 16.5C15.5 16.9812 15.8937 17.375 16.375 17.375C16.8562 17.375 17.25 16.9812 17.25 16.5C17.25 16.0187 16.8562 15.625 16.375 15.625ZM12 15.625C11.5187 15.625 11.125 16.0187 11.125 16.5C11.125 16.9812 11.5187 17.375 12 17.375C12.4812 17.375 12.875 16.9812 12.875 16.5C12.875 16.0187 12.4812 15.625 12 15.625Z" fill="url(#paint0_linear_7788_107535)"/>
                    </g>
                </g>
            </g>
            <defs>
                <filter id="filter0_d_7788_107535" x="4" y="9.5" width="16" height="16" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="1"/>
                    <feGaussianBlur stdDeviation="0.5"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7788_107535"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7788_107535" result="shape"/>
                </filter>
                <filter id="filter1_d_7788_107535" x="5.75" y="15.625" width="12.5" height="3.75" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="1"/>
                    <feGaussianBlur stdDeviation="0.5"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7788_107535"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7788_107535" result="shape"/>
                </filter>
                <linearGradient id="paint0_linear_7788_107535" x1="12" y1="15.625" x2="12" y2="17.375" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white"/>
                    <stop offset="1" stopColor="#EFEFEF"/>
                </linearGradient>
                <clipPath id="clip0_7788_107535">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
            </defs>
        </Icon>
    )
}
