import { Box, Flex, Text } from '@chakra-ui/layout'
import { chakra } from 'utils/chakra'

export const CreateVirtualOrderBox = chakra(Box, {
    margin: '16px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
})

export const VirtualOrderBoxReverse = chakra(Flex, {
    margin: '16px 0',
    flexDirection: 'row-reverse',
})

export const OrderItemsBox = chakra(Flex, {
    flexDirection: 'column',
    alignItems: 'center',
})

export const InputText = chakra(Text, {
    variant: 'supertitle',
    margin: '2px 0',
})

export const VirtualOrderBox = chakra(Box, {
    margin: '8px 0',
})
