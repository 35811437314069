import { mappingMetadataInstance, tenantsInstance } from './instances'
import { IFunction, MappingsResponse, PagingFilters } from 'types/api'
import { KnownObject, Mapping } from 'types/core'

export class MappingAPI {
    static async getMappings(
        tenantId: string,
        { page, size }: PagingFilters,
        isFilters: boolean
    ): Promise<MappingsResponse> {
        const isFiltersUrl = isFilters ?
            `/${tenantId}/integration-mappings?page=${1}&size=${-1}`
            : `/${tenantId}/integration-mappings?page=${page}&size=${size}`
        const response = await tenantsInstance.get<MappingsResponse>(isFiltersUrl)
        return response.data
    }

    static async getMappingsMetadata(): Promise<KnownObject[]> {
        const response = await mappingMetadataInstance.get<KnownObject[]>(
            `/known-scheme-objects`
        )
        return response.data
    }

    static async getMappingById(tenantId: string, mappingId: string) {
        const response = await tenantsInstance.get<Mapping>(
            `/${tenantId}/integration-mappings/${mappingId}`
        )
        return response.data
    }

    static async getMappingFunc() {
        const response = await mappingMetadataInstance.get<IFunction[]>(
            `/functions`
        )
        return response.data
    }

    static async createMapping(tenantId: string, data: Mapping) {
        const response = await tenantsInstance.post(
            `/${tenantId}/integration-mappings`,
            data
        )
        return response.data
    }

    static async updateMapping(
        tenantId: string,
        mappingId: string,
        data: Mapping
    ) {
        const response = await tenantsInstance.put(
            `/${tenantId}/integration-mappings/${mappingId}`,
            data
        )
        return response.data
    }

    static async removeMapping(tenantId: string, mappingId: string) {
        const response = await tenantsInstance.delete(
            `/${tenantId}/integration-mappings/${mappingId}`
        )
        return response.data
    }
}
