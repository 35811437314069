import { Box, Text } from '@chakra-ui/layout'
import { chakra } from 'utils/chakra'

export const NodeName = chakra(Text, {
    variant: 'paragraphsmall',
})

export const NodeBox = chakra(Box, {
    width: '100%',
    marginTop: 2
})

export const NodeBoxMedium = chakra(Box, {
    width: '100%',
    marginTop: 2
})
