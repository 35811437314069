import { FC } from 'react'
import { Box, Flex, HStack, Spacer } from '@chakra-ui/layout'
import { UpArrowIcon, DownArrowIcon } from 'icons'
import { ProviderDataBox, ShipmentProvider, Percent, Change } from './styles'

interface DataProps {
    source: string
    myData: number[]
}

export const DataContainer: FC<DataProps> = ({ source, myData }) => {
    return (
        <ProviderDataBox>
            <Box justifyContent={'left'}>
                <ShipmentProvider isTruncated>{source}</ShipmentProvider>
            </Box>
            <Spacer />
            <Flex>
                <Percent>{myData?.length ? myData[0].toFixed(2) + '%' : ''}</Percent>
                <Box>
                    {myData?.length >= 1 ? (
                        <HStack>
                            <UpArrowIcon color={'mintgreenBlue'} />
                            <Change color={'mintgreenBlue'}>
                                {myData[1].toFixed(2) + '%'}
                            </Change>
                        </HStack>
                    ) : (
                        <HStack>
                            <DownArrowIcon color={'#FF4365'} />
                            <Change color={'#FF4365'}>
                                {myData.length>=1 ? myData[1].toFixed(2) + '%' : ''}
                            </Change>
                        </HStack>
                    )}
                </Box>
            </Flex>
        </ProviderDataBox>
    )
}
