import { makeAutoObservable } from 'mobx'
import { AxiosError } from 'axios'
import { notificationStore } from 'stores'
import { IOrderExternalSource, IWow, UUID } from 'types/core'
import { WowsAPI } from 'api/wows'
import { ConfigurationsAPI } from 'api/configurations'
import { OrderClientAPI } from 'api/orderClient'

interface IRulesSetsFilters {
    page: number
    size: number
    name: string
}

interface IOrderSourceMappings {
    wowId: UUID
    orderSourceId: UUID
}

const defaultFilters: IRulesSetsFilters = {
    page: 1,
    size: 10,
    name: '',
}

export class RulesSetsStore {
    public rulesSets: IWow[] = []
    public filters: IRulesSetsFilters = defaultFilters
    public pages: number = 0
    public orderSourceMappings: IOrderSourceMappings[] = []
    public orderSources: IOrderExternalSource[] = []

    constructor() {
        makeAutoObservable(this)
    }

    public async search(): Promise<void> {
        try {
            const { items: rulesSets = [], totalPages } =
                await WowsAPI.getAll(this.filters.page, this.filters.size, this.filters.name)

            this.init(rulesSets)
            this.setUpPages(totalPages)
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    public async wowOrderSourceMappings(): Promise<void> {
        try {
            const { value = JSON.stringify([]) } = await ConfigurationsAPI.getWowOrderSourceMappings()
            this.initOrderSourceMappings(JSON.parse(value))
        } catch (err) {
            const error = err as AxiosError
            if (error.response && error.response.status === 404) {
                await ConfigurationsAPI.putWowOrderSourceMappings({
                    category: "Rules Engine",
                    subcategory: "Mappings",
                    value: JSON.stringify([]),
                })
            }

            this.initOrderSourceMappings([])
            notificationStore.triggerErrorToast(err)
        }
    }

    public async getOrderSources(): Promise<void> {
        try {
            const orderSources = await OrderClientAPI.getOrderSources()
            this.initOrderSources(orderSources)
        } catch (err) {
            this.initOrderSources([])
            notificationStore.triggerErrorToast(err)
        }
    }

    public async putOrderSourceMapping(orderSourceMappings: IOrderSourceMappings[]): Promise<void> {
        try {
            await ConfigurationsAPI.putWowOrderSourceMappings({
                category: "Rules Engine",
                subcategory: "Mappings",
                value: JSON.stringify(orderSourceMappings),
            })
        } catch (err) {
            this.initOrderSources([])
            notificationStore.triggerErrorToast(err)
        }
    }

    public findOrderSourceByWowId(wowId: UUID): IOrderExternalSource | null {
        const { orderSourceId } = this.orderSourceMappings.find(
            (item) => item.wowId.toLowerCase() === wowId.toLowerCase()
        ) || {}

        if (orderSourceId) {
            const orderSource = this.orderSources.find(
                item => item.id.toLowerCase() === orderSourceId.toLowerCase()
            )

            return orderSource || null
        }

        return null
    }

    public async searchByName(value: string): Promise<void> {
        this.initFilters({ ...this.filters, name: value })
        await this.search()
    }

    public async delete(wowId: UUID, orderSource: IOrderExternalSource | null): Promise<void> {
        try {
            await WowsAPI.deleteById(wowId)
            if (orderSource) {
                const filterMapping = this.orderSourceMappings.filter(
                    ({ orderSourceId }) => orderSourceId.toLowerCase() !== orderSource.id.toLowerCase()
                )

                await this.putOrderSourceMapping(filterMapping)
            }
            await this.search()
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    private setUpPages(pages: number): void {
        this.pages = pages
    }

    private initOrderSourceMappings(orderSourceMappings: IOrderSourceMappings[]): void {
        this.orderSourceMappings = orderSourceMappings
    }

    private initOrderSources(orderSources: IOrderExternalSource[]): void {
        this.orderSources = orderSources
    }

    public async setPages(filters: Partial<IRulesSetsFilters>): Promise<void> {
        if (!filters.page) {
            this.initFilters({ ...this.filters, ...filters, page: 1 })
        } else {
            this.initFilters({ ...this.filters, ...filters })
        }

        await this.search()
    }

    private init(rulesSets: IWow[]): void {
        this.rulesSets = rulesSets
    }

    private initFilters(filters: IRulesSetsFilters): void {
        this.filters = filters
    }

    public clean(): void {
        this.init([])
        this.setUpPages(0)
        this.initOrderSources([])
        this.initOrderSourceMappings([])
        this.initFilters(defaultFilters)
    }
}
