import { useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { Box } from '@chakra-ui/layout'
import { Checkbox } from '@chakra-ui/react'
import { Button } from '@chakra-ui/button'
import { CounteredInput } from 'components/CounteredInput'
import { validateEmail } from 'pages/LoginPage/mock'
import { minPassLength, validateTextEmail } from 'constants/userAdmin'
import { routes } from 'navigation/routes'
import {
    EMAIL_LENGTH,
    FIRST_NAME_LENGTH,
    LAST_NAME_LENGTH,
    PASSWORD_LENGTH,
} from 'constants/rules'
import { tenantsStore, tenantUsersStore, userStore } from 'stores'
import { IUserRoleModel } from 'stores/models'
import { UserRole } from 'api/models'
import { FlexStyled } from 'pages/AdminPages/Users/UsersPage/styles'
import { InputText } from 'pages/LoginPage/styles'

interface UserForm {
    email: string | null
    firstName: string | null
    lastName: string | null
    roles: IUserRoleModel[]
    currentUserPassword: string | null
    newUserPassword: string | null
    newUserPasswordRepeat: string | null
}

const defaultUserForm: UserForm = {
    email: null,
    firstName: null,
    lastName: null,
    roles: [],
    currentUserPassword: '',
    newUserPassword: '',
    newUserPasswordRepeat: '',
}

export const User = observer(() => {
    const { id = 'new' } = useParams()
    const isNew = id === 'new'
    const navigate = useNavigate()
    const { isSuperAdmin, isTenantAdmin } = toJS(userStore.currentRoles)
    const isAdmin = isTenantAdmin || isSuperAdmin

    const {
        register,
        control,
        watch,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm<UserForm>({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        defaultValues: defaultUserForm,
    })

    const { fields } = useFieldArray({
        control,
        name: 'roles',
        keyName: 'role',
    })

    const userFieldsRemainings = {
        email: EMAIL_LENGTH - (watch('email', '')?.length ?? 0),
        firstName: FIRST_NAME_LENGTH - (watch('firstName', '')?.length ?? 0),
        lastName: LAST_NAME_LENGTH - (watch('lastName', '')?.length ?? 0),
        currentPassword:
            PASSWORD_LENGTH - (watch('currentUserPassword', '')?.length ?? 0),
        newPassword:
            PASSWORD_LENGTH - (watch('newUserPassword', '')?.length ?? 0),
        newPasswordRepeat:
            PASSWORD_LENGTH - (watch('newUserPasswordRepeat', '')?.length ?? 0),
    }

    const selectedTenant = tenantsStore.selectedTenant
    const currentUser = tenantUsersStore.currentUser
    const currentRoles = tenantUsersStore.currentRoles

    useEffect(() => {
        if (isNew) {
            tenantUsersStore.setCurrent(null, selectedTenant?.id, '')
        } else if (!!selectedTenant) {
            tenantUsersStore.setCurrent(
                id,
                selectedTenant.id,
                selectedTenant.authenticationType
            )
        }
        return () => tenantUsersStore.resetState()
    }, [id, isNew, selectedTenant])

    useEffect(() => {
        reset({
            ...defaultUserForm,
            ...toJS(currentUser),
            ...{ roles: toJS(currentRoles) },
        })
    }, [
        reset,
        setValue,
        currentUser,
        currentUser?.firstName,
        currentUser?.lastName,
        currentUser?.email,
        currentRoles,
    ])
    const getRequiredMessage = (name: string) =>
        `This field cannot be blank. Please enter a ${name} to continue.`

    async function saveUser(data: UserForm): Promise<void> {
        if (isNew && !!selectedTenant?.id) {
            await tenantUsersStore.create(
                selectedTenant.id,
                selectedTenant.authenticationType,
                data.firstName!,
                data.lastName!,
                data.email!,
                data.newUserPassword!,
                data.roles.filter(r => r.isAssigned).map(r => r.role)
            )
                if(tenantUsersStore.created) {
                    navigate(routes.users)
                }
        } else if (!!currentUser && !isNew && !!selectedTenant?.id) {
            await tenantUsersStore.update(
                selectedTenant.id,
                currentUser.id,
                data.firstName,
                data.lastName,
                data.currentUserPassword,
                data.newUserPassword,
                data.roles.filter(r => r.isAssigned).map(r => r.role)
            )

            navigate(routes.users)
        }
    }

    return (
        <FlexStyled>
            <Box>
                <form onSubmit={handleSubmit(saveUser)}>
                    <Box margin="16px 0">
                        <InputText>Email</InputText>
                        <CounteredInput
                            placeholder="Enter Email..."
                            size="sm"
                            width="580px"
                            marginTop="8px"
                            isReadOnly={!isNew}
                            color={isNew ? 'color.100' : 'gray.400'}
                            remaining={userFieldsRemainings.email}
                            isInvalid={!!errors.email}
                            description={errors.email?.message}
                            {...register('email', {
                                required:
                                    'This field cannot be blank. Please enter an email to continue.',
                                validate: {
                                    value: (value: string | null) => {
                                        if (!validateEmail(value))
                                            return validateTextEmail
                                        return true
                                    },
                                },
                                maxLength: {
                                    value: EMAIL_LENGTH,
                                    message: `This field max length is ${EMAIL_LENGTH}`,
                                },
                            })}
                        />
                    </Box>
                    <Box margin="16px 0">
                        <InputText>First Name</InputText>
                        <CounteredInput
                            placeholder="Enter First Name..."
                            size="sm"
                            width="580px"
                            marginTop="8px"
                            remaining={userFieldsRemainings.firstName}
                            isInvalid={!!errors.firstName}
                            description={errors.firstName?.message}
                            {...register('firstName', {
                                required: getRequiredMessage('first name'),
                                maxLength: {
                                    value: FIRST_NAME_LENGTH,
                                    message: `This field max length is ${FIRST_NAME_LENGTH}`,
                                },
                            })}
                        />
                    </Box>
                    <Box margin="16px 0">
                        <InputText>Last Name</InputText>
                        <CounteredInput
                            placeholder="Enter Last Name..."
                            size="sm"
                            width="580px"
                            marginTop="8px"
                            remaining={userFieldsRemainings.lastName}
                            isInvalid={!!errors.lastName}
                            description={errors.lastName?.message}
                            {...register('lastName', {
                                required: getRequiredMessage('last name'),
                                maxLength: {
                                    value: LAST_NAME_LENGTH,
                                    message: `This field max length is ${LAST_NAME_LENGTH}`,
                                },
                            })}
                        />
                    </Box>
                    <Box display="flex" flexDirection="column">
                        {fields.map((field, index) => (
                            <Checkbox
                                color="color.100"
                                key={field.role}
                                {...register(`roles.${index}.isAssigned`)}
                                disabled={
                                    !isAdmin &&
                                    field.role === UserRole.TenantAdmin
                                }
                            >
                                {field.caption}
                            </Checkbox>
                        ))}
                    </Box>

                    {!isNew && (
                        <Box margin="16px 0">
                            <InputText>Current password</InputText>
                            <CounteredInput
                                type="password"
                                autoComplete="off"
                                placeholder="Enter the current password..."
                                size="sm"
                                width="580px"
                                marginTop="8px"
                                remaining={userFieldsRemainings.currentPassword}
                                isInvalid={!!errors.currentUserPassword}
                                description={
                                    errors.currentUserPassword?.message
                                }
                                {...register('currentUserPassword', {
                                    required:
                                        isNew ||
                                        !!watch('newUserPassword') ||
                                        !!watch('newUserPasswordRepeat'),
                                    minLength: {
                                        value: minPassLength,
                                        message: `This field min length is ${minPassLength}`,
                                    },
                                    maxLength: {
                                        value: PASSWORD_LENGTH,
                                        message: `This field max length is ${PASSWORD_LENGTH}`,
                                    },
                                })}
                            />
                        </Box>
                    )}
                    <Box margin="16px 0">
                        <InputText>New password</InputText>
                        <CounteredInput
                            type="password"
                            placeholder="Enter a new password..."
                            size="sm"
                            width="580px"
                            marginTop="8px"
                            remaining={userFieldsRemainings.newPassword}
                            isInvalid={!!errors.newUserPassword}
                            description={errors.newUserPassword?.message}
                            {...register('newUserPassword', {
                                minLength: {
                                    value: minPassLength,
                                    message: `This field min length is ${minPassLength}`,
                                },
                                maxLength: {
                                    value: PASSWORD_LENGTH,
                                    message: `This field max length is ${PASSWORD_LENGTH}`,
                                },
                            })}
                        />
                    </Box>
                    <Box margin="16px 0">
                        <InputText>New password repeat</InputText>
                        <CounteredInput
                            type="password"
                            placeholder="Repeat a new password..."
                            size="sm"
                            width="580px"
                            marginTop="8px"
                            remaining={userFieldsRemainings.newPasswordRepeat}
                            isInvalid={!!errors.newUserPasswordRepeat}
                            description={errors.newUserPasswordRepeat?.message}
                            {...register('newUserPasswordRepeat', {
                                minLength: {
                                    value: minPassLength,
                                    message: `This field min length is ${minPassLength}`,
                                },
                                maxLength: {
                                    value: PASSWORD_LENGTH,
                                    message: `This field max length is ${PASSWORD_LENGTH}`,
                                },
                            })}
                        />
                    </Box>
                    <Box margin="16px 0">
                        <Button
                            type="submit"
                            color="white"
                            variant="blue"
                        >
                            Save
                        </Button>

                        <Button
                            margin="0 16px"
                            type="button"
                            color="white"
                            variant="blue"
                            onClick={() => navigate(routes.users)}
                        >
                            Cancel
                        </Button>
                    </Box>
                </form>
            </Box>
        </FlexStyled>
    )
})
