import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { InputGroup } from '@chakra-ui/input'
import { Center, Flex, Text } from '@chakra-ui/layout'
import { useRangePickerContext } from '../context'
import { dateRangeStore } from '../store'
import { ClearCorssButton } from './ClearCorssButton'
import {
    CalenderFlexStyled,
    CalendarIconStyled,
    CalenderInputStyled,
    InputLeftElementStyled,
} from '../../styles'

interface CalendarNavProps {
    dateRangePicker: boolean
    calendarStartDate: Date | null
    calendarEndDate: Date | null
    clearStartDate: () => void
    clearEndDate: () => void
}

export const CalendarNav: FC<CalendarNavProps> = observer(
    ({
        dateRangePicker,
        calendarStartDate,
        calendarEndDate,
        clearStartDate,
        clearEndDate,
    }) => {
        const { onChangeRange } = useRangePickerContext()

        const selectingStartDate = () => dateRangeStore.selectingStartDate()
        
        const selectingEndDate = () => dateRangeStore.selectingEndDate()

        return (
            <Flex height="68px">
                <CalenderFlexStyled
                    flex="1"
                    onClick={selectingStartDate}
                >
                    <Flex direction="column" flex="1">
                        <Text variant="supertitle">
                            {dateRangePicker ? 'From' : 'Date'}
                        </Text>
                        <InputGroup>
                            <InputLeftElementStyled
                                children={
                                    <CalendarIconStyled className="CalendarIcon" />
                                }
                            />
                            <CalenderInputStyled
                                size="sm"
                                variant="calendar"
                                borderColor={
                                    dateRangeStore.isSelectStartDate
                                        ? 'blue.200'
                                        : 'none'
                                }
                                placeholder="Pick or enter..."
                                defaultValue={calendarStartDate?.toLocaleDateString()}
                            />
                        </InputGroup>
                    </Flex>
                    {calendarStartDate ? (
                        <Center marginLeft="10px">
                            <ClearCorssButton
                                onClick={() => {
                                    clearStartDate()
                                    dateRangeStore.clearStart()
                                    onChangeRange(
                                        dateRangeStore.getRangeResult()
                                    )
                                }}
                            />
                        </Center>
                    ) : null}
                </CalenderFlexStyled>
                {dateRangePicker && (
                    <CalenderFlexStyled
                        flex="1"
                        onClick={selectingEndDate}
                    >
                        <Flex direction="column">
                            <Text variant="supertitle">To</Text>
                            <InputGroup>
                                <InputLeftElementStyled
                                    pointerEvents="none"
                                    children={
                                        <CalendarIconStyled className="CalendarIcon" />
                                    }
                                />
                                <CalenderInputStyled
                                    size="sm"
                                    variant="calendar"
                                    borderColor={
                                        dateRangeStore.isSelectEndDate
                                            ? 'blue.200'
                                            : 'none'
                                    }
                                    placeholder="Pick or enter..."
                                    disabled={dateRangeStore.isNoEnd}
                                    defaultValue={calendarEndDate?.toLocaleDateString()}
                                />
                            </InputGroup>
                        </Flex>
                        {calendarEndDate ? (
                            <Center marginLeft="10px">
                                <ClearCorssButton
                                    onClick={() => {
                                        clearEndDate()
                                        dateRangeStore.clearEnd()
                                        onChangeRange(
                                            dateRangeStore.getRangeResult()
                                        )
                                    }}
                                />
                            </Center>
                        ) : null}
                    </CalenderFlexStyled>
                )}
            </Flex>
        )
    }
)
