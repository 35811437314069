import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import {
    AuthNoAuth,
    AvailableParameterValue,
    FeatureFlagsGlobal,
    FeatureFlagsTenants,
    IAuthenticationSchema,
    IntegrationChannel,
    IRoutingMapOptions,
    LayoutData,
    Mapping,
    ResourceOwnerAuth,
    Secrets,
    ShippingCost,
    ShortWorkflow,
    UUID,
    WorkflowStatus,
    Wow,
} from './core'

export enum PortType {
    Then = 0,
    Else = 1,
    Default = 0,
}

export enum UiLoginFlow {
    Popup = 'Popup',
    Redirect = 'Redirect',
}

export interface AzureAuth {
    clientId: string
    authorityUrl: string
    uiLoginFlow: UiLoginFlow
}

export interface StepDto {
    layout?: LayoutData
    stepMetaDataId: UUID
}

export interface ChangeWorkflowStatusDto {
    status: WorkflowStatus
}

export interface CreateWorkflowDto {
    name?: string
    description?: string
}

export interface CreateWowDto {
    name?: string
    description?: string
}

export interface CreateWowSimulate {
    name?: string
    workflowIds?: string[]
}

export interface DeleteWowDto {
    id: UUID
}

export interface LayoutDto {
    layout?: LayoutData
}

export interface ParameterValueDto {
    value?: string
}

export interface ParentStepDto {
    parentStepId?: UUID
}

export interface Parameters {
    [key: string]: string
}

export interface WorkflowTriggerRequest {
    workflowId: UUID
    parameters?: Parameters
}

export interface WowTriggerRequest {
    wowId: UUID
    parameters?: Parameters
}

export interface WorkflowIdsValueDto {
    workflows: UUID[]
}

export interface WorkflowValueDto {
    trigger?: string
    name?: string
    status?: WorkflowStatus
}

export interface WorkflowsResponse {
    pageIndex: number
    totalPages: number
    pageSize: number
    totalItems: number
    items: ShortWorkflow[]
}

export interface WowsResponse {
    pageIndex: number
    totalPages: number
    pageSize: number
    totalItems: number
    items: Wow[]
}

export interface PutWorkflowRequest {
    name?: string
    description?: string
    status?: WorkflowStatus
    trigger?: string
}

export interface SubmitForApprovalResponse {
    id: UUID
    status: WorkflowStatus
}

export interface ApproveWorkflowRequest {
    message: string
}

export interface RejectWorkflowRequest {
    message: string
}

export enum UserRole {
    TenantAdmin = 'TenantAdmin',
    SuperAdmin = 'SuperAdmin',
    RulesCreator = 'RulesCreator',
    RulesApprover = 'RulesApprover',
    Support = 'Support',
}

export interface JWTPayload {
    iss: string
    nbf: number
    iat: number
    exp: number
    scope: string[]
    amr: string[]
    client_id: string
    sub: string
    auth_time: number
    idp: string
    role?: UserRole[]
    name: string
    nameidentifier: string
    tenantid: string
    'luc:tenantid': string
    oid: string
    jti: string
}

export interface WorkflowsFilters {
    page: number
    size: number
    utcModifiedFrom: string | Date
    utcModifiedTo: string | Date
    statuses: string
    filter: string
    workflowHeaderId: UUID
    workflowVersion: number
    isInWow: boolean
}

export interface WorkflowAdd {
    name: string
    description?: string
    workflowHeaderId?: string
    basedOnWorkflowId?: string
}

export interface WorkflowsFiltersOptional {
    page?: number
    size?: number
    createdFrom?: string | Date
    createdTo?: string | Date
    statuses?: string
    filter?: string
    workflowHeaderId?: UUID
    workflowVersion?: number
    isInWow?: boolean
}

export interface AvailableValuesResponse {
    pageIndex: number
    totalPages: number
    pageSize: number
    totalItems: number
    items: AvailableParameterValue[]
}

export interface PagingFilters {
    page: number
    size: number
    data?: {
        isSimulated: boolean
    }
}

export interface IFunction {
    appliesTo: number
    isVirtual: boolean
    name: string
    parameters: IParametersFunc[]
    translateTo: string
    usedInLoop: string
}

export interface IParametersFunc {
    defaultValue: string
    name: string
    required: boolean
    supportsExpressions: boolean
}

export interface IOrderFilters {
    page: number
    size: number
    createdFrom: MaterialUiPickersDate
    createdTo: MaterialUiPickersDate
    deliveryFrom: MaterialUiPickersDate
    deliveryTo: MaterialUiPickersDate
    statuses: string[]
    ShipmentProviderCodes: string[]
    orderSourceIds: UUID[]
    orderNumber: string
    isSimulated: boolean
}

export interface ISavedOrderFilters extends IOrderFilters {
    name: string
    createdDate: string
}

export interface IDeletedOrderFilters extends ISavedOrderFilters {
    index: number
}

export interface ShippingCostResponse {
    pageIndex: number
    totalPages: number
    pageSize: number
    totalItems: number
    items: ShippingCost[]
}

export interface ShippingCostRequest {
    dateFrom?: string
    dateTo?: string
    page: number
    size: number
}

export interface IntegrationChannelsResponse {
    pageIndex: number
    totalPages: number
    pageSize: number
    totalItems: number
    items: IntegrationChannel[]
}

export interface ResourceOwnerAuthResponse {
    pageIndex: number
    pageSize: number
    totalPages: number
    totalItems: number
    items: ResourceOwnerAuth[]
}

export interface AuthNoAuthResponse {
    pageIndex: number
    totalPages: number
    pageSize: number
    totalItems: number
    items: AuthNoAuth[]
}

export interface IAuthApiKey {
    name: string
    apiKey: string
    tenantId: string
    id: string
    updatedAt: Date
}

export interface AuthApiKeyResponse {
    pageIndex: number
    pageSize: number
    totalPages: number
    totalItems: number
    items: IAuthApiKey[]
}

export interface SecretsResponse {
    pageIndex: number
    totalPages: number
    pageSize: number
    totalItems: number
    items: Secrets[]
}

export interface MappingsResponse {
    pageIndex: number
    totalPages: number
    pageSize: number
    totalItems: number
    items: Mapping[]
}

export interface FeatureFlagsGlobalResponse {
    pageIndex: number
    totalPages: number
    pageSize: number
    totalItems: number
    items: FeatureFlagsGlobal[]
}

export interface FeatureFlagsTenantsResponse {
    pageIndex: number
    totalPages: number
    pageSize: number
    totalItems: number
    items: FeatureFlagsTenants[]
}

export interface CustomerShipper {
    type: string
    name: string
    code: string
    isEnabled: boolean
    ecommerceCode: string
    orderSourceCode: string
    connectionSettings: string
    methods: CustomerShipmentMethods[]
}

export interface CustomerBaseData {
    name: string
    code: string
    description?: string
}

export interface CustomerBaseDataSettings {
    ecommerceCode: string
    orderSourceCode: string
    connectionSettings?: string
}

export interface CustomerShipmentMethods {
    name: string
    code: string
    description: string
    isEnabled: boolean
    ecommerceCode: string
    orderSourceCode: string
}

export interface AuthenticationSchemaResponse {
    pageIndex: number
    pageSize: number
    totalPages: number
    totalItems: number
    items: IAuthenticationSchema[]
}

export interface IRoutingRuleResponse {
    location: string
    name: string
    ignoreCase: boolean
}

export interface RoutingMapOptionsResponse {
    root: IRoutingMapOptions
}

export interface IRoutingsMap {
    [key: string]: string
}

export interface RoutingMapResponse {
    id: string;
    tenantId: string;
    updatedAt: Date;
    routings: IRoutingsMap
}

export interface OrderFlows {
    name: string
    channelType: string
    mapperType: string
    downstreamUrl: string
    relativeUrl: string
    method: string
}

export interface OrderRetryResponse {
    data: string[],
    reason: string
}
