import { makeAutoObservable } from 'mobx'
import { ParamStore } from './ParamStore'
import { UUID } from 'types/core'

interface IParamStore {
    [key: UUID]: ParamStore
}

export class ParamStores {
    public stores: IParamStore = {}

    constructor() {
        makeAutoObservable(this)
    }

    public async setStore (id: UUID, stepId: UUID) {
        if (!this.stores[id]) {
            this.stores[id] = new ParamStore()
            await this.stores[id].init(id, stepId)
        }
    }
}
