import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react'
import { v4 } from 'uuid'
import { Button } from '@chakra-ui/button'
import { ICard } from 'components/Cards'
import { InputWithHeader } from 'components/InputWithHeader'
import { useInputChange } from 'hooks/useInputChange'
import { deleteEmptyObjectValues } from 'utils/deleteEmptyObjectValues'
import {
    QUANTITY,
    PRODUCT_CODE,
    QUANTITY_UNIT,
    CUSTOMER_PRODUCT_NUMBER,
} from 'constants/virtualOrder'
import { OrderItemsBox, VirtualOrderBoxReverse } from './style'

interface ICreateOrderItem {
    onClose: () => void
    setOrderItem: Dispatch<SetStateAction<IOrderItemState[]>>
    setCards: Dispatch<SetStateAction<ICard[]>>
    orderItems: IOrderItemState[]
}

export interface IOrderItem {
    productCode: string
    quantityUnit: string
    quantity: number
    customerProductNumber: string
}

export interface IOrderItemState extends IOrderItem {
    id: string | number
    orderSourceNumber: string
}

export const CreateOrderItem = ({
    onClose,
    setOrderItem,
    setCards,
    orderItems,
}: ICreateOrderItem) => {
    const {
        value: productCodeValue,
        isError: productCodeIsError,
        inputProps: productCodeProps,
    } = useInputChange({
        isRequired: true,
        maxLength: PRODUCT_CODE,
    })
    const {
        value: quantityValue,
        isError: quantityIsError,
        inputProps: quantityProps,
        onSetIsError: onSetIsErrorQuantity,
        onSetErrorMessage: onSetErrorMessageQuantity,
    } = useInputChange({
        isRequired: true,
        inputType: 'number',
        maxLength: QUANTITY,
    })
    const {
        value: quantityUnitValue,
        isError: quantityUnitIsError,
        inputProps: quantityUnitProps,
    } = useInputChange({ maxLength: QUANTITY_UNIT })
    const {
        value: customerProductNumberValue,
        isError: customerProductNumberIsError,
        inputProps: customerProductNumberProps,
    } = useInputChange({ maxLength: CUSTOMER_PRODUCT_NUMBER })

    const isDisabled = productCodeIsError
        || quantityIsError
        || quantityUnitIsError
        || customerProductNumberIsError

    const orderItemInputs = useMemo(() => [
        {
            inputText: 'Product Code (Required)',
            placeholder: 'Enter Product Code...',
            inputProps: productCodeProps,
        },
        {
            inputText: 'Quantity(Required)',
            placeholder: 'Enter Quantity...',
            inputProps: quantityProps,
        },
        {
            inputText: 'Quantity Unit',
            placeholder: 'Enter Quantity Unit...',
            inputProps: quantityUnitProps,
        },
        {
            inputText: 'Customer Product Number',
            placeholder: 'Enter Customer Product Number...',
            inputProps: customerProductNumberProps,
        },
    ], [
        productCodeProps,
        quantityProps,
        quantityUnitProps,
        customerProductNumberProps,
    ])

    const saveOrderItem = () => {
        const randomNumber = Math.floor(Math.random() * 100)
        const orderItem = {
            id: v4(),
            quantity: +quantityValue,
            productCode: productCodeValue,
            quantityUnit: quantityUnitValue,
            customerProductNumber: customerProductNumberValue,
            orderSourceNumber: `${randomNumber}`,
        }

        const updateOrderItems = [...orderItems, deleteEmptyObjectValues(orderItem)]
        const adaptCards = updateOrderItems.map(({ id, productCode, quantity}) => ({
            id,
            topValue: productCode,
            bottomValue: quantity,
        }))

        setOrderItem(updateOrderItems)
        setCards(adaptCards)
        onClose()
    }

    useEffect(() => {
        if (+quantityValue < 1) {
            onSetIsErrorQuantity(true)
            onSetErrorMessageQuantity('Quantity must be greater than 0')
        } else {
            onSetIsErrorQuantity(false)
            onSetErrorMessageQuantity('')
        }
    }, [quantityValue, onSetIsErrorQuantity, onSetErrorMessageQuantity])

    return (
        <>
            <OrderItemsBox>
                {orderItemInputs.map(({ inputText, placeholder, inputProps}) =>
                    <InputWithHeader
                        key={inputText}
                        inputText={inputText}
                        placeholder={placeholder}
                        inputProps={inputProps}
                    />
                )}
            </OrderItemsBox>
            <VirtualOrderBoxReverse>
                <Button
                    type="submit"
                    variant="blue"
                    isDisabled={isDisabled}
                    onClick={saveOrderItem}
                >
                    Save
                </Button>
                <Button
                    margin="0 16px"
                    type="button"
                    variant="blue"
                    onClick={onClose}
                >
                    Cancel
                </Button>
            </VirtualOrderBoxReverse>
        </>
    )
}
