import React, { FC, useMemo } from 'react'
import { range } from 'lodash'
import { Flex, Text, BoxProps, Center, IconButton } from '@chakra-ui/react'
import { PaginationArrowRightIcon, PaginationArrowLeftIcon } from 'icons'
import { getNumericPagination } from './utils'
import { getShowingItems } from 'utils/getShowingItems'
import { PageButton, PaginationList, PageText, PageDots, ShowingItemsBox } from './styles'

interface NumericPaginationProps extends BoxProps {
    pages: number
    activePage: number
    onChangePage: (page: number) => void
    totalItems?: number
    items?: number
    paginationItems?: number
}

export const NumericPagination: FC<NumericPaginationProps> = ({
    pages,
    activePage,
    onChangePage,
    totalItems,
    items,
    paginationItems = 7,
    ...props
}) => {
    const pagesList = useMemo(() => range(1, pages + 1), [pages])

    if (pages <= 1) return null

    return (
        <Flex justifyContent="center" {...props}>
            {!!totalItems && !!items && (
                <ShowingItemsBox>
                    <Text variant="paragraph">
                        Showing {getShowingItems(totalItems, activePage, items)} of {totalItems}
                    </Text>
                </ShowingItemsBox>
            )}
            <Center>
                {pages > paginationItems && activePage > 1 && (
                    <IconButton
                        aria-label="Pagination Arrow Left Icon"
                        variant="paginationArrows"
                        marginRight="10px"
                        onClick={() => onChangePage(activePage - 1)}
                        icon={
                            <PaginationArrowLeftIcon
                                boxSize="32px"
                                padding="10px"
                                color="color.100"
                            />
                        }
                    />
                )}
                <PaginationList>
                    {getNumericPagination(pagesList, activePage, pages, paginationItems).map(
                        (page, i) => {
                            if (typeof page === 'string') {
                                return (
                                    <PageDots key={i}>
                                        <PageText>{page}</PageText>
                                    </PageDots>
                                )
                            }

                            const isActivePage = page === activePage
                            const color = isActivePage
                                ? 'blue.200'
                                : undefined
                            const colorText = isActivePage ? 'white' : undefined

                            return (
                                <PageButton
                                    key={i}
                                    backgroundColor={color}
                                    _hover={{
                                        backgroundColor: isActivePage ? color : 'blueWhite.960',
                                    }}
                                    onClick={() => onChangePage(page)}
                                >
                                    <PageText color={colorText}>{page}</PageText>
                                </PageButton>
                            )
                        }
                    )}
                </PaginationList>
                {pages > paginationItems && activePage < pages && (
                    <IconButton
                        aria-label="Pagination Arrow Right Icon"
                        variant="paginationArrows"
                        marginLeft="10px"
                        onClick={() => onChangePage(activePage + 1)}
                        icon={
                            <PaginationArrowRightIcon
                                boxSize="32px"
                                padding="10px"
                                color="color.100"
                            />
                        }
                    />
                )}
            </Center>
            {!!totalItems && !!items &&  <Center flex={1} />}
        </Flex>
    )
}
