import { makeAutoObservable } from 'mobx'
import { KnownObject, Mapping } from 'types/core'
import { IFunction, PagingFilters } from 'types/api'
import { IMappingOption } from 'types/core'
import { MappingAPI } from 'api/mapping'
import { notificationStore } from './index'

interface IOpenModals {
    [key: string]: {
        [key: string]: boolean
    }
}

export const defaultFilters: PagingFilters = {
    page: 1,
    size: 10,
}

const gettingAllItemsFilter: PagingFilters = {
    page: 1,
    size: -1
}

const defaultData = {
    id: '',
    name: '',
    requestMappingScheme: {
        type: 'Forward',
        schemeObject: {},
    },
    responseMappingSchemes: {
        default: {
            type: 'Forward',
            schemeObject: {},
        },
    },
}

const defaultOpenModals = {
    req: {
        default: false,
    },
    res: {
        default: false,
    },
}

export class MappingStore {
    public mappingList: Mapping[] = []
    public mappingOptions: IMappingOption[] = []
    public data: Mapping = defaultData
    public copyData: Mapping = defaultData
    public filters: PagingFilters = defaultFilters
    public openModals: IOpenModals = defaultOpenModals
    public function: IFunction[] = []
    public pages: number = 0

    constructor() {
        makeAutoObservable(this)
    }

    public async fetch(id: string, filters = this.filters, isFilters = false) {
        try {
            const response = await MappingAPI.getMappings(id, filters, isFilters)
            this.initMappingList(response.items)
            this.setUpPages(response.totalPages)
        } catch (err) {
            this.initMappingList([])
        }
    }

    public async getMappingByID(tenantId: string, mappingId: string) {
        try {
            const response = await MappingAPI.getMappingById(
                tenantId,
                mappingId,
            )
            this.initData(response)
            this.initCopyData(response)
            this.setModals(response)
        } catch (err) {
            this.initData(defaultData)
            this.initCopyData(defaultData)
        }
    }

    public async getAllMappings(tenantId: string) {
        try {
            const response = await MappingAPI.getMappings(
                tenantId,
                gettingAllItemsFilter,
                true
            )
            this.initMappingList(response.items)
        } catch (err) {
            this.initMappingList([])
        }
    }

    public async getMappingsMetadata() {
        try {
            const response = await MappingAPI.getMappingsMetadata()
            this.initMappingsOptions(response)
        } catch (err) {
            this.initMappingsOptions({} as KnownObject[])
        }
    }

    public async getMappingsFunc() {
        try {
            const response = await MappingAPI.getMappingFunc()
            this.initMappingFunc(response)
        } catch (err) {
            this.initMappingFunc([])
        }
    }

    public async deleteMapping(tenantId: string, mappingId: string) {
        try {
            await MappingAPI.removeMapping(tenantId, mappingId)
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    public async createMapping(tenantId: string, data: Mapping) {
        await MappingAPI.createMapping(tenantId, data)
    }

    public async putMapping(
        tenantId: string,
        mappingId: string,
        data: Mapping,
    ) {
        await MappingAPI.updateMapping(tenantId, mappingId, data)
    }

    public updateDataJson(
        value: object,
        type: string,
        param: string,
    ) {
        if (type === 'req') {
            this.data.requestMappingScheme.schemeObject = value
        } else if (type === 'res') {
            this.data.responseMappingSchemes[param].schemeObject = value
        }
    }

    public updateDataType(value: string, type: string, param: string) {
        if (type === 'req') {
            this.data.requestMappingScheme.type = value
        } else if (type === 'res') {
            this.data.responseMappingSchemes[param].type = value
        }
    }

    public updateCopyDataJson(
        value: object,
        type: string,
        param: string,
    ) {
        if (type === 'req') {
            this.copyData.requestMappingScheme.schemeObject = value
        } else if (type === 'res') {
            this.copyData.responseMappingSchemes[param].schemeObject = value
        }
    }

    public updateCopyDataType(value: string, type: string, param: string) {
        if (type === 'req') {
            this.copyData.requestMappingScheme.type = value
        } else if (type === 'res') {
            this.copyData.responseMappingSchemes[param].type = value
        }
    }

    public addResField(name: string) {
        this.data.responseMappingSchemes[name] = { type: 'Forward', schemeObject: {} }
        this.openModals.res[name] = false
    }

    public delResField(name: string) {
        const data = this.data
        delete data?.responseMappingSchemes[name]
        this.data = data
    }

    public changeName(name: string) {
        this.data.name = name
    }

    public changeOpenModal(type: string, param: string) {
        if (
            this.openModals[type] &&
            this.openModals[type][param] !== undefined
        ) {
            this.openModals = {
                ...this.openModals,
                [type]: { [param]: !this.openModals[type][param] },
            }
        }
    }

    private setModals(response: Mapping) {
        let modals = this.openModals
        Object.keys(response?.responseMappingSchemes).forEach(el => {
            return (modals = { ...modals, res: { ...modals.res, [el]: false } })
        })
        this.openModals = modals
    }

    private initMappingList(items: Mapping[]) {
        this.mappingList = items.length ? items : []
    }

    private initMappingFunc(items: IFunction[]) {
        this.function = items.length ? items : []
    }

    public initData(response: Mapping) {
        this.data = response || defaultData
    }

    private initCopyData(response: Mapping) {
        this.copyData = response || defaultData
    }

    private initMappingsOptions(options: KnownObject[]) {
        this.mappingOptions = options.map(option => {
            return {
                label: option.name,
                value: JSON.stringify(option.schemeObject),
            }
        })
    }

    private setUpPages(totalPages: number) {
        this.pages = totalPages
    }

    public cleanData() {
        this.initData(defaultData)
        this.initCopyData(defaultData)
    }

    public setFilters(filters: Partial<PagingFilters>) {
        if (!filters.page) {
            this.filters = { ...this.filters, ...filters, page: 1 }
        } else {
            this.filters = { ...this.filters, ...filters }
        }
    }
}
