import React, { ChangeEvent, FC } from 'react'
import { Switch as ChakraSwitch } from '@chakra-ui/react'

interface SwitchProps {
    value: string
    isDraft: boolean
    onChange: (newValue: string) => void
}

export const Switch: FC<SwitchProps> = ({
    value,
    isDraft,
    onChange,
    ...props
}) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.checked.toString())

    return (
        <ChakraSwitch
            isDisabled={!isDraft}
            variant="switchBoolean"
            defaultChecked={value === 'true'}
            onChange={handleChange}
            {...props}
        />
    )
}
