import React, { FC } from 'react'
import { CrossSmallIcon } from 'icons'
import { CenterClearDateStyled } from '../../styles'

interface ClearCorssButtonProps {
    onClick: () => void
}

export const ClearCorssButton: FC<ClearCorssButtonProps> = ({ onClick }) => {
    return (
        <CenterClearDateStyled onClick={onClick}>
            <CrossSmallIcon boxSize="10px" color="gray.710" />
        </CenterClearDateStyled>
    )
}
