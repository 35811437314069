import React, { FC, useEffect, useRef, useState } from 'react'
import {
    Box,
    Center,
    Flex,
    IconButton,
    Spacer,
    Stack,
    Text,
} from '@chakra-ui/react'
import { toJS } from 'mobx'
import { ConfirmModal } from 'components/Modal/ConfirmModal'
import { RuleStatusTag } from 'components/RuleStatusTag'
import { userStore } from 'stores'
import { WorkflowsAPI } from 'api/workflows'
import { ShortWorkflow } from 'types/core'
import { EyeIcon, PencilIcon, TrashCanCloseIcon } from 'icons'
import { CardBox, InfoBox, ViewButton } from './styles'

interface MyRuleCardProps {
    workflow: ShortWorkflow
    onEdit: () => void
    onDelete: () => void
    onChange: () => void
    onView: () => void
}

export const MyRuleCard: FC<MyRuleCardProps> = ({
    workflow,
    onEdit,
    onDelete,
    onView,
    onChange,
}) => {
    const [image, setImage] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const mountedRef = useRef(true)
    const { isCreator } = toJS(userStore.currentRoles)

    const modifiedDate = new Date(workflow.modifiedAt).toLocaleDateString('en')

    useEffect(() => {
        WorkflowsAPI.getPreview(workflow.id).then(img => {
            if (!mountedRef.current) return null
            setImage(`url('${img}')`)
        })

        return () => {
            mountedRef.current = false
        }
    }, [workflow.id])

    const onClose = () => setIsOpen(false)
    return (
        <CardBox onClick={onEdit} backgroundImage={image}>
            <Flex direction="column" height="100%" width="100%">
                <Spacer />
                <InfoBox>
                    <Flex>
                        <Text variant="h5" isTruncated>
                            {workflow.name}
                        </Text>
                        <Spacer />
                        {isCreator && (
                            <Stack direction="row">
                                <Center
                                    onClick={e => {
                                        e.stopPropagation()
                                        onChange()
                                    }}
                                >
                                    <IconButton
                                        aria-label="Pencil Icon"
                                        variant="hoveredMediumWhite"
                                        icon={
                                            <PencilIcon
                                                padding="6px"
                                                boxSize="24px"
                                            />
                                        }
                                    />
                                </Center>
                                <Center
                                    onClick={e => {
                                        e.stopPropagation()
                                        setIsOpen(true)
                                    }}
                                >
                                    <IconButton
                                        aria-label="Cross Small Icon"
                                        variant="hoveredMediumWhite"
                                        icon={
                                            <TrashCanCloseIcon
                                                padding="5px"
                                                boxSize="25px"
                                            />
                                        }
                                    />
                                </Center>
                            </Stack>
                        )}
                    </Flex>
                    <Box marginTop="8px">
                        <Text variant="paragraphsmall" isTruncated>
                            {workflow.description}
                        </Text>
                    </Box>
                    <Flex marginTop="8px">
                        <Center>
                            <Text variant="xstext">
                                Modified: {modifiedDate} - v{workflow.version}
                            </Text>
                        </Center>
                        <Spacer />
                        <RuleStatusTag status={workflow.status} />
                    </Flex>
                </InfoBox>
            </Flex>
            <ViewButton
                onClick={e => {
                    e.stopPropagation()
                    onView()
                }}
            >
                <EyeIcon boxSize="15px" color="orderTracking.200" />
            </ViewButton>
            <ConfirmModal
                isOpen={isOpen}
                onClose={onClose}
                confirmFunc={onDelete}
                headerText="Are you sure?"
                bodyText={`Are you sure you'd like to delete this Rule "${workflow.name}". This cannot be undone.`}
                buttonText="Yes, Delete"
                colorButton="red.500"
            />
        </CardBox>
    )
}
