import { CellData } from 'components/DateRangePicker/types'
import React, { FC } from 'react'
import { DefaultCell } from './DefaultCell'
import { DisabledCell } from './DisabledCell'
import { GradientCell } from './GradientCell'
import { OffsetCell } from './OffsetCell'
import { RangedCell } from './RangedCell'
import { SelectedCell } from './SelectedCell'
import { TodayCell } from './TodayCell'

interface DayCellProps {
    cellData: CellData
    selectDate: (date: Date) => void
}

export const DayCell: FC<DayCellProps> = ({ cellData, selectDate }) => {
    switch (cellData.type) {
        case 'offset':
            return <OffsetCell />
        case 'gradient':
            return <GradientCell cellData={cellData} />
        case 'disabled':
            return <DisabledCell cellData={cellData} />
        case 'default':
            return <DefaultCell selectDate={selectDate} cellData={cellData} />
        case 'today':
            return <TodayCell selectDate={selectDate} cellData={cellData} />
        case 'selected':
            return <SelectedCell selectDate={selectDate} cellData={cellData} />
        case 'ranged':
            return <RangedCell selectDate={selectDate} cellData={cellData} />
        default:
            return <OffsetCell />
    }
}
