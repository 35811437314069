import React from 'react'
import { observer } from 'mobx-react-lite'
import { Center, HStack } from '@chakra-ui/react'
import { shippingCostStore } from 'stores'
import { PaginationButton } from '../styles'

export const ShipingCostPagination = observer(() => {
    if (shippingCostStore.totalPages < 2) return null

    const pages = new Array(shippingCostStore.totalPages).fill(true)

    return (
        <Center marginTop="24px">
            <HStack spacing="12px">
                {pages.map((page, index) => {
                    const isSelected =
                        index + 1 === shippingCostStore.currentPage

                    return (
                        <PaginationButton
                            key={index}
                            bgColor={isSelected ? 'denim' : 'gray.400'}
                            onClick={() => {
                                shippingCostStore.setPage(index + 1)
                            }}
                        />
                    )
                })}
            </HStack>
        </Center>
    )
})
