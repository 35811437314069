import { AzureAuthService } from 'services/azureAuth'

export const onLogOut = async () => {
    if (localStorage.getItem('authType') === 'azure_ad')
        await AzureAuthService.logOut()
    localStorage.removeItem('authToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('authType')
    localStorage.removeItem('clientId')
    localStorage.removeItem('authorityUrl')
    localStorage.removeItem('uiLoginFlow')
    localStorage.removeItem('orderFilters')
    document.location.reload()
}
