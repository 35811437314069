import { makeAutoObservable } from 'mobx'
import { v4 } from 'uuid'
import { IRoutingMapOptions, ISelectOption, UUID } from 'types/core'
import { notificationStore } from './index'
import { tenantsInstance } from 'api/instances'
import { StatusType } from 'types/chart'
import { IRoutingsMap, RoutingMapResponse } from 'types/api'
import { TenantsApi } from 'api/tenants'

export class RoutingMapStore {
    public routingMap: string[][] = []
    public defaultRoutingMap: string[][] = []
    public routingMapOptions: ISelectOption[] = []

    constructor() {
        makeAutoObservable(this)
    }

    public async fetchRoutingMap(tenantId: UUID) {
        try {
            const {
                data: { routings },
            } = await tenantsInstance.get<RoutingMapResponse>(
                `${tenantId}/routing/map`
            )

            this.initRoutingMap(routings)
        } catch (e) {
            this.routingMap = []
            this.defaultRoutingMap = []
        }
    }

    public async fetchRoutingMapOptions(tenantId: UUID) {
        try {
            const data = await TenantsApi.getRoutingMapOptions(tenantId)

            this.initRoutingMapParentOption(data.root)
            this.initRoutingMapChildOptions(data.root)
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    public async updateRoutingsMap(tenantId: UUID) {
        const updatedData = this.routingMap.map(routing => {
            const inputValue = routing[1]
            const selectValue = routing[2]
            return [inputValue, selectValue]
        })
        const data = Object.fromEntries(updatedData)

        await tenantsInstance.put(`${tenantId}/routing/map`, data)

        this.defaultRoutingMap = this.routingMap
    }

    public addNewRow(id: UUID) {
        const updatedRoutingMap = [...this.routingMap]
        const inputValue = ''
        const selectValue = this.routingMapOptions[0].value || ''

        updatedRoutingMap.push([id, inputValue, selectValue])

        this.routingMap = updatedRoutingMap
    }

    public validateSelectedInput(value: string, id: UUID) {
        let isUniqueValue = true

        this.routingMap.forEach(routing => {
            const routingId = routing[0]
            const inputValue = routing[1]
            const isCurrentInput = routingId === id

            if (value === inputValue && !isCurrentInput) {
                notificationStore.toast({
                    title: 'Error',
                    description: 'Key must be unique',
                    status: StatusType.Error,
                })
                isUniqueValue = false
            }
        })

        return isUniqueValue
    }

    public updateSelectedInput(value: string, id: UUID) {
        this.routingMap = this.routingMap.map(routing => {
            const routingId = routing[0]
            const selectValue = routing[2]

            if (routingId === id) {
                return [routingId, value, selectValue]
            }

            return routing
        })
    }

    public updateSelectedChild(id: UUID, selectedValue: string) {
        this.routingMap = this.routingMap.map(routing => {
            const routingId = routing[0]
            const inputValue = routing[1]

            if (routingId === id) {
                return [routingId, inputValue, selectedValue]
            }
            return routing
        })
    }

    public deleteSelectedRoutingMap(id: UUID) {
        this.routingMap = this.routingMap.filter(routing => {
            const routingId = routing[0]

            return routingId !== id
        })
    }

    private initRoutingMap(routings: IRoutingsMap) {
        const updatedData = Object.entries(routings).map(routing => {
            const id = v4()
            return [id, ...routing]
        })

        this.defaultRoutingMap = updatedData
        this.routingMap = updatedData
    }

    private initRoutingMapParentOption(tenant: IRoutingMapOptions) {
        this.routingMapOptions.push({
            value: tenant.id,
            label: tenant.name,
        })
    }

    private initRoutingMapChildOptions(tenant: IRoutingMapOptions) {
        if (!tenant.tenants.length) return

        tenant.tenants.forEach(({ tenants, name, id }) => {
            this.routingMapOptions.push({
                value: id,
                label: name,
            })

            this.initRoutingMapChildOptions({ ...tenant, tenants })
        })
    }

    public clean() {
        this.routingMapOptions = []
        this.routingMap = []
        this.defaultRoutingMap = []
    }
}
