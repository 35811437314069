import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const ArrowRightUpIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 14 13"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M11.0054 3.414L2.39838 12.021L0.984375 10.607L9.59037 2H2.00538V0H13.0054V11H11.0054V3.414Z"
                fill="currentColor"
            />
        </Icon>
    )
}
