import { configurationsInstance } from './instances'
import { IGetWowOrderSourceMappings, IPutWowOrderSourceMappings } from 'types/core'

export class ConfigurationsAPI {
    static async getWowOrderSourceMappings(): Promise<IGetWowOrderSourceMappings> {
        const { data = {} } = await configurationsInstance.get('WowOrderSourceMappings')
        return data
    }

    static async putWowOrderSourceMappings(data: IPutWowOrderSourceMappings): Promise<void> {
        await configurationsInstance.put(
            'WowOrderSourceMappings',
            data,
        )
    }
}
