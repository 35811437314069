import React, { FC } from 'react'
import { useLocation } from 'react-router-dom'
import {
    Box,
    BoxProps,
    Container,
    ContainerProps,
    Flex,
    FlexProps,
} from '@chakra-ui/layout'
import { Outlet } from 'react-router'
import { Header } from 'components/Header'
import { Sidebar } from 'components/Sidebar'
import { routes } from '../navigation/routes'

interface BaseLayoutProps {
    baseBox?: FlexProps
    headerBox?: BoxProps
    contentBox?: ContainerProps
}

export const BaseLayout: FC<BaseLayoutProps> = ({
    baseBox = {},
    contentBox = {},
    headerBox = {},
}) => {
    const { pathname } = useLocation()
    const isAuthorized = localStorage.getItem('authToken')

    return (
        <Flex
            direction="column"
            background="blue.990"
            _light={{
                background: 'blue.100',
            }}
            {...baseBox}
        >
            <Box zIndex="13" as="header" {...headerBox}>
                <Header />
            </Box>
            <Flex overflowY="auto">
                {pathname !== routes.forgotPassword && pathname !== routes.login && <Sidebar />}
                <Container
                    maxWidth="1440px"
                    padding={isAuthorized ? '0 10px 0 90px' : '10px'}
                    {...contentBox}
                >
                    <Outlet />
                </Container>
            </Flex>
        </Flex>
    )
}
