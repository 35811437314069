import React from 'react'
import { startCase } from 'lodash'
import { AccordionOrderShippedPanel } from './content/Accordion/AccordionOrderShippedPanel'
import { CustomAccordion } from 'components/baseComponents/Accordion'
import { AccordionButton } from './content/Accordion/AccordionButton'
import { AccordionPanel } from './content/Accordion/AccordionPanel'
import { AccordionInfo } from './content/AccordionInfo'
import { auditOrderStatuses } from './mock'
import { IAuditOrder } from 'types/core'

export const transformStatus = (description?: string) => {
    if (!description) return '-'
    return `Order ${startCase(description)}`
}

export const renderAccordion = (auditOrder: IAuditOrder, opened: number[]) => {
    const { status: { code } = {} } = auditOrder
    const orderTitle =
        code && auditOrderStatuses[code] ? auditOrderStatuses[code].title : ''
    const orderStatus =
        code && auditOrderStatuses[code] ? auditOrderStatuses[code].status : ''


    if (code === 0) {
        return (
            <AccordionInfo
                order={auditOrder}
                title={orderTitle}
                status={orderStatus}
            />
        )
    }
    if (code === 100) {
        return (
            <CustomAccordion
                width="100%"
                AccordionButtonContent={
                    <AccordionButton
                        title={orderTitle}
                        status={orderStatus}
                        isOpened={opened.includes(0)}
                        order={auditOrder}
                    />
                }
                AccordionPanelContent={
                    <AccordionPanel isOpened={opened.includes(0)} />
                }
            />
        )
    }
    // TODO change status here when api will be ready
    if (code === 2000) {
        return (
            <CustomAccordion
                width="100%"
                AccordionButtonContent={
                    <AccordionButton
                        order={auditOrder}
                        title={orderTitle}
                        status={orderStatus}
                        isOpened={opened.includes(1)}
                    />
                }
                AccordionPanelContent={<AccordionOrderShippedPanel />}
            />
        )
    }
    if (code && code < 1000) {
        return (
            <AccordionInfo
                order={auditOrder}
                title={orderTitle}
                status={orderStatus}
            />
        )
    }
    if (code && code >= 1000 && code < 2000) {
        return (
            <AccordionInfo
                error
                order={auditOrder}
                title={orderTitle}
                status={orderStatus}
            />
        )
    }

    return null
}
