import React, { FC } from 'react'
import { format } from 'date-fns'
import { observer } from 'mobx-react-lite'
import { Center, IconButton } from '@chakra-ui/react'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { dateFormat } from '../mock'
import { CrossSmallIcon } from 'icons'
import { orderStore } from 'stores'
import { SelectedFilter, FilterName, FilterValues } from './styles'

interface ISelectedDate {
    from: MaterialUiPickersDate
    to: MaterialUiPickersDate
    filterName: string
    isSavedFilters: boolean
    orderNumber: string
}

export const SelectedDate: FC<ISelectedDate> = observer(({
    from,
    to,
    filterName,
    isSavedFilters,
    orderNumber,
}) => {
    const getFormattedDate = (date?: MaterialUiPickersDate) => {
        return date && new Date(date).toString() !== 'Invalid Date'
            ? format(new Date(date), dateFormat)
            : 'Not chosen'
    }

    const handleClear = async () => await orderStore.clearFilters(filterName)

    return (
        <SelectedFilter backgroundColor={orderNumber ? 'color.225' : 'blueWhite.990'}>
            <FilterName>
                {filterName}:
            </FilterName>
            <FilterValues>
                {getFormattedDate(from)} - {getFormattedDate(to)}
            </FilterValues>
            {!isSavedFilters && (
                <Center>
                    <IconButton
                        onClick={handleClear}
                        aria-label="Order Filter Cross Small Icon"
                        variant="hoveredMediumWhite"
                        marginLeft="10px"
                        icon={
                            <CrossSmallIcon padding="3px" boxSize="20px" />
                        }
                    />
                </Center>
            )}
        </SelectedFilter>
    )
})
