import { Flex, HStack, Text, Center } from '@chakra-ui/react'
import { chakra } from 'utils/chakra'

export const PageButton = chakra(Flex, {
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    cursor: 'pointer',
})

export const PageDots = chakra(Flex, {
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    width: '32px',
    height: '32px',
    cursor: 'default',
})

export const ShowingItemsBox = chakra(Center, {
    flex: 1,
    justifyContent: 'flexStart',
})

export const PaginationList = chakra(HStack, {
    spacing: '6px',
})

export const PageText = chakra(Text, {
    variant: 'paragraph',
})
