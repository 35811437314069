import React, { FC } from 'react'
import { NodeProps, Position } from 'react-flow-renderer'
import { HandleComponentProps } from 'react-flow-renderer/dist/components/Handle'
import { NodeData } from 'types/chart'
import { NodeLayout } from './NodeLayout'

const ports: HandleComponentProps[] = [
    {
        type: 'target',
        position: Position.Left,
    },
    {
        type: 'source',
        position: Position.Right,
    },
]

export const TransferNode: FC<NodeProps<NodeData>> = ({
    data: { step, readonly },
}) => {
    return <NodeLayout step={step} ports={ports} readonly={readonly} />
}
