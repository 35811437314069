import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const TrashCanCloseIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 12 15"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M1.40749 12.6338C1.40749 13.4486 2.07416 14.1153 2.88897 14.1153H8.8149C9.62971 14.1153 10.2964 13.4486 10.2964 12.6338V5.22643C10.2964 4.41161 9.62971 3.74495 8.8149 3.74495H2.88897C2.07416 3.74495 1.40749 4.41161 1.40749 5.22643V12.6338ZM3.62971 5.22643H8.07416C8.48156 5.22643 8.8149 5.55976 8.8149 5.96717V11.8931C8.8149 12.3005 8.48156 12.6338 8.07416 12.6338H3.62971C3.2223 12.6338 2.88897 12.3005 2.88897 11.8931V5.96717C2.88897 5.55976 3.2223 5.22643 3.62971 5.22643ZM3.75193 6.83754C4.04055 6.54892 4.50831 6.54837 4.79761 6.83631L5.85193 7.88569L6.90008 6.83754C7.1885 6.54912 7.65611 6.54912 7.94453 6.83754C8.23294 7.12595 8.23294 7.59357 7.94453 7.88198L6.89638 8.93013L7.94453 9.97828C8.23294 10.2667 8.23294 10.7343 7.94453 11.0227C7.65611 11.3111 7.1885 11.3111 6.90008 11.0227L5.85193 9.97458L4.80378 11.0227C4.51537 11.3111 4.04776 11.3111 3.75934 11.0227C3.47093 10.7343 3.47093 10.2667 3.75934 9.97828L4.80749 8.93013L3.75316 7.88075C3.75275 7.88035 3.75234 7.87994 3.75193 7.87953C3.4642 7.59179 3.4642 7.12528 3.75193 6.83754ZM7.40008 0.781982C7.59267 0.781982 7.78527 0.863464 7.9186 0.996797L8.44453 1.52272H10.2964C10.7038 1.52272 11.0371 1.85606 11.0371 2.26346C11.0371 2.67087 10.7038 3.0042 10.2964 3.0042H1.40749C1.00008 3.0042 0.666748 2.67087 0.666748 2.26346C0.666748 1.85606 1.00008 1.52272 1.40749 1.52272H3.25934L3.78527 0.996797C3.9186 0.863464 4.11119 0.781982 4.30378 0.781982H7.40008Z"
                fill="currentColor"
            />
        </Icon>
    )
}
