import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const UserPagesIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 20.18 20.18"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g id="a" />
            <g id="b">
                <g id="c">
                    <g id="d">
                        <path fill="currentColor" d="M13.08,11.46c.74-.75,1.2-1.78,1.2-2.92,0-2.31-1.88-4.18-4.18-4.18s-4.18,1.88-4.18,4.18c0,1.14,.46,2.16,1.2,2.92-2.06,1.08-3.47,3.24-3.47,5.72v.18c0,.31,.15,.6,.39,.79,1.76,1.34,3.8,2.02,6.06,2.02s4.31-.68,6.06-2.02c.25-.19,.39-.48,.39-.79v-.18c0-2.48-1.41-4.64-3.47-5.72Zm-2.99-5.1c1.2,0,2.18,.98,2.18,2.18s-.98,2.18-2.18,2.18-2.18-.98-2.18-2.18,.98-2.18,2.18-2.18Zm0,11.82c-1.64,0-3.14-.44-4.44-1.32,.17-2.31,2.1-4.13,4.44-4.13s4.28,1.83,4.44,4.13c-1.31,.88-2.8,1.32-4.44,1.32Z" />
                        <path fill="currentColor" d="M10.09,0C4.53,0,0,4.53,0,10.09c0,1.5,.31,2.93,.91,4.24,.23,.5,.82,.72,1.33,.49,.5-.23,.72-.83,.49-1.33-.48-1.05-.73-2.19-.73-3.4C2,5.63,5.63,2,10.09,2s8.09,3.63,8.09,8.09c0,1.21-.24,2.35-.73,3.4-.23,.5-.01,1.1,.49,1.33,.14,.06,.28,.09,.42,.09,.38,0,.74-.22,.91-.58,.6-1.31,.91-2.74,.91-4.24C20.18,4.53,15.66,0,10.09,0Z" />
                    </g>
                </g>
            </g>
        </Icon>
    )
}
