import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { useToast } from '@chakra-ui/toast'
import { UndoToast } from './UndoToast'
import { ISavedOrderFilters } from 'types/api'
import { orderStore } from 'stores'
import { DeleteButtonStyled } from './styles'

interface IDeleteButton {
    filter: ISavedOrderFilters
}

export const DeleteButton: FC<IDeleteButton> = observer(({ filter }) => {
    const toast = useToast()

    const handleClose = (toastId: string | number) => toast.close(toastId)

    return (
        <DeleteButtonStyled
            onClick={() => {
                toast({
                    render: ({ id }) => (
                        <UndoToast
                            filterName={filter.name}
                            handleClose={() => handleClose(id)}
                        />
                    )
                })
                orderStore.deleteSavedFilter(filter.name)
            }}
        >
            Delete
        </DeleteButtonStyled>
    )
})
