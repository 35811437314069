import { chakra } from 'utils/chakra'
import { BaseLayout } from './BaseLayout'

export const WebPageLayout = chakra(BaseLayout, {
    baseBox: {
        minHeight: '100vh',
        height: '100%',
        overflowX: 'auto',
    },
    headerBox: {
        position: 'sticky',
        top: '0px',
        zIndex: '13',
        minWidth: 'min-content',
    },
    contentBox: {
        flex: 'auto',
    },
})
