import { AdaptSelectDataOption } from 'types/chart'

export const adaptSelectData = (data: AdaptSelectDataOption[], method: string) => {
    switch (method) {
        case 'serverData':
            return data.map(
                ({ key, displayValue }) => ({ value: key, label: displayValue })
            )
        case 'selectData':
            return JSON.stringify(data.map(({ value }) => value))
        default:
            return []
    }
}

export const separateComma = (data: AdaptSelectDataOption[]) => {
    if (!Array.isArray(data)) return ''
    return data.map(({ value }) => value).toString()
}
