import { Box,  } from '@chakra-ui/layout'
import { chakra } from 'utils/chakra'

export const DateBox = chakra(Box, {
    paddingLeft: 5,
    height: '50px'
})

export const ReasonBox = chakra(Box, {
    marginRight: 35,
    height: '50px'
})
