import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const RectangleGreenIcon: FC<IconProps> = props => {
    return (
        <Icon
            width="3.5"
            height="12"
            viewBox="0 0 15 44"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect x="-17.0752" width="32" height="44" rx="8" fill="#10AD10"/>
        </Icon>


    )
}
