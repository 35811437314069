import { isEqual } from 'lodash'
import { makeAutoObservable } from 'mobx'
import { compareAsc, format } from 'date-fns'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { OrderRetryAPI } from 'api/orderRetry'
import { OrderClientAPI } from 'api/orderClient'
import { isInvalid } from 'pages/HomePage/content/OrderStatusTracking/Filters/utils'
import { statuses } from 'pages/HomePage/content/OrderStatusTracking/Filters/ShowFilters/mock'
import { orderErrorStatuses } from 'components/RetryCheckbox/mock'
import { notificationStore, orderStore } from 'stores'
import { WowsAPI } from 'api/wows'
import { simulateStatusPage } from 'constants/ordersStatus'
import { adaptationGMT } from 'utils/adaptationGMT'
import { StatusType } from 'types/chart'
import { IDeletedOrderFilters, IOrderFilters, ISavedOrderFilters } from 'types/api'
import {
    IOpenTabDetails,
    IOrder,
    IOrderDistributionSource,
    IOrderExternalSource,
    IOrderRelationApi,
    IOrderShipment,
    IOrderStatus,
    IPackage,
    ISimulateOrder,
    ItemShippingOrder,
    UUID,
} from 'types/core'

const defaultFilters: IOrderFilters = {
    page: 1,
    size: 10,
    createdFrom: null,
    createdTo: null,
    deliveryFrom: null,
    deliveryTo: null,
    orderNumber: '',
    statuses: [],
    ShipmentProviderCodes: [],
    orderSourceIds: [],
    isSimulated: false,
}

const defaultEditFilters = {
    ...defaultFilters,
    name: '',
    createdDate: '',
}

export class OrderStore {
    public orders: IOrder[] = []
    public orderInfo: IOrder = {} as IOrder
    public itemsShippingOrder: ItemShippingOrder[] = []
    public virtualOrderId: string = ''
    public creationVirtualOrderId: string = ''
    public simulateOrderId: string = ''
    public filters: IOrderFilters = defaultFilters
    public editFilters: ISavedOrderFilters = defaultEditFilters
    public localFilters: IOrderFilters = defaultFilters
    public savedFilters: ISavedOrderFilters[] = []
    public deletedFilters: IDeletedOrderFilters[] = []
    public pages: number = 0
    public totalItems: number = 0
    public status: IOrderStatus[] = statuses
    public distributionSource: IOrderDistributionSource[] = []
    public externalSource: IOrderExternalSource[] = []
    public loading: boolean = false
    public checkErrorStatusFlag: string = ''
    public isDasboardStatus:boolean = false
    public isCheckCountErrorStatus: number = 0
    public isSplitCounter: number = 0
    public orderRelations: IOrderRelationApi[] = []
    public childOrders: IOrder[] = []
    public childClickOrders: IOrder | undefined
    public childrenCompleteData: IOrder[] = []
    public childOrderErrors: IOrder[] = []
    public paginationNumber: number = 0
    public openTabDetails: IOpenTabDetails[] = []

    constructor() {
        makeAutoObservable(this)
    }
    public initStatus =(isStatus:boolean)=>{
        this.isDasboardStatus = isStatus
    }
    public checkCountErrorStatus = (isCheckErrorCount: number) => {
        this.isCheckCountErrorStatus = isCheckErrorCount
    }
    public splitCounter = (isCheckSplitCount: number) => {
        this.isSplitCounter = isCheckSplitCount
    }
    public getSelectedTabSpliteOrders() {
        this.openTabDetails?.forEach((openTab, index) => {
            this.orders?.forEach((order) => {
                if (openTab.childId === order.id) {
                    this.splitOrderHierarchy(order, 'SplitOrder', index)
                }
            })
        })
    }

    public async search(isSimulated?: boolean): Promise<void> {
        const adaptFilters = this.localFilters.orderNumber
            ? {
                  ...defaultFilters,
                  orderNumber: this.localFilters.orderNumber,
              }
            : {
                  ...this.localFilters,
                  createdFrom: adaptationGMT(
                      this.localFilters.createdFrom,
                      'from'
                  ),
                  createdTo: adaptationGMT(this.localFilters.createdTo, 'to'),
                  deliveryFrom: adaptationGMT(
                      this.localFilters.deliveryFrom,
                      'from'
                  ),
                  deliveryTo: adaptationGMT(this.localFilters.deliveryTo, 'to'),
              }

        try {
            this.initLoading(true)
            const {
                items: orders = [],
                totalPages,
                totalItems,
            } = await OrderClientAPI.searchOrder(adaptFilters, isSimulated)

            this.initLoading(false)
            this.init(orders)
            this.setUpPages(totalPages)
            this.setUpTotalItems(totalItems)
        } catch (err) {
            this.initLoading(false)
            if (!isEqual(this.filters, defaultFilters)) {
                this.initFilters(defaultFilters)
                this.initLocalFilters(this.filters)
                await this.search()
            }
            notificationStore.triggerErrorToast(err)
        }
    }

    public async searchSimulate(): Promise<void> {
        const filters = {
            ...this.filters,
            orderNumber: this.localFilters.orderNumber,
            statuses: simulateStatusPage,
        }

        try {
            if (this.childClickOrders) this.initLoading(false)
            else this.initLoading(true)
            const {
                items: orders = [],
                totalPages,
                totalItems,
            } = await OrderClientAPI.searchOrder(filters, true)

            this.initLoading(false)
            this.init(orders)
            this.setUpPages(totalPages)
            this.setUpTotalItems(totalItems)
        } catch (err) {
            this.initLoading(false)
            if (!isEqual(this.filters, defaultFilters)) {
                this.initFilters(defaultFilters)
                this.initLocalFilters(this.filters)
                await this.search()
            }
            notificationStore.triggerErrorToast(err)
        }
    }

    public async searchErrors(statuses: string[] ): Promise<IOrder[]> {
        const localFilters = localStorage.getItem('orderFilters')

        if (localFilters) {
            try {
                 statuses = JSON.parse(localFilters).statuses
            } catch (err) {
                notificationStore.triggerErrorToast(err)
            }
        }

        const errorFilters =  {
            ...defaultFilters,
            page: 1,
            statuses: statuses
        }

        const retryOrders: IOrder[] = []
        try {
            this.initLoading(true)
            while(errorFilters.page <= this.pages && errorFilters.page <= 10) {
                const {
                    items: orders = [],
                } = await OrderClientAPI.searchOrder(errorFilters)
                retryOrders.push(...orders)
                errorFilters.page += 1
            }
            retryOrders.push(...this.childOrderErrors)
            this.initLoading(false)
            return retryOrders
        } catch (err) {
            this.initLoading(false)
            notificationStore.triggerErrorToast(err)
            return retryOrders
        }
    }

    public async searchByNumber(value: string): Promise<void> {
        this.initFilters({ ...this.filters, orderNumber: value })
        this.initLocalFilters(this.filters)
        await this.search()
        localStorage.setItem('orderFilters', JSON.stringify(this.filters))
    }

    public async applyFilters(isSimulated?: boolean): Promise<void> {
        this.initLocalFilters({ ...this.filters, page: 1 })
        await this.search(isSimulated)
        localStorage.setItem('orderFilters', JSON.stringify(this.localFilters))
    }

    public setAllFilters(filters: IOrderFilters): void {
        this.initFilters(filters)
    }

    public setLocalFilters(): void {
        try {
            const localFilters = localStorage.getItem('orderFilters')
            this.initLocalFilters(
                localFilters ? JSON.parse(localFilters) : defaultFilters
            )
        } catch (err) {
            this.initLocalFilters(defaultFilters)
        }
    }

    public setEditFilters(filter: ISavedOrderFilters): void {
        this.initEditFilters(filter)
    }

    public isFiltersChanged(): boolean {
        return !isEqual(
            JSON.stringify(this.localFilters), JSON.stringify(this.filters)
        )
    }

    public isEditFiltersChanged(): boolean {
        const savedFilter = this.savedFilters.find(
            ({ name }) => name === this.editFilters.name
        )

        return !isEqual(this.editFilters, savedFilter)
    }

    public isDateInvalid(isEditFilters?: boolean): boolean {
        const { createdFrom, createdTo, deliveryFrom, deliveryTo }
            = isEditFilters ? this.editFilters : this.filters

        const isInvalidDate = (dateFrom: MaterialUiPickersDate, dateTo: MaterialUiPickersDate) => {
            if (isInvalid(dateFrom) || isInvalid(dateTo)) return false

            return !!((dateFrom && dateTo) && new Date(format(new Date(dateFrom), 'MM/dd/yyyy')) > dateTo)
        }

        return isInvalidDate(createdFrom, createdTo) || isInvalidDate(deliveryFrom, deliveryTo)
    }

    public async searchById(id: UUID): Promise<IOrder> {
        const orderInfo = await OrderClientAPI.searchOrderById(id)
        this.initOrderInfo(orderInfo)
        await this.getOrderSources()
        await this.getItemsShippingInfo(orderInfo)
        return orderInfo
    }

    public async createVirtualOrder(
        id: string,
        data: ISimulateOrder
    ): Promise<boolean | undefined> {
        const simulateId = await OrderClientAPI.createVirtualOrder(id, data)
        this.initVirtualOrderId(simulateId.id)
        return true
    }

    public async setCreationVirtualOrderId(onClose: () => void) {
        try {
            const orderSources = await OrderClientAPI.getOrderSources()
            const findId = orderSources.find(({ id }) => id)

            if (findId) {
                this.initCreationVirtualOrderId(findId.id)
            } else {
                notificationStore.toast({
                    title: 'Error',
                    description: 'Something went wrong.',
                    status: StatusType.Error,
                })
                onClose()
            }
        } catch (err) {
            notificationStore.toast({
                title: 'Error',
                description: 'Something went wrong.',
                status: StatusType.Error,
            })
        }
    }

    public async simulateOrder(
        orderId: string,
        workflowIds: string[]
    ): Promise<void> {
        try {
            this.checkErrorStatusFlag = ''
            const simulateId = await WowsAPI.simulate(orderId, workflowIds)
            this.initSimulateOrderId(simulateId.orderId)
        } catch (err) {
            this.checkErrorStatusFlag = 'CHECKERRORSTATUS'
            notificationStore.triggerErrorToast(err)
        }
    }

    public async getOrderSources(): Promise<void> {
        try {
            const orderSources = await OrderClientAPI.getOrderSources()
            this.initExternalSource(orderSources)
        } catch (err) {
            this.initExternalSource([])
            notificationStore.triggerErrorToast(err)
        }
    }

    public async getFilters(): Promise<void> {
        try {
            const distributionSource = await OrderClientAPI.getShippers()
            this.initDistributionSource(distributionSource)
        } catch (err) {
            this.initDistributionSource([])
            notificationStore.triggerErrorToast(err)
        }
    }

    public setFilters(
        values: string[] | number[] | MaterialUiPickersDate,
        filterValue: string,
        dateRange?: string
    ): void {
        const isValuesArray = Array.isArray(values)
        const isEditFilters = !!this.editFilters.name

        if (filterValue === 'created' && !isValuesArray) {
            const date = dateRange === 'dateFrom'
                    ? { createdFrom: values }
                    : { createdTo: values }

            isEditFilters
                ? this.initEditFilters({ ...this.editFilters, ...date, page: 1 })
                : this.initFilters({ ...this.filters, ...date, page: 1 })
        }
        if (filterValue === 'delivery' && !isValuesArray) {
            const date = dateRange === 'dateFrom'
                    ? { deliveryFrom: values }
                    : { deliveryTo: values }

            isEditFilters
                ? this.initEditFilters({ ...this.editFilters, ...date, page: 1 })
                : this.initFilters({ ...this.filters, ...date, page: 1 })
        }
        if (filterValue === 'status' && isValuesArray) {
            const mapStatuses: string[] = []
            values.forEach(value => {
                const findEnumValue = this.status.find(
                    ({ name }) => name === value
                )
                if (findEnumValue) mapStatuses.push(...findEnumValue.enumValue)
            })

            isEditFilters
                ? this.initEditFilters({
                    ...this.editFilters,
                    statuses: mapStatuses,
                    page: 1,
                })
                : this.initFilters({
                    ...this.filters,
                    statuses: mapStatuses,
                    page: 1,
                })
        }
        if (filterValue === 'distributionSource' && isValuesArray) {
            const mapDistributionSource: UUID[] = []
            values.forEach(value => {
                const findId = this.distributionSource.find(
                    ({ name }) => name === value
                )
                if (findId) mapDistributionSource.push(findId.code)
            })

            isEditFilters
                ? this.initEditFilters({
                    ...this.editFilters,
                    ShipmentProviderCodes: mapDistributionSource,
                    page: 1,
                })
                : this.initFilters({
                    ...this.filters,
                    ShipmentProviderCodes: mapDistributionSource,
                    page: 1,
                })
        }
        if (filterValue === 'externalSource' && isValuesArray) {
            const mapExternalSource: UUID[] = []
            values.forEach(value => {
                const findId = this.externalSource.find(
                    ({ name }) => name === value
                )
                if (findId) mapExternalSource.push(findId.id)
            })

            isEditFilters
                ? this.initEditFilters({
                    ...this.editFilters,
                    orderSourceIds: mapExternalSource,
                    page: 1,
                })
                : this.initFilters({
                    ...this.filters,
                    orderSourceIds: mapExternalSource,
                    page: 1,
                })
        }
    }

    private setUpPages(pages: number): void {
        this.pages = pages
    }

    private setUpTotalItems(totalItems: number): void {
        this.totalItems = totalItems
    }

    private setOrderRelations(orderRelations: IOrderRelationApi[]) {
        this.orderRelations = orderRelations
    }

    public setLocalStorageFilters(): void {
        let localStorageFilters = defaultFilters
        try {
            const localFilters = localStorage.getItem('orderFilters')
            if (localFilters) localStorageFilters = JSON.parse(localFilters)
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }

        this.initFilters({
            ...this.filters,
            ...localStorageFilters,
        })
    }

    public setSavedFilters(savedFilters?: ISavedOrderFilters[]): void {
        if (savedFilters) {
            this.initSavedFilters(savedFilters)
        } else {
            try {
                const getSavedFilters = localStorage.getItem('savedOrderFilters')

                if (getSavedFilters) {
                    const filters = JSON.parse(getSavedFilters)
                    this.initSavedFilters(filters)
                }
            } catch (err) {
                notificationStore.triggerErrorToast(err)
            }
        }
    }

    public setDeletedFilters(deletedFilters: IDeletedOrderFilters[]): void {
        this.initDeletedFilters(deletedFilters)
    }

    public clearEditFilters(): void {
        this.initEditFilters(defaultEditFilters)
    }

    public undoDeleteFilter(filterName: string): void {
        if (this.deletedFilters.length) {
            const findDeletedFilter = this.deletedFilters.find(({ name }) => name === filterName)

            if (findDeletedFilter) {
                const {
                    page,
                    size,
                    createdFrom,
                    createdTo,
                    deliveryFrom,
                    deliveryTo,
                    statuses,
                    ShipmentProviderCodes,
                    orderSourceIds,
                    orderNumber,
                    isSimulated,
                    name,
                    createdDate,
                    index,
                } = findDeletedFilter
                const test = {
                    page,
                    size,
                    createdFrom,
                    createdTo,
                    deliveryFrom,
                    deliveryTo,
                    statuses,
                    ShipmentProviderCodes,
                    orderSourceIds,
                    orderNumber,
                    isSimulated,
                    name,
                    createdDate,
                }
                const updateSavedFilters = [...this.savedFilters]
                updateSavedFilters.splice(index, 0, test)

                orderStore.setSavedFilters(updateSavedFilters)
                orderStore.setDeletedFilters(
                    this.deletedFilters.filter(({ name }) => name !== findDeletedFilter.name)
                )
                localStorage.setItem('savedOrderFilters', JSON.stringify(updateSavedFilters))
            }
        }
    }

    public deleteSavedFilter(filterName: string): void {
        try {
            const findFilter = this.savedFilters.find(
                ({ name }) => name === filterName
            )
            const findFilterIndex = this.savedFilters.findIndex(
                ({ name }) => name === filterName
            )
            const deleteFilter = this.savedFilters.filter(({ name }) => name !== filterName)

            if (findFilter) {
                this.setDeletedFilters([...this.deletedFilters, { ...findFilter, index: findFilterIndex }])
            }

            this.setSavedFilters(deleteFilter)
            localStorage.setItem('savedOrderFilters', JSON.stringify(deleteFilter))
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    public async clearFilters(filterName: string): Promise<void> {
        const isEditFilters = !!this.editFilters.name

        switch (filterName) {
            case 'all':
                isEditFilters
                    ? this.initEditFilters({
                        ...defaultEditFilters,
                        name: this.editFilters.name,
                        createdDate: this.editFilters.createdDate,
                    })
                    : this.initFilters(defaultFilters)
                break
            case 'created':
                isEditFilters
                    ? this.initEditFilters({
                        ...this.editFilters,
                        createdFrom: null,
                        createdTo: null,
                    })
                    : this.initFilters({
                        ...this.filters,
                        createdFrom: null,
                        createdTo: null,
                    })
                break
            case 'delivery':
                isEditFilters
                    ? this.initEditFilters({
                        ...this.editFilters,
                        deliveryFrom: null,
                        deliveryTo: null,
                    })
                    : this.initFilters({
                        ...this.filters,
                        deliveryFrom: null,
                        deliveryTo: null,
                    })
                break
            case 'status':
                isEditFilters
                    ? this.initEditFilters({ ...this.editFilters, statuses: [] })
                    : this.initFilters({ ...this.filters, statuses: [] })
                break
            case 'distribution source':
                isEditFilters
                    ? this.initEditFilters({ ...this.editFilters, ShipmentProviderCodes: [] })
                    : this.initFilters({ ...this.filters, ShipmentProviderCodes: [] })
                break
            case 'external source':
                isEditFilters
                    ? this.initEditFilters({ ...this.editFilters, orderSourceIds: [] })
                    : this.initFilters({ ...this.filters, orderSourceIds: [] })
                break
            case 'orderNumber':
                isEditFilters
                    ? this.initEditFilters({ ...this.editFilters, orderNumber: '' })
                    : this.initFilters({ ...this.filters, orderNumber: '' })
                break
        }

        if (!isEditFilters) {
            this.initLocalFilters({ ...this.filters, page: 1 })

            await this.search()

            localStorage.setItem('orderFilters', JSON.stringify(this.localFilters))
        }
    }

    public async setPages(
        filters: Partial<IOrderFilters>,
        isSimulated?: boolean,
        isMockStatusFilter?: boolean
    ): Promise<void> {
        this.childOrders = []
        this.paginationNumber = filters.page || 0
        if (!filters.page) {
            this.initFilters({ ...this.filters, ...filters, page: 1 })
        } else {
            this.initFilters({ ...this.filters, ...filters })
        }
        this.initLocalFilters({ ...this.localFilters, ...filters })

        await isMockStatusFilter ? this.searchSimulate() : this.search(isSimulated)
    }

    private initVirtualOrderId(id: string): void {
        this.virtualOrderId = id
    }

    private initCreationVirtualOrderId(id: string): void {
        this.creationVirtualOrderId = id
    }

    private initSimulateOrderId(id: string): void {
        this.simulateOrderId = id
    }

    private init(orders: IOrder[]): void {
        this.orders = orders || []
        if (
            this.childClickOrders &&
            !this.paginationNumber
        ) {
            this.openTabDetails?.forEach((openTab) => {
                this.orders?.forEach((order, index) => {
                    if (openTab.childId === order.id && this.orders[index]) {
                            this.childrenCompleteData =  this.orders[index].children =
                            openTab.childrenData || this.childOrders
                         if (this.childrenCompleteData) {
                            const childrenIndex = this.childrenCompleteData.findIndex(
                                item => item.id === this.childClickOrders?.id
                            )
                            if (childrenIndex >= 0) {
                                this.childrenCompleteData[childrenIndex].children =
                                    this.childOrders
                            }
                        }
                        this.orders[index].children =
                            openTab.childrenData || this.childOrders
                    }
                })
            })
        }
    }

    private initFilters(filters: IOrderFilters): void {
        this.filters = filters
    }

    private initLocalFilters(localFilters: IOrderFilters): void {
        this.localFilters = localFilters
    }

    private initEditFilters(filters: ISavedOrderFilters): void {
        this.editFilters = filters
    }

    private initSavedFilters(savedFilters: ISavedOrderFilters[]): void {
        this.savedFilters = savedFilters
    }

    private initDeletedFilters(deletedFilters: IDeletedOrderFilters[]): void {
        this.deletedFilters = deletedFilters
    }

    private initOrderInfo(orderInfo: IOrder): void {
        this.orderInfo = orderInfo || {}
    }

    private initExternalSource(externalSource: IOrderExternalSource[]): void {
        this.externalSource = externalSource
    }

    private initDistributionSource(
        distributionSource: IOrderDistributionSource[]
    ): void {
        this.distributionSource = distributionSource
    }

    private initLoading(loading: boolean): void {
        this.loading = loading
    }

    private async setItemsShippingOrder(
        orderShipperCode: string,
        orderShipmentPackage: IPackage
    ) {
        try {
            const packageInfo =
                await OrderClientAPI.getShipmentStatusHistoryByPackageId(orderShipmentPackage.id)

            const shipmentStatus = packageInfo.sort((a, b) =>
                compareAsc(new Date(b.created).getTime(), new Date(a.created).getTime())
            )[0].shipmentStatus
            const newItemsShippingOrder: ItemShippingOrder[] = []
            for (const packageItem of orderShipmentPackage.packageItems) {
                newItemsShippingOrder.push({
                    packageId: orderShipmentPackage.id,
                    name: packageItem.orderItem.name,
                    productCode: packageItem.orderItem.productCode,
                    quantity: packageItem.quantity,
                    deliveryMethod: orderShipperCode,
                    trackingNumber: orderShipmentPackage.trackingNumber,
                    deliveryStatus: shipmentStatus,
                    shipmentNumber: orderShipmentPackage.shipmentNumber
                })
            }
            this.initItemsShippingOrder([...this.itemsShippingOrder, ...newItemsShippingOrder])
        } catch (e) {
            this.initItemsShippingOrder([])
        }
    }

    private initItemsShippingOrder(itemsShippingOrder: ItemShippingOrder[]) {
        this.itemsShippingOrder = itemsShippingOrder
    }

    private async getItemsShippingInfo(orderInfo: IOrder) {
        const getOrderShipmentsInfo = (orderShipments:IOrderShipment[]): Promise<IOrderShipment[]> =>
            Promise.all(orderShipments.map(x => OrderClientAPI.searchOrderShipmentById(x.id)))

        try {
            const orderShipmentsInfo = await getOrderShipmentsInfo(orderInfo.orderShipments)

            for (const orderShipmentInfo of orderShipmentsInfo){
                for (const orderShipmentPackage of orderShipmentInfo.packages) {
                    await this.setItemsShippingOrder(
                        orderShipmentInfo.shipper.code,
                        orderShipmentPackage)
                }
            }
        } catch (err) {
            this.initItemsShippingOrder([])
        }
    }

    public async retryOrders(orderIDs: string[], reason: string) {
        try {
            await OrderRetryAPI.retryOrders(orderIDs, reason)
            notificationStore.triggerSuccessToast('Order Retry Successfully')
        }
        catch(err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    public async getOrderRelations(orderID: string, orderType: number) {
        try {
            const orderRelations = await OrderRetryAPI.orderRelations(orderID, orderType)
            this.setOrderRelations(orderRelations)
        }
        catch(err) {
            this.setOrderRelations([])
            notificationStore.triggerErrorToast(err)
        }
    }
    public getTabIndex(tabInfo: IOpenTabDetails[]) {
        this.openTabDetails = tabInfo
    }

    public clean(): void {
        this.initItemsShippingOrder([])
        this.orderInfo = {} as IOrder
        this.initFilters(defaultFilters)
        this.initLocalFilters(defaultFilters)
        this.init([])
    }

    public getChildrenMockData = async (orderID: string) => {
        const orderType = 1

        if (orderID) await this.getOrderRelations(orderID, orderType)
    }
    
    public splitOrderHierarchy = async (order: IOrder, orderPageType: string, openTabIndex: number = -1) => {
        const mergedSplitOrderChild: IOrder[] = []
        this.paginationNumber = 0

        await this.getChildrenMockData(order.id)
        const orderRelationsCount = this.orderRelations.length;

        if (orderRelationsCount) {
            const promiseList: Array<Promise<IOrder>> = []
            this.orderRelations?.forEach(
                (relationResponse: IOrderRelationApi) => {
                    promiseList.push(OrderClientAPI.searchOrderById(
                        relationResponse.newOrderId
                    ))
                }
            )
            Promise.all(promiseList).then((relationResponse) => {
                relationResponse.forEach((response,relationIndex) => {
                    const currIndex = relationIndex + 1;
                    mergedSplitOrderChild.push({
                        ...relationResponse,
                        ...response,
                    })
              
                    const openedChildOrderInfo = this.openTabDetails?.findIndex(
                        tab => tab.childId === order.id
                    )
                    
                    if (
                        openedChildOrderInfo !== -1 &&
                        orderRelationsCount ===
                        mergedSplitOrderChild?.length
                    ) {
                        const openChildData = this.openTabDetails.length - 1
                        const openTabDetailIndex =
                            openTabIndex !== -1 ? openTabIndex : openChildData
                        this.openTabDetails[openTabDetailIndex] = {
                            childId:
                                this.openTabDetails[openTabDetailIndex]
                                    ?.childId,
                            childrenData: mergedSplitOrderChild,
                        }
                    }
                    if (currIndex === orderRelationsCount) {
                        this.splitCounter(currIndex)
                        if (orderPageType !== 'orderRuleSimulate') {
                            this.childOrders = mergedSplitOrderChild
                            const childOrderErrors =
                                mergedSplitOrderChild.filter(order => {
                                    return orderErrorStatuses.includes(
                                        order.status
                                    )
                                })
                            this.childOrderErrors.push(...childOrderErrors)
                        }
                    }
                })
            })

        } else {
            this.childOrders = []
        }

        if (orderPageType === 'orderRuleSimulate') {
            order.children = mergedSplitOrderChild
        } else {
            this.childClickOrders = order
        }
    }
}
