import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const AngleIcon: FC<
    IconProps & { direction: 'left' | 'right' }
> = ({ direction, ...props }) => {
    return (
        <Icon
            viewBox="0 0 8 8"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            {direction === 'left' ? (
                <path
                    d="M1.414 3.99987L3.889 6.47487L3.182 7.18187L0 3.99987L3.182 0.817871L3.889 1.52487L1.414 3.99987Z"
                    fill="currentColor"
                />
            ) : (
                <path
                    d="M2.58584 3.99987L0.11084 1.52487L0.81784 0.817871L3.99984 3.99987L0.81784 7.18187L0.11084 6.47487L2.58584 3.99987Z"
                    fill="currentColor"
                />
            )}
        </Icon>
    )
}
