import React from 'react'
import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from '@chakra-ui/react'

interface ITableModal {
    modalTitle: string
    isOpen: boolean
    onClose: () => void
    onSubmit: (data: any) => void
    children: React.ReactNode
    handleSubmit: any
    isSubmitting: boolean
}

const TableModal = ({
    modalTitle,
    isOpen,
    onClose,
    onSubmit,
    children,
    handleSubmit,
    isSubmitting,
}: ITableModal) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{modalTitle}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {children}
                        <ModalFooter>
                            <Button variant="blue" mr={3} onClick={onClose}>
                                Close
                            </Button>
                            <Button
                                variant="blue"
                                isLoading={isSubmitting}
                                type="submit"
                            >
                                Submit
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default TableModal
