import React, { useCallback, useEffect, useRef } from 'react'
import { Box, BoxProps } from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'
import { useDebounce, useLocalStore } from 'hooks'
import { GetDataFunction, ScrollerSettings } from './types'
import { ScrollerStore } from './store'
import { ScrollerService } from './service'

export type {
    GetDataFunction,
    GetDataResult,
    ScrollerSettings,
    ScrollerCache,
} from './types'

interface VirtualScrollerProps<Data> {
    container: BoxProps
    settings: ScrollerSettings
    get: GetDataFunction<Data>
    render: (data: Data) => JSX.Element
    empty?: JSX.Element | null
}

export const VirtualScroller = observer(function <Data>({
    container,
    settings,
    get,
    render,
    empty = null,
}: VirtualScrollerProps<Data>): JSX.Element {
    const viewportRef = useRef<HTMLDivElement>(null)
    const scrollStore = useLocalStore(() => {
        const scrollerService = new ScrollerService<Data>(settings)
        return new ScrollerStore<Data>(scrollerService)
    })

    useEffect(() => {
        scrollStore.init(get, viewportRef.current?.scrollTop || 0)
    }, [get, scrollStore])

    const scrollHandler = useCallback(
        (scrollTop: number) => {
            scrollStore.scrollTo(scrollTop)
        },
        [scrollStore]
    )

    const debouncedScroll = useDebounce(scrollHandler, 50)

    return (
        <Box
            {...container}
            ref={viewportRef}
            overflowY="auto"
            height="min-content"
            padding="0"
            backgroundColor="blueWhite.999"
            maxHeight={scrollStore.viewportHeight}
            onScroll={event => {
                debouncedScroll(event.currentTarget.scrollTop)
            }}
        >
            <Box height={scrollStore.paddingTop} />
            {scrollStore.data.length !== 0
                ? scrollStore.data.map(row => render(row))
                : empty}
            <Box height={scrollStore.paddingBottom} />
        </Box>
    )
})
