import { Box } from '@chakra-ui/layout'
import { Spinner, Tooltip } from '@chakra-ui/react'
import { chakra } from 'utils/chakra'

export const SpinnerStyled = chakra(Spinner, {
    position: 'absolute',
    left: '140px',
    top: '265px',
})

export const MenuListBox = chakra(Box, {
    height: '300px',
    borderRadius: '0px 0px 6px 6px',
    padding: '0',
    overflowY: 'auto',
    overflowX: 'hidden',
})

export const CustomTooltip = chakra(Tooltip, {
    position: 'relative',
    _after: {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: '50%',
        transform: 'translate(-50%, 100%)',
        width: '100%',
        height: '10px',
    },
})
