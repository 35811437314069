import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const DollarIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox='0 0 8 12'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <path
                d='M3.95298 11.2C3.87298 11.2 3.80498 11.172 3.74898 11.116C3.70098 11.068 3.67698 11.004 3.67698 10.924V10.084C3.11698 10.036 2.63298 9.908 2.22498 9.7C1.81698 9.484 1.50498 9.22 1.28898 8.908C1.08098 8.588 0.968977 8.248 0.952977 7.888C0.952977 7.824 0.976977 7.768 1.02498 7.72C1.08098 7.664 1.14498 7.636 1.21698 7.636H1.84098C1.98498 7.636 2.08498 7.716 2.14098 7.876C2.22098 8.212 2.42898 8.5 2.76498 8.74C3.10098 8.98 3.57698 9.1 4.19298 9.1C4.85698 9.1 5.35698 8.984 5.69298 8.752C6.02898 8.512 6.19698 8.18 6.19698 7.756C6.19698 7.484 6.11298 7.264 5.94498 7.096C5.78498 6.928 5.54498 6.78 5.22498 6.652C4.90498 6.524 4.42498 6.364 3.78498 6.172C3.16098 5.996 2.66498 5.808 2.29698 5.608C1.92898 5.408 1.65298 5.16 1.46898 4.864C1.28498 4.568 1.19298 4.196 1.19298 3.748C1.19298 3.156 1.41298 2.656 1.85298 2.248C2.29298 1.832 2.90098 1.584 3.67698 1.504V0.676C3.67698 0.595999 3.70098 0.531999 3.74898 0.483999C3.80498 0.428 3.87298 0.4 3.95298 0.4H4.40898C4.48898 0.4 4.55298 0.428 4.60098 0.483999C4.65698 0.531999 4.68498 0.595999 4.68498 0.676V1.516C5.19698 1.58 5.63298 1.724 5.99298 1.948C6.36098 2.172 6.63698 2.436 6.82098 2.74C7.01298 3.044 7.11698 3.348 7.13298 3.652C7.13298 3.716 7.10898 3.772 7.06098 3.82C7.02098 3.868 6.96098 3.892 6.88098 3.892H6.24498C6.07698 3.892 5.97298 3.816 5.93298 3.664C5.88498 3.328 5.69698 3.052 5.36898 2.836C5.04098 2.612 4.62898 2.5 4.13298 2.5C3.58898 2.5 3.16098 2.604 2.84898 2.812C2.53698 3.02 2.38098 3.328 2.38098 3.736C2.38098 4.008 2.45298 4.232 2.59698 4.408C2.74898 4.584 2.97698 4.74 3.28098 4.876C3.58498 5.004 4.02898 5.152 4.61298 5.32C5.29298 5.504 5.82898 5.696 6.22098 5.896C6.61298 6.088 6.90498 6.332 7.09698 6.628C7.28898 6.916 7.38498 7.284 7.38498 7.732C7.38498 8.412 7.14498 8.96 6.66498 9.376C6.18498 9.784 5.52498 10.024 4.68498 10.096V10.924C4.68498 11.004 4.65698 11.068 4.60098 11.116C4.55298 11.172 4.48898 11.2 4.40898 11.2H3.95298Z'
                fill="currentColor"
            />
        </Icon>
    )
}
