import React, { useCallback, useEffect, useRef } from 'react'
import { MenuListProps } from 'react-select'
import { observer } from 'mobx-react-lite'
import { throttle } from 'lodash'
import { paramStores } from 'stores'
import { ParamStore } from 'stores/ParamStore'
import { MenuListBox, SpinnerStyled } from './styles'

export const MenuList = observer((props: MenuListProps) => {
    const menuListRef = useRef<HTMLDivElement>(null)

    const isIdExist = Array.isArray(props.options) && props.options.find(
        ({ parameterId }) => parameterId
    )
    const store = isIdExist ? paramStores.stores[isIdExist.parameterId] : {} as ParamStore
    const loading = !!store.loading

    const handleScroll = useCallback(() => {
        if (menuListRef.current) {
            const scrollHeight = menuListRef.current.scrollHeight
            const scrollTop = menuListRef.current.scrollTop
            const isScrollbarDown = !!scrollTop && Math.round(scrollHeight - scrollTop) === 300

            if (isScrollbarDown && store.loadMore) store.loadMore()
        }
        // Need for improvement. Warnings console
        /* eslint-disable */
    }, [])

    useEffect(() => {
        const div = menuListRef.current
        if (div) div.addEventListener('scroll', throttle(handleScroll, 700))
    }, [handleScroll])

    return (
        <MenuListBox ref={isIdExist ? menuListRef : null}>
            {props.children}
            {loading && <SpinnerStyled />}
        </MenuListBox>
    )
})
