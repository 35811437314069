import { Box, Text } from '@chakra-ui/layout'
import { CounteredInput } from '../CounteredInput'
import { chakra } from 'utils/chakra'

export const InputStyled = chakra(CounteredInput, {
    width: '580px',
    marginTop: '8px',
    color: 'color.100',
})

export const InputText = chakra(Text, {
    variant: 'supertitle',
    margin: '2px 0',
})

export const InputWithHeaderBox = chakra(Box, {
    margin: '5px 0',
})
