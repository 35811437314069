import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const SearchIcon: FC<IconProps> = props => {
    return (
        <Icon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M17.2049 15.952L21 19.7462L19.7462 21L15.952 17.2049C14.5402 18.3366 12.7842 18.9522 10.9748 18.9496C6.57271 18.9496 3 15.3769 3 10.9748C3 6.57271 6.57271 3 10.9748 3C15.3769 3 18.9496 6.57271 18.9496 10.9748C18.9522 12.7842 18.3366 14.5402 17.2049 15.952ZM15.4274 15.2945C16.5519 14.138 17.18 12.5879 17.1774 10.9748C17.1774 7.54741 14.4013 4.77218 10.9748 4.77218C7.54741 4.77218 4.77218 7.54741 4.77218 10.9748C4.77218 14.4013 7.54741 17.1774 10.9748 17.1774C12.5879 17.18 14.138 16.5519 15.2945 15.4274L15.4274 15.2945Z"
                fill="currentColor"
            />
        </Icon>
    )
}
