import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const ImageIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 10 8"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M8.78571 8H1.35714C0.88375 8 0.5 7.61625 0.5 7.14286V0.857143C0.5 0.38375 0.88375 0 1.35714 0H8.78571C9.25911 0 9.64286 0.38375 9.64286 0.857143V7.14286C9.64286 7.61625 9.25911 8 8.78571 8ZM2.78571 1.57143C2.39123 1.57143 2.07143 1.89123 2.07143 2.28571C2.07143 2.6802 2.39123 3 2.78571 3C3.1802 3 3.5 2.6802 3.5 2.28571C3.5 1.89123 3.1802 1.57143 2.78571 1.57143ZM2.78571 3.28571C2.39123 3.28571 2.07143 3.60552 2.07143 4C2.07143 4.39448 2.39123 4.71429 2.78571 4.71429C3.1802 4.71429 3.5 4.39448 3.5 4C3.5 3.60552 3.1802 3.28571 2.78571 3.28571ZM2.78571 5C2.39123 5 2.07143 5.3198 2.07143 5.71429C2.07143 6.10877 2.39123 6.42857 2.78571 6.42857C3.1802 6.42857 3.5 6.10877 3.5 5.71429C3.5 5.3198 3.1802 5 2.78571 5ZM7.92857 2.57143V2C7.92857 1.88166 7.83263 1.78571 7.71429 1.78571H4.14286C4.02452 1.78571 3.92857 1.88166 3.92857 2V2.57143C3.92857 2.68977 4.02452 2.78571 4.14286 2.78571H7.71429C7.83263 2.78571 7.92857 2.68977 7.92857 2.57143ZM7.92857 4.28571V3.71429C7.92857 3.59595 7.83263 3.5 7.71429 3.5H4.14286C4.02452 3.5 3.92857 3.59595 3.92857 3.71429V4.28571C3.92857 4.40405 4.02452 4.5 4.14286 4.5H7.71429C7.83263 4.5 7.92857 4.40405 7.92857 4.28571ZM7.92857 6V5.42857C7.92857 5.31023 7.83263 5.21429 7.71429 5.21429H4.14286C4.02452 5.21429 3.92857 5.31023 3.92857 5.42857V6C3.92857 6.11834 4.02452 6.21429 4.14286 6.21429H7.71429C7.83263 6.21429 7.92857 6.11834 7.92857 6Z"
                fill="currentColor"
            />
        </Icon>
    )
}
