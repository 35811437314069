import React, { FC } from 'react'
import { DollarIcon, PercentIcon } from 'icons'
import { DisplayAsText, OrderQuantityBox } from './styles'

interface NumberInputBoxStyledProps {
    isPercentSelected: boolean
    isNumberOfCases: boolean
    displayAsValue?: string | null
}

export const NumberInputBoxStyled: FC<NumberInputBoxStyledProps> = ({
    isPercentSelected,
    isNumberOfCases,
    displayAsValue,
}) => {
    switch (true) {
        case isNumberOfCases:
            return <OrderQuantityBox />
        case isPercentSelected:
            return <PercentIcon boxSize="9px" color="gray.400" />
        case !!displayAsValue:
            return <DisplayAsText>{displayAsValue}</DisplayAsText>
        default:
            return <DollarIcon boxSize="12px" color="gray.400" />
    }
}
