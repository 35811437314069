import { firstStep, secondStep } from 'constants/steps'

export const loginErrorMessage = 'We’re sorry; we don’t recognize this email and password combination. If you have forgotten your password, please try the “Forgot password” link below.'

export const validateEmail = (value: string | null): boolean => !!value ? /^\S+@\S+\.\S+$/.test(value) : false
export const validatePass = (value: string | null): boolean => !!value ? /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{6,}$/.test(value) : false

export const steps = [
    { label: 'Step 1', value: firstStep },
    { label: 'Step 2', value: secondStep },
]
