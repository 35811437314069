import React from 'react'
import { FormLabel } from '@chakra-ui/react'
import { FormControl, FormErrorMessage } from '@chakra-ui/form-control'
import { Input } from '@chakra-ui/input'
import { TableForm } from 'types/core'
import { tenantsStore } from '../../../../stores'

const AuthApiKeyInputs = ({ errors, register }: TableForm) => {
    return (
        <>
            <FormControl>
                <FormLabel htmlFor="tenantId">Tenant Id</FormLabel>
                <Input
                    id="tenantId"
                    placeholder="Tenant Id"
                    value={tenantsStore.tenantId}
                    disabled
                />
            </FormControl>
            <FormControl isInvalid={!!errors.name} mt={5}>
                <FormLabel htmlFor="name">Name</FormLabel>
                <Input
                    id="name"
                    placeholder="name"
                    {...register('name', {
                        required: 'This is required',
                        minLength: {
                            value: 4,
                            message: 'Minimum length should be 4',
                        },
                    })}
                />
                <FormErrorMessage>
                    {errors.name && errors.name.message}
                </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.apiKey} mt={5} mb={5}>
                <FormLabel htmlFor="apiKey">Api key</FormLabel>
                <Input
                    id="apiKey"
                    placeholder="Api key"
                    {...register('apiKey', {
                        required: 'This is required',
                        minLength: {
                            value: 4,
                            message: 'Minimum length should be 4',
                        },
                    })}
                />
                <FormErrorMessage>
                    {errors.apiKey && errors.apiKey.message}
                </FormErrorMessage>
            </FormControl>
        </>
    )
}

export default AuthApiKeyInputs
