import React, { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import { Box, Divider, Text, SimpleGrid } from '@chakra-ui/layout'
import { RetriesHistoryOfHeader } from '../../RetriesHistoryOfHeader'
import { format } from 'date-fns'
import { orderStore } from 'stores'
import {
    RetriesHistoryBox,
    CollapseAllButton,
    RetriesHistoryHeader,
    NoData,
} from 'pages/OrderDetailsPage/styles'
import { DateBox, ReasonBox } from './styles'

export const RetriesHistory: FC = observer(() => {
    const [opened, setOpened] = useState<number[]>([])
    const { id } = useParams()

    const orderType = 0
    const relations = toJS(orderStore.orderRelations)

    useEffect(() => {
        if (id) orderStore.getOrderRelations(id, orderType)

        return () => {
            orderStore.clean()
        }
    }, [id])

    if (!relations.length) {
        return <NoData>No Data</NoData>
    }

    return (
        <RetriesHistoryBox>
            <RetriesHistoryHeader>
                <Text variant="h2">Retry History</Text>
                <CollapseAllButton
                    disabled={!opened.length}
                    onClick={() => setOpened([])}
                >
                    Collapse All
                </CollapseAllButton>
            </RetriesHistoryHeader>
            <Divider margin="8px 0 16px 0" />
            <RetriesHistoryOfHeader />
            {!!relations.length && relations.map(({ newOrderId, created, reason }) =>
                <Box key={newOrderId} backgroundColor='blueWhite.950'>
                    <SimpleGrid columns={4} spacing={0}>
                        <DateBox>{format(new Date(created), "MM/dd/yyyy hh:mm aaaaa'm'")}</DateBox>
                        <ReasonBox>{reason}</ReasonBox>
                    </SimpleGrid>
                </Box>
            )}
            <Divider margin="16px 0 0 0" />
        </RetriesHistoryBox>
    )
})
