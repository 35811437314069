import React from 'react'
import { Center } from '@chakra-ui/react'
import { components, DropdownIndicatorProps } from 'react-select'
import { DropDownIcon } from 'icons'

export const DropdownIndicator = (props: DropdownIndicatorProps) => {
    return (
        <components.DropdownIndicator {...props}>
            <Center cursor="pointer">
                <DropDownIcon
                    openned={false}
                    boxSize="18px"
                    color="color.100"
                />
            </Center>
        </components.DropdownIndicator>
    )
}
