import { makeAutoObservable } from 'mobx'
import { FeatureFlagsGlobal, FeatureFlagsTenants } from 'types/core'
import { PagingFilters } from 'types/api'
import { FeatureFlagsApi } from 'api/featureFlags'
import { notificationStore } from './index'

export interface IChangeData {
    id?: string
    enable?: boolean
    updatedAt?: string | Date
    name?: string
}

export const defaultFilters: PagingFilters = {
    page: 1,
    size: 10,
}

export class FeatureFlagStore {
    public globalList: FeatureFlagsGlobal[] = []
    public tenantsList: FeatureFlagsTenants[] = []
    public filters: PagingFilters = defaultFilters
    public pages: number = 0

    constructor() {
        makeAutoObservable(this)
    }

    public async fetchGlobal(filters = this.filters) {
        try {
            const response = await FeatureFlagsApi.getGlobal(filters)
            this.initGlobalList(response.items)
            this.setUpPages(response.totalPages)
        } catch (err) {
            this.initGlobalList([])
        }
    }

    public async fetchTenants(id: string, filters = this.filters) {
        try {
            const response = await FeatureFlagsApi.getTenants(id, filters)
            this.initTenantsList(response.items)
            this.setUpPages(response.totalPages)
        } catch (err) {
            this.initTenantsList([])
        }
    }

    public async createFeatureFlagTenant(tenantId: string, name: string, enable: boolean, isHierarchical: boolean) {
        try {
            await FeatureFlagsApi.createTenants(tenantId, {
                name,
                enable,
                isHierarchical,
            })
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    public async changeGlobal(data: IChangeData) {
        try {
            await FeatureFlagsApi.putGlobal(data)
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    public async changeTenants(tenantId: string, data: IChangeData) {
        try {
            await FeatureFlagsApi.putTenants(tenantId, data)
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    public async deleteTenants(id: string, tenantId: string) {
        try {
            await FeatureFlagsApi.deleteTenants(tenantId, id)
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    private initGlobalList(items: FeatureFlagsGlobal[]) {
        this.globalList = items
    }

    private initTenantsList(items: FeatureFlagsTenants[]) {
        this.tenantsList = items
    }

    private setUpPages(totalPages: number) {
        this.pages = totalPages
    }

    public cleanGlobal() {
        this.initGlobalList([])
    }

    public cleanTenants() {
        this.initTenantsList([])
    }

    public setFilters(filters: Partial<PagingFilters>) {
        if (!filters.page) {
            this.filters = { ...this.filters, ...filters, page: 1 }
        } else {
            this.filters = { ...this.filters, ...filters }
        }
    }
}
