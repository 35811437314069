import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import {
    PDFDownloadLink,
    Page,
    Text,
    View,
    Document,
} from '@react-pdf/renderer'
import { Button } from '@chakra-ui/react'
import { headerStyles, footerStyles } from '../PDFStyles/stylesMoc'
import {
    IPPSalesOverDuration,
    IProductPerformanceSales,
    IReportData,
    IReportStyles,
    ReportType,
    IOrderPerformance,
    IProductPerformanceItem,
} from 'types/core'
import { userStore } from 'stores'
import { exportButtonStyle } from './styles'

interface IPDFProps<Data> {
    data?: IReportData[]
    performanceByTotalOrderData?: IProductPerformanceItem[]
    productSalesOverDuration?: IPPSalesOverDuration[]
    reportName: string
    fileName: string
    curDate: string
    totalItems: number
    columns: JSX.Element
    pdfStyles: IReportStyles
    rowStyles: (data: Data | any) => JSX.Element
}

export const PDFExport = observer(function <Data>({
    data,
    performanceByTotalOrderData,
    productSalesOverDuration,
    reportName,
    fileName,
    curDate,
    totalItems,
    columns,
    pdfStyles,
    rowStyles,
}: IPDFProps<Data>): JSX.Element {
    const curUser = toJS(userStore.name)

    const Prints = () => (
        <Document>
            <Page style={headerStyles.page}>
                <View>
                    <View style={headerStyles.title}>
                        <Text style={headerStyles.version}>
                            DOM Release v.0.06.07
                        </Text>
                        <Text style={headerStyles.name}>{reportName}</Text>
                        <Text>{curDate}</Text>
                    </View>
                    <View style={headerStyles.info}>
                        <Text style={headerStyles.location}>
                            {' '}
                            Location: 1006 Lima
                        </Text>
                        <Text>Item(s): {totalItems}</Text>
                    </View>
                    {reportName !==
                        ReportType.performanceReceivedBySalesChannel &&
                        reportName !==
                            ReportType.performanceSalesOverDuration && (
                            <View style={headerStyles.table}>
                                <View
                                    style={headerStyles.header}
                                    wrap={false}
                                    fixed
                                >
                                    {columns}
                                </View>
                                {!!data && !!data.length ? (
                                    <View>
                                        {data.map(row => rowStyles(row))}
                                    </View>
                                ) : (
                                    <View>
                                        <Text style={pdfStyles.row}>
                                            No Data found
                                        </Text>
                                    </View>
                                )}
                            </View>
                        )}
                    {reportName ===
                        ReportType.performanceReceivedBySalesChannel &&
                        !!performanceByTotalOrderData &&
                        !!performanceByTotalOrderData.length &&
                        performanceByTotalOrderData.map(row => (
                            <View
                                style={headerStyles.table}
                                key={row.sourceName}
                            >
                                <Text fixed>{row.sourceName}</Text>
                                <View style={headerStyles.header} wrap={false}>
                                    {columns}
                                </View>
                                {row?.orderPerformance.map(
                                    (item: IOrderPerformance) => rowStyles(item)
                                )}
                            </View>
                        ))}
                    {reportName === ReportType.performanceSalesOverDuration &&
                        !!productSalesOverDuration &&
                        !!productSalesOverDuration.length &&
                        productSalesOverDuration?.map(row => (
                            <View
                                key={row.sourceName}
                                style={headerStyles.table}
                            >
                                <Text fixed>{row.sourceName}</Text>
                                <View style={headerStyles.header} wrap={false}>
                                    {columns}
                                </View>
                                {row?.productPerformance?.map(
                                    (item: IProductPerformanceSales) =>
                                        rowStyles(item)
                                )}
                            </View>
                        ))}
                    <View style={footerStyles.end}>
                        <Text>
                            --------------------- End of Report
                            ---------------------
                        </Text>
                    </View>
                </View>
                <Text style={footerStyles.username} fixed>
                    Run by {curUser}
                </Text>
                <Text
                    style={footerStyles.pageNumber}
                    render={({ pageNumber, totalPages }) =>
                        `Page ${pageNumber} of ${totalPages}`
                    }
                    fixed
                />
            </Page>
        </Document>
    )
    return (
        <PDFDownloadLink
            document={<Prints key={fileName} />}
            fileName={fileName}
        >
            {({ blob, url, loading, error }) =>
                loading ? (
                    <Button disabled style={exportButtonStyle}>
                        Export as PDF
                    </Button>
                ) : (
                    <Button disabled={!totalItems} style={exportButtonStyle}>
                        Export as PDF
                    </Button>
                )
            }
        </PDFDownloadLink>
    )
})
