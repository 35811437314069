import React, { FC, useRef } from 'react'
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button,
} from '@chakra-ui/react'

interface MessageValidProps {
    isOpen: boolean
    validateCsvDataMsg: string
    onClose: () => void
}

export const AlertValidationMessage: FC<MessageValidProps> = ({
    isOpen,
    validateCsvDataMsg,
    onClose,
}) => {
    const cancelRef = useRef<HTMLButtonElement>(null)
    
    return (
        <AlertDialog
            isOpen={isOpen}
            onClose={onClose}
            leastDestructiveRef={cancelRef}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        Message
                    </AlertDialogHeader>
                    <AlertDialogBody>{validateCsvDataMsg}</AlertDialogBody>
                    <AlertDialogFooter>
                        <Button onClick={onClose}>Close</Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}

export default AlertValidationMessage
