import { DependsOnMappings, IDependsOnParameters, Parameter, ParameterMetadata } from 'types/core'

const getParameterValue = (parameterValue?: string) => {
    if (parameterValue === 'null') return ''
    try {
        const parsedValue = parameterValue ? JSON.parse(parameterValue) : ''
        if (Array.isArray(parsedValue)) return parsedValue.join(',')
    } catch (err) {

    }
    return parameterValue || ''
}

export const dependsOnParameters = (
    dependsOnMappings: DependsOnMappings[],
    nodeParameters: ParameterMetadata[],
    stepParameters: Parameter[],
) => {
    return dependsOnMappings.reduce((acc: IDependsOnParameters, mapping) => {
        const findParameter = nodeParameters.find(({ name }) => name === mapping.parameter)
        const findParameterValue = stepParameters.find(
            ({ parameterMetadataId }) => parameterMetadataId === findParameter?.id
        )

        acc.dependencies = {
            ...acc.dependencies,
            ...{ [mapping.value]: getParameterValue(findParameterValue?.value) }
        }

        const nodeParameterValue = findParameter?.defaultValue
        const stepParameterValue = findParameterValue?.value
        const isNodeParameter = nodeParameterValue === '' || nodeParameterValue === 'null' || nodeParameterValue
        const isStepParameter = stepParameterValue === '' || stepParameterValue === 'null' || stepParameterValue

        if (findParameter?.description && mapping.required && isNodeParameter === isStepParameter) {
            acc.requiredMappings.push(findParameter?.description || '')
            acc.isDisabled = true
        }

        return acc
    }, {
        dependencies: {},
        isDisabled: false,
        requiredMappings: [],
    })
}
