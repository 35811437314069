import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import { useDisclosure } from '@chakra-ui/hooks'
import { toJS } from 'mobx'
import { isEqual } from 'lodash'
import { observer } from 'mobx-react-lite'
import { Button } from '@chakra-ui/button'
import { Checkbox, Flex, HStack, Text } from '@chakra-ui/react'
import { Divider } from '@chakra-ui/layout'
import { Input } from '../Input'
import { SavedFiltersDrawer } from './SavedFiltersDrawer'
import { SaveFiltersModal } from './SaveFiltersModal'
import { RetryReasonModal } from 'components/RetryReason/ReasonModal'
import { WarningMessage } from 'components/RetryReason/WarningMessage'
import { ShowFilters } from './ShowFilters'
import { SelectedFilters } from './SelectedFilters'
import { FilterIcon, FloppyDiskIcon, RetryArrowIcon } from 'icons'
import { orderStore } from 'stores'
import { errorStatuses, retryTooltipText } from './mock'
import { mapErrors, mapStatuses, getSelectedFilters } from './utils'
import {
    BottomFiltersBox,
    ErrorsOnlyBox,
    FiltersBox,
    ShowFiltersButtonText,
    SavedFiltersButtonText,
    Title,
    TitleBox,
    TopFiltersBox,
    RetryButtonText,
    RetryTooltip,
} from './styles'

interface IOrderFilters {
    handleFiltersOpen: (isChecked: boolean) => void
    onSubmitRetry?: (reason: string) => void
    isSimulated?: boolean
}

export const OrderFilters: FC<IOrderFilters> = observer(({
    handleFiltersOpen,
    onSubmitRetry = () => {},
    isSimulated
}) => {
    const [isFiltersOpen, setIsFiltersOpen] = useState(true)
    const [errorsOnly, setErrorsOnly] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [openRetry, setOpenRetry] = useState(false)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = useRef(null)

    const filters = orderStore.filters
    const localFilters = orderStore.localFilters
    const localStatuses = toJS(orderStore.localFilters.statuses)

    const getFiltersNumber = (): number => {
        const {
            createdFrom,
            createdTo,
            deliveryFrom,
            deliveryTo,
            statuses,
            ShipmentProviderCodes,
            orderSourceIds,
            orderNumber,
        } = orderStore.localFilters

        let amount = 0
        if (createdFrom || createdTo) amount++
        if (deliveryFrom || deliveryTo) amount++

        amount =
            amount +
            +!!statuses.length +
            +!!ShipmentProviderCodes?.length +
            +!!orderSourceIds.length +
            +!!orderNumber
        return amount
    }

    const handleShowFilters = (): void => setIsFiltersOpen(prev => !prev)

    const onOpenModal = (): void => setOpenModal(true)

    const onOpenRetry = (): void => setOpenRetry(true)
    const onCloseRetry = (): void => setOpenRetry(false)

    const handleClearAll = async (): Promise<void> => {
        await orderStore.clearFilters('all')
    }

    const handleErrorsChange = async (
        e: ChangeEvent<HTMLInputElement>
    ): Promise<void> => {
        const isChecked = e.target.checked

        setErrorsOnly(isChecked)
        orderStore.setFilters(isChecked ? mapErrors : [], 'status')
        await orderStore.applyFilters()
    }

    useEffect(() => {
        setIsFiltersOpen(false)
    }, [])

    useEffect(() => {
        const isErrorStatuses = isEqual(
            mapStatuses().sort(),
            localStatuses.sort()
        )

        setErrorsOnly(isErrorStatuses)
        handleFiltersOpen(
            localStatuses.some(status => errorStatuses.includes(status))
        )

    }, [localStatuses, handleFiltersOpen])

    const isCheckedNumberOfRetriesRecords = orderStore.isCheckCountErrorStatus

    return (
        <>
            <TitleBox>
                <Title>Order Status Tracking</Title>
            </TitleBox>
            <Divider margin="8px 0" />
            <FiltersBox>
                <TopFiltersBox
                    margin={getFiltersNumber() ? '16px 0' : '16px 0 24px 0'}
                >
                    <Input />
                    <Flex position="relative">
                        <ErrorsOnlyBox>
                            <Checkbox
                                marginLeft="15px"
                                isChecked={errorsOnly}
                                onChange={handleErrorsChange}
                            >
                                <Text variant="paragraphsmall">
                                    Errors Only
                                </Text>
                            </Checkbox>
                        </ErrorsOnlyBox>
                        <Button
                            variant="outlineWhiteBlack"
                            ref={btnRef}
                            onClick={onOpen}
                        >
                            <FloppyDiskIcon boxSize="12px" color="color.100" />
                            <SavedFiltersButtonText>
                                Saved Filters
                            </SavedFiltersButtonText>
                        </Button>
                        <Button
                            variant="outlineWhiteBlack"
                            onClick={handleShowFilters}
                        >
                            <FilterIcon boxSize="12px" color="color.100" />
                            <ShowFiltersButtonText>
                                Show Filters {getSelectedFilters(filters) && `(${getSelectedFilters(filters)})`}
                            </ShowFiltersButtonText>
                        </Button>
                        <ShowFilters
                            isOpen={isFiltersOpen}
                            setOpen={setIsFiltersOpen}
                            isSimulated={isSimulated}
                        />
                    </Flex>
                </TopFiltersBox>
                {!!getFiltersNumber() && (
                    <BottomFiltersBox>
                        <SelectedFilters filter={localFilters} isSavedFilters={false} />
                        <Flex>
                            {getFiltersNumber() > 1 && (
                                <Button
                                    variant="underline"
                                    margin="9px 8px 0 12px"
                                    paddingTop="7px"
                                    onClick={handleClearAll}
                                >
                                    Clear All
                                </Button>
                            )}
                            <HStack gap="1" marginRight="7px">
                                <Button
                                    variant="underline"
                                    margin="9px 19px 0 30px"
                                    paddingTop="7px"
                                    maxWidth="79px"
                                    minWidth="79px"
                                    onClick={onOpenModal}
                                >
                                    Save Filters
                                </Button>
                                <RetryTooltip
                                    placement="top"
                                    label={retryTooltipText}
                                    pointerEvents="auto"
                                >
                                    <Button
                                        variant="outlineWhiteBlack"
                                        maxWidth="95px"
                                        minWidth="95px"
                                        onClick={onOpenRetry}
                                    >
                                        <RetryArrowIcon />
                                        <RetryButtonText>
                                            Retry
                                        </RetryButtonText>
                                    </Button>
                                </RetryTooltip>
                                {!!isCheckedNumberOfRetriesRecords && (isCheckedNumberOfRetriesRecords >= 20) ? (
                                    <WarningMessage
                                        isOpen={openRetry}
                                        onSubmit={onSubmitRetry}
                                        onClose={onCloseRetry}
                                    />
                                ) : (
                                    <RetryReasonModal
                                        isOpen={openRetry}
                                        onSubmit={onSubmitRetry}
                                        onClose={onCloseRetry}
                                    />
                                )}
                            </HStack>
                        </Flex>
                    </BottomFiltersBox>
                )}
            </FiltersBox>
            <SaveFiltersModal
                isOpen={openModal}
                setOpenModal={setOpenModal}
            />
            <SavedFiltersDrawer
                isDrawerOpen={isOpen}
                onDrawerClose={onClose}
                btnDrawerRef={btnRef}
            />
        </>
    )
})
