import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'
import { routes } from 'navigation/routes'
import { Box } from '@chakra-ui/react'
import { format } from 'date-fns'
import { Button } from '@chakra-ui/button'
import { Divider } from '@chakra-ui/layout'
import { Cards } from 'components/Cards'
import { CustomSplitPanel } from './CustomSplitPanel'
import { ArrowRightLongIcon } from 'icons'
import { IOrder } from 'types/core'
import { stringOrDash } from 'utils/stringOrDash'
import {
    AccordionPanelBox,
    Details,
    DetailsBox,
    DetailsInfoBox,
    DistSourceNumberBox,
    HeadingBox,
    HeadingText,
    InfoBox,
    InfoText,
    LeftBox,
    RightBox,
} from '../styles'

interface CustomAccordionPanelProps {
    order: IOrder
    splitOrderChildData?: IOrder | null
    isFiltersOpen?: boolean
    childOrderHandler?: (orderID: IOrder) => void
    toRetry?: (orderID: string) => boolean
    handleCheckChange?: (orderID: string) => void
    isOrderSimulated?: boolean
}

export const CustomAccordionPanel: FC<CustomAccordionPanelProps> = observer(({
    order: {
        orderItems = [],
        id,
        created,
        shippingLocation,
        customerAddress,
        ecommerceExternalOrderId,
        customerName,
    },
    splitOrderChildData,
    isFiltersOpen,
    childOrderHandler,
    toRetry = () => false,
    handleCheckChange,
    isOrderSimulated,
}) => {
    const navigate = useNavigate()

    const splitChildrenCheck = splitOrderChildData?.children?.length
    const adaptCards = orderItems.map(({ id, productCode, name, quantity }) => {
        return {
            id,
            topValue: productCode,
            middleValue: name,
            bottomValue: quantity,
        }
    })

    return (
        <>
            <Box onClick={e => e.stopPropagation()}>
                <AccordionPanelBox>
                    <LeftBox>
                        <HeadingBox>
                            <Details>Details</Details>
                            <Button
                                size="xs"
                                variant="empty"
                                onClick={() => navigate(`${routes.orderDetails}/${id}`)}
                                rightIcon={<ArrowRightLongIcon boxSize="14px" />}
                            >
                                Full Details
                            </Button>
                        </HeadingBox>
                        <Divider />
                        <DetailsBox>
                            <DetailsInfoBox marginRight="50px">
                                <InfoBox marginBottom="10px">
                                    <HeadingText>Created On</HeadingText>
                                    <InfoText>
                                        {created
                                            ? format(new Date(created), 'MM/dd/yy')
                                            : '-'}
                                    </InfoText>
                                </InfoBox>
                                <InfoBox>
                                    <HeadingText>Delivery Plant</HeadingText>
                                    <InfoText>
                                        {stringOrDash(shippingLocation?.code)},{' '}
                                    </InfoText>
                                    <InfoText>{stringOrDash(shippingLocation?.name)}</InfoText>
                                </InfoBox>
                            </DetailsInfoBox>
                            <DetailsInfoBox>
                                <InfoBox marginLeft="30px">
                                    <HeadingText>Shipping Address</HeadingText>
                                    <InfoText>
                                        {customerAddress ? (
                                            <>
                                                {stringOrDash(
                                                    customerAddress.addressLine1?.toLowerCase()
                                                    || customerAddress.addressLine2?.toLowerCase()
                                                )}
                                                <br />
                                                {stringOrDash(customerAddress.city?.toLowerCase())}
                                                , {stringOrDash(customerAddress.state)},{' '}
                                                {stringOrDash(customerAddress.postalCode)}
                                            </>
                                        ) : '-'}
                                    </InfoText>
                                </InfoBox>
                            </DetailsInfoBox>
                        </DetailsBox>
                        <DistSourceNumberBox>
                            <DetailsInfoBox marginRight="50px">
                                <InfoBox>
                                    <HeadingText>Sales Order Number</HeadingText>
                                    <InfoText>
                                        {stringOrDash(ecommerceExternalOrderId)}
                                    </InfoText>
                                </InfoBox>
                            </DetailsInfoBox>
                            <DetailsInfoBox>
                                <InfoBox marginLeft="30px">
                                    <HeadingText>Customer Name</HeadingText>
                                    <InfoText>{stringOrDash(customerName)}</InfoText>
                                </InfoBox>
                            </DetailsInfoBox>
                        </DistSourceNumberBox>
                    </LeftBox>
                    <RightBox>
                        <Cards
                            bottomHeader="QUANTITY"
                            cards={adaptCards}
                        />
                    </RightBox>
                </AccordionPanelBox>
                {splitChildrenCheck ? (
                    <Box onClick={e => e.stopPropagation()}>
                        <CustomSplitPanel
                            isFiltersOpen={isFiltersOpen}
                            splitOrderChildData={splitOrderChildData}
                            toRetry={toRetry}
                            handleCheckChange={handleCheckChange}
                            childOrderHandler={childOrderHandler}
                            isOrderSimulated={isOrderSimulated}
                        />
                    </Box>
                ) : null}
            </Box>
        </>
    )
})
