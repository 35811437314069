import { authInstance } from './instances'

export class AuthAPI {
    static async login(username: string, password: string) {
        // await this.initialize()
        const bodyFormData = new FormData()
        bodyFormData.append('username', username)
        bodyFormData.append('password', password)

        const { data = {} } = await authInstance.post('/token', bodyFormData)
        return data
    }

    static async refreshAuth() {
        const refreshToken = localStorage.getItem('refreshToken') || ''

        const { data = {} } = await authInstance.post('/refresh', {
            refreshToken,
        })
        return data
    }

    static async getAuthTypes() {
        return await authInstance.post('/authTypes')
    }

    static forgotPassword(email: string) {
        return Promise.resolve({ status: 'Success', email: 'abc@gmail.com' })
    }

    static async getAccountConfiguration(email: string) {
        const { data = {} } = await authInstance.post('/configuration', {
            email,
        })
        return data
    }

    static async getAccountTokenAAD(token: string) {
        const { data = {} } = await authInstance.post('/token-aad', { token })
        return data
    }

    static async exchangeCurrentToken(tenantId: string, token?: string) {
        const { data } = await authInstance.post('/exchange-token', {
            tenantId,
            token,
        })
        return data
    }
}
