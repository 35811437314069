import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { IconButton } from '@chakra-ui/react'
import { orderStore } from 'stores'
import {
    UndoToastBox,
    UndoButton,
    UndoToastText,
    CrossSmallIconStyled,
} from './styles'

interface IUndoToast {
    filterName: string
    handleClose: () => void
}

export const UndoToast: FC<IUndoToast> = observer(({ filterName, handleClose }) => {
    const handleUndo = (): void => {
        orderStore.undoDeleteFilter(filterName)
        handleClose()
    }

    return (
        <UndoToastBox>
            <UndoToastText>
                Saved Filters "{filterName}" successfully removed
            </UndoToastText>
            <UndoButton onClick={handleUndo}>
                Undo
            </UndoButton>
            <IconButton
                onClick={handleClose}
                aria-label="Delete Toast Cross Small Icon"
                variant="hoveredMediumWhite"
                marginRight="5px"
                icon={<CrossSmallIconStyled />}
            />
        </UndoToastBox>
    )
})
