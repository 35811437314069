import React, { FC } from 'react'
import { ListItem, Modal, ModalContent } from '@chakra-ui/react'
import { WorkflowError } from 'types/core'
import {
    ModalHeaderStyled,
    CrossSmallIconStyled,
    ModalHeaderText,
    ModalBodyStyled,
    OrderedListStyled,
    BorderStyled,
    ModalOverlayStyled,
    ButtonStyled,
    TextStyled,
    ModalFooterStyled,
} from './styles'

interface IErrorsModal {
    isOpen: boolean
    onClose: () => void
    workflowErrors: WorkflowError[]
}

export const ErrorsModal: FC<IErrorsModal> = ({ isOpen, onClose, workflowErrors }) => (
    <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlayStyled />
        <ModalContent alignSelf="center" backgroundColor="blueWhite.950">
            <ModalHeaderStyled>
                <CrossSmallIconStyled onClick={onClose} />
                <ModalHeaderText>Invalid Rule</ModalHeaderText>
            </ModalHeaderStyled>
            <ModalBodyStyled>
                <TextStyled>Error(s) in Rule Configuration:</TextStyled>
                <BorderStyled>
                    <OrderedListStyled>
                        {workflowErrors?.map(({ message }, i) => (
                            <ListItem key={i}>
                                {message}
                            </ListItem>
                        ))}
                    </OrderedListStyled>
                </BorderStyled>
            </ModalBodyStyled>
            <ModalFooterStyled>
                <ButtonStyled onClick={onClose}>Close</ButtonStyled>
            </ModalFooterStyled>
        </ModalContent>
    </Modal>
)
