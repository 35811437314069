import React, { useEffect, useState } from 'react'
import { Button, Select, useDisclosure } from '@chakra-ui/react'
import { Text, Box } from '@chakra-ui/layout'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { format } from 'date-fns'
import { toJS } from 'mobx'
import { ComponentModal } from '../Modal/ComponentModal'
import { CreateVirtualOrder } from '../CreateVirtualOrder'
import { ShowRuleSimulate } from './ShowRuleSimulate'
import { dateFormat, orderCreateFlag } from './mock'
import { IOrder } from 'types/core'
import { editoreStore, orderStore } from 'stores'
import { RuleSimulateBlock } from './styles'

interface ICloseSimulateModal {
    onClose(): void
}

export const RulesSimulate = observer((closeSimulateModal: ICloseSimulateModal) => {
    const { id } = useParams<'id'>()
    const [orderValue, setOrderValue] = useState('')
    const [onCreateFlag, setOnCreateFlag] = useState('')
    const [openShowSimulateModal, setOpenShowSimulateModal] = useState(false)
    const { isOpen, onOpen, onClose } = useDisclosure()

    const orders = toJS(orderStore.orders)
    const simulateOrderId = toJS(orderStore.virtualOrderId)

    const sendSimulate = () => {
        id &&
            orderStore
                .simulateOrder(orderValue, [id])
                .then(() => setOpenShowSimulateModal(true))
    }
    const handleOrderChange = (value: string) => {
        setOrderValue(value)
        setOnCreateFlag('')
    }
    const handlerModal = () => setOnCreateFlag(orderCreateFlag)
    const handleOpenModal = () => setOpenShowSimulateModal(!openShowSimulateModal)
    const showDate = (date: Date) => format(new Date(date), dateFormat)

    useEffect(() => {
        orderStore.search(true)
    }, [simulateOrderId])

    useEffect(() => {
        onCreateFlag === 'ONCREATE' && !!orders?.length && setOrderValue(orders[0].id)
        // Need for improvement. Warnings console
        /* eslint-disable */
    }, [orders])

    return (
        <>
            <RuleSimulateBlock>
                <Text variant="h4">Rules:</Text>
                <Select disabled={true}>
                    <option>
                        {editoreStore.workflowStore.workflow?.name}
                    </option>
                </Select>
            </RuleSimulateBlock>
            <RuleSimulateBlock>
                <Text variant="h4">Orders:</Text>
                <Select
                    value={orderValue}
                    onChange={value => handleOrderChange(value.target.value)}
                >
                    <option value="" />
                    {orders.map((el: IOrder) => (
                        <option key={el.id} value={el.id}>
                            {`${showDate(el.created)}  |  ${
                                el.customerName
                            }  |  ${el.number}`}
                        </option>
                    ))}
                </Select>
                <Box
                    margin="16px 0"
                    display="flex"
                    flexDirection="row-reverse"
                >
                    <Button
                        marginTop="10px"
                        variant="blue"
                        size="sm"
                        onClick={() => {
                            onOpen()
                        }}
                    >
                        Add Virtual Order
                    </Button>
                </Box>
            </RuleSimulateBlock>
            <Box display="flex" flexDirection="row-reverse">
                <Button
                    height="34px"
                    margin="0 16px"
                    variant="blue"
                    size="sm"
                    disabled={!(orderValue.length && id)}
                    onClick={sendSimulate}
                >
                    Run Simulation
                </Button>
                <Button
                    height="34px"
                    margin="0 16px"
                    type="button"
                    variant="blue"
                    onClick={() => closeSimulateModal.onClose()}
                >
                    Cancel
                </Button>
            </Box>

            <ComponentModal
                isOpen={isOpen}
                onClose={onClose}
                headerText="Create Virtual Order"
                reactComponent={
                    <CreateVirtualOrder
                        onClose={onClose}
                        onHandlerModal={handlerModal}
                    />
                }
            />
            {orderStore.checkErrorStatusFlag !== 'CHECKERRORSTATUS' && (
                <ComponentModal
                    isOpen={openShowSimulateModal}
                    onClose={handleOpenModal}
                    headerText="Show Order Simulate"
                    size="5xl"
                    reactComponent={<ShowRuleSimulate onClose={handleOpenModal} />}
                />
            )}
        </>
    )
})
