import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const ArrowBackIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 16 13"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.4572 11.3849C7.62185 11.5448 7.71475 11.7645 7.71475 11.994C7.71475 12.7446 6.81234 13.1261 6.2739 12.6032L0.587197 7.08002C0.267846 6.76986 0.273436 6.25545 0.599451 5.9523L6.28581 0.664677C6.82958 0.159036 7.71475 0.544662 7.71475 1.2872C7.71475 1.52337 7.6165 1.7489 7.44355 1.90972L3.25306 5.80636H15.1315C15.6111 5.80636 16 6.19522 16 6.6749C16 7.15458 15.6111 7.54343 15.1315 7.54343H3.50204L7.4572 11.3849Z"
                fill="currentColor"
            />
        </Icon>
    )
}
