import { Textarea, Flex, Text } from '@chakra-ui/react'
import { chakra } from 'utils/chakra'

export const RetryIconButton = chakra(Flex, {
    borderRadius: '6px',
    boxSize: '24px'
})

export const ReasonInput = chakra(Textarea, {
    padding: '9px 16px',
    height: '200px',
    borderRadius: '8px',
    backgroundColor: 'blueWhite.950',
})

export const WarningMessageText = chakra(Textarea, {
    padding: '9px 16px',
    height: '130px',
    borderRadius: '8px',
    backgroundColor: 'blueWhite.950',
    borderColor: 'color.875',
    color: 'color.100'
})

export const ValidationMessageText = chakra(Text, {
    color: 'red',
    marginLeft: '25px'
})

export const RequiredLabelMessage = chakra(Text, {
    marginLeft: '2px',
    fontSize: '12px'
})