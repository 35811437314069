import { Box } from '@chakra-ui/layout'
import { Input } from '@chakra-ui/input'
import { chakra } from 'utils/chakra'
import { IconButton } from '@chakra-ui/react'

export const InputSearchBox = chakra(Box, {
    position: 'relative',
})

export const SearchIconBox = chakra(Box, {
    position: 'absolute',
    left: '12px',
    top: '3px',
    zIndex: 1,
})

export const ClearIconBox = chakra(IconButton, {
    variant: 'hoveredMediumGray',
    position: 'absolute',
    right: '16px',
    top: '4px',
    zIndex: 1,
})

export const SearchInputStyled = chakra(Input, {
    height: '32px',
    border: '1px solid',
    borderColor: 'borderInput.100',
    backgroundColor: 'color.900',
    borderRadius: '20px',
    fontSize: '12px',
    lineHeight: '14px',
    color: 'color.100',
    paddingLeft: '40px',
    _placeholder: {
        color: 'searchInputText',
    },
    _focus: {
        borderColor: 'blue.300',
    },
})
