import { Box, Flex } from '@chakra-ui/layout'
import { chakra } from 'utils/chakra'
import { Button } from '@chakra-ui/react'

export const MappingJsonGlobal = chakra(Flex, {
    flexDirection: 'column',
    width: '100%',
    color: 'adminGatewayTabs',
    borderColor: 'borderGatewayTabs',
})

export const MappingJsonGlobalBox = chakra(Flex, {
    padding: '10px 0',
    alignItems: 'center',
    justifyContent: 'space-between',
})

export const MappingBox = chakra(Flex, {
    padding: '15px 0',
})

export const MappingJsonBox = chakra(Flex, {
    padding: '0 10px',
    width: '100%',
    flexDirection: 'column',
})

export const MappingAccordingTitle = chakra(Flex, {
    alignItems: 'center',
})

export const MappingTitle = chakra(Flex, {
    padding: '10px 10px',
})

export const TitleBox = chakra(Flex, {
    flex: '1',
    textAlign: 'left',
    textTransform: 'capitalize',
})

export const jsonComponentStyle = {
    padding: 24,
    minHeight: 280,
}

export const JsonBaseBox = chakra(Flex, {
    alignItems: 'flex-start',
})

export const JsonCenterBox = chakra(Flex, {
    alignItems: 'center',
})

export const JsonValueBox = chakra(Flex, {
    marginLeft: '5px',
})

export const AddModalButton = chakra(Button, {
    height: '34px',
    margin: '0 16px',
})

export const EditModalGlobalBox = chakra(Flex, {
    flexDirection: 'column',
    paddingBottom: '20px',
})

export const EditModalText = chakra(Box, {
    marginLeft: '10px',
    paddingBottom: '10px',
    textAlign: 'left',
})

export const EditModalBox = chakra(Flex, {
    alignItems: 'center',
    marginLeft: '10px',
    paddingBottom: '10px',
})

export const EditModalBoxInvested = chakra(EditModalBox, {
    marginLeft: '15px',
})

export const EditModalErrorBox = chakra(Flex, {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'start',
    width: '100%',
})

export const ParamEditModalBox = chakra(Flex, {
    paddingBottom: '5px',
    paddingLeft: '2px',
    alignItems: 'center',
})

export const ParamEditModalBoxGlobal = chakra(Box, {
    paddingLeft: '20px',
    border: '1px solid',
    paddingRight: '10px',
    paddingBottom: '10px',
    paddingTop: '5px',
})

export const ParamEditContainer = chakra(Box, {
    textAlign: 'left',
    paddingLeft: '2px',
    width: '100%',
})
