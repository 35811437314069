import React, { useEffect } from 'react'
import { Box, Button, Select, Text } from '@chakra-ui/react'
import { useForm, Controller } from 'react-hook-form'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import { CounteredInput } from 'components/CounteredInput'
import { tenantsStore } from 'stores'
import { ITenantTree } from 'types/core'
import { chakra } from 'utils/chakra'
import { SaveIcon } from 'icons'
import { TENANT_CLIENT_ID_LENGTH, TENANT_AUTHORITY_URL_LENGTH } from 'constants/rules'
import { DividerStyled, SaveButtonBox } from '../Tabs/style'

export const TextStyled = chakra(Text, {
    fontSize: 'md',
    marginBottom: '10px'
})

export const SelectStyled = chakra(Select, {
    color: 'searchInputText',
})

export const AzureAdditionalSettings = observer(() => {
    const uiLoginFlows = toJS(tenantsStore.uiLoginFlow)
    const selectedTenant = tenantsStore.selectedTenant

    const {
        register,
        control,
        reset,
        watch,
        formState: { errors },
        handleSubmit
    } = useForm<ITenantTree>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: selectedTenant,
    })

    const clientId = watch('clientId', '')
    const authorityUrl = watch('authorityUrl', '')
    const clientIdRemaining = TENANT_CLIENT_ID_LENGTH - (clientId ? clientId.length : TENANT_CLIENT_ID_LENGTH)
    const authorityURLRemaining = TENANT_AUTHORITY_URL_LENGTH - (authorityUrl ? authorityUrl.length : TENANT_AUTHORITY_URL_LENGTH)

    const onSubmit = (values: ITenantTree) =>  {
        tenantsStore.saveSsoSettings(values)
    }

    useEffect(() => {
        reset(selectedTenant)
        // Need for improvement. Warnings console
        /* eslint-disable */
    }, [selectedTenant])

    return (
        <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <SaveButtonBox>
                    <Button
                        type="submit"
                        variant="blue"
                        size="sm"
                    >
                        <SaveIcon w={3} h={3} color="white" />
                    </Button>
                </SaveButtonBox>
                <DividerStyled />
                <TextStyled>Client Id</TextStyled>
                <CounteredInput
                    placeholder="Enter Client ID..."
                    size="sm"
                    marginTop="8px"
                    remaining={clientIdRemaining}
                    isInvalid={!!errors.clientId}
                    description={errors.clientId?.message}
                    {...register('clientId', {
                        required:
                            'This field cannot be blank. Please enter a tenant client id to continue.',
                        maxLength: {
                            value: TENANT_CLIENT_ID_LENGTH,
                            message: `This field max length is ${TENANT_CLIENT_ID_LENGTH}`,
                        },
                    })}
                />
                <TextStyled>Authority URL</TextStyled>
                <CounteredInput
                    placeholder="Enter Authority URL..."
                    size="sm"
                    marginTop="8px"
                    remaining={authorityURLRemaining}
                    isInvalid={!!errors.authorityUrl}
                    description={errors.authorityUrl?.message}
                    {...register('authorityUrl', {
                        required:
                            'This field cannot be blank. Please enter an Authority URL to continue.',
                        maxLength: {
                            value: TENANT_AUTHORITY_URL_LENGTH,
                            message: `This field max length is ${TENANT_AUTHORITY_URL_LENGTH}`,
                        },
                    })}
                />
                <TextStyled>Login mode</TextStyled>
                <Controller
                    control={control}
                    name="uiLoginFlow"
                    key="uiLoginFlow"
                    render={({ field: { onChange, value, ref } }) =>
                        <SelectStyled
                            onChange={(e)=> onChange(e.target.value)}
                            value={value || ''}
                            ref={ref}
                        >
                            <option hidden disabled value="">Select login mode</option>
                            {uiLoginFlows.map(item =>
                                <option
                                    key={item}
                                    value={item}
                                >
                                    {item}
                                </option>
                            )}
                        </SelectStyled>
                    }
                />
            </form>
        </Box>
    )
})
