import { useDisclosure } from '@chakra-ui/hooks'
import { Box, FlexProps } from '@chakra-ui/layout'
import { Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/popover'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { FC, useState, useEffect } from 'react'
import { orderStore } from 'stores'
import { CalendarBody } from './content/CalendarBody'
import { CalendarNav } from './content/CalendarNav'
import { RangePickerContextProvider } from './context'
import { dateRangeStore } from './store'
import { RangePickerResult } from './types'

interface DateRangePickerProps extends FlexProps {
    onChangeRange: (range: RangePickerResult) => void
    dateRangePickerSwitch: boolean
    filterType: string | null
}

export const DateRangePicker: FC<DateRangePickerProps> = observer(
    ({ dateRangePickerSwitch, filterType, onChangeRange, width = '520px', ...props }) => {
        const { onOpen, onClose, isOpen } = useDisclosure()

        const onPopoverClose = () => {
            dateRangeStore.unselecting()
            onClose()
        }

        const [calendarStartDate, setCalendarStartDate] = useState<Date | null>(null)
        const [calendarEndDate, setCalendarEndDate] = useState<Date | null>(null)
        const [showLeftYears, setShowLeftYears] = useState(false)
        const [showRightYears, setShowRightYears] = useState(false)

        const createdFromStore = toJS(orderStore.filters.createdFrom)
        const createdToStore = toJS(orderStore.filters.createdTo)
        const deliveryFromStore = toJS(orderStore.filters.deliveryFrom)
        const deliveryToStore = toJS(orderStore.filters.deliveryTo)

        const onLeftYear = () => setShowLeftYears(!showLeftYears)
        
        const onRightYear = () => setShowRightYears(!showRightYears)

        const selectDate = (date: Date) => {
            if (calendarStartDate && calendarEndDate) {
                setCalendarStartDate(date)
                setCalendarEndDate(null)
                } else if (calendarStartDate) setCalendarEndDate(date)
                else if (!calendarStartDate && calendarEndDate) setCalendarStartDate(date)
                else setCalendarStartDate(date)
        }

        const clearAllDates = () => {
            setCalendarStartDate(null)
            setCalendarEndDate(null)
        }

        const showDateRange = () => {
            calendarStartDate && dateRangeStore.selectDate(calendarStartDate)
            calendarEndDate && dateRangeStore.selectDate(calendarEndDate)
            calendarStartDate && dateRangeStore.currentMonth()
        }

        const onPopoverOpen = () => {
            onOpen()
            dateRangeStore.clearAll()
            showDateRange()
        }

        const clearStartDate = () => setCalendarStartDate(null)

        const clearEndDate = () => setCalendarEndDate(null)

        useEffect(() => {
            dateRangeStore.setIsNoEndDate(dateRangePickerSwitch)
        }, [dateRangePickerSwitch])

        useEffect(() => {
            if (filterType === 'created') {
                createdFromStore &&
                    setCalendarStartDate(new Date(createdFromStore))
                createdToStore && setCalendarEndDate(new Date(createdToStore))
            } else if (filterType === 'delivery') {
                deliveryFromStore &&
                    setCalendarStartDate(new Date(deliveryFromStore))
                deliveryToStore && setCalendarEndDate(new Date(deliveryToStore))
            }
        }, [filterType, createdFromStore, createdToStore, deliveryFromStore, deliveryToStore])

        return (
            <RangePickerContextProvider
                value={{
                    onChangeRange,
                }}
            >
                <Popover
                    isOpen={isOpen}
                    onOpen={onPopoverOpen}
                    onClose={onPopoverClose}
                >
                    <PopoverTrigger>
                        <Box height="68px" width={dateRangePickerSwitch ? '520px' : '260px'} {...props}>
                            <CalendarNav
                                clearStartDate={clearStartDate}
                                clearEndDate={clearEndDate}
                                calendarStartDate={calendarStartDate}
                                calendarEndDate={calendarEndDate}
                                dateRangePicker={dateRangePickerSwitch}
                            />
                        </Box>
                    </PopoverTrigger>
                    <PopoverContent
                        width={width}
                        _focus={{
                            border: 'none',
                            outline: 'none',
                        }}
                        border="none"
                        backgroundColor="dateRangePicker.700"
                        boxShadow="0px 2px 6px rgba(0, 0, 0, 0.05)"
                        padding="20px 18px"
                    >
                        <CalendarBody
                            selectDate={selectDate}
                            clearAllDates={clearAllDates}
                            isDateRangePicker={dateRangePickerSwitch}
                            onClose={onPopoverClose}
                            showLeftYears={showLeftYears}
                            showRightYears={showRightYears}
                            onLeftYear={onLeftYear}
                            onRightYear={onRightYear}
                        />
                    </PopoverContent>
                </Popover>
            </RangePickerContextProvider>
        )
    }
)
