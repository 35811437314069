import { makeAutoObservable } from 'mobx'

type ProgressStep = 0 | 1 | 2 | 3 | 4

const mapStepToProgress: Record<ProgressStep, number> = {
    0: 0,
    1: 33,
    2: 66,
    3: 90,
    4: 100
}

export class ProgressStore {
    public progress: number | null = null
    private isMoving: boolean = false

    constructor() {
        makeAutoObservable(this)
    }

    public startMoving() {
        this.isMoving = true
    }

    public showProgress(progress: number) {
        this.progress = progress
        this.isMoving = false
    }

    public showStepProgress(step: ProgressStep) {
        this.showProgress(mapStepToProgress[step])
    }

    public hideProgress() {
        if (!this.isMoving) {
            this.progress = null
        }
    }
}
