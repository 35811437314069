import React, { ChangeEventHandler, FC } from 'react'
import { ChakraProps } from '@chakra-ui/react'
import { SearchIcon, CrossSmallIcon } from 'icons'
import { InputSearchBox, SearchIconBox, ClearIconBox, SearchInputStyled } from './styles'

interface InputSearchProps extends ChakraProps{
    placeholder: string
    searchValue: string
    onChange: ChangeEventHandler<HTMLInputElement>
    clearable?: boolean
    handleClear?: () => void
    borderColor?: string
}

export const InputSearch: FC<InputSearchProps> = ({
    placeholder,
    searchValue,
    onChange,
    clearable = false,
    handleClear,
    borderColor = 'divider',
    ...props
}) => {
    return (
        <InputSearchBox {...props}>
            <SearchIconBox>
                <SearchIcon boxSize="20px" color="color.100" />
            </SearchIconBox>
            <SearchInputStyled
                placeholder={placeholder}
                value={searchValue}
                onChange={onChange}
                borderColor={borderColor}
            />
            {clearable && searchValue && handleClear && (
                <ClearIconBox
                    aria-label="Bell Icon"
                    onClick={handleClear}
                    icon={<CrossSmallIcon boxSize="13px" color="color.100" />}
                />
            )}
        </InputSearchBox>
    )
}
