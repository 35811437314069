import React, { useEffect, useState } from 'react'
import { Box } from '@chakra-ui/layout'
import { observer } from 'mobx-react-lite'
import { mappingStore, notificationStore } from 'stores'
import { Button } from '@chakra-ui/button'
import { useDisclosure } from '@chakra-ui/react'
import { StatusType } from 'types/chart'
import { ComponentModal } from 'components/Modal/ComponentModal'
import { EditItemComponent } from './EditModal'
import { AddItemComponent } from './AddModal'
import { JsonBaseBox, JsonCenterBox, JsonValueBox } from '../styles'
import { toJS } from 'mobx'

interface IJsonComponent {
    disabled: boolean
    data: object | string
    theme?: string
    type: string
    param: string
    handleSave: (value: object) => void
    handleSaveCopy: (value: object) => void
}

interface ICheckIsAddItem { type: string }

export const typeValue = ['', 'value', 'array', 'group', 'object']

export const checkAddBtn = ['array', 'group', 'object']

export const JsonComponent = observer(({ data, type, param, handleSave, disabled, handleSaveCopy }: IJsonComponent) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isOpenModalAdd, setOpenModalAdd] = useState(false)
    const [jsonData, setJsonData] = useState({})
    const [arrKeysModal, setArrKeysModal] = useState([''])
    const [valueModal, setValueModal] = useState({})
    const copyData = toJS(mappingStore.copyData)

    useEffect(() => {
        if (!!data && typeof data === 'string') {
            try {
                setJsonData(JSON.parse(data))
            } catch (error) {
                setJsonData({})
                notificationStore.toast({
                    title: 'Error',
                    description: 'This not JSON',
                    status: StatusType.Error,
                })
            }

        } else {
            setJsonData(data || {})
        }
    }, [data])

    const handleChangeJson = (value: object) => {
        mappingStore.updateDataJson(value, type, param)
        setJsonData(value)
    }

    const onCloseModal = () => {
        mappingStore.initData(copyData)
        onClose()
    }

    const onAddModal = (value: string[]) => {
        setOpenModalAdd(true)
        setArrKeysModal([...value])
    }

    const checkIsObj = (value: unknown) => {
        if (
            typeof value === 'object' &&
            value !== undefined &&
            value !== null
        ) {
            return Object.keys(value)[0] === '__metadata'
        }
        return false
    }

    const checkIsAddItem = (value: ICheckIsAddItem) => {
        if (typeof value === 'object') {
            return checkAddBtn.find(el => value?.type?.toLowerCase() === el)
        }
        return false
    }

    const transformValue = (key: string, value: unknown, arrKeys: string[]) => {
        const saveParamModalEdit = (value: any, arrKeys: string[]) => {
            setArrKeysModal(arrKeys)
            setValueModal(value)
            onOpen()
        }

        const deleteItem = (item: string, arr: string[]) => {
            const copyObj: { [key: string]: unknown } = { ...jsonData }
            if (!arr.length) {
                delete copyObj[item]
            } else {
                [...arr].reduce((acc: any, path, index) => {
                    if (index === arr.length - 2 && acc instanceof Object && acc.constructor === Object) {
                        delete acc[path]
                        return acc
                    }
                    if (acc instanceof Object && acc.constructor === Object) {
                        return acc[path]
                    }
                    return acc
                }, copyObj)
            }
            setJsonData(copyObj)
            handleChangeJson(copyObj)
            handleSave(copyObj)
            handleSaveCopy(copyObj)
        }

        return (
            <Box display='flex'>
                <Box>{typeof value === 'string' ? value : '...'}</Box>
                <Button
                    size='xs'
                    variant='ghost'
                    colorScheme='blue'
                    onClick={() => saveParamModalEdit(value, arrKeys)}
                >
                    Edit
                </Button>
                <Button
                    size='xs'
                    variant='ghost'
                    colorScheme='red'
                    onClick={() => deleteItem(key, arrKeys)}
                >
                    Delete
                </Button>
            </Box>
        )
    }

    const transformObject = (data: unknown) => {
        const isObj = data instanceof Object && data.constructor === Object
        if (isObj) {
            if (Object.entries(data).length) {
                return Object.entries(data)
            }
            return []
        } else if (Array.isArray(data)) {
            return data.reduce((acc: [string, unknown | any], element, index) => {
                const elementArr = [index.toString(), element]
                acc.push(elementArr)
                return acc
            }, [])
        }
    }

    const displayValue = (data: unknown, arr: string[]) => {
        if (!transformObject(data).length) {
            return (
                <Box>
                    <Button
                        disabled={disabled}
                        size='xs'
                        variant='ghost'
                        colorScheme='yellow'
                        onClick={() => onAddModal(arr)}
                    >
                        Add
                    </Button>
                </Box>
            )
        } else {
            return (
                <Box>
                    {transformObject(data).map((key: [string, unknown | any], index: number) => {
                        return (
                            <Box key={`${key[0]}+${index}`}>
                                {!index && !arr.length && (
                                    <Button
                                        size='xs'
                                        variant='ghost'
                                        colorScheme='yellow'
                                        onClick={() => onAddModal(arr)}
                                    >
                                        Add
                                    </Button>
                                )}
                                <JsonBaseBox>
                                    <Box>{key[0] === '__metadata' ? 'metadata' : key[0]}:</Box>
                                    {checkIsObj(key[1]) || Array.isArray(key[1]) ? (
                                        <JsonValueBox>
                                            {displayValue(key[1], [...arr, key[0]])}
                                        </JsonValueBox>
                                    ) : (
                                        <JsonCenterBox>
                                            <JsonValueBox>
                                                {transformValue(key[0], key[1], [
                                                    ...arr,
                                                    key[0],
                                                ])}
                                                {checkIsAddItem(key[1]) && (
                                                    <Button
                                                        size='xs'
                                                        variant='ghost'
                                                        colorScheme='yellow'
                                                        onClick={() => onAddModal(arr)}
                                                    >
                                                        Add
                                                    </Button>
                                                )}
                                            </JsonValueBox>
                                        </JsonCenterBox>
                                    )}
                                </JsonBaseBox>
                            </Box>
                        )
                    })}
                </Box>
            )
        }
    }

    return (
        <Box width='100%'>
            {displayValue(jsonData, [])}

            <ComponentModal
                isOpen={isOpen}
                onClose={onCloseModal}
                headerText='Object Details'
                reactComponent={
                    <EditItemComponent
                        value={valueModal}
                        arrKeys={arrKeysModal}
                        onClose={onCloseModal}
                        onCloseSave={() => onClose()}
                        jsonData={jsonData}
                        setJsonData={setJsonData}
                        handleSave={handleSave}
                        handleSaveCopy={handleSaveCopy}
                    />
                }
            />
            <ComponentModal
                isOpen={isOpenModalAdd}
                onClose={() => setOpenModalAdd(false)}
                headerText='Add new Item'
                reactComponent={
                    <AddItemComponent
                        arrKeys={arrKeysModal}
                        onClose={() => setOpenModalAdd(false)}
                        jsonData={jsonData}
                        setJsonData={setJsonData}
                        handleSave={handleSave}
                        handleSaveCopy={handleSaveCopy}
                    />
                }
            />
        </Box>
    )
})
