import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { v4, validate } from 'uuid'
import { Button, useColorMode } from '@chakra-ui/react'
import { isEqual } from 'lodash'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { MultiValue, SingleValue } from 'react-select'
import { CustomSelect as Select } from 'components/baseComponents/Select'
import { adaptedRoles } from 'pages/AdminPages/Tenants/TenantsPage/utils'
import { camelToSpace } from 'utils/stringTransform'
import { tenantsGroupMappingStore, tenantsStore, userStore } from 'stores'
import { UserRole } from 'api/models'
import { ISelectOption, UUID } from 'types/core'
import { SaveIcon } from 'icons'
import {
    DividerStyled,
    SaveButtonBox,
    SaveInfoText,
} from 'components/TenantsComponents/Tabs/style'
import { reactSelectStyles } from 'components/baseComponents/Select/styles'
import {
    ButtonStyled,
    GroupIdContainer,
    GroupIdInput,
    SelectBox,
    SelectContainer,
    TenantsBox,
    Title,
} from './styles'

export const TenantsGroupMapping: FC = observer(() => {
    const [errors, setErrors] = useState<UUID[]>([])
    const { colorMode } = useColorMode()
    const { id, authenticationType } = toJS(tenantsStore.selectedTenant)
    const tenantSupportRoles = toJS(tenantsGroupMappingStore.supportRoles)
    const tenantRoles = toJS(tenantsGroupMappingStore.roles)
    const tenantSelectedRoles = toJS(tenantsGroupMappingStore.selectedRoles)

    const defaultSelectedRoles = toJS(
        tenantsGroupMappingStore.defaultSelectedRoles
    )
    const { isTenantAdmin, isSuperAdmin } = toJS(userStore.currentRoles)
    const saveInfo =
        isEqual(tenantSelectedRoles, defaultSelectedRoles) || !!errors.length

    const handleChangeRoles = (
        value: SingleValue<ISelectOption> | MultiValue<ISelectOption>,
        id: UUID,
        groupId: UUID
    ): void => {
        if (value && 'value' in value && value.value) {
            tenantsGroupMappingStore.updateSelectedRoles({
                id,
                groupId,
                roleName: value.value,
                isNewRow: false,
            })
        }
    }

    const handleChangeIds = (
        value: ChangeEvent<HTMLInputElement>,
        id: UUID
    ): void => {
        if (validate(value.target.value)) {
            tenantsGroupMappingStore.updateSelectedIds(value.target.value, id)
            setErrors(prev => prev.filter(errorId => errorId !== id))
        } else {
            setErrors(prev => [...prev, id])
        }
    }

    const saveRoles = (): void => {
        tenantsGroupMappingStore.saveSelectedRoles(id, authenticationType)
    }

    const addRole = (): void => {
        const id = v4()
        tenantsGroupMappingStore.addSelectedRole(id)
        setErrors(prev => [...prev, id])
    }

    const deleteRole = (id: UUID): void => {
        tenantsGroupMappingStore.deleteSelectedRole(id)
        setErrors(prev => prev.filter(errorId => errorId !== id))
    }

    useEffect(() => {
        tenantsGroupMappingStore.getTenantRoles()
        if (id && authenticationType === 'azure_ad')
            tenantsGroupMappingStore.fetch(id, authenticationType)
    }, [id, authenticationType])

    return (
        <>
            <SaveButtonBox>
                <Button
                    type="submit"
                    variant="blue"
                    size="sm"
                    disabled={saveInfo}
                    onClick={saveRoles}
                >
                    <SaveIcon w={3} h={3} color="white" />
                </Button>
                {!saveInfo && (
                    <SaveInfoText>
                        Changes are detected, you can save them
                    </SaveInfoText>
                )}
            </SaveButtonBox>
            <DividerStyled />
            <TenantsBox>
                {!!tenantSelectedRoles.length && (
                    <GroupIdContainer>
                        <Title marginLeft="20px">AAD Group Id</Title>
                        {tenantSelectedRoles.map(({ id, groupId }) => {
                            const findError = errors.find(
                                errorId => errorId === id
                            )
                            const findRole = tenantSelectedRoles.find(
                                role => role.id === id
                            )
                            const isTenantRole =
                                findRole?.roleName === UserRole.TenantAdmin

                            const isNewRow = findRole?.isNewRow

                            return (
                                <GroupIdInput
                                    key={id}
                                    value={groupId}
                                    isDisabled={
                                        !isNewRow &&
                                        isTenantRole &&
                                        !isTenantAdmin &&
                                        !isSuperAdmin
                                    }
                                    onChange={value =>
                                        handleChangeIds(value, id)
                                    }
                                    border={
                                        findError ? '2px solid' : '1px solid'
                                    }
                                    borderColor={
                                        findError ? 'red.500' : 'gray.600'
                                    }
                                />
                            )
                        })}
                    </GroupIdContainer>
                )}
                {!!tenantSelectedRoles.length && (
                    <SelectContainer>
                        <Title marginLeft="30px">Role</Title>
                        {tenantSelectedRoles.map(({ id, groupId }) => {
                            const findRole =
                                tenantSelectedRoles.find(
                                    role => role.id === id
                                ) || null

                            const isNewRow = findRole?.isNewRow

                            const isTenantRole =
                                findRole?.roleName === UserRole.TenantAdmin

                            const selectValue =
                                isNewRow && !isTenantAdmin
                                    ? adaptedRoles(tenantSupportRoles)[0]
                                    : findRole
                                    ? {
                                          value: findRole.roleName,
                                          label: camelToSpace(
                                              findRole.roleName
                                          ),
                                      }
                                    : findRole

                            const options =
                                isTenantAdmin || isSuperAdmin
                                    ? adaptedRoles(tenantRoles)
                                    : adaptedRoles(tenantSupportRoles)

                            return (
                                <SelectBox key={id}>
                                    <Select
                                        selectValue={selectValue}
                                        options={options}
                                        placeholder="Tenant roles"
                                        onChange={value =>
                                            handleChangeRoles(
                                                value,
                                                id,
                                                groupId
                                            )
                                        }
                                        isDisabled={
                                            !isNewRow &&
                                            isTenantRole &&
                                            !isTenantAdmin &&
                                            !isSuperAdmin
                                        }
                                        customStyles={reactSelectStyles(
                                            colorMode,
                                            '140px',
                                            '16px',
                                            '75px'
                                        )}
                                    />
                                    <ButtonStyled
                                        variant="blue"
                                        disabled={
                                            !isNewRow &&
                                            isTenantRole &&
                                            !isTenantAdmin &&
                                            !isSuperAdmin
                                        }
                                        onClick={() => deleteRole(id)}
                                    >
                                        -
                                    </ButtonStyled>
                                </SelectBox>
                            )
                        })}
                    </SelectContainer>
                )}
            </TenantsBox>
            <ButtonStyled variant="blue" onClick={addRole}>Add new role</ButtonStyled>
        </>
    )
})
