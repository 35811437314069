import React, { FC } from 'react'
import { format } from 'date-fns'
import { AccordionIcon } from '@chakra-ui/accordion'
import { Center } from '@chakra-ui/layout'
import { RuleStatus } from 'components/RuleStatus'
import { setRectangleIcon } from 'utils/setRectangleIcon'
import { IWorkflow } from 'types/core'
import { dateToLocal } from 'utils/dateToLocal'
import {
    AccordionButtonBox,
    AccordionButtonItem,
    AccordionButtonItemBox,
    RectangleIconBox,
} from 'pages/OrderDetailsPage/styles'

interface AccordionRulesEngineButtonProps {
    workflow: IWorkflow
}

export const AccordionRulesEngineButton: FC<AccordionRulesEngineButtonProps> = ({
    workflow: {
        name= '-',
        status,
        started,
    },
}) => {
    const localDate = dateToLocal(started)

    return (
        <AccordionButtonBox backgroundColor="blueWhite.990">
            <RectangleIconBox>{setRectangleIcon(status)}</RectangleIconBox>
            <AccordionButtonItem variant="h5" flexGrow={2}>{name}</AccordionButtonItem>
            <AccordionButtonItem variant="linksmall" flexGrow={1}>
                {started ? format(new Date(localDate), 'LLL. dd, yyyy') : '-'}
            </AccordionButtonItem>
            <AccordionButtonItem variant="linksmall" flexGrow={1}>
                {started ? format(new Date(localDate), "hh:mm:ss aaaaa'm'") : '-'}
            </AccordionButtonItem>
            <AccordionButtonItemBox flexGrow={5}>
                <Center justifyContent="flex-start">
                    <RuleStatus status={status} />
                </Center>
            </AccordionButtonItemBox>
            <AccordionIcon color="grayBlue.400" flexBasis={1} flexGrow={0.3} />
        </AccordionButtonBox>
    )
}
