import { Center } from '@chakra-ui/layout'
import {
    CategoryDistSourcesIcon,
    CategoryMasterDataIcon,
    CategoryVariablesIcon,
    CategoryCustomBlockIcon,
    CategorySplitBlockIcon,
} from 'icons'
import { SplitBlockIcon } from 'icons/style'
import { StepCategory } from 'types/core'

export const categoriesIcons: {
    [category: string]: JSX.Element
} = {
    [StepCategory.MasterData]: (
        <Center
            width="18px"
            height="18px"
            borderRadius="50%"
            backgroundColor="peony.500"
            paddingTop="2px"
        >
            <CategoryMasterDataIcon boxSize="11px" />
        </Center>
    ),
    [StepCategory.Variable]: (
        <Center
            width="18px"
            height="18px"
            borderRadius="50%"
            backgroundColor="bluebell.100"
            paddingTop="2px"
        >
            <CategoryVariablesIcon boxSize="12px" />
        </Center>
    ),
    [StepCategory.DistributionSource]: (
        <Center
            width="18px"
            height="18px"
            borderRadius="50%"
            backgroundColor="sherbet"
            paddingTop="2px"
        >
            <CategoryDistSourcesIcon boxSize="12px" />
        </Center>
    ),
    [StepCategory.CustomBlock]: (
        <Center
            width="18px"
            height="18px"
            borderRadius="50%"
            backgroundColor="red.500"
            paddingRight="1px"
        >
            <CategoryCustomBlockIcon boxSize="12px" />
        </Center>
    ),
    [StepCategory.SplitOrderBlock]: (
        <SplitBlockIcon>{<CategorySplitBlockIcon />}</SplitBlockIcon>
    ),
}
