import { Box } from '@chakra-ui/layout'
import { chakra } from 'utils/chakra'

export const EChartsReact = chakra(Box, {
    width: '100%',
    padding: '0px 0px 0px 0px',
})

export const ChartBox = chakra(Box, {
    
})