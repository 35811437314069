import React, { FC } from 'react'
import { Checkbox, CheckboxGroup, Collapse, useDisclosure } from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'
import { orderStore } from 'stores'
import { IRenderOrderFilters } from 'types/core'
import { SimpleArrowDownIcon, SimpleArrowRightIcon } from 'icons'
import { CollapseBox, FilterText, CheckboxesBox } from './styles'

export const CollapseCheckbox: FC<IRenderOrderFilters> = observer(({ filter, isEditFilters }) => {
    const { isOpen, onToggle } = useDisclosure()
    const filters = isEditFilters ? orderStore.editFilters : orderStore.filters

    const handleChange = (values: string[] | number[]): void => {
        orderStore.setFilters(values, filter.value)
    }

    const getFilterValues = () => {
        if (filter.value === 'status') return orderStore.status
        if (filter.value === 'distributionSource') return orderStore.distributionSource
        if (filter.value === 'externalSource') return orderStore.externalSource

        return []
    }

    const getSelectedValues = (): string[] => {
        const filtered = []
        if (filter.value === 'status') {
            filtered.push(
                ...orderStore.status.filter(
                    ({ enumValue }) => filters.statuses.some(value => enumValue.includes(value))
                )
            )
        }
        if (filter.value === 'distributionSource') {
            filtered.push(
                ...orderStore.distributionSource.filter(
                    ({ code }) => filters.ShipmentProviderCodes?.includes(code)
                )
            )
        }
        if (filter.value === 'externalSource') {
            filtered.push(
                ...orderStore.externalSource.filter(
                    ({ id }) => filters.orderSourceIds.includes(id)
                )
            )
        }

        return filtered.map(({ name }) => name)
    }

    return (
        <>
            <CollapseBox onClick={onToggle}>
                {isOpen ? (
                    <SimpleArrowDownIcon
                        boxSize="15px"
                        color="blue.300"
                        marginRight="5px"
                    />
                ) : (
                    <SimpleArrowRightIcon
                        boxSize="15px"
                        color="color.100"
                        marginRight="5px"
                    />
                )}
                <FilterText>{filter.name}</FilterText>
            </CollapseBox>
            <Collapse in={isOpen} animateOpacity>
                <CheckboxGroup
                    onChange={handleChange}
                    value={getSelectedValues()}
                >
                    <CheckboxesBox>
                        {getFilterValues().length ? getFilterValues().map(({ name }) =>
                            <Checkbox
                                margin="5px 5px 5px 20px"
                                key={name}
                                value={name}
                            >
                                {name}
                            </Checkbox>
                        ) : (
                            <div>No content</div>
                        )}
                    </CheckboxesBox>
                </CheckboxGroup>
            </Collapse>
        </>
    )
})
