import AuthNoAuthPage from 'pages/AdminPages/Gateway/AuthNoAuthPage'
import AdminSecretsPage from 'pages/AdminPages/Gateway/SecretsPage'
import AdminMappingPage from 'pages/AdminPages/Gateway/MappingPage'
import { AdminChannelsPage } from 'pages/AdminPages/Gateway/ChannelsPage'
import { AdminAuthApiKeyPage } from 'pages/AdminPages/Gateway/AuthApiKeyPage'
import { AdminResourceOwnersAuthPage } from 'pages/AdminPages/Gateway/ResourceOwnersAuthPage'
import { AdminAuthenticationSchemaPage } from 'pages/AdminPages/Gateway/AuthenticationSchemaPage'
import { GlobalFlagsTab } from 'pages/AdminPages/FeatureFlags/Global'
import { TenantsFlagsTab } from 'pages/AdminPages/FeatureFlags/Tenants'

export const gateWayTabsList = [
    { headTab: 'Channels', bodyTab: <AdminChannelsPage /> },
    { headTab: 'Auth No Auth', bodyTab: <AuthNoAuthPage /> },
    {
        headTab: 'Resource Owners Auth',
        bodyTab: <AdminResourceOwnersAuthPage />,
    },
    {
        headTab: 'Auth Api Key',
        bodyTab: <AdminAuthApiKeyPage />,
    },
    { headTab: 'Secrets', bodyTab: <AdminSecretsPage /> },
    {
        headTab: 'Authentication Schema',
        bodyTab: <AdminAuthenticationSchemaPage />,
    },
    { headTab: 'Mapping', bodyTab: <AdminMappingPage /> },
]

export const featureFlagsTabsList = [
    { headTab: 'Global', bodyTab: <GlobalFlagsTab /> },
    { headTab: 'Per Tenant', bodyTab: <TenantsFlagsTab />},
]

export const FEATURE_FLAG_NAME_LENGTH = 3
