import {
    Control,
    FieldErrors,
    FieldValues,
    UseFormRegister,
} from 'react-hook-form'
import { Style } from '@react-pdf/types'
import { UiLoginFlow } from './api'
import { UserRole } from 'api/models'

export type UUID = string

export interface IOrderSource {
    id: string
    name: string
    icon: string
}

export interface ISimulateOrder {
    customerCode: string
    orderSourceExternalOrderId: string
    orderType: string
    expectedDeliveryDate: string
    rawData: string
    customerExternalOrderId?: string
    paymentTerm?: string
    salesOffice?: string
    orderItems: IOrderItems[]
    notes?: IOrderNotes[]
}

export interface IOrderItems {
    productCode: string | null
    quantity: number | null
    quantityUnit: string | null
    orderSourceNumber: string | null
}

export interface IOrderNotes {
    id: string | number
    code: string | null
    value?: string | null
    externalCode?: string | null
}

export interface IShipper {
    code: string
    id: string
    name: string
}

export interface IPackage {
    id: string
    tenantId: string
    shipmentStatus: string
    shipmentStatusTimestamp: Date
    packageItems: PackageItem[]
    orderShipmentId: string
    trackingNumber: string
    shipmentNumber: string
    deliveryDate: Date
    ecommerceExternalId: string
}

export interface IShipmentStatusHistory {
    id: UUID
    tenantId: UUID
    packageId: UUID
    shipmentExternalStatusCode: string
    shipmentExternalStatusDescription: string
    shipmentStatus: string
    shipmentStatusTimestamp: Date
    created: Date
}

export interface PackageItem {
    id: string
    tenantId: string
    packageId: string
    orderItemId: string
    quantity: number
    quantityUnit: string
    orderItem: IOrderItem
}

export interface ItemShippingOrder {
    packageId: string
    name: string
    productCode: string
    quantity: number
    deliveryMethod: string
    trackingNumber: string
    shipmentNumber: string
    deliveryStatus: string
}

export interface IOrderShipment {
    id: UUID
    customerAddressId: UUID
    shipperId: UUID
    shippingLocationAddressId: UUID
    tenantId: UUID
    shipper: IShipper
    deliveryDate: Date
    packages: IPackage[]
    providerCode?: string
}

export enum StepStatus {
    Started = 'Started',
    Success = 'Success',
    Waiting = 'Waiting',
    Failed = 'Wailed',
}

export enum OrderFilters {
    Created = 'created',
    Status = 'status',
    Delivery = 'delivery',
    DistributionSource = 'distribution source',
    ExternalSource = 'external source',
}

export interface IOrderExternalSource {
    id: string
    code: string
    name: string
    description: string
}

export interface IOrderDistributionSource extends IOrderExternalSource {
    isActive: boolean
}

export interface IOrderFilter {
    value: string
    name: OrderFilters
}

export interface IRenderOrderFilters {
    filter: IOrderFilter
    isEditFilters: boolean
}

export interface IOrderStatus {
    id: string
    name: string
    enumValue: string[]
}

export interface IWorkflowStep {
    id: UUID
    category: string
    name: string
    status: StepStatus
    started: Date
    completed: Date
    selectedOutput: number
}

export interface IRoutingRule {
    location: string
    routingRuleName: string
    ignoreCase: boolean
}

export interface ITenantTree extends ITenantsTreeSettings {
    id: string
    name: string
    domain: string
}

interface ITenantsTreeSettings {
    hasMasterData: boolean
    hasAuth: boolean
    allowPermissionsInheritance: boolean
    enableSubTenantRouting: boolean
    authenticationType: string | null
    clientId?: string
    authorityUrl?: string
    uiLoginFlow?: UiLoginFlow
    location?: string
    routingRuleName?: string
    ignoreCase?: boolean
}

export interface ITenantsAccessHierarchy {
    tenantId: string
    parentTenantId: string
    tenantName: string
    roles: UserRole[]
    children?: ITenantsAccessHierarchy[]
}

export interface IWorkflow {
    id: UUID
    name: string
    status: WorkflowRunStatus
    started: Date
    completed: Date
    type: string | null
}

export interface IWorkflowRuns {
    workflow: IWorkflow
    steps: IWorkflowStep[]
}

export interface IOrderItem {
    id: UUID
    tenantId: UUID
    orderId: UUID
    name: string
    quantity: number
    quantityUnit: string
    productCode: string
    orderSourceNumber: string
    customerProductNumber: string
}

export interface IRunParameter {
    id: UUID
    name: string
    value: string | string[]
    isSelect: boolean
}

export interface IRunValue {
    name: string
    value: string
    isSelect?: boolean
    id?: UUID
}

export interface IRunValues {
    stepId: UUID
    parameters: IRunParameter[]
    values: IRunValue[]
}

export enum WorkflowStatus {
    Obsoleted = 'obsoleted',
    Approval = 'submitted-for-approval',
    Draft = 'draft',
    Approved = 'approved',
    Rejected = 'rejected',
    Created = 'created',
    Error = 'source/plant determination FAILURE',
}

export interface CreateRuleForm {
    name: string
    description: string
    message: string
    comment: string
}

export enum WorkflowRunStatus {
    Started = 'Started',
    Processing = 'Processing',
    Skipped = 'Skipped',
    Success = 'Success',
    Failed = 'Failed',
}

export enum StepCategory {
    Source = 'Source',
    MasterData = 'Master Data',
    Variable = 'Variables',
    DistributionSource = 'Dist. Sources',
    CustomBlock = 'Custom Attributes',
    SplitOrderBlock = 'Split Order',
}

export interface ShortWorkflow {
    id: UUID
    name: string
    description: string
    trigger: string | null
    status: WorkflowStatus
    createdOn: string
    modifiedAt: string
    version: number
    workflowHeaderId: UUID
    createdByUser?: ShortWorkflowUser
    approvedByUser?: ShortWorkflowUser
    wows?: Wow[]
}

export interface ShortWorkflowUser {
    id: UUID
    email: string
    firstName: string
    lastName: string
}

export interface ChangedWorkflow {
    name?: string
    description?: string
    trigger?: string | null
    status?: WorkflowStatus
}

export interface Workflow extends Omit<ShortWorkflow, 'trigger'> {
    steps: WorkflowStep[]
}

export interface CreatedWorkflow {
    id: UUID
    name: string
}

export interface WorkflowApproval {
    status: WorkflowStatus
    startDateTime: string
    endDateTime: string
    comment?: string
}

export interface WorkflowError {
    stepId: string | null
    message: string
}

export interface WorkflowSubmitForApproval {
    approverId: UUID
    startDateTime: string
    endDateTime?: string
    comment?: string
}

export interface ApprovedRejectedWorkflow {
    id: UUID
    status: WorkflowStatus
}

export interface WorkflowStep {
    id: UUID
    stepMetadataId: UUID
    stepMetadata: StepMetadata
    order: number
    layout: LayoutData
    parameters: Parameter[]
    steps: WorkflowStep[]
}

export interface StepMetadata {
    id: UUID
    name: string
    description: string
    category: StepCategory
    outputs: number
    parameterMetadata: ParameterMetadata[]
}

export interface DependsOnMappings {
    parameter: string
    value: string
    required?: boolean
}

export interface Parameter {
    id: UUID
    stepId: UUID
    parameterMetadataId: UUID
    value: string
    parameterMetaData: ParameterMetadata
}

export interface IDependencies {
    [key: string]: string
}

export interface IDependsOnParameters {
    dependencies: IDependencies
    isDisabled: boolean
    requiredMappings: string[]
}

export interface ParameterMetadata {
    displayAs: string | null
    type: string
    subtype: string
    isExternal: boolean
    id: UUID
    name: string
    description: string
    defaultValue: string
    order: number
    displayOptions: DisplayOptions
    dependsOn?: string
}

export interface DisplayOptions {
    displayOption: string
    displayAs: string | null
    displayCsvImport: string
}

export interface AvailableParameterValue {
    key: string
    displayValue: string
}

export interface LayoutData {
    x: number
    y: number
}

export interface Wow {
    id: UUID
    name: string
    description: string
    workflows: Workflow[]
}

export interface IWow {
    id: UUID
    workflows: Workflow[]
    name: string
    description: string
}

export interface IPutWowOrderSourceMappings {
    category: string
    subcategory: string
    value: string
}

export interface IGetWowOrderSourceMappings {
    category: string
    id: string
    subcategory: string
    tenantId: string
    updatedAt: string
    value: string
}

export interface IAuditOrder {
    id: UUID
    status: {
        code: number
        description: string
    }
    timestamp: Date | string
    shortReason?: string
}

export enum OrderStatus {
    AcknowledgedByECC = 'acknowledgedByECC',
    Completed = 'completed',
    Created = 'created',
    Error = 'error',
    ErrorRuleProcessing = 'errorRuleProcessing',
    ErrorSentToECC = 'errorSentToECC',
    ErrorSentToIcep = 'errorSentToIcep',
    PreProcessingComplete = 'preProcessingComplete',
    ProcessingRule = 'processingRule',
    SentToECC = 'sentToECC',
    SentToIcep = 'sentToIcep',
    Shipment = 'shipment',
    Shipping = 'shipping',
    Delivered = 'delivered',
    PostProcessingComplete = 'postProcessingComplete',
    PartiallyDelivered = 'partiallyDelivered',
    Split = 'split',
    SplitError = 'splitError',
    CancelRequested = 'cancelRequested',
    CancelCompleted = 'cancelCompleted',
    CancelFailed = 'cancelFailed',
}

export interface IAddress {
    countryCode: string
    state?: string
    city?: string
    postalCode?: string
    addressLine1?: string
    addressLine2?: string
}

export interface IOrderClientAddress extends IAddress {}

export interface IShippingLocation {
    id: string
    code: string
    name: string
    address: IAddress
}

export interface IWorkflowEvents {
    workflowId: string
    workflowName: string
    workflowType: string
}

export enum IWorkflowEventsKeys {
    WorkflowId = 'workflowId',
    WorkflowName = 'workflowName',
    WorkflowType = 'workflowType',
}

export interface IOrder {
    id: string
    ecommerceExternalOrderId?: string
    orderSourceId?: string
    created: Date
    status: OrderStatus
    reason?: string
    expectedDeliveryDate: Date
    orderSourceExternalOrderId: string
    number: string
    isUpdateChild: boolean
    isUpdateParent: boolean

    source: IOrderSource

    orderShipments: IOrderShipment[]
    orderItems: IOrderItem[]

    customerCode: string
    customerName: string
    customerExternalOrderId: string
    customerAddress: IOrderClientAddress

    shippingLocation: IShippingLocation
    workflowEvents: IWorkflowEvents[]
    children: IOrder[]
    isSimulated: boolean
    newOrderId: string
    type: string
}

export interface ShippingCost {
    id: UUID
    number: string
    externalId: string
    clientId: string
    ecommerceId: string
    placedDate: string
    shipmentProviderId: UUID
    shipmentProviderName: string
    shipmentMethod: string
    cost: number
    itemsQuantity: number
}

export interface MappingConfiguration {
    type: string
    mapperId: string
}

export interface RoutingRule {
    location: string
    name: string
    ignoreCase: boolean
}

export interface IntegrationChannel {
    name: string
    type: string
    resourceType: string
    relativeUrl: string
    downstreamURL: string
    httpMethods: string[]
    authenticationSchemeId: string
    mappingConfiguration: MappingConfiguration
    id: string
    tenantId: string
    routingRule: RoutingRule | null
    modifiedAt: Date
}

export interface IntegrationChannelTableView {
    name: string
    type: string
    resourceType: string
    relativeUrl: string
    downstreamUrl: string
    httpMethods: string[]
    authenticationSchemeId?: string
    mappingConfiguration?: string
    mapperId: string
    id: string
    tenantId: string
    routingRule: RoutingRule | null
    modifiedAt: Date
}

export interface ResourceOwnerAuth {
    id: string
    tenantId: string
    updatedAt: Date
    name: string
    clientId: string
    clientSecret: string
    username: string
    password: string
    subcategory: string
}

export interface IResourceOwnerAuthSecret extends ResourceOwnerAuth {
    subcategory: string
}

export enum Theme {
    Light = 'light',
    Dark = 'dark',
}

export interface ISemanticTokens {
    colors: {
        [key: string]: {
            default: string
            _dark: string
        }
    }
}

export interface IThemeColors {
    [key: string]:
        | {
              [key: number]: string
          }
        | string
}

export interface ITenantsRolesData {
    groupId: UUID
    roleName: string
}

export interface ITenantsRoles extends ITenantsRolesData {
    id: UUID
    isNewRow: boolean
}

export interface ISelectOption {
    value: string
    label: string
}

export interface IMappingOption {
    label: string
    value: string
}

export interface AuthNoAuth {
    id: string
    tenantId: string
    name: string
    updatedAt: Date
    subcategory: string
}

export interface IAuthNoAuthSecret extends AuthNoAuth {
    subcategory: string
}

export interface IAuthApiKey {
    id: string
    name: string
    tenantId: string
    apiKey: string
    updatedAt: Date
    subcategory: string
}

export interface IAuthApiKeySecret extends IAuthApiKey {
    subcategory: string
}

export interface TableForm {
    errors: FieldErrors
    register: UseFormRegister<FieldValues>
    control?: Control
}

export enum LocationKeys {
    ApiKey = 'apiKey',
    ClientId = 'clientId',
    ClientSecret = 'clientSecret',
    Username = 'username',
    Password = 'password',
}

export enum ChannelsSchemaHttpMethod {
    Get = 'GET',
    Post = 'POST',
    Put = 'PUT',
    Delete = 'DELETE',
}

export enum AuthenticationSchemaLocationType {
    Header = 'Header',
    Query = 'Query',
}

export enum AuthenticationSchemaLocationFormat {
    Plain = 'Plain',
    Base64 = 'Base64',
    Urlencode = 'Urlencode',
    Basicauth = 'Basicauth',
}

export type AuthenticationSchemaLocationIndex = 0 | 1

export enum LocationSecretIdSubCategory {
    API_KEY = 'api-key',
    RESOURCE_OWNER = 'resource-owner',
    AUTH_NO_AUTH = 'auth-no-auth'
}

export enum FieldName {
    ApiKeyName = 'apiKeyName',
    ClientIdName = 'clientIdName',
    ClientSecretName = 'clientSecretName',
    ClientUsernameName = 'clientUsernameName',
    ClientPasswordName = 'clientPasswordName',
}

export enum FieldFormat {
    ApiKeyFormat = 'apiKeyFormat',
    ClientIdFormat = 'clientIdFormat',
    ClientSecretFormat = 'clientSecretFormat',
    ClientUsernameFormat = 'clientUsernameFormat',
    ClientPasswordFormat = 'clientPasswordFormat',
}

export enum FieldIndex {
    ApiKeyIndex = 'apiKeyIndex',
    ClientIdIndex = 'clientIdIndex',
    ClientSecretIndex = 'clientSecretIndex',
    ClientUsernameIndex = 'clientUsernameIndex',
    ClientPasswordIndex = 'clientPasswordIndex',
}

export enum FieldType {
    ApiKeyType = 'apiKeyType',
    ClientIdType = 'clientIdType',
    ClientSecretType = 'clientSecretType',
    ClientUsernameType = 'clientUsernameType',
    ClientPasswordType = 'clientPasswordType',
}

export type FieldNameForm = {
    [key in FieldName]?: string
}

export type FieldIndexForm = {
    [key in FieldIndex]?: AuthenticationSchemaLocationIndex
}

export type FieldFormatForm = {
    [key in FieldFormat]?: AuthenticationSchemaLocationFormat
}

export type FieldTypeForm = {
    [key in FieldType]?: AuthenticationSchemaLocationType
}

export interface AuthSchema {
    name: string
    secretId: string
    secretIdSubCategory: LocationSecretIdSubCategory
}

export type AuthenticationSchemaLocation = {
    [key in LocationKeys]?: {
        type: AuthenticationSchemaLocationType
        name: string
        format: AuthenticationSchemaLocationFormat
        index: AuthenticationSchemaLocationIndex
    }
}

export interface IAuthenticationSchema {
    id: string
    tenantId: string
    name: string
    secretId: string
    subcategory: string
    locations: AuthenticationSchemaLocation
    updatedAt: Date
}

export interface Secrets {
    id: string
    tenantId: string
    category: string
    subcategory: string
    value: string
    updatedAt: Date
}

export interface Mapping {
    id: string
    tenantId?: string
    updatedAt?: Date | string
    name: string
    requestMappingScheme: MappingScheme
    responseMappingSchemes: IResponseMappingSchemes
}

export interface FeatureFlagsGlobal {
    id: string
    enable: boolean
    updatedAt?: Date | string
    name: string
}

export interface FeatureFlagsTenants {
    id: string
    enable: boolean
    updatedAt?: Date | string
    name: string
    tenantId: string
    isHierarchical: boolean
}

export interface MappingsMetadata {
    KnownObjects: KnownObject[]
}

export interface KnownObject {
    schemeObject: object
    name: string
}

interface IResponseMappingSchemes {
    [key: string | number]: MappingScheme
}

interface MappingScheme {
    type: string
    schemeObject: object
}

export interface ShipperData {
    shippers: [
        {
            name: string
            value: number
        }
    ]
    totalOrders: number
}

export interface Shippers {
    name: string
    value: number
}

export interface OrderSourceData {
    source: string
    percentage: number
    growth: number
}

export interface ShipperDetails {
    shipperName: string
    orderCounts: OrderCount[]
}

export interface ShipperInfo {
    shipperName: string
    count: number
}

export interface OrderCount {
    count: number
    hour: number
    time: string
}

export interface CsvDataFormat {
    ShipperName: string
    Count: number
    Time: string
}

export type timeIntervel = {}

export interface graph {
    name: string
    type: string
    smooth: boolean
    lineStyle: {
        width: number
    }
    showSymbol: boolean
    areaStyle: {
        opacity: number
        color?: {}
    }
    emphasis: {
        focus: string
    }
    data: number[]
}

export interface totalGp {
    tooltip: {
        trigger: string
        axisPointer: {
            type: string
            label: {
                backgroundColor: string
            }
        }
    }
    legend: {
        top: string
        left: string
        padding: number[]
        itemWidth: number
        itemHeight: number
        textStyle: {
            color: string
        }
    }
    grid: {
        containLabel: boolean
    }
    xAxis: [
        {
            axisLabel: {
                rotate: number
                textStyle: {
                    height: number
                    color: string
                }
            }
            type: string
            boundaryGap: boolean
            data: timeIntervel
        }
    ]
    yAxis: [
        {
            name: string
            nameLocation: string
            nameTextStyle: {
                padding: number[]
                color: string
            }
            axisLabel: {
                textStyle: {
                    color: string
                }
            }
            type: string
        }
    ]
    series: graph[]
}

export interface IShiperNames {
    count: number
    shippers: IShipper[]
}

export interface IShipper {
    shipperId: string
    shipperName: string
}

export interface IOrderShipping extends IReportData {
    orderId: string
    sourceId: string
    orderDate: Date
    orderStatusDescription: string
    expectedDelivery: Date
    actualDelivery: Date
}

export interface IItem {
    shipperName: string
    orderShipping: IOrderShipping[]
}

export interface IReports {
    pageIndex: number
    pageSize: number
    totalItems: number
    totalPages: number
    items: IItem[]
}

export interface IReportSearchList {
    id: string
    name: string
    type: string
}

export interface ISourceNames {
    count: number
    names: string[]
}

export interface IOrderPerformance {
    orderDate: Date | string
    quantityReceived: number
    quantityCancelled: number
    quantityOpen: number
    quantityCompleted: number
    sourceName?: string
    created: string | Date
    expectedDeliveryDate: string | Date
}

export interface ReportData {}

export interface IProductPerformanceItem {
    orderDate: Date | string
    sourceName: string
    orderPerformance: IOrderPerformance[]
}

export interface IProductPerformance {
    pageIndex: number
    pageSize: number
    totalItems: number
    totalPages: number
    items: IProductPerformanceItem[]
}

export interface IReportData {
    created: string | Date
    expectedDeliveryDate: string | Date
}

export interface IProductPerformanceBySalesReport {
    ProductPerformanceBySalesReport: IProductPerformanceBySalesReport[]
}

export interface IProductPerformanceByTotalOrderReport {
    ProductPerformanceByTotalOrderReport: IProductPerformanceByTotalOrderReport[]
}

export interface ITotalOrdersShippedByShipper extends IReportData {
    shipper: string
    method: string
    quantity: number
    orderPercentage: number
}

export interface IErrorReportData extends IReportData {
    created: Date
    orderSourceExternalOrderId: string
    orderSource: string
    expectedDeliveryDate: Date
    status: string
    customerCode: string
    distributionSource: string
}

export interface IShippersData {
    value: string
    label: string
}

export interface IProductPerformanceSales extends IReportData {
    sku: string
    itemDescription: string
    quantity: number
    percentageOfOrders: string
    sourceName?: string
}

export interface IPPSalesOverDuration {
    sourceName: string
    productPerformance: IProductPerformanceSales[]
}

export interface IPPSalesTotalSources {
    sourceId: string
    sourceName: string
}

export interface IPPSalesSources {
    countOfSources: number
    sources: IPPSalesTotalSources[]
}

export interface IReportStyles {
    row?: {
        [key: string]: Style | string
    }
    cell1?: {
        width?: string
    }
    cell2?: {
        width?: string
    }
    cell3?: {
        width?: string
    }
}

export interface IRoutingMapOptions {
    id: string
    name: string
    domain: string
    allowPermissionsInheritance: boolean
    enableSubTenantRouting: boolean
    tenants: IRoutingMapOptions[]
}

export enum ReportType {
    onTimeShipping = 'ontime-shipping',
    errorStatusReport = 'error-status',
    performanceReceivedBySalesChannel = 'performance-by-total-orders-received-sales-channel',
    performanceSalesOverDuration = 'product-performance-sales-over-duration',
    performanceShippedByShippers = 'product-performance-shipped-shippers',
    shippingFeeReport ='shipping-fee-report'
}

export interface IAttributeSource {
    categoryId: string
    categoryName: string
    status: boolean
    attributeSourceName: string
}

export interface ICreateCategorySource {
    id: string
    name: string
    attributeSourceName?: string
}

export interface ICategory {
    categoryId: string
    categoryName: string
    attributeSourceName: string
    isSelected: boolean
    status: boolean
}

export interface IselectCategoryOption {
    id: string
    value: string
    label: string
}

export interface IFieldCategory {
    categoryId: string
    categoryName: string
    isSelected: boolean
}

export interface IFieldAttributeList {
    status: boolean
    attributeName: string
    displayName: string
    categories: IFieldCategory[]
    attributeId: string
}

export interface IFieldAttributeCategory {
    attributeItems: IFieldAttributeList[]
}

export interface IAttributeBody {
    attributeName: string
    status: boolean
    attributeId: string
    displayName: string
    categoryId: string
}

export interface ISaveAttributeList {
    body: IAttributeBody[]
}

export interface ISaveCategoryReqObj {
    body: ICreateReqBody
}

export interface ICreateReqBody {
    name: string
    attributeSourceName: string | null
    status: boolean
}

export interface ICreateCategorySource {
    id: string
    name: string
    attributeSourceName?: string
}

export interface IEditCategoryItemDetails {
    categoryId: string
    categoryName: string
    status: boolean
    attributeSourceName: string
    isSelected: boolean
}

export interface IAttributeRequest {
    value: string
    label: string
    id?: string
}

export interface IOrderRelations {
    newOrderID: string
    origininalOrderID: string
    type: string
    created: Date
    reason: string
}

export interface IOrderRelationApi {
    newOrderId: string
    originatedOrderId: string
    type: string
    created: Date
    reason: string
}

export interface IShipmentSetting {
    enabled: boolean
    ecommerceCode: string
    orderSourceCode: string
    connectionSettings?: string
}

export interface IShipmentProvider {
    id?: string
    providerType: string
    name: string
    code: string
    settings: IShipmentSetting
    shipmentMethods: IShipmentMethod[]
    tenantId: string
}

export interface IShipmentMethod {
    id?: string
    name: string
    code: string
    description: string
    data: string
    shipmentSetting: IShipmentSetting
}

export interface ICsvImportedPopupData {
    value: string
    label: string
}

export interface ICsvImport {
    key: string
}

export interface IOpenTabDetails {
    childId: string
    childrenData: IOrder[]
}