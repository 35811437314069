import React, { useEffect, useMemo, useState } from 'react'
import { Button, useDisclosure } from '@chakra-ui/react'
import { Box, Flex } from '@chakra-ui/layout'
import { Column } from 'react-table'
import { observer } from 'mobx-react-lite'
import { useForm } from 'react-hook-form'
import { toJS } from 'mobx'
import {
    notificationStore,
    resourceOwnersAuthStore,
    secretsStore,
    tenantsStore,
} from 'stores'
import { ResourceOwnerAuth } from 'types/core'
import { StatusType } from 'types/chart'
import { resourceOwnersAuthApi } from 'api/resourceOwnersAuth'
import ResourceOwnersAuthInputs from 'components/TableComponents/TableModal/ModalFormInputs/ResourceOwnersAuthInputs'
import EditableTable, { EditingRows } from 'components/TableComponents/Table'
import PasswordCell from 'components/TableComponents/PasswordCell'
import ActionsCell from 'components/TableComponents/ActionsCell'
import { NumericPagination } from 'components/NumericPagination'
import CustomModal from 'components/TableComponents/TableModal'
import { ConfirmModal } from 'components/Modal/ConfirmModal'
import { errorObjTransformString } from 'utils/objectTransform'

interface IEditingRowsData {
    name: string
    clientSecret: string
    username: string
    password: string
}

const editRowsData = {
    name: '',
    clientSecret: '',
    username: '',
    password: '',
}

export const AdminResourceOwnersAuthPage = observer(() => {
    const customToast = notificationStore.toast
    const [editingRows, setEditingRows] = useState<EditingRows>({})
    const [editingRowsData, setEditingRowsData] = useState<IEditingRowsData>(editRowsData)
    const [deleteRowIndex, setDeleteRowIndex] = useState<number>(0)
    const data: ResourceOwnerAuth[] = toJS(resourceOwnersAuthStore.data)
    const { tenantId } = tenantsStore

    const { isOpen, onOpen, onClose } = useDisclosure()
    const {
        isOpen: isOpenDelete,
        onOpen: onOpenDelete,
        onClose: onCloseDelete,
    } = useDisclosure()

    const {
        handleSubmit,
        register,
        reset,
        formState: { errors, isSubmitting },
    } = useForm()

    const columns: Column[] = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                editable: true,
            },
            {
                Header: 'Client Id',
                accessor: 'clientId',
            },
            {
                Header: 'Client Secret',
                accessor: 'clientSecret',
                editable: true,
            },
            {
                Header: 'User Name',
                accessor: 'username',
                editable: true,
            },
            {
                Header: 'Password',
                accessor: 'password',
                editable: true,
                Cell: PasswordCell,
            },
            {
                Header: 'Actions',
                accessor: 'edit',
                Cell: ActionsCell,
            },
        ],
        []
    )

    useEffect(() => {
        tenantId && resourceOwnersAuthStore.fetch(tenantId)

        return () => {
            resourceOwnersAuthStore.clean()
        }
    }, [tenantId])

    const onEdit = (id: number): void => {
        if (id) {
            setEditingRows({ [id]: true })
            setEditingRowsData({
                name: data[id].name,
                clientSecret: data[id].clientSecret,
                username: data[id].username,
                password: data[id].password,
            })
        }
    }

    const onCancelEdit = (id: string | number): void => {
        updateMyData(+id, 'name', editingRowsData.name)
        updateMyData(+id, 'clientSecret', editingRowsData.clientSecret)
        updateMyData(+id, 'username', editingRowsData.username)
        updateMyData(+id, 'password', editingRowsData.password)
        setEditingRows({ [id]: false })
    }

    const onSubmit = (data: ResourceOwnerAuth) => {
        resourceOwnersAuthApi
            .createResourceOwnerAuth(tenantId, data)
            .then(() => {
                resourceOwnersAuthStore.fetch(tenantId)
                secretsStore.fetchResourceOwners(tenantId)
                onClose()
                reset()
            })
            .catch(error => {
                if (error.response?.status === 400) {
                    customToast({
                        title: 'Error',
                        description: errorObjTransformString(
                            error.response.data.errors
                        ),
                        status: StatusType.Error,
                    })
                } else if (error.response?.status === 409) {
                    customToast({
                        title: 'Error',
                        description: 'Name already exists.',
                        status: StatusType.Error,
                    })
                } else {
                    customToast({
                        title: 'Error',
                        description: 'Something went wrong.',
                        status: StatusType.Error,
                    })
                }
            })
    }

    const onSave = (rowIndex: number): void => {
        resourceOwnersAuthApi
            .updateResourceOwnerAuth(data[rowIndex])
            .then(() => {
                setEditingRows({ [rowIndex]: false })
                secretsStore.fetchResourceOwners(tenantId)
            })
            .catch(error => {
                if (error.response?.status === 400) {
                    customToast({
                        title: 'Error',
                        description: errorObjTransformString(
                            error.response.data.errors
                        ),
                        status: StatusType.Error,
                    })
                } else if (error.response?.status === 409) {
                    customToast({
                        title: 'Error',
                        description: 'Name already exists.',
                        status: StatusType.Error,
                    })
                } else {
                    customToast({
                        title: 'Error',
                        description: 'Something went wrong.',
                        status: StatusType.Error,
                    })
                }
            })
    }

    const updateMyData = (
        rowIndex: number,
        columnId: string,
        value: string
    ): void => {
        resourceOwnersAuthStore.updateResourceOwnerAuthData(
            rowIndex,
            columnId,
            value
        )
    }

    const onDeleteRow = () => {
        resourceOwnersAuthApi
            .removeResourceOwnerAuth(data[deleteRowIndex])
            .then(() => {
                onCancelEdit(deleteRowIndex)
                onCloseDelete()

                resourceOwnersAuthStore.fetch(tenantId)
            })
            .catch(error => {
                if (error.response?.status === 400) {
                    customToast({
                        title: 'Error',
                        description: error?.response.data,
                        status: StatusType.Error,
                    })
                }
                else {
                    customToast({
                        title: 'Error',
                        description: 'Something went wrong',
                        status: StatusType.Error,
                    })
                }
            })
    }

    const openDeleteModal = (rowIndex: number) => {
        setDeleteRowIndex(rowIndex)
        onOpenDelete()
    }

    return (
        <div>
            <Box padding="31px 0">
                <Flex color="white" overflowY={'scroll'}>
                    <Box flex="1">
                        <EditableTable
                            data={data}
                            columns={columns}
                            editingRows={editingRows}
                            setEditingRows={setEditingRows}
                            updateMyData={updateMyData}
                            onEdit={onEdit}
                            onSave={onSave}
                            onCancelEdit={onCancelEdit}
                            openDeleteModal={openDeleteModal}
                        />
                    </Box>
                </Flex>
            </Box>
            <Button
                size="sm"
                variant="outline"
                colorScheme="blue"
                onClick={onOpen}
            >
                Add
            </Button>
            <CustomModal
                modalTitle="Create Resource Owners Auth"
                isOpen={isOpen}
                onClose={onClose}
                onSubmit={onSubmit}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
            >
                <ResourceOwnersAuthInputs errors={errors} register={register} />
            </CustomModal>
            <NumericPagination
                marginTop="24px"
                pages={resourceOwnersAuthStore.pages}
                activePage={resourceOwnersAuthStore.filters.page}
                onChangePage={page => {
                    resourceOwnersAuthStore.setFilters({ page })
                    resourceOwnersAuthStore.fetch(tenantId)
                }}
            />
            <ConfirmModal
                isOpen={isOpenDelete}
                onClose={onCloseDelete}
                confirmFunc={onDeleteRow}
                headerText="Delete Resource Owner Auth"
                bodyText="Are you sure?"
                buttonText="Delete"
                colorButton="red.500"
            />
        </div>
    )
})
