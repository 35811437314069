export const isValidJSONBody = (jsonBody: string = '') => {
    return jsonBody.startsWith('$.')
}

export const getValidationSchema = (ruleType?: string, value?: string) => {
    switch (ruleType) {
        case 'JSONBody': {
            return isValidJSONBody(value)
        }
        default: {
            return undefined
        }
    }
}

export const getErrorMessage = (ruleType?: string) => {
    switch (ruleType) {
        case 'JSONBody': {
            return 'Name must start with $.'
        }
        default: {
            return
        }
    }
}
