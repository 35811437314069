import React, { useEffect, useState } from 'react'
import { Controller, FieldValues, UseFormWatch } from 'react-hook-form'
import { FormControl, FormErrorMessage } from '@chakra-ui/form-control'
import { FormLabel, useColorMode } from '@chakra-ui/react'
import { Input } from '@chakra-ui/input'
import Select, { SingleValue } from 'react-select'
import { ISelectOption, TableForm } from 'types/core'
import { OrderFlows } from 'types/api'
import { getErrorMessage, getValidationSchema } from 'utils/getValidationSchema'
import { tenantsStore } from 'stores'
import { IntegrationChannelsApi } from 'api/integrationChannels'
import {
    channelTypeOptions,
    inputPattern,
    locationTypeHttpMethod,
    routingRuleOptions,
} from '../../mock'
import { MultiSelect as CustomMultiSelect } from 'components/baseComponents/MultiSelect'
import { CheckboxOption as Option } from 'components/baseComponents/MultiSelect/CheckboxOption'
import { MenuList } from 'components/diagrams/nodes/NodeLayout/NodeParameter/params/MultiSelect/MenuList'
import { MultiValueContainer } from 'components/diagrams/nodes/NodeLayout/NodeParameter/params/MultiSelect/MultiValueContainer'
import { DropdownIndicator } from 'components/baseComponents/MultiSelect/DropdownIndicator'
import { CheckboxStyled } from 'components/TenantsComponents/GeneralSettings'
import { multiSelectStyles } from 'components/baseComponents/MultiSelect/styles'
import { reactSelectStyles } from 'components/baseComponents/Select/styles'

interface IChannelsInputs extends TableForm {
    setValue: (name: string, value: string) => void
    resetField: (name: string, options?: Record<string, boolean | any>) => void
    getValues: (payload?: string | string[]) => string
    watch: UseFormWatch<FieldValues>
    isCreateChannels: boolean
    authSchemaData: { id: string, name: string }[]
    mappingData: { id: string, name: string }[]
}

const ChannelsInputs = ({
    errors,
    register,
    control,
    setValue,
    resetField,
    watch,
    authSchemaData,
    mappingData,
    isCreateChannels
}: IChannelsInputs) => {
    const { colorMode } = useColorMode()
    const routingRuleType = watch('routingRule.location')


    const [mapperType, setMapperType] = useState(false)
    const [disabledRelative, setDisabledRelative] = useState(false)
    const [disabledDownstream, setDisabledDownstream] = useState(false)
    const [resourceTypeOptions, setResourceTypeOptions] = useState<ISelectOption[]>([])
    const [authSchemaOptions, setAuthSchemaOptions] = useState<ISelectOption[]>([])
    const [mappingOptions, setMappingOptions] = useState<ISelectOption[]>([])

    const [orderFlows, setOrderFlows] = useState<OrderFlows[]>([])

    const getOptions = () => {
        const authSchemaOptions: ISelectOption[] = authSchemaData.map(
            authSchema => ({
                value: authSchema.id,
                label: authSchema.name,
            })
        )

        const mappingOptions: ISelectOption[] = mappingData.map(mapping => ({
            value: mapping.id,
            label: mapping.name,
        }))
        const copyMappingOptions = [...mappingOptions]
        copyMappingOptions.push({ value: "Proxy", label: "Proxy"})

        setAuthSchemaOptions(authSchemaOptions)
        setMappingOptions(copyMappingOptions)
    }
    const defaultValidation = {
        required: 'This is required',
        minLength: {
            value: 4,
            message: 'Minimum length should be 4',
        },
    }

    const disabledHttpMethods = (value: string) => {
        if (value === 'Proxy') {
            setValue('mapperId', 'Proxy')
            setMapperType(true)
        } else {
            setValue('mapperId', '')
            setMapperType(false)
        }
    }

    const disabledUrl = (value: string, typeUrl: string) => {
        if (typeUrl === 'relativeUrl') setDisabledRelative(value !== '')
        if (typeUrl === 'downstreamUrl') setDisabledDownstream(value !== '')
    }

    useEffect(() => {
        IntegrationChannelsApi.getOrderFlows().then(data => {
            const resourceType: ISelectOption[] = data.map(orderFlow => ({
                value: orderFlow.name,
                label: orderFlow.name,
            }))
            setOrderFlows(data)
            setResourceTypeOptions(resourceType)
        })
        getOptions()
        // Need for improvement. Warnings console
        /* eslint-disable */
    }, [])

    return (
        <>
            <FormControl>
                <FormLabel htmlFor="tenantId">Tenant Id</FormLabel>
                <Input
                    id="tenantId"
                    placeholder="Tenant Id"
                    value={tenantsStore.tenantId}
                    disabled
                />
            </FormControl>
            <FormControl isInvalid={!!errors.name} mt={5}>
                <FormLabel htmlFor="name">Name</FormLabel>
                <Input
                    id="name"
                    placeholder="name"
                    {...register('name', defaultValidation)}
                />
                <FormErrorMessage>
                    {errors.name && errors.name.message}
                </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.type} mt={5}>
                <FormLabel>Channel Type</FormLabel>
                <Controller
                    name="type"
                    control={control}
                    rules={{ required: 'This is required' }}
                    render={({ field }) => (
                        <Select
                            placeholder="Select..."
                            options={channelTypeOptions}
                            styles={reactSelectStyles(colorMode, '400px')}
                            isMulti={false}
                            {...field}
                            onChange={(options: SingleValue<ISelectOption>) => {
                                field.onChange(options?.label)
                                const resourceType: ISelectOption[] = []
                                orderFlows.forEach(orderFlow => {
                                    if (
                                        options?.label === orderFlow.channelType
                                    ) {
                                        resourceType.push({
                                            value: orderFlow.name,
                                            label: orderFlow.name,
                                        })
                                    }
                                })
                                setResourceTypeOptions(resourceType)
                                setMapperType(false)
                                resetField('resourceType')
                                resetField('downstreamUrl')
                                resetField('relativeUrl')
                                resetField('httpMethods')
                            }}
                            value={channelTypeOptions.find(
                                option => option.value === field?.value
                            )}
                        />
                    )}
                />
                <FormErrorMessage>
                    {errors.type && errors.type.message}
                </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.resourceType} mt={5}>
                <FormLabel htmlFor="resourceType">Resource Type</FormLabel>
                <Controller
                    name="resourceType"
                    control={control}
                    rules={{ required: 'This is required' }}
                    render={({ field }) => (
                        <Select
                            placeholder="Select..."
                            options={resourceTypeOptions}
                            styles={reactSelectStyles(colorMode, '400px')}
                            isMulti={false}
                            {...field}
                            onChange={options => {
                                if (field?.value === 'Service.MasterData' || field?.value === 'Service.Inventory') {
                                    setMapperType(true)
                                } else setMapperType(false)
                                field.onChange(options?.label)
                                orderFlows.forEach(orderFlow => {
                                    if (options?.label === orderFlow.name) {
                                        setValue('type', orderFlow.channelType)
                                        setValue('httpMethods', orderFlow.method)
                                        setValue('downstreamUrl', orderFlow.downstreamUrl)
                                        setValue('relativeUrl', orderFlow.relativeUrl)
                                        disabledHttpMethods(orderFlow.mapperType)
                                        disabledUrl(orderFlow.relativeUrl, 'relativeUrl')
                                        disabledUrl(orderFlow.downstreamUrl, 'downstreamUrl')
                                    }
                                })
                            }}
                            value={resourceTypeOptions.filter(
                                option => option.label === field?.value
                            )}
                        />)
                    }
                />
                <FormErrorMessage>
                    {errors.resourceType && errors.resourceType.message}
                </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.relativeUrl} mt={5}>
                <FormLabel htmlFor="relativeUrl">Relative URL</FormLabel>
                <Input
                    disabled={disabledRelative}
                    id="relativeUrl"
                    placeholder="Relative Url"
                    {...register('relativeUrl', {
                        ...defaultValidation,
                    })}
                />
                <FormErrorMessage>
                    {errors.relativeUrl &&
                        'Relative Url must start with http or https'}
                </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.downstreamUrl} mt={5}>
                <FormLabel htmlFor="downstreamUrl">Downstream URL</FormLabel>
                <Input
                    disabled={disabledDownstream}
                    id="downstreamUrl"
                    placeholder="Downstream URL"
                    {...register('downstreamUrl', {
                        ...defaultValidation,
                        pattern: inputPattern,
                    })}
                />
                <FormErrorMessage>
                    {errors.downstreamUrl &&
                        'Downstream Url must start with http or https'}
                </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.authenticationSchemeId} mt={5}>
                <FormLabel>Auth Schema</FormLabel>
                <Controller
                    name="authenticationSchemeId"
                    control={control}
                    rules={{ required: 'This is required' }}
                    render={({ field }) => (
                        <Select
                            placeholder="Select..."
                            options={authSchemaOptions}
                            styles={reactSelectStyles(colorMode, '400px')}
                            isMulti={false}
                            {...field}
                            onChange={options => field.onChange(options?.value)}
                            value={authSchemaOptions.find(
                                option => option.value === field?.value
                            )}
                        />
                    )}
                />
                <FormErrorMessage>
                    {errors.authenticationSchemeId &&
                        errors.authenticationSchemeId.message}
                </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.httpMethods} mt={5}>
                <FormLabel htmlFor='httpMethods'>Http Method</FormLabel>
                <Controller
                    name='httpMethods'
                    control={control}
                    rules={{ required: 'This is required' }}
                    render={({ field }) =>  (
                        <CustomMultiSelect
                            isDisabled={false}
                            components={{
                                Option,
                                MenuList,
                                MultiValueContainer,
                                DropdownIndicator,
                                IndicatorSeparator: () => null,
                            }}
                            options={locationTypeHttpMethod}
                            optionSelected={Array.isArray(field?.value) ? field?.value.map(
                                (option: string) => {
                                    return {
                                        value: option.toUpperCase(),
                                        label: option.toUpperCase()
                                    }
                                }
                            ) : []}
                            placeholder='Select...'
                            customStyles={multiSelectStyles({
                                theme: colorMode,
                                isSelectRule: false,
                                isDisabled: false,
                                isChannels: true,
                            })}
                            onChange={options => field.onChange(options.map(el => el.value))}
                            isClearable={false}
                        />
                        )
                    }
                />
                <FormErrorMessage>
                    {errors.httpMethods && errors.httpMethods.message}
                </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.mapperId} mt={5} mb={5}>
                <FormLabel>Mapping Configuration</FormLabel>
                <Controller
                    name="mapperId"
                    control={control}
                    rules={{ required: 'This is required' }}
                    render={({ field }) => (
                            <Select
                                placeholder="Select..."
                                isDisabled={mapperType}
                                options={mappingOptions}
                                styles={reactSelectStyles(colorMode, '400px')}
                                isMulti={false}
                                {...field}
                                onChange={options => field.onChange(options?.value)}
                                value={mappingOptions.find(
                                    option => {
                                        if (!isCreateChannels && field?.value === null) {
                                            return option.value === 'Proxy'
                                        }
                                        return option.value === field?.value
                                    }
                                )}
                            />
                        )
                    }
                />
                <FormErrorMessage>
                    {errors.mapperId && errors.mapperId.message}
                </FormErrorMessage>
            </FormControl>
            <FormControl mt={5} mb={5}>
                <FormLabel>Routing rule type</FormLabel>
                <Controller
                    name="routingRule.location"
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                        <Select
                            placeholder="Select..."
                            options={routingRuleOptions}
                            styles={reactSelectStyles(colorMode, '400px')}
                            isMulti={false}
                            ref={ref}
                            onChange={options => onChange(options?.value)}
                            value={routingRuleOptions.find(
                                option => option.value === value
                            )}
                        />
                    )}
                />
            </FormControl>
            <FormControl isInvalid={!!errors.routingRule?.name} mt={5}>
                <FormLabel htmlFor="name">Routing rule name</FormLabel>
                <Input
                    id="routingRule.name"
                    placeholder="Routing rule name"
                    {...register('routingRule.name', {
                        required:
                            routingRuleType &&
                            'This field cannot be blank. Please enter a name to continue.',
                        validate: value =>
                            getValidationSchema(routingRuleType, value) ||
                            getErrorMessage(routingRuleType),
                    })}
                />
                <FormErrorMessage>
                    {errors.routingRule?.name &&
                        errors.routingRule?.name.message}
                </FormErrorMessage>
            </FormControl>
            <FormControl mt={5}>
                <Controller
                    control={control}
                    name="routingRule.ignoreCase"
                    defaultValue={false}
                    render={({ field: { onChange, value, ref } }) => (
                        <CheckboxStyled
                            onChange={onChange}
                            ref={ref}
                            isChecked={value}
                            defaultChecked={value}
                            checked={value}
                        >
                            Ignore Case
                        </CheckboxStyled>
                    )}
                />
            </FormControl>
        </>
    )
}

export default ChannelsInputs
