const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]

export function getMonthName(date: Date): string {
    const month = date.getMonth()

    return monthNames[month]
}

export function dayEquals(date1: Date, date2: Date): boolean {
    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
    )
}

export function isToday(date: Date): boolean {
    const now = new Date()

    return dayEquals(now, date)
}
