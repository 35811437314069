import { Text, chakra } from '@chakra-ui/react'

export const ExportText = chakra(Text, {
    fontWeight: 'thin',
})

export const exportButtonStyle = {
    height: '26.5px',
    fontSize: '14px',
}

export const menuButtonStyles = {
    fontSize: '14px',
    height: '26.5px',
    marginLeft: '120px',
}

export const menuListStyle = {
    minWidth: '150px',
}
