import { makeAutoObservable } from 'mobx'
import { notificationStore } from 'stores'
import { WorkflowStore } from './WorkflowStore'
import { UUID, WorkflowApproval, WorkflowError } from 'types/core'
import { WorkflowsService } from 'services/workflows'

export class SubmitForApprovalStore {
    workflowStore: WorkflowStore
    isApproved: boolean = false
    public workflowErrors: WorkflowError[] = []

    constructor() {
        this.workflowStore = new WorkflowStore()

        makeAutoObservable(this)
    }

    public async init(id: UUID) {
        try {
            await this.workflowStore.fetch(id)
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    public async submitForApproval(message?: string) {
        if (!this.workflowStore.workflow) return

        const approval: WorkflowApproval = {
            status: this.workflowStore.workflow.status,
            startDateTime: '2021-11-02T20:29:54.824Z',
            endDateTime: '2021-11-02T20:29:54.824Z',
            comment: message,
        }

        await WorkflowsService.submitForApproval(
            this.workflowStore.workflow.id,
            approval,
        )

        this.initWorkflowErrors([])
        this.approved()
    }

    public setWorkflowErrors(errors: WorkflowError[]) {
        this.initWorkflowErrors(errors)
    }

    private initWorkflowErrors(errors: WorkflowError[]) {
        this.workflowErrors = errors
    }

    private approved() {
        this.isApproved = true
    }

    public clean() {
        this.workflowStore.clean()
        this.isApproved = false
        this.initWorkflowErrors([])
    }
}
