export const headerOptions = [
    'Version',
    'Date Modified',
    'Time Modified',
    'Description',
    'Creator',
    'Approver',
    'Status',
    'Activity',
]

export const SUBMIT_FOR_APPROVAL = 'Approve'
export const APPROVE = 'Approve'
export const REJECT = 'Reject'
export const OBSOLETE = 'Obsolete'
export const NEW_VERSION = 'New Version'
export const MODIFY = 'Modify'
