import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const UserIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 10C0 4.579 4.579 0 10 0C15.421 0 20 4.579 20 10C20 13.19 18.408 16.078 16 17.924V18H15.898C14.23 19.245 12.187 20 10 20C7.813 20 5.77 19.245 4.102 18H4V17.924C1.592 16.078 0 13.189 0 10ZM7.12347 15.236C6.59154 15.6639 6.22136 16.2604 6.074 16.927C7.242 17.604 8.584 18 10 18C11.416 18 12.758 17.604 13.926 16.927C13.7785 16.2605 13.4082 15.6641 12.8764 15.2362C12.3445 14.8083 11.6827 14.5744 11 14.573H9C8.3173 14.5742 7.6554 14.808 7.12347 15.236ZM13.7677 13.4117C14.5877 13.9574 15.2286 14.7329 15.61 15.641C17.077 14.182 18 12.176 18 10C18 5.663 14.337 2 10 2C5.663 2 2 5.663 2 10C2 12.176 2.923 14.182 4.39 15.641C4.77144 14.7329 5.41227 13.9574 6.23227 13.4117C7.05227 12.866 8.01501 12.5742 9 12.573H11C11.985 12.5742 12.9477 12.866 13.7677 13.4117ZM5.99869 7.99479C5.99869 5.71479 7.71869 3.99479 9.99869 3.99479C12.2787 3.99479 13.9987 5.71479 13.9987 7.99479C13.9987 10.2748 12.2787 11.9948 9.99869 11.9948C7.71869 11.9948 5.99869 10.2748 5.99869 7.99479ZM7.99869 7.99479C7.99869 9.17279 8.82069 9.99479 9.99869 9.99479C11.1767 9.99479 11.9987 9.17279 11.9987 7.99479C11.9987 6.81679 11.1767 5.99479 9.99869 5.99479C8.82069 5.99479 7.99869 6.81679 7.99869 7.99479Z"
                fill="currentColor"
            />
        </Icon>
    )
}
