import { Flex, Text, Box, Center } from '@chakra-ui/layout'
import { Button, DrawerBody } from '@chakra-ui/react'
import { CrossSmallIcon } from 'icons'
import { chakra } from 'utils/chakra'

export const CollapseBox = chakra(Flex, {
    flexDirection: 'column',
    height: '140px',
    border: '1px solid',
    borderColor: 'blueBlack.300',
    backgroundColor: 'blueWhite.900',
    borderRadius: '8px',
    padding: '16px',
})

export const CollapsedBox = chakra(Flex, {
    border: '1px solid',
    borderColor: 'blueBlack.300',
    borderRadius: '0 0 8px 8px',
    borderTop: '0',
    backgroundColor: 'blueWhite.900',
    padding: '16px',
})

export const IconsBox = chakra(Flex, {

})

export const DeleteButtonStyled = chakra(Button, {
    variant: 'underline',
    margin: '4px 20px 0 10px',
    maxWidth: '44px',
    minWidth: '44px',
    paddingTop: '6px',
})

export const UndoButton = chakra(Button, {
    variant: 'underline',
    margin: '0 20px 6px 19px',
    maxWidth: '36px',
    minWidth: '36px',
    paddingTop: '6px',
})

export const UndoToastBox = chakra(Flex, {
    backgroundColor: 'blueWhite.1100',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)',
    borderRadius: '6px',
    padding: '16px',
    alignItems: 'center',
})

export const DividerBox = chakra(Center, {
    padding: '0 15px',
    borderRight: '1px solid',
    borderLeft: '1px solid',
    borderColor: 'blueBlack.300',
    backgroundColor: 'blueWhite.900',
})

export const SavedFilterItemBox = chakra(Box, {
    margin: '15px 0',
})

export const BodyBox = chakra(Flex, {
    margin: '4px 0',
})

export const FooterBox = chakra(Flex, {
    marginTop: '12px',
    width: '100%',
    justifyContent: 'space-between',
})

export const FooterButtonsBox = chakra(Flex, {

})

export const HeaderBox = chakra(Flex, {
    width: '100%',
    height: '40px',
    justifyContent: 'space-between',
})

export const FilterName = chakra(Text, {
    variant: 'h4',
    color: 'color.100',
    maxWidth: '260px',
})

export const UndoToastText = chakra(Text, {
    variant: 'paragraph',
    color: 'color.50',
})

export const CrossSmallIconStyled = chakra(CrossSmallIcon, {
    boxSize: '20px',
    padding: '3px',
    color: 'color.50',
    _hover: {
        color: 'black',
    }
})

export const FilterText = chakra(Text, {
    variant: 'h4Small',
    color: 'color.100',
    textTransform: 'capitalize',
    alignSelf: 'center',
})

export const DrawerBodyStyled = chakra(DrawerBody, {
    marginRight: '8px',
    sx: {
        '&::-webkit-scrollbar': {
            width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'scrollbar.75',
                borderRadius: '8px',
                maxHeight: '56px',
        }
    },
})

export const OutlinedButton = chakra(Button, {
    size: 'sm',
    backgroundColor: 'blueWhite.900',
    color: 'buttonOutlineColor',
    border: 'solid 2px',
    borderColor: 'blue.200',
    marginRight: '5px',
    _hover: {
        color: 'white',
        backgroundColor: 'blue.200',
    },
})
