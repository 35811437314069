interface IAuditOrderStatuses {
    [key: number]: {
        title: string
        status: string
    }
}

interface IOrderShippedMock {
    id: string
    date: string
    time: string
    status: string
}

export const auditOrderStatuses: IAuditOrderStatuses = {
    0: {
        title: 'Order Received',
        status: 'Order Received',
    },
    50: {
        title: 'Preprocessing complete',
        status: 'Preprocessing complete',
    },
    100: {
        title: 'Processing rule',
        status: 'Processing rule',
    },
    300: {
        title: 'Order sent to distribution source',
        status: 'Order sent to distribution source',
    },
    400: {
        title: 'Order acknowledged',
        status: 'Order acknowledged',
    },
    500: {
        title: 'Order shipped DOM',
        status: 'Order shipped DOM',
    },
    600: {
        title: 'Order shipped Source Sys',
        status: 'Order shipped Source Sys',
    },
    900: {
        title: 'Order completed',
        status: 'Order completed',
    },
    1000: {
        title: 'Failure DOM',
        status: 'Failure DOM',
    },
    1300: {
        title: 'Failure distribution source',
        status: 'Failure distribution source',
    },
    1600: {
        title: 'Failure external source',
        status: 'Failure external source',
    },
    // 2000 is mock status code
    2000: {
        title: 'Order Shipped',
        status: 'Shipped - see “Items & Shipping” tab for details on each item or pack',
    },
}

export const orderShippedMock: IOrderShippedMock[] = [
    {
        id: '1Z9999991234006200',
        date: 'Nov. 16, 2021',
        time: '10:03:20',
        status: 'Awaiting Shipment',
    },
    {
        id: '1Z9999991234006345',
        date: 'Nov. 15, 2021',
        time: '10:03:20',
        status: 'Picked',
    },
    {
        id: '1Z9999991234004995',
        date: 'Nov. 15, 2021',
        time: '10:03:20',
        status: 'Shipped',
    },
]

export const accordionItemsShippingOptions = [
    {
        title: 'Item',
        colSpan: 3,
    },
    {
        title: 'Quantity',
        colSpan: 3,
    },
    {
        title: 'Delivery Method',
        colSpan: 3,
    },
    {
        title: 'Tracking Number',
        colSpan: 3,
    },
    {
        title: 'Delivery Status',
        colSpan: 3,
    },
]
