import React, { useEffect, useState } from 'react'
import { Button } from '@chakra-ui/button'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
    Box,
    Divider,
    Flex,
    Heading,
    SimpleGrid,
    Spacer,
    Text,
} from '@chakra-ui/layout'
import { CounteredInput } from 'components/CounteredInput'
import { CounteredTextarea } from 'components/CounteredTextarea'
import { MoreStepsButton } from 'components/diagrams/EditorSidebar/content/MoreStepsButton'
import { Gap, GapLayout } from 'layout/GapLayout'
import { ArrowRightIcon } from 'icons'
import { onChange } from './utils'
import { progressStore, workflowStore } from 'stores'
import { NAME_LENGTH, DESCRIPTION_LENGTH } from 'constants/rules'

interface CreateRuleForm {
    ruleName: string
    ruleDescription: string
}

export const CreationRulePage = observer(() => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const { id } = useParams()
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = useForm<CreateRuleForm>({ mode: 'onChange', reValidateMode: 'onChange' })

    const workflow = toJS(workflowStore.workflow)
    const ruleNameRemaining = NAME_LENGTH - watch('ruleName', '').length
    const ruleDescriptionRemaining = DESCRIPTION_LENGTH - watch('ruleDescription', '').length

    const handleClick = () => handleSubmit(values => onChange(values, navigate, setLoading, id))

    useEffect(() => {
        if (id) workflowStore.fetch(id)
        progressStore.showStepProgress(1)
        return () => {
            progressStore.hideProgress()
            workflowStore.clean()
        }
    }, [id])

    useEffect(() => {
        if (workflow?.name && workflow?.description) {
            setValue('ruleName', workflow.name)
            setValue('ruleDescription', workflow.description)
        }
    }, [workflow?.name, workflow?.description, setValue])

    return (
        <Flex direction="row" height="100%">
            <Box width="176px" margin="34px 14px 0 34px">
                <MoreStepsButton activeStep={1} />
            </Box>
            <Box flex="1">
                <GapLayout padding="41px 44px 44px 25px">
                    <Box>
                        <Text variant="supertitle">Create a new rule</Text>
                        <Flex>
                            <Heading variant="h2">
                                Nickname {'&'} Tagging
                            </Heading>
                            <Spacer />
                            <Text variant="h3">Step 1 of 3</Text>
                        </Flex>
                        <Text variant="h2" marginTop="64px">
                            1. Name and Describe Your Rule
                        </Text>
                        <Text marginTop="16px">
                            Create a nickname that will help you search for your
                            rule later.
                        </Text>
                        <Text variant="supertitle" marginTop="46px">
                            Nickname
                        </Text>
                        <CounteredInput
                            placeholder="Enter Nickname..."
                            size="sm"
                            width="580px"
                            marginTop="8px"
                            remaining={ruleNameRemaining}
                            isInvalid={!!errors.ruleName}
                            description={errors.ruleName?.message}
                            {...register('ruleName', {
                                required:
                                    'This field cannot be blank. Please enter a nickname to continue.',
                                maxLength: {
                                    value: NAME_LENGTH,
                                    message: `This field max length is ${NAME_LENGTH}`,
                                },
                            })}
                        />
                        <Text variant="supertitle" marginTop="55px">
                            Short Description
                        </Text>
                        <CounteredTextarea
                            placeholder="Enter Description..."
                            size="sm"
                            width="580px"
                            marginTop="8px"
                            remaining={ruleDescriptionRemaining}
                            isInvalid={!!errors.ruleDescription}
                            {...register('ruleDescription', {
                                required: 'This field cannot be blank. Please enter a short description to continue.',
                                maxLength: {
                                    value: DESCRIPTION_LENGTH,
                                    message: `This field max length is ${DESCRIPTION_LENGTH}`,
                                },
                            })}
                            description={
                                errors.ruleDescription?.message ||
                                '(Example: "When my order contains fewer than 10 cases, ship with Roadie.")'
                            }
                        />
                        <Divider marginTop="69px" />
                        <Text variant="h2" marginTop="56px">
                            2. Choose a Template from a Previous Rule
                        </Text>
                        <Text marginTop="16px">
                            Your previous rules are listed below. You can choose
                            one of them as a starting point for your new rule:
                        </Text>
                        <SimpleGrid columns={3} gap="10px" marginTop="73px">
                            {[1, 2, 3].map(i => (
                                <Box key={i}>
                                    <Box
                                        width="100%"
                                        height="208px"
                                        backgroundColor="blue.800"
                                    />
                                    <Text marginTop="18px" variant="h5">
                                        Title
                                    </Text>
                                    <Text mt="9px" variant="paragraphsmall">
                                        Lorem ipsum dolor, sit amet consectetur
                                        adipisicing elit
                                    </Text>
                                </Box>
                            ))}
                        </SimpleGrid>
                    </Box>
                    <Gap size="10px" />
                    <Flex marginTop="50px">
                        <Button
                            type="submit"
                            variant="blueBig"
                            isLoading={loading}
                            rightIcon={<ArrowRightIcon margin="2px 0 0 3px" boxSize="10px" color="white" />}
                            onClick={handleClick()}
                        >
                            Next Step: Logic Canvas
                        </Button>
                    </Flex>
                </GapLayout>
            </Box>
        </Flex>
    )
})
