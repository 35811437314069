import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const ArrowRightLongIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 12 10"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 3.87768C0.447715 3.87768 -2.09627e-07 4.3254 -1.85485e-07 4.87768C-1.61344e-07 5.42997 0.447715 5.87768 1 5.87768L8.50603 5.87768L6.97911 7.37767C6.58197 7.7765 6.58197 8.42314 6.97911 8.82197C7.37625 9.2208 8.02015 9.2208 8.41729 8.82197L11.6102 5.67C11.8473 5.48715 12 5.20025 12 4.87768C12 4.54132 11.8339 4.24374 11.5793 4.06248L8.4173 1.1768C8.02015 0.777968 7.37626 0.777968 6.97911 1.1768C6.58197 1.57563 6.58197 2.22227 6.97911 2.6211L8.39624 3.87768L1 3.87768Z"
                fill="currentColor"
            />
        </Icon>
    )
}
