import { FeatureFlagsGlobalResponse, FeatureFlagsTenantsResponse, PagingFilters } from 'types/api'
import { FeatureFlagsGlobal, FeatureFlagsTenants } from 'types/core'
import { IChangeData } from 'stores/FeatureFlagStore'
import { featureFlagsInstence } from './instances'

export class FeatureFlagsApi {
    static async getGlobal(
        { page, size }: PagingFilters,
    ): Promise<FeatureFlagsGlobalResponse> {
        const response = await featureFlagsInstence.get<FeatureFlagsGlobalResponse>(`/global/feature-flags?page=${page}&size=${size}`)
        return response.data
    }

    static async getTenants(
        tenantId: string,
        { page, size }: PagingFilters,
    ): Promise<FeatureFlagsTenantsResponse> {
        const response = await featureFlagsInstence.get<FeatureFlagsTenantsResponse>(`tenants/${tenantId}/feature-flags?page=${page}&size=${size}`)
        return response.data
    }

    static async createTenants(
        tenantId: string,
        data: FeatureFlagsTenants | IChangeData
    ): Promise<FeatureFlagsTenantsResponse> {
        const response = await featureFlagsInstence.post<FeatureFlagsTenantsResponse>(`tenants/${tenantId}/feature-flags`, data)
        return response.data
    }

    static async putGlobal(data: FeatureFlagsGlobal | IChangeData): Promise<FeatureFlagsGlobalResponse> {
        const response = await featureFlagsInstence.put<FeatureFlagsGlobalResponse>(`/global/feature-flags/${data.id}`, data)
        return response.data
    }

    static async putTenants(
        tenantId: string,
        data: FeatureFlagsTenants | IChangeData
    ): Promise<FeatureFlagsTenantsResponse> {
        const response = await featureFlagsInstence.put<FeatureFlagsTenantsResponse>(`tenants/${tenantId}/feature-flags/${data.id}`, data)
        return response.data
    }

    static async deleteTenants(tenantId: string, id: string): Promise<FeatureFlagsTenantsResponse> {
        const response = await featureFlagsInstence.delete<FeatureFlagsTenantsResponse>(`tenants/${tenantId}/feature-flags/${id}`)
        return response.data
    }
}
