import { notificationStore } from 'stores'
import { StatusType } from 'types/chart'

export const jsonParse = (value?: string) => {
    if (!value) return false

    try {
        return JSON.parse(value)
    } catch (e) {
        notificationStore.toast({
            title: 'Error',
            description: 'Parsing Error...',
            status: StatusType.Error,
        })
    }

    return false
}
