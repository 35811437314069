import React, { useState } from 'react'
import { Link as DomLink } from 'react-router-dom'
import { Divider, Link } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/button'
import { useForm } from 'react-hook-form'
import { Message } from 'components/baseComponents/Message'
import { LoginInput } from './Input'
import { routes } from 'navigation/routes'
import { ArrowLeftIcon } from 'icons'
import { AuthAPI } from 'api/auth'
import { validateEmail } from '../mock'
import { StatusType } from 'types/chart'
import {
    ForgotPasswordSubheadingText,
    ForgotPasswordButtonStyled,
    ForgotPasswordHeadingsBox,
    ForgotPasswordHeadingText,
    ForgotPasswordButtonText,
    LoginHelpTextBox,
    LoginHelpText,
    Container,
    InputText,
    LoginBox,
    InputBox,
    ButtonBox,
} from '../styles'

interface ForgotPasswordProps {
    email: string
}

export const ForgotPasswordPage = () => {
    const [isMessageOpen, setIsMessageOpen] = useState(false)
    const [message, setMessage] = useState({ title: '', description: '' })
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ForgotPasswordProps>()

    const onForgotPass = ({ email }: ForgotPasswordProps) => {
        AuthAPI.forgotPassword(email)
            .then(res => {
                setIsMessageOpen(true)
                setMessage({
                    title: 'Success',
                    description: `Reset link sent to “${res.email}”`,
                })
            })
            .catch((e) => {
                setIsMessageOpen(true)
                setMessage({
                    title: 'Failed',
                    description: `Error: “${e.message}”`,
                })
            })
    }

    return (
        <Container>
            <LoginBox>
                <ForgotPasswordHeadingsBox>
                    <ForgotPasswordHeadingText>Forgot Your Password?</ForgotPasswordHeadingText>
                    <ForgotPasswordSubheadingText>
                        Enter your email below, and we’ll send you a link to reset your password:
                    </ForgotPasswordSubheadingText>
                </ForgotPasswordHeadingsBox>
                <Message
                    isOpen={isMessageOpen}
                    setOpen={setIsMessageOpen}
                    title={message.title}
                    description={message.description}
                    status={message.title === 'Success' ? StatusType.Success : StatusType.Error}
                />
                <InputBox margin="16px 0 0 0">
                    <InputText>EMAIL</InputText>
                    <LoginInput
                        variant="login"
                        type="email"
                        placeholder="Enter your company email"
                        errorMessage={errors.email?.message}
                        borderError={!!errors.email}
                        register={register}
                        validate={(value) => {
                            if (!value) return 'This field cannot be left blank'
                            if (!validateEmail(value)) return 'Please enter a valid email address format: "abc@gmail.com"'
                            return true
                        }}
                    />
                </InputBox>
                <ButtonBox margin="32px 0">
                    <Button
                        variant="blue"
                        onClick={handleSubmit(onForgotPass)}
                    >
                        Send Password Reset Link
                    </Button>
                </ButtonBox>
                <Link to={routes.login} as={DomLink}>
                    <ButtonBox>
                        <ForgotPasswordButtonStyled>
                            <ArrowLeftIcon boxSize="8px" color="color.100" />
                            <ForgotPasswordButtonText>Back to Login</ForgotPasswordButtonText>
                        </ForgotPasswordButtonStyled>
                    </ButtonBox>
                </Link>
                <Divider margin="32px 0" />
                <LoginHelpTextBox>
                    <LoginHelpText>Don’t have a DOM account yet?</LoginHelpText>
                    <LoginHelpText>Contact your IT Administrator to sign up.</LoginHelpText>
                </LoginHelpTextBox>
            </LoginBox>
        </Container>
    )
}
