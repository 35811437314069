import React, { FC, useEffect } from 'react'
import {
    NumberInput,
    Input,
    Checkbox,
    Switch,
    Select,
    MultiSelect,
} from './params'
import { paramStore, paramStores, selectParamStores } from 'stores'
import {
    DependsOnMappings,
    Parameter,
    ParameterMetadata,
    UUID,
    DisplayOptions
} from 'types/core'
import { CsvImportRuleBlock } from './params/FileInput/CsvImportRuleBlock'
import { displayCsvStatus } from './params/MultiSelect/utils'

export interface ParameterInputProps {
    id: UUID
    stepId: UUID
    name: string
    isDraft: boolean
    description: string
    dependsOnMappings: DependsOnMappings[]
    stepParameters: Parameter[]
    nodeParameters: ParameterMetadata[]
    type: string | null
    subtype: string | null
    displayAs: DisplayOptions
    isExternal: boolean
    readonly: boolean
    value: string
    onChange: (newValue: string) => void
    ruleName: string
}

export const ParameterInput: FC<ParameterInputProps> = ({
    id,
    stepId,
    name,
    isDraft,
    description,
    dependsOnMappings,
    stepParameters,
    nodeParameters,
    type,
    displayAs,
    isExternal,
    readonly,
    value,
    onChange,
    ruleName,
}) => {
    useEffect(() => {
        if (type === 'select') {
            selectParamStores.setStore(isExternal, id, stepId, value)
        }
    }, [id, isExternal, stepId, type, value])

    const displayCsvImportStatus = displayCsvStatus(nodeParameters)

    useEffect(() => {
        if (type === 'multiselect') paramStore.storeAllAvailableValuesId(id)
    }, [id, type])

    switch (type) {
        case 'int':
            return (
                <NumberInput
                    isDraft={isDraft}
                    name={name}
                    inputWidth="65"
                    stepId={stepId}
                    readonly={readonly}
                    value={value}
                    onChange={onChange}
                />
            )
        case 'string':
            return (
                <Input
                    isDraft={isDraft}
                    readonly={readonly}
                    value={value}
                    onChange={onChange}
                    description={description}
                />
            )
        case 'bool':
            return (
                <Checkbox
                    readonly={readonly}
                    isDraft={isDraft}
                    value={value}
                    onChange={onChange}
                />
            )
        case 'toggle':
            return <Switch
                value={value}
                isDraft={isDraft}
                onChange={onChange}
            />
        case 'decimal':
            return (
                <NumberInput
                    isDraft={isDraft}
                    name={name}
                    isDecimal
                    stepId={stepId}
                    inputWidth="100"
                    readonly={readonly}
                    displayAs={displayAs}
                    value={value}
                    onChange={onChange}
                />
            )
        case 'guid':
            return (
                <Input
                    isDraft={isDraft}
                    readonly={readonly}
                    value={value}
                    onChange={onChange}
                    description={description}
                    needValidation
                />
            )
        case 'select':
            return (
                <Select
                    id={id}
                    description={description}
                    dependsOnMappings={dependsOnMappings}
                    stepParameters={stepParameters}
                    nodeParameters={nodeParameters}
                    stepId={stepId}
                    isDraft={isDraft}
                    readonly={readonly}
                    isExternal={isExternal}
                    value={value}
                    placeholder={description}
                    ruleName={ruleName}
                />
            )
        case 'multiselect':
            paramStores.setStore(id, stepId)
            return (
                <>
                    {displayCsvImportStatus && <CsvImportRuleBlock />}
                    <MultiSelect
                        id={id}
                        isDraft={isDraft}
                        description={description}
                        stepId={stepId}
                        readonly={readonly}
                        value={value}
                        dependsOnMappings={dependsOnMappings}
                        stepParameters={stepParameters}
                        nodeParameters={nodeParameters}
                        placeholder={description}
                        ruleName={ruleName}
                    />
                </>
            )
        default:
            return null
    }
}
