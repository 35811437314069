import React from 'react'
import {
    TableForm,
    FieldFormat,
    FieldIndex,
    FieldName,
    FieldType,
} from '../../../../../../types/core'
import { LocationForm } from './LocationForm'

export const ResourceOwnerLocation = (props: TableForm) => {
    return (
        <>
            <LocationForm
                header="Client Id:"
                nameFieldTitle="Id"
                fields={[
                    FieldType.ClientIdType,
                    FieldName.ClientIdName,
                    FieldFormat.ClientIdFormat,
                    FieldIndex.ClientIdIndex,
                ]}
                {...props}
            />
            <LocationForm
                header="Client Secret:"
                nameFieldTitle="Secret"
                fields={[
                    FieldType.ClientSecretType,
                    FieldName.ClientSecretName,
                    FieldFormat.ClientSecretFormat,
                    FieldIndex.ClientSecretIndex,
                ]}
                {...props}
            />
            <LocationForm
                header="Client Username:"
                nameFieldTitle="Name"
                fields={[
                    FieldType.ClientUsernameType,
                    FieldName.ClientUsernameName,
                    FieldFormat.ClientUsernameFormat,
                    FieldIndex.ClientUsernameIndex,
                ]}
                {...props}
            />
            <LocationForm
                header="Client Password:"
                nameFieldTitle="Password"
                fields={[
                    FieldType.ClientPasswordType,
                    FieldName.ClientPasswordName,
                    FieldFormat.ClientPasswordFormat,
                    FieldIndex.ClientPasswordIndex,
                ]}
                {...props}
            />
        </>
    )
}
