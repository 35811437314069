import { ApprovalStore } from './ApprovalStore'
import { AuditOrderStore } from './AuditOrderStore'
import { AuthApiKeyStore } from './AuthApiKeyStore'
import { AuthenticationSchemaStore } from './AuthenticationSchemaStore'
import { AuthNoAuthStore } from './AuthNoAuthStore'
import { CustomeAttributeStore } from './CustomAttributeStore'
import { DailyOrderStore } from './DailyOrderSrore'
import { DashboardStore } from './DashboardStore'
import { EditoreStore } from './EditoreStore'
import { IntegrationChannelStore } from './IntegrationChannelStore'
import { KPIStore } from './KPIStore'
import { MappingStore } from './MappingStore'
import { NotificationStore } from './NotificationStore'
import { OrderStore } from './OrderStore'
import { ParamStores } from './ParamStores'
import { PrioritizeStore } from './PrioritizeStore'
import { ProgressStore } from './ProgressStore'
import { SelectParamStores } from './SelectParamStores'
import { ErrorReportStore } from './ReportsStore/ErrorReportStore'
import { OnTimeShippingReportStore } from './ReportsStore/OnTimeshippingStore'
import { PerformanceByTotalOrdersReportStore } from './ReportsStore/PerformanceByTotalOrdersStore'
import { ProdPerfByTotalOrdersReportStore } from './ReportsStore/ProdPerfByTotalOrderReportStore'
import { ProductPerformanceBySalesReportStore } from './ReportsStore/ProductPerformanceSalesStore'
import { ResourceOwnersAuthStore } from './ResourceOwnersAuthStore'
import { StepsMetadataStore } from './StepsMetadataStore'
import { RoutingMapStore } from './RoutingMapStore'
import { RulesSetsStore } from './RulesSetsStore'
import { SecretsStore } from './SecretsStore'
import { ShippingCostStore } from './ShippingCostStore'
import { SubmitForApprovalStore } from './SubmitForApprovalStore'
import { TenantsGroupMappingStore } from './TenantsGroupMappingStore'
import { TenantsStore } from './TenantsStore'
import { TenantUsersStore } from './TenantUsersStore'
import { UserStore } from './UserStore'
import { WorkflowsStore } from './WorkflowsStore'
import { WorkflowStore } from './WorkflowStore'
import { WorkflowVersionsStore } from './WorkflowVersionsStore'
import { WowStore } from './WowStore'
import { FeatureFlagStore } from './FeatureFlagStore'
import { ParamStore } from './ParamStore'
import { CustomerShipperStore } from './CustomerShipperStore'
import { SizeStore } from './SizeStore'
import { PreviewStore } from './PreviewStore'
import { ShippingCostReportStore } from './ReportsStore/ShippingCostReportStore'

export const previewStore = new PreviewStore()
export const userStore = new UserStore()
export const sizeStore = new SizeStore()
export const wowStore = new WowStore()
export const progressStore = new ProgressStore()
export const editoreStore = new EditoreStore()
export const workflowStore = new WorkflowStore()
export const workflowsStore = new WorkflowsStore()
export const workflowVersionsStore = new WorkflowVersionsStore()
export const prioritizeStore = new PrioritizeStore()
export const dashboardStore = new DashboardStore()
export const submitForApprovalStore = new SubmitForApprovalStore()
export const approvalStore = new ApprovalStore()
export const orderStore = new OrderStore()
export const rulesSetsStore = new RulesSetsStore()
export const auditOrderStore = new AuditOrderStore()
export const stepsMetadataStore = new StepsMetadataStore()
export const shippingCostStore = new ShippingCostStore()
export const tenantsGroupMappingStore = new TenantsGroupMappingStore()
export const routingMapStore = new RoutingMapStore()
export const tenantsStore = new TenantsStore()
export const resourceOwnersAuthStore = new ResourceOwnersAuthStore()
export const authNoAuthStore = new AuthNoAuthStore()
export const authApiKeyStore = new AuthApiKeyStore()
export const secretsStore = new SecretsStore()
export const authenticationSchemaStore = new AuthenticationSchemaStore(
    secretsStore,
    tenantsStore
)
export const mappingStore = new MappingStore()
export const integrationChannelStore = new IntegrationChannelStore(
    authenticationSchemaStore,
    mappingStore,
    tenantsStore
)
export const notificationStore = new NotificationStore()
export const tenantUsersStore = new TenantUsersStore()
export const paramStores = new ParamStores()
export const selectParamStores = new SelectParamStores()
export const kpiStore = new KPIStore()
export const dailyOrderStore = new DailyOrderStore()
export const onTimeShippingReportStore = new OnTimeShippingReportStore()
export const performanceByTotalOrdersReportStore =
    new PerformanceByTotalOrdersReportStore()
export const prodPerformanceBySalesStore =
    new ProductPerformanceBySalesReportStore()
export const prodPerfByTotalOrdersReportStore =
    new ProdPerfByTotalOrdersReportStore()
export const errorReportStore = new ErrorReportStore()
export const customeAttributeStore = new CustomeAttributeStore()
export const featureFlagStore = new FeatureFlagStore()
export const paramStore = new ParamStore()
export const customerShipperStore = new CustomerShipperStore()
export const shippingCostReportStore = new ShippingCostReportStore()