import { makeAutoObservable } from 'mobx'
import { SecretsAPI, SecretsSubcategory } from '../api/secrets'
import {
    IAuthApiKeySecret,
    IAuthNoAuthSecret,
    IResourceOwnerAuthSecret,
    Secrets,
} from '../types/core'
import { PagingFilters } from '../types/api'

const defaultFilters: PagingFilters = {
    page: 1,
    size: 5,
}

export class SecretsStore {
    public dataApiKeys: IAuthApiKeySecret[] = []
    public dataResourceOwners: IResourceOwnerAuthSecret[] = []
    public dataNoAuth: IAuthNoAuthSecret[] = []
    public filtersResourceOwners: PagingFilters = defaultFilters
    public pagesResourceOwners: number = 0
    public filtersApiKeys: PagingFilters = defaultFilters
    public pagesApiKeys: number = 0
    public filtersNoAuth: PagingFilters = defaultFilters
    public pagesNoAuth: number = 0

    constructor() {
        makeAutoObservable(this)
    }

    public async fetchApiKeys(id: string) {
        try {
            const response = await SecretsAPI.getSecrets(
                id,
                SecretsSubcategory.ApiKey,
                this.filtersApiKeys
            )

            this.initApiKeys(response.items)
            this.setUpPages(SecretsSubcategory.ApiKey, response.totalPages)
        } catch (e) {
            this.initApiKeys([])
        }
    }

    public async fetchResourceOwners(id: string) {
        try {
            const response = await SecretsAPI.getSecrets(
                id,
                SecretsSubcategory.ResourceOwner,
                this.filtersResourceOwners
            )

            this.initResourceOwners(response.items)
            this.setUpPages(
                SecretsSubcategory.ResourceOwner,
                response.totalPages
            )
        } catch (e) {
            this.initResourceOwners([])
        }
    }

    public async fetchAuthNoAuth(id: string) {
        try {
            const response = await SecretsAPI.getSecrets(
                id,
                SecretsSubcategory.NoAuth,
                this.filtersNoAuth
            )
            this.initAuthNoAuth(response.items)
            this.setUpPages(
                SecretsSubcategory.NoAuth,
                response.totalPages
            )
        } catch (e) {
            this.initAuthNoAuth([])
        }
    }

    private initApiKeys(items: Secrets[]) {
        const updatedData: IAuthApiKeySecret[] = items.map(item => {
            const parsedData = JSON.parse(item.value)
            return {
                id: item.id,
                tenantId: item.tenantId,
                subcategory: item.subcategory,
                name: parsedData.Name,
                apiKey: parsedData.ApiKey,
                updatedAt: item.updatedAt,
            }
        })
        this.dataApiKeys = updatedData
    }

    private initResourceOwners(items: Secrets[]) {
        const updatedData: IResourceOwnerAuthSecret[] = items.map(item => {
            const parsedData = JSON.parse(item.value)
            return {
                id: item.id,
                tenantId: item.tenantId,
                clientId: parsedData.ClientId,
                subcategory: item.subcategory,
                name: parsedData.Name,
                clientSecret: parsedData.ClientSecret,
                password: parsedData.Password,
                username: parsedData.Username,
                updatedAt: item.updatedAt,
            }
        })
        this.dataResourceOwners = updatedData
    }

    private initAuthNoAuth(items: Secrets[]) {
        try {
            const updatedData: IAuthNoAuthSecret[] = items.map(item => {
                const parsedData = JSON.parse(item.value)
                return {
                    id: item.id,
                    tenantId: item.tenantId,
                    subcategory: item.subcategory,
                    name: parsedData.Name,
                    updatedAt: item.updatedAt
                }
            })
            this.dataNoAuth = updatedData
        } catch (e) {
            this.dataNoAuth = []
        }
    }

    public setFiltersApiKeys(filters: Partial<PagingFilters>) {
        if (!filters.page) {
            this.filtersApiKeys = {
                ...this.filtersApiKeys,
                ...filters,
                page: 1,
            }
        } else {
            this.filtersApiKeys = { ...this.filtersApiKeys, ...filters }
        }
    }

    public setFiltersResourceOwners(filters: Partial<PagingFilters>) {
        if (!filters.page) {
            this.filtersResourceOwners = {
                ...this.filtersResourceOwners,
                ...filters,
                page: 1,
            }
        } else {
            this.filtersResourceOwners = {
                ...this.filtersResourceOwners,
                ...filters,
            }
        }
    }

    private setUpPages(subcategory: SecretsSubcategory, totalPages: number) {
        subcategory === SecretsSubcategory.ApiKey
            ? (this.pagesApiKeys = totalPages)
            : (this.pagesResourceOwners = totalPages)
    }

    public clean() {
        this.filtersApiKeys = defaultFilters
        this.filtersResourceOwners = defaultFilters
    }
}
