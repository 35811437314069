import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const Simulate: FC<IconProps> = props => {
    return (
        <Icon viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M8 2H3C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V8C2 8.26522 2.10536 8.51957 2.29289 8.70711C2.48043 8.89464 2.73478 9 3 9C3.26522 9 3.51957 8.89464 3.70711 8.70711C3.89464 8.51957 4 8.26522 4 8V4H8C8.26522 4 8.51957 3.89464 8.70711 3.70711C8.89464 3.51957 9 3.26522 9 3C9 2.73478 8.89464 2.48043 8.70711 2.29289C8.51957 2.10536 8.26522 2 8 2ZM8 20H4V16C4 15.7348 3.89464 15.4804 3.70711 15.2929C3.51957 15.1054 3.26522 15 3 15C2.73478 15 2.48043 15.1054 2.29289 15.2929C2.10536 15.4804 2 15.7348 2 16V21C2 21.2652 2.10536 21.5196 2.29289 21.7071C2.48043 21.8946 2.73478 22 3 22H8C8.26522 22 8.51957 21.8946 8.70711 21.7071C8.89464 21.5196 9 21.2652 9 21C9 20.7348 8.89464 20.4804 8.70711 20.2929C8.51957 20.1054 8.26522 20 8 20ZM21 2H16C15.7348 2 15.4804 2.10536 15.2929 2.29289C15.1054 2.48043 15 2.73478 15 3C15 3.26522 15.1054 3.51957 15.2929 3.70711C15.4804 3.89464 15.7348 4 16 4H20V8C20 8.26522 20.1054 8.51957 20.2929 8.70711C20.4804 8.89464 20.7348 9 21 9C21.2652 9 21.5196 8.89464 21.7071 8.70711C21.8946 8.51957 22 8.26522 22 8V3C22 2.73478 21.8946 2.48043 21.7071 2.29289C21.5196 2.10536 21.2652 2 21 2ZM21 15C20.7348 15 20.4804 15.1054 20.2929 15.2929C20.1054 15.4804 20 15.7348 20 16V20H16C15.7348 20 15.4804 20.1054 15.2929 20.2929C15.1054 20.4804 15 20.7348 15 21C15 21.2652 15.1054 21.5196 15.2929 21.7071C15.4804 21.8946 15.7348 22 16 22H21C21.2652 22 21.5196 21.8946 21.7071 21.7071C21.8946 21.5196 22 21.2652 22 21V16C22 15.7348 21.8946 15.4804 21.7071 15.2929C21.5196 15.1054 21.2652 15 21 15Z"
                fill="currentColor"
            />
            <path
                d="M18 8.89296C17.996 8.87441 17.996 8.85521 18 8.83666C17.9965 8.82041 17.9965 8.80364 18 8.78739V8.72405L17.9574 8.61847C17.94 8.58967 17.9185 8.56357 17.8934 8.54105L17.8295 8.48475H17.7939L14.9942 6.73224L12.3508 5.11345C12.2896 5.0654 12.2196 5.02955 12.1447 5.00788H12.0879C12.0243 4.99737 11.9595 4.99737 11.896 5.00788H11.8249C11.7424 5.02596 11.6633 5.05684 11.5904 5.09937L6.28231 8.37214L6.21835 8.4214L6.1544 8.47771L6.08334 8.52698L6.04781 8.56921L6.00518 8.67478V8.73812V8.78035C5.99827 8.82703 5.99827 8.87445 6.00518 8.92112V15.0655C6.00493 15.1851 6.03547 15.3028 6.09391 15.4075C6.15235 15.5121 6.23675 15.6003 6.33916 15.6637L11.6686 18.9294L11.7752 18.9717H11.832C11.9523 19.0094 12.0813 19.0094 12.2016 18.9717H12.2584L12.365 18.9294L17.6518 15.713C17.7542 15.6496 17.8386 15.5614 17.8971 15.4567C17.9555 15.352 17.986 15.2343 17.9858 15.1147V8.97039C17.9858 8.97039 18 8.92112 18 8.89296ZM11.9671 6.53517L13.2319 7.30937L9.2597 9.74459L7.98773 8.97039L11.9671 6.53517ZM11.2565 17.0925L7.3482 14.7276V10.2302L11.2565 12.6232V17.0925ZM11.9671 11.3845L10.6098 10.5751L14.582 8.13284L15.9464 8.97039L11.9671 11.3845ZM16.5859 14.7065L12.6777 17.1136V12.6232L16.5859 10.2302V14.7065Z"
                fill="currentColor"
            />
        </Icon>
    )
}
