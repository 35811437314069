import { Box, Text } from '@chakra-ui/layout'
import { chakra } from 'utils/chakra'

export const RulesTitleBox = chakra(Box, {
    width: '100%',
    height: '40px',
    backgroundColor: 'blueWhite.990',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'auto',
    padding: '8px 17px 8px 23px',
})

export const RulesHeaderItem = chakra(Text, {
    textAlign: 'left',
    flexBasis: 1,
    variant: 'paragraph',
})
