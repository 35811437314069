import React from 'react'
import { Heading, Flex } from '@chakra-ui/layout'
import { TenantTree } from 'components/TenantsComponents/TenantTree'
import { TabsComponent } from 'components/Tabs'
import { gateWayTabsList } from 'constants/admin'
import { TenantsBox, TreeBox, TabsBox } from './styles'

export const AdminGatewayPage = () => {
    return (
        <TenantsBox>
            <Heading as="h1" variant="h1" marginBottom="24px">
                Admin Gateway Page
            </Heading>
            <Flex color="white">
                <TreeBox minWidth="20%" maxWidth="20%">
                    <Heading as="h2" variant="h2" marginBottom="24px">
                        Tenants Tree
                    </Heading>
                    <TenantTree />
                </TreeBox>
                <TabsBox borderLeftColor="divider" width="80%">
                    <Heading
                        as="h2"
                        variant="h2"
                        marginBottom="24px"
                        marginLeft="16px"
                    >
                        Tabs
                    </Heading>
                    <TabsComponent listTabs={gateWayTabsList} name='activeGatewayTab' />
                </TabsBox>
            </Flex>
        </TenantsBox>
    )
}
