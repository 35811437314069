import { anatomy, PartsStyleFunction } from '@chakra-ui/theme-tools'

const dropdownSelectAnatomy = anatomy('DropdownSelect').parts(
    'input',
    'container',
    'option',
    'empty'
)

const baseStyle: PartsStyleFunction<typeof dropdownSelectAnatomy> = ({
    isOpen,
}) => ({
    input: {
        bgColor: 'bgGray800',
        padding: '4px 4px 4px 8px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'color.550',
        borderRadius: isOpen ? '6px 6px 0px 0px' : '6px',
        cursor: 'pointer',
        fontSize: '12px',
        lineHeight: '14px',
        color: 'color.100',
    },
    container: {
        bgColor: 'color.600',
        borderRadius: '0px 0px 6px 6px',
        padding: '0px 0px 4px',
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)',
    },
    option: {
        padding: '4px 8px',
        height: '26px',
        fontSize: '12px',
        lineHeight: '14px',
        justifyContent: 'left',
        _hover: {
            bgColor: 'grayBlue.50',
        },
    },
    empty: {
        padding: '4px 8px',
        height: '26px',
        fontSize: '12px',
        lineHeight: '14px',
    },
})

export const DropdownSelect = {
    baseStyle,
}
