import { makeAutoObservable } from 'mobx'
import { unionBy, isEmpty, some } from 'lodash'
import { OptionData } from 'components/baseComponents/Selects'
import { GetDataResult } from 'components/VirtualScroller'
import { IDependencies, UUID, ICsvImportedPopupData } from 'types/core'
import { AvailableValuesService } from 'services/availableValues'
import { notificationStore, editoreStore } from './index'

export class ParamStore {
    private parameterId: UUID = ''
    private stepId: UUID = ''

    public selectedValues: OptionData[] = []
    public values: OptionData[] = []
    public originalValues: OptionData[] = []
    public dependencies: IDependencies = {}
    public page: number = 1
    public totalPages: number = 1
    public size: number = 20
    public search: string = ''
    public loading: boolean = false
    public isAllAvailableData: ICsvImportedPopupData[] = []
    public isAllAvailableDataId: string = ''

    constructor() {
        makeAutoObservable(this)
    }

    public async init(parameterId: UUID, stepId: UUID) {
        this.parameterId = parameterId
        this.stepId = stepId
    }

    public async initValues(value: string) {
        await this.setDefaultSelectedValues(value)
    }

    public async setDefaultSelectedValues(defaultSelected: string) {
        const pageNumber = 1
        this.setLoading(true)

        try {
            const defaultValues = JSON.parse(defaultSelected)
            const { data: selectedValues = [] } = await this.getAvailableValues(
                pageNumber,
                defaultValues.length,
                defaultValues
            )

            this.setSelectedValues(selectedValues)
            this.setLoading(false)
        } catch (err) {
            this.setLoading(false)
            this.setSelectedValues([])
        }
    }

    public async searchValues(search: string) {
        this.setLoading(true)
        this.search = search
        this.page = 1

        try {
            const { data: values = [], totalPages } =
                await this.getAvailableValues(this.page, this.size, this.search)
            if (this.totalPages !== totalPages) this.totalPages = totalPages

            this.setOriginalValues(values)
            this.setValues(unionBy(this.selectedValues, values, 'value'))
            this.setLoading(false)
        } catch (err) {
            this.setLoading(false)
            notificationStore.triggerErrorToast(err)
        }
    }

    public async setSelected(selectedValues: OptionData[]) {
        this.setValues(unionBy(selectedValues, this.originalValues, 'value'))
        this.setSelectedValues(selectedValues)

        const selectedKeys = this.selectedValues.length
            ? JSON.stringify(this.selectedValues.map(({ value }) => value))
            : ''
        editoreStore.diagramStore.changeParameterValue(
            this.parameterId,
            this.stepId,
            selectedKeys
        )

        await this.saveValue()
    }

    public async setDependencies(dependencies: IDependencies) {
        let needSave = true
        if (isEmpty(this.dependencies) || !this.selectedValues.length) {
            needSave = false
        }

        this.initDependencies(dependencies)
        this.setValues(
            this.values.filter(value => !some(this.selectedValues, value))
        )
        this.setSelectedValues([])

        const selectedKeys = this.selectedValues.length
            ? JSON.stringify(this.selectedValues.map(({ value }) => value))
            : ''
        editoreStore.diagramStore.changeParameterValue(
            this.parameterId,
            this.stepId,
            selectedKeys
        )

        if (needSave) await this.saveValue()
    }

    private initDependencies(dependencies: IDependencies) {
        this.dependencies = dependencies
    }

    public storeAllAvailableValues(
        isAllAvailableArrayData: ICsvImportedPopupData[]
    ) {
        this.isAllAvailableData = isAllAvailableArrayData
    }

    public storeAllAvailableValuesId(isAllAvailableId: string): void {
        this.isAllAvailableDataId = isAllAvailableId
    }

    private async saveValue() {
        const adaptedSelectedValues = this.selectedValues.reduce(
            (acc: string[], { value }) => {
                if (acc.find(val => val === value)) return acc
                acc.push(value)
                return acc
            }, [])

        try {
            await AvailableValuesService.changeValue(
                this.parameterId,
                adaptedSelectedValues.length
                    ? JSON.stringify(adaptedSelectedValues)
                    : ''
            )
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    public async loadMore() {
        if (this.page === this.totalPages) return

        this.setLoading(true)

        try {
            const { data: values = [] } = await this.getAvailableValues(
                this.page + 1,
                this.size,
                this.search
            )

            this.setOriginalValues(
                unionBy(this.originalValues, values, 'value')
            )
            this.setValues(unionBy(this.values, values, 'value'))

            this.setLoading(false)
            this.page = this.page + 1
        } catch (err) {
            this.setLoading(false)
            notificationStore.triggerErrorToast(err)
        }
    }

    public setValues = (values: OptionData[]) => {
        this.values = values
    }

    public setOriginalValues = (originalValues: OptionData[]) => {
        this.originalValues = originalValues
    }

    public setSelectedValues = (selectedValues: OptionData[]) => {
        this.selectedValues = selectedValues
    }

    public setLoading = (loading: boolean) => {
        this.loading = loading
    }

    public async getAvailableValues(
        page: number,
        size: number,
        search: string | string[]
    ) {
        const dependencies = isEmpty(this.dependencies)
            ? undefined
            : this.dependencies

        try {
            return await AvailableValuesService.getValues({
                parameterId: this.parameterId,
                search,
                page,
                size,
                dependencies,
            })
        } catch (err) {
            notificationStore.triggerErrorToast(err)
            return {} as GetDataResult<OptionData>
        }
    }
}
