import { makeAutoObservable } from 'mobx'
import { ReportAPI } from 'api/reports'
import { PagingFilters } from 'types/api'
import { IErrorReportData } from 'types/core'

const defaultFilters: PagingFilters = {
    page: 1,
    size: 10,
}

export class ErrorReportStore {
    public filters: PagingFilters = defaultFilters
    public pages: number = 0
    public errorReport: IErrorReportData[] = []
    public errorReportExport: IErrorReportData[] = []
    public totalItems: number = 0

    constructor() {
        makeAutoObservable(this)
    }

    public async getErrorStatusReportData(
        fromDate: string,
        toDate: string,
        exporting: boolean
    ) {
        try {
            if (!exporting) {
                const data = await ReportAPI.getErrorStatusReport(
                    fromDate,
                    toDate,
                    this.filters
                )
                this.init(data?.items)
                this.initTotalItems(data?.totalItems)
                this.initPages(data?.totalPages)
            } else {
                const exportData = await ReportAPI.getErrorStatusReportExport(
                    fromDate,
                    toDate
                )
                this.initExportData(exportData?.items)
            }
        } catch (err) {
            this.initTotalItems(0)
        }
    }

    private init(data: IErrorReportData[]) {
        this.errorReport = data || []
    }

    private initExportData(data: IErrorReportData[]) {
        this.errorReportExport = data || []
    }

    private initTotalItems(data: number) {
        this.totalItems = data
    }

    private initPages(pages: number) {
        this.pages = pages
    }

    private initFilters(filter: PagingFilters) {
        this.filters = filter
    }

    public setFilters(filters: Partial<PagingFilters>) {
        if (!filters.page) {
            this.filters = { ...this.filters, ...filters, page: 1 }
        } else {
            this.filters = { ...this.filters, ...filters }
        }
    }

    public reloadPages() {
        this.initFilters(defaultFilters)
    }

    public clean() {
        this.initFilters(defaultFilters)
        this.initPages(0)
        this.init([])
        this.initExportData([])
        this.initTotalItems(0)
    }
}
