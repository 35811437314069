import React, { FC } from 'react'
import { Text } from '@chakra-ui/layout'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import { IconButton } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { routes } from 'navigation/routes'
import { RectangleIcon } from '../../utils'
import { checkRoleForConfiguration } from 'utils/jwt'
import { EditPencilIcon } from 'icons'
import { userStore } from 'stores'
import {
    ItemBox,
    RectangleIconBox,
    CategorySetsBox,
    StatusEnabled,
    ItemBoxEdit,
} from './styles'

interface ICategoryItem {
    id: string
    name: string
    source: string
    status: boolean
}

export const CategoryItem: FC<ICategoryItem> = observer(
    ({ id, name, source, status }) => {
        const navigate = useNavigate()
        const onEdit = (): void => navigate(`${routes.editCategoy}/${id}`)
        const currentRole = toJS(userStore.currentRoles)

        return (
            <CategorySetsBox>
                <RectangleIconBox>
                    <RectangleIcon iconType={source ? 'green' : 'gray'} />
                </RectangleIconBox>
                <ItemBox flexGrow={1}>
                    <Text variant="h6">{name}</Text>
                </ItemBox>
                <ItemBox flexGrow={1}>
                    <Text variant="h6">{source ? source : '-'}</Text>
                </ItemBox>
                <ItemBox flexGrow={0.5}>
                    {status ? (
                        <StatusEnabled backgroundColor="green.100" variant="h6">
                            Enabled
                        </StatusEnabled>
                    ) : (
                        <StatusEnabled backgroundColor="red" variant="h6">
                            Disabled
                        </StatusEnabled>
                    )}
                </ItemBox>
                <ItemBoxEdit>
                    <IconButton
                        aria-label="Show Navigation Icon"
                        variant="squircleWhiteBlueIcon"
                        disabled={!checkRoleForConfiguration(currentRole) || !source }
                        onClick={onEdit}
                        icon={
                            <EditPencilIcon boxSize="30px" color="color.100" />
                        }
                    />
                </ItemBoxEdit>
            </CategorySetsBox>
        )
    }
)
