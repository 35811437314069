import React, { LegacyRef } from 'react'
import { Input, InputProps } from '@chakra-ui/input'
import { ErrorMessage } from './styles'

interface CustomInputProps extends InputProps {
    ref?: LegacyRef<HTMLInputElement> | undefined
    errorMessage?: string
}

export const CustomInput = React.forwardRef<
    HTMLInputElement,
    CustomInputProps
>(({
    value,
    onChange,
    placeholder = '',
    variant = 'outline',
    isInvalid = false,
    errorMessage,
    ...props
}, ref) => {
    return (
        <>
            <Input
                variant={variant}
                defaultValue={value}
                placeholder={placeholder}
                onChange={onChange}
                ref={ref}
                {...props}
            />
            {!!errorMessage && (<ErrorMessage>{errorMessage}</ErrorMessage>)}
        </>
    )
})
