import { Box, Center, Flex } from '@chakra-ui/react'
import { chakra } from 'utils/chakra'

export const CardBox = chakra(Box, {
    backgroundColor: 'gray.500',
    borderRadius: '6px',
    position: 'relative',
    cursor: 'pointer',
    filter: 'drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.25))',
    backgroundSize: 'cover',
})

export const ViewButton = chakra(Center, {
    position: 'absolute',
    top: '6px',
    right: '8px',
    backgroundColor: 'viewButton',
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    cursor: 'pointer',
})

export const InfoBox = chakra(Flex, {
    direction: 'column',
    backgroundColor: 'blueWhite.960',
    opacity: '0.8',
    padding: '8px',
    zIndex: '1',
    borderRadius: '0 0 6px 6px',
})
