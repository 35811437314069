import React, { FC, useEffect } from 'react'
import { truncate, isEqual } from 'lodash'
import { observer } from 'mobx-react-lite'
import { Box, HStack } from '@chakra-ui/layout'
import {
    Checkbox,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from '@chakra-ui/react'
import { TooltipTextCut } from 'components/TooltipTextCut'
import { RuleStatusTag } from 'components/RuleStatusTag'
import { prioritizeStore } from 'stores'
import { ShortWorkflow, UUID, WorkflowStatus } from 'types/core'
import { getBorderRadius } from 'utils/getBorderRadius'
import {
    OutlinedButton,
    SaveButton,
    RulesBox,
    RuleBox,
    RuleItem,
    ModalContentStyled,
} from '../styles'

interface EditWowDialogProps {
    isOpen: boolean
    onClose: () => void
    workflows: ShortWorkflow[]
    defaultSelected: UUID[]
}

export const EditWowDialog: FC<EditWowDialogProps> = observer(({
    isOpen,
    onClose,
    workflows,
    defaultSelected,
}) => {
        const editRules = prioritizeStore.editRules
        const allChecked = workflows.every(({ id }) => editRules.includes(id))
        const isIndeterminate = workflows.some(({ id }) => editRules.includes(id)) && !allChecked

        useEffect(() => {
            prioritizeStore.startEdit(defaultSelected)
        }, [defaultSelected])

        const onSave = () => {
            prioritizeStore.endEdit()
            onClose()
        }

        const onAllCheck = () => {
            const selectAll = workflows.reduce((acc: string[], { id, status }) => {
                const isCheckboxChecked = editRules.includes(id)
                const isCheckboxDisabled = !isCheckboxChecked && status !== WorkflowStatus.Approved
                if (isCheckboxDisabled) return acc
                acc.push(id)
                return acc
            }, [])

            if (isEqual(editRules, selectAll)) {
                prioritizeStore.uncheckAll()
            } else {
                prioritizeStore.checkAll(selectAll)
            }
        }

        return (
            <Modal
                scrollBehavior="inside"
                closeOnOverlayClick
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContentStyled>
                    <ModalHeader marginTop="10px">Rules Workflow</ModalHeader>
                    <ModalCloseButton marginTop="15px" />
                    <ModalBody padding="10px 10px 10px 32px" overflow="hidden">
                        <RulesBox>
                            <RuleBox
                                borderRadius={getBorderRadius(false, true, false)}
                                backgroundColor="blueWhite.990"
                            >
                                <RuleItem flexGrow={2}>
                                    <Checkbox
                                        isChecked={allChecked}
                                        isIndeterminate={isIndeterminate}
                                        onChange={onAllCheck}
                                    />
                                </RuleItem>
                                <RuleItem fontWeight="500" flexGrow={3}>Name</RuleItem>
                                <RuleItem fontWeight="500" flexGrow={2}>Description</RuleItem>
                                <RuleItem fontWeight="500" flexGrow={1}>Version</RuleItem>
                                <RuleItem fontWeight="500" flexGrow={1}>Status</RuleItem>
                            </RuleBox>
                            {workflows.map(({
                                id,
                                name,
                                description,
                                version,
                                status,
                            }, index) => {
                                const isOnlyOne = workflows.length === 1
                                const isFirst = false
                                const isLast = index === workflows.length - 1
                                const isCheckboxChecked = editRules.includes(id)
                                const isCheckboxDisabled = !isCheckboxChecked && status !== WorkflowStatus.Approved

                                return (
                                    <RuleBox
                                        borderRadius={getBorderRadius(isOnlyOne, isFirst, isLast)}
                                        key={id}
                                    >
                                        <RuleItem flexGrow={2}>
                                            <Checkbox
                                                isDisabled={isCheckboxDisabled}
                                                isChecked={isCheckboxChecked}
                                                onChange={() => prioritizeStore.toggleOne(id)}
                                            />
                                        </RuleItem>
                                        <TooltipTextCut maxTextLength={25} text={name}>
                                            <RuleItem flexGrow={3}>
                                                {truncate(name, { length: 25 })}
                                            </RuleItem>
                                        </TooltipTextCut>
                                        <TooltipTextCut maxTextLength={25} text={description}>
                                            <RuleItem flexGrow={2}>
                                                {truncate(description, { length: 25 })}
                                            </RuleItem>
                                        </TooltipTextCut>
                                        <RuleItem flexGrow={1}>
                                            <Box paddingLeft='18px'>
                                                {version}
                                            </Box>
                                        </RuleItem>
                                        <RuleItem flexGrow={1}>
                                            <RuleStatusTag status={status} />
                                        </RuleItem>
                                    </RuleBox>
                                )
                            })}
                        </RulesBox>
                    </ModalBody>
                    <ModalFooter>
                        <HStack padding="8px 0 16px 0">
                            <OutlinedButton onClick={onClose}>
                                Cancel
                            </OutlinedButton>
                            <SaveButton onClick={onSave}>
                                Save
                            </SaveButton>
                        </HStack>
                    </ModalFooter>
                </ModalContentStyled>
            </Modal>
        )
    }
)
