import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const ErrorIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 26 26"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_7788_107554)">
                <path d="M16.8061 23.0508L22.5127 17.3443L22.5127 9.27399L16.8061 3.56745L8.73586 3.56745L3.02932 9.27399L3.02932 17.3443L8.73586 23.0508L16.8061 23.0508Z" fill="#FC1313"/>
                <g filter="url(#filter0_d_7788_107554)">
                    <g filter="url(#filter1_d_7788_107554)">
                        <path d="M12.7718 12.4392L15.8165 9.39453L16.6862 10.2643L13.6416 13.3089L16.6862 16.3536L15.8165 17.2233L12.7718 14.1787L9.72715 17.2233L8.85742 16.3536L11.9021 13.3089L8.85742 10.2643L9.72715 9.39453L12.7718 12.4392Z" fill="url(#paint0_linear_7788_107554)"/>
                    </g>
                </g>
            </g>
            <defs>
                <filter id="filter0_d_7788_107554" x="4.39062" y="5.92969" width="16.7617" height="16.7617" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="1"/>
                    <feGaussianBlur stdDeviation="0.5"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7788_107554"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7788_107554" result="shape"/>
                </filter>
                <filter id="filter1_d_7788_107554" x="7.85742" y="9.39453" width="9.82812" height="9.82812" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="1"/>
                    <feGaussianBlur stdDeviation="0.5"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7788_107554"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7788_107554" result="shape"/>
                </filter>
                <linearGradient id="paint0_linear_7788_107554" x1="12.7718" y1="9.39453" x2="12.7718" y2="17.2233" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white"/>
                    <stop offset="1" stopColor="#EFEFEF"/>
                </linearGradient>
                <clipPath id="clip0_7788_107554">
                    <rect width="25.3064" height="25.3064" fill="white" transform="translate(0.117188 0.65625)"/>
                </clipPath>
            </defs>
        </Icon>
    )
}
