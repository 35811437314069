import { useCallback } from 'react'
import { useLocation, useParams } from 'react-router-dom'

export const useGetPath = () => {
    const params = useParams()
    const location = useLocation()

    const basePath = useCallback(() => {
        return Object.values(params).reduce(
            (path, param) => {
                if (!path) return param
                return path.replace('/' + param, '')
            },
            location.pathname,
        )
    }, [location.pathname, params])

    return { fullUrl: basePath(), baseUrl: `/${basePath()?.split('/')[1]}`}
}
