import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

interface TreeArrowProps extends IconProps {
    isOpen: boolean
}

export const TreeArrow: FC<TreeArrowProps> = ({isOpen = false, ...props}) => {
    return (
        <Icon
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            {isOpen ? (
                <path
                    d="M12 16a1 1 0 0 1-.64-.23l-6-5a1 1 0 1 1 1.28-1.54L12 13.71l5.36-4.32a1 1 0 0 1 1.41.15 1 1 0 0 1-.14 1.46l-6 4.83A1 1 0 0 1 12 16z"
                    fill="currentColor"
                />
            ) : (
                <path
                    d="M10 19a1 1 0 0 1-.64-.23 1 1 0 0 1-.13-1.41L13.71 12 9.39 6.63a1 1 0 0 1 .15-1.41 1 1 0 0 1 1.46.15l4.83 6a1 1 0 0 1 0 1.27l-5 6A1 1 0 0 1 10 19z"
                    fill="currentColor"
                />
            )}
        </Icon>
    )
}
