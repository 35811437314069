import { ConditionNode } from './ConditionNode'
import { SourceNode } from './SourceNode'
import { TargetNode } from './TargetNode'
import { TransferNode } from './TransferNode'

export const nodeTypes = {
    SourceNode,
    TransferNode,
    ConditionNode,
    TargetNode,
}
