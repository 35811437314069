import React, { FC } from 'react'
import { Center, IconButton } from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'
import { orderStore } from 'stores'
import { CrossSmallIcon } from 'icons'
import { UUID } from 'types/core'
import { SelectedFilter, FilterName, FilterValues, TooltipStyled } from './styles'

interface ISelectedCheckboxes {
    filterValue: string
    filterName: string
    isSavedFilters: boolean
    statuses: string[]
    ShipmentProviderCodes: string[]
    orderSourceIds: UUID[]
    orderNumber: string
}

export const SelectedCheckboxes: FC<ISelectedCheckboxes> = observer(({
    filterValue,
    filterName,
    isSavedFilters,
    statuses,
    ShipmentProviderCodes,
    orderSourceIds,
    orderNumber,
}) => {
    const getSelectedValues = () => {
        const filtered = []
        if (filterValue === 'status') {
            filtered.push(
                ...orderStore.status.filter(
                    ({ enumValue }) => statuses.some(value => enumValue.includes(value))
                )
            )
        }
        if (filterValue === 'distributionSource') {
            filtered.push(
                ...orderStore.distributionSource.filter(
                    ({ code }) => ShipmentProviderCodes.includes(code)
                )
            )
        }
        if (filterValue === 'externalSource') {
            filtered.push(
                ...orderStore.externalSource.filter(
                    ({ id }) => orderSourceIds.includes(id)
                )
            )
        }

        return filtered.map(({ name }) => name)
    }

    const formatValue = (value: string[]) => {
        if (value.length === 1) return value[0]
        return `${value[0]} + ${value.length - 1}`
    }

    const handleClear = async () => await orderStore.clearFilters(filterName)

    return (
        <TooltipStyled label={getSelectedValues().join('\r\n')}>
            <SelectedFilter backgroundColor={orderNumber ? 'color.225' : 'blueWhite.990'}>
                <FilterName>
                    {filterName}:
                </FilterName>
                <FilterValues>
                    {formatValue(getSelectedValues())}
                </FilterValues>
                {!isSavedFilters && (
                    <Center>
                        <IconButton
                            onClick={handleClear}
                            aria-label="Order Filter Cross Small Icon"
                            variant="hoveredMediumWhite"
                            marginLeft="10px"
                            icon={
                                <CrossSmallIcon padding="3px" boxSize="20px" />
                            }
                        />
                    </Center>
                )}
            </SelectedFilter>
        </TooltipStyled>
    )
})
