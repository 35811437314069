import { ParametersAPI } from 'api/parameters'
import { makeAutoObservable } from 'mobx'
import {
    Elements,
    FlowElement,
    isEdge,
    removeElements,
    XYPosition,
} from 'react-flow-renderer'
import { editoreStore, notificationStore } from 'stores'
import { StepsService } from 'services/steps'
import { PortType } from 'types/api'
import {
    Connection as ChartConnection,
    EdgeType,
    HandleType,
    NodeType,
} from 'types/chart'
import { StepMetadata, UUID, Workflow, WorkflowStep } from 'types/core'

export class DiagramStore {
    public id: UUID = ''
    public name: string = ''
    public description: string = ''
    public elements: Elements = []
    public dataModal: string[] = []
    public isOpenModalDetails: boolean = false

    private flatSteps: WorkflowStep[] = []
    private readonly: boolean
    private rootElement!: FlowElement

    constructor(readonly?: 'readonly') {
        this.readonly = !!readonly
        makeAutoObservable(this)
    }

    public handleModalDetails = (value: boolean) => this.isOpenModalDetails = value

    public changeModalData = (data: string[]) => this.dataModal = data

    public clear(): void {
        this.id = ''
        this.name = ''
        this.description = ''
        this.elements = []
        this.flatSteps = []
    }

    public init(workflow: Workflow): void {
        this.id = workflow.id
        this.name = workflow.name
        this.description = workflow.description

        const rootId = `root-${workflow.id}`

        this.rootElement = {
            id: rootId,
            position: {
                x: 50,
                y: 50,
            },
            type: NodeType.Source,
        }

        this.elements.push(this.rootElement)

        workflow.steps.forEach(step => {
            this.visiteStep(step)

            this.elements.push({
                id: `${rootId}-${step.id}`,
                source: rootId,
                target: step.id,
                type: EdgeType.DefaultEdge,
            })
        })
    }

    private visiteStep(step: WorkflowStep): void {
        const outputs = step.stepMetadata.outputs || 0

        if (this.flatSteps.every(flatStep => flatStep.id !== step.id)) {
            this.flatSteps.push(step)

            const newElement: FlowElement = {
                id: step.id,
                position: {
                    x: step.layout.x || 50,
                    y: step.layout.y || 50,
                },
                type: this.getNodeType(outputs),
                data: {
                    step,
                    readonly: this.readonly,
                },
            }

            this.elements.push(newElement)
        }

        for (let child of step.steps) {
            this.visiteEdge(step, child)
            this.visiteStep(child)
        }
    }

    private visiteEdge(step: WorkflowStep, child: WorkflowStep) {
        const outputs = step.stepMetadata.outputs || 0

        const edgeType = this.getEdgeTypeByOrder(outputs, child.order)

        const handle =
            edgeType === EdgeType.ThenEdge
                ? 'then'
                : edgeType === EdgeType.ElseEdge
                ? 'else'
                : undefined

        const newEdge: FlowElement = {
            id: `${step.id}-${child.id}`,
            source: step.id,
            target: child.id,
            sourceHandle: handle,
            type: edgeType,
        }

        this.elements.push(newEdge)
    }

    public clean(): void {
        this.id = ''
        this.name = ''
        this.description = ''
        this.elements = []
        this.flatSteps = []
    }

    private findStepById(stepId: UUID): WorkflowStep | null {
        return this.flatSteps.find(step => step.id === stepId) || null
    }

    public async moveStep(stepId: UUID, pos: XYPosition) {
        if (stepId) {
            try {
                await StepsService.move(stepId, pos)
            } catch (err) {
                notificationStore.triggerErrorToast(err)
            }
        }
    }

    public async removeStep(id: UUID) {
        await StepsService.remove(id)
        this.removeNode(id)
    }

    private removeNode(id: UUID) {
        const elementToRemove = this.elements.find(elem => elem.id === id)

        if (elementToRemove) {
            this.elements = removeElements([elementToRemove], this.elements)
            this.flatSteps = this.flatSteps.filter(elem => elem.id !== id)
        }
    }

    public async createStep(metadata: StepMetadata, pos: XYPosition) {
        try {
            const added = await StepsService.add(this.id, metadata.id, pos)

            this.insertStep(added)
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    public changeParameterValue(parameterId: UUID, stepId: UUID, newValue: string) {
        const findElement = editoreStore.diagramStore.elements.find(({ id }) => id === stepId)
        const findParameter = findElement?.data.step.parameters.find(({ id }: { id: UUID }) => id === parameterId)

        if (findParameter) findParameter.value = newValue
    }

    private insertStep(step: WorkflowStep) {
        const outputs = step.stepMetadata.outputs || 0

        const newStep: FlowElement = {
            id: step.id,
            position: {
                x: step.layout.x,
                y: step.layout.y,
            },
            type: this.getNodeType(outputs),
            data: {
                step,
                readonly: this.readonly,
            },
        }

        this.elements.push(newStep)
    }

    public async connect(connection: ChartConnection) {
        const { source, target, sourceHandle, targetHandle } = connection

        if (source && target) {
            const portType = this.getPortType(sourceHandle, targetHandle)
            const edgeType = this.getEdgeType(sourceHandle, targetHandle)

            if (source !== this.rootElement.id) {
                try {
                    await StepsService.connect(target, source, portType)
                    this.insertEdge(source, target, edgeType)
                } catch (err) {
                    notificationStore.triggerErrorToast(err)
                }
            } else {
                try {
                    await StepsService.disconnect(target)
                    this.insertEdge(source, target, edgeType)
                } catch (err) {
                    notificationStore.triggerErrorToast(err)
                }
            }
        }
    }

    private getNodeType(outputs: number): NodeType {
        switch (outputs) {
            case 0:
                return NodeType.Target
            case 1:
                return NodeType.Transfer
            case 2:
                return NodeType.Condition
            default:
                return NodeType.Transfer
        }
    }

    private getEdgeType(
        sourceHandle: HandleType = HandleType.Default,
        targetHandle: HandleType = HandleType.Default
    ): EdgeType {
        switch (sourceHandle) {
            case HandleType.Then:
                return EdgeType.ThenEdge
            case HandleType.Else:
                return EdgeType.ElseEdge
            default:
                return EdgeType.DefaultEdge
        }
    }

    private getEdgeTypeByOrder(outputs: number, order: number): EdgeType {
        if (outputs === 2) {
            return order === 0 ? EdgeType.ThenEdge : EdgeType.ElseEdge
        } else {
            return EdgeType.DefaultEdge
        }
    }

    private getPortType(
        sourceHandle: HandleType = HandleType.Default,
        targetHandle: HandleType = HandleType.Default
    ): PortType {
        switch (sourceHandle) {
            case HandleType.Then:
                return PortType.Then
            case HandleType.Else:
                return PortType.Else
            default:
                return PortType.Default
        }
    }

    private insertEdge(source: UUID, target: UUID, type: EdgeType) {
        const index = this.elements.findIndex(
            elem => isEdge(elem) && elem.target === target
        )

        if (index !== -1) {
            this.elements.splice(index, 1)
        }

        this.elements.push({
            id: `${source}-${target}`,
            source,
            target,
            sourceHandle:
                type === EdgeType.ThenEdge
                    ? 'then'
                    : type === EdgeType.ElseEdge
                    ? 'else'
                    : undefined,
            type,
        })
    }

    public async changeParameter(parameterId: UUID, stepId: UUID, newValue: string) {
        this.changeParameterValue(parameterId, stepId, newValue)

        try {
            await ParametersAPI.changeValue(parameterId, newValue)
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }
}
