import { Icon, IconProps } from '@chakra-ui/react'
import { FC } from 'react'

interface DropDownIconProps extends IconProps {
    openned: boolean
}

export const DropDownIcon: FC<DropDownIconProps> = ({
    openned = false,
    ...props
}) => {
    return (
        <Icon
            viewBox="0 0 18 18"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            {openned ? (
                <path
                    d="M9.00012 6.75002L5.81787 9.93227L6.87912 10.9928L9.00012 8.87102L11.1211 10.9928L12.1824 9.93227L9.00012 6.75002Z"
                    fill="currentColor"
                />
            ) : (
                <path
                    d="M8.99988 11.25L12.1821 8.06773L11.1209 7.00723L8.99988 9.12898L6.87888 7.00723L5.81763 8.06773L8.99988 11.25Z"
                    fill="currentColor"
                />
            )}
        </Icon>
    )
}
