import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const RetryArrowIcon: FC<IconProps> = props => {
    return (
        <Icon 
            viewBox="0 0 24 24" 
            fill="currentColor" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M21 11.0015C20.7348 11.0015 20.4804 11.1068 20.2929 11.2944C20.1054 11.4819 20 11.7362 20 12.0015C20.0119 13.8745 19.3703 15.693 18.1857 17.1439C17.001 18.5948 15.3475 19.5871 13.51 19.9501C11.6724 20.313 9.76578 20.0238 8.11851 19.1322C6.47123 18.2407 5.18641 16.8026 4.48533 15.0657C3.78425 13.3288 3.71079 11.4018 4.27762 9.61654C4.84445 7.8313 6.01607 6.2996 7.5907 5.28524C9.16533 4.27089 11.0444 3.83738 12.9043 4.05938C14.7641 4.28137 16.4884 5.14498 17.78 6.50146H15.38C15.1148 6.50146 14.8604 6.60682 14.6729 6.79436C14.4854 6.98189 14.38 7.23625 14.38 7.50146C14.38 7.76668 14.4854 8.02103 14.6729 8.20857C14.8604 8.39611 15.1148 8.50146 15.38 8.50146H19.91C20.1752 8.50146 20.4296 8.39611 20.6171 8.20857C20.8046 8.02103 20.91 7.76668 20.91 7.50146V3.00146C20.91 2.73625 20.8046 2.48189 20.6171 2.29436C20.4296 2.10682 20.1752 2.00146 19.91 2.00146C19.6448 2.00146 19.3904 2.10682 19.2029 2.29436C19.0154 2.48189 18.91 2.73625 18.91 3.00146V4.77146C17.2447 3.17952 15.0808 2.21142 12.784 2.03079C10.4873 1.85017 8.19863 2.46811 6.30492 3.78015C4.41121 5.0922 3.02861 7.01787 2.39083 9.23165C1.75305 11.4454 1.89921 13.8115 2.80461 15.93C3.71001 18.0484 5.31911 19.7893 7.3599 20.8583C9.40069 21.9273 11.748 22.2589 14.005 21.7969C16.2621 21.335 18.2904 20.1079 19.7471 18.3231C21.2039 16.5383 21.9997 14.3053 22 12.0015C22 11.7362 21.8946 11.4819 21.7071 11.2944C21.5196 11.1068 21.2652 11.0015 21 11.0015Z" 
                fill="currentColor" 
            />
        </Icon>
    )
}
