import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const HideNavigationIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 22 22"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            {...props}
        >
            <rect x="0.941821" y="0.941821" width="20.1164" height="20.1164" rx="1.27423" stroke="currentColor" strokeWidth="1.88364" />
            <path d="M8 2V21" stroke="currentColor" strokeWidth="1.88364" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15 14L13 11L15 8" stroke="currentColor" strokeWidth="1.99444" strokeLinecap="round" strokeLinejoin="round" />
        </Icon>
    )
}
