import React, { FC } from 'react'
import { IconButton, Td, Tr } from '@chakra-ui/react'
import { EditIcon, UserDeactivateIcon, UserActivateIcon } from 'icons'
import { IUserModel } from 'stores/models'

interface IUserModelListItem {
    item: IUserModel
    onDisableRequested: (user: IUserModel) => void
    onActivateRequested: (user: IUserModel) => void
    onUpdateRequested: (user: IUserModel) => void
}

export const UsersListItem: FC<IUserModelListItem> = ({
    item,
    onDisableRequested,
    onActivateRequested,
    onUpdateRequested
}) => {

    return (
        <Tr color={item.isActive ? "color.100" : "gray.400"}>
            <Td>{item.number}</Td>
            <Td>{item.displayName}</Td>
            <Td>{item.email}</Td>
            <Td>{item.state}</Td>
            <Td>
                {item.isEditable && (
                    <>
                        <IconButton
                            aria-label="User Edit Icon"
                            variant="ghost"
                            color="blue.300"
                            onClick={() => onUpdateRequested(item)}
                            disabled={!item.isActive}
                            icon={<EditIcon boxSize="15px" />}
                        />
                        {item.isActive ? (
                            <IconButton
                                aria-label="User Deactivate Icon"
                                variant="ghost"
                                color="blue.300"
                                onClick={() => onDisableRequested(item)}
                                icon={<UserDeactivateIcon boxSize="20px" />}
                            />
                        ) : (
                            <IconButton
                                aria-label="User Activate Icon"
                                variant="ghost"
                                color="blue.300"
                                onClick={() => onActivateRequested(item)}
                                icon={<UserActivateIcon boxSize="20px" />}
                            />
                        )}
                    </>
                )}
            </Td>
        </Tr>
    )
}
