export const Accordion = {
    variants: {
        unstyled: {
            container: {
                borderTopWidth: '0px',
                _last: {
                    borderBottomWidth: '0px',
                },
            },
            button: {
                _focus: {
                    boxShadow: 'none',
                },
                _hover: {
                    bg: 'none',
                },
                px: 0,
                py: 1,
            },
            panel: {
                padding: 0,
            },
        },
    },
}
