import { Text, StyleSheet, View } from '@react-pdf/renderer'
import { productPerformanceBySalesReportColoumn } from '../ReportsTable/columnMoc'
import { Cell1 } from './Cellstyle/cell'
import { IProductPerformanceSales } from 'types/core'

export const productPerformanceBysalesoverStyles = StyleSheet.create({
    row: {
        fontSize: '7',
        display: 'flex',
        flexDirection: 'row',
    },
    cell1: {
        width: '15%',
    },
    cell2: {
        width: '20%',
    },
    cell3: {
        width: '50%',
    },
})

export const productPerformanceBysalesoverHeader = (
    <View style={productPerformanceBysalesoverStyles.row}>
        {!!productPerformanceBySalesReportColoumn.length &&
            productPerformanceBySalesReportColoumn.map(column => (
                <Text
                    key={column.Header}
                    style={column.style}
                >
                    {column.Header}
                </Text>
            ))}
    </View>
)

export const productPerformanceBysalesoverRow = (
    data: IProductPerformanceSales
) => {
    const productSalesRow = [
        data.sku,
        data.itemDescription,
        data.quantity,
        data.percentageOfOrders,
    ]
    return (
        <View style={productPerformanceBysalesoverStyles.row}>
            {!!productSalesRow &&
                !!productSalesRow?.length &&
                productSalesRow?.map(productSalesItem => {
                    switch (productSalesItem) {
                        case data?.sku:
                            return (
                                <Cell1
                                    cell1={
                                        productPerformanceBysalesoverStyles.cell2
                                    }
                                    data={productSalesItem}
                                />
                            )
                        case data?.itemDescription:
                            return (
                                <Cell1
                                    cell1={
                                        productPerformanceBysalesoverStyles.cell3
                                    }
                                    data={productSalesItem}
                                />
                            )
                        default:
                            return (
                                <Cell1
                                    cell1={
                                        productPerformanceBysalesoverStyles.cell1
                                    }
                                    data={productSalesItem}
                                />
                            )
                    }
                })}
        </View>
    )
}
