export const TENANT_NAME_LENGTH = 50
export const TENANT_DOMAIN_LENGTH = 1024
export const TENANT_CLIENT_ID_LENGTH = 100
export const TENANT_AUTHORITY_URL_LENGTH = 150
export const NAME_LENGTH = 100
export const DESCRIPTION_LENGTH = 500
export const MESSAGE_LENGTH = DESCRIPTION_LENGTH
export const EMAIL_LENGTH = 256
export const FIRST_NAME_LENGTH = 30
export const LAST_NAME_LENGTH = 30
export const PASSWORD_LENGTH = 50
export const TENANT_CHILD_NAME_LENGTH = 50
export const TENANT_CHILD_DOMAIN_LENGTH = 1024
