import React, { FC } from 'react'
import Select, { ActionMeta, GroupBase, MultiValue, SingleValue, StylesConfig } from 'react-select'
import { ISelectOption } from 'types/core'

interface SelectProps {
    selectValue: ISelectOption | null
    options: ISelectOption[]
    placeholder: string
    onChange: (newValue: SingleValue<ISelectOption> | MultiValue<ISelectOption>, actionMeta: ActionMeta<ISelectOption>) => void
    customStyles: StylesConfig<ISelectOption, boolean, GroupBase<ISelectOption>>
    isSearchable?: boolean
    isDisabled?: boolean
    isClearable?: boolean
}

export const CustomSelect: FC<SelectProps> = ({
  selectValue,
  options,
  placeholder,
  onChange,
  customStyles,
  isSearchable = true,
  isDisabled = false,
  isClearable = false,
}) => (
    <Select
        value={selectValue}
        options={options}
        placeholder={placeholder}
        styles={customStyles}
        onChange={onChange}
        isSearchable={isSearchable}
        isDisabled={isDisabled}
        isClearable={isClearable}
        components={{
            IndicatorSeparator: () => null,
        }}
    />
)
