import { Box, Flex, Text } from '@chakra-ui/layout'
import { chakra } from 'utils/chakra'

export const ItemBox = chakra(Flex, {
    alignSelf: 'center',
    flexBasis: 1,
})

export const ItemBoxStatus = chakra(Flex, {
    alignSelf: 'center',
    flexBasis: 1,
})
export const RectangleIconBox = chakra(Box, {
    position: 'relative',
    right: '4.6px',
    marginRight: '5px',
})

export const CategorySetsBox = chakra(Flex, {
    width: '100%',
    minHeight: '60px',
    backgroundColor: 'blueWhite.950',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.25)',
    borderRadius: '6px',
    alignItems: 'center',
    cursor: 'auto',
    padding: '2px 20px 2px 5px',
    textAlign: 'start',
    margin: '16px 0',
})

export const StatusEnabled = chakra(Text, {
    borderRadius: '6px',
    width: '80px',
    textAlign: 'center',
    height: '32px',
    paddingTop: '7px',
})

export const BoxStyle = chakra(Box, {
    justifyContent: 'end',
    paddingRight: '5px',
})

export const ItemBoxEdit = chakra(Flex, {
    alignSelf: 'center',
    flexBasis: 1,
    justifyContent: 'end',
    paddingRight: '5px',
    flexGrow: 0.5,
})
