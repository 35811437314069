import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const PlainArrowBottomIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 10 6"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M5.0002 4.55157L8.77593 0.775834C9.01025 0.541519 9.39014 0.541519 9.62446 0.775834C9.85877 1.01015 9.85877 1.39005 9.62446 1.62436L5.42446 5.82436C5.19014 6.05868 4.81025 6.05868 4.57593 5.82436L0.375931 1.62436C0.141617 1.39005 0.141617 1.01015 0.375931 0.775834C0.610246 0.541519 0.990145 0.541519 1.22446 0.775834L5.0002 4.55157Z"
                fill="currentColor"
            />
        </Icon>
    )
}
