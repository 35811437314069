import React, { FC } from 'react'
import { Box, Center } from '@chakra-ui/layout'
import { format } from 'date-fns'
import { observer } from 'mobx-react-lite'
import { AccordionIcon } from '@chakra-ui/accordion'
import { RuleStatus } from 'components/RuleStatus'
import { IAuditOrder, WorkflowRunStatus } from 'types/core'
import { auditOrderStore } from 'stores'
import { stringOrDash } from 'utils/stringOrDash'
import { AccordionButtonBox, AccordionButtonItem } from 'pages/OrderDetailsPage/styles'

interface AccordionButtonProps {
    order: IAuditOrder
    status: string
    title: string
    isOpened: boolean
}

export const AccordionButton: FC<AccordionButtonProps> = observer(({
    order: {
        timestamp,
        status: { code },
    },
    status,
    title,
    isOpened,
}) => {
    const isWorkflowRunsFailed = auditOrderStore.isWorkflowRunsFailed
    const boxBorderColor =
        isWorkflowRunsFailed && !isOpened && code === 100 ? 'red.500' : 'transparent'

    return (
        <AccordionButtonBox borderColor={boxBorderColor}>
            <AccordionButtonItem variant="h4" flexGrow={2}>
                {stringOrDash(title)}
            </AccordionButtonItem>
            <AccordionButtonItem variant="linksmall" flexGrow={1}>
                {timestamp ? format(new Date(timestamp), 'LLL. dd, yyyy') : '-'}
            </AccordionButtonItem>
            <AccordionButtonItem variant="linksmall" flexGrow={1}>
                {timestamp ? format(new Date(timestamp), "hh:mm:ss aaaaa'm'") : '-'}
            </AccordionButtonItem>
            <Box textAlign="left" flexBasis="1" flexGrow={5}>
                {isWorkflowRunsFailed && code === 100 ? (
                    <Center marginLeft="8px" justifyContent="flex-start">
                        <RuleStatus status={WorkflowRunStatus.Failed} />
                    </Center>
                ) : (
                    <AccordionButtonItem variant="linksmall" flexGrow={5}>
                        {stringOrDash(status)}
                    </AccordionButtonItem>
                )}
            </Box>
            <AccordionIcon color="grayBlue.400" flexBasis={1} flexGrow={0.3} />
        </AccordionButtonBox>
    )
})
