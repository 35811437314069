import React from 'react'
import { TableForm } from 'types/core'
import { FormControl, FormErrorMessage } from '@chakra-ui/form-control'
import { FormLabel } from '@chakra-ui/react'
import { Input } from '@chakra-ui/input'
import { Switch } from '@chakra-ui/switch'
import { FEATURE_FLAG_NAME_LENGTH } from 'constants/admin'

interface IAddFeatureFlag extends TableForm {
    isGlobal: boolean
}

export const AddFeatureFlag = ({
       errors,
       register,
       isGlobal,
   }: IAddFeatureFlag) => (
    <>
        <FormControl isInvalid={!!errors.name} mt={5}>
            <FormLabel htmlFor="name">Name</FormLabel>
            <Input
                id="name"
                placeholder="Name"
                {...register('name', {
                    required: 'This is required',
                    minLength: {
                        value: FEATURE_FLAG_NAME_LENGTH,
                        message: `Minimum length should be ${FEATURE_FLAG_NAME_LENGTH}`,
                    },
                })}
            />
            <FormErrorMessage>
                {errors.name && errors.name.message}
            </FormErrorMessage>
        </FormControl>
        <FormControl mt={5}>
            <FormLabel htmlFor='enable'>Enable</FormLabel>
            <Switch
                variant='switchBoolean'
                {...register('enable')}
            />
        </FormControl>
        {!isGlobal &&
            <FormControl mt={5}>
                <FormLabel htmlFor='isHierarchical'>Is Hierarchical</FormLabel>
                <Switch
                    variant='switchBoolean'
                    {...register('isHierarchical')}
                />
            </FormControl>
        }
    </>
)
