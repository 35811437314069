import { ordersInstance } from './instances'
import { OrderRetryResponse } from '../types/api'

export class OrderRetryAPI {
    static async retryOrders(orderIds: string[], reason: string) {
        await ordersInstance.post<OrderRetryResponse>(
            `/$retry`, 
            {
                orderIds, 
                reason
            })
    }

    static async orderRelations(id: string, orderType: number) {
        const { data } = await ordersInstance.get(`${id}/relations?type=${orderType}`)

        return data
    }
}