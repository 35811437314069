import React, { useCallback, useEffect, useState } from 'react'
import { useDisclosure } from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import { PreviewDialogModal } from '../PreviewDialogModal'
import { submitForApprovalStore } from 'stores'
import { WorkflowsAPI } from 'api/workflows'
import { EyeIcon } from 'icons'
import { PreviewBox, ImageBox, CenterIcon } from './styles'

export const DiagramPreview = observer(() => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [image, setImage] = useState('')

    const workflow = toJS(submitForApprovalStore.workflowStore.workflow)

    const loadPreview = useCallback(async () => {
        if (workflow) {
            try {
                const img = await WorkflowsAPI.getPreview(workflow.id)
                setImage(img)
            } catch (err) {
                setImage('')
            }
        }
    }, [workflow])

    useEffect(() => {
        loadPreview()
    }, [loadPreview])

    if (!workflow) {
        return <PreviewBox />
    }

    return (
        <>
            <PreviewBox cursor="pointer" onClick={onOpen}>
                <ImageBox src={image} />
                <CenterIcon onClick={onOpen}>
                    <EyeIcon boxSize="15px" color="white" />
                </CenterIcon>
            </PreviewBox>
            <PreviewDialogModal
                isOpen={isOpen}
                onClose={onClose}
                viewId={workflow.id}
                title={workflow.name}
            />
        </>
    )
})
