import React, { FC, useRef, useState, useEffect } from 'react'
import { useColorMode } from '@chakra-ui/react'
import { Theme } from 'types/core'
import { getIcons } from './utils'
import { IconStatus, statusColor } from './mock'
import { StatusBox, StatusText } from './styles'

interface StatusTagProps {
    status: IconStatus
    text: string
    backgroundColor?: string
}

export const StatusTag: FC<StatusTagProps> = ({
    status,
    text,
    backgroundColor = 'transparent',
}) => {
    const [height, setHeight] = useState(0)
    const { colorMode } = useColorMode()
    const ref = useRef<HTMLDivElement>(null)

    const color = statusColor[status]
    const icon = getIcons(status, height, color)

    const handleChangeHeight = () => {
        if (ref && ref.current) setHeight(ref.current.clientHeight)
    }

    useEffect(() => {
        handleChangeHeight()
        window.addEventListener('resize', handleChangeHeight)
        return () => window.removeEventListener('resize', handleChangeHeight)
    }, [])

    return (
        <StatusBox
            ref={ref}
            backgroundColor={backgroundColor}
            border={colorMode === Theme.Light ? `1px solid ${color}` : '0'}
        >
            {icon}
            <StatusText variant="smalllink">
                {text}
            </StatusText>
        </StatusBox>
    )
}
