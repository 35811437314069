import { Stack } from '@chakra-ui/layout'
import React, { FC } from 'react'
import { StepMetadata } from 'types/core'
import { PaletteNode } from './PaletteNode'

interface PaletteProps {
    metadatas: StepMetadata[]
}

export const Palette: FC<PaletteProps> = ({ metadatas }) => {
    return (
        <Stack>
            {metadatas.map(metadata => (
                <PaletteNode key={metadata.id} metadata={metadata} />
            ))}
        </Stack>
    )
}
