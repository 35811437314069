import { tenantsInstance } from './instances'
import {
    AuthenticationSchemaResponse,
    PagingFilters,
    SecretsResponse,
} from '../types/api'
import { IAuthenticationSchema } from '../types/core'

export class AuthenticationSchemaAPI {
    static async getAuthenticationSchema(
        tenantId: string,
        { page, size }: PagingFilters
    ): Promise<AuthenticationSchemaResponse> {
        const response =
            await tenantsInstance.get<AuthenticationSchemaResponse>(
                `/${tenantId}/integration-auth-scheme?page=${page}&size=${size}`
            )

        return response.data
    }

    static async addAuthenticationSchema(
        tenantId: string,
        name: string,
        secretId: string,
        locations: {}
    ) {
        const response = tenantsInstance.post<IAuthenticationSchema>(
            `/${tenantId}/integration-auth-scheme`,
            {
                name,
                secretId,
                locations,
            }
        )

        return response || {}
    }

    static async updateAuthenticationSchema(data: IAuthenticationSchema) {
        const response = tenantsInstance.put(
            `/${data.tenantId}/integration-auth-scheme/${data.id}`,
            {
                ...data,
            }
        )

        return response || {}
    }

    static async deleteAuthenticationSchema(data: IAuthenticationSchema) {
        const response = tenantsInstance.delete(
            `/${data.tenantId}/integration-auth-scheme/${data.id}`
        )

        return response || {}
    }

    static async getAuthSchemeSecrets(
        tenantId: string
    ): Promise<SecretsResponse> {
        const response = await tenantsInstance.get<SecretsResponse>(
            `/${tenantId}/secrets?category=auth`,
            {}
        )
        return response.data
    }
}
