import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

interface PlusEllipseIconProps {
    iconText: string
    circleColor?: string
}

export const PlusEllipseIcon: FC<PlusEllipseIconProps & IconProps> = ({
    iconText,
    circleColor = '#4774A5',
    ...props
}) => {
    return (
        <Icon
            viewBox='0 0 16 16'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <circle filter="drop-shadow( 0 0 2px rgba(0, 0, 0, .4))" cx="8" cy="8" r="4" fill={circleColor}/>
            <text
                x={iconText === '+' ? '6.3': '6.5'}
                y="9.8"
                fontSize="6"
                fontWeight="400"
                fill="white"
            >
                {iconText}
            </text>
        </Icon>
    )
}
