import React, { FC, useEffect } from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { Center } from '@chakra-ui/react'
import { Box, Divider, Stack } from '@chakra-ui/layout'
import { useNavigate } from 'react-router-dom'
import { routes } from 'navigation/routes'
import { NumericPagination } from 'components/NumericPagination'
import { Input } from './content/Input'
import { CategoryHeader } from './content/CategoryHeader'
import { CategoryItem } from './content/CategoryItem'
import { checkRoleForConfiguration } from 'utils/jwt'
import { customeAttributeStore, userStore } from 'stores'
import {
    BoxStyled,
    FiltersBox,
    CreateCategoryButton,
    Title,
    TitleBox,
    ContainerBox,
} from './styles'

export const CategoryPage: FC = observer(() => {
    const category = toJS(customeAttributeStore.categoryList)
    const { isCreator } = toJS(userStore.currentRoles)
    const navigate = useNavigate()
    const onCreateNew = (): void => navigate(routes.createNewCategory)
    const currentRole = toJS(userStore.currentRoles)

    useEffect(() => {
        customeAttributeStore.search()
    }, [])

    return (
        <ContainerBox>
            <Stack
                as={Box}
                spacing={{ base: 8, md: 14 }}
                py={{ base: 20, md: 4 }}
            >
                <Stack>
                    <BoxStyled>
                        <TitleBox>
                            <Title>Category</Title>
                        </TitleBox>
                        <Divider margin="8px 0" />
                        <FiltersBox>
                            <Input />
                            <Center marginRight="10px">
                                {checkRoleForConfiguration(currentRole) && (
                                    <CreateCategoryButton
                                        disabled={!isCreator}
                                        onClick={onCreateNew}
                                    >
                                        Create New Category
                                    </CreateCategoryButton>
                                )}
                            </Center>
                        </FiltersBox>
                        {!!category.length && (
                            <>
                                <CategoryHeader />
                                {category.map(res => (
                                    <CategoryItem
                                        key={res.categoryId}
                                        id={res.categoryId}
                                        name={res.categoryName}
                                        source={res.attributeSourceName}
                                        status={res.status}
                                    />
                                ))}
                                <NumericPagination
                                    marginTop="24px"
                                    pages={customeAttributeStore.pages}
                                    activePage={
                                        customeAttributeStore.filters.page
                                    }
                                    onChangePage={page =>
                                        customeAttributeStore.setPages({ page })
                                    }
                                />
                            </>
                        )}
                    </BoxStyled>
                </Stack>
            </Stack>
        </ContainerBox>
    )
})
