import React, { FC } from 'react'
import { EdgeProps } from 'react-flow-renderer'
import { EdgeLayout } from './layout/EdgeLayout'
import { useToken } from '@chakra-ui/react'

export const ElseEdge: FC<EdgeProps> = props => {
    const [gray300] = useToken('colors', ['gray.300'])

    return (
        <EdgeLayout
            edge={props}
            path={{
                style: {
                    stroke: gray300,
                },
            }}
            text={{
                label: 'else',
            }}
        />
    )
}
