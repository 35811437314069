import { makeAutoObservable } from 'mobx'
import { v4 } from 'uuid'
import { TenantsGroupMappingAPI } from 'api/tenantsGroupMapping'
import { ITenantsRoles, UUID } from 'types/core'
import { notificationStore, userStore } from './index'
import { StatusType } from '../types/chart'
import { UserRole } from '../api/models'

export class TenantsGroupMappingStore {
    public roles: string[] = []
    public supportRoles: string[] = []
    public selectedRoles: ITenantsRoles[] = []
    public defaultSelectedRoles: ITenantsRoles[] = []

    constructor() {
        makeAutoObservable(this)
    }

    public async fetch(tenantId: UUID, tenantAuthType: string) {
        try {
            const tenantsRoles: ITenantsRoles[] =
                await TenantsGroupMappingAPI.getAll(tenantId, tenantAuthType)
            const addIds = tenantsRoles.map(tenantsRole => ({
                ...tenantsRole,
                id: v4(),
            }))
            this.initSelectedRoles(addIds)
            this.initDefaultSelectedRoles(addIds)
        } catch (err) {
            notificationStore.triggerErrorToast(err)
            throw err
        }
    }

    public async getTenantRoles() {
        try {
            const response = await TenantsGroupMappingAPI.getTenantRoles()
            this.initTenantRoles(response)
        } catch (err) {
            notificationStore.triggerErrorToast(err)
            throw err
        }
    }

    public addSelectedRole(id: UUID) {
        if (this.roles.length) {
            const updatedRoles = [...this.selectedRoles]
            const { isTenantAdmin, isSuperAdmin } = userStore.currentRoles
            updatedRoles.push({
                id,
                groupId: '',
                roleName:
                    !isTenantAdmin || !isSuperAdmin
                        ? this.supportRoles[0]
                        : this.roles[0],
                isNewRow: true,
            })
            this.selectedRoles = updatedRoles
        } else {
            notificationStore.toast({
                title: 'Error',
                description: 'Empty Roles...',
                status: StatusType.Error,
            })
        }
    }

    public updateSelectedRoles(changedRole: ITenantsRoles) {
        this.selectedRoles = this.selectedRoles.map(selectedRole => {
            if (selectedRole.id === changedRole.id) return changedRole
            return selectedRole
        })
    }

    public updateSelectedIds(value: UUID, id: UUID) {
        this.selectedRoles = this.selectedRoles.map(selectedRole => {
            if (selectedRole.id === id)
                return { ...selectedRole, groupId: value }
            return selectedRole
        })
    }

    public deleteSelectedRole(id: UUID) {
        this.selectedRoles = this.selectedRoles.filter(
            selectedRole => selectedRole.id !== id
        )
    }

    public async saveSelectedRoles(
        id: UUID,
        authenticationType: string | null
    ) {
        try {
            if (authenticationType) {
                const adaptedRoles = this.selectedRoles.map(
                    ({ groupId, roleName }) => ({ groupId, roleName })
                )
                await TenantsGroupMappingAPI.saveAll(
                    id,
                    authenticationType,
                    adaptedRoles
                )
                this.defaultSelectedRoles = this.selectedRoles
            } else {
                notificationStore.toast({
                    title: 'Error',
                    description: 'Authentication Type are empty',
                    status: StatusType.Error,
                })
            }
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    private initSelectedRoles(tenantsGroupMapping: ITenantsRoles[]) {
        const updatedData = tenantsGroupMapping.map(data => ({
            ...data,
            isNewRow: false,
        }))
        this.selectedRoles = updatedData
    }

    private initDefaultSelectedRoles(tenantsGroupMapping: ITenantsRoles[]) {
        const updatedData = tenantsGroupMapping.map(data => ({
            ...data,
            isNewRow: false,
        }))
        this.defaultSelectedRoles = updatedData
    }

    private initTenantRoles(roles: string[]) {
        this.supportRoles = roles.filter(role => role !== UserRole.TenantAdmin)
        this.roles = roles
    }
}
