import { makeAutoObservable } from 'mobx'
import { notificationStore } from 'stores'
import { WorkflowStore } from './WorkflowStore'
import { DiagramStore } from './DiagramStore'
import { UUID } from 'types/core'
import { WorkflowsService } from 'services/workflows'

export class ApprovalStore {
    workflowStore: WorkflowStore
    diagramStore: DiagramStore

    constructor() {
        this.workflowStore = new WorkflowStore()
        this.diagramStore = new DiagramStore()

        makeAutoObservable(this)
    }

    public async init(id: UUID) {
        try {
            const workflow = await this.workflowStore.fetch(id)
            this.diagramStore.init(workflow)
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    public async approve(message: string) {
        if (!this.workflowStore.workflow) {
            return
        }

        try {
            const approved = await WorkflowsService.approve(
                this.workflowStore.workflow.id,
                message
            )
            this.workflowStore.workflow.status = approved.status
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    public async reject(message: string) {
        if (!this.workflowStore.workflow) {
            return
        }
        try {
            const rejected = await WorkflowsService.reject(
                this.workflowStore.workflow.id,
                message
            )
            this.workflowStore.workflow.status = rejected.status
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    public clean() {
        this.workflowStore.clean()
        this.diagramStore.clean()
    }
}
