import { AxiosResponse } from 'axios'
import {
    ApproveWorkflowRequest,
    CreateWorkflowDto,
    PutWorkflowRequest,
    RejectWorkflowRequest,
    StepDto,
    SubmitForApprovalResponse, WorkflowAdd,
    WorkflowsFilters,
    WorkflowsResponse,
} from 'types/api'
import {
    ApprovedRejectedWorkflow, ChangedWorkflow,
    CreatedWorkflow,
    LayoutData,
    UUID,
    Workflow,
    WorkflowApproval,
    WorkflowStep, WorkflowSubmitForApproval,
} from 'types/core'
import { trueQueryParams } from 'utils/trueQueryParams'
import { workflowsInstance } from './instances'

export class WorkflowsAPI {
    static async getAll(filters: WorkflowsFilters) {
        const response = await workflowsInstance.get<WorkflowsResponse>(
            trueQueryParams(filters)
        )
        return response.data
    }

    static async getById(id: UUID) {
            const response = await workflowsInstance.get<Workflow>(`/${id}`)
            return response.data
    }

    static async getByWorkflowHeaderId(filters: WorkflowsFilters) {
        const response = await workflowsInstance.get<WorkflowsResponse>(trueQueryParams(filters))
        return response.data
    }

    static async addNew(params: WorkflowAdd) {
        const response = await workflowsInstance.post<
            CreateWorkflowDto,
            AxiosResponse<CreatedWorkflow>
        >('/', params)
        return response.data
    }

    static async deleteById(id: UUID) {
        await workflowsInstance.delete(`/${id}`)
    }

    static async addStep(
        workflowId: UUID,
        metadataId: UUID,
        layout: LayoutData
    ) {
        const response = await workflowsInstance.post<
            StepDto,
            AxiosResponse<WorkflowStep>
        >(`/${workflowId}/steps`, {
            layout,
            stepMetaDataId: metadataId,
        })

        return response.data
    }

    static async putById(
        id: UUID,
        changedWorkflow: ChangedWorkflow
    ) {
        const newWorkflow: PutWorkflowRequest = {
            ...changedWorkflow,
            trigger: '',
        }
        const response = await workflowsInstance.put(`/${id}`, newWorkflow)
        return response.data
    }

    static async addPreview(id: UUID, file: File) {
        const formData = new FormData()
        formData.append('layout-preview', file)
        const { data = {} } = await workflowsInstance.post(
            `/${id}/preview`,
            formData
        )
        return data
    }

    static async getPreview(id: UUID): Promise<string> {
        const response = await workflowsInstance.get(`/${id}/preview`, {
            responseType: 'arraybuffer',
        })

        const contentType = response.headers['content-type'].toLowerCase()
        const image = Buffer.from(response.data, 'binary').toString('base64')

        return `data:${contentType};base64,${image}`
    }

    static async submitForApproval(id: UUID, approval: WorkflowApproval | WorkflowSubmitForApproval) {
        const response = await workflowsInstance.post<
            WorkflowApproval,
            AxiosResponse<SubmitForApprovalResponse>
        >(`/${id}/$submit-for-approval`, approval)
        return response.data
    }

    static async approve(id: UUID, message?: string) {
        const response = await workflowsInstance.post<
            ApproveWorkflowRequest,
            AxiosResponse<ApprovedRejectedWorkflow>
        >(`/${id}/$approve`, {
            message,
        })
        return response.data
    }

    static async reject(id: UUID, message?: string) {
        const response = await workflowsInstance.post<
            RejectWorkflowRequest,
            AxiosResponse<ApprovedRejectedWorkflow>
        >(`/${id}/$reject`, {
            message,
        })
        return response.data
    }

    static async obsolete(id: UUID, message?: string) {
        const response = await workflowsInstance.post<
            RejectWorkflowRequest,
            AxiosResponse<ApprovedRejectedWorkflow>
        >(`/${id}/$obsolete`, message ? {
            message,
        } : {})
        return response.data
    }

    static async modify(id: UUID, message?: string) {
        const response = await workflowsInstance.post<
            RejectWorkflowRequest,
            AxiosResponse<ApprovedRejectedWorkflow>
        >(`/${id}/$draft`, message ? {
            message,
        } : {})
        return response.data
    }
}
