import {
    reportInstence,
    reportsShipperInstence,
    reportsMenuList,
    reportsProductPerformSourcesInstence,
    reportsProductPerformItemsInstence,
    reportProdPerformanceByTotalOrders,
    reportProductPerformanceBySales,
    reportsSourceInstance,
    reportErrors,
} from './instances'
import { PagingFilters } from 'types/api'

export class ReportAPI {
    static async getTotalReports(
        fromDate: string,
        todate: string,
        shipername: string,
        { page, size }: PagingFilters
    ) {
        const { data = {} } = await reportInstence.get(
            `?fromdate=${fromDate}&todate=${todate}&shipper=${shipername}&PageIndex=${page}&PageSize=${size}`
        )
        return data
    }

    static async shipperNames() {
        const { data = {} } = await reportsShipperInstence.get('')
        return data
    }

    static async getMenuReports(filter: string) {
        const { data = {} } = await reportsMenuList.get(`?filter=${filter}`)
        return data
    }

    static async productPerformnceSource() {
        const { data = {} } = await reportsProductPerformSourcesInstence.get('')
        return data
    }

    static async getProductPerformanceTotalReports(
        fromDate: string,
        todate: String,
        source: string,
        { page, size }: PagingFilters
    ) {
        const { data = {} } = await reportsProductPerformItemsInstence.get(
            `?fromdate=${fromDate}&todate=${todate}&source=${source}&PageIndex=${page}&PageSize=${size}`
        )
        return data
    }

    static async sourceNames() {
        const { data = {} } = await reportsSourceInstance.get('')
        return data
    }

    static async getProductPerformanceBySalesReport(
        fromDate: string,
        toDate: string,
        source: string,
        { page, size }: PagingFilters
    ) {
        const { data = {} } = await reportProductPerformanceBySales.get(
            `?fromdate=${fromDate}&todate=${toDate}&sourceId=${source}&pageIndex=${page}&pageSize=${size}`
        )
        return data
    }

    static async getProductPerformanceByTotalOrdersReport(
        fromDate: string,
        toDate: string,
        { page, size }: PagingFilters
    ) {
        const { data = {} } = await reportProdPerformanceByTotalOrders.get(
            `?fromdate=${fromDate}&todate=${toDate}&page=${page}&size=${size}`
        )
        return data
    }

    static async getErrorStatusReport(
        fromDate: string,
        toDate: string,
        { page, size }: PagingFilters
    ) {
        const { data = {} } = await reportErrors.get(
            `?fromdate=${fromDate}&todate=${toDate}&pageIndex=${page}&pageSize=${size}`
        )
        return data
    }

    static async getErrorStatusReportExport(fromDate: string, toDate: string) {
        const { data = {} } = await reportErrors.get(
            `?fromdate=${fromDate}&todate=${toDate}&export=true`
        )
        return data
    }

    static async getProductPerformanceByTotalOrdersReportExport(
        fromDate: string,
        toDate: string
    ) {
        const { data = {} } = await reportProdPerformanceByTotalOrders.get(
            `?fromdate=${fromDate}&todate=${toDate}&export=true`
        )
        return data
    }

    static async getProductPerformanceBySalesReportExport(
        fromDate: string,
        toDate: string,
        source: string
    ) {
        const { data = {} } = await reportProductPerformanceBySales.get(
            `?fromdate=${fromDate}&todate=${toDate}&sourceId=${source}&export=true`
        )
        return data
    }

    static async getProductPerformanceTotalReportsExport(
        fromDate: string,
        todate: String,
        source: string
    ) {
        const { data = {} } = await reportsProductPerformItemsInstence.get(
            `?fromdate=${fromDate}&todate=${todate}&source=${source}&export=true`
        )
        return data
    }

    static async getOnTimeShippersExport(
        fromDate: string,
        toDate: string,
        shipername: string
    ) {
        const { data = {} } = await reportInstence.get(
            `?fromdate=${fromDate}&todate=${toDate}&shipper=${shipername}&export=true`
        )
        return data
    }
}
