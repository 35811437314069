import { Chart } from './Chart'
import {
    Flex,
    Spacer,
    Text,
} from '@chakra-ui/layout'
import {
    CardContainer,
    ContentContainer,
    NameContainer,
} from './styles'
import { Center } from '@chakra-ui/react'
import { dailyOrderStore } from 'stores'
import { useEffect } from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { ExportMenuOption } from '../ExportMenu'

export const OrderThroughputCard = observer(() => {


    useEffect(() => {
        dailyOrderStore.getOrderSources()
        return () => {
            dailyOrderStore.clean()
        }
    }, [])
    const csvData = toJS(dailyOrderStore?.csvDataFormat)

    return (
        <CardContainer>
            <NameContainer>
                <Flex>
                    <Text color="white" variant="h5">
                        Daily Count of Orders by Shippers
                    </Text>
                    <Spacer />
                    <Center>
                        <ExportMenuOption
                            data={csvData}
                            fileName="Dailyorders"
                            id='dailyorders' />
                    </Center>
                </Flex>
            </NameContainer>
            <ContentContainer id='dailyorders'>
                <Chart/>
            </ContentContainer>
        </CardContainer>
    )
})
