import { makeAutoObservable } from 'mobx'
import {
    AuthNoAuth,
    IAuthApiKey,
    IAuthenticationSchema,
    ResourceOwnerAuth,
} from '../types/core'
import { PagingFilters } from '../types/api'
import { AuthenticationSchemaAPI } from '../api/authenticationSchema'
import { SecretsStore } from './SecretsStore'
import { TenantsStore } from './TenantsStore'

const defaultFilters: PagingFilters = {
    page: 1,
    size: 10,
}

const gettingAllItemsFilter: PagingFilters = {
    page: 1,
    size: -1
}

export class AuthenticationSchemaStore {
    public data: IAuthenticationSchema[] = []
    public dataChannels: IAuthenticationSchema[] = []
    public filters: PagingFilters = defaultFilters
    public pages: number = 0

    constructor(
        public secretsStore: SecretsStore,
        public tenantsStore: TenantsStore,
        public authSchemeSecrets: (
            | IAuthApiKey
            | ResourceOwnerAuth
            | AuthNoAuth
        )[] = []
    ) {
        makeAutoObservable(this)
    }

    public async fetch(id: string, filters = this.filters) {
        try {
            const response =
                await AuthenticationSchemaAPI.getAuthenticationSchema(
                    id,
                    filters
                )
            await this.init(response.items)
            this.setUpPages(response.totalPages)
        } catch (err) {
            await this.init([])
        }
    }

    public async getAllAuthSchemas(id: string) {
        try {
            const response = await AuthenticationSchemaAPI.getAuthenticationSchema(
                id,
                gettingAllItemsFilter
            )
            this.init(response.items)
            this.initAuthAll(response.items)
        } catch (err) {
            this.init([])
            this.initAuthAll([])
        }
    }

    private initAuthAll(items: IAuthenticationSchema[]) {
        this.dataChannels = items
    }

    private async init(items: IAuthenticationSchema[]) {
        await Promise.all([
            this.secretsStore.fetchApiKeys(this.tenantsStore.tenantId),
            this.secretsStore.fetchResourceOwners(this.tenantsStore.tenantId),
            this.secretsStore.fetchAuthNoAuth(this.tenantsStore.tenantId)
        ])

        this.authSchemeSecrets = [
            ...this.secretsStore.dataApiKeys,
            ...this.secretsStore.dataResourceOwners,
            ...this.secretsStore.dataNoAuth
        ]

        const updatedData = items.map(authSchema => {
            const selectedSecret = this.authSchemeSecrets.find(
                authSecret => authSecret.id === authSchema.secretId
            )

            const secretName = `${selectedSecret?.name} (${selectedSecret?.subcategory})`

            return {
                ...authSchema,
                secretName,
            }
        })
        this.data = updatedData
    }

    private setUpPages(totalPages: number) {
        this.pages = totalPages
    }

    public setFilters(filters: Partial<PagingFilters>) {
        if (!filters.page) {
            this.filters = { ...this.filters, ...filters, page: 1 }
        } else {
            this.filters = { ...this.filters, ...filters }
        }
    }

    public clean() {
        this.filters = defaultFilters
    }
}
