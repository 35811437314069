import React, { FC } from 'react'
import { BoxProps, Divider, Spacer, Text } from '@chakra-ui/layout'
import { WidgetLayoutBox, TitleBox } from './styles'

interface WidgetLayoutProps {
    title: string
    widgetBox?: BoxProps
    actions?: JSX.Element
}

export const WidgetLayout: FC<WidgetLayoutProps> = ({
    title,
    widgetBox = {},
    actions,
    children,
}) => {
    return (
        <WidgetLayoutBox{...widgetBox}>
            <TitleBox>
                <Text variant="h2">{title}</Text>
                <Spacer minWidth="15px" />
                {actions}
            </TitleBox>
            <Divider marginTop="8px" />
            {children}
        </WidgetLayoutBox>
    )
}
