import * as echarts from 'echarts'
import { CsvDataFormat, graph, timeIntervel } from 'types/core'

export const defaulitCpountGraph = [
    {
        name: '',
        type: 'line',
        stack: 'Total',
        smooth: true,
        lineStyle: {
            width: 1.5
        },
        showSymbol: false,
        areaStyle: {
            opacity: 1,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: "green"
            }])
        },
        emphasis: {
            focus: 'series'
        },
        data: [0, 0, 0, 0, 0]
    }
]

export const defaultCsvData: CsvDataFormat[] = [{
    ShipperName: '--', Count: 0, Time: '00:00'
},
]
export const defaulitTimeIntervel: timeIntervel = [0, 0, 0, 0, 0]

export const myOptions = (timeIntervals: timeIntervel, seriesData: graph[], color: string) => {

    return {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985',
                }
            },
        },
        legend: {
            top: '85%',
            left: 'center',
            padding: [0, 0, 0, 0],
            itemWidth: 20,
            itemHeight: 4,
            textStyle: {
                color: (color === 'dark') ? 'white' : 'black',
            },
        },
        grid: {
            containLabel: true,
        },
        xAxis: [{
            name: 'Time Duration',
            nameLocation: 'middle',
            nameTextStyle: {
                padding: [10, 8, 18, 8],
                color: (color === 'dark') ? 'white' : 'black',
            },
            axisLabel: {
                height: 2,
                color: (color === 'dark') ? 'white' : 'black',
            },
            type: 'category',
            boundaryGap: false,
            data: timeIntervals
        }],
        yAxis: [{
            name: 'Total Number of Orders',
            nameLocation: 'middle',
            nameTextStyle: {
                padding: [8, 8, 10, 8],
                color: (color === 'dark') ? 'white' : 'black',
            },
            axisLabel: {
                color:(color === 'dark') ? 'white' : 'black',
            },
            type: 'value',

        }],
        series: seriesData,
    };
}

