import { Column } from 'react-table'
import {
    AuthenticationSchemaLocationFormat,
    AuthenticationSchemaLocationType,
    ChannelsSchemaHttpMethod,
    ISelectOption,
} from 'types/core'
import PasswordCell from './PasswordCell'
import { ActionTableCell } from 'pages/AdminPages/Gateway/MappingPage/ActionTableCell'
import TwoActionsCells from './TwoActionsCells'
import HttpMethodsCell from './HttpMethodsCell'
import CheckBoxFeatureFlagsCell from './FeatureFlagsCheckBoxCell'
import MappingConfigCell from './MappingConfigCell'
import BtnDeleteFeatureFlagsCell from './BtnDeleteFeatureFlagsCell'

export enum ChannelType {
    Inbound = 'Inbound',
    Outbound = 'Outbound',
}

export enum ModalState {
    Add = 'add',
    Edit = 'edit',
}

export const inputPattern = /^(ftp|http|https):\/\/[^ "]+$/

export const channelTypeOptions: ISelectOption[] = [
    {
        value: 'Inbound',
        label: 'Inbound',
    },
    {
        value: 'Outbound',
        label: 'Outbound',
    },
]

export const routingRuleOptions: ISelectOption[] = [
    { value: 'Header', label: 'Header' },
    { value: 'Query', label: 'Query' },
    { value: 'JSONBody', label: 'JSONBody' },
]

export const locationTypeHttpMethod = [
    {
        value: ChannelsSchemaHttpMethod.Get,
        label: ChannelsSchemaHttpMethod.Get,
    },
    {
        value: ChannelsSchemaHttpMethod.Post,
        label: ChannelsSchemaHttpMethod.Post,
    },
    {
        value: ChannelsSchemaHttpMethod.Put,
        label: ChannelsSchemaHttpMethod.Put,
    },
    {
        value: ChannelsSchemaHttpMethod.Delete,
        label: ChannelsSchemaHttpMethod.Delete,
    },
]

export const locationTypeOptions = [
    {
        value: AuthenticationSchemaLocationType.Header,
        label: AuthenticationSchemaLocationType.Header,
    },
    {
        value: AuthenticationSchemaLocationType.Query,
        label: AuthenticationSchemaLocationType.Query,
    },
]

export const locationFormatOptions = [
    {
        value: AuthenticationSchemaLocationFormat.Plain,
        label: AuthenticationSchemaLocationFormat.Plain,
    },
    {
        value: AuthenticationSchemaLocationFormat.Base64,
        label: AuthenticationSchemaLocationFormat.Base64,
    },
    {
        value: AuthenticationSchemaLocationFormat.Urlencode,
        label: AuthenticationSchemaLocationFormat.Urlencode,
    },
    {
        value: AuthenticationSchemaLocationFormat.Basicauth,
        label: AuthenticationSchemaLocationFormat.Basicauth,
    },
]

export const locationIndexOptions = [
    {
        value: '0',
        label: '0',
    },
    {
        value: '1',
        label: '1',
    },
]

export const columnsApiKey: Column[] = [
    {
        Header: 'Name',
        accessor: 'name',
    },
    {
        Header: 'Api key',
        accessor: 'apiKey',
    },
]

export const columnsResourceOwner: Column[] = [
    {
        Header: 'Name',
        accessor: 'name',
    },
    {
        Header: 'Client Id',
        accessor: 'clientId',
    },
    {
        Header: 'Client Secret',
        accessor: 'clientSecret',
    },
    {
        Header: 'User Name',
        accessor: 'username',
    },
    {
        Header: 'Password',
        accessor: 'password',
        Cell: PasswordCell,
    },
]

export const columnsMapping: Column[] = [
    {
        Header: 'Id',
        accessor: 'id',
    },
    {
        Header: 'Name',
        accessor: 'name',
    },
    {
        Header: 'Action',
        accessor: 'edit',
        Cell: ActionTableCell,
    },
]

export const columnsChannel: Column[] = [
    {
        Header: 'Name',
        accessor: 'name',
    },
    {
        Header: 'Channel Type',
        accessor: 'type',
    },
    {
        Header: 'Resource Type',
        accessor: 'resourceType',
    },
    {
        Header: 'Relative URL',
        accessor: 'relativeUrl',
    },
    {
        Header: 'Downstream URL',
        accessor: 'downstreamUrl',
    },
    {
        Header: 'Http Method',
        accessor: 'httpMethods',
        Cell: HttpMethodsCell,
    },
    {
        Header: 'Authentication Scheme Id',
        accessor: 'authSchemeName',
    },
    {
        Header: 'Mapping Configuration',
        accessor: 'mapperId',
        Cell: MappingConfigCell,
    },
    {
        Header: 'Actions',
        accessor: 'edit',
        Cell: TwoActionsCells,
    },
]

export const columnsFeatureFlagsGlobal: Column[] = [
    {
        Header: 'Id',
        accessor: 'id',
    },
    {
        Header: 'Name',
        accessor: 'name',
    },
    {
        Header: 'Enable',
        accessor: 'enable',
        Cell: CheckBoxFeatureFlagsCell,
    },
]

export const columnsFeatureFlagsPerTenant: Column[] = [
    {
        Header: 'Tenant Id',
        accessor: 'tenantId',
    },
    {
        Header: 'Name',
        accessor: 'name',
    },
    {
        Header: 'Enable',
        accessor: 'enable',
        Cell: CheckBoxFeatureFlagsCell,
    },
    {
        Header: 'Is Hierarchical',
        accessor: 'isHierarchical',
        Cell: CheckBoxFeatureFlagsCell,
    },
    {
        Header: 'Action',
        accessor: 'id',
        Cell: BtnDeleteFeatureFlagsCell,
    },
]
