import React, { FC } from 'react'
import {
    ActionMeta,
    default as ReactSelect,
    GroupBase,
    InputActionMeta,
    OptionsOrGroups,
    StylesConfig,
} from 'react-select'
import { OptionData } from '../Selects'

type IsMulti = true

interface MultiSelectProps {
    components: any
    options: OptionsOrGroups<OptionData, GroupBase<OptionData>> | undefined
    optionSelected: readonly OptionData[] | null
    customStyles: StylesConfig<OptionData, IsMulti, GroupBase<OptionData>> | undefined
    placeholder: string
    onChange: (
        selectedValues: readonly OptionData[],
        { action, option }: ActionMeta<OptionData>
    ) => void
    isDisabled?: boolean
    menuIsOpen?: boolean
    isClearable?: boolean
    isSearchable?: boolean
    selectAllOption?: OptionData
    onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void
    inputValue?: string

}

export const MultiSelect: FC<MultiSelectProps> = ({
    options= [],
    selectAllOption ,
    optionSelected,
    placeholder,
    customStyles,
    components,
    onChange,
    isClearable = true,
    menuIsOpen,
    isSearchable = true,
    isDisabled = false,
    onInputChange = () => {},
    inputValue,
}) => {
    return (
        <ReactSelect
            isMulti
            options={options.length && selectAllOption ? [selectAllOption, ...options] : options}
            value={optionSelected}
            placeholder={placeholder}
            styles={customStyles}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            onChange={onChange}
            components={components}
            isClearable={isClearable}
            isSearchable={isSearchable}
            isDisabled={isDisabled}
            onInputChange={onInputChange}
            inputValue={inputValue}
            menuIsOpen={menuIsOpen}
        />
    )
}
