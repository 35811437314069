import React from 'react'
import { Heading, Flex } from '@chakra-ui/layout'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { User } from './content/User/User'
import { UsersList } from './content/UsersList'
import { TenantTree } from 'components/TenantsComponents/TenantTree'
import { NotSelectedTenantPage } from 'components/NoSelectedTenant'
import { tenantsStore } from 'stores'
import { UsersBox, HeadingStyled, TreeBox, TabsBox } from './styles'

interface IAdminUsersPage {
    isUserList?: boolean
}

export const AdminUsersPage = observer(({isUserList = true}: IAdminUsersPage) => {
    const selectedTenant = toJS(tenantsStore.selectedTenant)

    return (
        <UsersBox padding="31px 0">
            <Heading as="h1" variant="h1" marginBottom="24px">
                Admin Users Page
            </Heading>
            <Flex color='white'>
                <TreeBox >
                    <HeadingStyled>
                        Tenants Tree
                    </HeadingStyled>
                    <TenantTree />
                </TreeBox>
                <TabsBox>
                    {!!selectedTenant.id &&
                        <>
                            {isUserList ? <UsersList /> : <User />}
                        </>
                    }
                    {!selectedTenant.id && <NotSelectedTenantPage />}
                </TabsBox>
            </Flex>
        </UsersBox>
    )
})
