import { FC } from 'react'
import { Text } from '@chakra-ui/layout'
import { OnTimeShippingReport } from './Reports/OnTimeShipping'
import { ProductPerformanceSalesoverDuration } from './Reports/ProductPbysalesoverDuration'
import { ProductPerformanceShipperByShipper } from './Reports/ProductPShippedByShippers'
import { PerformaceByTotalOrders } from './Reports/PerformaceBytotalOrderReport'
import { ErrorStatusReport } from './Reports/ErrorReport'
import { ShippingCostReport } from './Reports/ShippingCostReport'
import { ReportType } from 'types/core'

interface IOpenReportsPageprops {
    type: string
}

export const OpenReportsPage: FC<IOpenReportsPageprops> = ({ type }) => {
    switch (type) {
        case ReportType.onTimeShipping:
            return <OnTimeShippingReport />
        case ReportType.performanceReceivedBySalesChannel:
            return <PerformaceByTotalOrders />
        case ReportType.performanceSalesOverDuration:
            return <ProductPerformanceSalesoverDuration />
        case ReportType.performanceShippedByShippers:
            return <ProductPerformanceShipperByShipper />
        case ReportType.errorStatusReport:
            return <ErrorStatusReport />
        case ReportType.shippingFeeReport:
            return <ShippingCostReport/>
        default:
            return <Text>In Progress</Text>
    }
}

export const getCurrentDate = (): string => {
    const newDate = new Date()
    const month = newDate.getMonth() + 1
    const date = newDate.getDate()
    const year = newDate.getFullYear()
    const hour =
        newDate.getHours() < 10 ? `0${newDate.getHours()}` : newDate.getHours()
    const mins =
        newDate.getMinutes() < 10
            ? `0${newDate.getMinutes()}`
            : newDate.getMinutes()
    return `${
        month < 10 ? `0${month}` : `${month}`
    }/${date}/${year}_${hour}:${mins}`
}

export const dateFormatter = (myDate: Date | string) => {
    if (myDate !== null) {
        const date = new Date(myDate)
        return `${
            date.toLocaleDateString() +
            ' ' +
            (date.getUTCHours() < 10
                ? '0' + date.getUTCHours()
                : date.getUTCHours()) +
            ':' +
            (date.getUTCMinutes() < 10
                ? '0' + date.getUTCMinutes()
                : date.getUTCMinutes())
        }`
    } else {
        return ''
    }
}

export const dateOnlyFormatter = (myDate: Date | string) => {
    if (myDate !== null) {
        const date = new Date(myDate)
        return `${date.toLocaleDateString()}`
    } else {
        return ''
    }
}
