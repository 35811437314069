import { makeAutoObservable } from 'mobx'
import { UUID } from 'types/core'
import { notificationStore } from 'stores'
import { DiagramStore } from './DiagramStore'
import { WorkflowStore } from './WorkflowStore'

export class PreviewStore {
    public workflowStore: WorkflowStore = new WorkflowStore()
    public diagramStore: DiagramStore = new DiagramStore('readonly')

    constructor() {
        makeAutoObservable(this)
    }

    public async init(id: UUID) {
        try {
            const workflow = await this.workflowStore.fetch(id)
            this.diagramStore.init(workflow)
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    public async clean() {
        this.diagramStore.clean()
        this.workflowStore.clean()
    }
}
