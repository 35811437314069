import React, { FC, useCallback, useEffect } from 'react'
import { isEqual } from 'lodash'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import { VirtualizedSelect } from 'components/baseComponents/Selects'
import {
    DependsOnMappings,
    Parameter,
    ParameterMetadata,
    UUID,
} from 'types/core'
import { selectParamStores } from 'stores'
import { dependsOnParameters } from 'components/diagrams/nodes/NodeLayout/NodeParameter/params/utils'
import { RequiredText } from 'components/diagrams/nodes/NodeLayout/NodeParameter/params/styles'
import { ExternalParamStore } from './externalStore'

interface ExternalProps {
    id: UUID
    stepId: UUID
    placeholder?: string
    readonly: boolean
    isDraft: boolean
    description: string
    dependsOnMappings: DependsOnMappings[]
    stepParameters: Parameter[]
    nodeParameters: ParameterMetadata[]
    ruleName: string
}

export const External: FC<ExternalProps> = observer(
    ({
        id,
        stepId,
        placeholder,
        readonly,
        isDraft,
        description,
        dependsOnMappings,
        stepParameters,
        nodeParameters,
        ruleName,
    }) => {
        const store = selectParamStores.stores[stepId]?.external?.[id]

        const { dependencies, isDisabled, requiredMappings } = dependsOnParameters(
            dependsOnMappings,
            nodeParameters,
            stepParameters,
        )

        const getOptions = useCallback(
            (page, size, search) => {
                const getAvailableValues = async (store?: ExternalParamStore) => {
                    if (store && !isDisabled)
                        return store.getAvailableValues(page, size, search)

                    return {
                        data: [],
                        count: 0,
                        totalPages: 0,
                    }
                }
                return getAvailableValues(store)
            },
            // Need for improvement. Warnings console
            /* eslint-disable */
            [store?.dependencies]
        )

        useEffect(() => {
            if (store && !isEqual(store.dependencies, dependencies)) {
                store.setDependencies(dependencies)
            }
        }, [dependencies, store])

        if (!store) return null

        return (
            <>
                <VirtualizedSelect
                    maxWidth="310px"
                    isDisabled={isDisabled || !isDraft}
                    readonly={readonly}
                    placeholder={placeholder}
                    value={toJS(store.value)}
                    onClear={() => store.clearValue()}
                    onChange={option => {
                        store.selectValue(option)
                    }}
                    getOptions={getOptions}
                    ruleName={ruleName}
                />
                {!!requiredMappings.length && (
                    <RequiredText>
                        Parameter "{description}" is disabled, please fill
                        required parameters: "{requiredMappings.join(', ')}"
                    </RequiredText>
                )}
            </>
        )
    }
)
