import React from 'react'
import { observer } from 'mobx-react-lite'
import { Table, Thead, Tr, Th, Tbody, Td, Text, Center } from '@chakra-ui/react'
import { shippingCostStore } from 'stores'

export const ShipingCostTable = observer(() => {
    if (shippingCostStore.shippingCosts.length === 0) {
        return (
            <Center>
                <Text>No data to display</Text>
            </Center>
        )
    }

    return (
        <Table>
            <Thead>
                <Tr>
                    <Th>
                        <Text>Created Date</Text>
                    </Th>
                    <Th>
                        <Text>ECC Order Number</Text>
                    </Th>
                    <Th>
                        <Text>Shipping Method</Text>
                    </Th>
                    <Th>
                        <Text>Qty</Text>
                    </Th>
                    <Th>
                        <Text>Total Cost</Text>
                    </Th>
                </Tr>
            </Thead>
            <Tbody>
                {shippingCostStore.shippingCosts.map(shippingCost => (
                    <Tr key={shippingCost.id}>
                        <Td>
                            <Text>{shippingCost.placedDate}</Text>
                        </Td>
                        <Td>
                            <Text>{shippingCost.externalId}</Text>
                        </Td>
                        <Td>
                            <Text>{shippingCost.shipmentMethod}</Text>
                        </Td>
                        <Td>
                            <Text>{shippingCost.itemsQuantity}</Text>
                        </Td>
                        <Td>
                            <Text>
                                {'$'}
                                {shippingCost.cost}
                            </Text>
                        </Td>
                    </Tr>
                ))}
            </Tbody>
        </Table>
    )
})
