import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const CategoryMasterDataIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 10 11"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g filter="url(#filter0_d)">
                <path
                    d="M9 1.28572V2.08928C9 2.79744 7.20834 3.375 5 3.375C2.79166 3.375 1 2.79744 1 2.08928V1.28572C1 0.577564 2.79166 0 5 0C7.20834 0 9 0.577564 9 1.28572ZM9 3.09375V4.90178C9 5.60994 7.20834 6.1875 5 6.1875C2.79166 6.1875 1 5.60994 1 4.90178V3.09375C1.85938 3.67634 3.43229 3.94755 5 3.94755C6.56771 3.94755 8.14061 3.67634 9 3.09375ZM9 5.90625V7.71428C9 8.42244 7.20834 9 5 9C2.79166 9 1 8.42244 1 7.71428V5.90625C1.85938 6.48884 3.43229 6.76005 5 6.76005C6.56771 6.76005 8.14061 6.48884 9 5.90625Z"
                    fill="url(#paint0_linear)"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d"
                    x="0"
                    y="0"
                    width="10"
                    height="11"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        result="shape"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear"
                    x1="5"
                    y1="0"
                    x2="5"
                    y2="9"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#EFEFEF" />
                </linearGradient>
            </defs>
        </Icon>
    )
}
