export const typographyVariants = {
    logo: {
        fontSize: "18px",
        fontWeight: "400",
        lineHeight: "21px",
        letterSpacing: "0.2em",
        textTransform: "uppercase",
    },
    h1: {
        fontWeight: 'bold',
        fontSize: '32px',
        lineHeight: '38px',
    },
    h2: {
        fontWeight: 'bold',
        fontSize: '24px',
        lineHeight: '28px',
    },
    h3: {
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '28px',
    },
    h3Small: {
        fontWeight: '700',
        fontSize: '18px',
        lineHeight: '24px',
    },
    h4Small: {
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '20px',
    },
    h4Normal: {
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
    },
    h4LineHeight: {
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
    },
    h4Bold: {
        fontWeight: '700',
        fontSize: '16px',
        lineHeight: '24px',
    },
    h4Custom: {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '19px',
    },
    h4: {
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '20px',
    },
    h5: {
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '16px',
    },
    h5Light: {
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '16px',
    },
    h6: {
        fontWeight: 'medium',
        fontSize: '12px',
        lineHeight: '16px',
    },
    h6Light: {
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '16px',
    },
    supertitle: {
        fontSize: '12px',
        lineHeight: '14px',
        textTransform: 'uppercase',
    },
    quotewhite: {
        fontStyle: 'italic',
        fontWeight: 'medium',
        fontSize: '14px',
        lineHeight: '17px',
    },
    paragraphsmall: {
        fontSize: '12px',
        lineHeight: '14px',
    },
    paragraphSmallUnderline: {
        fontWeight: '500',
        fontSize: '12px',
        lineHeight: '14px',
        textDecorationLine: 'underline',
    },
    paragraphSmall400: {
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '14px',
    },
    paragraphSmall500: {
        fontWeight: '500',
        fontSize: '12px',
        lineHeight: '14px',
    },
    paragraph: {
        fontSize: '14px',
        lineHeight: '17px',
    },
    paragraphBold: {
        fontSize: '14px',
        lineHeight: '24px',
    },
    smalllink: {
        fontWeight: 'medium',
        fontSize: '10px',
        lineHeight: '12px',
    },
    smalllinklight: {
        fontSize: '10px',
        lineHeight: '12px',
    },
    supertextsemibold: {
        fontWeight: 'medium',
        fontSize: '12px',
        lineHeight: '14px',
        textTransform: 'capitalize',
    },
    xstext: {
        fontSize: '8px',
        lineHeight: '9px',
    },
    supertitlebold: {
        fontWeight: 'bold',
        fontSize: '12px',
        lineHeight: '14px',
        textTransform: 'uppercase',
    },
    linkmedium: {
        fontWeight: 'medium',
        fontSize: '14px',
        lineHeight: '17px',
    },
    linksmall: {
        fontWeight: 'light',
        fontSize: '14px',
        lineHeight: '17px',
    },
    linkBold: {
        fontWeight: '700',
        fontSize: '14px',
        lineHeight: '17px',
    },
    tableHeader: {
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '18px',
    },
}
