import { CellData, RangePickerResult } from 'components/DateRangePicker/types'
import { makeAutoObservable } from 'mobx'
import { dayEquals, getMonthName, isToday } from 'utils/datetime'

interface MonthData {
    name: string
    year: number
    month: number
    days: number
    startWeekDay: number
    weeks: number
    start: Date
    end: Date
}

export class DateRangeStore {
    public selectedStartDate: Date | null = null
    public selectedEndDate: Date | null = null
    public isNoEnd: boolean = false

    public isSelectStartDate: boolean = false
    public isSelectEndDate: boolean = false

    public leftMonth: CellData[] = []
    public rightMonth: CellData[] = []
    public leftMonthData: MonthData
    public rightMonthData: MonthData

    constructor() {
        const now = new Date()
        const nowYear = now.getFullYear()
        const nowMonth = now.getMonth()

        const startLeft = new Date(nowYear, nowMonth, 1)
        const endLeft = new Date(nowYear, nowMonth + 1, 0)
        const startRight = new Date(nowYear, nowMonth + 1, 1)
        const endRight = new Date(nowYear, nowMonth + 2, 0)

        this.leftMonthData = this.getMonthData(startLeft, endLeft)
        this.rightMonthData = this.getMonthData(startRight, endRight)

        this.init()

        makeAutoObservable(this)
    }

    private getMonthData(start: Date, end: Date): MonthData {
        return {
            start,
            end,
            days: end.getDate(),
            year: start.getFullYear(),
            month: start.getMonth(),
            startWeekDay: start.getDay(),
            weeks: Math.ceil((start.getDay() + end.getDate()) / 7),
            name: getMonthName(start),
        }
    }

    private init() {
        this.leftMonth = []
        this.rightMonth = []
        this.initLeft()
        this.initRight()
    }

    private initLeft() {
        const offset = this.leftMonthData.startWeekDay

        if (this.includeInRange(this.leftMonthData.start)) {
            for (let i = 1; i < offset; i++) {
                this.leftMonth.push({
                    type: 'offset',
                })
            }

            if (offset >= 1) {
                this.leftMonth.push({
                    type: 'gradient',
                    direction: 'left',
                })
            }
        } else {
            const lastDayPrevMonth = new Date(
                this.leftMonthData.year,
                this.leftMonthData.month,
                0
            )

            for (let i = 0; i < offset; i++) {
                this.leftMonth.push({
                    type: 'disabled',
                    day: lastDayPrevMonth.getDate() - offset + i + 1,
                })
            }
        }

        for (let i = 0; i < this.leftMonthData.days; i++) {
            const day = new Date(
                this.leftMonthData.year,
                this.leftMonthData.month,
                i + 1
            )

            this.leftMonth.push(this.getCellData(day))
        }

        if (this.includeInRange(this.leftMonthData.end)) {
            const cells = offset + this.leftMonthData.days

            if (cells % 7 !== 0) {
                this.leftMonth.push({
                    type: 'gradient',
                    direction: 'right',
                })
            }
        }
    }

    private initRight() {
        const offset = this.rightMonthData.startWeekDay

        if (this.includeInRange(this.rightMonthData.start)) {
            for (let i = 1; i < offset; i++) {
                this.rightMonth.push({
                    type: 'offset',
                })
            }

            if (offset >= 1) {
                this.rightMonth.push({
                    type: 'gradient',
                    direction: 'left',
                })
            }
        } else {
            for (let i = 0; i < offset; i++) {
                this.rightMonth.push({
                    type: 'offset',
                })
            }
        }

        for (let i = 0; i < this.rightMonthData.days; i++) {
            const day = new Date(
                this.rightMonthData.year,
                this.rightMonthData.month,
                i + 1
            )

            this.rightMonth.push(this.getCellData(day))
        }

        const weeks = Math.max(
            this.leftMonthData.weeks,
            this.rightMonthData.weeks
        )

        const rest = weeks * 7 - (offset + this.rightMonthData.days)

        if (rest > 0) {
            if (this.includeInRange(this.rightMonthData.end)) {
                this.rightMonth.push({
                    type: 'gradient',
                    direction: 'right',
                })
            } else {
                for (let i = 0; i < rest; i++) {
                    this.rightMonth.push({
                        type: 'disabled',
                        day: i + 1,
                    })
                }
            }
        }
    }

    private getCellData(date: Date): CellData {
        if (this.isStartEndRange(date)) {
            return {
                type: 'selected',
                direction: 'none',
                date,
            }
        } else if (this.isStartRange(date)) {
            if (this.isNoEnd) {
                return {
                    type: 'selected',
                    direction: 'left',
                    date,
                }
            } else {
                return {
                    type: 'selected',
                    direction: this.selectedEndDate ? 'left' : 'none',
                    date,
                }
            }
        } else if (this.isEndRange(date)) {
            return {
                type: 'selected',
                direction: this.selectedStartDate ? 'right' : 'none',
                date,
            }
        } else if (this.includeInRange(date)) {
            return {
                type: 'ranged',
                date,
            }
        } else if (isToday(date)) {
            return {
                type: 'today',
                date,
            }
        } else {
            return {
                type: 'default',
                date,
            }
        }
    }

    private isStartEndRange(date: Date): boolean {
        return (
            !!this.selectedStartDate &&
            !!this.selectedEndDate &&
            dayEquals(date, this.selectedStartDate) &&
            dayEquals(this.selectedStartDate, this.selectedEndDate)
        )
    }

    private isStartRange(date: Date): boolean {
        return (
            !!this.selectedStartDate && dayEquals(date, this.selectedStartDate)
        )
    }

    private isEndRange(date: Date): boolean {
        return (
            !this.isNoEnd &&
            !!this.selectedEndDate &&
            dayEquals(date, this.selectedEndDate)
        )
    }

    private includeInRange(date: Date): boolean {
        if (this.isNoEnd) {
            if (this.selectedStartDate) {
                return date.getTime() > this.selectedStartDate.getTime()
            }
        } else {
            if (this.selectedStartDate && this.selectedEndDate) {
                const time = date.getTime()
                const start = this.selectedStartDate.getTime()
                const end = this.selectedEndDate.getTime()

                return start < time && time < end
            }
        }

        return false
    }

    public nextMonth() {
        this.leftMonthData = this.rightMonthData

        const startMonth = new Date(
            this.leftMonthData.year,
            this.leftMonthData.month + 1,
            1
        )
        const endMonth = new Date(
            this.leftMonthData.year,
            this.leftMonthData.month + 2,
            0
        )

        this.rightMonthData = this.getMonthData(startMonth, endMonth)

        this.init()
    }

    public currentMonth() {
        const today = new Date()
        const selectedStartDate = new Date(this.selectedStartDate || today)
        const startYear = selectedStartDate.getFullYear()
        const startMonth = selectedStartDate.getMonth()

        const startOfLeftMonth = new Date(startYear, startMonth, 1)
        const endOfLeftMonth = new Date(startYear, startMonth + 1, 0)

        const startOfRightMonth = new Date(startYear, startMonth + 1, 1)
        const endOfRightMonth = new Date(startYear, startMonth + 2, 0)

        this.leftMonthData = this.getMonthData(startOfLeftMonth, endOfLeftMonth)
        this.rightMonthData = this.getMonthData(
            startOfRightMonth,
            endOfRightMonth
        )
        this.init()
    }

    public prevMonth() {
        this.rightMonthData = this.leftMonthData

        const startMonth = new Date(
            this.rightMonthData.year,
            this.rightMonthData.month - 1,
            1
        )
        const endMonth = new Date(
            this.rightMonthData.year,
            this.rightMonthData.month,
            0
        )

        this.leftMonthData = this.getMonthData(startMonth, endMonth)

        this.init()
    }

    public selectDate(date: Date) {
        if (this.isSelectStartDate) {
            if (this.selectedEndDate) {
                if (date.getTime() > this.selectedEndDate.getTime()) {
                    this.selectedEndDate = null
                }
            }
            this.selectedStartDate = date
            this.isSelectStartDate = false
            this.isSelectEndDate = true
        } else if (this.isSelectEndDate) {
            if (this.selectedStartDate) {
                if (date.getTime() < this.selectedStartDate.getTime()) {
                    this.selectedStartDate = null
                }
            }
            this.selectedEndDate = date
            this.isSelectEndDate = false
            this.isSelectStartDate = true
        } else {
            return
        }

        this.init()
    }

    public toggleIsNoEndDate() {
        this.isNoEnd = !this.isNoEnd
        this.init()
    }

    public selectingStartDate() {
        this.isSelectStartDate = true
        this.isSelectEndDate = false
    }

    public selectingEndDate() {
        this.isSelectStartDate = false
        this.isSelectEndDate = true
    }

    public unselecting() {
        this.isSelectStartDate = false
        this.isSelectEndDate = false
    }

    public clearAll() {
        this.selectedStartDate = null
        this.selectedEndDate = null
        this.selectingStartDate()
        this.init()
    }

    public clearStart() {
        this.selectedStartDate = null
        this.selectingStartDate()
        this.init()
    }

    public clearEnd() {
        this.selectedEndDate = null
        this.selectingEndDate()
        this.init()
    }

    public updateLeftYear(paramyear: number) {
        this.leftMonthData.year = paramyear
    }

    public updateRightYear(paramyear: number) {
        this.rightMonthData.year = paramyear
    }

    public setIsNoEndDate(value: boolean) {
        this.isNoEnd = !value
    }

    public getRangeResult(): RangePickerResult {
        return {
            start: this.selectedStartDate,
            end: this.selectedEndDate,
            noEndDate: this.isNoEnd,
        }
    }
}

export const dateRangeStore = new DateRangeStore()
