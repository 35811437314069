import React, { FC } from 'react'
import { Box } from '@chakra-ui/layout'
import { ITenantsAccessHierarchy } from 'types/core'
import SwitchControlTreeChild from './SwitchControlTreeChild'

interface ISwitchControlTree {
    item?: ITenantsAccessHierarchy
    tree: ITenantsAccessHierarchy[]
    onSelect: (e: React.MouseEvent, tenantId: string) => void
}

const SwitchControlTree: FC<ISwitchControlTree> = ({ tree, onSelect }) => {
    return (
        <Box>
            {tree.map(item => (
                <Box paddingLeft="12px" key={item.tenantId}>
                    <SwitchControlTreeChild item={item} onSelect={onSelect} />
                </Box>
            ))}
        </Box>
    )
}

export default SwitchControlTree
