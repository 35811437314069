import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { customerShipperStore, tenantsStore } from 'stores'
import { CustomerShipper } from 'types/api'
import { Divider, useDisclosure } from '@chakra-ui/react'
import { CustomAccordion } from 'components/baseComponents/Accordion'
import { ShipperAccordion } from './ShipperComponents/ShipperAccordion'
import { CustomerShipperPanel } from './ShipperComponents/CustomerShipperPanel'
import CustomerShipperInputs from './ShipperComponents/CustomerShipperInputs'
import CustomModal from 'components/TableComponents/TableModal'
import { accordionHeaderOptions } from './mock'
import {
    CustomerShipperHeader,
    AccordionStyled,
    CustomerShipperContainer,
    AccordionHeaderBox,
    AccordionHeaderText,
    ButtonStyled,
} from './styles'

export interface IDataCustomerShipment {
    name: string
    code: string
    description?: string
    ecommerceCode?: string
    orderSourceCode?: string
    connectionSettings?: string
    type?: string
}

export const CustomerShipperPage = observer(() => {
    const [opened, setOpened] = useState<number>(-1)
    const currentPosition = window.scrollY;
    const { tenantId } = tenantsStore
    const data = toJS(customerShipperStore.shipmentProviderList)
    const isSettingsModal = toJS(customerShipperStore.isSettingsModal)
    const isCreateModal = toJS(customerShipperStore.isCreateModal)
    const providerItem = toJS(customerShipperStore.shipmentProviderItem)
    const methodItem = toJS(customerShipperStore.shipmentMethodsItem)

    const {
        isOpen: isAddShipperOpen,
        onOpen: onAddShipperOpen,
        onClose: onAddShipperClose,
    } = useDisclosure()
    const {
        isOpen: isAddShipmentMethodOpen,
        onOpen: onAddShipmentMethodOpen,
        onClose: onAddShipmentMethodClose,
    } = useDisclosure()

    const {
        handleSubmit,
        register,
        setValue,
        reset,
        formState: { errors, isSubmitting },
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
    })

    const openCreateProviderModal = () => {
        customerShipperStore.changeTypeReq(true)
        customerShipperStore.changeTypeModal(false)
        onAddShipperOpen()
    }

    const closeProviderModal = () => {
        customerShipperStore.changeTypeReq(false)
        customerShipperStore.changeTypeModal(false)
        onAddShipperClose()
    }

    const closeMethodModal = () => {
        customerShipperStore.changeTypeReq(false)
        customerShipperStore.changeTypeModal(false)
        onAddShipmentMethodClose()
    }

    const onSubmitShipper = (data: IDataCustomerShipment) => {
        if (isSettingsModal) {
            customerShipperStore.changeShipmentProviderSettings(
                tenantId,
                data.code,
                {
                    ecommerceCode: data.ecommerceCode || '',
                    orderSourceCode: data.orderSourceCode || '',
                    connectionSettings: data.connectionSettings || '',
                },
                closeProviderModal
            )
        }
        else {
            if (isCreateModal) {
                customerShipperStore.createShipmentProvider(
                    tenantId,
                    {
                        code: data.code,
                        name: data.name
                    },
                    closeProviderModal
                    )
            } else {
                customerShipperStore.changeShipmentProvider(
                    tenantId,
                    providerItem.code,
                    {
                        code: data.code,
                        name: data.name
                    },
                    closeProviderModal
                )
            }
        }
    }

    const onSubmitMethod = (data: IDataCustomerShipment) => {
        if (isSettingsModal) {
            customerShipperStore.changeShipmentMethodSettings(
                tenantId,
                providerItem.code,
                data.code,
                {
                    ecommerceCode: data.ecommerceCode || '',
                    orderSourceCode: data.orderSourceCode || '',
                },
                closeMethodModal
            )
        }
        else {
            if (isCreateModal) {
                customerShipperStore.createShipmentMethod(
                    tenantId,
                    providerItem.code,
                    {
                        code: data.code,
                        name: data.name,
                        description: data.description,
                    },
                    closeMethodModal
                )
            } else {
                customerShipperStore.changeShipmentMethod(
                    tenantId,
                    providerItem.code,
                    methodItem.code,
                    {
                        code: data.code,
                        name: data.name,
                        description: data.description,
                    },
                    closeMethodModal
                )
            }
        }
    }

    const titleModal = (isProvider: boolean) => {
        if (isProvider && isSettingsModal && !isCreateModal) return 'Setup Shipping Provider'
        if (isProvider && !isSettingsModal && isCreateModal) return 'Create Custom Shipping Provider '
        if (isProvider && !isSettingsModal && !isCreateModal) return 'Edit Custom Shipping Provider'
        if (!isProvider && isSettingsModal && !isCreateModal) return 'Setup Shipment Method'
        if (!isProvider && !isSettingsModal && isCreateModal) return 'Create Shipment Method'
        if (!isProvider && !isSettingsModal && !isCreateModal) return 'Edit Shipment Method'
        return ''
    }

    useEffect(() => {
        if (tenantId) customerShipperStore.fetchShipmentProvider(tenantId)
        return () => customerShipperStore.cleanAll()
    }, [tenantId])

    useEffect(() => {
        if (data[opened] && data[opened]?.code !== providerItem.code) {
            customerShipperStore.changeShipmentProviderItem(data[opened])
        }
        window.scroll(0, currentPosition)
    }, [data, opened, providerItem.code])

    return (
        <CustomerShipperContainer>
            <CustomerShipperHeader>
                Shipping Providers
            </CustomerShipperHeader>
            <Divider />
            <AccordionHeaderBox>
                {accordionHeaderOptions.map((option) => (
                    <AccordionHeaderText key={option}>
                        {option}
                    </AccordionHeaderText>
                ))}
            </AccordionHeaderBox>
            <Divider />
            <AccordionStyled
                allowToggle
                index={opened}
                onChange={(expandedIndex: number) => {
                    if (expandedIndex > -1) customerShipperStore.changeShipmentProviderItem(data[expandedIndex])
                    setOpened(expandedIndex)
                }}
            >
                {data?.map((shipper: CustomerShipper, index) => (
                        <CustomAccordion
                            key={shipper.code}
                            AccordionButtonContent={
                                <ShipperAccordion
                                    isOpen={opened === index}
                                    shipmentProvider={shipper}
                                    isEnabled={shipper.isEnabled}
                                    tenantId={tenantId}
                                    onAddShipperOpen={onAddShipperOpen}
                                    isOpenMethods={data[opened]?.code}
                                    setOpened={setOpened}
                                />
                            }
                            AccordionPanelContent={
                                <CustomerShipperPanel
                                    onAddShipmentMethodOpen={onAddShipmentMethodOpen}
                                    onAddShipmentCreateMethodOpen={() => customerShipperStore.changeTypeReq(true)}
                                    shipmentMethods={shipper.methods}
                                    tenantId={tenantId}
                                    shipper={shipper}
                                />
                            }
                        />

                ))}
            </AccordionStyled>
            <ButtonStyled onClick={openCreateProviderModal}>
                Create Custom Shipper
            </ButtonStyled>

            <CustomModal
                modalTitle={titleModal(true)}
                isOpen={isAddShipperOpen}
                onClose={closeProviderModal}
                onSubmit={onSubmitShipper}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
            >
                <CustomerShipperInputs
                    setFormValue={setValue}
                    errors={errors}
                    register={register}
                    reset={reset}
                    isShipmentMethod={false}
                    isSettingsModal={isSettingsModal}
                    data={providerItem}
                    isCreateModal={isCreateModal}
                />
            </CustomModal>
            <CustomModal
                modalTitle={titleModal(false)}
                isOpen={isAddShipmentMethodOpen}
                onClose={closeMethodModal}
                onSubmit={onSubmitMethod}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
            >
                <CustomerShipperInputs
                    setFormValue={setValue}
                    errors={errors}
                    register={register}
                    reset={reset}
                    isShipmentMethod={true}
                    isSettingsModal={isSettingsModal}
                    data={methodItem}
                    isCreateModal={isCreateModal}
                />
            </CustomModal>
        </CustomerShipperContainer>
    )
})
