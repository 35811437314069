import { makeAutoObservable } from 'mobx'
import { ReportAPI } from 'api/reports'
import { PagingFilters } from 'types/api'
import { IShippersData, IProductPerformanceItem } from 'types/core'

const defaultFilters: PagingFilters = {
    page: 1,
    size: 10,
}

export class PerformanceByTotalOrdersReportStore {
    public filters: PagingFilters = defaultFilters
    public pages: number = 0
    public preparedLabels: IShippersData[] = []
    public productPerformanceReports: IProductPerformanceItem[] = []
    public totalItems: number = 0
    public exportTotalItems: number = 0
    public productPerformanceReportsExport: IProductPerformanceItem[] = []

    constructor() {
        makeAutoObservable(this)
    }

    public async getPrerformenceSource() {
        try {
            const data = await ReportAPI.productPerformnceSource()
            this.preparedValuesandLabel(data?.sources)
        } catch (err) {
            this.preparedLabels = []
        }
    }

    public preparedValuesandLabel(data: IProductPerformanceItem[]) {
        this.preparedLabels = []

        !!data &&
            data?.forEach((item: IProductPerformanceItem) =>
                this.preparedLabels.push({
                    value: item.sourceName,
                    label: item.sourceName,
                })
            )
    }

    public async getProductPerformanceReport(
        fromDate: string,
        todate: string,
        shipername: string
    ) {
        try {
            const data = await ReportAPI.getProductPerformanceTotalReports(
                fromDate,
                todate,
                shipername,
                this.filters
            )
            this.initTotalItems(data?.totalItems)
            this.init(data?.items)
            this.initPages(data?.totalPages)
        } catch (err) {
            this.totalItems = 0
            this.productPerformanceReports = []
        }
    }

    public async getProductPerformanceExportData(
        fromDate: string,
        todate: string,
        shipername: string
    ) {
        try {
            const data =
                await ReportAPI.getProductPerformanceTotalReportsExport(
                    fromDate,
                    todate,
                    shipername
                )
            this.initExportTotalItems(data?.totalItems)
            this.initExportData(data?.items)
        } catch (err) {
            this.initExportData([])
        }
    }

    public initExportTotalItems(items: number) {
        this.exportTotalItems = items
    }

    private initExportData(data: IProductPerformanceItem[]) {
        this.productPerformanceReportsExport = data
    }

    private initTotalItems(items: number) {
        this.totalItems = items
    }

    private init(data: IProductPerformanceItem[]) {
        this.productPerformanceReports = data || []
    }

    private initPages(pages: number) {
        this.pages = pages
    }

    private initFilters(filter: PagingFilters) {
        this.filters = filter
    }

    public setFilters(filters: Partial<PagingFilters>) {
        if (!filters.page) {
            this.filters = { ...this.filters, ...filters, page: 1 }
        } else {
            this.filters = { ...this.filters, ...filters }
        }
    }

    public reloadPages() {
        this.initFilters(defaultFilters)
    }

    public clean() {
        this.initFilters(defaultFilters)
        this.initPages(0)
        this.init([])
        this.initExportData([])
        this.initTotalItems(0)
        this.initExportTotalItems(0)
    }
}
