import { Th, Td } from '@chakra-ui/react'
import { Flex, Text } from '@chakra-ui/layout'
import { chakra } from 'utils/chakra'

export const FlexBox = chakra(Flex, {
    direction: 'column',
    gap: '10px',
})

export const SourceName = chakra(Text, {
    margin: '10px 0 0 10px',
    textTransform: 'uppercase',
    fontSize: '16px',
})

export const TableHead = chakra(Th, {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '17px',
    textTransform: 'capitalize',
    backgroundColor: 'reports.200',
    color: 'white',
    textAlign:'left'
})

export const TableData = chakra(Td, {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '18px',
    textAlign:'left'
})

export const TextSize = chakra(Text, {
    fontSize: '12px',
})
