import React, { FC } from 'react'
import { Button } from '@chakra-ui/button'
import { Box, Text } from '@chakra-ui/layout'
import { observer } from 'mobx-react-lite'
import { DiagramPreview } from 'components/diagrams/DiagramPreview'
import { submitForApprovalStore } from 'stores'
import { KeyDetailsBox, LeftContent, RightContent } from './styles'

interface IKeyDetails {
    onApprove: () => Promise<void>
    loading: boolean
}

export const KeyDetails: FC<IKeyDetails> = observer(({ onApprove, loading }) => {
    return (
        <Box width="999px">
            <Text variant="h3">Key Details</Text>
            <KeyDetailsBox>
                <LeftContent>
                    <Text variant="supertitle">Name</Text>
                    <Text variant="h5" marginTop="7px">
                        {submitForApprovalStore.workflowStore.workflow?.name}
                    </Text>
                    <Text variant="supertitle" marginTop="33px">
                        Short Description
                    </Text>
                    <Text marginTop="8px">
                        {submitForApprovalStore.workflowStore.workflow?.description}
                    </Text>
                    <Text variant="supertitle" marginTop="49px">
                        Logic Canvas Preview
                    </Text>
                    <DiagramPreview />
                </LeftContent>
                <RightContent>
                    <Button
                        variant="blueBottom"
                        isLoading={loading}
                        onClick={onApprove}
                    >
                        Submit For Approval
                    </Button>
                </RightContent>
            </KeyDetailsBox>
        </Box>
    )
})
