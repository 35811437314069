import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Flex } from '@chakra-ui/react'
import { ReportsNavBar } from './ReportsNavBar'
import { OpenReportsPage } from './utils'
import { onTimeShippingReportStore } from 'stores'

export const ReportsPage = observer(() => {
    const [reports, setReports] = useState(false)
    const [reportsPage, setReportsPage] = useState('')
    const type = onTimeShippingReportStore.defaultReportType

    function reportSearchItem(filter: string) {
        return onTimeShippingReportStore.searchReportsList(filter)
    }

    const getReportName = (type: string) => {
        setReports(true)
        setReportsPage(type)
    }

    return (
        <Flex gap="10px">
            <ReportsNavBar
                onChange={reportSearchItem}
                setItem={getReportName}
            />
            {reports && <OpenReportsPage type={reportsPage} />}
            {!reports && <OpenReportsPage type={type} />}
        </Flex>
    )
})
