import { TextSize } from './styles'

interface IDateProps {
    value: string
}

interface IDecimalTrim {
    value: number
}

const dateFormater = (props: IDateProps) => {
    if (props?.value) {
        const date = new Date(props.value)
        return <TextSize>{date.toLocaleDateString()}</TextSize>
    } else {
        return <></>
    }
}

const dateFormat = (props: IDateProps) => {
    if (props?.value) {
        const date = new Date(props.value)
        return (
            <TextSize>
                {date.toLocaleDateString() +
                    ' ' +
                    (date.getUTCHours() < 10
                        ? '0' + date.getUTCHours()
                        : date.getUTCHours()) +
                    ':' +
                    (date.getUTCMinutes() < 10
                        ? '0' + date.getUTCMinutes()
                        : date.getUTCMinutes())}
            </TextSize>
        )
    } else {
        return <></>
    }
}

const decimalTrim = (props: IDecimalTrim) => {
    if (props?.value) {
        return <TextSize>{props.value.toFixed(2)}</TextSize>
    } else {
        return <></>
    }
}

export const productPerformanceBySalesReportColoumn = [
    {
        Header: 'SKU/EIN',
        accessor: 'sku',
        style: { width: '20%' },
    },
    {
        Header: 'Item Description',
        accessor: 'itemDescription',
        style: { width: '50%' },
    },
    {
        Header: 'Qty',
        accessor: 'quantity',
        style: { width: '15%' },
    },
    {
        Header: '% of Orders(Avg)',
        accessor: 'percentageOfOrders',
        style: { width: '15%' },
    },
]

export const productPerformanceByTotalOrdersReport = [
    {
        Header: 'Shipper',
        accessor: 'shipper',
        style: { width: '25%' },
    },
    {
        Header: 'Method',
        accessor: 'method',
        style: { width: '25%' },
    },
    {
        Header: 'Qty',
        accessor: 'quantity',
        style: { width: '25%' },
    },
    {
        Header: '% of Orders(Avg)',
        accessor: 'orderPercentage',
        Cell: decimalTrim,
        style: { width: '25%' },
    },
]

export const onTimeShippingcolumns = [
    {
        Header: 'Order Id (Source)',
        accessor: 'sourceId',
        width: 185,
        style: { width: '15%' },
    },
    {
        Header: 'Order Id (DOM)',
        accessor: 'orderId',
        width: 160,
        style: { width: '30%' },
    },
    {
        Header: 'Order Date',
        accessor: 'orderDate',
        Cell: dateFormater,
        style: { width: '15%' },
    },
    {
        Header: 'Order Status',
        accessor: 'orderStatusDescription',
        width: 140,
        style: { width: '10%' },
    },
    {
        Header: 'Delivery (Exp)',
        accessor: 'expectedDelivery',
        Cell: dateFormater,
        style: { width: '15%' },
    },
    {
        Header: 'Delivery (Act)',
        accessor: 'actualDelivery',
        Cell: dateFormater,
        style: { width: '15%' },
    },
]

export const performanceByTotalOdersColumns = [
    {
        Header: 'Order Date',
        accessor: 'orderDate',
        Cell: dateFormater,
        style: { width: '20%' },
    },
    {
        Header: 'Quantity Received',
        accessor: 'quantityReceived',
        style: { width: '20%' },
    },
    {
        Header: 'Quantity Cancelled',
        accessor: 'quantityCancelled',
        style: { width: '20%' },
    },

    {
        Header: 'Quantity Open',
        accessor: 'quantityOpen',
        style: { width: '20%' },
    },
    {
        Header: 'Quantity Completed',
        accessor: 'quantityCompleted',
        style: { width: '20%' },
    },
]

export const errorStatusReportColumns = [
    {
        Header: 'Created Date',
        accessor: 'created',
        Cell: dateFormat,
        style: {
            width: '14%',
        },
    },
    {
        Header: 'Source No.',
        accessor: 'orderSourceExternalOrderId',
        style: {
            width: '14%',
        },
    },
    {
        Header: 'Ext. Source',
        accessor: 'orderSource',
        style: {
            width: '14%',
        },
    },
    {
        Header: 'Delivery Date',
        accessor: 'expectedDeliveryDate',
        Cell: dateFormater,
        style: {
            width: '14%',
        },
    },
    {
        Header: 'Status',
        accessor: 'status',
        style: {
            width: '16%',
        },
    },
    {
        Header: 'Customer',
        accessor: 'customerCode',
        style: {
            width: '14%',
        },
    },
    {
        Header: 'Distribution Source',
        accessor: 'distributionSource',
        style: {
            width: '14%',
        },
    },
]

export const shippingReportColoumns = [
    {
        Header: 'Created Date',
        accessor: 'placedDate',
        Cell: dateFormater,
        style: { width: '20%' },
    },
    {
        Header: 'Order Number',
        accessor: 'number',
        style: { width: '20%' },
    },
    {
        Header: 'Shipping Method',
        accessor: 'shipmentMethod',
        style: { width: '20%' },
    },

    {
        Header: 'Qty',
        accessor: 'itemsQuantity',
        style: { width: '20%' },
    },
    {
        Header: 'Total Cost',
        accessor: 'cost',
        style: { width: '20%' },
    },
]