import { StatusType } from 'types/chart'
import { colors } from 'theme/colors'

export const setStatusBackground = (status: string) => {
    switch (status) {
        case StatusType.Info:
            return colors.denim
        case StatusType.Warning:
            return colors.yellow['300']
        case StatusType.Success:
            return colors.blue['200']
        case StatusType.Error:
            return colors.red['500']
        default:
            return colors.yellow['300']
    }
}
