import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const UserDeactivateIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 60 60"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g>
                <path
                    d="M47,33c-7.168,0-13,5.832-13,13s5.832,13,13,13s13-5.832,13-13S54.168,33,47,33z M47,57c-6.065,0-11-4.935-11-11s4.935-11,11-11s11,4.935,11,11S53.065,57,47,57z"
                    fill="currentColor"
                />
                <path
                    d="M51.95,41.051c-0.391-0.391-1.023-0.391-1.414,0L47,44.586l-3.536-3.535c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414L45.586,46l-3.536,3.535c-0.391,0.391-0.391,1.023,0,1.414c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293L47,47.414l3.536,3.535c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293c0.391-0.391,0.391-1.023,0-1.414L48.414,46l3.536-3.535C52.34,42.074,52.34,41.441,51.95,41.051z"
                    fill="currentColor"
                />
                <path
                    d="M35.986,34.424c0.405-0.809,0.764-1.65,1.066-2.522c1.267-0.602,2.085-1.864,2.085-3.289v-3.545c0-0.867-0.318-1.708-0.887-2.369v-4.667c0.052-0.52,0.236-3.448-1.883-5.864C34.524,10.065,31.541,9,27.5,9s-7.024,1.065-8.867,3.168c-2.119,2.416-1.935,5.346-1.883,5.864v4.667c-0.568,0.661-0.887,1.502-0.887,2.369v3.545c0,1.101,0.494,2.128,1.34,2.821c0.81,3.173,2.477,5.575,3.093,6.389v2.894c0,0.816-0.445,1.566-1.162,1.958l-7.907,4.313c-0.252,0.137-0.502,0.297-0.752,0.476C5.276,42.792,2,36.022,2,28.5C2,14.439,13.439,3,27.5,3S53,14.439,53,28.5c0,0.887-0.05,1.763-0.139,2.627c0.656,0.26,1.294,0.555,1.905,0.896C54.915,30.868,55,29.694,55,28.5C55,13.337,42.663,1,27.5,1S0,13.337,0,28.5c0,8.009,3.444,15.228,8.926,20.258l-0.026,0.023l0.892,0.752c0.058,0.049,0.121,0.089,0.179,0.137c0.474,0.393,0.965,0.766,1.465,1.127c0.162,0.117,0.324,0.235,0.489,0.348c0.534,0.368,1.082,0.717,1.642,1.048c0.122,0.072,0.245,0.142,0.368,0.212c0.613,0.349,1.239,0.678,1.88,0.98c0.047,0.022,0.095,0.042,0.142,0.064c2.089,0.971,4.319,1.684,6.651,2.105c0.061,0.011,0.122,0.022,0.184,0.033c0.724,0.125,1.456,0.225,2.197,0.292c0.09,0.008,0.18,0.013,0.271,0.021C25.998,55.961,26.744,56,27.5,56c0.749,0,1.488-0.039,2.222-0.098c0.093-0.008,0.186-0.013,0.279-0.021c0.735-0.067,1.461-0.164,2.178-0.287c0.062-0.011,0.125-0.022,0.187-0.034c0.534-0.096,1.061-0.21,1.583-0.336C32.099,52.614,31,49.436,31,46C31,41.446,32.922,37.341,35.986,34.424z"
                    fill="currentColor"
                />
            </g>
        </Icon>
    )
}
