import { Text, StyleSheet, View } from '@react-pdf/renderer'
import { dateOnlyFormatter } from '../utils'
import { errorStatusReportColumns } from '../ReportsTable/columnMoc'
import { Cell1 } from './Cellstyle/cell'
import { IErrorReportData, IReportStyles } from 'types/core'

export const errorStatusReportStyles: IReportStyles = StyleSheet.create({
    row: {
        fontSize: '8',
        display: 'flex',
        flexDirection: 'row',
    },
    cell1: {
        width: '14%',
    },
    cell2: {
        width: '16%',
    },
})

export const errorStatusReportHeader = (
    <View style={errorStatusReportStyles.row}>
        {!!errorStatusReportColumns.length &&
            errorStatusReportColumns.map(column => (
                <Text key={column.Header} style={column.style}>
                    {column.Header}
                </Text>
            ))}
    </View>
)

export const errorStatusReportRow = (data: IErrorReportData) => {
    const errorStatusRow = [
        data?.created,
        data?.orderSourceExternalOrderId,
        data?.orderSource,
        dateOnlyFormatter(data?.expectedDeliveryDate),
        data?.status,
        data?.customerCode,
        data?.distributionSource,
    ]
    return (
        <View style={errorStatusReportStyles.row}>
            {!!errorStatusRow &&
                !!errorStatusRow?.length &&
                errorStatusRow?.map(errorItem => {
                    switch (errorItem) {
                        case data?.status:
                            return (
                                <Cell1
                                    cell2={errorStatusReportStyles.cell2}
                                    data={errorItem}
                                />
                            )
                        default:
                            return (
                                <Cell1
                                    cell1={errorStatusReportStyles.cell1}
                                    data={errorItem}
                                />
                            )
                    }
                })}
        </View>
    )
}
