import React, { FC } from 'react'
import { InputProps } from '@chakra-ui/input'
import { FieldValues, UseFormRegister, Validate } from 'react-hook-form'
import { CustomInput } from './styles'

interface LoginInputProps extends InputProps{
    type: string
    errorMessage: string | undefined
    variant: string
    placeholder: string
    borderError: boolean
    validate: Validate<string>
    register: UseFormRegister<FieldValues>
}

export const LoginInput: FC<LoginInputProps> = ({
    type,
    variant,
    errorMessage,
    placeholder,
    borderError,
    validate,
    register,
    ...props
}) => {
    return (
        <CustomInput
            value=""
            variant={variant}
            placeholder={placeholder}
            type={type}
            errorMessage={errorMessage}
            border={borderError ? '2px solid' : undefined}
            borderColor={borderError ? 'red.500' : undefined}
            {...register(type, { validate })}
            {...props}
        />
    )
}
