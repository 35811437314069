import React from 'react'
import { Link as DomLink } from 'react-router-dom'
import { IconButton, Switch, useColorMode } from '@chakra-ui/react'
import { Link } from '@chakra-ui/layout'
import { ProgressBar } from '../ProgressBar'
import { TenantSwitchControl } from './TenantSwitchControl'
import { Input } from './Input'
import { routes } from 'navigation/routes'
import { BellIcon, UserIcon } from 'icons'
import {
    ContentBox,
    HeaderBox,
    IconsBox,
    LeftContent,
    RightContent,
    LogoText,
} from './styles'

export const Header = () => {
    const { toggleColorMode } = useColorMode()
    const themeMode = localStorage.getItem('chakra-ui-color-mode')
    const isAuthorized = localStorage.getItem('authToken')

    return (
        <>
            <HeaderBox>
                <ContentBox justifyContent={isAuthorized ? 'space-between' : 'center'}>
                    {!isAuthorized && <LogoText>Lucas</LogoText>}
                    {!!isAuthorized && (
                        <>
                            <LeftContent>
                                <Link to={routes.home} as={DomLink} color="white">
                                    <LogoText>Lucas</LogoText>
                                </Link>
                                <TenantSwitchControl />
                                <Switch
                                    variant="switchTheme"
                                    defaultChecked={!themeMode || themeMode === 'dark'}
                                    onChange={toggleColorMode}
                                />
                            </LeftContent>
                            <RightContent>
                                <Input />
                                <IconsBox>
                                    <IconButton
                                        aria-label="Bell Icon"
                                        variant="squircleGrayIcon"
                                        icon={
                                            <BellIcon
                                                boxSize="20px"
                                                color="color.100"
                                            />
                                        }
                                    />
                                    <IconButton
                                        aria-label="User Icon"
                                        variant="squircleGrayIcon"
                                        icon={
                                            <UserIcon
                                                boxSize="20px"
                                                color="color.100"
                                            />
                                        }
                                    />
                                </IconsBox>
                            </RightContent>
                        </>
                    )}
                </ContentBox>
            </HeaderBox>
            <ProgressBar />
        </>
    )
}
