import React, { FC } from 'react'
import { Handle } from 'react-flow-renderer'
import { HandleComponentProps } from 'react-flow-renderer/dist/components/Handle'
import { useColorMode } from '@chakra-ui/system'
import { Theme } from 'types/core'
import { colors } from 'theme/colors'

const handleStyles = {
    width: '11px',
    height: '11px',
    background: colors.blue['990'],
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.40)',
    border: '1px solid',
}

export const CustomHandle: FC<HandleComponentProps> = ({
    id,
    style = {},
    ...props
}) => {
    const { colorMode } = useColorMode()
    const borderColor = colorMode === Theme.Dark ? colors.blue['950'] : colors.blue['910']
    const styles = {
        ...handleStyles,
        borderColor,
        ...style,
    }

    if (id) {
        return (
            <Handle
                id={id}
                {...props}
                style={styles}
            />
        )
    } else {
        return (
            <Handle
                {...props}
                style={styles}
            />
        )
    }
}
