import React, { ChangeEvent, useEffect, useState } from 'react'
import { Input } from '@chakra-ui/react'
import { CellValue, Row } from 'react-table'
import { EditingRows } from '../Table'
import { ISelectOption } from '../../../types/core'

export interface CustomCellProps {
    column: any
    row: Row
    value: CellValue
    updateMyData: (
        rowIndex: number,
        columnId: string,
        value: string | ISelectOption
    ) => void
    editingRows: EditingRows
}

const EditableCell = ({
    column,
    updateMyData,
    editingRows,
    value: initialValue,
    row,
}: CustomCellProps) => {
    const [value, setValue] = useState(initialValue)

    const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setValue(e.target.value)
    }

    const onBlur = (): void => {
        updateMyData(row.index, column.id, value)
    }

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    if (!column.editable || !editingRows[row.id]) {
        if (column.Header === 'Location') {
            return '{Location}'
        }
        return initialValue
    }

    return <Input size="sm" value={value} onChange={onChange} onBlur={onBlur} />
}

export default EditableCell
