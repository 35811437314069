import React from 'react'
import { observer } from 'mobx-react-lite'
import { Box, Flex, Spacer, Text } from '@chakra-ui/react'
import { userStore } from 'stores'
import { TitleHeader } from '../styles'

export const ShipingCostHeader = observer(() => {
    return (
        <Box position="relative" marginBottom="15px">
            <Flex>
                <Text>{new Date().toLocaleDateString()}</Text>
                <Spacer />
                <Text>{userStore.name}</Text>
            </Flex>
            <TitleHeader>
                <Text>Shipping Costs</Text>
            </TitleHeader>
        </Box>
    )
})
