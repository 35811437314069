import React, { FC, useEffect, useState } from 'react'
import { Editable, EditableInput, EditablePreview } from '@chakra-ui/editable'
import { Box, Text } from '@chakra-ui/layout'
import { MiniMenuButton } from 'components/MiniMenuButton'
import { NAME_LENGTH, DESCRIPTION_LENGTH } from 'constants/rules'
import { useDebounce } from 'hooks'
import { RuleHeaderIcon } from 'icons'
import { EditableStyled } from '../styles'

interface RuleHeaderButtonProps {
    readonly?: boolean
    name: string
    description: string
    onChangeName?: (newName: string) => void
    onChangeDescription?: (newDescription: string) => void
}

export const RuleHeaderButton: FC<RuleHeaderButtonProps> = ({
    readonly = false,
    name,
    description,
    onChangeName,
    onChangeDescription,
}) => {
    const [nameValue, setNameValue] = useState(name)
    const [descriptionValue, setDescriptionValue] = useState(description)

    const debouncedName = useDebounce((newName: string) => {
        if (newName && !readonly && onChangeName) {
            onChangeName(newName)
        }
    }, 700)

    const debouncedDescription = useDebounce((newDescription: string) => {
        if (newDescription && !readonly && onChangeDescription) {
            onChangeDescription(newDescription)
        }
    }, 700)

    useEffect(() => {
        name !== nameValue && debouncedName(nameValue)
        // Need for improvement. Warnings console
        /* eslint-disable */
    }, [nameValue])

    useEffect(() => {
        description !== descriptionValue && debouncedDescription(descriptionValue)
        // Need for improvement. Warnings console
        /* eslint-disable */
    }, [descriptionValue])

    return (
        <MiniMenuButton
            title="Rule Header"
            icon={<RuleHeaderIcon boxSize="29px" />}
            iconColor="blue.800"
        >
            <Box padding="26px 15px 15px 15px">
                <Text variant="supertitle">
                    Nickname
                </Text>
                <EditableStyled
                    isDisabled={readonly}
                    value={nameValue}
                    onChange={setNameValue}
                >
                    <EditablePreview width="full" />
                    <EditableInput maxLength={NAME_LENGTH} />
                </EditableStyled>
                <Text variant="supertitle">
                    Short Descriptions
                </Text>
                <Editable
                    isDisabled={readonly}
                    value={descriptionValue}
                    color="color.100"
                    fontSize="12px"
                    lineHeight="14px"
                    onChange={setDescriptionValue}
                >
                    <EditablePreview width="full" />
                    <EditableInput maxLength={DESCRIPTION_LENGTH} />
                </Editable>
            </Box>
        </MiniMenuButton>
    )
}
