import React, { FC, RefObject, useEffect, useRef } from 'react'
import { useDisclosure } from '@chakra-ui/hooks'
import { observer } from 'mobx-react-lite'
import {
    Drawer,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Text,
} from '@chakra-ui/react'
import { SavedFilterItem } from './SavedFilterItem'
import { EditFiltersDrawer } from '../EditFiltersDrawer'
import { orderStore } from 'stores'
import { DrawerBodyStyled } from './styles'

interface ISavedFiltersDrawer {
    isDrawerOpen: boolean
    onDrawerClose: () => void
    btnDrawerRef: RefObject<HTMLHeadingElement>
}

export const SavedFiltersDrawer: FC<ISavedFiltersDrawer> = observer(({
    isDrawerOpen,
    onDrawerClose,
    btnDrawerRef,
}) => {
    const btnEditRef = useRef(null)
    const { isOpen, onOpen, onClose } = useDisclosure()

    const savedFilters = orderStore.savedFilters

    useEffect(() => {
        if (isDrawerOpen) orderStore.setSavedFilters()
    }, [isDrawerOpen])

    return (
        <Drawer
            size="sm"
            placement="right"
            isOpen={isDrawerOpen}
            onClose={onDrawerClose}
            finalFocusRef={btnDrawerRef}
        >
            <DrawerOverlay />
            <DrawerContent backgroundColor="blueWhite.1000">
                <DrawerCloseButton margin="20px 20px 0 0" />
                <DrawerHeader>
                    <Text marginTop="15px" variant="h2">Saved Filters</Text>
                </DrawerHeader>
                <DrawerBodyStyled>
                    {savedFilters.length ?
                        savedFilters.map(filter => (
                            <SavedFilterItem
                                key={filter.name}
                                filter={filter}
                                onClose={onDrawerClose}
                                onEditOpen={onOpen}
                            />
                        )) : (
                            <Text>No saved filters. Please save your filters to view them.</Text>
                        )
                    }
                </DrawerBodyStyled>
            </DrawerContent>
            <EditFiltersDrawer
                isOpen={isOpen}
                onClose={onClose}
                btnRef={btnEditRef}
            />
        </Drawer>
    )
})
