import { Text } from '@chakra-ui/layout'
import { DefaultCellData } from 'components/DateRangePicker/types'
import React, { FC } from 'react'
import { ClickedCellLayout } from './ClickedCellLayout'
import { CenterDefaultCellStyled } from '../../../styles'

interface DefaultCellProps {
    cellData: DefaultCellData
    selectDate: (date: Date) => void
}

export const DefaultCell: FC<DefaultCellProps> = ({ cellData, selectDate }) => {
    const day = cellData.date.getDate()

    return (
        <ClickedCellLayout date={cellData.date} selectDate={selectDate}>
            <CenterDefaultCellStyled>
                <Text variant="paragraphsmall">{day}</Text>
            </CenterDefaultCellStyled>
        </ClickedCellLayout>
    )
}
