import React from 'react'
import { useToast } from '@chakra-ui/toast'
import { StatusType } from 'types/chart'
import { CrossSmallIcon } from 'icons'
import { setStatusBackground } from 'utils/setStyles'
import { Title, ToastBox, ToastText, Description } from './styles'

export interface ToastProps {
    title: string
    description: string
    status: StatusType
    isClosable?: boolean
}

export const useCustomToast = () => {
    const toast = useToast()

    return (toastProps: ToastProps, margin?: string) => {
        const {title, description, status, isClosable = true} = toastProps

        return toast({
            render: ({ onClose }) => {
                return (
                    <ToastBox
                        margin={margin || '0 0 26% 0'}
                        background={setStatusBackground(status)}
                    >
                        <ToastText>
                            <Title>{title}: </Title>
                            <Description>{description}</Description>
                        </ToastText>
                        {isClosable && (
                            <CrossSmallIcon
                                cursor="pointer"
                                role="button"
                                boxSize="9px"
                                color="white"
                                onClick={onClose}
                            />
                        )}
                    </ToastBox>
                )
            },
        })
    }
}
