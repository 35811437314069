import axios from 'axios'
import { makeAutoObservable } from 'mobx'
import { ToastProps } from 'hooks/useCustomToast'
import { StatusType } from 'types/chart'
import { orderStore } from 'stores'

export class NotificationStore {
    public toast: (toastProps: ToastProps, margin?: string) => void = () => {}

    constructor() {
        makeAutoObservable(this)
    }

    public setToast(toast: (toastProps: ToastProps, margin?: string) => void) {
        this.toast = toast
    }

    private getAllErrors(errors: object = {}) {
        return Object.values(errors).reduce((acc: string[], value) => {
            if (Array.isArray(value)) {
                value.forEach(val => acc.push(val))
            }
            if (typeof value === 'string') {
                acc.push(value)
            }
            return acc
        }, []).join(', ')
    }

    public triggerErrorToast(err: any | unknown) {
        let errorMessage = 'Something went wrong...'

        const errResponseData = err?.response?.data
        const checkErrorStatusFlag = orderStore.checkErrorStatusFlag

        if (axios.isAxiosError(err) && err.response && errResponseData) {
            if (checkErrorStatusFlag === 'CHECKERRORSTATUS') {
                errorMessage =
                    errResponseData.additionalData[0].message ||
                    errResponseData
            } else if (errResponseData[0]?.message) {
                errorMessage = errResponseData[0]?.message
            } else {
                errorMessage =
                    errResponseData.message ||
                    errResponseData.title ||
                    this.getAllErrors(errResponseData.errors) ||
                    errResponseData
            }
        }

        this.toast({
            title: 'Error',
            description: errorMessage,
            status: StatusType.Error,
        })
    }

    public triggerSuccessToast(successStatus: string) {
        this.toast({
            title: 'Success',
            description: successStatus,
            status: StatusType.Success,
        })
    }

    public clearToast() {
        this.toast = () => {}
    }
}
