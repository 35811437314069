import { Steps } from './steps'
import { Accordion } from './accordion'
import { Button } from './button'
import { Heading } from './heading'
import { Input } from './input'
import { Radio } from './radio'
import { Text } from './text'
import { Tabs } from './tabs'
import { Switch } from './switch'
import { Checkbox } from './checkbox'
import { Divider } from './divider'
import { DropdownSelect } from './dropdownSelect'
import { Link } from './link'

export const components = {
    Text,
    Heading,
    Accordion,
    Button,
    Input,
    Radio,
    Tabs,
    Checkbox,
    Divider,
    DropdownSelect,
    Switch,
    Steps,
    Link,
}
