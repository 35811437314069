import { auditOrder } from './instances'
import { UUID } from 'types/core'

export class AuditClientAPI {
    static async getOrders(id: UUID) {
        const response = await auditOrder.get(id)
        return response.data || {}
    }
    static async getWorkflowRuns(id: UUID) {
        const response = await auditOrder.get(`${id}/workflow-runs`)
        return response.data || {}
    }
    static async getRunValues(id: UUID, stepId: UUID) {
        const response = await auditOrder.get(`${id}/run-values/${stepId}`)
        return response.data || {}
    }
}
