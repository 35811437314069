import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const DownloadIcon: FC<IconProps> = props => {
    return (
        <Icon
            width="10"
            height="19"
            viewBox="0 0 10 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 0C10.789 0 11.4286 0.639593 11.4286 1.42857V7.97969L12.5613 6.84699C13.1192 6.2891 14.0237 6.2891 14.5816 6.84699C15.1395 7.40488 15.1395 8.3094 14.5816 8.8673L11.0102 12.4387C10.4523 12.9966 9.54774 12.9966 8.98985 12.4387L5.41842 8.8673C4.86053 8.3094 4.86053 7.40488 5.41842 6.84699C5.97631 6.2891 6.88083 6.2891 7.43872 6.84699L8.57143 7.97969V1.42857C8.57143 0.639593 9.21102 0 10 0ZM1.42857 11.4286C2.21755 11.4286 2.85714 12.0682 2.85714 12.8571V14.2857C2.85714 15.0747 3.49674 15.7143 4.28571 15.7143H15.7143C16.5033 15.7143 17.1429 15.0747 17.1429 14.2857V12.8571C17.1429 12.0682 17.7825 11.4286 18.5714 11.4286C19.3604 11.4286 20 12.0682 20 12.8571V14.2857C20 16.6526 18.0812 18.5714 15.7143 18.5714H4.28571C1.91878 18.5714 0 16.6526 0 14.2857V12.8571C0 12.0682 0.639593 11.4286 1.42857 11.4286Z"
                fill="currentColor"
            />
        </Icon>
    )
}
