import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const MoreStepsCheckIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 12 12"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_1040:8603)">
                <g filter="url(#filter0_d_1040:8603)">
                    <path
                        d="M4.07573 10.2987L0.175729 6.39867C-0.0585762 6.16436 -0.0585762 5.78447 0.175729 5.55014L1.02424 4.70161C1.25854 4.46728 1.63846 4.46728 1.87277 4.70161L4.49999 7.32881L10.1272 1.70161C10.3615 1.4673 10.7414 1.4673 10.9758 1.70161L11.8243 2.55014C12.0586 2.78444 12.0586 3.16434 11.8243 3.39867L4.92426 10.2987C4.68993 10.533 4.31003 10.533 4.07573 10.2987Z"
                        fill="url(#paint0_linear_1040:8603)"
                    />
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_d_1040:8603"
                    x="-1"
                    y="1.52588"
                    width="14"
                    height="10.9487"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1040:8603"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1040:8603"
                        result="shape"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear_1040:8603"
                    x1="5.99999"
                    y1="1.52588"
                    x2="5.99999"
                    y2="10.4744"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#EFEFEF" />
                </linearGradient>
                <clipPath id="clip0_1040:8603">
                    <rect width="12" height="12" fill="white" />
                </clipPath>
            </defs>
        </Icon>
    )
}
