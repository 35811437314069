import React from 'react'
import {
    BrowserRouter as Router,
    Navigate,
    Route,
    Routes,
} from 'react-router-dom'
import { ScreenLayout } from 'layout/ScreenLayout'
import { WebPageLayout } from 'layout/WebPageLayout'
import { ApprovalPage } from 'pages/ApprovalPage'
import { CreationRulePage } from 'pages/CreationRulePage'
import { EditorPage } from 'pages/EditorPage'
import { ErrorPage } from 'pages/ErrorPage'
import { HomePage } from 'pages/HomePage'
import { AdminUsersPage } from 'pages/AdminPages/Users/UsersPage'
import { AdminTenantsPage } from 'pages/AdminPages/Tenants/TenantsPage'
import { OrderDetailsPage } from 'pages/OrderDetailsPage'
import { ForgotPasswordPage } from 'pages/LoginPage/content/ForgotPasswordPage'
import { LoginPage } from 'pages/LoginPage/content/LoginPage'
import { SubmitForApprovalPage } from 'pages/SubmitForApprovalPage'
import { ShippingCostPage } from 'pages/ShippingCost'
import { RulesSetsPage } from 'pages/RulesSetsPage'
import { PrivateRoute } from './PrivateRoute'
import { routes } from './routes'
import { AdminChannelsPage } from 'pages/AdminPages/Gateway/ChannelsPage'
import { AdminResourceOwnersAuthPage } from 'pages/AdminPages/Gateway/ResourceOwnersAuthPage'
import AuthNoAuthPage from 'pages/AdminPages/Gateway/AuthNoAuthPage'
import { AdminAuthApiKeyPage } from 'pages/AdminPages/Gateway/AuthApiKeyPage'
import { AdminGatewayPage } from 'pages/AdminPages/Gateway/GatewayPage'
import { AdminFeatureFlags } from 'pages/AdminPages/FeatureFlags'
import { AdminAuthenticationSchemaPage } from 'pages/AdminPages/Gateway/AuthenticationSchemaPage'
import { RulesSimulate } from 'pages/RulesSimulatePage'
import { ReportsPage } from 'pages/ReportsPage'
import { CreationRulesSetsPage } from 'pages/CreationRulesSetsPage'
import { CreateNewCategoryPage } from 'pages/AttributeConfiguration/AddCategory/CreationCategory'
import { CategoryPage } from 'pages/AttributeConfiguration/AddCategory'
import { AttributeConfiguration } from 'pages/AttributeConfiguration'

export const Navigation = () => {
    return (
        <Router>
            <Routes>
                <Route element={<ScreenLayout />}>
                    <Route path={routes.editor}>
                        <Route index element={<Navigate to={routes.home} />} />
                        <Route
                            path=":id"
                            element={
                                <PrivateRoute>
                                    <EditorPage />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                    <Route path={routes.submitApproval}>
                        <Route index element={<Navigate to={routes.home} />} />
                        <Route
                            path=":id"
                            element={
                                <PrivateRoute>
                                    <ApprovalPage />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                </Route>
                <Route element={<WebPageLayout />}>
                    <Route
                        path={`${routes.users}/:id`}
                        element={
                            <PrivateRoute checkAdminPage={true}>
                                <AdminUsersPage isUserList={false} />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={routes.users}
                        element={
                            <PrivateRoute checkAdminPage={true}>
                                <AdminUsersPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={routes.admin}
                        element={<Navigate to={routes.tenants} replace />}
                    />
                    <Route
                        path={routes.tenants}
                        element={
                            <PrivateRoute checkAdminPage={true}>
                                <AdminTenantsPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={routes.resourceOwnersAuth}
                        element={
                            <PrivateRoute>
                                <AdminResourceOwnersAuthPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={routes.ruleSimulate}
                        element={
                            <PrivateRoute>
                                <RulesSimulate />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={routes.authApiKey}
                        element={
                            <PrivateRoute>
                                <AdminAuthApiKeyPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={routes.gateway}
                        element={
                            <PrivateRoute>
                                <AdminGatewayPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={routes.featureFlags}
                        element={
                            <PrivateRoute>
                                <AdminFeatureFlags />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={routes.authenticationSchema}
                        element={
                            <PrivateRoute>
                                <AdminAuthenticationSchemaPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={routes.channels}
                        element={
                            <PrivateRoute>
                                <AdminChannelsPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={routes.authNoAuth}
                        element={
                            <PrivateRoute>
                                <AuthNoAuthPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={routes.home}
                        element={
                            <PrivateRoute>
                                <HomePage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={`${routes.orderDetails}/:id`}
                        element={
                            <PrivateRoute>
                                <OrderDetailsPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={routes.shippingCost}
                        element={
                            <PrivateRoute>
                                <ShippingCostPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={routes.createNewRule}
                        element={
                            <PrivateRoute>
                                <CreationRulePage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={`${routes.changeRule}/:id`}
                        element={
                            <PrivateRoute>
                                <CreationRulePage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={routes.createNewRuleSet}
                        element={
                            <PrivateRoute>
                                <CreationRulesSetsPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={`${routes.editRuleSet}/:id`}
                        element={
                            <PrivateRoute>
                                <CreationRulesSetsPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={routes.prioritize}
                        element={
                            <PrivateRoute>
                                <RulesSetsPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={routes.reports}
                        element={
                            <PrivateRoute>
                                <ReportsPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={routes.createNewCategory}
                        element={
                            <PrivateRoute>
                                <CreateNewCategoryPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={routes.categoryList}
                        element={
                            <PrivateRoute>
                                <CategoryPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={`${routes.editCategoy}/:id`}
                        element={
                            <PrivateRoute>
                                <CreateNewCategoryPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={routes.attributeConfiguration}
                        element={
                            <PrivateRoute>
                                <AttributeConfiguration />
                            </PrivateRoute>
                        }
                    />
                    <Route path={routes.approval}>
                        <Route index element={<Navigate to={routes.home} />} />
                        <Route
                            path=":id"
                            element={
                                <PrivateRoute>
                                    <SubmitForApprovalPage />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                    <Route path={routes.login} element={<LoginPage />} />
                    <Route
                        path={routes.forgotPassword}
                        element={<ForgotPasswordPage />}
                    />
                    <Route
                        path="*"
                        element={<ErrorPage>Page not found</ErrorPage>}
                    />
                </Route>
            </Routes>
        </Router>
    )
}
