import React, { ChangeEvent, useEffect, useState } from 'react'
import { Button, useColorMode } from '@chakra-ui/react'
import { MultiValue, SingleValue } from 'react-select'
import { isEqual } from 'lodash'
import { toJS } from 'mobx'
import { v4 } from 'uuid'
import { observer } from 'mobx-react-lite'
import { SaveIcon } from 'icons'
import { ISelectOption, UUID } from 'types/core'
import { routingMapStore, tenantsStore } from 'stores'
import { CustomSelect as Select } from 'components/baseComponents/Select'
import { reactSelectStyles } from 'components/baseComponents/Select/styles'
import {
    DividerStyled,
    SaveButtonBox,
    SaveInfoText,
} from 'components/TenantsComponents/Tabs/style'
import {
    ButtonStyled,
    GroupIdContainer,
    GroupIdInput,
    SelectBox,
    SelectContainer,
    TenantsBox,
    Title,
} from 'pages/AdminPages/Tenants/TenantsPage/TenantsGroupMapping/styles'

const RoutingMap = observer(() => {
    const [errors, setErrors] = useState<UUID[]>([])
    const routingMap = toJS(routingMapStore.routingMap)
    const defaultRoutingMap = toJS(routingMapStore.defaultRoutingMap)
    const routingMapOptions = toJS(routingMapStore.routingMapOptions)
    const selectedTenant = toJS(tenantsStore.selectedTenant)
    const tenantsTreeSettings = toJS(tenantsStore.tenantsTreeSettings)

    const saveInfo = isEqual(routingMap, defaultRoutingMap) || !!errors.length

    const { colorMode } = useColorMode()

    const handleChangeChild = (
        value: SingleValue<ISelectOption> | MultiValue<ISelectOption>,
        id: UUID
    ): void => {
        if (value && 'value' in value && value.value) {
            const selectedValue = value.value

            routingMapStore.updateSelectedChild(id, selectedValue)
        }
    }

    const handleChangeInput = (
        value: ChangeEvent<HTMLInputElement>,
        id: UUID
    ): void => {
        const isUniqueValue = routingMapStore.validateSelectedInput(
            value.target.value,
            id
        )

        if (!!value.target.value && isUniqueValue) {
            routingMapStore.updateSelectedInput(value.target.value, id)
            setErrors(prev => prev.filter(errorId => errorId !== id))
        } else {
            setErrors(prev => [...prev, id])
        }
    }

    const addRow = (): void => {
        const id = v4()
        routingMapStore.addNewRow(id)
        setErrors(prev => [...prev, id])
    }

    const deleteRoutingMap = (id: UUID): void => {
        routingMapStore.deleteSelectedRoutingMap(id)
        setErrors(prev => prev.filter(errorId => errorId !== id))
    }

    const onSubmit = async () => {
        await routingMapStore.updateRoutingsMap(selectedTenant.id)
    }

    useEffect(() => {
        routingMapStore.fetchRoutingMapOptions(selectedTenant.id)
        routingMapStore.fetchRoutingMap(selectedTenant.id)
        return () => {
            routingMapStore.clean()
            setErrors([])
        }
    }, [selectedTenant.id, tenantsTreeSettings.length])

    return (
        <>
            <SaveButtonBox>
                <Button
                    type="submit"
                    variant="blue"
                    size="sm"
                    disabled={saveInfo}
                    onClick={onSubmit}
                >
                    <SaveIcon w={3} h={3} color="white" />
                </Button>
                {!saveInfo && (
                    <SaveInfoText>
                        Changes are detected, you can save them
                    </SaveInfoText>
                )}
            </SaveButtonBox>
            <DividerStyled />
            <TenantsBox>
                {!!routingMap.length && (
                    <>
                        <GroupIdContainer>
                            <Title marginLeft="20px">Key</Title>
                            {routingMap.map(([id, inputValue]) => {
                                const findError = errors.find(
                                    errorId => errorId === id
                                )
                                return (
                                    <GroupIdInput
                                        key={id}
                                        value={inputValue}
                                        onChange={value =>
                                            handleChangeInput(value, id)
                                        }
                                        border={
                                            findError
                                                ? '2px solid'
                                                : '1px solid'
                                        }
                                        borderColor={
                                            findError ? 'red.500' : 'gray.600'
                                        }
                                    />
                                )
                            })}
                        </GroupIdContainer>
                        <SelectContainer>
                            <Title marginLeft="30px">Select child</Title>
                            {routingMap.map(([id, inputValue, option]) => {
                                const findChild =
                                    routingMapOptions.find(
                                        child => child.value === option
                                    ) || null

                                const selectValue = findChild
                                    ? {
                                          value: findChild.value,
                                          label: findChild.label,
                                      }
                                    : findChild

                                return (
                                    <SelectBox key={id}>
                                        <Select
                                            selectValue={selectValue}
                                            options={routingMapOptions}
                                            placeholder="Tenant child"
                                            onChange={value =>
                                                handleChangeChild(value, id)
                                            }
                                            customStyles={reactSelectStyles(
                                                colorMode,
                                                '140px',
                                                '16px',
                                                '60px'
                                            )}
                                        />
                                        <ButtonStyled
                                            variant="blue"
                                            onClick={() => deleteRoutingMap(id)}
                                        >
                                            -
                                        </ButtonStyled>
                                    </SelectBox>
                                )
                            })}
                        </SelectContainer>
                    </>
                )}
            </TenantsBox>
            <ButtonStyled variant="blue" onClick={addRow} disabled={!routingMapOptions.length}>
                Add
            </ButtonStyled>
        </>
    )
})
export default RoutingMap
