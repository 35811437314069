import { tenantsInstance } from './instances'
import { AuthNoAuthResponse, PagingFilters } from '../types/api'
import { AuthNoAuth } from '../types/core'

export class AuthNoAuthApi {
    static async getAuthNoAuth(
        tenantId: string,
        { page, size }: PagingFilters
    ): Promise<AuthNoAuthResponse> {
        const response = await tenantsInstance.get<AuthNoAuthResponse>(
            `/${tenantId}/auth/no-auths?page=${page}&size=${size}`
        )
        return response.data
    }

    static async updateAuthNoAuth(
        data: AuthNoAuth
    ): Promise<AuthNoAuthResponse> {
        const response = await tenantsInstance.put<AuthNoAuthResponse>(
            `/${data.tenantId}/auth/no-auths/${data.id}`,
            {
                name: data.name,
            }
        )
        return response.data
    }

    static async createAuthNoAuth(
        tenantId: string,
        data: AuthNoAuth
    ): Promise<AuthNoAuthResponse> {
        const response = await tenantsInstance.post<AuthNoAuthResponse>(
            `/${tenantId}/auth/no-auths`,
            {
                name: data.name,
            }
        )
        return response.data
    }

    static async removeAuthNoAuth(data: AuthNoAuth) {
        const response = await tenantsInstance.delete(
            `/${data.tenantId}/auth/no-auths/${data.id}`
        )

        return response.data
    }
}
