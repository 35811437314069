import { Box, Flex, Text } from '@chakra-ui/layout'
import { chakra } from 'utils/chakra'
import { Button } from '@chakra-ui/react'

export const TitleBox = chakra(Flex, {
    justifyContent: 'space-between',
    flexDirection: 'column',
})

export const Title = chakra(Text, {
    variant: 'h2',
    color: 'color.200',
    marginTop: '13px',
})

export const NavTitle = chakra(Text, {
    variant: 'paragraph',
    color: 'color.200',
})

export const InputsBox = chakra(Flex, {
    margin: '24px 0 21px 0',
    flexDirection: 'column',
})

export const BottomButtonsBox = chakra(Flex, {
    justifyContent: 'space-between',
})

export const BottomButtonBox = chakra(Flex, {
    justifyContent: 'flex-end',
})

export const BottomLeftButtons = chakra(Flex, {})

export const BottomRightButtons = chakra(Flex, {})

export const BoxStyled = chakra(Box, {
    borderRadius: '16px',
    padding: '24px',
    backgroundColor: 'blueWhite.900',
    boxShadow: '0px 6px 26px rgba(16, 30, 115, 0.06)',
})

export const SetRulesButton = chakra(Button, {
    variant: 'blue',
    height: '40px',
})

export const OutlinedButton = chakra(Button, {
    height: '40px',
    backgroundColor: 'blueWhite.900',
    color: 'buttonOutlineColor',
    border: 'solid 2px',
    borderColor: 'blue.200',
    marginRight: '20px',
    _hover: {
        color: 'white',
        backgroundColor: 'blue.200',
    },
    _active: {
        backgroundColor: 'blue.200',
    },
})
