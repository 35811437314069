import React, { useEffect, FC, Dispatch } from 'react'
import { Box } from '@chakra-ui/layout'
import { useDisclosure } from '@chakra-ui/hooks'
import { observer } from 'mobx-react-lite'
import { PrioritizeList } from './content/PrioritizeList'
import { EditWowDialog } from './content/EditWowDialog'
import { Gap, GapLayout } from 'layout/GapLayout'
import { prioritizeStore } from 'stores'
import { UUID } from 'types/core'

interface IPrioritizeRules {
    id: UUID
    editOpen: boolean
    setEditOpen: Dispatch<boolean>
}

export const PrioritizeRules: FC<IPrioritizeRules> = observer(({ id, editOpen, setEditOpen }) => {
    const {
        isOpen: isOpenEditWowDialog,
        onOpen: onOpenEditWowDialog,
        onClose: onCloseEditWowDialog,
    } = useDisclosure()

    const onClose = () => {
        setEditOpen(false)
        onCloseEditWowDialog()
    }

    useEffect(() => {
        prioritizeStore.fetch(id)

        return () => {
            prioritizeStore.clean()
        }
    }, [id])

    useEffect(() => {
        if (editOpen) onOpenEditWowDialog()
    }, [editOpen, onOpenEditWowDialog])

    return (
        <GapLayout>
            <Box margin="31px 0">
                {!!prioritizeStore.workflowsStore.workflows.length && <PrioritizeList />}
            </Box>
            <Gap size="10px" />
            {!!prioritizeStore.workflowsStore.workflows.length && (
                <EditWowDialog
                    isOpen={isOpenEditWowDialog}
                    onClose={onClose}
                    workflows={prioritizeStore.workflowsStore.workflows}
                    defaultSelected={prioritizeStore.defaultRules}
                />
            )}
        </GapLayout>
    )
})
