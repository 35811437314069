import React, { ChangeEvent, Dispatch, SetStateAction, useState } from 'react'
import { Box } from '@chakra-ui/layout'
import { Input } from '@chakra-ui/react'
import { AddModalButton } from '../styles'

interface IAddItemComponent {
    onClose: () => void
    arrKeys: string[]
    jsonData: object
    setJsonData: Dispatch<SetStateAction<object>>
    handleSave: (value: object) => void
    handleSaveCopy: (value: object) => void
}

const defaultObject = {
    '__metadata': {
        'type': 'Object',
        'alias': null,
        'expression': { parameters: null, type: 'value', value: '' },
    },
}

export const AddItemComponent = ({
                                     arrKeys,
                                     onClose,
                                     jsonData,
                                     setJsonData,
                                     handleSave,
                                     handleSaveCopy,
                                 }: IAddItemComponent) => {
    const [inputValue, setInputValue] = useState('')

    const handleSaveModal = () => {
        let copyObj: { [key: string]: unknown } = { ...jsonData }
        if (!arrKeys.length) {
            copyObj = { ...copyObj, [inputValue]: defaultObject }
        } else {
            [...arrKeys].reduce((acc: any, path, index) => {
                if (
                    index === [...arrKeys].length - 1 &&
                    acc instanceof Object &&
                    acc.constructor === Object
                ) {
                    acc[path] = { ...acc[path], [inputValue]: defaultObject }
                    return acc
                }
                if (acc instanceof Object && acc.constructor === Object) {
                    return acc[path]
                }
                return acc
            }, copyObj)

        }
        setJsonData(copyObj)
        handleSave(copyObj)
        handleSaveCopy(copyObj)
        onClose()
    }

    return (
        <Box>
            <Box display="flex" paddingBottom="20px">
                <Input
                    size='sm'
                    value={inputValue}
                    placeholder='key'
                    onChange={(value: ChangeEvent<HTMLInputElement>) =>
                        setInputValue(value.target.value)
                    }
                    width="851px"
                />
            </Box>

            <Box display="flex" flexDirection="row-reverse">
                <AddModalButton
                    variant="blue"
                    size='sm'
                    onClick={handleSaveModal}
                >
                    Save
                </AddModalButton>
                <AddModalButton
                    type='button'
                    variant="blue"
                    onClick={onClose}
                >
                    Cancel
                </AddModalButton>
            </Box>
        </Box>
    )
}
