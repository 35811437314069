import React, { FC } from 'react'
import { FormControl } from '@chakra-ui/form-control'
import { InputProps } from '@chakra-ui/input'
import { Flex, Spacer } from '@chakra-ui/layout'
import { InputStyled, TextStyled } from './styles'

interface CounteredInputProps extends InputProps {
    description?: string
    remaining?: number
    type?: string
    disabled?: boolean
    showRemaining?: boolean
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const CounteredInput: FC<CounteredInputProps> = React.forwardRef<
    HTMLInputElement,
    CounteredInputProps
>(({ description, onChange, remaining = 30, type = 'text', showRemaining = true, ...props }, ref) => {
        const isInvalid = props.isInvalid

        return (
            <FormControl
                width={props.width}
                height={props.height}
                isInvalid={isInvalid}
            >
                <InputStyled
                    type={type}
                    onChange={onChange}
                    border={isInvalid ? '3px solid' : '1px solid'}
                    borderColor={isInvalid ? 'red.500' : 'color.875'}
                    ref={ref}
                    {...props}
                />
                <Flex marginTop="9px">
                    <TextStyled color={isInvalid ? 'red.500' : 'color.100'}>
                        {description}
                    </TextStyled>
                    <Spacer />
                    {showRemaining && (
                        <TextStyled color={isInvalid ? 'red.500' : 'color.100'}>
                            {remaining}
                        </TextStyled>
                    )}
                </Flex>
            </FormControl>
        )
    }
)
