import React, { useEffect, useMemo } from 'react'
import { Column } from 'react-table'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { featureFlagStore } from 'stores'
import { columnsFeatureFlagsGlobal } from 'components/TableComponents/mock'
import EditableTable from 'components/TableComponents/Table'
import { NumericPagination } from 'components/NumericPagination'
import {
    FeatureFlagsBox,
    FeatureFlagsBoxColor,
    FeatureFlagsGlobal
} from '../styles'

export const GlobalFlagsTab = observer(() => {
    const data = toJS(featureFlagStore.globalList)

    useEffect(() => {
        featureFlagStore.fetchGlobal()
        return () => {
            featureFlagStore.cleanGlobal()
        }
    }, [])

    const columns: Column[] = useMemo(() => columnsFeatureFlagsGlobal, [])

    const updateMyData = (rowIndex: number, columnId: string, value: string) => {
        const updateData = data[rowIndex]
        featureFlagStore.changeGlobal({...updateData, [columnId]: value})
    }

    return (
        <FeatureFlagsGlobal>
            <FeatureFlagsBoxColor>
                <FeatureFlagsBox>
                    <EditableTable
                        data={data}
                        columns={columns}
                        updateMyData={updateMyData}
                    />
                </FeatureFlagsBox>
            </FeatureFlagsBoxColor>
            <NumericPagination
                marginTop="24px"
                pages={featureFlagStore.pages}
                activePage={featureFlagStore.filters.page}
                onChangePage={page => {
                    featureFlagStore.setFilters({ page })
                }}
            />
        </FeatureFlagsGlobal>
    )
})
