import React, { FC, useCallback, useRef, useState } from 'react'
import {
    BoxProps,
    Center,
    Collapse,
    Text,
    useDisclosure,
    useMultiStyleConfig,
    useOutsideClick,
} from '@chakra-ui/react'
import { CrossSmallIcon, DropDownIcon } from 'icons'
import { VirtualScroller, GetDataResult } from 'components/VirtualScroller'
import { getItemName } from '../utils'
import { OptionData } from '../types'
import {
    ButtonsBox,
    IconBox,
    OptionsBox,
    SelectBox,
    SelectInput,
} from '../styles'

interface VirtualizedSelectProps extends Omit<BoxProps, 'onChange'> {
    placeholder?: string
    readonly?: boolean
    getOptions: (
        page: number,
        size: number,
        search: string
    ) => Promise<GetDataResult<OptionData>>
    value: OptionData | null
    onChange: (option: OptionData) => void
    onClear: () => void
    isDisabled: boolean
    ruleName: string
}

export const VirtualizedSelect: FC<VirtualizedSelectProps> = ({
    placeholder = '',
    readonly = false,
    getOptions,
    value,
    onChange,
    onClear,
    isDisabled,
    ruleName,
    ...props
}) => {
    const ref = useRef<HTMLDivElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)
    const { isOpen, onClose, onToggle } = useDisclosure()
    const [searchValue, setSearchValue] = useState('')
    const styles = useMultiStyleConfig('DropdownSelect', { isOpen })

    const isDisabledColor = isDisabled ? 'gray.100' : 'white'
    const borderRadius = isOpen ? '8px 8px 0 0' : '8px'

    const handleClick = () => {
        if (!readonly && !isDisabled) onToggle()
    }

    useOutsideClick({
        ref,
        handler: () => {
            onClose()
        },
    })

    const getVirtualizedItems = useCallback(
        (page, size) => {
            return getOptions(page, size, searchValue)
        },
        [getOptions, searchValue]
    )

    return (
        <SelectBox
            ref={ref}
            onClick={handleClick}
            {...props}
        >
            <SelectInput
                variant="nodeSelect"
                color={isDisabledColor}
                _placeholder={{ color: isDisabledColor }}
                borderRadius={borderRadius}
                ref={inputRef}
                placeholder={placeholder}
                isDisabled={isDisabled}
                isReadOnly={!!value}
                value={
                    value
                        ? getItemName(value.label, value.value, ruleName)
                        : searchValue
                }
                onChange={event => {
                    setSearchValue(event.target.value)
                }}
            />

            {!readonly && !isDisabled && (
                <ButtonsBox>
                    {!!value && (
                        <IconBox
                            onClick={() => {
                                onClear()
                                inputRef.current?.focus()
                            }}
                        >
                            <CrossSmallIcon boxSize="8px" color="white" />
                        </IconBox>
                    )}
                    <IconBox>
                        <DropDownIcon
                            openned={isOpen}
                            boxSize="18px"
                            color="white"
                        />
                    </IconBox>
                </ButtonsBox>
            )}
            {!isDisabled && (
                <OptionsBox>
                    <Collapse in={isOpen}>
                        <VirtualScroller<OptionData>
                            container={styles.container as BoxProps}
                            settings={{
                                pageSize: 100,
                                itemHeight: 26,
                                amount: 8,
                                tolerance: 3,
                            }}
                            get={getVirtualizedItems}
                            render={option => (
                                <Center
                                    key={option.value}
                                    {...(styles.option as BoxProps)}
                                    onClick={() => {
                                        onChange(option)
                                        setSearchValue('')
                                    }}
                                    backgroundColor="blueWhite.950"
                                    _hover={{ backgroundColor: 'blueWhite.995' }}
                                >
                                    <Text isTruncated>
                                        {getItemName(
                                            option.label,
                                            option.value,
                                            ruleName
                                        )}
                                    </Text>
                                </Center>
                            )}
                            empty={
                                <Center
                                    {...(styles.empty as BoxProps)}
                                    backgroundColor="blueWhite.950"
                                    _hover={{ backgroundColor: 'blueWhite.995' }}
                                >
                                    <Text isTruncated>No options</Text>
                                </Center>
                            }
                        />
                    </Collapse>
                </OptionsBox>
            )}
        </SelectBox>
    )
}
