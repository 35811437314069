import React, { FC } from 'react'
import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/modal'
import { Flex, Text, Center, Divider } from '@chakra-ui/layout'
import { DiagramView } from './DiagramView'
import { CrossSmallIcon } from 'icons'
import { UUID } from 'types/core'
import { IconBox, HeaderBox, ModalBodyStyled } from './styles'

interface PreviewDialogModalProps {
    viewId: UUID
    title?: string
    isOpen: boolean
    onClose: () => void
}

export const PreviewDialogModal: FC<PreviewDialogModalProps> = ({
    viewId,
    title,
    isOpen,
    onClose,
}) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size="6xl"
            scrollBehavior="inside"
            closeOnOverlayClick
            isCentered
        >
            <ModalOverlay backgroundColor="rgba(0, 0, 0, 0.25)" />
            <ModalContent borderRadius="16px" height="90%">
                <ModalBodyStyled>
                    <Flex direction="column" height="100%">
                        {!!title && (
                            <>
                                <HeaderBox>
                                    <Text variant="h2" color="color.100">
                                        {title}
                                    </Text>
                                    <IconBox onClick={onClose}>
                                        <CrossSmallIcon boxSize="15px" color="color.100" />
                                    </IconBox>
                                </HeaderBox>
                                <Center
                                    padding="0 24px"
                                    backgroundColor="color.810"
                                >
                                    <Divider variant="editor" />
                                </Center>
                            </>
                        )}
                        <DiagramView id={viewId} />
                    </Flex>
                </ModalBodyStyled>
            </ModalContent>
        </Modal>
    )
}
