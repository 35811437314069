import { UserRole } from 'api/models'
import { UUID } from 'types/core'

export declare type UserRoleName =
    | `${UserRole.RulesCreator}`
    | `${UserRole.RulesApprover}`
    | `${UserRole.TenantAdmin}`
    | `${UserRole.Support}`

export interface IUserRoleModel {
    role: UserRoleName
    isAssigned: boolean
    caption: string
}

export const userRoleModels: IUserRoleModel[] = [
    { role: 'RulesCreator', caption: 'Rule\'s creator', isAssigned: false },
    { role: 'RulesApprover', caption: 'Rule\'s approver', isAssigned: false },
    { role: 'TenantAdmin', caption: 'Tenant administrator', isAssigned: false },
    { role: 'Support', caption: 'Support', isAssigned: false },
]

export interface IUserModel {
    id: UUID
    number: number
    firstName: string
    lastName: string
    displayName: string
    email: string
    isActive: boolean
    isEditable: boolean
    state: string
}
