import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const AdminPagesIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox='0 0 20 22'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <g id='a' />
            <g id='b'>
                <g id='c'>
                    <path id='d'
                          fill="currentColor"
                          d='M19.41,4.15L10.41,.09c-.26-.12-.56-.12-.82,0L.59,4.15c-.36,.16-.59,.52-.59,.91V13.19c0,2.9,1.72,5.56,4.55,6.66l5.07,2.08c.24,.1,.52,.1,.76,0l5.08-2.08h0c2.7-1.09,4.54-3.74,4.54-6.66V5.06c0-.39-.23-.75-.59-.91Zm-4.7,13.84h0l-4.7,1.93-4.7-1.93h-.02s-.08-.04-.12-.06c0-.03,0-.06,0-.09,0-2.26,1.65-4.21,3.79-4.68v2.58c0,.55,.45,1,1,1s1-.45,1-1v-2.58c2.14,.47,3.79,2.42,3.79,4.68,0,.04,0,.09,0,.13l-.04,.02ZM7.58,8.58c0-1.31,1.06-2.37,2.37-2.37s2.37,1.06,2.37,2.37-1.06,2.37-2.37,2.37-2.37-1.06-2.37-2.37Zm10.42,4.61c0,1.29-.51,2.53-1.37,3.46-.39-2.14-1.8-3.94-3.69-4.89,.85-.8,1.38-1.93,1.38-3.18,0-2.41-1.96-4.37-4.37-4.37s-4.37,1.96-4.37,4.37c0,1.25,.53,2.39,1.38,3.18-1.88,.94-3.28,2.72-3.68,4.84-.82-.93-1.28-2.13-1.28-3.41V5.71L10,2.1l8,3.61v7.48Z' />
                </g>
            </g>
        </Icon>
    )
}
