import React, { useEffect, useMemo, useState } from 'react'
import { addMinutes, format, subMonths } from 'date-fns'
import { Box, useDisclosure } from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'
import { Button } from '@chakra-ui/button'
import { Cards, ICard } from 'components/Cards'
import { CreateOrderNote } from './CreateOrderNote'
import { ComponentModal } from '../Modal/ComponentModal'
import { CreateOrderItem, IOrderItemState } from './CreateOrderItem'
import { InputWithHeader } from 'components/InputWithHeader'
import { IOrderNotes } from 'types/core'
import { dateFormat } from 'pages/HomePage/mock'
import { deleteEmptyObjectValues } from 'utils/deleteEmptyObjectValues'
import { deleteKeysFromObjArray } from 'utils/deleteKeysFromObjArray'
import { useInputChange } from 'hooks/useInputChange'
import { notificationStore, orderStore } from 'stores'
import {
    ORDER_TYPE,
    SALES_OFFICE,
    PAYMENT_TERM,
    CUSTOMER_CODE,
    CUSTOMER_EXTERNAL_ORDER_ID,
    ORDER_SOURCE_EXTERNAL_ORDER_ID,
} from 'constants/virtualOrder'
import {
    InputText,
    VirtualOrderBox,
    CreateVirtualOrderBox,
    VirtualOrderBoxReverse,
} from './style'

interface IOnClose {
    onClose: () => void
    onHandlerModal: () => void
}

export const CreateVirtualOrder = observer(({
    onClose,
    onHandlerModal,
}: IOnClose) => {
    const [orderItemsError, setOrderItemsError] = useState(false)
    const [orderItems, setOrderItems] = useState<IOrderItemState[]>([])
    const [notes, setNotes] = useState<IOrderNotes[]>([])
    const [orderItemsCards, setOrderItemsCards] = useState<ICard[]>([])
    const [orderNotesCards, setOrderNotesCards] = useState<ICard[]>([])
    const {
        value: customerCodeValue,
        isError: customerCodeIsError,
        inputProps: customerCodeProps,
    } = useInputChange({
        isRequired: true,
        maxLength: CUSTOMER_CODE,
    })
    const {
        value: sourceExternalIdValue,
        isError: sourceExternalIdIsError,
        inputProps: sourceExternalIdProps,
    } = useInputChange({
        isRequired: true,
        maxLength: ORDER_SOURCE_EXTERNAL_ORDER_ID,
    })
    const {
        value: orderTypeValue,
        isError: orderTypeIsError,
        inputProps: orderTypeProps,
    } = useInputChange({
        isRequired: true,
        maxLength: ORDER_TYPE,
    })
    const {
        value: customerExternalIdValue,
        isError: customerExternalIdIsError,
        inputProps: customerExternalIdProps,
    } = useInputChange({ maxLength: CUSTOMER_EXTERNAL_ORDER_ID })
    const {
        value: paymentTermValue,
        isError: paymentTermIsError,
        inputProps: paymentTermProps,
    } = useInputChange({ maxLength: PAYMENT_TERM })
    const {
        value: salesOfficeValue,
        isError: salesOfficeIsError,
        inputProps: salesOfficeProps,
    } = useInputChange({ maxLength: SALES_OFFICE })
    const {
        isOpen: isItemsOpen,
        onOpen: onItemsOpen,
        onClose: onItemsClose,
    } = useDisclosure()
    const {
        isOpen: isNotesOpen,
        onOpen: onNotesOpen,
        onClose: onNotesClose,
    } = useDisclosure()

    const { creationVirtualOrderId } = orderStore

    const isDisabled = customerCodeIsError
        || sourceExternalIdIsError
        || orderTypeIsError
        || customerExternalIdIsError
        || paymentTermIsError
        || salesOfficeIsError

    const virtualOrderInputs = useMemo(() => [
        {
            inputText: 'Customer Code (Required)',
            placeholder: 'Enter Customer Code...',
            inputProps: customerCodeProps,
        },
        {
            inputText: 'Order Source External Order Id (Required)',
            placeholder: 'Enter Order Source External Order Id...',
            inputProps: sourceExternalIdProps,
        },
        {
            inputText: 'Order Type (Required)',
            placeholder: 'Enter Order Type...',
            inputProps: orderTypeProps,
        },
        {
            inputText: 'Customer External Order Id',
            placeholder: 'Enter Customer External Order Id...',
            inputProps: customerExternalIdProps,
        },
        {
            inputText: 'Payment Term',
            placeholder: 'Enter Payment Term...',
            inputProps: paymentTermProps,
        },
        {
            inputText: 'Sales Office',
            placeholder: 'Enter Sales Office...',
            inputProps: salesOfficeProps,
        },
    ], [
        customerCodeProps,
        sourceExternalIdProps,
        orderTypeProps,
        customerExternalIdProps,
        paymentTermProps,
        salesOfficeProps,
    ])

    const saveVirtualOrder = () => {
        if (!orderItems.length) {
            setOrderItemsError(true)
            return
        }

        const timeZoneOffset = new Date().getTimezoneOffset()
        const defaultDateFrom = format(
            addMinutes(subMonths(new Date(), 1), timeZoneOffset),
            dateFormat
        )
        const data = {
            expectedDeliveryDate: defaultDateFrom,
            orderType: orderTypeValue,
            salesOffice: salesOfficeValue,
            paymentTerm: paymentTermValue,
            rawData: sourceExternalIdValue,
            customerCode: customerCodeValue,
            customerExternalOrderId: customerExternalIdValue,
            orderSourceExternalOrderId: sourceExternalIdValue,
            orderItems: deleteKeysFromObjArray(orderItems, ['id']),
            notes: deleteKeysFromObjArray(notes, ['id']),
        }

        orderStore
            .createVirtualOrder(creationVirtualOrderId, deleteEmptyObjectValues(data))
            .then(res => {
                onClose()
                res && onHandlerModal()
            })
            .catch((err) => {
                notificationStore.triggerErrorToast(err)
            })
    }

    const onOrderItemsDelete = (id: string | number | null) => {
        setOrderItemsCards(prev => prev.filter(card => card.id !== id))
        setOrderItems(prev => prev.filter(orderItem => orderItem.id !== id))
    }

    const onOrderNotesDelete = (id: string | number | null) => {
        setOrderNotesCards(prev => prev.filter(card => card.id !== id))
        setNotes(prev => prev.filter(note => note.id !== id))
    }

    useEffect(() => {
        orderStore.setCreationVirtualOrderId(onClose)
    }, [onClose])

    useEffect(() => {
        if (orderItems.length) setOrderItemsError(false)
    }, [orderItems, notes])

    return (
        <CreateVirtualOrderBox>
            <Box>
                {virtualOrderInputs.map(({ inputText, placeholder, inputProps}) =>
                    <InputWithHeader
                        key={inputText}
                        inputText={inputText}
                        placeholder={placeholder}
                        inputProps={inputProps}
                    />
                )}
                <VirtualOrderBox>
                    {!!orderItemsCards.length && (
                        <InputText marginBottom="10px">Order Items (Required)</InputText>
                    )}
                    {orderItemsError && (
                        <InputText color="red.500">PLease add at least 1 order item</InputText>
                    )}
                    <Cards
                        bottomHeader="QUANTITY"
                        cards={orderItemsCards}
                        onDelete={onOrderItemsDelete}
                    />
                </VirtualOrderBox>
                <VirtualOrderBox>
                    <Button
                        marginTop="10px"
                        variant="blue"
                        onClick={onItemsOpen}
                    >
                        Add Order Item (Required)
                    </Button>
                </VirtualOrderBox>
                <VirtualOrderBox>
                    {!!orderNotesCards.length && (
                        <InputText marginBottom="10px">Order Notes</InputText>
                    )}
                    <Cards
                        middleHeader="VALUE"
                        bottomHeader="EXTERNAL CODE"
                        cards={orderNotesCards}
                        onDelete={onOrderNotesDelete}
                    />
                </VirtualOrderBox>
                <VirtualOrderBox>
                    <Button
                        marginTop="10px"
                        variant="blue"
                        onClick={onNotesOpen}
                    >
                        Add Order Note
                    </Button>
                </VirtualOrderBox>
            </Box>
            <Box alignSelf="flex-end">
                <VirtualOrderBoxReverse>
                    <Button
                        type="submit"
                        variant="blue"
                        isDisabled={isDisabled || orderItemsError}
                        onClick={saveVirtualOrder}
                    >
                        Save
                    </Button>
                    <Button
                        margin="0 16px"
                        type="button"
                        variant="blue"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                </VirtualOrderBoxReverse>
            </Box>
            <ComponentModal
                isOpen={isItemsOpen}
                onClose={onItemsClose}
                headerText="Create Order Item"
                reactComponent={
                    <CreateOrderItem
                        onClose={onItemsClose}
                        setOrderItem={setOrderItems}
                        setCards={setOrderItemsCards}
                        orderItems={orderItems}
                    />
                }
            />
            <ComponentModal
                isOpen={isNotesOpen}
                onClose={onNotesClose}
                headerText="Create Order Note"
                reactComponent={
                    <CreateOrderNote
                        onClose={onNotesClose}
                        setOrderNote={setNotes}
                        setCards={setOrderNotesCards}
                        notes={notes}
                    />
                }
            />
        </CreateVirtualOrderBox>
    )
})
