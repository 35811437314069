import React from 'react'
import { RectangleErrorIcon, RectangleSuccessIcon, RectangleWarningIcon } from 'icons'
import { WorkflowRunStatus, OrderStatus } from 'types/core'

export const setRectangleIcon = (status: string) => {
    switch(status) {
        case OrderStatus.Created:
        case OrderStatus.Completed:
        case WorkflowRunStatus.Started:
        case WorkflowRunStatus.Success:
            return <RectangleSuccessIcon />
        case OrderStatus.ProcessingRule:
        case OrderStatus.Shipment:
        case WorkflowRunStatus.Processing:
        case WorkflowRunStatus.Skipped:
            return <RectangleWarningIcon />
        case OrderStatus.Error:
        case WorkflowRunStatus.Failed:
            return <RectangleErrorIcon />
        default:
            return <RectangleErrorIcon />
    }
}
