import { ScrollerService } from 'components/VirtualScroller/service'
import { OptionData } from '..'

export class RisenScrollerService extends ScrollerService<OptionData> {
    protected risen: OptionData[] = []

    public async setRisen(risen: OptionData[]) {
        this.risen = risen
    }

    protected async displayData() {
        const [start, end] = this.getBounds()

        let resultData: OptionData[] = []

        if (this.risen.length > start) {
            const takeFromRisenStart = start
            const takeFromRisenEnd = Math.min(end, this.risen.length)

            const displayRisen = this.risen.slice(
                takeFromRisenStart,
                takeFromRisenEnd
            )

            resultData = [...displayRisen]
        }

        const startPage = this.getStartPage(start)

        for (
            let [fetchedPage, skip, need] = [
                startPage,
                this.getStartOnPage(Math.max(start - this.risen.length, 0)),
                this.bufferedItems - resultData.length,
            ];
            resultData.length < this.bufferedItems &&
            resultData.length < this.totalCount;
            fetchedPage++
        ) {
            await this.fetchPages(fetchedPage, 1)

            const pageData = this.getCachedPage(fetchedPage).filter(
                item => !this.risen.some(risen => risen.value === item.value)
            )

            let startOnPage = 0

            if (skip !== 0) {
                if (skip < pageData.length) {
                    startOnPage = skip
                    skip = 0
                } else {
                    skip -= pageData.length
                    continue
                }
            }

            let endOnPage = startOnPage + need

            if (endOnPage < pageData.length) {
                const takedData = pageData.slice(startOnPage, endOnPage)

                need -= takedData.length
                resultData = [...resultData, ...takedData]
            } else {
                const takedData = pageData.slice(startOnPage)

                need -= takedData.length
                resultData = [...resultData, ...takedData]
            }
        }

        this.data = resultData
        this.paddingTop = this.calcPaddingTop(start)
        this.paddingBottom = this.calcPaddingBottom(end)
    }
}
