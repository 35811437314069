import React, { ChangeEvent, useEffect, useState } from 'react'
import { Button, Input, InputRightElement } from '@chakra-ui/react'
import { InputGroup } from '@chakra-ui/input'
import { Flex, Text } from '@chakra-ui/layout'
import { CustomCellProps } from '../EditableCell'

const PasswordCell = ({
    column,
    updateMyData,
    editingRows,
    value: initialValue,
    row,
}: CustomCellProps) => {
    const [value, setValue] = useState(initialValue)
    const [show, setShow] = useState(false)

    const onInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setValue(e.target.value)
    }

    const handleButtonClick = () => setShow(!show)

    const onInputBlur = (): void => {
        updateMyData(row.index, column.id, value)
        handleButtonClick()
    }

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    if (!column.editable || !editingRows[row.id])
        return (
            <Flex whiteSpace="nowrap" align="center">
                <Text mr={3}>{show ? value : '**********'}</Text>
                <Button variant="blue" size="xs" onClick={handleButtonClick}>
                    {show ? 'Hide' : 'Show'}
                </Button>
            </Flex>
        )

    return (
        <InputGroup size="md" width={150}>
            <Input
                width={150}
                pr={58}
                size="sm"
                type={show ? 'text' : 'password'}
                value={value}
                onChange={onInputChange}
                onBlur={onInputBlur}
            />
            <InputRightElement width={70}>
                <Button
                    mr={-5}
                    mt={-2}
                    variant="blue"
                    size="xs"
                    onClick={handleButtonClick}>
                    {show ? 'Hide' : 'Show'}
                </Button>
            </InputRightElement>
        </InputGroup>
    )
}

export default PasswordCell
