import { Dispatch, FC, useState } from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useColorMode } from '@chakra-ui/react'
import { MultiValue, SingleValue } from 'react-select'
import { CustomSelect } from 'components/baseComponents/Select'
import { ISelectOption } from 'types/core'
import { performanceByTotalOrdersReportStore } from 'stores'
import { reactSelectStyles } from 'components/baseComponents/Select/styles'

interface ISelectProps {
    onChange: Dispatch<string>
}

export const CustomSelectContainer: FC<ISelectProps> = observer(
    ({ onChange }) => {
        const [selectValue, setSelectValue] =
            useState<SingleValue<ISelectOption>>(null)
        const performanceShippers = toJS(
            performanceByTotalOrdersReportStore?.preparedLabels
        )
        const { colorMode } = useColorMode()

        const handleChange = (
            selectedValue:
                | SingleValue<ISelectOption>
                | MultiValue<ISelectOption>
        ) => {
            if (
                selectedValue &&
                'value' in selectedValue &&
                selectedValue.value
            ) {
                const value =
                    selectedValue && selectedValue.value
                        ? selectedValue.value
                        : ''
                setSelectValue(selectedValue)
                onChange(value)
            }
        }

        return (
            <CustomSelect
                selectValue={selectValue}
                options={performanceShippers}
                placeholder="Select Source"
                onChange={handleChange}
                customStyles={reactSelectStyles(colorMode)}
            />
        )
    }
)
