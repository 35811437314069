import { tenantsInstance } from './instances'
import { PagingFilters, ResourceOwnerAuthResponse } from '../types/api'
import { ResourceOwnerAuth } from '../types/core'

export class resourceOwnersAuthApi {
    static async getResourceOwnersAuth(
        tenantId: string,
        { page, size }: PagingFilters
    ): Promise<ResourceOwnerAuthResponse> {
        const response = await tenantsInstance.get<ResourceOwnerAuthResponse>(
            `/${tenantId}/auth/resource-owners?page=${page}&size=${size}`
        )

        return response.data
    }

    static async createResourceOwnerAuth(
        tenantId: string,
        data: ResourceOwnerAuth
    ): Promise<ResourceOwnerAuth> {
        const response = await tenantsInstance.post<ResourceOwnerAuth>(
            `/${tenantId}/auth/resource-owners`,
            {
                name: data.name,
                clientId: data.clientId,
                clientSecret: data.clientSecret,
                username: data.username,
                password: data.password,
            }
        )

        return response.data || {}
    }

    static async updateResourceOwnerAuth(data: ResourceOwnerAuth) {
        const response = await tenantsInstance.put(
            `/${data.tenantId}/auth/resource-owners/${data.id}`,
            {
                name: data.name,
                clientId: data.clientId,
                clientSecret: data.clientSecret,
                username: data.username,
                password: data.password,
            }
        )

        return response.data || {}
    }

    static async removeResourceOwnerAuth(data: ResourceOwnerAuth) {
        const response = await tenantsInstance.delete(
            `/${data.tenantId}/auth/resource-owners/${data.id}`
        )

        return response.data || {}
    }
}
