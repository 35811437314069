import React, { FC } from 'react'
import { Flex } from '@chakra-ui/layout'
import { truncate } from 'lodash'
import { renderLabelRow } from 'components/TooltipWithButton/renderLabelRow'

interface ITooltipWithButton {
    value?: string
    cutLength: number
    selectedValues?: string[]
    onCloseTooltip: () => void
}

export const TooltipWithButton: FC<ITooltipWithButton> = ({
    children,
    value,
    cutLength,
    selectedValues,
    onCloseTooltip,
}) => {
    return (
        <Flex
            paddingTop="5px"
            flexDirection="column"
            onClick={e => e.stopPropagation()}
            onMouseLeave={onCloseTooltip}
        >
            {!!value && truncate(value, { length: cutLength })}
            {!!selectedValues && renderLabelRow(selectedValues.slice(0, 5), 40)}
            {((selectedValues && selectedValues.length > 5) || (value && value.length > 40 * 5)) && children}
        </Flex>
    )
}
