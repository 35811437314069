import { StylesConfig } from 'react-select'
import { ISelectOption } from 'types/core'
import { getThemeColor } from 'utils/getThemeColor'
import { colors } from 'theme/colors'
import { pseudoSelectors } from 'components/styles'

interface IMultiSelectStyles {
    theme: string
    isSelectRule: boolean
    isDisabled?: boolean
    isDraft?: boolean
    isChannels?: boolean
}

export const multiSelectStyles = ({
    theme,
    isSelectRule,
    isDisabled,
    isChannels,
    isDraft,
} : IMultiSelectStyles): StylesConfig<ISelectOption> => ({
    container: styles => ({
        ...styles,
        boxSizing: 'border-box',
        marginRight: '16px',
        width: isChannels ? '400px' : '310px',
        maxHeight: '26px',
    }),
    dropdownIndicator: (styles, state) => ({
        ...styles,
        transition: 'all .2s ease',
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
        'svg': {
            color: 'white',
        },
    }),
    valueContainer: styles => ({
        ...styles,
        padding: '0 8px',
        maxHeight: '13px',
        alignContent: 'center',
    }),
    indicatorsContainer: styles => ({
        ...styles,
        '& > div': {
            padding: '0 8px',
        },
    }),
    menuList: styles => ({
        ...styles,
        backgroundColor: getThemeColor(theme, 'blueWhite.950'),
        borderRadius: '0px 0px 6px 6px',
        padding: 0,
    }),
    menu: styles => ({
        ...styles,
        borderRadius: '0px 0px 6px 6px',
        marginTop: 0,
        height: isChannels ? '10px' : 'auto',
        backgroundColor: getThemeColor(theme,'blueWhite.950'),
    }),
    placeholder: styles => ({
        ...styles,
        color: colors.gray['100'],
        fontSize: '12px',
        lineHeight: '14px',
    }),
    control: (styles, state) => ({
        ...styles,
        backgroundColor: colors.blue['990'],
        borderRadius: state.selectProps.menuIsOpen ? '8px 8px 0 0' : '8px',
        color: 'white',
        minHeight: isSelectRule ? '40px' : '26px',
        maxHeight: isSelectRule ? '40px' : '26px',
        boxShadow: 'none',
        border: 'none',
    }),
    input: styles => ({
        ...styles,
        color: 'white',
        fontSize: '12px',
        lineHeight: '14px',
    }),
    singleValue: styles => ({
        ...styles,
        color: 'white',
        fontSize: '12px',
        lineHeight: '14px',
    }),
    option: (styles) => ({
        ...styles,
        ...pseudoSelectors('backgroundColor', getThemeColor(theme, 'blueWhite.995')),
        backgroundColor: getThemeColor(theme, 'blueWhite.950'),
        color: getThemeColor(theme, 'color.100'),
        fontSize: '12px',
        lineHeight: '14px',
    }),
})
