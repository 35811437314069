import React, { Dispatch, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { MultiValue, SingleValue } from 'react-select'
import { useColorMode } from '@chakra-ui/react'
import { toJS } from 'mobx'
import { CustomSelect } from 'components/baseComponents/Select'
import { ISelectOption } from 'types/core'
import { customeAttributeStore } from 'stores'
import { reactSelectStyles } from './styles'

interface ICustomSelectContainer {
    value: SingleValue<ISelectOption>
    setValue: Dispatch<SingleValue<ISelectOption>>
}

export const CustomSelectContainer: FC<ICustomSelectContainer> = observer(
    ({ value, setValue }) => {
        const { colorMode } = useColorMode()
        const selectSourceName = toJS(customeAttributeStore.categorySoucesList)

        const handleChange = (
            selectedValue:
                | SingleValue<ISelectOption>
                | MultiValue<ISelectOption>
        ) => {
            if (
                selectedValue &&
                'value' in selectedValue &&
                selectedValue.value
            ) {
                setValue(selectedValue)
            } else {
                setValue(null)
            }
        }

        return (
            <CustomSelect
                selectValue={value}
                options={selectSourceName}
                placeholder="Choose Source Name.."
                onChange={handleChange}
                customStyles={reactSelectStyles(colorMode)}
                isClearable
            />
        )
    }
)
