import React, { ChangeEvent, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import {
    Box,
    Input,
    Select,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Button,
    useDisclosure,
} from '@chakra-ui/react'
import { mappingStore, notificationStore, tenantsStore } from 'stores'
import { defaultFilters } from 'stores/MappingStore'
import { useColorMode } from '@chakra-ui/system'
import { JsonComponent } from './JsonComponents/JsonComponent'
import { JsonEditorModal } from './JsonComponents/ModalJson'
import { MinusIcon } from 'icons'
import { ConfirmModal } from 'components/Modal/ConfirmModal'
import {
    MappingJsonGlobal,
    MappingJsonBox,
    MappingAccordingTitle,
    MappingTitle,
    MappingBox,
    MappingJsonGlobalBox,
    TitleBox,
} from './styles'

interface IMappingJson {
    mappingId: string
    onCloseMappingComponent: () => void
    onDeleteMapping: (id: string) => void
}

export const MappingJson = observer(
    ({ mappingId, onCloseMappingComponent, onDeleteMapping }: IMappingJson) => {
        const [statusCode, setStatusCode] = useState('')
        const [valueName, setValueName] = useState('')
        const { isOpen, onOpen, onClose } = useDisclosure()
        const { tenantId } = tenantsStore
        const [id] = useState(tenantId)
        const theme = useColorMode()
        const data = toJS(mappingStore.data)
        const openModal = toJS(mappingStore.openModals)

        useEffect(() => {
            if (id !== tenantId) {
                onCloseMappingComponent()
            }
            // Need for improvement. Warnings console
            /* eslint-disable */
        }, [tenantId])

        useEffect(() => {
            if (data?.name !== undefined) {
                setValueName(data?.name)
            }
        }, [data?.name])

        useEffect(() => {
            mappingStore.getMappingByID(tenantId, mappingId)
        }, [mappingId, tenantId])

        useEffect(() => {
            mappingStore.getMappingsMetadata()
            return () => {
                mappingStore.cleanData()
            }
        }, [])

        const handleToggleModal = (type: string, param: string) => {
            mappingStore.changeOpenModal(type, param)
        }

        const handleChangeJson = (
            value: object,
            type: string,
            param: string,
        ) => {
            mappingStore.updateDataJson(value, type, param)
        }

        const handleChangeJsonCopy = (
            value: object,
            type: string,
            param: string,
        ) => {
            mappingStore.updateCopyDataJson(value, type, param)
        }

        const handleChangeName = (value: ChangeEvent<HTMLInputElement>) => {
            setValueName(value.target.value)
        }

        const handleChangeType = (
            value: string,
            type: string,
            param: string,
        ) => {
            if (value === 'Forward') handleChangeJson({}, type, param)
            mappingStore.updateDataType(value, type, param)
        }

        const handleChangeTypeCopy = (
            value: string,
            type: string,
            param: string,
        ) => {
            if (value === 'Forward') handleChangeJsonCopy({}, type, param)
            mappingStore.updateCopyDataType(value, type, param)
        }

        const handleCreateMapping = () => {
            mappingStore
                .createMapping(tenantId, { ...data, name: valueName })
                .then(() => {
                    onCloseMappingComponent()
                    mappingStore.setFilters(defaultFilters)
                })
                .catch(error => {
                    notificationStore.triggerErrorToast(error)
                })
        }

        const handleEditMapping = () => {
            mappingStore
                .putMapping(tenantId, mappingId, { ...data, name: valueName })
                .then(() => {
                    onCloseMappingComponent()
                })
                .catch(error => {
                    notificationStore.triggerErrorToast(error)
                })
        }

        const handleStatusCode = (value: ChangeEvent<HTMLInputElement>) => {
            setStatusCode(value.target.value)
        }

        const handleAddField = () => {
            mappingStore.addResField(statusCode)
        }

        const handleDelField = (item: string) => {
            mappingStore.delResField(item)
        }

        const disabledStatusCodeBtn = +statusCode < 200 || +statusCode > 599

        return (
            <>
                {data && (
                    <MappingJsonGlobal>
                        <MappingJsonGlobalBox>
                            <Box>{mappingId ? 'Edit' : 'Create'} Mapping:</Box>
                            <Box>
                                <Button
                                    marginTop='10px'
                                    marginRight='10px'
                                    variant="blue"
                                    size='sm'
                                    onClick={onOpen}
                                >
                                    Delete
                                </Button>
                                <Button
                                    marginTop='10px'
                                    variant="blue"
                                    size='sm'
                                    onClick={onCloseMappingComponent}
                                >
                                    Close
                                </Button>
                            </Box>
                            <ConfirmModal
                                isOpen={isOpen}
                                onClose={onClose}
                                confirmFunc={() => onDeleteMapping(mappingId)}
                                headerText='Delete Mapping'
                                bodyText='Are you sure?'
                                buttonText='Delete'
                                colorButton='red.500'
                            />
                        </MappingJsonGlobalBox>
                        <Input
                            placeholder='Enter Status Code'
                            type='number'
                            value={statusCode}
                            onChange={(value: ChangeEvent<HTMLInputElement>) =>
                                handleStatusCode(value)
                            }
                        />
                        <Button
                            marginTop='10px'
                            variant="blue"
                            size='sm'
                            onClick={handleAddField}
                            disabled={disabledStatusCodeBtn}
                        >
                            Add Responses Status Code
                        </Button>
                        <Box>Name:</Box>
                        <Input value={valueName} onChange={handleChangeName} />
                        <MappingJsonBox>
                            <MappingTitle>Request:</MappingTitle>
                            <Accordion allowToggle width='100%'>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box flex='1' textAlign='left'>
                                                Default
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <MappingBox>
                                            <Select
                                                value={
                                                    data?.requestMappingScheme
                                                        ?.type
                                                }
                                                onChange={value => {
                                                    handleChangeType(
                                                        value.target.value,
                                                        'req',
                                                        'default',
                                                    )
                                                    handleChangeTypeCopy(
                                                        value.target.value,
                                                        'req',
                                                        'default',
                                                    )
                                                }
                                                }
                                            >
                                                <option value='Mapper'>
                                                    Mapper
                                                </option>
                                                <option value='Forward'>
                                                    Forward
                                                </option>
                                            </Select>
                                            <Button
                                                padding='20px 30px'
                                                variant="blue"
                                                onClick={() =>
                                                    handleToggleModal(
                                                        'req',
                                                        'default',
                                                    )
                                                }
                                                disabled={data?.requestMappingScheme?.type === 'Forward'}
                                            >
                                                View Scheme Object
                                            </Button>

                                            <JsonEditorModal
                                                item={'req'}
                                                data={
                                                    data?.requestMappingScheme
                                                        ?.schemeObject
                                                }
                                                handleSave={(value: object) =>
                                                    handleChangeJson(
                                                        value,
                                                        'req',
                                                        'default',
                                                    )
                                                }
                                                handleSaveCopy={(value: object) =>
                                                    handleChangeJsonCopy(
                                                        value,
                                                        'req',
                                                        'default',
                                                    )
                                                }
                                                openModal={
                                                    openModal.req.default
                                                }
                                                handleToggleModal={() =>
                                                    handleToggleModal(
                                                        'req',
                                                        'default',
                                                    )
                                                }
                                                theme={theme.colorMode}
                                            />
                                        </MappingBox>
                                        <JsonComponent
                                            disabled={data?.requestMappingScheme?.type === 'Forward'}
                                            data={
                                                data.requestMappingScheme
                                                    ?.schemeObject
                                            }
                                            handleSave={(value: object) =>
                                                handleChangeJson(
                                                    value,
                                                    'req',
                                                    'default',
                                                )
                                            }
                                            handleSaveCopy={(value: object) => {
                                                handleChangeJsonCopy(
                                                    value,
                                                    'req',
                                                    'default',
                                                )
                                            }

                                            }
                                            type='req'
                                            param='default'
                                            theme={theme.colorMode}
                                        />
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                        </MappingJsonBox>

                        <MappingJsonBox>
                            <MappingTitle>Responses:</MappingTitle>
                            <Accordion allowToggle width='100%'>
                                {data.responseMappingSchemes &&
                                    Object.keys(
                                        data.responseMappingSchemes,
                                    ).map(item => (
                                        <AccordionItem key={item}>
                                            <MappingAccordingTitle>
                                                <AccordionButton>
                                                    <TitleBox>{item}</TitleBox>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                                {item !== 'default' && (
                                                    <Button
                                                        variant="blue"
                                                        size='sm'
                                                        paddingRight='10px'
                                                        onClick={() =>
                                                            handleDelField(item)
                                                        }
                                                    >
                                                        <MinusIcon
                                                            w={3}
                                                            h={3}
                                                            color='color.600'
                                                        />
                                                    </Button>
                                                )}
                                            </MappingAccordingTitle>

                                            <AccordionPanel pb={4}>
                                                <MappingBox>
                                                    <Select
                                                        value={
                                                            data
                                                                ?.responseMappingSchemes[
                                                                item
                                                                ].type
                                                        }
                                                        onChange={value => {
                                                            handleChangeType(
                                                                value.target.value,
                                                                'res',
                                                                item,
                                                            )
                                                            handleChangeTypeCopy(
                                                                value.target.value,
                                                                'res',
                                                                item,
                                                            )
                                                        }
                                                        }
                                                    >
                                                        <option value='Mapper'>
                                                            Mapper
                                                        </option>
                                                        <option value='Forward'>
                                                            Forward
                                                        </option>
                                                    </Select>
                                                    <Button
                                                        disabled={data?.responseMappingSchemes[item].type === 'Forward'}
                                                        padding='20px 35px'
                                                        variant="blue"
                                                        onClick={() =>
                                                            handleToggleModal(
                                                                'res',
                                                                item,
                                                            )
                                                        }
                                                    >
                                                        View Scheme Object
                                                    </Button>

                                                    <JsonEditorModal
                                                        key={item}
                                                        item={item}
                                                        data={
                                                            data
                                                                ?.responseMappingSchemes[
                                                                item
                                                                ].schemeObject
                                                        }
                                                        handleSave={(
                                                            value: object,
                                                        ) =>
                                                            handleChangeJson(
                                                                value,
                                                                'res',
                                                                item,
                                                            )
                                                        }
                                                        handleSaveCopy={(value: object) =>
                                                            handleChangeJsonCopy(
                                                                value,
                                                                'res',
                                                                item,
                                                            )
                                                        }
                                                        openModal={
                                                            openModal.res[item]
                                                        }
                                                        handleToggleModal={() =>
                                                            handleToggleModal(
                                                                'res',
                                                                item,
                                                            )
                                                        }
                                                        theme={theme.colorMode}
                                                    />
                                                </MappingBox>
                                                <JsonComponent
                                                    disabled={data?.responseMappingSchemes[item].type === 'Forward'}
                                                    type='res'
                                                    param={item}
                                                    data={data?.responseMappingSchemes[item].schemeObject}
                                                    theme={theme.colorMode}
                                                    handleSave={(
                                                        value: object,
                                                    ) =>
                                                        handleChangeJson(
                                                            value,
                                                            'res',
                                                            item,
                                                        )
                                                    }
                                                    handleSaveCopy={(value: object) =>
                                                        handleChangeJsonCopy(
                                                            value,
                                                            'res',
                                                            item,
                                                        )
                                                    }
                                                />
                                            </AccordionPanel>
                                        </AccordionItem>
                                    ))}
                            </Accordion>
                        </MappingJsonBox>
                        <Button
                            marginTop='10px'
                            variant="blue"
                            size='sm'
                            disabled={!valueName}
                            onClick={
                                mappingId
                                    ? handleEditMapping
                                    : handleCreateMapping
                            }
                        >
                            {mappingId ? 'Change Mapping' : 'Send Mapping'}
                        </Button>
                    </MappingJsonGlobal>
                )}
            </>
        )
    },
)
