import { Box, Flex, Text } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/react'
import { chakra } from 'utils/chakra'

export const ClearBox = chakra(Flex, {
    cursor: 'pointer',
    alignItems: 'center',
    padding: '4px 8px',
    borderRadius: '6px',
    _hover: {
        backgroundColor: 'gray.600'
    }
})

export const ClearText = chakra(Text, {
    variant: 'h6',
})

export const TitleBox = chakra(Flex, {
    justifyContent: 'space-between',
})

export const Title = chakra(Text, {
    variant: 'h2',
    color: 'color.200',
})

export const FiltersBox = chakra(Flex, {
    justifyContent: 'space-between',
    margin: '24px 0 21px 0',
})

export const BoxStyled = chakra(Box, {
    borderRadius: '16px',
    padding: '24px',
    backgroundColor: 'blueWhite.900',
    boxShadow: '0px 6px 26px rgba(16, 30, 115, 0.06)',
})

export const RuleSetButton = chakra(Button, {
    variant: 'blue',
    height: '40px',
})
