import { FC, useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button
} from '@chakra-ui/react'
import { ReasonInput, RequiredLabelMessage, ValidationMessageText } from './styles'

interface IRetryProps {
    isOpen: boolean
    onSubmit: (reason: string) => void
    onClose: () => void
}

export const RetryReasonModal: FC<IRetryProps> = observer(({
    isOpen,
    onSubmit,
    onClose
}) => {

    const [reason, setReason] = useState('')
    const [reasonChecker, setReasonChecker] = useState<boolean>(false)

    const handleClick = () => {
        if (reason.trim()) {
            setReasonChecker(false)
            onSubmit(reason)
            setReason('')
            onClose()
        } else {
            setReasonChecker(true)
        }
    }

    const handleClose = () => {
        setReason('')
        onClose()
    }

    return(
        <Modal
            onClose={handleClose}
            isOpen={isOpen}
            scrollBehavior="inside"
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    Reason for Retrying
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <RequiredLabelMessage>
                        ENTER THE REASON* (Required)
                    </RequiredLabelMessage>
                    <ReasonInput
                        placeholder="Enter the reason"
                        value={reason}
                        onChange={e => {
                            setReason(e.currentTarget.value)
                            setReasonChecker(false)
                        }}
                        borderColor={reasonChecker ? 'red' : 'color.875'}
                    />
                </ModalBody>
                {reasonChecker ? (
                    <ValidationMessageText>
                        This field cannot be blank. Please enter the reason.
                    </ValidationMessageText>
                ) : null}
                <ModalFooter>
                    <Button variant="blue" onClick={handleClick}>
                        Retry
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
)})
