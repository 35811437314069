import { notificationStore } from 'stores'
import { OptionData } from 'components/baseComponents/Selects'
import { ParameterMetadata, UUID } from 'types/core'

export const addNumber = (
    value: OptionData[] = [],
    id: UUID,
    selctedRule: string
) => {
    return value.map(({ value, label }) => {
        if (value === '*' || label.includes(value)) {
            return {
                value,
                label,
                parameterId: id,
            }
        } else if (ruleType(selctedRule)) {
            return {
                value,
                label: `${label}`,
                parameterId: id,
            }
        } else {
            return {
                value,
                label: `${label} (${value})`,
                parameterId: id,
            }
        }
    })
}

export const displayCsvStatus = (nodeParameters: ParameterMetadata[]) => {
    if (!!nodeParameters.length) {
        const multiSelectBrowseStatus: string =
            nodeParameters[0]?.displayOptions?.displayCsvImport
        if (multiSelectBrowseStatus) {
            try {
                const multiSelectBrowseStatusJson = JSON.parse(
                    multiSelectBrowseStatus
                )
                return multiSelectBrowseStatusJson
            } catch (err) {
                notificationStore.triggerErrorToast(err)
            }
        }
    }
    return false
}

export const ruleType = (selctedRule: string) => {
    return (
        selctedRule === 'Custom Attribute' ||
        selctedRule === 'ShipRoadieActivity' ||
        selctedRule === 'UPS shipment cost' ||
        selctedRule === 'ShipUpsActivity'
    )
}
