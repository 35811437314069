import { tenantsIdentityInstance, usersIdentityInstance, usersInstance } from './instances'
import { PagingFilters } from 'types/api'
import { UUID } from 'types/core'
import { CreateUserRequest, FilteredPagedRequest, IUser, PagedResponse, UpdateUserRequest, UserRole } from './models'

export class UsersApi {
    static async GetUsers({ page, size }: PagingFilters) {
        const response = await usersInstance.post(`?page=${page}&size=${size}`)
        return response.data || {}
    }

    static async get(userId: UUID): Promise<IUser> {
        const { data } = await usersIdentityInstance.get<IUser>(`${userId}`)
        return data
    }

    static async getPaged(tenantId: UUID, paging: FilteredPagedRequest): Promise<PagedResponse<IUser>> {
        const { data } = await tenantsIdentityInstance.get<PagedResponse<IUser>>(`${tenantId}/users`, {
            params: paging
        })
        return data
    }

    static async getUserRoles(tenantId: UUID, userId: UUID): Promise<UserRole[]> {
        const { data } = await tenantsIdentityInstance.get<UserRole[]>(`${tenantId}/users/${userId}/roles`)
        return data;
    }

    static async setUserRoles(tenantId: UUID, userId: UUID, roles: string[]): Promise<void> {
        await tenantsIdentityInstance.post(`${tenantId}/users/${userId}/roles`, roles)
    }

    static async create(tenantId: UUID, request: CreateUserRequest): Promise<IUser> {
        const { data } = await tenantsIdentityInstance.post<IUser>(`${tenantId}/users`, request)
        return data
    }

    public static async update(userId: UUID, request: UpdateUserRequest): Promise<void> {
        await usersIdentityInstance.patch<void>(`${userId}`, request)
    }

    


    // static async createTenantUser(tenantId: UUID, user: ICreateUser ) {
    //     const { data = {} } = await tenantsIdentityInstance.post(`${tenantId}/users`, user)
    //     return data
    // }

    

    // static async updateTenantUser(userId: UUID, user: IUpdateUser) {
    //     const { data = {} } = await usersIdentityInstance.patch(userId, user)
    //     return data
    // }

    static async activate(userId: UUID): Promise<void> {
        return await usersIdentityInstance.post(`${userId}/$activate`)
    }

    static async disable(userId: UUID): Promise<void> {
        return await usersIdentityInstance.post(`${userId}/$deactivate`)
    }
}
