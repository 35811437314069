import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const CalendarIcon: FC<IconProps> = props => {
    return (
        <Icon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M3.87364 0C3.32136 0 2.87364 0.447715 2.87364 1V3.0625H0.978261C0.437982 3.0625 0 3.51974 0 4.08378V14.9774C0 15.5414 0.437982 15.9987 0.978261 15.9987H14.0217C14.562 15.9987 15 15.5414 15 14.9774V4.08378C15 3.51974 14.562 3.0625 14.0217 3.0625H11.9735V1C11.9735 0.447715 11.5258 0 10.9735 0H10.5788C10.0265 0 9.5788 0.447715 9.5788 1V3.0625H5.26834V1C5.26834 0.447715 4.82063 0 4.26834 0H3.87364ZM1.95652 5.60505C1.95652 5.32891 2.18038 5.10505 2.45652 5.10505H12.5435C12.8196 5.10505 13.0435 5.32891 13.0435 5.60505V13.4561C13.0435 13.7323 12.8196 13.9561 12.5435 13.9561H2.45652C2.18038 13.9561 1.95652 13.7323 1.95652 13.4561V5.60505ZM4.5647 10.0781C4.02442 10.0781 3.58644 10.5161 3.58644 11.0564V11.1424C3.58644 11.6827 4.02442 12.1207 4.5647 12.1207C5.10498 12.1207 5.54296 11.6827 5.54296 11.1424V11.0564C5.54296 10.5161 5.10498 10.0781 4.5647 10.0781ZM6.52122 11.0564C6.52122 10.5161 6.9592 10.0781 7.49948 10.0781C8.03976 10.0781 8.47774 10.5161 8.47774 11.0564V11.1424C8.47774 11.6827 8.03976 12.1207 7.49948 12.1207C6.9592 12.1207 6.52122 11.6827 6.52122 11.1424V11.0564ZM9.45601 11.0564C9.45601 10.5161 9.89399 10.0781 10.4343 10.0781C10.9745 10.0781 11.4125 10.5161 11.4125 11.0564V11.1424C11.4125 11.6827 10.9745 12.1207 10.4343 12.1207C9.89399 12.1207 9.45601 11.6827 9.45601 11.1424V11.0564ZM3.58644 7.76569C3.58644 7.23926 4.0132 6.8125 4.53963 6.8125H4.58977C5.1162 6.8125 5.54296 7.23926 5.54296 7.76569C5.54296 8.29212 5.1162 8.71888 4.58977 8.71888H4.53963C4.0132 8.71888 3.58644 8.29212 3.58644 7.76569ZM7.47441 6.8125C6.94798 6.8125 6.52122 7.23926 6.52122 7.76569C6.52122 8.29212 6.94798 8.71888 7.47441 8.71888H7.52455C8.05099 8.71888 8.47774 8.29212 8.47774 7.76569C8.47774 7.23926 8.05098 6.8125 7.52455 6.8125H7.47441ZM10.4092 6.8125C9.88276 6.8125 9.45601 7.23926 9.45601 7.76569C9.45601 8.29212 9.88276 8.71888 10.4092 8.71888H10.4593C10.9858 8.71888 11.4125 8.29212 11.4125 7.76569C11.4125 7.23926 10.9858 6.8125 10.4593 6.8125H10.4092Z"
                fill="currentColor"
            />
        </Icon>
    )
}
