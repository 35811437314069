import React, { Dispatch, FC, useState } from 'react'
import { MultiValue, SingleValue } from 'react-select'
import { useColorMode } from '@chakra-ui/react'
import { CustomSelect } from 'components/baseComponents/Select'
import { timeRangeOptions } from 'pages/HomePage/mock'
import { ISelectOption } from 'types/core'
import { reactSelectStyles } from 'components/baseComponents/Select/styles'

interface SelectProps {
    onChange: Dispatch<string>
    placeholder?: string
}

export const CustomSelectContainer: FC<SelectProps> = ({
    onChange,
    placeholder = '',
}) => {
    const [selectValue, setSelectValue] = useState<SingleValue<ISelectOption>>(timeRangeOptions[1])
    const { colorMode } = useColorMode()

    const handleChange = (selectedValue: SingleValue<ISelectOption> | MultiValue<ISelectOption>) => {
        if (selectedValue && 'value' in selectedValue) {
            setSelectValue(selectedValue)
            onChange(selectedValue.value)
        }
    }

    return (
        <CustomSelect
            selectValue={selectValue}
            options={timeRangeOptions}
            placeholder={placeholder}
            onChange={handleChange}
            customStyles={reactSelectStyles(colorMode)}
        />
    )
}
