import { Input } from '@chakra-ui/input'
import { chakra } from 'utils/chakra'

export const SearchInput = chakra(Input, {
    height: '32px',
    border: '1px solid',
    borderColor: 'blue.75',
    backgroundColor: 'transparent',
    borderRadius: '8px',
    fontSize: '12px',
    lineHeight: '14px',
    color: 'color.100',
    paddingLeft: '16px',
    paddingRight: '70px',
    _placeholder: {
        color: 'searchInputText',
    },
    _focus: {
        borderColor: 'color.100',
    },
})
