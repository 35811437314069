import { Grid, GridItem, Flex, Text } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/react'
import { chakra } from 'utils/chakra'

export const GridContainer = chakra(Grid, {
    gridTemplateAreas: `"user filters"
            "table table"
            "items page"`,
    gridTemplateRows: '50px minmax(40px,auto) auto',
    gridTemplateColumns: '150px 1fr',
    columnGap: 5,
    marginTop: '16px',
    marginBottom: '8px',
    borderRadius: '16px',
    backgroundColor: 'color.900',
    boxShadow: '0px 6px 26px rgba(16, 30, 115, 0.06)',
    height: '100%',
    width: '100%',
})

export const GridUser = chakra(GridItem, {
    area: 'user',
    paddingTop: '30px',
    paddingLeft: '20px',
})

export const GridFilter = chakra(GridItem, {
    area: 'filters',
    paddingTop: '30px',
    paddingRight: '20px',
    marginLeft: '15%',
})

export const FlexFilter = chakra(Flex, {
    gap: '15px',
})

export const SubmitButton = chakra(Button, {
    fontSize: '14px',
    height: '26.5px',
})

export const GridTable = chakra(GridItem, {
    area: 'table',
    paddingTop: '32px',
})

export const GridItems = chakra(GridItem, {
    area: 'items',
    alignSelf: 'center',
    justifySelf: 'center',
    padding: '15px 0 15px 0',
})

export const GridPagination = chakra(GridItem, {
    area: 'page',
    alignSelf: 'center',
    justifySelf: 'flex-start',
    marginLeft: '25%',
    padding: '15px 0 15px 0',
})

export const TextUser = chakra(Text, {
    fontWeight: 'bolder',
    fontSize: '15px',
    marginTop: '-10px',
})

export const TextItems = chakra(Text, {
    justifySelf: 'flex-start',
})

export const TextRecords = chakra(Text, {
    textAlign: 'center',
    fontWeight: 'bold',
})

export const UserName= chakra(Text,{
    marginLeft: '18%',
})