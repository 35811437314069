import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const MinusIcon: FC<IconProps> = props => {
    return (
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 8 8"
            {...props}
        >
            <path
                d="M3.5 3.5H4.5V3.5H7.5V4.5H4.5V7.5V4.5H0.5V3.5H3.5Z"
                fill="currentColor"
            />
        </Icon>
    )
}
