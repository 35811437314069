import {
    CustomerBaseData,
    CustomerBaseDataSettings,
    CustomerShipper,
} from '../types/api'
import { customerShipperInstence } from './instances'

export class CustomerShipperApi {
    static async getShipmentProvider(
        tenantId: string,
    ): Promise<CustomerShipper[]> {
        const response = await customerShipperInstence.get<CustomerShipper[]>(`/tenants/${tenantId}/shipment-providers`)
        return response.data
    }

    static async postShipmentProvider(
        tenantId: string,
        data: CustomerBaseData,
    ): Promise<CustomerBaseData> {
        const response = await customerShipperInstence.post<CustomerBaseData>(`/tenants/${tenantId}/shipment-providers`, data)
        return response.data
    }

    static async putShipmentProvider(
        tenantId: string,
        shipmentProviderCode: string,
        data: CustomerBaseData,
    ): Promise<CustomerBaseData> {
        const response = await customerShipperInstence.put<CustomerBaseData>(
            `/tenants/${tenantId}/shipment-providers/${shipmentProviderCode}`,
            data
        )
        return response.data
    }

    static async putShipmentProviderSettings(
        tenantId: string,
        shipmentProviderCode: string,
        data: CustomerBaseDataSettings,
    ): Promise<CustomerBaseDataSettings> {
        const response = await customerShipperInstence.put<CustomerBaseDataSettings>(
            `/tenants/${tenantId}/shipment-providers/${shipmentProviderCode}/settings`,
            data
        )
        return response.data
    }

    static async putShipmentProviderActivate(
        tenantId: string,
        shipmentProviderCode: string,
    ): Promise<CustomerShipper> {
        const response = await customerShipperInstence.put<CustomerShipper>(
            `/tenants/${tenantId}/shipment-providers/${shipmentProviderCode}/$activate`
        )
        return response.data
    }

    static async putShipmentProviderDeactivate(
        tenantId: string,
        shipmentProviderCode: string,
    ): Promise<CustomerShipper> {
        const response = await customerShipperInstence.put<CustomerShipper>(
            `/tenants/${tenantId}/shipment-providers/${shipmentProviderCode}/$deactivate`
        )
        return response.data
    }

    static async postShipmentMethod(
        tenantId: string,
        shipmentProviderCode: string,
        data: CustomerBaseData,
    ): Promise<CustomerBaseData> {
        const response = await customerShipperInstence.post<CustomerBaseData>(
            `/tenants/${tenantId}/shipment-providers/${shipmentProviderCode}/methods`,
            data
        )
        return response.data
    }

    static async putShipmentMethod(
        tenantId: string,
        shipmentProviderCode: string,
        shipmentMethodCode: string,
        data: CustomerBaseData,
    ): Promise<CustomerBaseData> {
        const response = await customerShipperInstence.put<CustomerBaseData>(
            `/tenants/${tenantId}/shipment-providers/${shipmentProviderCode}/methods/${shipmentMethodCode}`,
            data
        )
        return response.data
    }

    static async putShipmentMethodSettings(
        tenantId: string,
        shipmentProviderCode: string,
        shipmentMethodCode: string,
        data: CustomerShipperApi,
    ): Promise<CustomerBaseDataSettings> {
        const response = await customerShipperInstence.put<CustomerBaseDataSettings>(
            `/tenants/${tenantId}/shipment-providers/${shipmentProviderCode}/methods/${shipmentMethodCode}/settings`,
            data
        )
        return response.data
    }

    static async putShipmentMethodActivate(
        tenantId: string,
        shipmentProviderCode: string,
        shipmentMethodCode: string,
    ): Promise<CustomerShipper> {
        const response = await customerShipperInstence.put<CustomerShipper>(
            `/tenants/${tenantId}/shipment-providers/${shipmentProviderCode}/methods/${shipmentMethodCode}/$activate`
        )
        return response.data
    }

    static async putShipmentMethodDeactivate(
        tenantId: string,
        shipmentProviderCode: string,
        shipmentMethodCode: string,
    ): Promise<CustomerShipper> {
        const response = await customerShipperInstence.put<CustomerShipper>(
            `/tenants/${tenantId}/shipment-providers/${shipmentProviderCode}/methods/${shipmentMethodCode}/$deactivate`
        )
        return response.data
    }
}
