import React, { useEffect } from 'react'
import { Link as DomLink, useParams } from 'react-router-dom'
import { CustomTabs } from './content/Tabs'
import { OrderInfo } from './content/OdrerInfo'
import { routes } from 'navigation/routes'
import { orderStore } from 'stores'
import { ArrowLeftIcon } from 'icons'
import {
    BackButtonText,
    OrderDetailsPageBox,
    BackButtonLink,
} from './styles'

export const OrderDetailsPage = () => {
    const { id } = useParams()

    useEffect(() => {
        if (id) orderStore.searchById(id)

        return () => {
            orderStore.clean()
        }
    }, [id])

    return (
        <OrderDetailsPageBox>
            <BackButtonLink to={routes.home} as={DomLink}>
                <ArrowLeftIcon boxSize="15px" color="blue.300" />
                <BackButtonText>Back to Dashboard</BackButtonText>
            </BackButtonLink>
            <OrderInfo />
            <CustomTabs />
        </OrderDetailsPageBox>
    )
}
