import { Flex, Text } from '@chakra-ui/layout'
import { Tooltip } from '@chakra-ui/react'
import { chakra } from 'utils/chakra'

export const TitleBox = chakra(Flex, {
    justifyContent: 'space-between',
})

export const ErrorsOnlyBox = chakra(Flex, {
    alignSelf: 'center',
    marginRight: '25px',
})

export const Title = chakra(Text, {
    variant: 'h2',
    color: 'color.200',
})

export const TitleIconsBox = chakra(Flex, {
    justifyContent: 'space-between',
    alignItems: 'center',
})

export const FiltersBox = chakra(Flex, {
    flexDirection: 'column',
})

export const TopFiltersBox = chakra(Flex, {
    justifyContent: 'space-between',
})

export const BottomFiltersBox = chakra(Flex, {
    marginBottom: '24px',
    justifyContent: 'space-between',
})

export const InputsBox = chakra(Flex, {
    alignItems: 'center',
    flexGrow: 1,
})

export const ShowFiltersButtonText = chakra(Text, {
    variant: 'h5Light',
    marginLeft: '10px',
})

export const SavedFiltersButtonText = chakra(Text, {
    variant: 'h5Light',
    marginLeft: '10px',
})

export const RetryButtonText = chakra(Text, {
    variant: 'h5',
    marginLeft: '10px',
})

export const ShowOrders = chakra(Text, {
    variant: 'paragraphsmall',
    marginRight: '16px',
})

export const FilteringBy = chakra(Text, {
    variant: 'paragraphsmall',
    margin: '0 8px 0 16px',
})

export const RetryTooltip = chakra(Tooltip, {
    position: 'relative',
    _after: {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: '50%',
        transform: 'translate(-50%, 100%)',
        width: '100%',
        height: '10px',
    },
})
