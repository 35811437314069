import { FC } from 'react'
import { Checkbox } from '@chakra-ui/react'

interface ICheckboxProps {
    orderID: string,
    toRetry: (orderID: string) => boolean,
    handleCheckChange: (orderID: string) => void
}

export const RetryChildCheckbox: FC<ICheckboxProps> = ({
    orderID,
    toRetry,
    handleCheckChange
}) => {
    const handleChange = () => {
        handleCheckChange(orderID)
    }

    return (
        <Checkbox
            isChecked={toRetry(orderID)}
            onChange={handleChange}
        >
        </Checkbox>
    )
}
