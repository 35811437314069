import { Box, Flex } from '@chakra-ui/layout'
import { Text } from '@chakra-ui/layout'
import { chakra } from 'utils/chakra'

export const CardContainer = chakra(Box, {
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)',
    borderRadius: '6px',
    width: '100%',
})

export const NameContainer = chakra(Flex, {
    backgroundColor: 'blueBlack.990',
    padding: '8px',
    justifyContent: 'space-between',
    borderRadius: '6px 6px 0px 0px',
})

export const ContentContainer = chakra(Box, {
    backgroundColor: 'blueWhite.950',
    padding: '8px',
    borderRadius: '0px 0px 6px 6px',
})

export const TitleContainer = chakra(Text, {
    color: 'white',
    variant: 'h5',
})

export const DescriptionContainer = chakra(Text, {
    color: 'color.100',
    variant: 'h6Light',
    marginBottom: '16px',
})
