import { startCase } from 'lodash'
import { IWorkflowEvents, OrderStatus, IWorkflowEventsKeys } from 'types/core'
import { IconStatus } from 'components/StatusTag/mock'

export const getWorkflowEventsValues = (workflowEvents: IWorkflowEvents[], key: IWorkflowEventsKeys) => {
    const values = workflowEvents.reduce(
        (acc: string[], workflowEvent) => {
            if (workflowEvent[key]) acc.push(workflowEvent[key])
            return acc
        }, []
    )

    return values.length ? values : '-'
}

export function mapOrderStatusToMessage(orderStatus: OrderStatus): string {
    switch (orderStatus) {
        case OrderStatus.PreProcessingComplete:
            return 'Preprocessing complete'
        case OrderStatus.ProcessingRule:
            return 'Processing rule'
        case OrderStatus.Created:
            return 'Order received'
        case OrderStatus.SentToECC:
            return 'Order sent to distribution source'
        case OrderStatus.AcknowledgedByECC:
            return 'Order acknowledged'
        case OrderStatus.Shipment:
            return 'Order shipped DOM'
        case OrderStatus.SentToIcep:
            return 'Order shipped Source Sys'
        case OrderStatus.Completed:
            return 'Order completed'
        case OrderStatus.Error:
            return 'Failure DOM'
        case OrderStatus.ErrorRuleProcessing:
            return 'Error Rule Processing'
        case OrderStatus.ErrorSentToECC:
            return 'Failure distribution source'
        case OrderStatus.ErrorSentToIcep:
            return 'Failure external source'
        case OrderStatus.Split:
            return 'Order split'
        case OrderStatus.SplitError:
            return 'Order split error'
        default:
            return startCase(orderStatus)
    }
}

export function mapOrderStatusToTagStatus(
    orderStatus: OrderStatus
): IconStatus {
    switch (orderStatus) {
        case OrderStatus.Completed:
            return 'success'
        case OrderStatus.Split:
            return 'split'
        case OrderStatus.SplitError:
            return 'orderSplitError'
        case OrderStatus.Error:
        case OrderStatus.ErrorSentToECC:
        case OrderStatus.ErrorSentToIcep:
            return 'error'
        case OrderStatus.Shipment:
        case OrderStatus.AcknowledgedByECC:
        case OrderStatus.Created:
        case OrderStatus.ProcessingRule:
        case OrderStatus.PreProcessingComplete:
        case OrderStatus.Shipping:
        case OrderStatus.Delivered:
        case OrderStatus.PostProcessingComplete:
        case OrderStatus.PartiallyDelivered:
        case OrderStatus.SentToECC:
        case OrderStatus.SentToIcep:
            return 'warning'
        case OrderStatus.CancelCompleted:
            return 'cancelCompleted'
        case OrderStatus.CancelRequested:
            return 'cancelRequested'
        case OrderStatus.CancelFailed:
            return 'cancelFailed'
        default:
            return 'error'
    }
}
