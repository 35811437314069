import React, { FC, useState } from 'react'
import { Box, Text } from '@chakra-ui/layout'
import { ITenantsAccessHierarchy } from 'types/core'
import SwitchControlTree from '../index'
import { SimpleArrowDownIcon, SimpleArrowUpIcon } from 'icons'
import { CollapseBox, NameContainer, RolesBox } from '../../styles'

interface ISwitchControlTreeChild {
    item: ITenantsAccessHierarchy
    onSelect: (e: React.MouseEvent, tenantId: string) => void
}

const SwitchControlTreeChild: FC<ISwitchControlTreeChild> = ({
    item,
    onSelect,
}) => {
    const [isOpen, setIsOpen] = useState(false)

    const isParent = !!item.children?.length
    const roles = item.roles.join(', ')

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation()
        setIsOpen(!isOpen)
    }
    return (
        <Box>
            <NameContainer>
                <Text
                    onClick={e => {
                        onSelect(e, item.tenantId)
                    }}
                    fontWeight={isParent ? 'bold' : 'normal'}
                    cursor="pointer"
                >
                    {item.tenantName}
                </Text>
                {isParent && (
                    <CollapseBox onClick={handleClick}>
                        {isOpen ? (
                            <SimpleArrowUpIcon />
                        ) : (
                            <SimpleArrowDownIcon />
                        )}
                    </CollapseBox>
                )}
            </NameContainer>
            <RolesBox>{roles}</RolesBox>
            {!!item.children && isOpen && (
                <SwitchControlTree
                    item={item}
                    tree={item.children}
                    onSelect={onSelect}
                />
            )}
        </Box>
    )
}

export default SwitchControlTreeChild
