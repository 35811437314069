import { ChangeEventHandler, useState, useEffect, FC, Dispatch } from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { Spacer, Text } from '@chakra-ui/layout'
import { useDebounce } from 'hooks'
import { InputSearch } from 'components/baseComponents/InputSearchCircle'
import { VerticalDotsIcon, CrossSmallIcon } from 'icons'
import { onTimeShippingReportStore } from 'stores'
import { IReportSearchList } from 'types/core'
import {
    NavBox,
    ReportItem,
    ReportList,
    SearchBox,
    ClearIconBox,
    AllReportBox,
    NavLink,
    TextBox,
} from './styles'

interface IReportItemProps {
    onChange: Dispatch<string>
    setItem: Dispatch<string>
}

export const ReportsNavBar: FC<IReportItemProps> = observer(
    ({ onChange, setItem }) => {
        const [localSearchValue, setLocalSearchValue] = useState('')
        const [id, setId] = useState('')
        const timeOut = 800
        const reportsItem = toJS(onTimeShippingReportStore?.reportListItems)

        const handleClear = () => {
            setLocalSearchValue('')
            onChange('')
        }

        useEffect(() => {
            onChange(localSearchValue)
        }, [localSearchValue, onChange])

        const updateSearchValue = useDebounce((newValue: string) => {
            onChange(newValue)
        }, timeOut)

        const handleChangeSearchValue: ChangeEventHandler<
            HTMLInputElement
        > = event => {
            const value = event.target.value
            setLocalSearchValue(value)
            updateSearchValue(value)
        }

        const selectReport = (report: IReportSearchList) => {
            setItem(report?.type)
            setId(report?.id)
        }

        return (
            <>
                <NavBox>
                    <AllReportBox>
                        <Text variant="h4">
                            All Reports({reportsItem.length})
                        </Text>
                    </AllReportBox>
                    <SearchBox>
                        <InputSearch
                            placeholder="Search"
                            searchValue={localSearchValue}
                            onChange={handleChangeSearchValue}
                        />
                        {!!localSearchValue && (
                            <ClearIconBox
                                aria-label="Bell Icon"
                                onClick={handleClear}
                                icon={
                                    <CrossSmallIcon
                                        boxSize="13px"
                                        color="color.100"
                                    />
                                }
                            />
                        )}
                    </SearchBox>
                    <ReportList>
                        {Array.isArray(reportsItem) && !!reportsItem.length ? (
                            reportsItem?.map((report, index) => (
                                <ReportItem
                                    key={report?.id}
                                    background={
                                        id === report?.id || (!id && !index)
                                            ? 'blueWhite.940'
                                            : 'color.900'
                                    }
                                    color={
                                        id === report?.id || (!id && !index)
                                            ? 'white'
                                            : 'color.100'
                                    }
                                    borderLeftRadius={
                                        id === report?.id || (!id && !index)
                                            ? '8px'
                                            : '0px'
                                    }
                                >
                                    <NavLink
                                        key={report?.id}
                                        color={
                                            id === report?.id || (!id && !index)
                                                ? 'white'
                                                : 'color.100'
                                        }
                                        onClick={e => selectReport(report)}
                                    >
                                        {report.name}
                                    </NavLink>
                                    <Spacer />
                                    <VerticalDotsIcon />
                                </ReportItem>
                            ))
                        ) : (
                            <TextBox>No reports match search criteria.</TextBox>
                        )}
                    </ReportList>
                </NavBox>
            </>
        )
    }
)
