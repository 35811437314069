import React, { FC } from 'react'
import { CollapseDate } from './CollapseDate'
import { CollapseCheckbox } from './CollapseCheckbox'
import { OrderFilters, IRenderOrderFilters } from 'types/core'

export const RenderFilters: FC<IRenderOrderFilters> = ({ filter, isEditFilters = false }) => {
    switch(filter.name) {
        case OrderFilters.Created:
        case OrderFilters.Delivery:
            return <CollapseDate isEditFilters={isEditFilters} filter={filter} />
        case OrderFilters.Status:
        case OrderFilters.DistributionSource:
        case OrderFilters.ExternalSource:
            return <CollapseCheckbox isEditFilters={isEditFilters} filter={filter} />
        default:
            return null
    }
}
