import React, { FC } from 'react'
import { truncate } from 'lodash'
import { Grid, Text } from '@chakra-ui/layout'
import { observer } from 'mobx-react-lite'
import { format } from 'date-fns'
import { toJS } from 'mobx'
import { RuleStatusTag } from 'components/RuleStatusTag'
import { TooltipTextCut } from 'components/TooltipTextCut'
import { renderWows } from './renderWows'
import { ShortWorkflow } from 'types/core'
import { workflowVersionsStore } from 'stores'
import { ItemBox, ItemCell } from '../styles'

interface IRuleVersionItem {
    workflowVersion: ShortWorkflow
}

export const RuleVersionItem: FC<IRuleVersionItem> = observer(({ workflowVersion }) => {
    const { id } = toJS(workflowVersionsStore.selectedWorkflow)
    const {
        description,
        status,
        modifiedAt,
        version,
        createdByUser,
        approvedByUser,
        wows,
    } = workflowVersion

    const onSelect = (): void => {
        workflowVersionsStore.setSelectedWorkflow(workflowVersion)
    }

    return (
        <ItemBox
            onClick={onSelect}
            backgroundColor={id === workflowVersion.id ? 'blue.125' : 'ruleVersioningHeader'}
            _hover={{
                backgroundColor: id === workflowVersion.id ? 'blue.125' :  'ruleVersioningHover',
            }}
        >
            <Grid
                width="100%"
                templateColumns="repeat(8, 1fr)"
            >
                <ItemCell>
                    <Text variant="h4Normal">{version}</Text>
                </ItemCell>
                <ItemCell>
                    <Text variant="linksmall">
                        {modifiedAt ? format(new Date(modifiedAt), 'LLL. dd, yyyy') : '-'}
                    </Text>
                </ItemCell>
                <ItemCell>
                    <Text variant="linksmall">
                        {modifiedAt ? format(new Date(modifiedAt), 'hh:mm:ss') : '-'}
                    </Text>
                </ItemCell>
                <ItemCell>
                    <TooltipTextCut maxTextLength={20} text={description}>
                        <Text variant="linksmall">
                            {description
                                ? truncate(description.toString(), { length: 20 })
                                : '-'
                            }
                        </Text>
                    </TooltipTextCut>
                </ItemCell>
                <ItemCell>
                    <Text variant="linksmall">
                        {createdByUser ? `${createdByUser.firstName} ${createdByUser.lastName}` : '-'}
                    </Text>
                </ItemCell>
                <ItemCell>
                    <Text variant="linksmall">
                        {approvedByUser ? `${approvedByUser.firstName} ${approvedByUser.lastName}` : '-'}
                    </Text>
                </ItemCell>
                <ItemCell>
                    <RuleStatusTag status={status} />
                </ItemCell>
                <ItemCell flexDirection="column">
                    {wows?.length
                        ? renderWows(wows, true, false)
                        : (
                            <Text variant="paragraphSmall500">
                                N/A
                            </Text>
                        )
                    }
                </ItemCell>
            </Grid>
        </ItemBox>
    )
})
