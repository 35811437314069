import React, { useState } from 'react'
import { Button, ButtonGroup } from '@chakra-ui/react'
import { ConfirmModal } from 'components/Modal/ConfirmModal'

interface IActionTableCell {
    onOpen: () => void
    onSaveData: (id: string) => void
    onDelete: (id: string) => void
    row: {
        values: IValues
    }
}

interface IValues {
    id: string
}

export const ActionTableCell = ({ row, onOpen, onSaveData, onDelete }: IActionTableCell) => {
    const [openModal, setOpenModal] = useState(false)

    const onOpenModal = () => setOpenModal(true)
    const onCloseModal = () => setOpenModal(false)

    return (
        <>
            <ConfirmModal
                isOpen={openModal}
                onClose={onCloseModal}
                confirmFunc={() => {
                    onDelete(row.values.id)
                    onCloseModal()
                }}
                headerText="Delete Mapping"
                bodyText="Are you sure?"
                buttonText="Delete"
                colorButton="red.500"
            />
            <ButtonGroup variant="outline">
                <Button
                    size="xs"
                    variant="ghost"
                    colorScheme="blue"
                    onClick={() => {
                        onOpen()
                        onSaveData(row.values.id)
                    }}
                >
                    Edit
                </Button>
                <Button
                    size="xs"
                    variant="ghost"
                    colorScheme="blue"
                    onClick={onOpenModal}
                >
                    Delete
                </Button>
            </ButtonGroup>
        </>
    )
}
