import React, { FC, useEffect, useMemo } from 'react'
import { Tab, TabList, TabPanel, TabPanels } from '@chakra-ui/react'
import { FormProvider, useForm } from 'react-hook-form'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import { ITenantTree } from 'types/core'
import { tenantsStore, userStore } from 'stores'
import { GeneralSettingsComponent } from '../GeneralSettings'
import { AzureAdditionalSettings } from '../GeneralSettings/AzureAD'
import RoutingRule from '../GeneralSettings/RoutingRule'
import RoutingMap from '../GeneralSettings/RoutingMap'
import { CustomerShipperPage } from '../GeneralSettings/CustomerShipper'
import { TenantsGroupMapping } from 'pages/AdminPages/Tenants/TenantsPage/TenantsGroupMapping'

interface ITabsComponent {
    handleTabsChange: (tabIndex: number) => void
}

export const FormComponent: FC<ITabsComponent> = observer(
    ({ handleTabsChange }) => {
        const selectedTenant = toJS(tenantsStore.selectedTenant)
        const isAzureAD = toJS(tenantsStore.isAzureAD)
        const { isTenantAdmin, isSuperAdmin } = toJS(userStore.currentRoles)
        const isAdmin = isTenantAdmin || isSuperAdmin

        const methods = useForm<ITenantTree>({
            mode: 'onChange',
            reValidateMode: 'onChange',
            defaultValues: useMemo(() => {
                return selectedTenant
            }, [selectedTenant]),
        })

        return (
            <FormProvider {...methods}>
                <TabPanels>
                    {isAdmin && (
                        <TabPanel>
                            <GeneralSettingsComponent />
                        </TabPanel>
                    )}
                    {isAdmin && (
                        <TabPanel>
                            <RoutingRule />
                        </TabPanel>
                    )}
                    {isAdmin && (
                        <TabPanel>
                            <RoutingMap />
                        </TabPanel>
                    )}
                     {isAdmin && (
                        <TabPanel>
                            <CustomerShipperPage />
                        </TabPanel>
                    )}
                    {isAzureAD && isAdmin && (
                        <TabPanel>
                            <AzureAdditionalSettings />
                        </TabPanel>
                    )}
                    {isAzureAD && (
                        <TabPanel>
                            <TenantsGroupMapping />
                        </TabPanel>
                    )}
                </TabPanels>
            </FormProvider>
        )
    }
)

export const TabsList = observer(() => {
    const selectedTenant = toJS(tenantsStore.selectedTenant)
    const isAzureAD = toJS(tenantsStore.isAzureAD)
    const { isTenantAdmin, isSuperAdmin } = toJS(userStore.currentRoles)
    const isAdmin = isTenantAdmin || isSuperAdmin

    const tabsPanelComponentsList = [
        { tabName: 'General', hidden: !isAdmin },
        { tabName: 'Routing Rule', hidden: !isAdmin },
        { tabName: 'Routing Map', hidden: !isAdmin },
        { tabName: 'Shipping Providers', hidden: !isAdmin },
        { tabName: 'Azure AD', hidden: !isAzureAD || !isAdmin },
        { tabName: 'Roles Mapping', hidden: !isAzureAD },
    ]

    useEffect(() => {
        return () => {
            tenantsStore.clearSelectedTenant()
        }
    }, [])

    return (
        <TabList marginLeft="16px">
            {!!selectedTenant?.id &&
                tabsPanelComponentsList
                    .filter(item => !item.hidden)
                    .map(item => (
                        <Tab key={item.tabName} marginRight="32px">
                            {item.tabName}
                        </Tab>
                    ))}
        </TabList>
    )
})
