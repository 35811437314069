import { Box, Flex, Text } from '@chakra-ui/layout'
import { PopoverContent } from '@chakra-ui/popover'
import { chakra } from 'utils/chakra'

export const CollapseBox = chakra(Box, {
    as: 'span',
    cursor: 'pointer',
})

export const Title = chakra(Box, {
    backgroundColor: '',
    padding: '8px',
    borderRadius: '6px 6px 0px 0px',
})

export const NameContainer = chakra(Flex, {
    justifyContent: 'space-between',
    alignItems: 'center',
})

export const RolesBox = chakra(Text, {
    fontSize: '12px',
    fontWeight: '400',
    color: 'color.450',
    marginBottom: '10px',
})

export const TextContainer = chakra(Text, {
    fontSize: '14px',
    fontWeight: '700',
    cursor: 'pointer',
    marginLeft: '12px',
    display: 'flex',
    alignItems: 'center',
})

export const SwitchControlContainer = chakra(Flex, {
    justifyContent: 'center',
    height: '30px',
    alignItems: 'center',
    borderLeft: '2px solid',
    borderRight: '2px solid ',
    borderColor: 'grayBlue.710',
    borderRadius: '1px',
    padding: '0 32px',
    margin: '0 32px',
})

export const PopoverContainer = chakra(PopoverContent, {
    overflow: 'auto',
    maxHeight: '400px',
    backgroundColor: 'color.900',
    sx: {
        '&::-webkit-scrollbar': {
            width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'scrollbar.75',
            borderRadius: '8px',
            minHeight: '56px',
        },
    },
})
