import React, { useCallback, useEffect, useState } from 'react'
import { Box, Flex, Heading, Spacer, Text } from '@chakra-ui/layout'
import { Center, Divider, useDisclosure } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '@chakra-ui/button'
import { observer } from 'mobx-react-lite'
import { ArrowRightIcon } from 'icons'
import { toJS } from 'mobx'
import { DiagramEditor } from 'components/diagrams/DiagramEditor'
import { EditorSidebar } from 'components/diagrams/EditorSidebar'
import { ComponentModal } from 'components/Modal/ComponentModal'
import { RulesSimulate } from 'components/RulesSimulate'
import { RuleHistory } from 'components/RuleHistory'
import { CanvasHeader } from 'components/CanvasHeader'
import { renderLabelRow } from 'components/TooltipWithButton/renderLabelRow'
import { editoreStore, progressStore, userStore } from 'stores'
import { routes } from 'navigation/routes'
import { useScreenshot } from 'hooks'
import { GridStyles, EditorBox, EditorSidebarBox } from './styles'

export const EditorPage = observer(() => {
    const { id } = useParams<'id'>()
    const navigate = useNavigate()
    const {
        isOpen: isSimulateOpen,
        onOpen: onSimulateOpen,
        onClose: onSimulateClose,
    } = useDisclosure()
    const {
        isOpen: isHistoryOpen,
        onOpen: onHistoryOpen,
        onClose: onHistoryClose,
    } = useDisclosure()
    const {
        isOpen: isOpenModal,
        onOpen: onOpenModal,
        onClose: onCloseModal,
    } = useDisclosure()
    const [loadingNextStep, setLoadingNextStep] = useState<boolean>(false)
    const { ref: screenshotRef, makeBlob } = useScreenshot()

    const elements = toJS(editoreStore.diagramStore.elements)
    const { isCreator } = toJS(userStore.currentRoles)
    const workflow = editoreStore.workflowStore.workflow
    const isOpenModalDetails = editoreStore.diagramStore.isOpenModalDetails
    const selectedValuesLabels = editoreStore.diagramStore.dataModal

    const makePreview = useCallback(async () => {
        const blob = await makeBlob()
        await editoreStore.addPreview(blob)
    }, [makeBlob])

    const onNextStep = useCallback(async () => {
        setLoadingNextStep(true)
        await makePreview()
        progressStore.startMoving()
        navigate(`${routes.approval}/${workflow?.id}`)
    }, [makePreview, navigate, workflow])

    const makePreviewForNew = useCallback(async () => {
        if (editoreStore.isRecentlyCreated) {
            await makePreview()
            editoreStore.toggleRecentlyCreated()
        } else if (editoreStore.isOpenned) {
            await makePreview()
        } else {
            editoreStore.openDiagram()
        }
    }, [makePreview])

    const openModalDetails = () => {
        onCloseModal()
        editoreStore.diagramStore.handleModalDetails(false)
    }

    useEffect(() => {
        if (isOpenModalDetails) onOpenModal()
        else onCloseModal()
    }, [isOpenModalDetails, onOpenModal, onCloseModal])

    useEffect(() => {
        progressStore.showStepProgress(2)
        if (id) editoreStore.init(id)
    }, [id])

    useEffect(() => {
        if (elements.length !== 0) makePreviewForNew()
    }, [elements.length, makePreviewForNew])

    useEffect(() => {
        return () => {
            progressStore.hideProgress()
            editoreStore.clean()
        }
    }, [])

    return (
        <Box height="92vh">
            <GridStyles>
                {isCreator && (
                    <EditorSidebarBox>
                        <EditorSidebar />
                    </EditorSidebarBox>
                )}
                <EditorBox>
                    <Box>
                        <Text variant="supertitle">Create a New Rule</Text>
                        <Flex marginBottom="8px">
                            <Heading variant="h2">Logic Canvas</Heading>
                            <Spacer />
                            <Flex direction="column">
                                <Spacer />
                                <Text variant="h3">Step 2 of 3</Text>
                            </Flex>
                        </Flex>
                    </Box>
                    <CanvasHeader>
                        {workflow?.name} - v{workflow?.version}
                    </CanvasHeader>
                    <Center
                        padding="0 30px"
                        backgroundColor="color.810"
                    >
                        <Divider variant="editor" />
                    </Center>
                    <DiagramEditor
                        backgroundColor="color.810"
                        ref={screenshotRef}
                    />
                </EditorBox>
                <Box gridArea="footer">
                    <Flex direction="row-reverse">
                        <Button
                            variant="blueFixed"
                            disabled={!isCreator || workflow?.status !== 'draft'}
                            onClick={onNextStep}
                            isLoading={loadingNextStep}
                            rightIcon={
                                <ArrowRightIcon
                                    boxSize="9px"
                                    color="white"
                                />
                            }
                        >
                            Next Step: Submit for Approval
                        </Button>
                        <Button
                            variant="blueFixed"
                            margin="0 47px"
                            disabled={!isCreator}
                            onClick={onSimulateOpen}
                        >
                            Order Simulate
                        </Button>
                        <Button
                            variant="blueFixed"
                            onClick={onHistoryOpen}
                        >
                            Version History
                        </Button>
                    </Flex>
                </Box>
            </GridStyles>
            <ComponentModal
                isOpen={isSimulateOpen}
                onClose={onSimulateClose}
                headerText="Rules Simulate"
                reactComponent={<RulesSimulate onClose={onSimulateClose} />}
            />
            <ComponentModal
                headerText="Rule History"
                isOpen={isHistoryOpen}
                onClose={onHistoryClose}
                reactComponent={<RuleHistory onHistoryClose={onHistoryClose} />}
            />
            <ComponentModal
                isOpen={isOpenModal}
                onClose={openModalDetails}
                headerText="Details"
                scrollBehavior="inside"
                size="xl"
                reactComponent={renderLabelRow(selectedValuesLabels, 50)}
            />
        </Box>
    )
})
