import React, { FC } from 'react'
import { Button, ButtonGroup } from '@chakra-ui/react'
import { CellProps } from 'react-table'

const TwoActionsCells: FC<CellProps<object>> = ({
    row,
    onEdit,
    openDeleteModal,
}): JSX.Element => (
    <ButtonGroup variant="outline">
        <Button
            size="xs"
            variant="ghost"
            colorScheme="blue"
            onClick={() => {
                onEdit(row.id)
            }}
        >
            Edit
        </Button>
        <Button
            size="xs"
            variant="ghost"
            colorScheme="blue"
            onClick={() => {
                openDeleteModal(row.id)
            }}
        >
            Delete
        </Button>
    </ButtonGroup>
)

export default TwoActionsCells
