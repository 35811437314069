import React from 'react'
import { observer } from 'mobx-react-lite'
import { Box } from '@chakra-ui/layout'
import { progressStore } from 'stores'
import { ProgressBarBox } from './styles'

export const ProgressBar = observer(() => {
    if (!progressStore.progress) return null

    return (
        <Box>
            <ProgressBarBox width={progressStore.progress + '%'} />
        </Box>
    )
})
