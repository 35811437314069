import { chakra } from 'utils/chakra'
import { Spinner } from '@chakra-ui/react'
import { Button } from '@chakra-ui/button'
import { Flex } from '@chakra-ui/layout'

export const SpinnerStyled = chakra(Spinner, {
    height: '200px',
    width: '200px',
})

export const ShowRuleSimulateButton = chakra(Button, {
    height: "34px",
    margin: "0 16px"
})

export const ShowRuleSimulateButtonBox = chakra(Flex, {
    flexDirection:"row-reverse"
})
