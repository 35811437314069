import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const CircleErrorIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 30 30"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g filter="url(#filter0_d_2045_17900)">
                <rect x="6" y="4" width="18" height="18" rx="9" fill="url(#paint0_linear_2045_17900)"/>
            </g>
            <defs>
                <filter id="filter0_d_2045_17900" x="0" y="0" width="30" height="30" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="2"/>
                    <feGaussianBlur stdDeviation="3"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2045_17900"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2045_17900" result="shape"/>
                </filter>
                <linearGradient id="paint0_linear_2045_17900" x1="15" y1="4" x2="15" y2="22" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF4365"/>
                    <stop offset="1" stopColor="#CE215F"/>
                </linearGradient>
            </defs>
        </Icon>
    )
}
