import { Flex, Text } from '@chakra-ui/layout'
import { DrawerBody } from '@chakra-ui/react'
import { ArrowBackIcon } from 'icons'
import { chakra } from 'utils/chakra'

export const DrawerBodyStyled = chakra(DrawerBody, {
    marginRight: '8px',
    sx: {
        '&::-webkit-scrollbar': {
            width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'scrollbar.75',
                borderRadius: '8px',
                maxHeight: '56px',
        }
    },
})

export const HeaderBox = chakra(Flex, {
    alignItems: 'center',
    marginTop: '15px',
})

export const FiltersBox = chakra(Flex, {
    flexDirection: 'column',
    border: '1px solid',
    borderColor: 'grayBlue.950',
    borderRadius: '8px',
    backgroundColor: 'blueWhite.950',
    marginTop: '20px',
    padding: '24px',
})

export const DetectedChangesText = chakra(Text, {
    variant: 'smalllink',
    color: 'red.500',
})

export const FiltersHeader = chakra(Flex, {
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '15px',
})

export const EditButtons = chakra(Flex, {
    margin: '32px 0',
    justifyContent: 'flex-end',
})

export const ArrowBackIconStyled = chakra(ArrowBackIcon, {
    boxSize: '14px',
    color: 'color.100',
    _hover: {
        color: 'black',
    }
})

export const ModalInputText = chakra(Text, {
    variant: 'h4LineHeight',
    textAlign: 'start',
})
