import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const RhombusWarningIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 35 34"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g filter="url(#filter0_d_2828_11944)">
                <rect x="6.70117" y="15" width="15.5563" height="15.5563" transform="rotate(-45 6.70117 15)" fill="url(#paint0_linear_2828_11944)"/>
            </g>
            <defs>
                <filter id="filter0_d_2828_11944" x="0.701172" y="0" width="34" height="34" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="2"/>
                    <feGaussianBlur stdDeviation="3"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2828_11944"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2828_11944" result="shape"/>
                </filter>
                <linearGradient id="paint0_linear_2828_11944" x1="14.4793" y1="15" x2="14.4793" y2="31.6675" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBB700"/>
                    <stop offset="1" stopColor="#FB8700"/>
                </linearGradient>
            </defs>

        </Icon>
    )
}
