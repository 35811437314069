import React, { ChangeEvent, FC, useState } from 'react'
import { Checkbox as ChakraCheckbox } from '@chakra-ui/react'
import { CheckboxTitle, CheckboxBox } from './styles'

interface CheckboxProps {
    readonly?: boolean
    isDraft?: boolean
    title?: string
    value?: string
    onChange: (newValue: string) => void
}

export const Checkbox: FC<CheckboxProps> = ({
    readonly = false,
    isDraft = false,
    title,
    value = 'false',
    onChange,
}) => {
    const [isChecked, setIsChecked] = useState(value === 'true')

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.checked.toString())
        setIsChecked(e.target.checked)
    }

    return (
        <CheckboxBox>
            <ChakraCheckbox
                disabled={!isDraft}
                isReadOnly={readonly}
                isChecked={isChecked}
                onChange={handleChange}
                margin="0 5px"
            />
            {!!title && <CheckboxTitle>{title}</CheckboxTitle>}
        </CheckboxBox>
    )
}
