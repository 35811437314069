import { Box } from '@chakra-ui/layout'
import { chakra } from 'utils/chakra'

export const CardContainer = chakra(Box, {
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)',
    borderRadius: '6px',
    width: '100%',
    backgroundColor: 'blueWhite.950',
})

export const NameContainer = chakra(Box, {
    backgroundColor: 'blueBlack.990',
    padding: '8px',
    borderRadius: '6px 6px 0px 0px',
})

export const ContentContainer = chakra(Box, {
    backgroundColor: 'blueWhite.950',
    borderRadius: '0px 0px 6px 6px',
})
