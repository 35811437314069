import React, { FC, ReactElement } from 'react'
import { AccordionButton, AccordionItem } from '@chakra-ui/accordion'
import { AccordionPanelStyled } from './styles'

interface CustomAccordionProps {
    AccordionButtonContent: ReactElement
    AccordionPanelContent: ReactElement
    width?: string
    margin?: string
}

export const CustomAccordion: FC<CustomAccordionProps> = ({
    AccordionButtonContent,
    AccordionPanelContent,
    width = '99%',
    margin = '0',
}) => {
    return (
        <AccordionItem margin={margin}>
            <AccordionButton padding="0">
                {AccordionButtonContent}
            </AccordionButton>
            <AccordionPanelStyled width={width}>
                {AccordionPanelContent}
            </AccordionPanelStyled>
        </AccordionItem>
    )
}
