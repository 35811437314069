export const simulateStatusPage = [
    'PreProcessingComplete',
    'ProcessingRule',
    'SentToECC',
    'AcknowledgedByECC',
    'Shipment',
    'SentToIcep',
    'PostProcessingComplete',
    'Shipping',
    'Delivered',
    'PartiallyDelivered',
    'Completed',
    'ProcessingInitError',
    'Error',
    'PreProcessingError',
    'RuleProcessingError',
    'CompletionError',
    'ValidateAddressError',
    'ErrorSentToECC',
    'ErrorAcknowledgedByECC',
    'ErrorShipment',
    'ErrorSentToIcep',
    'PostProcessingError',
    'Failed',
    'DeliveryError',
    'Split',
    'SplitError',
]
export const simulateStatus = [
    'SentToECC',
    'AcknowledgedByECC',
    'Shipment',
    'SentToIcep',
    'Shipping',
    'Delivered',
    'PartiallyDelivered',
    'Completed',
    'Error',
    'PreProcessingError',
    'RuleProcessingError',
    'CompletionError',
    'ValidateAddressError',
    'ErrorSentToECC',
    'ErrorAcknowledgedByECC',
    'ErrorShipment',
    'ErrorSentToIcep',
    'PostProcessingError',
    'Failed',
    'DeliveryError',
]
