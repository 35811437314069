import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const DashboardIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M19 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V19C0 19.2652 0.105357 19.5196 0.292893 19.7071C0.48043 19.8946 0.734784 20 1 20H19C19.2652 20 19.5196 19.8946 19.7071 19.7071C19.8946 19.5196 20 19.2652 20 19V1C20 0.734784 19.8946 0.48043 19.7071 0.292893C19.5196 0.105357 19.2652 0 19 0ZM6 18H2V8H6V18ZM12 18H8V8H12V18ZM18 18H14V8H18V18ZM18 6H2V2H18V6Z"
                fill="currentColor"
            />
        </Icon>
    )
}
