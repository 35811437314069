import { Center } from '@chakra-ui/layout'
import { chakra } from 'utils/chakra'

export const SplitBlockIcon = chakra(Center, {
    width: '18px',
    height: '18px',
    borderRadius: '50%',
    backgroundColor: 'bluebell.100',
    paddingRight: '1px',
})
