import { chakra } from 'utils/chakra'
import {
    Button,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    OrderedList,
} from '@chakra-ui/react'
import { Box, Text } from '@chakra-ui/layout'
import { CrossSmallIcon } from 'icons'

export const ModalHeaderStyled = chakra(ModalHeader, {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '32px',
    paddingBottom: '5px',
    paddingX: '32px',
})

export const ModalBodyStyled = chakra(ModalBody, {
    paddingX: '32px',
})

export const OrderedListStyled = chakra(OrderedList, {
    fontSize: '14px',
})

export const BorderStyled = chakra(Box, {
    border: '1px solid',
    borderColor: 'gray.600',
    borderRadius: '4px',
    padding: '2px 6px',
})

export const ModalHeaderText = chakra(Text, {
    variant: 'h2',
})

export const CrossSmallIconStyled = chakra(CrossSmallIcon, {
    alignSelf: 'end',
    role: 'button',
    cursor: 'pointer',
    boxSize: '16px',
    color: 'color.100',
})

export const ButtonStyled = chakra(Button, {
    variant: 'blueFixed',
    width: '64px',
    height: '32px',
    padding: '8px 12px',
})

export const ModalFooterStyled = chakra(ModalFooter, {
    paddingBottom: '30px',
    paddingX: '32px',
    paddingTop: '18px',
})

export const TextStyled = chakra(Text, {
    paddingBottom: '7px',
    fontSize: '16px',
})

export const ModalOverlayStyled = chakra(ModalOverlay, {
    background: 'modalOverlay',
})
