import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const ArrowRetryLeftIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M7.98532 1.30067e-05C5.84761 0.00385172 3.90671 0.846239 2.47374 2.21566L1.32165 1.06356C0.833904 0.575852 0 0.921271 0 1.61101V5.9355C0 6.36308 0.346613 6.70969 0.774194 6.70969H5.09868C5.78842 6.70969 6.13384 5.87579 5.64613 5.38805L4.29936 4.04127C5.29497 3.10905 6.58326 2.59266 7.95194 2.58085C10.9325 2.55511 13.4449 4.96721 13.4192 8.04672C13.3947 10.968 11.0263 13.4194 8 13.4194C6.67332 13.4194 5.41945 12.9459 4.43132 12.0789C4.27832 11.9446 4.04726 11.9528 3.90332 12.0967L2.6239 13.3761C2.46674 13.5333 2.47452 13.7895 2.63945 13.9385C4.058 15.2198 5.93787 16 8 16C12.4183 16 16 12.4183 16 8.00008C16 3.58688 12.3985 -0.00789022 7.98532 1.30067e-05Z"
                fill="currentColor"
            />
        </Icon>
    )
}
