import React, { useRef, FC, Dispatch } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDisclosure, useOutsideClick  } from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'
import { PreviewDialogModal } from 'components/diagrams/PreviewDialogModal'
import { RULES_APPROVER, RULES_CREATOR } from 'constants/userRoles'
import { workflowVersionsStore } from 'stores'
import { WorkflowStatus } from 'types/core'
import { checkRole } from 'utils/jwt'
import { routes } from 'navigation/routes'
import {
    SUBMIT_FOR_APPROVAL,
    APPROVE,
    REJECT,
    OBSOLETE,
    NEW_VERSION,
    MODIFY,
} from '../mock'
import {
    ArrowRightIcon,
    TrashCanCloseIcon,
    CheckIcon,
    ArrowRetryLeftIcon,
    ArrowRightUpIcon,
    EyeIcon,
    PencilIcon,
} from 'icons'
import { ActionsMenuBox, ViewText, ActionItemBox } from '../styles'

interface IActionsMenu {
    onMenuToggle: () => void
    setModalName: Dispatch<string>
    onActionModalOpen: () => void
    onHistoryClose: () => void
}

export const ActionsMenu: FC<IActionsMenu> = observer(({
    onMenuToggle,
    setModalName,
    onActionModalOpen,
    onHistoryClose,
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const ref = useRef(null)
    const navigate = useNavigate()
    useOutsideClick({
        ref: ref,
        handler: isOpen ? () => {} : onMenuToggle,
    })

    const { id, status, approvedByUser, name } = workflowVersionsStore.selectedWorkflow
    const isRulesCreator = checkRole(RULES_CREATOR)
    const isRulesApprover = checkRole(RULES_APPROVER)
    const isDraft = status === WorkflowStatus.Draft
    const isApproval = status === WorkflowStatus.Approval
    const isApproved = status === WorkflowStatus.Approved
    const isRejected = status === WorkflowStatus.Rejected
    const isObsoleted = status === WorkflowStatus.Obsoleted

    const onView = (): void => onOpen()

    const onSubmitForApproval = async (): Promise<void> => {
        setModalName(SUBMIT_FOR_APPROVAL)
        onActionModalOpen()
    }

    const onApprove = async (): Promise<void> => {
        setModalName(APPROVE)
        onActionModalOpen()
    }

    const onEdit = (): void => {
        if (isDraft) {
            navigate(`${routes.editor}/${id}`)
            onHistoryClose()
        } else {
            setModalName(MODIFY)
            onActionModalOpen()
        }
    }

    const onReject = async (): Promise<void> => {
        setModalName(REJECT)
        onActionModalOpen()
    }

    const onObsolete = async (): Promise<void> => {
        setModalName(OBSOLETE)
        onActionModalOpen()
    }

    const onNewVersion = async (): Promise<void> => {
        setModalName(NEW_VERSION)
        onActionModalOpen()
    }

    return (
        <ActionsMenuBox ref={ref}>
            <ActionItemBox>
                <EyeIcon boxSize="12px" alignSelf="center" />
                <ViewText
                    marginLeft="10px"
                    onClick={onView}
                >
                    View
                </ViewText>
            </ActionItemBox>
            {isRulesCreator && isDraft && !!approvedByUser && (
                <ActionItemBox>
                    <ArrowRightIcon boxSize="12px" alignSelf="center" />
                    <ViewText
                        marginLeft="10px"
                        onClick={onSubmitForApproval}
                    >
                        Submit
                    </ViewText>
                </ActionItemBox>
            )}
            {isRulesApprover && isApproval && (
                <ActionItemBox>
                    <CheckIcon boxSize="13px" alignSelf="center" />
                    <ViewText
                        marginLeft="9px"
                        onClick={onApprove}
                    >
                        Approve
                    </ViewText>
                </ActionItemBox>
            )}
            {isRulesCreator && (isApproval || isRejected) && (
                <ActionItemBox>
                    <PencilIcon boxSize="12px" alignSelf="center" />
                    <ViewText
                        marginLeft="9px"
                        onClick={onEdit}
                    >
                        {isDraft ? 'Edit' : 'Modify'}
                    </ViewText>
                </ActionItemBox>
            )}
            {((isRulesCreator && (isDraft || isApproval || isRejected)) || (isRulesApprover && isApproved)) && (
                <ActionItemBox>
                    <TrashCanCloseIcon boxSize="14px" alignSelf="center" />
                    <ViewText
                        marginLeft="8px"
                        onClick={onObsolete}
                    >
                        Make Obsolete
                    </ViewText>
                </ActionItemBox>
            )}
            {isRulesApprover && isApproval && (
                <ActionItemBox>
                    <ArrowRetryLeftIcon boxSize="12px" alignSelf="center" />
                    <ViewText
                        marginLeft="9px"
                        onClick={onReject}
                    >
                        Reject
                    </ViewText>
                </ActionItemBox>
            )}
            {isRulesCreator && (isApproved || isObsoleted) && (
                <ActionItemBox>
                    <ArrowRightUpIcon boxSize="12px" alignSelf="center" />
                    <ViewText
                        marginLeft="9px"
                        onClick={onNewVersion}
                    >
                        New Version
                    </ViewText>
                </ActionItemBox>
            )}
            <PreviewDialogModal
                isOpen={isOpen}
                onClose={onClose}
                viewId={id}
                title={name}
            />
        </ActionsMenuBox>
    )
})
