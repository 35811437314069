import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Box, Flex, Heading, Spacer, Text } from '@chakra-ui/layout'
import { useDisclosure } from '@chakra-ui/react'
import { toJS } from 'mobx'
import { ErrorsModal } from './content/ErrorsModal'
import { MoreStepsButton } from 'components/diagrams/EditorSidebar/content/MoreStepsButton'
import { routes } from 'navigation/routes'
import { KeyDetails } from './content/KeyDetails'
import { progressStore, submitForApprovalStore } from 'stores'

export const SubmitForApprovalPage = observer(() => {
    const { id } = useParams<'id'>()
    const [loading, setLoading] = useState(false)
    const workflowErrors = toJS(submitForApprovalStore.workflowErrors)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const navigate = useNavigate()

    const onApprove = async () => {
        try {
            setLoading(true)
            await submitForApprovalStore.submitForApproval()
            navigate(routes.home)
        } catch(err: unknown | any) {
            onOpen()
            submitForApprovalStore.setWorkflowErrors(err.response.data.errors)
        }
        setLoading(false)
        progressStore.showStepProgress(4)
    }

    useEffect(() => {
        progressStore.showStepProgress(3)

        return () => progressStore.hideProgress()
    }, [])

    useEffect(() => {
        if (id) {
            submitForApprovalStore.init(id)
        }

        return () => submitForApprovalStore.clean()
    }, [id])

    return (
        <Flex direction="row">
            <Box width="176px" margin="34px 14px 0 34px">
                <MoreStepsButton activeStep={3} />
            </Box>
            <Box flex="1" padding="31px 44px 44px 25px">
                <Text variant="supertitle">Create a New Rule</Text>
                <Flex>
                    <Heading variant="h2">
                        Review {'&'} Submit for Approval
                    </Heading>
                    <Spacer />
                    <Text variant="h3">Step 3 of 3</Text>
                </Flex>

                <Text marginTop="24px">
                    Your rule draft has been saved to your Dashboard and
                    Templates. Please review the details below:
                </Text>
                <Flex marginTop="32px">
                    <KeyDetails loading={loading} onApprove={onApprove} />
                </Flex>
            </Box>
            <ErrorsModal
                isOpen={isOpen}
                onClose={onClose}
                workflowErrors={workflowErrors}
            />
        </Flex>
    )
})
