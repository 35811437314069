import { Flex, VStack } from '@chakra-ui/react'
import { Text } from '@chakra-ui/layout'
import { chakra } from 'utils/chakra'

export const RuleApprovalsBox = chakra(Flex, {
    flexDirection: 'column',
    backgroundColor: 'color.900',
    padding: '24px',
    borderRadius: '16px',
    boxShadow: '0px 6px 26px rgba(16, 30, 115, 0.06)',
})

export const TitleRuleApprovals = chakra(Text, {
    color: 'color.100',
    variant: 'h2',
})

export const RuleApprovalsCardBox = chakra(VStack, {
    width: '100%',
    spacing: '24px',
    marginBottom: '32px',
})
