import { FC, useMemo } from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { Box } from '@chakra-ui/react'
import { Column } from 'react-table'
import ReportsTable from './reportsTable'
import {
    errorStatusReportColumns,
    onTimeShippingcolumns,
    performanceByTotalOdersColumns,
    productPerformanceBySalesReportColoumn,
    productPerformanceByTotalOrdersReport,
    shippingReportColoumns,
} from './columnMoc'
import {
    IPPSalesOverDuration,
    ReportType,
    IProductPerformanceItem,
} from 'types/core'
import {
    performanceByTotalOrdersReportStore,
    onTimeShippingReportStore,
    prodPerformanceBySalesStore,
    prodPerfByTotalOrdersReportStore,
    errorReportStore,
    shippingCostReportStore,
} from 'stores'
import { FlexBox, SourceName } from './styles'

interface IReportsDataprops {
    reportType: string
}

export const ReportsData: FC<IReportsDataprops> = observer(({ reportType }) => {
    const onmTimeShippingData = toJS(
        onTimeShippingReportStore?.onTimeShippingdataTabe
    )
    const data = toJS(
        performanceByTotalOrdersReportStore?.productPerformanceReports
    )
    const errorStatusReportData = toJS(errorReportStore?.errorReport)
    const totalOrderReportData = toJS(
        prodPerfByTotalOrdersReportStore?.totalOrdersReport
    )
    const salesReportData = toJS(
        prodPerformanceBySalesStore?.productPerformanceReports
    )
    const shippingCostReportItems = toJS(shippingCostReportStore?.shippingCostReportData)

    const columnOnTimeShipping: Column[] = useMemo(
        () => onTimeShippingcolumns,
        []
    )
    const columnPerformanceTotalOders: Column[] = useMemo(
        () => performanceByTotalOdersColumns,
        []
    )
    const columnProductPerformanceBySales: Column[] = useMemo(
        () => productPerformanceBySalesReportColoumn,
        []
    )
    const columnProductPerformanceByTotalOrders: Column[] = useMemo(
        () => productPerformanceByTotalOrdersReport,
        []
    )
    const columnErrorStatus: Column[] = useMemo(
        () => errorStatusReportColumns,
        []
    )

    const columnShippingCostReport: Column[] = useMemo(
        () => shippingReportColoumns,
        []
    )

    return (
        <FlexBox>
            {reportType === ReportType.performanceReceivedBySalesChannel &&
                !!data?.length &&
                data?.map((item: IProductPerformanceItem) => (
                    <Box key={item?.sourceName}>
                        <SourceName>{item?.sourceName}</SourceName>
                        <ReportsTable
                            data={item?.orderPerformance}
                            columns={columnPerformanceTotalOders}
                        />
                    </Box>
                ))}
            {reportType === ReportType.onTimeShipping && (
                <ReportsTable
                    data={onmTimeShippingData}
                    columns={columnOnTimeShipping}
                />
            )}
            {reportType === ReportType.performanceShippedByShippers && (
                <ReportsTable
                    data={totalOrderReportData}
                    columns={columnProductPerformanceByTotalOrders}
                />
            )}
            {reportType === ReportType.performanceSalesOverDuration &&
                !!salesReportData?.length &&
                salesReportData?.map((item: IPPSalesOverDuration) => (
                    <Box key={item?.sourceName}>
                        <SourceName>{item?.sourceName}</SourceName>
                        <ReportsTable
                            data={item?.productPerformance}
                            columns={columnProductPerformanceBySales}
                        />
                    </Box>
                ))}
            {reportType === ReportType.errorStatusReport && (
                <ReportsTable
                    data={errorStatusReportData}
                    columns={columnErrorStatus}
                />
            )}
            {reportType === ReportType.shippingFeeReport && (
                <ReportsTable
                    data={shippingCostReportItems}
                    columns={columnShippingCostReport}
                />
            )}
        </FlexBox>
    )
})
