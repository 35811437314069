import React, { FC, MouseEvent } from 'react'
import {
    Box,
    Center,
    Collapse,
    Flex,
    Spacer,
    Stack,
    Text,
    useDisclosure,
} from '@chakra-ui/react'
import { HandleComponentProps } from 'react-flow-renderer/dist/components/Handle'
import { observer } from 'mobx-react-lite'
import { sortBy } from 'lodash'
import { toJS } from 'mobx'
import { CustomHandle } from '../CustomHandle'
import { NodeParameter } from './NodeParameter'
import { editoreStore, stepsMetadataStore } from 'stores'
import { WorkflowStep } from 'types/core'
import { categoriesIcons } from 'utils/categoriesIcons'
import { jsonParse } from 'utils/jsonParse'
import {
    MenuDotsIconStyled,
    CrossSmallIconStyled,
    PlainArrowTopIconStyled,
    PlainArrowBottomIconStyled,
} from './styles'

interface NodeLayoutProps {
    step: WorkflowStep
    ports: HandleComponentProps[]
    readonly?: boolean
}

export const NodeLayout: FC<NodeLayoutProps> = observer(({
    step,
    ports = [],
    readonly = false,
    children,
}) => {
    const { isOpen, onToggle } = useDisclosure()
    const metadatas = toJS(stepsMetadataStore.metadatas)
    const stepMetadata = step.stepMetadata
    const stepParameters = [...step.parameters]
    const metadata = metadatas && metadatas.find(({ id }) => id === step.stepMetadataId)
    const nodeParameters = metadata?.parameterMetadata ? sortBy([...metadata.parameterMetadata],  ['order']) : []
    const workflow = editoreStore.workflowStore.workflow
    const isDraft = workflow?.status === 'draft'

    const onRemove = (): Promise<void> => editoreStore.diagramStore.removeStep(step.id)

    return (
        <Box
            minWidth="200px"
            maxHeight="400px"
            borderRadius="8px"
            backgroundColor="blueWhite.950"
            boxShadow="0px 2px 6px rgba(0, 0, 0, 0.25)"
            onWheelCapture={(e: MouseEvent) => e.stopPropagation()}
        >
            <Flex
                backgroundColor="backgroundRuleHeader"
                height="32px"
                padding="0px 8px"
                borderRadius="8px 8px 0px 0px"
            >
                <Center marginRight="8px">
                    {categoriesIcons[stepMetadata.category]}
                </Center>
                <Center marginRight="8px">
                    <Text fontWeight="bold" color="white" fontSize="sm">
                        {stepMetadata.name}
                    </Text>
                </Center>
                <Spacer />
                <Stack direction="row" spacing="8px">
                    {children || nodeParameters.length ? (
                        <Center onClick={onToggle}>
                            {isOpen ? (
                                <PlainArrowTopIconStyled onClick={onToggle} />
                            ) : (
                                <PlainArrowBottomIconStyled onClick={onToggle} />
                            )}
                        </Center>
                    ) : null}
                    {!readonly && isDraft ? (
                        <Center>
                            <CrossSmallIconStyled onClick={onRemove} />
                        </Center>
                    ) : null}
                </Stack>
            </Flex>
            <Flex>
                <Center margin="10px 0">
                    <MenuDotsIconStyled isVertical />
                </Center>
                <Box>
                    <Flex>
                        <Center
                            maxWidth="300px"
                            padding="10px"
                            paddingRight="60px"
                        >
                            <Text size="sm" wordBreak="break-word">
                                {step.stepMetadata.description}
                            </Text>
                        </Center>
                    </Flex>
                    <Collapse in={isOpen} animateOpacity>
                        {(!!children || !!nodeParameters.length) && (
                            <Box>
                                <Box padding="0 10px 10px 10px">
                                    <Flex direction="row" wrap="wrap" maxW="320px">
                                        {nodeParameters.map(param => {
                                            const findParameter = stepParameters.find(
                                                ({ parameterMetadataId }) => parameterMetadataId === param.id
                                            ) || ''
                                            const parseDependsOn = jsonParse(param.dependsOn)
                                            const dependsOnMappings = parseDependsOn ? parseDependsOn.mappings : []

                                            return (
                                                <NodeParameter
                                                    key={param.id}
                                                    id={findParameter && findParameter.id}
                                                    stepId={step.id}
                                                    isDraft={isDraft}
                                                    dependsOnMappings={dependsOnMappings}
                                                    stepParameters={stepParameters}
                                                    name={param.name}
                                                    type={param.type}
                                                    subtype={param.subtype}
                                                    isExternal={param.isExternal}
                                                    readonly={readonly}
                                                    nodeParameters={nodeParameters}
                                                    displayAs={param.displayOptions}
                                                    description={param.description}
                                                    value={findParameter && findParameter.value}
                                                    ruleName={stepMetadata.name}
                                                    onChange={newValue => {
                                                        editoreStore.diagramStore.changeParameter(
                                                            findParameter && findParameter.id,
                                                            step.id,
                                                            newValue,
                                                        )
                                                    }}
                                                />
                                            )
                                        })}
                                    </Flex>
                                    {children}
                                </Box>
                            </Box>
                        )}
                    </Collapse>
                    {ports.map((port, i) => (
                        <CustomHandle key={i} {...port} />
                    ))}
                </Box>
            </Flex>
        </Box>
    )
})
