import { useCallback, useRef } from 'react'

type DebounceFunction<T> = (value: T) => void

export function useDebounce<T>(
    func: DebounceFunction<T>,
    timeout: number = 1000
): DebounceFunction<T> {
    const timerRef = useRef<NodeJS.Timeout | null>(null)

    const callback = useCallback(
        value => {
            if (timerRef.current) {
                clearTimeout(timerRef.current)
            }

            const newTimer = setTimeout(() => {
                func(value)
            }, timeout)

            timerRef.current = newTimer
        },
        [timeout, func]
    )

    return callback
}
