export interface PagingModel {
    totalItems: number
    totalPages: number
    pageIndex: number
    pageSize: number
}

export const pageSizes = [
    10,
    25,
    50
]

export const defaultPaging: PagingModel = {
    totalItems: 0,
    totalPages: 1,
    pageIndex: 1,
    pageSize: pageSizes[0]
}