import { makeAutoObservable, runInAction } from 'mobx'
import { isEmpty } from 'lodash'
import { editoreStore, notificationStore } from 'stores'
import { OptionData } from 'components/baseComponents/Selects'
import { GetDataResult } from 'components/VirtualScroller'
import { AvailableValuesService } from 'services/availableValues'
import { IDependencies, UUID } from 'types/core'

export class ExternalParamStore {
    private parameterId: UUID
    private stepId: UUID
    private defaultSelected: string

    public value: OptionData | null = null
    public dependencies: IDependencies = {}

    constructor(parameterId: UUID, stepId: UUID, defaultSelected: string) {
        this.parameterId = parameterId
        this.stepId = stepId
        this.defaultSelected = defaultSelected
        makeAutoObservable(this, {
            getAvailableValues: false,
        })
    }

    public async init() {
        if (this.defaultSelected !== 'null' && this.defaultSelected !== '') {
            try {
                const dependencies = isEmpty(this.dependencies)
                    ? undefined
                    : this.dependencies

                const { data = [] } = await AvailableValuesService.getValues({
                    parameterId: this.parameterId,
                    search: this.defaultSelected,
                    dependencies,
                })

                runInAction(() => {
                    this.value = data[0]
                })
            } catch (err) {
                notificationStore.triggerErrorToast(err)
            }
        }
    }

    public async selectValue(value: OptionData | null) {
        if (this.value === value) return

        const newValue = value?.value || 'null'
        editoreStore.diagramStore.changeParameterValue(
            this.parameterId,
            this.stepId,
            newValue
        )

        this.value = value
        await this.saveValue()
    }

    public async clearValue() {
        editoreStore.diagramStore.changeParameterValue(
            this.parameterId,
            this.stepId,
            'null'
        )
        this.value = null

        await this.saveValue()
    }

    private async saveValue() {
        return await AvailableValuesService.changeValue(
            this.parameterId,
            this.value?.value || 'null'
        )
    }

    public async setDependencies(dependencies: IDependencies) {
        try {
            if (!isEmpty(this.dependencies))
                await this.selectValue(null)
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
        this.initDependencies(dependencies)
        await this.init()
    }

    private initDependencies(dependencies: IDependencies) {
        this.dependencies = dependencies
    }

    public async getAvailableValues(
        page: number,
        size: number,
        search: string
    ): Promise<GetDataResult<OptionData>> {
        const dependencies = isEmpty(this.dependencies)
            ? undefined
            : this.dependencies

        return AvailableValuesService.getValues({
            parameterId: this.parameterId,
            search,
            page,
            size,
            dependencies,
        })
    }
}
