import { Flex, Text } from '@chakra-ui/layout'
import { chakra } from 'utils/chakra'

export const TagBox = chakra(Flex, {
    borderRadius: '15px',
    height: '20px',
    padding: '4px 8px',
})

export const TagText = chakra(Text, {
    variant: 'smalllink',
    textTransform: 'uppercase',
})
