import React, { FC, useState } from 'react'
import { toJS } from 'mobx'
import { format } from 'date-fns'
import { observer } from 'mobx-react-lite'
import { Text, Grid } from '@chakra-ui/layout'
import { IconButton, useDisclosure } from '@chakra-ui/react'
import { ComponentModal } from 'components/Modal/ComponentModal'
import { ActionInputs } from './ActionInputs'
import { ActionsMenu } from './ActionsMenu'
import { InfoItem } from './InfoItem'
import { workflowVersionsStore } from 'stores'
import {
    InfoDisplayBox,
    InfoHeaderBox,
    InfoItemsBox,
    ActionBox,
    ArrowUpIcon,
    ArrowDownIcon,
} from '../styles'

interface IInfoDisplay {
    onHistoryClose: () => void
    isRuleVersionSelection?: boolean
}
export const InfoDisplay: FC<IInfoDisplay> = observer(({
    onHistoryClose,
    isRuleVersionSelection = false,
}) => {
    const [modalName, setModalName] = useState('')
    const {
        isOpen: isMenuOpen,
        onToggle: onMenuToggle,
    } = useDisclosure()
    const {
        isOpen: isActionModalOpen,
        onOpen: onActionModalOpen,
        onClose: onActionModalClose,
    } = useDisclosure()

    const {
        id,
        description,
        status,
        modifiedAt,
        version,
        createdByUser,
        approvedByUser,
        name
    } = toJS(workflowVersionsStore.selectedWorkflow)

    const getModifiedDate = (): string => {
        return `${format(new Date(modifiedAt), 'LLL. dd, yyyy')} - ${format(new Date(modifiedAt), 'hh:mm:ss')}`
    }

    return (
        <InfoDisplayBox>
            <InfoHeaderBox>
                <Text variant="h4Custom">Test Version Rule</Text>
                {!isRuleVersionSelection && <ActionBox>
                    <Text onClick={onMenuToggle} variant="h4LineHeight">Action</Text>
                    {isMenuOpen ? (
                        <IconButton
                            aria-label="Simple Arrow Icon"
                            variant="hoveredMediumWhite"
                            onClick={onMenuToggle}
                            icon={<ArrowUpIcon />}
                        />
                    ) : (
                        <IconButton
                            aria-label="Simple Arrow Icon"
                            variant="hoveredMediumWhite"
                            onClick={onMenuToggle}
                            icon={<ArrowDownIcon />}
                        />
                    )}
                    {isMenuOpen && (
                        <ActionsMenu
                            setModalName={setModalName}
                            onMenuToggle={onMenuToggle}
                            onHistoryClose={onHistoryClose}
                            onActionModalOpen={onActionModalOpen}
                        />
                    )}
                    <ComponentModal
                        size="lg"
                        headerText={modalName}
                        isOpen={isActionModalOpen}
                        onClose={onActionModalClose}
                        reactComponent={
                            <ActionInputs
                                selectedWorkflowId={id}
                                onHistoryClose={onHistoryClose}
                                modalName={modalName}
                                setModalName={setModalName}
                                onActionModalClose={onActionModalClose}
                                name={name}
                            />
                        }
                    />
                </ActionBox>}
            </InfoHeaderBox>
            <InfoItemsBox>
                <Grid
                    gap={1}
                    width="100%"
                    templateColumns="repeat(4, 1fr)"
                >
                    <InfoItem
                        infoName="Version"
                        infoValue={version}
                    />
                    <InfoItem
                        infoName="Description"
                        infoValue={description}
                    />
                    <InfoItem
                        infoName="Approver"
                        infoValue={
                            approvedByUser ? `${approvedByUser.firstName} ${approvedByUser.lastName}` : ''
                        }
                    />
                    <InfoItem infoName="Activity" />
                </Grid>
            </InfoItemsBox>
            <InfoItemsBox>
                <Grid
                    gap={1}
                    width="75%"
                    templateColumns="repeat(3, 1fr)"
                >
                    <InfoItem
                        infoName="Date & Time Modified"
                        infoValue={modifiedAt ? getModifiedDate() : ''}
                    />
                    <InfoItem
                        infoName="Creator"
                        infoValue={createdByUser ? `${createdByUser.firstName} ${createdByUser.lastName}` : ''}
                    />
                    <InfoItem
                        infoName="Status"
                        infoValue={status}
                    />
                </Grid>
            </InfoItemsBox>
        </InfoDisplayBox>
    )
})
