import React from 'react'
import { Flex, Text } from '@chakra-ui/layout'
import { components, OptionProps } from 'react-select'

export const CheckboxOption = (props: OptionProps) => (
    <components.Option {...props}>
        <Flex>
            <input type="checkbox" checked={props.isSelected} onChange={() => null} />
            {' '}
            <Text margin="0 4px">{props.label}</Text>
        </Flex>
    </components.Option>
)
