import React, { FC, useEffect } from 'react'
import { Button } from '@chakra-ui/button'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import { NumericPagination } from '../NumericPagination'
import { RuleVersionItem } from './content/RuleVersionItem'
import { ItemsHeader } from './content/ItemsHeader'
import { InfoDisplay } from './content/InfoDisplay'
import { Filters } from './filters'
import { editoreStore, workflowVersionsStore } from 'stores'
import { ShortWorkflow } from 'types/core'
import { headerOptions } from './mock'
import { ClearButtonBox, NoItemsBox } from './styles'

interface IRuleHistory {
    onHistoryClose: () => void
}

export const RuleHistory: FC<IRuleHistory> = observer(({ onHistoryClose }) => {
    const workflow = editoreStore.workflowStore.workflow
    const workflowVersions = toJS(workflowVersionsStore.workflowVersions)
    const filters = toJS(workflowVersionsStore.filters)
    const pages = toJS(workflowVersionsStore.pages)

    const handleClearAll = async (): Promise<void> => {
        workflowVersionsStore.clearFilters()
        await workflowVersionsStore.fetch()
    }

    const onChangePage = async (page: number): Promise<void> => {
        workflowVersionsStore.setFilters({ page })
        await workflowVersionsStore.fetch()
    }

    useEffect(() => {
        if (workflow?.workflowHeaderId) {
            workflowVersionsStore.setFilters({
                workflowHeaderId: workflow.workflowHeaderId,
            })
            workflowVersionsStore.fetch()
        }
    }, [workflow])

    useEffect(() => {
        return () => {
            workflowVersionsStore.clean()
        }
    }, [])

    return (
        <>
            <InfoDisplay onHistoryClose={onHistoryClose} />
            <Filters />
            {workflowVersionsStore.isFiltersApplied() && (
                <ClearButtonBox>
                    <Button
                        variant="underline"
                        onClick={handleClearAll}
                    >
                        Clear All
                    </Button>
                </ClearButtonBox>
            )}
            <ItemsHeader options={headerOptions} />
            {!!workflowVersions?.length ? workflowVersions.map((workflowVersion: ShortWorkflow) => {
                return (
                    <RuleVersionItem
                        key={workflowVersion.id}
                        workflowVersion={workflowVersion}
                    />
                )
            }) : (
                <NoItemsBox>No Items</NoItemsBox>
            )}
            <NumericPagination
                marginTop="50px"
                pages={pages}
                items={workflowVersions.length}
                activePage={filters.page}
                onChangePage={onChangePage}
            />
        </>
    )
})
