import { makeAutoObservable } from 'mobx'
import { AuthNoAuth } from '../types/core'
import { PagingFilters } from '../types/api'
import { AuthNoAuthApi } from '../api/authNoAuth'

const defaultFilters: PagingFilters = {
    page: 1,
    size: 10,
}

export class AuthNoAuthStore {
    public data: AuthNoAuth[] = []
    public filters: PagingFilters = defaultFilters
    public pages: number = 0

    constructor() {
        makeAutoObservable(this)
    }

    public async fetch(id: string) {
        try {
            const response = await AuthNoAuthApi.getAuthNoAuth(id, this.filters)
            this.init(response.items)
            this.setUpPages(response.totalPages)
        } catch (err) {
            this.init([])
        }
    }

    public updateData(
        rowIndex: number,
        columnId: string,
        value: string | null
    ) {
        this.data = this.data.map((row, index) => {
            if (index === rowIndex) {
                return {
                    ...this.data[rowIndex],
                    [columnId]: value,
                }
            }
            return row
        })
    }

    private init(items: AuthNoAuth[]) {
        this.data = items
    }

    private setUpPages(totalPages: number) {
        this.pages = totalPages
    }

    public setFilters(filters: Partial<PagingFilters>) {
        if (!filters.page) {
            this.filters = { ...this.filters, ...filters, page: 1 }
        } else {
            this.filters = { ...this.filters, ...filters }
        }
    }

    public clean() {
        this.filters = defaultFilters
    }
}
