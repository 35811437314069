import React, { FC } from 'react'
import { toJS } from 'mobx'
import { truncate } from 'lodash'
import { observer } from 'mobx-react-lite'
import { TooltipTextCut } from 'components/TooltipTextCut'
import { renderWows } from './renderWows'
import { workflowVersionsStore } from 'stores'
import { InfoItemStyled, InfoName, InfoValue } from '../styles'

interface IInfoItem {
    infoName: string
    infoValue?: string | number
}

export const InfoItem: FC<IInfoItem> = observer(({
    infoName,
    infoValue,
}) => {
    const { wows } = toJS(workflowVersionsStore.selectedWorkflow)
    const emptyValue = infoName === 'Activity' ? 'N/A' : '-'

    return (
        <InfoItemStyled>
            <InfoName>{infoName}</InfoName>
            {infoName === 'Activity' && wows ?
                renderWows(wows, false, true)
                : (
                    <TooltipTextCut maxTextLength={50} text={infoValue}>
                        <InfoValue>
                            {infoValue
                                ? truncate(infoValue.toString(), { length: 50 })
                                : emptyValue
                            }
                        </InfoValue>
                    </TooltipTextCut>
                )
            }
        </InfoItemStyled>
    )
})
