import { makeAutoObservable } from 'mobx'
import { notificationStore } from 'stores'
import { DiagramStore } from './DiagramStore'
import { WorkflowStore } from './WorkflowStore'
import { StatusType } from 'types/chart'
import { UUID } from 'types/core'
import { WorkflowsService } from 'services/workflows'

export class EditoreStore {
    public workflowStore: WorkflowStore
    public diagramStore: DiagramStore
    public isRecentlyCreated: boolean = false
    public isOpenned: boolean = false

    constructor() {
        this.workflowStore = new WorkflowStore()
        this.diagramStore = new DiagramStore()

        makeAutoObservable(this)
    }

    public async init(id: UUID) {
        try {
            const workflow = await this.workflowStore.fetch(id)
            if (this.diagramStore.elements.length) this.diagramStore.clear()
            this.diagramStore.init(workflow)
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    public toggleRecentlyCreated() {
        this.isRecentlyCreated = !this.isRecentlyCreated
    }

    public openDiagram() {
        this.isOpenned = true
    }

    public async addPreview(blob: Blob) {
        if (!this.workflowStore.workflow) {
            notificationStore.toast({
                title: 'Error',
                description: 'Missing Workflow',
                status: StatusType.Error,
            })
            return
        }

        const id = this.workflowStore.workflow.id
        const filename = `preview-${id}.png`
        const file = new File([blob], filename, {
            type: 'image/png',
        })
        try {
            await WorkflowsService.addPreview(id, file)
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    public clean() {
        this.isRecentlyCreated = false
        this.isOpenned = false
        this.workflowStore.clean()
        this.diagramStore.clean()
    }
}
