import React, { FC } from 'react'
import { FormControl } from '@chakra-ui/form-control'
import { Box, Flex, Spacer } from '@chakra-ui/layout'
import { TextareaProps } from '@chakra-ui/textarea'
import { TextareaStyled, TextStyled } from './styles'

interface CounteredInputProps extends TextareaProps {
    description?: string
    remaining?: number
}

export const CounteredTextarea: FC<CounteredInputProps> = React.forwardRef<
    HTMLTextAreaElement,
    CounteredInputProps
>(({ description, remaining = 30, width, height, ...props }, ref) => {
    const isInvalid = props.isInvalid

    return (
        <FormControl width={width} height={height}>
            <Flex direction="column" height="100%">
                <Box flex="1">
                    <TextareaStyled
                        border={isInvalid ? '3px solid' : '1px solid'}
                        borderColor={isInvalid ? 'red.500' : 'color.875'}
                        ref={ref}
                        {...props}
                    />
                </Box>
                <Flex marginTop="9px">
                    <TextStyled
                        color={isInvalid ? 'red.500' : 'color.100'}
                    >
                        {description}
                    </TextStyled>
                    <Spacer />
                    <TextStyled
                        color={isInvalid ? 'red.500' : 'color.100'}
                    >
                        {remaining}
                    </TextStyled>
                </Flex>
            </Flex>
        </FormControl>
    )
})
