import { GetDataFunction } from 'components/VirtualScroller'
import { ScrollerStore } from 'components/VirtualScroller/store'
import { makeAutoObservable } from 'mobx'
import { OptionData } from '..'
import { RisenScrollerService } from './service'

export class MultiSelectScrollStore {
    private scrollerService: RisenScrollerService

    public scrollStore: ScrollerStore<OptionData>

    constructor(scrollerService: RisenScrollerService) {
        this.scrollerService = scrollerService
        this.scrollStore = new ScrollerStore(this.scrollerService)
        makeAutoObservable(this)
    }

    public async init(
        getData: GetDataFunction<OptionData>,
        risen: OptionData[],
        initScroll: number
    ) {
        this.setRisen(risen)
        await this.scrollStore.init(getData, initScroll)
    }

    public async scrollTo(scroll: number) {
        await this.scrollStore.scrollTo(scroll)
    }

    private async setRisen(risen: OptionData[]) {
        await this.scrollerService.setRisen(risen)
    }
}
