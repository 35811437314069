import { Box, Text } from '@chakra-ui/layout'
import { chakra } from 'utils/chakra'

export const DataBox = chakra(Box, {
    padding: '4px'
})

export const ProviderDataBox = chakra(Box, {
    variant: 'supertitlebold',
    display: 'flex',
    height: '30px',
    width: '240px',
    backgroundColor: 'grayBlue.990',
    borderRadius: '6px',
    padding: '5px 5px',
})

export const ShipmentProvider = chakra(Text, {
    fontSize:'13px',
    width: '70px',
    fontWeight: 'semibold',
    padding: '1px 0px 0px 3px'
})

export const Percent = chakra(Text, {
    fontSize: '13px',
    justifyContent: 'right',
    marginLeft: '20px',
    marginRight: '4px',
    padding: '1px 0px 0px 0px'
})

export const Change = chakra(Text, {
    fontSize: '13px',
    marginLeft: '1px',
    marginRight: '5px',
    padding: '1px 3px 0px 0px'
})
