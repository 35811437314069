import { makeAutoObservable } from 'mobx'
import { ReportAPI } from 'api/reports'
import {
    IPPSalesSources,
    IPPSalesOverDuration,
    IPPSalesTotalSources,
    IShippersData,
} from 'types/core'
import { PagingFilters } from 'types/api'

const defaultFilters: PagingFilters = {
    page: 1,
    size: 10,
}

export class ProductPerformanceBySalesReportStore {
    public sourceLabels: IShippersData[] = []
    public totalItems: number = 0
    public filters: PagingFilters = defaultFilters
    public pages: number = 0
    public productPerformanceReports: IPPSalesOverDuration[] = []
    public productPerformanceSalesExport: IPPSalesOverDuration[] = []
    public exportTotalItems: number = 0

    constructor() {
        makeAutoObservable(this)
    }

    public async productPerformanceBySalesReport(
        fromDate: string,
        todate: string,
        source: string
    ) {
        try {
            const data = await ReportAPI.getProductPerformanceBySalesReport(
                fromDate,
                todate,
                source,
                this.filters
            )
            this.init(data?.items)
            this.initTotalCount(data?.totalItems)
            this.initPages(data?.totalPages)
        } catch (err) {
            this.totalItems = 0
        }
    }

    public async performanceBySalesReportExportData(
        fromDate: string,
        todate: string,
        shipername: string
    ) {
        try {
            const data =
                await ReportAPI.getProductPerformanceBySalesReportExport(
                    fromDate,
                    todate,
                    shipername
                )
            this.initExportTotalItems(data?.totalItems)
            this.initExportData(data?.items)
        } catch (err) {
            this.initExportData([])
        }
    }

    private initExportData(data: IPPSalesOverDuration[]) {
        this.productPerformanceSalesExport = data || []
    }

    private initExportTotalItems(data: number) {
        this.exportTotalItems = data
    }

    private initTotalCount(items: number) {
        this.totalItems = items
    }

    private init(data: IPPSalesOverDuration[]) {
        this.productPerformanceReports = data || []
    }

    public async getSourcesNames() {
        try {
            const data = await ReportAPI.sourceNames()
            this.sourcevaluesandLabels(data)
        } catch (err) {
            this.sourceLabels = []
        }
    }

    public async sourcevaluesandLabels(data: IPPSalesSources) {
        this.sourceLabels = []
        data?.sources?.forEach((source: IPPSalesTotalSources) =>
            this.sourceLabels.push({
                value: source.sourceId,
                label: source.sourceName,
            })
        )
    }

    private initPages(pages: number) {
        this.pages = pages
    }

    private initFilters(filter: PagingFilters) {
        this.filters = filter
    }

    public setFilters(filters: Partial<PagingFilters>) {
        if (!filters.page) {
            this.filters = { ...this.filters, ...filters, page: 1 }
        } else {
            this.filters = { ...this.filters, ...filters }
        }
    }

    public reloadPages() {
        this.initFilters(defaultFilters)
    }

    public clean() {
        this.initFilters(defaultFilters)
        this.initPages(0)
        this.initExportData([])
        this.init([])
        this.initTotalCount(0)
        this.initExportTotalItems(0)
    }
}
