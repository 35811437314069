import React, { ChangeEventHandler, FC, useState } from 'react'
import { Input as ChakraInput } from '@chakra-ui/react'
import { useDebounce } from 'hooks'
import { idValidator } from './utils'

interface InputProps {
    readonly: boolean
    isDraft: boolean
    value: string
    onChange: (newValue: string) => void
    description: string
    needValidation?: boolean
}

export const Input: FC<InputProps> = ({
    readonly,
    isDraft,
    value,
    onChange,
    description,
    needValidation = false,
}) => {
    const [isError, setIsError] = useState(false)

    const onParamChange: ChangeEventHandler<HTMLInputElement> = useDebounce(
        e => {
            if (needValidation && !idValidator(e.target.value)) {
                setIsError(true)
            } else {
                onChange(e.target.value)
                setIsError(prev => (prev ? false : prev))
            }
        },
        800
    )

    return (
        <ChakraInput
            isDisabled={!isDraft}
            isReadOnly={readonly}
            isInvalid={isError}
            defaultValue={value}
            placeholder={description}
            size="xs"
            marginTop="5px"
            height="30px"
            padding="6px 5px"
            backgroundColor="color.900"
            borderColor="divider"
            borderRadius="8px"
            color="color.100"
            onChange={onParamChange}
        />
    )
}
