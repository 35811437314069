import React from 'react'
import {
    FieldFormat,
    FieldIndex,
    FieldName,
    FieldType,
    TableForm,
} from '../../../../../../types/core'
import { LocationForm } from './LocationForm'

export const ApiKeyLocation = (props: TableForm) => {
    return (
        <LocationForm
            header="Api Key:"
            nameFieldTitle="Api"
            fields={[
                FieldType.ApiKeyType,
                FieldName.ApiKeyName,
                FieldFormat.ApiKeyFormat,
                FieldIndex.ApiKeyIndex,
            ]}
            {...props}
        />
    )
}
