import {
    Button,
    Divider,
    Flex,
    Grid,
    GridItem,
    Input,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
} from '@chakra-ui/react'
import { Text } from '@chakra-ui/react'
import { chakra } from 'utils/chakra'

export const GrigLayout = chakra(Grid, {
    gridTemplateAreas: `"header"
            "category"
            "attribute"
            "save"`,
    gridTemplateRows: '50px 60px 80px minmax(100px,auto) auto',
    columnGap: '5',
    margin: '16px 0 8px 24px',
    borderRadius: '16px',
    boxShadow: '0px 6px 26px rgba(16, 30, 115, 0.06)',
    minHeight: '400px',
    height: '850px',
    width: '95%',
    bgColor: 'blueWhite.900',
})

export const GridHeader = chakra(GridItem, {
    area: 'header',
    alignSelf: 'center',
    justifySelf: 'flex-start',
    padding: '0 24px 0 24px',
    marginTop: '24px',
})

export const GridDivider = chakra(Divider, {
    padding: '8px 0',
})

export const FlexFilter = chakra(Flex, {
    gap: '40%',
    paddingRight: '25px',
})

export const FlexSelect = chakra(Flex, {
    gap: '10px',
})

export const TableBody = chakra(Tbody, {
    zIndex: '1',
})

export const TextHeader = chakra(Text, {
    fontWeight: 'bold',
    fontSize: '24px',
})

export const GridCategory = chakra(GridItem, {
    area: 'category',
    alignSelf: 'center',
    justifySelf: 'flexstart',
    paddingLeft: '25.5px',
    marginTop: '24px',
})

export const GridTable = chakra(GridItem, {
    area: 'attribute',
    alignSelf: 'flexstart',
    justifySelf: 'flexstart',
    padding: '11px 20px 11px 20px',
    marginTop: '24px',
})

export const GridFooter = chakra(GridItem, {
    area: 'save',
    alignSelf: 'flexstart',
    justifySelf: 'end',
    marginTop: '32.5px',
    zIndex: '-1',
})

export const InputName = chakra(Input, {
    height: '32px',
    width: '200px',
    fontSize: '14px',
    borderColor: 'divider',
})

export const MainTable = chakra(Table, {
    size: 'sm',
    fontSize: '14px',
    textAlign: 'left',
})

export const Theader = chakra(Thead, {
    bgColor: 'blueWhite.960',
    fontWeight: 'bold',
    height: '40px',
    borderEnd: '10px',
})

export const TableHeader = chakra(Th, {
    textAlign: 'left',
    color: 'color.100',
})

export const TableData = chakra(Td, {
    textAlign: 'left',
})

export const FlexFooter = chakra(Flex, {
    gap: '10%',
    justifyContent: 'center',
    flexDirection: 'column',
    zIndex: '-1',
    marginBottom: '2px',
})

export const FlexAddCategory = chakra(Flex, {
    gap: '8.5px',
    alignItems: 'center',
})

export const AddButton = chakra(Button, {
    bgColor: 'blue.200',
    color: 'white',
    height: '32px',
    fontSize: '12px',
    lineHeight: '16px',
})

export const SaveButton = chakra(Button, {
    height: '32px',
    fontSize: '12px',
    lineHeight: '16px',
    border: '1px solid',
    borderColor: 'blue.200',
    backgroundColor: 'blueWhite.900',
    color: 'blueWhite.1120',
})

export const NoRecords = chakra(Text, {
    textAlign: 'center',
    fontWeight: 'bold',
    marginTop: '20%',
})

export const AlertText = chakra(Text, {
    fontWeight: 'bold',
    mb: '1rem',
    textAlign: 'center',
})

export const FooterFlex = chakra(Flex, {
    justifyContent: 'center',
    gap: '5%',
    marginTop: '3%',
})
