export const CODE = 40
export const VALUE = 500
export const QUANTITY = 10
export const ORDER_TYPE = 20
export const SALES_OFFICE = 40
export const PAYMENT_TERM = 40
export const PRODUCT_CODE = 40
export const EXTERNAL_CODE = 40
export const CUSTOMER_CODE = 40
export const QUANTITY_UNIT = 3
export const CUSTOMER_PRODUCT_NUMBER = 20
export const CUSTOMER_EXTERNAL_ORDER_ID = 50
export const ORDER_SOURCE_EXTERNAL_ORDER_ID = 50
