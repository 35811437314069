import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Input } from '@chakra-ui/input'
import { Center, IconButton, useNumberInput } from '@chakra-ui/react'
import { UUID, DisplayOptions } from 'types/core'
import { getMinMaxValues } from './utils'
import { selectParamStores } from 'stores'
import { colors } from 'theme/colors'
import { NumberInputBoxStyled } from './NumberInputBoxStyled'
import {
    NumberInputBox,
    QuantityText,
    IconBox,
    PlusEllipseIconStyled,
} from './styles'

interface NumberInputProps {
    value: string
    name: string
    stepId: UUID
    onChange: (newValue: string) => void
    readonly: boolean
    isDraft: boolean
    inputWidth?: string
    isDecimal?: boolean
    displayAs?: DisplayOptions
}

export const NumberInput: FC<NumberInputProps> = observer(({
    value,
    stepId,
    name,
    onChange,
    inputWidth = '150',
    readonly,
    isDraft,
    isDecimal = false,
    displayAs = null,
}) => {
    const isPercentSelected = selectParamStores.getSelectedLabel(stepId) === 'Percent'
    const isFeeParam = name === 'fee-value-param'
    const isNumberOfItemParam = name === 'number-of-item-param'
    const numberInputName = isNumberOfItemParam ? 'Items:' : 'Quantity:'
    const displayAsValue = displayAs?.displayAs
    const isNumberOfCases = name === 'order-quantity-value-param'

    const [min, max] = getMinMaxValues(isDecimal, isFeeParam, displayAsValue)

    const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
        useNumberInput({
            isReadOnly: readonly,
            step: 1,
            defaultValue: value,
            min,
            max,
            onChange: valueAsString => {
                const value = Number(valueAsString)
                if ((value === 0) || (value && value >= min && value <= max)) {
                    onChange(valueAsString)
                }
            },
        })

    const inc = isDraft ? getIncrementButtonProps() : {}
    const dec = isDraft ? getDecrementButtonProps() : {}
    const input = getInputProps()

    const circleColor = isDraft ? colors.blue['990'] : 'grey'

    return (
        <NumberInputBox>
            {!isDecimal && <QuantityText> { numberInputName } </QuantityText>}
            <IconButton
                aria-label="Plus Ellipse Icon"
                variant="ellipse"
                icon={
                    <PlusEllipseIconStyled
                        circleColor={circleColor}
                        iconText="-"
                        position={isDecimal ? 'relative' : 'initial'}
                        left={isDecimal ? '12px' : '0'}
                    />
                }
                {...dec}
            />
            {isDecimal && (
                <IconBox>
                    <Center position="relative" left="3px" top="2px">
                        <NumberInputBoxStyled
                            isPercentSelected={isPercentSelected}
                            isNumberOfCases={isNumberOfCases}
                            displayAsValue={displayAsValue}
                        />
                    </Center>
                </IconBox>
            )}
            <Input
                isReadOnly={readonly}
                isDisabled={!isDraft}
                variant="node"
                color={isDraft ? 'white' : 'grey'}
                width={`${inputWidth}px`}
                paddingLeft={isDecimal ? '25px' : '15px'}
                maxLength={isDecimal ? 27 : undefined}
                {...input}
            />
            <IconButton
                aria-label="Minus Ellipse Icon"
                variant="ellipse"
                icon={<PlusEllipseIconStyled
                        circleColor={circleColor}
                        iconText="+"
                    />
                }
                {...inc}
            />
        </NumberInputBox>
    )
})
