import { Flex, Text } from '@chakra-ui/layout'
import { chakra } from 'utils/chakra'

export const HeaderBox = chakra(Flex, {
    height: '48px',
    justifyContent: 'center',
    backgroundColor: 'blueWhite.950',
    boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.15)',
})

export const ContentBox = chakra(Flex, {
    width: '100%',
    justifyContent: 'space-between',
    padding: '0 42px',
})

export const LeftContent = chakra(Flex, {
    width: '380px',
    minWidth: '520px',
    alignItems: 'center',
})

export const RightContent = chakra(Flex, {
    width: '525px',
    minWidth: '450px',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: '5px',
})

export const IconsBox = chakra(Flex, {
    width: '85px',
    justifyContent: 'space-between',
    alignItems: 'center',
})

export const LogoText = chakra(Text, {
    variant: 'logo',
    textTransform: 'uppercase',
    alignSelf: 'center',
    cursor: 'pointer',
})
