import React, { Dispatch, FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import { Button } from '@chakra-ui/button'
import { useForm } from 'react-hook-form'
import { CounteredInput } from 'components/CounteredInput'
import { DESCRIPTION_LENGTH, NAME_LENGTH, MESSAGE_LENGTH } from 'constants/rules'
import { routes } from 'navigation/routes'
import { editoreStore, notificationStore, workflowVersionsStore } from 'stores'
import { CreateRuleForm, UUID } from 'types/core'
import { StatusType } from 'types/chart'
import { NEW_VERSION, MODIFY, OBSOLETE } from '../mock'
import { useDebounce } from 'hooks'
import { BottomBox } from 'pages/HomePage/content/OrderStatusTracking/Filters/ShowFilters/styles'
import { ActionInputsBox, InputTitle } from '../styles'

interface IActionInputs {
    selectedWorkflowId: UUID
    modalName: string
    setModalName: Dispatch<string>
    onActionModalClose: () => void
    onHistoryClose: () => void
    name: string
}

export const ActionInputs: FC<IActionInputs> = observer(({
    selectedWorkflowId,
    modalName,
    setModalName,
    onActionModalClose,
    onHistoryClose,
    name = ""
}) => {
    const { id } = useParams<'id'>()
    const navigate = useNavigate()
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm<CreateRuleForm>({ mode: 'onChange', reValidateMode: 'onChange' })

    const isNewVersion = modalName === NEW_VERSION
    const nameRemaining = NAME_LENGTH - watch('name', '')?.length
    const descriptionRemaining = DESCRIPTION_LENGTH - watch('description', '')?.length
    const messageRemaining = MESSAGE_LENGTH - watch('message', '')?.length
    const isSaveDisabled = (isNewVersion && (nameRemaining === NAME_LENGTH || descriptionRemaining === DESCRIPTION_LENGTH))
        || !isEmpty(errors)

    const handleSaveSend = async (values?: CreateRuleForm): Promise<void> => {
        workflowVersionsStore.actionSubmit(modalName, values).then(() => {
            notificationStore.toast({
                title: 'Success',
                description: `Action "${modalName}" Completed Successfully`,
                status: StatusType.Success,
            })
            onActionModalClose()
            workflowVersionsStore.fetch()
            if (modalName === MODIFY) {
                navigate(`${routes.editor}/${selectedWorkflowId}`)
                onHistoryClose()
                document.location.reload()
            }
            if (modalName === OBSOLETE) {
                editoreStore.clean()
                if (id) {
                    editoreStore.init(id)
                }
            }
        }).catch((err) => {
            notificationStore.triggerErrorToast(err)
        })
    }

    const onSaveSend = () => {
        return handleSubmit(async (values) => {
            if (!!values.name) await handleSaveSend(values)
            else await handleSaveSend({ ...values, name })
        })
    }

    const debouncedSave = useDebounce(onSaveSend(), 200)

    useEffect(() => {
        setValue('name', name)
        return () => {
            setModalName('')
        }
    }, [name, setModalName, setValue])

    return (
        <ActionInputsBox>
            {isNewVersion && (
                <>
                    <InputTitle>Name (Required)</InputTitle>
                    <CounteredInput
                        placeholder="Enter Name..."
                        size="sm"
                        width="465px"
                        marginTop="8px"
                        remaining={nameRemaining}
                        isInvalid={!!errors.name}
                        description={errors.name?.message}
                        {...register('name', {
                            required: 'This field cannot be blank. Please enter a name to continue.',
                            maxLength: {
                                value: NAME_LENGTH,
                                message: `This field max length is ${NAME_LENGTH}`,
                            },
                        })}
                    />
                    <InputTitle>Description (Required)</InputTitle>
                    <CounteredInput
                        placeholder="Enter Description..."
                        size="sm"
                        width="465px"
                        marginTop="8px"
                        remaining={descriptionRemaining}
                        isInvalid={!!errors.description}
                        description={errors.description?.message}
                        {...register('description', {
                            required: 'This field cannot be blank. Please enter a description to continue.',
                            maxLength: {
                                value: DESCRIPTION_LENGTH,
                                message: `This field max length is ${DESCRIPTION_LENGTH}`,
                            },
                        })}
                    />
                </>
            )}
            {!isNewVersion && (
                <>
                    <InputTitle>Message (Optional)</InputTitle>
                    <CounteredInput
                        placeholder="Enter Message..."
                        size="sm"
                        width="465px"
                        marginTop="8px"
                        remaining={messageRemaining}
                        isInvalid={!!errors.message}
                        description={errors.message?.message}
                        {...register('message', {
                            maxLength: {
                                value: MESSAGE_LENGTH,
                                message: `This field max length is ${MESSAGE_LENGTH}`,
                            },
                        })}
                    />
                </>
            )}
            <BottomBox>
                <Button
                    variant="transparent"
                    marginRight="12px"
                    onClick={onActionModalClose}
                >
                    Cancel
                </Button>
                <Button
                    variant="blue"
                    onClick={debouncedSave}
                    isDisabled={isSaveDisabled}
                >
                    Save & Send
                </Button>
            </BottomBox>
        </ActionInputsBox>
    )
})
