import { Box } from '@chakra-ui/react'
import { chakra } from 'utils/chakra'

export const KPIBox = chakra(Box, {
    padding: '16px',
    borderRadius: '16px',
    backgroundColor: 'color.900',
    boxShadow: '0px 6px 26px rgba(16, 30, 115, 0.06)',
    marginTop: '16px',
    marginBottom: '8px',
})
