import { StepsStyleConfig } from 'chakra-ui-steps'
import { Dict } from '@chakra-ui/utils'

interface IProps {
    [x: string]: any
    colorScheme: string
    colorMode: 'light' | 'dark'
    orientation?: 'horizontal' | 'vertical'
    theme: Dict<any>
}

export const Steps = {
    ...StepsStyleConfig,
    baseStyle: (props: IProps) => {
        return {
            ...StepsStyleConfig.baseStyle(props),
            stepIconContainer: {
                ...StepsStyleConfig.baseStyle(props).stepIconContainer,
                backgroundColor: 'blueWhite.960',
            },
            icon: {
                ...StepsStyleConfig.baseStyle(props).icon,
                color: '#A2A4A6',
            },
        }
    },
}
