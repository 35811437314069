import React, { FC } from 'react'
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { Text } from '@chakra-ui/layout'
import { AuditTrail } from './AuditTrail'
import { RetriesHistory } from './RetriesHistory'
import ItemsShippingInfo from './ItemsShippingInfo'
import { observer } from 'mobx-react-lite'
import { orderStore } from 'stores'

export const CustomTabs: FC = observer(() => {
    const checkIsDashboard = orderStore.isDasboardStatus

    return (
        <Tabs variant="line">
            <TabList>
                <Tab>Items & Shipping</Tab>
                <Tab margin="0 32px">Audit Trail</Tab>
                <Tab margin="0 32px">Customer Information</Tab>
                {checkIsDashboard && (
                    <Tab>Retries History</Tab>
                )}
            </TabList>
            <TabPanels>
                <TabPanel marginTop="25px" padding="0">
                    <ItemsShippingInfo />
                </TabPanel>
                <TabPanel marginTop="25px" padding="0">
                    <AuditTrail />
                </TabPanel>
                <TabPanel>
                    <Text variant="h2">Customer Information...</Text>
                </TabPanel>
                {checkIsDashboard && (
                    <TabPanel marginTop="25px" padding="0">
                        <RetriesHistory />
                    </TabPanel>
                )}
            </TabPanels>
        </Tabs>
    )
})
