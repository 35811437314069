import { Flex, Text } from '@chakra-ui/layout'
import { Tooltip } from '@chakra-ui/react'
import { chakra } from 'utils/chakra'

export const SelectedFiltersBox = chakra(Flex, {
    flexWrap: 'wrap',
    maxWidth: '750px'
})

export const TooltipStyled = chakra(Tooltip, {
    whiteSpace: 'pre',
    fontSize: '12px',
    lineHeight: '24px',
    backgroundColor: 'blueWhite.920',
    color: 'color.100',
    boxShadow: '0px 0px 16px rgba(29, 43, 53, 0.2)',
    borderRadius: '8px',
    padding: '12px 16px',
})

export const FilterName = chakra(Text, {
    variant: 'paragraphsmall',
    textTransform: 'capitalize',
    fontWeight: 'bold',
})

export const FilterValues = chakra(Text, {
    variant: 'paragraphsmall',
    marginLeft: '5px',
})

export const SelectedFilter = chakra(Flex, {
    height: '32px',
    margin: '5px 10px 5px 0',
    borderRadius: '8px',
    alignItems: 'center',
    padding: '0 12px',
    whiteSpace: 'nowrap',
})
