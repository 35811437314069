import { routes } from 'navigation/routes'

export const currentPage = (id: string, path?: string) => {
    return (id === 'dashboard' && path === routes.home)
        || (id === 'rulePrioritization' && path === routes.prioritize)
        || (id === 'createNewRule' && path === routes.createNewRule)
        || (id === 'tenants' && path === routes.tenants)
        || (id === 'users' && path === routes.users)
        || (id === 'gateway' && path === routes.gateway)
        || (id === 'featureFlags' && path === routes.featureFlags)
        || (id === 'rulesSimulate' && path === routes.ruleSimulate)
        || (id === 'reports' && path === routes.reports)
        || (id === 'attributeConfiguration' && path === routes.attributeConfiguration)
}
