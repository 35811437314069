import React, { FC } from 'react'
import { RectangleGreenIcon, RectangleGrayIcon } from 'icons'

interface RectangleIconProps {
    iconType: string
}

export const RectangleIcon: FC<RectangleIconProps> = ({ iconType }) => {
    switch (iconType) {
        case 'green':
            return <RectangleGreenIcon />
        case 'gray':
            return <RectangleGrayIcon />
        default:
            return <RectangleGrayIcon />
    }
}
