import { FC } from 'react'
import { External } from './External'
import { Internal } from './Internal'
import {
    DependsOnMappings,
    Parameter,
    ParameterMetadata,
    UUID,
} from 'types/core'

interface SelectProps {
    id: UUID
    stepId: UUID
    placeholder?: string
    readonly: boolean
    isDraft: boolean
    isExternal: boolean
    value: string
    description: string
    dependsOnMappings: DependsOnMappings[]
    stepParameters: Parameter[]
    nodeParameters: ParameterMetadata[]
    ruleName: string
}

export const Select: FC<SelectProps> = ({ isExternal, ...props }) => {
    return isExternal ? <External {...props} /> : <Internal {...props} />
}
