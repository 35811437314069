import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const SimpleArrowDownIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.96465 4.96536C2.69108 5.23997 2.69108 5.68124 2.96465 5.95481L7.60616 10.596C7.82397 10.814 8.17594 10.814 8.39374 10.596L13.0355 5.95481C13.3091 5.68124 13.3091 5.23997 13.0355 4.96536C12.7617 4.69179 12.3204 4.69179 12.0468 4.96536L7.99712 9.01016L3.94781 4.96011C3.67949 4.69179 3.23297 4.69179 2.96465 4.96536Z"
                fill="currentColor"
            />
        </Icon>
    )
}
