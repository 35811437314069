import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useDisclosure } from '@chakra-ui/hooks'
import { VStack } from '@chakra-ui/layout'
import { PreviewDialogModal } from 'components/diagrams/PreviewDialogModal'
import { PrioritizeItem } from './PrioritizeItem'
import RuleVersionSelection from './RuleVersionSelection'
import { ComponentModal } from 'components/Modal/ComponentModal'
import { notificationStore, prioritizeStore, userStore, workflowVersionsStore } from 'stores'
import { UUID } from 'types/core'
import { StatusType } from 'types/chart'
import { EmptyRulesBox, EmptyRulesText, ItemsBox } from '../styles'

export const PrioritizeList = observer(() => {
    const workflows = toJS(prioritizeStore.workflows)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const {
        isOpen: isOpenRule,
        onOpen: onOpenRule,
        onClose: onCloseRule,
    } = useDisclosure()

    const [viewedId, setViewedId] = useState<UUID | null>(null)
    const [title, setTitle] = useState<string>('')
    const customToast = notificationStore.toast
    const { isCreator } = toJS(userStore.currentRoles)

    return (
        <DragDropContext
            onDragEnd={results => {
                if (!results.destination) {
                    return
                }

                try {
                    prioritizeStore.prioritize(
                        results.source.index,
                        results.destination.index
                    )
                } catch (err) {
                    customToast({
                        title: 'Failed prioritize rules',
                        description: JSON.stringify(err),
                        status: StatusType.Error,
                    })
                }
            }}
        >
            <Droppable droppableId="prioritize">
                {provided => (
                    <ItemsBox
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {!!workflows.length ? (
                            workflows.map((item, i) => (
                                <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    isDragDisabled={!isCreator}
                                    index={i}
                                >
                                    {provided => (
                                        <VStack
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            ref={provided.innerRef}
                                            spacing="30px"
                                            marginTop="10px"
                                            marginBottom="20px"
                                        >
                                            <PrioritizeItem
                                                order={i + 1}
                                                description={item.name}
                                                version={item.version}
                                                status={item.status}
                                                onDiagramViewClick={() => {
                                                    setViewedId(item.id)
                                                    setTitle(item.name)
                                                    onOpen()
                                                }}
                                                onOpenRule={() => {
                                                    setViewedId(
                                                        item.workflowHeaderId
                                                    )
                                                    workflowVersionsStore.setSelectedWorkflow(item)
                                                    onOpenRule()
                                                }}
                                                onRemoveItem={() =>
                                                    prioritizeStore.removeItem(
                                                        item.id
                                                    )
                                                }
                                            />
                                        </VStack>
                                    )}
                                </Draggable>
                            ))
                        ) : (
                            <EmptyRulesBox>
                                <EmptyRulesText>
                                    There are no Rules chosen yet.
                                    <br />
                                    Choose your Rules for creating the Set
                                    Rules.
                                </EmptyRulesText>
                            </EmptyRulesBox>
                        )}
                    </ItemsBox>
                )}
            </Droppable>
            {viewedId ? (
                <PreviewDialogModal
                    isOpen={isOpen}
                    onClose={onClose}
                    title={title}
                    viewId={viewedId}
                />
            ) : null}
            <ComponentModal
                headerText="Rule Version Selection"
                isOpen={isOpenRule}
                onClose={onCloseRule}
                reactComponent={
                    <RuleVersionSelection
                        onCloseRule={onCloseRule}
                        viewedId={viewedId}
                    />
                }
            />
        </DragDropContext>
    )
})
