import { Box, Text, Center } from '@chakra-ui/layout'
import { chakra } from 'utils/chakra'
import { colors } from 'theme/colors'

export const StatusBox = chakra(Box, {
    position: 'relative',
    borderRadius: '6px',
    padding: '4px 12px 4px 18px',
    minHeight: '20px',
})

export const StatusText = chakra(Text, {
    textTransform: 'capitalize',
    color: 'color.100',
})

export const IconBox = chakra(Box, {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    top: '50%',
    transform: 'translate(0, -50%)',
    left: '-9px',
})

export const SplitStyled = chakra(Center, {
    width: '18px',
    height: '18px',
    borderRadius: '50%',
    backgroundColor: `${colors.red[600]}`,
    paddingRight: '1px',
})
