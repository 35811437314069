import { Flex } from '@chakra-ui/layout'
import { chakra } from 'utils/chakra'

export const DatePickersBox = chakra(Flex, {
    justifyContent: 'space-between',
})

export const DatePickerBox = chakra(Flex, {
    flexDirection: 'column',
})

export const FlexBox = chakra(Flex, {
    gap: '10px',
})
