import { AuthType, IUser, UserRole, UserState } from 'api/models';
import { UsersApi } from 'api/users';
import { makeAutoObservable } from 'mobx';
import { notificationStore } from 'stores';
import { StatusType } from 'types/chart';
import { UUID } from 'types/core';
import { defaultPaging, IUserModel, IUserRoleModel, PagingModel, userRoleModels } from './models';

const toUserModel = (user: IUser, number: number, tenantAuthType: string | null): IUserModel => ({
    ...user,
    displayName: (`${user.firstName} ${user.lastName}`.trim().length > 0 ?
        `${user.firstName} ${user.lastName}`.trim() :
        user.email),
    number,
    isActive: user.state === UserState.Active,
    isEditable: tenantAuthType === AuthType.Password,
})

const toRolesModel = (roles: UserRole[]): IUserRoleModel[] => userRoleModels
    .map(m => ({
        ...m,
        isAssigned: roles.some(r => r === m.role)
    }))

export class TenantUsersStore {

    public paging: PagingModel = defaultPaging
    public users: IUserModel[] = []
    public currentUser: IUserModel | null = null
    public currentRoles: IUserRoleModel[] = []
    public created: boolean = false

    public constructor() {
        makeAutoObservable(this)
    }

    public async fetch(tenantId: UUID, authType: string): Promise<void> {
        const response = await UsersApi.getPaged(tenantId, { page: this.paging.pageIndex, size: this.paging.pageSize })
        this.setPaging({ ...response })
        this.setUsers(response.items.map((user, index) =>
            toUserModel(user, (response.pageIndex - 1) * response.pageSize + index + 1, authType)))
    }

    public resetState(): void {
        this.setUsers([])
        this.setPaging(defaultPaging)
        this.setCurrentUser(null)
        this.setCurrentRoles([])
    }

    public setPaging(paging: PagingModel): void {
        this.paging = paging
    }

    public async setCurrent(userId: UUID | null, tenantId: UUID, authType: string | null): Promise<void> {
        try {
            if (!!userId) {
                let model = this.users.find(u => u.id === userId) ?? null

                if (!model) {
                    const user = await UsersApi.get(userId)
                    model = !!user ? toUserModel(user, 1, authType) : model
                }
                this.setCurrentUser(model)

                const roles = !!model ? toRolesModel(await UsersApi.getUserRoles(tenantId, userId)) : []
                this.setCurrentRoles(roles)

            } else {
                this.setCurrentUser(null)
                this.setCurrentRoles([])
            }
        } catch (ex) {
            notificationStore.triggerErrorToast(ex)
        }
    }

    public async update(
        tenantId: UUID,
        userId: UUID,
        firstName: string | null,
        lastName: string | null,
        currentPassword: string | null,
        newPassword: string | null,
        roles: string[]
    ): Promise<void> {
        try {
            await UsersApi.update(userId, {
                firstName,
                lastName,
                currentPassword,
                newPassword
            })
            await UsersApi.setUserRoles(tenantId, userId, roles)

            if (this.updateUser(userId, firstName, lastName) ||
                this.updateRoles(roles)) {
                notificationStore.toast({
                    title: 'Success',
                    description: 'The user has been updated.',
                    status: StatusType.Success
                })
            }
        } catch (ex) {
            notificationStore.triggerErrorToast(ex)
        }
    }

    public async create(
        tenantId: UUID,
        authType: string | null,
        firstName: string,
        lastName: string,
        email: string,
        password: string,
        roles: string[]
    ): Promise<void> {
        try {
            this.created = false
            const user = await UsersApi.create(tenantId, {
                firstName,
                lastName,
                email,
                password
            })
            await UsersApi.setUserRoles(tenantId, user.id, roles)
            const model = toUserModel(user, this.users.length + 1, authType)
            this.addUser(model)
            this.created = true
        } catch (ex) {
            notificationStore.triggerErrorToast(ex)
            this.created = false
        }
    }

    public async activate(user: IUserModel): Promise<void> {
        try {
            await UsersApi.activate(user.id)

            if (this.setUserIsActive(user.id, true)) {
                notificationStore.toast({
                    title: 'Success',
                    description: `The user ${user.displayName} has been activated.`,
                    status: StatusType.Success
                })
            }
        } catch (ex) {
            notificationStore.triggerErrorToast(ex)
        }
    }

    public async disable(user: IUserModel): Promise<void> {
        try {
            await UsersApi.disable(user.id)

            if (this.setUserIsActive(user.id, false)) {
                notificationStore.toast({
                    title: 'Success',
                    description: `The user ${user.displayName} has been disabled.`,
                    status: StatusType.Success
                })
            }

        } catch (ex) {
            notificationStore.triggerErrorToast(ex)
        }
    }

    private setCurrentUser(model: IUserModel | null): void {
        this.currentUser = model
    }

    private setCurrentRoles(models: IUserRoleModel[]): void {
        this.currentRoles = models
    }

    private updateUser(userId: UUID, firstName: string | null, lastName: string | null): boolean {
        const model = this.users.find(u => u.id === userId)
        if (!!model) {
            model.firstName = firstName ?? model.firstName
            model.lastName = lastName ?? model.lastName
            return true
        } else if (this.currentUser?.id === userId) {
            this.currentUser.firstName = firstName ?? this.currentUser.firstName
            this.currentUser.lastName = lastName ?? this.currentUser.lastName
            return true
        }

        return false
    }

    private updateRoles(roles: string[]): boolean {
        let updated = false
        this.currentRoles.forEach(m => {
            const isAssigned = roles.some(r => r === m.role)
            updated = (isAssigned !== m.isAssigned) || updated
            m.isAssigned = isAssigned
        })
        return updated
    }

    private addUser(user: IUserModel): void {
        this.users.push(user)
    }

    private setUsers(users: IUserModel[]): void {
        this.users = users;
    }

    private setUserIsActive(userId: UUID, isActive: boolean): boolean {
        const user = this.users.find(u => u.id === userId)
        if (!!user) {
            user.isActive = isActive
            user.state = isActive ? UserState.Active : UserState.Disabled
            return true
        }
        return false
    }
}
