import React, { FC, useEffect, useState } from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { isObject } from 'lodash'
import { useDisclosure } from '@chakra-ui/hooks'
import { Box, Divider } from '@chakra-ui/layout'
import { StepInfoModal } from '../StepInfoModal'
import { IWorkflowStep } from 'types/core'
import { setCircleIcon } from 'utils/setCircleIcon'
import { categoriesIcons } from 'utils/categoriesIcons'
import { stringOrDash } from 'utils/stringOrDash'
import { auditOrderStore } from 'stores'
import {
    BlockName,
    BlueDetails,
    HeadingText,
    InfoText,
    RulesEnginePanelBox,
    RulesEnginePanelItem,
} from '../../styles'

enum IRunValuesKey {
    Parameters = 'parameters',
    Values = 'values',
}

interface StepInfoProps {
    step: IWorkflowStep
}

interface IMessage {
    Message: string
}

export const StepInfo: FC<StepInfoProps> = observer(({ step }) => {
    const [selectedValues, setSelectedValues] = useState<string[]>([])
    const { id } = useParams()
    const runValues = toJS(auditOrderStore.runValues)[step.id]
    const { isOpen, onOpen, onClose } = useDisclosure()

    const renderItems = (headingText: string, runValuesKey: IRunValuesKey) => {
        if (!runValues || !Object.keys(runValues).length || !runValues[runValuesKey].length) return null
        const runValuesItems = runValues[runValuesKey]

        const mapValue = (value: unknown) => {
            if (value === false) return 'No'
            if (value === true) return 'Yes'
            if (typeof value === 'string' || typeof value === 'number') return stringOrDash(value)
            return '-'
        }

        return (
            <>
                <HeadingText>{headingText}</HeadingText>
                {runValuesItems.map(({ value, name, id }, i) => {
                    if (Array.isArray(value)) {
                        return (
                            <Box key={i}>
                                {value.slice(0, 3).map((val: string | IMessage) => {
                                    if (typeof val === 'string') {
                                        return (
                                            <Box key={val}>
                                                <InfoText>{stringOrDash(val)}</InfoText>
                                                <Divider width="92%" marginTop="5px" />
                                            </Box>
                                        )
                                    } else {
                                        return Object.values(val).map((objValue => (
                                            <Box key={objValue}>
                                                <InfoText>{stringOrDash(objValue)}</InfoText>
                                                <Divider width="92%" marginTop="5px" />
                                            </Box>
                                        )))
                                    }
                                })}
                                {value.length > 3 && (
                                    <BlueDetails
                                        onClick={() => {
                                            setSelectedValues(value)
                                            onOpen()
                                        }}
                                    >
                                        See all {IRunValuesKey.Values ? 'selected' : 'received'} values
                                    </BlueDetails>
                                )}
                            </Box>
                        )
                    } else if (isObject(value)) {
                        return Object.values(value).map(((objValue, index) => (
                            <Box key={index}>
                                <InfoText>{mapValue(objValue)}</InfoText>
                                <Divider width="92%" marginTop="5px" />
                            </Box>
                        )))
                    }

                    return (
                        <Box key={i} width="150px">
                            <InfoText>{mapValue(value)}</InfoText>
                            <Divider width="92%" marginTop="5px" />
                        </Box>
                    )
                })}
            </>
        )
    }

    useEffect(() => {
        if (id && step.id) auditOrderStore.getRunValues(id, step.id)
    }, [id, step.id])

    return (
        <>
            <RulesEnginePanelBox>
                <RulesEnginePanelItem display="flex" flexGrow={2}>
                    <Box paddingTop="3px" alignSelf="center">{setCircleIcon(step.status)}</Box>
                    <Box marginLeft="5px" alignSelf="center">{categoriesIcons[step.category]}</Box>
                    <BlockName>{stringOrDash(step.name)}</BlockName>
                </RulesEnginePanelItem>
                <RulesEnginePanelItem marginTop="9px" flexGrow={1}>
                    {renderItems('LOGIC', IRunValuesKey.Parameters)}
                </RulesEnginePanelItem>
                <RulesEnginePanelItem marginTop="9px" flexGrow={1}>
                    {renderItems('OUTCOME', IRunValuesKey.Values)}
                </RulesEnginePanelItem>
                <RulesEnginePanelItem flexGrow={5} />
                <RulesEnginePanelItem flexGrow={0.3} />
            </RulesEnginePanelBox>
            <Divider marginTop="15px" />
            <StepInfoModal
                isOpen={isOpen}
                onClose={onClose}
                selectedValues={selectedValues}
            />
        </>
    )
})
