import { tabsAnatomy as parts } from '@chakra-ui/anatomy'
import { PartsStyleObject } from '@chakra-ui/theme-tools'

const baseStyle: PartsStyleObject<typeof parts> = {
    tab: {
        boxShadow: 'none',
        _focus: {
            boxShadow: 'none',
        },
    },
}
const variants: Record<string, PartsStyleObject<typeof parts>> = {
    line: {
        tab: {
            color: 'color.100',
            padding: '0',
            margin: '0',
            _selected: {
                boxShadow: 'none',
                color: 'color.100',
                borderColor: 'blue.200',
            },
            _active: {
                boxShadow: 'none',
                color: 'white',
                borderColor: 'blue.200',
                background: 'none',
            },
        },
        tablist: {
            color: 'white',
            borderBottom: '0',
        },
    },
}
export const Tabs = {
    parts: parts.keys,
    baseStyle,
    variants,
}
