import React, { useEffect } from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { Button } from '@chakra-ui/button'
import {  Flex } from '@chakra-ui/layout'
import { ShortWorkflow, UUID } from 'types/core'
import { prioritizeStore, workflowVersionsStore } from 'stores'
import { headerOptions } from 'components/RuleHistory/mock'
import { InfoDisplay } from 'components/RuleHistory/content/InfoDisplay'
import { Filters } from 'components/RuleHistory/filters'
import { ItemsHeader } from 'components/RuleHistory/content/ItemsHeader'
import { RuleVersionItem } from 'components/RuleHistory/content/RuleVersionItem'
import { NumericPagination } from 'components/NumericPagination'
import {
    ClearButtonBox,
    NoItemsBox,
    StyledButton,
} from 'components/RuleHistory/styles'

interface IRuleVersionSelection {
    viewedId: UUID | null
    onCloseRule: () => void
}

const RuleVersionSelection = observer(
    ({ viewedId, onCloseRule }: IRuleVersionSelection) => {
        const workflowVersions = toJS(workflowVersionsStore.workflowVersions)
        const selectedWorkflow = toJS(workflowVersionsStore.selectedWorkflow)
        const filters = toJS(workflowVersionsStore.filters)
        const pages = toJS(workflowVersionsStore.pages)

        const onChangePage = async (page: number): Promise<void> => {
            workflowVersionsStore.setFilters({ page })
            await workflowVersionsStore.fetchWorkflowByHeaderId()
        }

        const handleClearAll = async (): Promise<void> => {
            workflowVersionsStore.clearFilters()
            await workflowVersionsStore.fetchWorkflowByHeaderId()
        }

        const onChooseVersion = (): void => {
            prioritizeStore.updateWorkflowVersion(selectedWorkflow)
            onCloseRule()
        }

        useEffect(() => {
            if (!viewedId) return

            workflowVersionsStore.setFilters({
                workflowHeaderId: viewedId,
            })
            workflowVersionsStore.fetchWorkflowByHeaderId()
        }, [viewedId])

        return (
            <>
                <InfoDisplay
                    isRuleVersionSelection
                    onHistoryClose={onCloseRule}
                />
                <Filters isRuleVersionSelection />
                {workflowVersionsStore.isFiltersApplied() && (
                    <ClearButtonBox>
                        <Button variant="underline" onClick={handleClearAll}>
                            Clear All
                        </Button>
                    </ClearButtonBox>
                )}
                <ItemsHeader options={headerOptions} />
                {!!workflowVersions?.length ? (
                    workflowVersions.map((workflowVersion: ShortWorkflow) => {
                        return (
                            <RuleVersionItem
                                key={workflowVersion.id}
                                workflowVersion={workflowVersion}
                            />
                        )
                    })
                ) : (
                    <NoItemsBox>No Items</NoItemsBox>
                )}
                <Flex marginTop="50px" justifyContent="flex-end">
                    <NumericPagination
                        marginRight="50px"
                        pages={pages}
                        items={workflowVersions.length}
                        activePage={filters.page}
                        onChangePage={onChangePage}
                    />
                    <Flex>
                        <StyledButton onClick={onCloseRule} marginRight="40px">
                            Cancel
                        </StyledButton>
                        <StyledButton onClick={onChooseVersion}>
                            Choose version
                        </StyledButton>
                    </Flex>
                </Flex>
            </>
        )
    }
)

export default RuleVersionSelection
