import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'
import { useColorMode } from '@chakra-ui/react'
import { Theme } from '../types/core'

interface IOrderCancelIcon extends IconProps {
    cancelColor: string
}

export const OrderCancelIcon: FC<IOrderCancelIcon> = ({ cancelColor, ...props }) => {
    const { colorMode } = useColorMode()

    return (
        <Icon
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_979_10762)">
                <rect
                    width="24"
                    height="24"
                    rx="12"
                    fill={colorMode === Theme.Dark ? '#091529' : 'white'}
                />
                <path
                    d="M7.57213 6.76406C8.7668 5.75277 10.3122 5.14298 12 5.14298C15.7871 5.14298 18.8571 8.21303 18.8571 12.0001C18.8571 13.6879 18.2474 15.2333 17.2361 16.428L7.57213 6.76406ZM6.764 7.57218C5.75267 8.76686 5.14286 10.3123 5.14286 12.0001C5.14286 15.7872 8.2129 18.8573 12 18.8573C13.6878 18.8573 15.2333 18.2474 16.4279 17.2361L6.764 7.57218ZM12 4.00012C7.58172 4.00012 4 7.58184 4 12.0001C4 16.4184 7.58172 20.0001 12 20.0001C16.4183 20.0001 20 16.4184 20 12.0001C20 7.58184 16.4183 4.00012 12 4.00012Z"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill={cancelColor}
                />
            </g>
            <rect
                x="0.5"
                y="0.5"
                width="23"
                height="23"
                rx="11.5"
                stroke={colorMode === Theme.Dark ? 'white' : cancelColor}
            />
            <defs>
                <clipPath id="clip0_979_10762">
                    <rect width="24" height="24" rx="12" fill="white"/>
                </clipPath>
            </defs>
        </Icon>
    )
}
