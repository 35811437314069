import { Button, Flex } from '@chakra-ui/react'
import { chakra } from 'utils/chakra'

export const MoreButton = chakra(Button, {
    backgroundColor: 'color.50',
    color: 'color.100',
    height: '1rem',
    padding: '10px',
    marginBottom: '10px',
    marginTop: '5px',
    width: '70px',
    alignSelf: 'center',
    _hover: {
        backgroundColor: 'color.75',
    }
})

export const LabelRow = chakra(Flex, {
    width: '100%',
    justifyContent: 'center',
    padding: '0 5px'
})
