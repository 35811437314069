import {
    CrossSmallIcon,
    MenuDotsIcon,
    PlainArrowBottomIcon,
    PlainArrowTopIcon,
} from 'icons'
import { chakra } from 'utils/chakra'

export const CrossSmallIconStyled = chakra(CrossSmallIcon, {
    cursor: 'pointer',
    color: 'white',
    role: 'button',
    boxSize: '13px',
    margin: '0 5px',
})

export const PlainArrowTopIconStyled = chakra(PlainArrowTopIcon, {
    cursor: 'pointer',
    color: 'white',
    role: 'button',
    boxSize: '13px',
})

export const PlainArrowBottomIconStyled = chakra(PlainArrowBottomIcon, {
    cursor: 'pointer',
    color: 'white',
    role: 'button',
    boxSize: '13px',
    marginLeft: '5px',
})

export const MenuDotsIconStyled = chakra(MenuDotsIcon, {
    boxSize: '20px',
    color: 'gray.400',
})
