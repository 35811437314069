import React, { FC } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Center, Flex, Text, VStack } from '@chakra-ui/layout'
import { MiniMenuButton } from 'components/MiniMenuButton'
import { MoreStepsCheckIcon, PageNavIcon } from 'icons'
import { routes } from 'navigation/routes'
import { progressStore } from 'stores'
import { UUID } from 'types/core'

interface Step {
    name: string
    link: (id: UUID) => string
}

const steps: Step[] = [
    {
        name: 'Nickname & Tagging',
        link: id => `${routes.changeRule}/${id}`,
    },
    {
        name: 'Logic Canvas',
        link: id => `${routes.editor}/${id}`,
    },
    {
        name: 'Review & Submit for Approval',
        link: id => `${routes.approval}/${id}`,
    },
]

interface MoreStepsButtonProps {
    activeStep: number
}

export const MoreStepsButton: FC<MoreStepsButtonProps> = ({ activeStep }) => {
    const navigate = useNavigate()
    const { id } = useParams()

    return (
        <MiniMenuButton
            title="More Steps"
            icon={<PageNavIcon boxSize="28px" />}
            iconColor="blue.200"
        >
            <Box backgroundColor="blueWhite.950">
                <VStack spacing="5px" marginTop="15px" marginBottom="20px">
                    {steps.map((step, i) => (
                        <Flex
                            key={i}
                            width="100%"
                            padding="10px 15px"
                            cursor={activeStep < i + 1 ? 'no-drop' : 'pointer'}
                            _hover={{ backgroundColor: 'blueWhite.910' }}
                            onClick={() => {
                                if (activeStep > i + 1) {
                                    progressStore.startMoving()
                                    navigate(step.link(id || ''))
                                }
                            }}
                        >
                            <Center
                                width="28px"
                                height="28px"
                                backgroundColor={
                                    activeStep < i + 1 ? 'gray.500' : 'blue.200'
                                }
                                borderRadius="50%"
                                marginRight="8px"
                                boxShadow="0px 2px 6px rgba(0, 0, 0, 0.25)"
                            >
                                {activeStep > i + 1 ? (
                                    <MoreStepsCheckIcon boxSize="12px" />
                                ) : activeStep < i + 1 ? (
                                    <Text variant="supertitle" color="color.50">
                                        {i + 1}
                                    </Text>
                                ) : (
                                    <Text variant="supertitle" color="white">{i + 1}</Text>
                                )}
                            </Center>
                            <Center flex="1">
                                <Text
                                    width="100%"
                                    variant="h5"
                                    color={
                                        activeStep < i + 1
                                            ? 'gray.400'
                                            : 'color.100'
                                    }
                                >
                                    {step.name}
                                </Text>
                            </Center>
                        </Flex>
                    ))}
                </VStack>
            </Box>
        </MiniMenuButton>
    )
}
