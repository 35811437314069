import React, { FC, useEffect, useState } from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { Accordion } from '@chakra-ui/accordion'
import { Box } from '@chakra-ui/react'
import { CustomAccordion } from 'components/baseComponents/Accordion'
import { CustomAccordionButton } from '../AccordionButton'
import { AccordionsHeader } from '../AccordionsHeader'
import { CustomAccordionPanel } from '.'
import { orderStore } from 'stores'
import { IOrder } from 'types/core'
import {
    accordionHeaderOptions,
    accordionHeaderOptionsOrderSimulate,
} from 'pages/HomePage/mock'
import { CategorySplitBlockIcon } from 'icons'
import { SplitBlockIcon } from 'icons/style'
import { SplitBoxWrap, BoxTitle, SplitStyled, AccordionMain } from 'pages/HomePage/styles'

interface CustomSplitPanelProps {
    isOrderSimulated?: boolean
    isFiltersOpen?: boolean
    splitOrderChildData?: IOrder
    toRetry?: (orderID: string) => boolean
    handleCheckChange?: (orderID: string) => void
    childOrderHandler?: (orderID: IOrder) => void
}

export const CustomSplitPanel: FC<CustomSplitPanelProps> = observer(({
    isFiltersOpen,
    splitOrderChildData,
    toRetry = () => false,
    handleCheckChange,
    childOrderHandler,
    isOrderSimulated
}) => {
    const [opened, setOpened] = useState<number[]>([])

    const page = toJS(orderStore.filters.page)

    useEffect(() => {
        setOpened([])
    }, [page])

    return (
        <Box onClick={e => e.stopPropagation()}>
            <SplitStyled>
                <SplitBoxWrap>
                    <SplitBlockIcon><CategorySplitBlockIcon /></SplitBlockIcon>
                    <BoxTitle>Split Orders</BoxTitle>
                </SplitBoxWrap>
                <AccordionMain>
                    <Accordion
                        allowMultiple
                        allowToggle
                        index={opened}
                        onChange={(expandedIndex: number[]) =>
                            setOpened(expandedIndex)
                        }
                    >
                        <AccordionsHeader
                            options={
                                isOrderSimulated
                                    ? accordionHeaderOptionsOrderSimulate
                                    : accordionHeaderOptions
                            }
                            isFiltersOpen={isFiltersOpen}
                            isSplitPanel
                        />
                        {splitOrderChildData?.children?.map(
                            (splitOrder: IOrder, index: number) => {
                                return (
                                    <CustomAccordion
                                        key={splitOrder.id}
                                        AccordionButtonContent={
                                            <CustomAccordionButton
                                                order={splitOrder}
                                                isOpen={opened.includes(index)}
                                                toRetry={toRetry}
                                                handleCheckChange={handleCheckChange}
                                                isFiltersOpen={isFiltersOpen}
                                                isIconDashboard
                                                childOrderHandler={childOrderHandler}
                                                isOnlyOne={false}
                                                isWorkFlowTable={isOrderSimulated}
                                                isOrderSimulated={isOrderSimulated}
                                            />
                                        }
                                        AccordionPanelContent={
                                            <CustomAccordionPanel
                                                order={splitOrder}
                                                splitOrderChildData={splitOrder}
                                                toRetry={toRetry}
                                                handleCheckChange={handleCheckChange}
                                                isFiltersOpen={isFiltersOpen}
                                                childOrderHandler={
                                                    childOrderHandler
                                                }
                                                isOrderSimulated={isOrderSimulated}
                                            />
                                        }
                                    />
                                )
                            }
                        )}
                    </Accordion>
                </AccordionMain>
            </SplitStyled>
        </Box>
    )
})
