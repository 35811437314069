import React, { Dispatch, FC, useState } from 'react'
import { useColorMode } from '@chakra-ui/react'
import { ActionMeta, MultiValueGenericProps } from 'react-select'
import { MultiSelect } from 'components/baseComponents/MultiSelect'
import { CheckboxOption as Option } from 'components/baseComponents/MultiSelect/CheckboxOption'
import { separateComma } from 'utils/dataAdaptation'
import { selectAllOption, rulesStatusOptions } from 'pages/HomePage/mock'
import { OptionData } from 'components/baseComponents/Selects'
import { MultiValue, container, multiSelectStyles } from './styles'

interface MultiSelectProps {
    onChange: Dispatch<string>
    placeholder?: string
}

export const MultiSelectContainer: FC<MultiSelectProps> = ({ onChange, placeholder = '' }) => {
    const [optionSelected, setOptionSelected] = useState<readonly OptionData[] | null>([...rulesStatusOptions, selectAllOption])
    const { colorMode } = useColorMode()

    const selectedValues = {
        MultiValueContainer: (
            {
                selectProps: { value: selectedValues },
                data: { value, label },
            }: MultiValueGenericProps<OptionData[]>
        ) => {
            if (!selectedValues || !Array.isArray(selectedValues)) return <></>

            const valuesLength = selectedValues.length
            const currentIndex = selectedValues.findIndex(({ value: val }: { value: string }) => val === value)

            if (valuesLength === rulesStatusOptions.length + 1) {
                const isAllSelected = value === selectAllOption.value
                return isAllSelected ? <MultiValue>All Statuses</MultiValue> : <></>
            }
            if (currentIndex < 1) {
                const isFirstValue = value === selectedValues[0].value
                return isFirstValue
                    ? <MultiValue>{label}</MultiValue>
                    : <MultiValue>, {label}</MultiValue>
            }
            if (currentIndex === selectedValues.length - 1) return <MultiValue>&nbsp;+ {selectedValues.length - 1}</MultiValue>
            return <></>
        }
    }

    const selectAllPressed = (actionSelected: boolean) => {
        if (actionSelected) {
            onChange(separateComma(rulesStatusOptions))
            setOptionSelected([selectAllOption, ...rulesStatusOptions])
        } else {
            onChange('')
            setOptionSelected(null)
        }
    }

    const handleChange = (
        selectedValues: readonly OptionData[],
        { action, option }: ActionMeta<OptionData>,
    ) => {
        const actionSelected = action === 'select-option'
        const exceptAllOption = selectedValues.filter(({ value }) => value !== '*')

        if (option && option.value === '*') return selectAllPressed(actionSelected)

        onChange(separateComma(exceptAllOption))
        setOptionSelected(
            selectedValues.length === rulesStatusOptions.length && actionSelected
                ? [selectAllOption, ...selectedValues]
                : exceptAllOption
        )
    }

    return (
        <MultiSelect
            components={{ Option, IndicatorSeparator: () => null, ...selectedValues }}
            options={rulesStatusOptions}
            selectAllOption={selectAllOption}
            optionSelected={optionSelected}
            placeholder={placeholder}
            customStyles={{
                ...multiSelectStyles(colorMode),
                container,
            }}
            onChange={handleChange}
            isClearable={false}
        />
    )
}
