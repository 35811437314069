import { StepsAPI } from 'api/steps'
import { WorkflowsAPI } from 'api/workflows'
import { PortType } from 'types/api'
import { LayoutData, UUID, WorkflowStep } from 'types/core'

export class StepsService {
    public static async add(
        workflow: UUID,
        metadata: UUID,
        layout: LayoutData
    ): Promise<WorkflowStep> {
        return await WorkflowsAPI.addStep(workflow, metadata, layout)
    }

    public static async remove(id: UUID) {
        await StepsAPI.deleteById(id)
    }

    public static async move(id: UUID, layout: LayoutData) {
        await StepsAPI.updateLayout(id, layout)
    }

    public static async connect(target: UUID, source: UUID, port: PortType) {
        return await StepsAPI.changeParent(target, source, port)
    }

    public static async disconnect(target: UUID) {
        return await StepsAPI.changeParent(target)
    }
}
