import React, { FC } from 'react'
import { CanvasHeaderContainer, CanvasHeaderText } from './styles'

export const CanvasHeader: FC = ({ children }) => {
    return (
        <CanvasHeaderContainer>
            <CanvasHeaderText>
                {children}
            </CanvasHeaderText>
        </CanvasHeaderContainer>
    )
}
