import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const SimpleArrowUpIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            verticalAlign="-webkit-baseline-middle"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#0074C0"
                d="M10.2761 5.79416C10.5496 5.51955 10.5496 5.07828 10.2761 4.80471L5.63456 0.16351C5.41675 -0.0545047 5.06478 -0.0545046 4.84698 0.16351L0.205255 4.80471C-0.068417 5.07828 -0.0684169 5.51955 0.205255 5.79417C0.479031 6.06773 0.920302 6.06773 1.19397 5.79416L5.2436 1.74936L9.29291 5.7994C9.56124 6.06773 10.0077 6.06773 10.2761 5.79416Z"
            />
        </Icon>
    )
}
