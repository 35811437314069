import React, { FC } from 'react'
import {
    RectangleErrorIcon,
    RectangleSplitIcon,
    RectangleSuccessIcon,
    RectangleWarningIcon,
} from 'icons'
import { IconStatus } from 'components/StatusTag/mock'

interface RectangleIconProps {
    status: IconStatus
    iconWidth?: number
}

export const RectangleIcon: FC<RectangleIconProps> = ({ status }) => {
    switch (status) {
        case 'success':
        case 'cancelCompleted':
            return <RectangleSuccessIcon />
        case 'warning':
        case 'cancelRequested':
            return <RectangleWarningIcon />
        case 'split':
            return <RectangleSplitIcon color="#8A78FA"/>
        case 'error':
        case 'cancelFailed':
            return <RectangleErrorIcon />
        default:
            return <RectangleErrorIcon />
    }
}
