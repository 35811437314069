import React from 'react'
import { toJS } from 'mobx'
import { Box } from '@chakra-ui/layout'
import { observer } from 'mobx-react-lite'
import { KPI } from './widgets/KPIMetrics'
import { MyRules } from './widgets/MyRules'
import { RuleApprovals } from './widgets/RuleApprovals'
import { OrderStatusTracking } from '../OrderStatusTracking'
import { userStore } from 'stores'
import { ApproverDashboardGrid, PlainUserDashboardGrid } from './styles'

export const Dashboard = observer(() => {
    const { isApprover } = toJS(userStore.currentRoles)
    const DashboardGrid = isApprover
        ? ApproverDashboardGrid
        : PlainUserDashboardGrid

    return (
        <DashboardGrid>
            <MyRules gridArea="rules" />
            {isApprover && <RuleApprovals gridArea='approvals' />}
            <Box gridArea="orders">
                <OrderStatusTracking />
            </Box>
            <KPI gridArea="kpi" />
        </DashboardGrid>
    )
})
