import { useEffect, useState } from 'react'
import { Button, Checkbox, Text } from '@chakra-ui/react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useForm, useFieldArray } from 'react-hook-form'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
} from '@chakra-ui/react'
import { NumericPagination } from 'components/NumericPagination'
import { Tr, Td, Box } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { routes } from 'navigation/routes'
import { CustomSelectContainer } from './select'
import { Search } from './Search'
import { checkRoleForConfiguration } from 'utils/jwt'
import { PlusIcon } from 'icons'
import {
    IAttributeBody,
    IAttributeRequest,
    IFieldAttributeCategory,
} from 'types/core'
import { customeAttributeStore, userStore } from 'stores'
import {
    GrigLayout,
    GridHeader,
    TextHeader,
    GridCategory,
    SaveButton,
    GridTable,
    GridFooter,
    AddButton,
    TableHeader,
    Theader,
    MainTable,
    TableData,
    InputName,
    GridDivider,
    FlexFilter,
    TableBody,
    FlexFooter,
    FlexSelect,
    FlexAddCategory,
    NoRecords,
    AlertText,
    FooterFlex,
} from './styles'

interface ICategory {
    selectedCategoryIndex: number
    selectedCategory: IUpdatedSelectedCategory
}
interface IUpdatedSelectedCategory {
    label: string
    value: string
}

export const AttributeConfiguration = observer(() => {
    const [valueName, setValueName] = useState<IAttributeRequest>({
        value: '',
        label: '',
        id: '',
    })
    const [status, setStatus] = useState(false)
    const [category, setCategory] = useState<ICategory[]>([])
    const [rederPage, setRenderPage] = useState<boolean>(false)
    const [openModel, setOpenModel] = useState<boolean>(false)
    const [apicall, setApicall] = useState<boolean>(false)
    const attributeItem = toJS(customeAttributeStore?.attributeListItem)
    const categoryList = toJS(customeAttributeStore?.selectCategoryItems)
    const currentRole = toJS(userStore.currentRoles)
    const navigate = useNavigate()
    const showCategoryList = (): void => {
        navigate(routes?.categoryList)
        customeAttributeStore.reloadPages()
    }
    const { register, control, handleSubmit, reset, setValue } =
        useForm<IFieldAttributeCategory>({
            mode: 'onChange',
            reValidateMode: 'onChange',
        })

    useEffect(() => {
        customeAttributeStore.attrributeCategoryList()
    }, [])

    useEffect(() => {
        if (valueName?.label !== '')
            customeAttributeStore.getAttributeListItem(valueName)
        return () => {
            customeAttributeStore.clean()
        }
        // Need for improvement. Warnings console
        /* eslint-disable */
    }, [valueName?.label, rederPage, apicall])

    const { fields } = useFieldArray({
        control,
        name: 'attributeItems',
    })

    useEffect(() => {
        setCategory([])
        reset({
            attributeItems: toJS(customeAttributeStore?.attributeListItem),
        })
    }, [
        valueName,
        reset,
        setValue,
        // Need for improvement. Warnings console
        // eslint-disable-next-line react-hooks/exhaustive-deps
        attributeItem?.length && attributeItem[0].attributeId,
        rederPage,
        customeAttributeStore?.attributeListItem,
    ])

    const onSubmit = async (data: IFieldAttributeCategory) => {
        const attributeItems = [...data.attributeItems]
        let updatedAttributeCategoriesList: IAttributeBody[] = []
        attributeItems &&
            attributeItems?.forEach((item, ind) => {
                const obj = {
                    attributeId: item?.attributeId,
                    attributeName: item?.attributeName,
                    displayName: item?.displayName,
                    status: item?.status,
                    categoryId:
                        category?.filter(
                            (item: ICategory) =>
                                ind === item?.selectedCategoryIndex
                        )?.[0]?.selectedCategory?.value ||
                        valueName?.value ||
                        '',
                }
                updatedAttributeCategoriesList.push(obj)
            })
        await customeAttributeStore.saveCategoty({
            body: updatedAttributeCategoriesList,
        })
        setRenderPage(previousValue => !previousValue)
        setCategory([])
        setOpenModel(false)
        setStatus(false)
    }
    
    const onCloseModel = () => {
        setApicall(previousValue => !previousValue)
        setOpenModel(false)
    }
    return (
        <GrigLayout>
            <form onSubmit={handleSubmit(onSubmit)}>
                <GridHeader>
                    <TextHeader>Attribute Configuration</TextHeader>
                    <GridDivider />
                </GridHeader>
                <GridCategory>
                    <FlexFilter>
                        <Search shipper={valueName} />
                        <FlexSelect>
                            <CustomSelectContainer
                                datacategary={categoryList}
                                onChange={setValueName}
                                type="category"
                            />
                        </FlexSelect>
                    </FlexFilter>
                </GridCategory>
                {valueName?.label &&
                    (!!attributeItem?.length ? (
                        <>
                            <GridTable>
                                <MainTable>
                                    <Theader>
                                        <Tr>
                                            <TableHeader>
                                                Attributes
                                            </TableHeader>
                                            <TableHeader>
                                                Display Name
                                            </TableHeader>
                                            <TableHeader>
                                                Categories
                                            </TableHeader>
                                            <TableHeader>Status</TableHeader>
                                        </Tr>
                                    </Theader>
                                    <TableBody>
                                        {fields?.map((attr, i) => (
                                            <Tr key={attr?.id}>
                                                <TableData>
                                                    <Text>
                                                        {attr.attributeName}
                                                    </Text>
                                                </TableData>
                                                <TableData>
                                                    <InputName
                                                        defaultValue={
                                                            attr.displayName
                                                        }
                                                        {...register(
                                                            `attributeItems.${i}.displayName`
                                                        )}
                                                        onChange={e => {
                                                            const dataChange = attr.displayName === e.target.value
                                                            setStatus(!dataChange)
                                                        }}
                                                        disabled={!checkRoleForConfiguration(currentRole)}
                                                    />
                                                </TableData>
                                                <Td>
                                                    <CustomSelectContainer
                                                        fieldCategory={
                                                            attr.categories
                                                        }
                                                        onChange={(
                                                            e: IUpdatedSelectedCategory
                                                        ) => {
                                                            const selectedCategoryValues: ICategory =
                                                                {
                                                                    selectedCategory:
                                                                        e,
                                                                    selectedCategoryIndex:
                                                                        i,
                                                                }
                                                                const dataChange = attr.categories.some(selectedOption => selectedOption.isSelected &&
                                                                    selectedOption.categoryName === selectedCategoryValues.selectedCategory.label)
                                                            setCategory([
                                                                ...category,
                                                                selectedCategoryValues,
                                                            ])
                                                            setStatus(
                                                                !dataChange
                                                            )
                                                        }}
                                                    />
                                                </Td>
                                                <TableData>
                                                    <Checkbox
                                                        {...register(
                                                            `attributeItems.${i}.status`
                                                        )}
                                                        onChange={e => {
                                                            const dataChange =
                                                                attr.status ===
                                                                e.target.checked
                                                            setStatus(
                                                                !dataChange
                                                            )
                                                        }}
                                                        isDisabled={!checkRoleForConfiguration(currentRole)}
                                                    >
                                                        {attr.status}
                                                    </Checkbox>
                                                </TableData>
                                            </Tr>
                                        ))}
                                    </TableBody>
                                </MainTable>
                            </GridTable>
                            <GridFooter>
                                <FlexFooter>
                                    <NumericPagination
                                        pages={customeAttributeStore.pages}
                                        activePage={
                                            customeAttributeStore.filters.page
                                        }
                                        onChangePage={page => {
                                            if (status) {
                                                setOpenModel(true)
                                                setStatus(false)
                                            } else {
                                                onCloseModel()
                                            }
                                            customeAttributeStore.setFilters({
                                                page,
                                            })
                                        }}
                                    />
                                    {checkRoleForConfiguration(currentRole) && (
                                        <FooterFlex>
                                            <AddButton>
                                                <FlexAddCategory>
                                                    <PlusIcon />
                                                    <Box onClick={showCategoryList}>
                                                        Add Categories
                                                    </Box>
                                                </FlexAddCategory>
                                            </AddButton>
                                            <SaveButton type="submit">
                                                Save
                                            </SaveButton>
                                        </FooterFlex>
                                    )}
                                </FlexFooter>
                            </GridFooter>
                        </>
                    ) : (
                        <NoRecords>No Records Found</NoRecords>
                    ))}
                <Modal
                    blockScrollOnMount={false}
                    isOpen={openModel}
                    onClose={onCloseModel}
                    closeOnOverlayClick={false}
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalBody paddingTop="25px">
                            <AlertText>
                                Do You want save the Changes ?
                            </AlertText>
                        </ModalBody>

                        <ModalFooter marginRight="120px">
                            <Button
                                colorScheme="red"
                                variant="outline"
                                mr={8}
                                onClick={onCloseModel}
                            >
                                No
                            </Button>
                            <Button
                                colorScheme="red"
                                variant="outline"
                                type="submit"
                                onClick={handleSubmit(onSubmit)}
                            >
                                Yes
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>{' '}
            </form>
        </GrigLayout>
    )
})
