import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { SingleValue } from 'react-select'
import { Box, Container, Divider, Link, Stack, Text } from '@chakra-ui/layout'
import { Link as DomLink, useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { toJS } from 'mobx'
import { CounteredInput } from 'components/CounteredInput'
import { CounteredTextarea } from 'components/CounteredTextarea'
import { PrioritizeRules } from 'components/PrioritizeRules'
import { CustomSelectContainer as Select } from './content/Select'
import {
    notificationStore,
    prioritizeStore,
    rulesSetsStore,
    userStore,
    wowStore,
} from 'stores'
import { DESCRIPTION_LENGTH, NAME_LENGTH } from 'constants/rulesSets'
import { routes } from 'navigation/routes'
import { ISelectOption } from 'types/core'
import {
    BottomButtonBox,
    BottomButtonsBox,
    BottomLeftButtons,
    BottomRightButtons,
    BoxStyled,
    InputsBox,
    NavTitle,
    OutlinedButton,
    SetRulesButton,
    Title,
    TitleBox,
} from './styles'

interface RulesSetsForm {
    name: string
    description: string
}

export const CreationRulesSetsPage = observer(() => {
    const [editOpen, setEditOpen] = useState(false)
    const [orderSource, setOrderSource] = useState<SingleValue<ISelectOption>>(null)
    const navigate = useNavigate()
    const { id = '' } = useParams()
    const {
        register,
        watch,
        setValue,
        formState: { errors },
    } = useForm<RulesSetsForm>({ mode: 'onChange', reValidateMode: 'onChange' })

    const wow = wowStore.wow
    const defaultRules = prioritizeStore.defaultRules
    const nameRemaining = NAME_LENGTH - watch('name', '')?.length
    const messageRemaining =
        DESCRIPTION_LENGTH - watch('description', '')?.length
    const pageType = id ? 'Edit ' : 'Create '
    const { isCreator } = toJS(userStore.currentRoles)

    const onEditOpen = (): void => setEditOpen(true)

    const onCreate = async (): Promise<void> => {
        try {
            const data = await prioritizeStore.wowStore.create(
                watch('name'),
                watch('description')
            )

            if (orderSource) {
                const filterMapping = rulesSetsStore.orderSourceMappings.filter(
                    ({ wowId, orderSourceId }) =>
                        orderSourceId.toLowerCase() !==
                            orderSource.value.toLowerCase() &&
                        wowId.toLowerCase() !== data.id.toLowerCase()
                )
                const updatedOrderSourceMapping = [
                    ...filterMapping,
                    {
                        wowId: data.id,
                        orderSourceId: orderSource.value,
                    },
                ]

                await rulesSetsStore.putOrderSourceMapping(
                    updatedOrderSourceMapping
                )
            }

            await prioritizeStore.putWorkflowsById(data.id)
            await prioritizeStore.wowStore.fetch(data.id)

            navigate(routes.prioritize)
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    const onEdit = async (): Promise<void> => {
        try {
            await prioritizeStore.wowStore.edit(
                id,
                watch('name'),
                watch('description')
            )

            if (orderSource) {
                const filterMapping = rulesSetsStore.orderSourceMappings.filter(
                    ({ wowId, orderSourceId }) =>
                        wowId.toLowerCase() !== id.toLowerCase() &&
                        orderSourceId.toLowerCase() !==
                            orderSource.value.toLowerCase()
                )
                const updatedOrderSourceMapping = [
                    ...filterMapping,
                    { wowId: id, orderSourceId: orderSource.value },
                ]

                await rulesSetsStore.putOrderSourceMapping(
                    updatedOrderSourceMapping
                )
            } else {
                const filterMapping = rulesSetsStore.orderSourceMappings.filter(
                    ({ wowId }) => wowId.toLowerCase() !== id.toLowerCase()
                )

                await rulesSetsStore.putOrderSourceMapping(filterMapping)
            }

            await prioritizeStore.putWorkflowsById(id)
            await prioritizeStore.wowStore.fetch(id)

            navigate(routes.prioritize)
        } catch (err) {
            notificationStore.triggerErrorToast(err)
        }
    }

    const onCancel = (): void => navigate(routes.prioritize)

    useEffect(() => {
        rulesSetsStore.search()
        rulesSetsStore.wowOrderSourceMappings()
        rulesSetsStore.getOrderSources()

        return () => {
            rulesSetsStore.clean()
        }
    }, [])

    useEffect(() => {
        if (id) wowStore.getById(id)

        return () => {
            wowStore.clean()
        }
    }, [id])

    useEffect(() => {
        const findOrderSource = rulesSetsStore.findOrderSourceByWowId(id)

        if (Object.keys(wow).length) {
            setValue('name', wow.name)
            setValue('description', wow.description)
        }
        if (id && findOrderSource) {
            setOrderSource({
                value: findOrderSource.id,
                label: findOrderSource.name,
            })
        }
        // Need for improvement. Warnings console
        /* eslint-disable */
    }, [wow, id])

    return (
        <Container maxWidth="100%" minWidth="1xl" margin="0" padding="0">
            <Stack
                as={Box}
                spacing={{ base: 8, md: 14 }}
                py={{ base: 20, md: 4 }}
            >
                <Stack>
                    <BoxStyled>
                        <TitleBox>
                            <NavTitle>
                                <Link to={routes.prioritize} as={DomLink}>
                                    Rule Sets
                                </Link>
                                {' >'} {pageType} Set Rules
                            </NavTitle>
                            <Title>{pageType} Set Rules</Title>
                        </TitleBox>
                        <Divider margin="8px 0" />
                        <InputsBox>
                            <Text variant="paragraphsmall">Set Rules Name</Text>
                            <CounteredInput
                                placeholder="Enter Name..."
                                size="sm"
                                width="430px"
                                marginTop="8px"
                                _disabled={{ color: 'inherit' }}
                                remaining={nameRemaining || NAME_LENGTH}
                                isInvalid={!!errors.name}
                                description={errors.name?.message}
                                isDisabled={!isCreator}
                                {...register('name', {
                                    required:
                                        'This field cannot be blank. Please enter a nickname to continue.',
                                    maxLength: {
                                        value: NAME_LENGTH,
                                        message: `This field max length is ${NAME_LENGTH}`,
                                    },
                                })}
                            />
                            <Text marginBottom="8px" variant="paragraphsmall">
                                Order Source
                            </Text>
                            <Select
                                value={orderSource}
                                setValue={setOrderSource}
                                isDisabled={!isCreator}
                            />
                            <Text variant="paragraphsmall">
                                Set Description
                            </Text>
                            <CounteredTextarea
                                placeholder="Enter Description..."
                                size="sm"
                                width="430px"
                                marginTop="8px"
                                remaining={messageRemaining || DESCRIPTION_LENGTH}
                                isInvalid={!!errors.description}
                                description={errors.description?.message}
                                isDisabled={!isCreator}
                                {...register('description', {
                                    required: 'This field cannot be blank.',
                                    maxLength: {
                                        value: DESCRIPTION_LENGTH,
                                        message: `This field max length is ${DESCRIPTION_LENGTH}`,
                                    },
                                })}
                            />
                        </InputsBox>
                        <TitleBox>
                            <Title>Rules</Title>
                        </TitleBox>
                        <Divider margin="8px 0" />
                        <PrioritizeRules
                            id={id}
                            editOpen={editOpen}
                            setEditOpen={setEditOpen}
                        />
                        {isCreator ? (
                            <BottomButtonsBox>
                                <BottomLeftButtons>
                                    <SetRulesButton onClick={onEditOpen}>
                                        {defaultRules.length
                                            ? 'Edit '
                                            : 'Choose '}
                                        Rules
                                    </SetRulesButton>
                                </BottomLeftButtons>
                                <BottomRightButtons>
                                    <OutlinedButton onClick={onCancel}>
                                        Cancel
                                    </OutlinedButton>
                                    <SetRulesButton
                                        disabled={
                                            !watch('name') ||
                                            !watch('description')
                                        }
                                        onClick={id ? onEdit : onCreate}
                                    >
                                        {id ? 'Edit ' : 'Create '} Set Rules
                                    </SetRulesButton>
                                </BottomRightButtons>
                            </BottomButtonsBox>
                        ) : (
                            <BottomButtonBox>
                                <SetRulesButton onClick={onCancel}>
                                    Cancel
                                </SetRulesButton>
                            </BottomButtonBox>
                        )}
                    </BoxStyled>
                </Stack>
            </Stack>
        </Container>
    )
})
