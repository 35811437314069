import { Box, Text } from '@chakra-ui/layout'
import { keyframes } from '@emotion/react'
import { CrossSmallIcon } from 'icons'
import { chakra } from 'utils/chakra'

const fadeIn = keyframes`
    from { opacity: 0 }
    to { opacity: 1 }
`

export const MessageBox = chakra(Box, {
    animation: `${fadeIn} 1s linear`,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px',
    minHeight: '40px',
    width: '100%',
    maxWidth: '1440px',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.25)',
    borderRadius: '6px',
})

export const InfoBox = chakra(Box, {})

export const Title = chakra(Text, {
    variant: 'h5',
    color: 'white',
})

export const IconBox = chakra(Box, {
    margin: '0 15px',
})

export const Description = chakra(Text, {
    variant: 'paragraph',
    marginTop: '4px',
    color: 'white',
})

export const CrossSmallIconStyled = chakra(CrossSmallIcon, {
    cursor: 'pointer',
    role: 'button',
    boxSize: '10px',
    color: 'white',
})
