import React, { FC } from 'react'
import { CSVLink } from 'react-csv'
import { ButtonStyled, ButtonGroupStyled } from './styles'

interface PopupButtonProps {
    onClose: () => void
    notMatchedExport: string[]
}

export const PopupButton: FC<PopupButtonProps> = ({
    onClose,
    notMatchedExport,
}) => (
    <ButtonGroupStyled>
        <ButtonStyled onClick={onClose}>Close</ButtonStyled>
        <CSVLink
            filename="notMatchedItems.csv"
            data={notMatchedExport.join('\r\n')}
        >
            <ButtonStyled>Export as CSV</ButtonStyled>
        </CSVLink>
    </ButtonGroupStyled>
)