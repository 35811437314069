import { useEffect, useState } from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { NumericPagination } from 'components/NumericPagination'
import { DatePickers } from 'pages/ReportsPage/DatePickers'
import { ReportsData } from 'pages/ReportsPage/ReportsTable'
import { getCurrentDate } from 'pages/ReportsPage/utils'
import { ExportButton } from 'pages/ReportsPage/ExportButton'
import { CustomSelectContainer } from './select'
import {
    performanceBytotalOrdersHeader,
    performanceBytotalOrdersRow,
    performanceBytotalOrdersStyles,
} from 'pages/ReportsPage/PDFStyles/performanceByTotalOrderPDFStyle'
import { ReportType } from 'types/core'
import { performanceByTotalOrdersReportStore } from 'stores'
import {
    FlexFilter,
    GridContainer,
    GridFilter,
    GridItems,
    GridPagination,
    GridTable,
    GridUser,
    SubmitButton,
    TextItems,
    TextRecords,
} from '../styles'

export const PerformaceByTotalOrders = observer(() => {
    const [shiperName, setShiperName] = useState<string>('')
    const [fromDate, setFromDate] = useState<Date | null>(null)
    const [toDate, setToDate] = useState<Date | null>(null)
    const [status, setStatus] = useState<Boolean>(false)
    const data = toJS(
        performanceByTotalOrdersReportStore.productPerformanceReports
    )
    const totalItems = toJS(performanceByTotalOrdersReportStore?.totalItems)
    const exportTotalCount = toJS(
        performanceByTotalOrdersReportStore?.exportTotalItems
    )
    const totalExportedData = toJS(
        performanceByTotalOrdersReportStore?.productPerformanceReportsExport
    )

    useEffect(() => {
        performanceByTotalOrdersReportStore.getPrerformenceSource()
        return () => {
            performanceByTotalOrdersReportStore.clean()
        }
    }, [])

    const productPsubmitDetails = async () => {
        performanceByTotalOrdersReportStore.reloadPages()
        await performanceByTotalOrdersReportStore.getProductPerformanceReport(
            fromDate?.toLocaleDateString() || '',
            toDate?.toLocaleDateString() || '',
            shiperName
        )
        setStatus(true)
    }

    const exportDetails = async () => {
        await performanceByTotalOrdersReportStore.getProductPerformanceExportData(
            fromDate?.toLocaleDateString() || '',
            toDate?.toLocaleDateString() || '',
            shiperName
        )
    }

    const onExport = () => {
        exportDetails()
    }

    return (
        <GridContainer>
            <GridUser>
                <CustomSelectContainer onChange={setShiperName} />
            </GridUser>
            <GridFilter>
                <FlexFilter>
                    <DatePickers
                        getfromDate={setFromDate}
                        getToDate={setToDate}
                    />
                    <SubmitButton onClick={productPsubmitDetails}>
                        Submit
                    </SubmitButton>
                    <ExportButton
                        performanceByTotalOrderData={totalExportedData}
                        onExport={onExport}
                        reportName={
                            ReportType.performanceReceivedBySalesChannel
                        }
                        fileName={`Performance by Total Orders(Received By Sales Channel)_ ${
                            fromDate?.toLocaleDateString() || '--'
                        } to ${toDate?.toLocaleDateString() || '--'}__(${
                            getCurrentDate() || '--'
                        })`}
                        curDate={getCurrentDate()}
                        totalItems={exportTotalCount}
                        columns={performanceBytotalOrdersHeader}
                        pdfStyles={performanceBytotalOrdersStyles}
                        rowStyles={performanceBytotalOrdersRow}
                    />
                </FlexFilter>
            </GridFilter>
            <GridTable>
                {status &&
                    (!!data?.length ? (
                        <ReportsData
                            reportType={
                                ReportType.performanceReceivedBySalesChannel
                            }
                        />
                    ) : (
                        <TextRecords>No Records Found</TextRecords>
                    ))}
            </GridTable>
            <GridItems>
                {status && !!data?.length && (
                    <TextItems>{totalItems} Items</TextItems>
                )}
            </GridItems>
            <GridPagination>
                <NumericPagination
                    pages={performanceByTotalOrdersReportStore.pages}
                    activePage={
                        performanceByTotalOrdersReportStore.filters.page
                    }
                    onChangePage={page => {
                        performanceByTotalOrdersReportStore.setFilters({
                            page,
                        })
                        performanceByTotalOrdersReportStore.getProductPerformanceReport(
                            fromDate?.toLocaleDateString() || '',
                            toDate?.toLocaleDateString() || '',
                            shiperName
                        )
                    }}
                />
            </GridPagination>
        </GridContainer>
    )
})
