import React, { Dispatch, FC, useState } from 'react'
import { CustomSelect } from 'components/baseComponents/Select'
import { MultiValue, SingleValue } from 'react-select'
import { ISelectOption } from 'types/core'
import { useColorMode } from '@chakra-ui/react'
import { reactSelectStyles } from 'components/baseComponents/Select/styles'

interface SelectProps {
    options: ISelectOption[],
    placeholder: string,
    onChange: Dispatch<string>
}

export const CustomSelectContainer: FC<SelectProps> = ({
    options,
    placeholder,
    onChange
}) => {

    const [selectValue, setSelectValue] = useState<SingleValue<ISelectOption>>(options[0] || null)
    const { colorMode } = useColorMode()

    const handleChange =  (selectedValue: SingleValue<ISelectOption> | MultiValue<ISelectOption>) => {
        if (selectedValue && 'value' in selectedValue && selectedValue.value) {
            const value = selectedValue && selectedValue.value ? selectedValue.value : 'W'
            setSelectValue(selectedValue)
            onChange(value)
        }
    }

    return (
        <CustomSelect
            selectValue={selectValue}
            options={options}
            placeholder={placeholder}
            onChange={handleChange}
            customStyles={reactSelectStyles(colorMode)}

        />
    )
}
