import React, { ChangeEvent, FC, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { InputSearchSquare } from 'components/baseComponents/InputSearchSquare'
import { customeAttributeStore } from 'stores'

export const Input: FC = observer(() => {
    const [value, setValue] = useState('')

    const handleClear = async (): Promise<void> => {
        setValue('')
        await customeAttributeStore.searchByName('')
    }

    const handleChange = (value: ChangeEvent<HTMLInputElement>): void => {
        setValue(value.target.value)
    }

    const handleSearch = async (): Promise<void> => {
        await customeAttributeStore.searchByName(value)
    }

    return (
        <InputSearchSquare
            value={value}
            placeholder="Search by Category Name..."
            handleClear={handleClear}
            handleChange={handleChange}
            handleSearch={handleSearch}
        />
    )
})
