import { Dispatch } from 'react'
import { NavigateFunction } from 'react-router-dom'
import { routes } from 'navigation/routes'
import { StatusType } from 'types/chart'
import { CreatedWorkflow, UUID } from 'types/core'
import {
    editoreStore,
    workflowStore,
    progressStore,
    dashboardStore,
    notificationStore,
} from 'stores'

interface CreateRuleForm {
    ruleName: string
    ruleDescription: string
}

export const onChange = async (
    { ruleName, ruleDescription }: CreateRuleForm,
    navigate: NavigateFunction,
    setLoading: Dispatch<boolean>,
    id?: UUID,
) => {
    try {
        setLoading(true)

        let created = {} as CreatedWorkflow

        if (id) {
            await workflowStore.changeWorkflow({
                name: ruleName,
                description: ruleDescription,
            })
        } else {
            const newCreated = await dashboardStore.myRulesStore.createNewWorkflow(
                ruleName,
                ruleDescription
            )

            if (newCreated) {
                created = newCreated
            }
        }

        setLoading(false)

        notificationStore.toast({
            title: 'Success',
            description: created.name ? `"${created.name}" rule successfully added` : 'Rule successfully changed',
            status: StatusType.Success,
        })

        editoreStore.toggleRecentlyCreated()
        progressStore.startMoving()
        navigate(`${routes.editor}/${id ? id : created.id}`)
    } catch (err) {
        setLoading(false)
        notificationStore.triggerErrorToast(err)
    }
}
