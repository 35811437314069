import { ShipperData, Shippers } from 'types/core'

export const shipperDataToString = (shipperData: ShipperData) => {
    let result: string = 'totalOrders,' + shipperData.totalOrders + ',\n'
    shipperData.shippers.forEach(
        shipper => (result += shipper.name + ',' + shipper.value + ',\n')
    )
    return result
}
export const getChartOption = (
    totalOrders: number,
    shippers: Shippers[],
    color: string
) => {
    return {
        tooltip: {
            trigger: 'item',
        },
        legend: {
            top: '220px',
            left: '10%',
            padding: [0, 0, 0, 0],
            itemWidth: 20,
            itemHeight: 4,
            textStyle: {
                color: color === 'dark' ? 'white' : 'black',
            },
        },
        series: [
            {
                type: 'pie',
                center: ['119px', '110px'],
                radius: ['85px', '65px'],
                avoidLabelOverlap: false,
                label: {
                    show: true,
                    position: 'center',
                    color: color === 'dark' ? 'white' : 'black',
                    fontWeight: 'bold',
                    fontSize: 14,
                    formatter: () => {
                        return 'Total orders:\n' + totalOrders
                    },
                },
                labelLine: {
                    show: false,
                },
                data: shippers,
            },
        ],
    }
}
