import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const PageNavIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 28 24"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g filter="url(#filter0_d)">
                <rect
                    x="1.5332"
                    width="24.9333"
                    height="22"
                    rx="2"
                    fill="url(#paint0_linear)"
                />
                <line
                    x1="10"
                    y1="5"
                    x2="23"
                    y2="5"
                    stroke="black"
                    strokeWidth="2"
                />
                <circle cx="6" cy="4.93335" r="2" fill="black" />
                <line
                    x1="10"
                    y1="11"
                    x2="23"
                    y2="11"
                    stroke="black"
                    strokeWidth="2"
                />
                <circle cx="6" cy="11" r="2" fill="black" />
                <line
                    x1="10"
                    y1="17"
                    x2="23"
                    y2="17"
                    stroke="black"
                    strokeWidth="2"
                />
                <circle cx="6" cy="17" r="2" fill="black" />
            </g>
            <defs>
                <filter
                    id="filter0_d"
                    x="0.533203"
                    y="0"
                    width="26.9331"
                    height="24"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        result="shape"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear"
                    x1="13.9999"
                    y1="0"
                    x2="13.9999"
                    y2="22"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#EFEFEF" />
                </linearGradient>
            </defs>
        </Icon>
    )
}
