import React, { FC, useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
} from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'
import { RetryReasonModal } from 'components/RetryReason/ReasonModal'
import { WarningMessageText } from './styles'

interface IRetryProps {
    isOpen: boolean
    onSubmit: (reason: string) => void
    onClose: () => void
}

export const WarningMessage: FC<IRetryProps> = observer(
({ 
    isOpen, 
    onSubmit,
    onClose 
}) => {
    const [openRetry, setOpenRetry] = useState(false)
    const onCloseRetry = (): void => setOpenRetry(false)
    const checkModalReason = () => {
        setOpenRetry(true)
        onClose()
    }
    return (
        <>
            <Modal
                onClose={onClose}
                isOpen={isOpen}
                scrollBehavior="inside"
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Warning</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <WarningMessageText isReadOnly>
                            Re-processing a large quantity or orders may
                            cause performance Issues. To narrow the number
                            to be reprocessed, please use filters to make
                            the selection, or select 'Proceed' to continue.
                        </WarningMessageText>
                    </ModalBody>
                    <ModalFooter>
                        <Button mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button mr={3} onClick={checkModalReason}>
                            Proceed
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <RetryReasonModal 
                isOpen={openRetry}
                onSubmit={onSubmit}
                onClose={onCloseRetry} 
            />
        </>
)})
