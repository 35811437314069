import { AxiosError } from 'axios'
import { notificationStore } from 'stores'
import { WorkflowsAPI } from 'api/workflows'
import { WorkflowsFilters, WorkflowsResponse, WorkflowAdd } from 'types/api'
import { StatusType } from 'types/chart'
import {
    ApprovedRejectedWorkflow,
    ChangedWorkflow,
    CreatedWorkflow,
    ShortWorkflow,
    UUID,
    Workflow,
    WorkflowApproval, WorkflowSubmitForApproval,
} from 'types/core'

export class WorkflowsService {
    public static async getAll(
        filters: WorkflowsFilters
    ): Promise<WorkflowsResponse> {
        return await WorkflowsAPI.getAll(filters)
    }

    public static async get(id: UUID): Promise<Workflow> {
        return await WorkflowsAPI.getById(id)
    }

    public static async getByWorkflowHeaderId( filters: WorkflowsFilters): Promise<WorkflowsResponse> {
        return await WorkflowsAPI.getByWorkflowHeaderId(filters)
    }

    public static async add(params: WorkflowAdd): Promise<CreatedWorkflow> {
        return await WorkflowsAPI.addNew(params)
    }

    public static async remove(id: UUID) {
        await WorkflowsAPI.deleteById(id)
    }

    public static async change(workflow: ShortWorkflow, changedWorkflow: ChangedWorkflow) {
        await WorkflowsAPI.putById(workflow.id, { ...workflow, ...changedWorkflow })
    }

    public static async submitForApproval(
        id: UUID,
        approval: WorkflowApproval | WorkflowSubmitForApproval
    ) {
        await WorkflowsAPI.submitForApproval(id, approval)
    }

    public static async addPreview(id: UUID, file: File) {
        try {
            await WorkflowsAPI.addPreview(id, file)
        } catch (error) {
            const err = error as AxiosError
            if (err.response && err.response.status === 404) {
                notificationStore.toast({
                    title: 'Error',
                    description: 'The WOW was not found',
                    status: StatusType.Error,
                })
            }
            throw error
        }
    }

    public static async approve(
        id: UUID,
        message?: string
    ): Promise<ApprovedRejectedWorkflow> {
        return await WorkflowsAPI.approve(id, message)
    }

    public static async reject(
        id: UUID,
        message?: string
    ): Promise<ApprovedRejectedWorkflow> {
        return await WorkflowsAPI.reject(id, message)
    }

    public static async obsolete(
        id: UUID,
        message?: string
    ): Promise<ApprovedRejectedWorkflow> {
        return await WorkflowsAPI.obsolete(id, message)
    }

    public static async modify(
        id: UUID,
        message?: string
    ): Promise<ApprovedRejectedWorkflow> {
        return await WorkflowsAPI.modify(id, message)
    }
}
