import { tenantsInstance } from './instances'
import { PagingFilters, SecretsResponse } from '../types/api'

export enum SecretsSubcategory {
    ApiKey = 'api-key',
    ResourceOwner = 'resource-owner',
    NoAuth = 'no-auth',
}

export class SecretsAPI {
    static async getSecrets(
        tenantId: string,
        subcategory: SecretsSubcategory,
        { page, size }: PagingFilters
    ): Promise<SecretsResponse> {
        const response = await tenantsInstance.get<SecretsResponse>(
            `/${tenantId}/secrets?category=auth&subcategory=${subcategory}&page=${page}&size=${size}`
        )
        return response.data
    }
}
