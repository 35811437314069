import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Divider } from '@chakra-ui/layout'
import { Collapse, IconButton, Tooltip, useDisclosure } from '@chakra-ui/react'
import { SelectedFilters } from '../SelectedFilters'
import { DeleteButton } from './DeleteButton'
import { SimpleArrowDownIcon, SimpleArrowUpIcon, EditPencilIcon } from 'icons'
import { ISavedOrderFilters } from 'types/api'
import { orderStore } from 'stores'
import { getSelectedFilters } from '../utils'
import {
    CollapseBox,
    FilterName,
    CollapsedBox,
    IconsBox,
    HeaderBox,
    FilterText,
    BodyBox,
    FooterBox,
    FooterButtonsBox,
    OutlinedButton,
    SavedFilterItemBox,
    DividerBox,
} from './styles'

interface ISavedFilterItem {
    filter: ISavedOrderFilters
    onClose: () => void
    onEditOpen: () => void
}

export const SavedFilterItem: FC<ISavedFilterItem> = observer(({
    filter,
    onClose,
    onEditOpen,
}) => {
    const { isOpen, onToggle } = useDisclosure()

    const {
        name,
        createdDate,
        page,
        size,
        createdFrom,
        createdTo,
        deliveryFrom,
        deliveryTo,
        statuses,
        ShipmentProviderCodes,
        orderSourceIds,
        orderNumber,
        isSimulated,
    } = filter
    const isNameValid = name?.length > 40
    const newFilter = {
        page,
        size,
        createdFrom,
        createdTo,
        deliveryFrom,
        deliveryTo,
        statuses,
        ShipmentProviderCodes,
        orderSourceIds,
        orderNumber,
        isSimulated,
    }

    const handleApply = async (): Promise<void> => {
        localStorage.setItem('orderFilters', JSON.stringify(newFilter))
        orderStore.setAllFilters(newFilter)
        orderStore.setLocalFilters()
        await orderStore.search()
        onClose()
    }

    const handleOpenEdit = (): void => {
        orderStore.setEditFilters(filter)
        onEditOpen()
    }

    return (
        <SavedFilterItemBox>
            <CollapseBox
                borderBottom={isOpen ? 0 : '1px solid blueBlack.300'}
                borderRadius={isOpen ? '8px 8px 0 0' : '8px'}
            >
                <HeaderBox>
                    <Tooltip label={isNameValid ? name : ''}>
                        <FilterName>{isNameValid ? `${ name.slice(0, 40) }...` : name }</FilterName>
                    </Tooltip>
                    <IconsBox>
                        <IconButton
                            aria-label="Edit Pencil Icon"
                            variant="hoveredMediumWhite"
                            marginRight="10px"
                            onClick={handleOpenEdit}
                            icon={
                                <EditPencilIcon
                                    needTitle={false}
                                    boxSize="22px"
                                    color="color.100"
                                    marginTop="7px"
                                    _hover={{
                                        color: 'color.50'
                                    }}
                                />
                            }
                        />
                        {isOpen ? (
                            <IconButton
                                aria-label="Simple Arrow Icon"
                                variant="hoveredMediumWhite"
                                onClick={onToggle}
                                icon={
                                    <SimpleArrowUpIcon
                                        boxSize='15px'
                                        color="blue.300"
                                        margin="9px 0 0 5px"
                                        _hover={{
                                            color: 'color.50'
                                        }}
                                    />
                                }
                            />
                        ) : (
                            <IconButton
                                aria-label="Simple Arrow Icon"
                                variant="hoveredMediumWhite"
                                onClick={onToggle}
                                icon={
                                    <SimpleArrowDownIcon
                                        boxSize="15px"
                                        color="color.100"
                                        _hover={{
                                            color: 'color.50'
                                        }}
                                    />
                                }
                            />
                        )}
                    </IconsBox>
                </HeaderBox>
                <BodyBox>
                    <FilterText>
                        {getSelectedFilters(filter)} Filter{getSelectedFilters(filter) === 1 ? '' : 's'} in Configuration
                    </FilterText>
                </BodyBox>
                <FooterBox>
                    <FilterText>Created: {createdDate}</FilterText>
                    <FooterButtonsBox>
                        <DeleteButton filter={filter} />
                        <OutlinedButton onClick={handleApply}>
                            Apply
                        </OutlinedButton>
                    </FooterButtonsBox>
                </FooterBox>
            </CollapseBox>
            <Collapse
                animateOpacity
                in={isOpen}
            >
                <DividerBox>
                    <Divider borderColor="blueBlack.200" />
                </DividerBox>
                <CollapsedBox>
                    <SelectedFilters filter={newFilter} isSavedFilters />
                </CollapsedBox>
            </Collapse>
        </SavedFilterItemBox>
    )
})
