import { typographyVariants } from './typography'

export const Heading = {
    baseStyle: {
        color: 'color.100',
        fontWeight: 'normal',
    },
    variants: typographyVariants,
    defaultProps: {
        variant: 'h2',
    },
}
