import React, { useEffect } from 'react'
import { ShipingCostHeader } from './content/ShipingCostHeader'
import { ShipingCostTable } from './content/ShipingCostTable'
import { ShipingCostPagination } from './content/ShipingCostPagination'
import { shippingCostStore } from 'stores'
import { PageBox } from './styles'

export const ShippingCostPage = () => {
    useEffect(() => {
        shippingCostStore.fetch()

        return () => {
            shippingCostStore.clean()
        }
    }, [])

    return (
        <PageBox>
            <ShipingCostHeader />
            <ShipingCostTable />
            <ShipingCostPagination />
        </PageBox>
    )
}
