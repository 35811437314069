import React, { ChangeEvent, FC } from 'react'
import { InputText, InputStyled, InputWithHeaderBox } from './styles'

interface IInputProps {
    value: string | number
    remaining: number
    isInvalid: boolean
    description: string
    onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

interface IInputWithHeader {
    inputText: string
    placeholder: string
    inputProps: IInputProps
}

export const InputWithHeader: FC<IInputWithHeader> = ({
    inputText,
    placeholder,
    inputProps,
}) => {
    return (
        <InputWithHeaderBox>
            <InputText>{inputText}</InputText>
            <InputStyled
                placeholder={placeholder}
                size="sm"
                {...inputProps}
            />
        </InputWithHeaderBox>
    )
}
