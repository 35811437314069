import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useDebounce } from 'hooks'
import { isEqual } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useColorMode } from '@chakra-ui/react'
import { MultiSelect as CustomMultiSelect } from 'components/baseComponents/MultiSelect'
import { CheckboxOption as Option } from 'components/baseComponents/MultiSelect/CheckboxOption'
import { DropdownIndicator } from 'components/baseComponents/MultiSelect/DropdownIndicator'
import { MenuList } from './MenuList'
import { MultiValueContainer } from './MultiValueContainer'
import { OptionData } from 'components/baseComponents/Selects'
import { addNumber, displayCsvStatus } from './utils'
import { dependsOnParameters } from 'components/diagrams/nodes/NodeLayout/NodeParameter/params/utils'
import {
    DependsOnMappings,
    Parameter,
    ParameterMetadata,
    UUID,
    ICsvImportedPopupData,
} from 'types/core'
import { paramStore, paramStores } from 'stores'
import { multiSelectStyles } from 'components/baseComponents/MultiSelect/styles'
import { RequiredText } from 'components/diagrams/nodes/NodeLayout/NodeParameter/params/styles'

interface MultiSelectProps {
    id: UUID
    stepId: UUID
    value: string
    description: string
    readonly: boolean
    isDraft: boolean
    dependsOnMappings: DependsOnMappings[]
    stepParameters: Parameter[]
    nodeParameters: ParameterMetadata[]
    placeholder: string
    ruleName: string
}

export const MultiSelect: FC<MultiSelectProps> = observer(({
    id,
    stepId,
    value,
    description,
    readonly,
    isDraft,
    dependsOnMappings,
    stepParameters,
    nodeParameters,
    placeholder,
    ruleName,
}) => {
    const store = useMemo(() => {
        return paramStores.stores[id]
    }, [id])
    const [search, setSearch] = useState('')
    const [blankArrayInCsv, setBlankArrayInCsv] = useState(false)
    const [selectedValues, setSelectedValues] = useState<ICsvImportedPopupData[]>([])
    const isCheckCsv = useRef(displayCsvStatus(nodeParameters))
    const { colorMode } = useColorMode()

    const displayCsvImportStatus = displayCsvStatus(nodeParameters)
    const values = addNumber(store.values, id, ruleName)
    
    const matchedValue = paramStore?.isAllAvailableData
    
    useEffect(() => {
        paramStore?.storeAllAvailableValues([])
        setSelectedValues(addNumber(store.selectedValues, id, ruleName))
    }, [store.selectedValues, id, ruleName])

    useEffect(() => {
        if (
            matchedValue.length > 0 &&
            isCheckCsv.current === true &&
            displayCsvImportStatus
        ) {
            const machedSelecteValue = blankArrayInCsv ? [] : matchedValue
            const mergedSelectedValues = [
                ...selectedValues,
                ...machedSelecteValue,
            ]
            setSelectedValues(mergedSelectedValues)
            isCheckCsv.current = false
        }
    }, [matchedValue, displayCsvImportStatus, blankArrayInCsv, selectedValues])

    const { dependencies, isDisabled, requiredMappings } = dependsOnParameters(
        dependsOnMappings,
        nodeParameters,
        stepParameters,
    )

    const searchValues = useDebounce(async (search: string) => {
        await store.searchValues(search)
    }, 700)

    const handleChange = async (selected: readonly OptionData[]) => {
        setBlankArrayInCsv(true)
        if (values.length === selected.length) setSearch('')
        await store.setSelected([...selected])
    }

    const handleInputChange = (value: string) => {
        setSearch(prev => {
            return search.length !== 1 && !value ? prev : value
        })
    }

    useEffect(() => {
        if (!isEqual(dependencies, store.dependencies)) {
            store.setDependencies(dependencies)
        }
        // Need for improvement. Warnings console
        /* eslint-disable */
    }, [dependsOnMappings, nodeParameters, stepParameters])

    useEffect(() => {
        if (!isDisabled) store.initValues(value)
    }, [value, isDisabled])

    useEffect(() => {
        if (!isDisabled) searchValues(search)
    }, [search, isDisabled, store.dependencies])

    return (
        <>
            <CustomMultiSelect
                isDisabled={isDisabled}
                components={{
                    Option,
                    MenuList,
                    MultiValueContainer,
                    DropdownIndicator,
                    IndicatorSeparator: () => null,
                }}
                options={values}
                optionSelected={selectedValues}
                placeholder={placeholder}
                customStyles={multiSelectStyles({
                    theme: colorMode,
                    isSelectRule: false,
                    isDisabled: isDisabled,
                    isDraft: isDraft,
                })}
                onChange={handleChange}
                isClearable={false}
                onInputChange={handleInputChange}
                inputValue={search}
                isSearchable={isDraft}
                menuIsOpen={isDraft ? undefined : false}
            />
            {!!requiredMappings.length && (
                <RequiredText>
                    Parameter "{description}" is disabled, please fill
                    required parameters: "{requiredMappings.join(', ')}"
                </RequiredText>
            )}
        </>
    )
})
