import React, { FC } from 'react'
import { ItemShippingOrder } from 'types/core'
import {
    AccordionInfoBox,
    AccordionInfoItem,
    DeliveryMethodBox,
} from 'pages/OrderDetailsPage/content/Tabs/ItemsShippingInfo/styles'

interface IAccordionItemsShippingInfo {
    order: ItemShippingOrder
}

const AccordionItemsShippingInfo: FC<IAccordionItemsShippingInfo> = ({ order }): JSX.Element => {
    const { name, deliveryStatus, deliveryMethod, shipmentNumber, quantity } =
        order

    return (
        <AccordionInfoBox>
            <AccordionInfoItem textTransform="uppercase" fontWeight="500">
                {name}
            </AccordionInfoItem>
            <AccordionInfoItem>{quantity}</AccordionInfoItem>
            <AccordionInfoItem>
                <DeliveryMethodBox>{deliveryMethod}</DeliveryMethodBox>
            </AccordionInfoItem>
            <AccordionInfoItem color="green.100">
                {shipmentNumber}
            </AccordionInfoItem>
            <AccordionInfoItem>{deliveryStatus}</AccordionInfoItem>
        </AccordionInfoBox>
    )
}

export default AccordionItemsShippingInfo
