import { Text } from '@chakra-ui/layout'
import { ContainerProps, GroupBase, StylesConfig } from 'react-select'
import { OptionData } from 'components/baseComponents/Selects'
import { chakra } from 'utils/chakra'
import { ISelectOption } from 'types/core'
import { getThemeColor } from 'utils/getThemeColor'
import { pseudoSelectors } from 'components/styles'
import { StylesConfigFunction } from 'react-select/dist/declarations/src/styles'

export const MultiValue = chakra(Text, {
    variant: 'paragraphsmall',
})

export const container: StylesConfigFunction<ContainerProps<OptionData, true, GroupBase<OptionData>>> = (styles) => ({
    ...styles,
    boxSizing: 'border-box',
    marginRight: '16px',
    width: '140px',
    maxHeight: '32px',
})

export const multiSelectStyles = (theme: string, isDisabled?: boolean): StylesConfig<ISelectOption> => ({
    container: styles => ({
        ...styles,
        boxSizing: 'border-box',
        marginRight: '16px',
        width: '310px',
        maxHeight: '32px',
    }),
    dropdownIndicator: (styles, state) => ({
        ...styles,
        ...pseudoSelectors('color', 'color.100'),
        transition: 'all .2s ease',
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
        color: getThemeColor(theme, 'color.100'),
    }),
    valueContainer: styles => ({
        ...styles,
        padding: '0 8px',
        maxHeight: '13px',
        flexDirection: 'column',
        alignContent: 'center',
    }),
    indicatorsContainer: styles => ({
        ...styles,
        '& > div': {
            padding: '0 8px',
        },
    }),
    menuList: styles => ({
        ...styles,
        backgroundColor: getThemeColor(theme, 'blueWhite.25'),
        borderRadius: '0px 0px 6px 6px',
        padding: 0,
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.45)',
    }),
    menu: styles => ({
        ...styles,
        borderRadius: '0px 0px 6px 6px',
        marginTop: 0,
        backgroundColor: getThemeColor(theme,'color.900'),
    }),
    placeholder: styles => ({
        ...styles,
        color: isDisabled ? '#C2C2CB' : getThemeColor(theme, 'sidebarIconColor'),
        fontSize: '12px',
        lineHeight: '14px',
    }),
    control: (styles, state) => ({
        ...styles,
        ...pseudoSelectors('border', '1px solid'),
        ...pseudoSelectors('borderColor', getThemeColor(theme, 'dividerHover')),
        backgroundColor: getThemeColor(theme, 'color.900'),
        borderRadius: state.selectProps.menuIsOpen ? '6px 6px 0 0' : '6px',
        color: getThemeColor(theme, 'color.100'),
        minHeight: '32px',
        maxHeight: '32px',
        boxShadow: 'none',
        border: `1px solid ${getThemeColor(theme, 'divider')}`,
    }),
    input: styles => ({
        ...styles,
        color: getThemeColor(theme, 'color.100'),
        maxHeight: '13px',
        padding: 0,
        margin: 0,
        fontSize: '12px',
        lineHeight: '14px',
    }),
    singleValue: styles => ({
        ...styles,
        color: getThemeColor(theme, 'color.100'),
        fontSize: '12px',
        lineHeight: '14px',
    }),
    option: (styles) => ({
        ...styles,
        ...pseudoSelectors('backgroundColor', getThemeColor(theme, 'color.920')),
        backgroundColor: getThemeColor(theme, 'color.900'),
        color: getThemeColor(theme, 'color.100'),
        fontSize: '12px',
        lineHeight: '14px',
    }),
})

