import React, { Dispatch, SetStateAction, useMemo } from 'react'
import { Button } from '@chakra-ui/button'
import { v4 } from 'uuid'
import { ICard } from 'components/Cards'
import { InputWithHeader } from 'components/InputWithHeader'
import { StatusType } from 'types/chart'
import { IOrderNotes } from 'types/core'
import { notificationStore } from 'stores'
import { useInputChange } from 'hooks/useInputChange'
import { deleteEmptyObjectValues } from 'utils/deleteEmptyObjectValues'
import {
    CODE,
    VALUE,
    EXTERNAL_CODE,
} from 'constants/virtualOrder'
import {
    OrderItemsBox,
    VirtualOrderBoxReverse,
} from './style'

interface ICreateOrderNote {
    onClose: () => void
    setOrderNote: Dispatch<SetStateAction<IOrderNotes[]>>
    setCards: Dispatch<SetStateAction<ICard[]>>
    notes: IOrderNotes[]
}

export const CreateOrderNote = ({
    onClose,
    setOrderNote,
    setCards,
    notes,
}: ICreateOrderNote) => {
    const {
        value: codeValue,
        isError: codeIsError,
        inputProps: codeProps,
    } = useInputChange({ isRequired: true, maxLength: CODE })
    const {
        value: valueValue,
        isError: valueIsError,
        inputProps: valueProps,
    } = useInputChange({ maxLength: VALUE })
    const {
        value: externalCodeValue,
        isError: externalCodeIsError,
        inputProps: externalCodeProps,
    } = useInputChange({ maxLength: EXTERNAL_CODE })

    const isDisabled = codeIsError || valueIsError || externalCodeIsError

    const orderNoteInputs = useMemo(() => [
        {
            inputText: 'Code (Required)',
            placeholder: 'Enter Code...',
            inputProps: codeProps,
        },
        {
            inputText: 'Value',
            placeholder: 'Enter Value...',
            inputProps: valueProps,
        },
        {
            inputText: 'External Code',
            placeholder: 'Enter External Code...',
            inputProps: externalCodeProps,
        },
    ], [
        codeProps,
        valueProps,
        externalCodeProps,
    ])

    const saveOrderNote = () => {
        const findExistingCode = notes.find(note => note.code === codeValue)
        if (findExistingCode) {
            notificationStore.toast({
                title: 'Error',
                description: 'Code must be Unique',
                status: StatusType.Error,
            })
            return
        }

        const note = {
            id: v4(),
            code: codeValue,
            value: valueValue,
            externalCode: externalCodeValue,
        }

        const updateNotes = [...notes, deleteEmptyObjectValues(note)]
        const adaptCards = updateNotes.map(({ id, code, value, externalCode}) => ({
            id,
            topValue: code,
            middleValue: value,
            bottomValue: externalCode,
        }))

        setOrderNote(updateNotes)
        setCards(adaptCards)
        onClose()
    }

    return (
        <>
            <OrderItemsBox>
                {orderNoteInputs.map(({ inputText, placeholder, inputProps}) =>
                    <InputWithHeader
                        key={inputText}
                        inputText={inputText}
                        placeholder={placeholder}
                        inputProps={inputProps}
                    />
                )}
            </OrderItemsBox>
            <VirtualOrderBoxReverse>
                <Button
                    type="submit"
                    variant="blue"
                    isDisabled={isDisabled}
                    onClick={saveOrderNote}
                >
                    Save
                </Button>
                <Button
                    margin="0 16px"
                    type="button"
                    variant="blue"
                    onClick={onClose}
                >
                    Cancel
                </Button>
            </VirtualOrderBoxReverse>
        </>
    )
}
