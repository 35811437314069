import { Text, StyleSheet, View } from '@react-pdf/renderer'
import { productPerformanceByTotalOrdersReport } from '../ReportsTable/columnMoc'
import { Cell1 } from './Cellstyle/cell'
import { ITotalOrdersShippedByShipper } from 'types/core'

export const productPerformanceShipperByShipperStyle = StyleSheet.create({
    row: {
        fontSize: '8',
        display: 'flex',
        flexDirection: 'row',
    },
    cell1: {
        width: '25%',
    },
})

export const productPerformanceShipperByShiperHeaders = (
    <View style={productPerformanceShipperByShipperStyle.row}>
        {!!productPerformanceByTotalOrdersReport.length &&
            productPerformanceByTotalOrdersReport.map(column => (
                <Text
                    key={column.Header}
                    style={column.style}
                >
                    {column.Header}
                </Text>
            ))}
    </View>
)

export const productPerformanceShipperByShiperRow = (
    data: ITotalOrdersShippedByShipper
) => {
    const productShipperByshipperRow = [
        data.shipper,
        data.method,
        data.quantity,
        data.orderPercentage,
    ]
    return (
        <View style={productPerformanceShipperByShipperStyle.row}>
            {!!productShipperByshipperRow &&
                !!productShipperByshipperRow?.length &&
                productShipperByshipperRow?.map(performanceShiperItem => {
                    return (
                        <Cell1
                            cell1={
                                productPerformanceShipperByShipperStyle.cell1
                            }
                            data={performanceShiperItem}
                        />
                    )
                })}
        </View>
    )
}
