import { Grid, Flex, Box, Stack } from '@chakra-ui/layout'
import { chakra } from "utils/chakra"

export const PageGrid = chakra(Grid, {
    height: '100%',
    templateColumns: '176px auto',
    templateRows: 'auto max-content',
    gridTemplateAreas: `
        "sidebar editor"
        "footer  footer"
    `,
    columnGap: '20px',
    rowGap: '16px',
    padding: '32px',
    overflow: 'auto',
})

export const SidebarContainer = chakra(Flex, {
    direction: 'column',
    overflow: 'auto',
    backgroundColor: 'color.900',
    padding: '8px',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)',
})

export const EditorBox = chakra(Flex, {
    gridArea: 'editor',
    direction: 'column',
    marginTop: '26px',
})

export const SidebarBox = chakra(Box, {
    as: 'aside',
    gridArea: 'sidebar',
    overflow: 'auto',
})

export const RuleHeaderButtonStack = chakra(Stack, {
    height: '55px',
    spacing: '13px',
    marginBottom: '21px',
})
