import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const UpArrowIcon: FC<IconProps> = props => {
    return (
        <Icon
            width="4"
            height="11"
            viewBox="0 0 4 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.06137 3.91651C7.5048 4.2473 8.22374 4.2473 8.66717 3.91651C9.1106 3.58572 9.1106 3.04941 8.66717 2.71862L5.35538 0.248092C5.07047 0.0355509 4.67181 -0.0404267 4.30558 0.0201589C4.20256 0.0371549 4.1048 0.0645877 4.0144 0.100895C3.90918 0.143153 3.81391 0.197434 3.73192 0.261271L0.437796 2.71862C-0.00563245 3.04941 -0.00563251 3.58572 0.437796 3.91651C0.881225 4.2473 1.60016 4.2473 2.04359 3.91651L3.41641 2.89242L3.41641 9.31759C3.41641 9.78539 3.92478 10.1646 4.55188 10.1646C5.17898 10.1646 5.68735 9.78539 5.68735 9.31759L5.68735 2.89152L7.06137 3.91651Z"
                fill="currentColor"
            />
        </Icon>
    )
}
