export const getNumericPagination = (
    pagesList: number[],
    activePage: number,
    pages: number,
    paginationItems: number,
) => {
    if (paginationItems === 7) {
        if (pages <= paginationItems) return pagesList
        if (activePage > 4 && activePage <= pages - 4) return [1, '...', activePage - 1, activePage, activePage + 1, '...', pages]
        if (activePage < 5) return [1, 2, 3, 4, 5, '...', pages]
        if (activePage > pages - 4) return [1, '...', pages- 4, pages - 3, pages - 2, pages - 1, pages]
    }

    if (paginationItems === 5) {
        if (pages <= paginationItems) return pagesList
        if (activePage > 2 && activePage <= pages - 2) return [1, '...', activePage, '...', pages]
        if (activePage < 3) return [1, 2, 3, '...', pages]
        if (activePage > pages - 2) return [1, '...', pages - 2, pages - 1, pages]
    }

    return pagesList
}
