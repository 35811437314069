import Icon, { IconProps } from '@chakra-ui/icon'
import React, { FC } from 'react'

interface MenuDotsIconProps extends IconProps {
    isVertical?: boolean
}

export const MenuDotsIcon: FC<MenuDotsIconProps> = ({
    isVertical = false,
    ...props
}) => {
    return (
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            {...props}
        >
            {isVertical ? (
                <circle cx="12" cy="2" r="2" fill="currentColor" />
            ) : (
                <circle cx="2" cy="12" r="2" fill="currentColor" />
            )}
            <circle cx="12" cy="12" r="2" fill="currentColor" />
            {isVertical ? (
                <circle cx="12" cy="22" r="2" fill="currentColor" />
            ) : (
                <circle cx="22" cy="12" r="2" fill="currentColor" />
            )}
        </Icon>
    )
}
