import React, { FC } from 'react'
import { Flex } from '@chakra-ui/layout'
import { CustomerShipmentMethods, CustomerShipper } from 'types/api'
import { CategoryDistSourcesIcon } from 'icons'
import { ShipmentMethodBox } from './ShipmentMethodBox'
import { accordionMethodHeaderOptions } from '../mock'
import {
    AccordionHeaderText,
    ButtonStyled,
    CustomerShipperPanelGlobalBox,
    MethodTitleTable,
    ShipmentMethodsTitle,
    CustomerShipperPanelBox,
    SvgBG,
} from '../styles'

interface ICustomerShipperPanel {
    onAddShipmentMethodOpen: () => void
    onAddShipmentCreateMethodOpen: () => void
    shipmentMethods: CustomerShipmentMethods[] | undefined
    tenantId: string
    shipper: CustomerShipper
}

export const CustomerShipperPanel: FC<ICustomerShipperPanel> = ({
    onAddShipmentMethodOpen,
    onAddShipmentCreateMethodOpen,
    shipmentMethods,
    tenantId,
    shipper,
}) => (
    <CustomerShipperPanelGlobalBox>
        <CustomerShipperPanelBox>
            <Flex alignItems='center' paddingBottom='12px'>
                <SvgBG>
                    <CategoryDistSourcesIcon stroke='white' boxSize="16px" />
                </SvgBG>
                <ShipmentMethodsTitle>Shipment Methods</ShipmentMethodsTitle>
            </Flex>
            <MethodTitleTable>
                {accordionMethodHeaderOptions.map((option) => (
                    <AccordionHeaderText key={option}>
                        {option}
                    </AccordionHeaderText>
                ))}
            </MethodTitleTable>

            {shipmentMethods?.map((shipmentMethod) => (
                <ShipmentMethodBox
                    key={shipmentMethod.code}
                    shipmentMethod={shipmentMethod}
                    tenantId={tenantId}
                    shipper={shipper}
                    onAddShipmentMethodOpen={onAddShipmentMethodOpen}
                />),
            )}
        </CustomerShipperPanelBox>
        {shipper.type !== 'Predefined' &&
            <ButtonStyled
                disabled={!shipper.isEnabled}
                onClick={() => {
                    onAddShipmentMethodOpen()
                    onAddShipmentCreateMethodOpen()
                }}
            >
                Add Shipment Method
            </ButtonStyled>
        }
    </CustomerShipperPanelGlobalBox>
)
