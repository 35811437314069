import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const RectangleGrayIcon: FC<IconProps> = props => {
    return (
        <Icon
            width="4.25"
            height="10"
            viewBox="0 0 13 44"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect x="-17.0752" width="30" height="44" rx="8" fill="#DADADA"/>
        </Icon>


    )
}
