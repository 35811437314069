import React, { FC } from 'react'
import { Modal, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import { ModalCloseButton, ModalConfirmButton, ModalHeaderText, ModalBodyStyled } from './style'

interface IConfirmModal {
    isOpen: boolean
    onClose: () => void
    confirmFunc: () => void
    headerText: string
    bodyText: string
    buttonText: string
    colorButton: string
}

export const ConfirmModal: FC<IConfirmModal> = ({
    isOpen,
    onClose,
    confirmFunc,
    headerText,
    bodyText,
    buttonText,
    colorButton,
}) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent alignSelf="center" backgroundColor="color.900">
                <ModalHeader>
                    <ModalHeaderText>{headerText}</ModalHeaderText>
                </ModalHeader>
                <ModalBodyStyled textAlign="center">{bodyText}</ModalBodyStyled>
                <ModalFooter justifyContent="right">
                    <ModalCloseButton onClick={onClose}>Close</ModalCloseButton>
                    <ModalConfirmButton onClick={confirmFunc} color={colorButton}>{buttonText}</ModalConfirmButton>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
