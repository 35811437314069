import { Box, Flex, Text } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/button'
import { chakra } from 'utils/chakra'

export const InputsBox = chakra(Box, {})
export const InputBox = chakra(Box, {})
export const ButtonBox = chakra(Box, {})

export const Container = chakra(Flex, {
    justifyContent: 'center',
    alignItems: 'center',
    height: '95vh',
    overflow: 'hidden',
})

export const LoginBox = chakra(Flex, {
    flexDirection: 'column',
    height: 'min-content',
    alignItems: 'center',
    padding: '32px',
    width: '444px',
    background: 'color.900',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.25)',
    borderRadius: '6px',
})

export const LoginHeading = chakra(Text, {
    variant: 'h1',
    margin: '16px 0 8px 0',
})

export const LoginSubheading = chakra(Text, {
    variant: 'h3',
    margin: '8px 0',
})

export const InputText = chakra(Text, {
    variant: 'supertitle',
    margin: '2px 0',
})

export const ForgotPasswordText = chakra(Text, {
    variant: 'h6',
    color: 'blue.200',
    cursor: 'pointer',
})

export const LinkBox = chakra(Box, {
    alignSelf: 'flex-start',
})

export const LoginHelpTextBox = chakra(Flex, {
    flexDirection: 'column',
    alignItems: 'center',
})

export const LoginHelpText = chakra(Text, {
    variant: 'paragraphsmall',
    margin: '4px 0',
})

export const ForgotPasswordHeadingsBox = chakra(Flex, {
    flexDirection: 'column',
    maxWidth: '277px',
})

export const ForgotPasswordHeadingText = chakra(Text, {
    variant: 'h2',
    marginBottom: '8px',
})

export const ForgotPasswordSubheadingText = chakra(Text, {
    variant: 'paragraph',
    textAlign: 'center',
    margin: '8px 0 16px 0',
})

export const ForgotPasswordButtonStyled = chakra(Button, {
    variant: 'outline',
    height: '20px',
    padding: '2px 8px',
})

export const ForgotPasswordButtonText = chakra(Text, {
    variant: 'h6',
    marginLeft: '10px',
})

export const StepsButtonsBox = chakra(Flex, {
    width: '100%',
    justify: 'flex-end',
    marginTop: '15px',
})
