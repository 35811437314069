import React, { FC } from 'react'
import { CategoryTitleBox, CategoryHeaderItem } from './styles'

export const CategoryHeader: FC = () => {
    return (
        <CategoryTitleBox>
            <CategoryHeaderItem flexGrow={1}>Category Name</CategoryHeaderItem>
            <CategoryHeaderItem flexGrow={1}>
                Attribute Source Name
            </CategoryHeaderItem>
            <CategoryHeaderItem flexGrow={0.5}>Status</CategoryHeaderItem>

            <CategoryHeaderItem
                display="flex"
                justifyContent="end"
                flexGrow={0.5}
            >
                Action
            </CategoryHeaderItem>
        </CategoryTitleBox>
    )
}
