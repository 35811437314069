import { Box, Flex, Text } from '@chakra-ui/layout'
import { chakra } from 'utils/chakra'

export const Title = chakra(Text, {
    variant: 'h5',
    color: 'white',
})

export const Description = chakra(Text, {
    variant: 'paragraph',
    margin: '0 12px 0 4px',
    color: 'white',
})

export const ToastText = chakra(Box, {
    width: '95%',
})

export const ToastBox = chakra(Flex, {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '4px 16px',
    minHeight: '40px',
    maxWidth: '1440px',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.45)',
    borderRadius: '6px',
})
