import { Divider, Heading } from '@chakra-ui/react'
import { Flex, Text } from '@chakra-ui/layout'
import { chakra } from 'utils/chakra'

export const SaveInfoText = chakra(Text, {
    variant: 'paragraphsmall',
    color: 'blue.300',
    marginLeft: '15px',
})

export const DividerStyled = chakra(Divider, {
    orientation: 'horizontal',
    marginTop: '20px',
    marginBottom: '20px',
    width: 'calc(100%-16px)',
    borderColor: 'divider'
})

export const SaveButtonBox = chakra(Flex, {
    alignItems: 'center',
})

export const HeadingStyled = chakra(Heading, {
    as: 'h6',
    size: 'sm',
    marginLeft: '15px',
    marginTop: '15px',
})
