import React, { FC } from 'react'
import { NodeProps } from 'react-flow-renderer'
import { NodeLayout } from './NodeLayout'
import { ports, mockSourceStep } from './mock'
import { SourceIndicatorIcon } from 'icons'
import { CenterSourceNode } from './styles'

export const SourceNode: FC<NodeProps> = () => {
    return (
        <>
            <CenterSourceNode>
                <SourceIndicatorIcon boxSize="30px" />
            </CenterSourceNode>
            <NodeLayout
                readonly
                step={mockSourceStep}
                ports={ports}
            ></NodeLayout>
        </>
    )
}
