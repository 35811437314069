import {
    IPPSalesOverDuration,
    IProductPerformanceSales,
    IReportData,
    ReportType,
    IOrderPerformance,
    IProductPerformanceItem,
} from 'types/core'

let csvFormetedData: IReportData[] = []

export const csvDataFormat = (
    data: IReportData[],
    performanceByTotalOrderData: IProductPerformanceItem[],
    productSalesOverDuration: IPPSalesOverDuration[],
    reportName: string
) => {
    switch (reportName) {
        case ReportType.performanceReceivedBySalesChannel:
            csvFormetedData = []
            !!performanceByTotalOrderData &&
                !!performanceByTotalOrderData?.length &&
                performanceByTotalOrderData?.map(res =>
                    res.orderPerformance?.map((source: IOrderPerformance) => {
                        source.sourceName = res.sourceName
                        return csvFormetedData.push(source)
                    })
                )
            return csvFormetedData
        case ReportType.performanceSalesOverDuration:
            csvFormetedData = []
            !!productSalesOverDuration &&
                !!productSalesOverDuration?.length &&
                productSalesOverDuration.map(res =>
                    res?.productPerformance?.map(
                        (source: IProductPerformanceSales) => {
                            source.sourceName = res.sourceName
                            return csvFormetedData.push(source)
                        }
                    )
                )
            return csvFormetedData
        default:
            return data
    }
}

export const changeDate = (date: string | number | Date, showtime: boolean) => {
    const today = new Date(date)
    today.setHours(today.getUTCHours() - 4)
    today.setMinutes(today.getUTCMinutes() + 15)
    const dd = String(today.getDate()).padStart(2, '0')
    const mm = String(today.getMonth() + 1).padStart(2, '0')
    const yyyy = today.getFullYear()
    const hour = today.getUTCHours()
    const minutes = today.getUTCMinutes()
    const newtoday = mm + '/' + dd + '/' + yyyy
    const newtodaytime = mm + '/' + dd + '/' + yyyy + ' ' + hour + ':' + minutes
    return showtime ? newtodaytime : newtoday
}
