import { Text, StyleSheet, View } from '@react-pdf/renderer'
import { onTimeShippingcolumns } from '../ReportsTable/columnMoc'
import { dateOnlyFormatter } from '../utils'
import { Cell1 } from './Cellstyle/cell'
import { IOrderShipping } from 'types/core'

export const onTimeShippingReportStyles = StyleSheet.create({
    row: {
        fontSize: '8',
        display: 'flex',
        flexDirection: 'row',
    },
    cell1: {
        width: '15%',
    },
    cell2: {
        width: '30%',
    },
    cell3: {
        width: '10%',
    },
})

export const onTimeShippingReportHeader = (
    <View style={onTimeShippingReportStyles.row}>
        {!!onTimeShippingcolumns.length &&
            onTimeShippingcolumns.map(column => (
                <Text key={column.Header} style={column.style}>
                    {column.Header}
                </Text>
            ))}
    </View>
)

export const onTimeShippingReportRow = (data: IOrderShipping) => {
    const onTimeShippingRow = [
        data.sourceId,
        data.orderId,
        dateOnlyFormatter(data.orderDate),
        data.orderStatusDescription,
        dateOnlyFormatter(data.expectedDelivery),
        data.actualDelivery,
    ]
    return (
        <View style={onTimeShippingReportStyles.row}>
            {!!onTimeShippingRow &&
                !!onTimeShippingRow?.length &&
                onTimeShippingRow?.map(onTimeShippingItem => {
                    switch (onTimeShippingItem) {
                        case data?.orderId:
                            return (
                                <Cell1
                                    cell1={onTimeShippingReportStyles.cell2}
                                    data={onTimeShippingItem}
                                />
                            )
                        case data?.orderStatusDescription:
                            return (
                                <Cell1
                                    cell2={onTimeShippingReportStyles.cell3}
                                    data={onTimeShippingItem}
                                />
                            )
                        default:
                            return (
                                <Cell1
                                    cell3={onTimeShippingReportStyles.cell1}
                                    data={onTimeShippingItem}
                                />
                            )
                    }
                })}
        </View>
    )
}
