import React, { Dispatch, SetStateAction } from 'react'
import { StatusType } from 'types/chart'
import { setStatusBackground } from 'utils/setStyles'
import {
    Title,
    MessageBox,
    Description,
    InfoBox,
    IconBox,
    CrossSmallIconStyled,
} from './styles'

interface MessageProps {
    title: string
    description: string
    status: StatusType
    isOpen: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    margin?: string
}

export const Message = ({
    title,
    description,
    status,
    isOpen,
    setOpen,
    margin,
}: MessageProps) => {
    const handleClick = () => setOpen(false)

    return (
        <MessageBox
            display={isOpen ? 'flex' : 'none'}
            background={setStatusBackground(status)}
            margin={margin || 0}
        >
            <InfoBox>
                <Title>{title}:</Title>
                <Description>{description}</Description>
            </InfoBox>
            <IconBox>
                <CrossSmallIconStyled onClick={handleClick} />
            </IconBox>
        </MessageBox>
    )
}
