import React, { FC } from 'react'
import { toJS } from 'mobx'
import { useNavigate } from 'react-router'
import { Button } from '@chakra-ui/button'
import { Center, Flex, Spacer } from '@chakra-ui/layout'
import { userStore } from 'stores'
import { routes } from 'navigation/routes'
import { ShortWorkflow, WorkflowStatus } from 'types/core'
import { RuleStatusTag } from 'components/RuleStatusTag'
import { ArrowRightLongIcon } from 'icons'
import {
    CardContainer,
    ContentContainer,
    DescriptionContainer,
    NameContainer,
    TitleContainer,
} from './styles'

interface RuleApprovalsCardProps {
    workflow: ShortWorkflow
    marginTop: string
}

export const RuleApprovalsCard: FC<RuleApprovalsCardProps> = ({
    workflow: {
        id,
        name,
        description,
        status,
        version,
    },
    marginTop = '0',
}) => {
    const navigate = useNavigate()
    const isDisplayButton =
        status === WorkflowStatus.Approval

    const { isApprover } = toJS(userStore.currentRoles)

    return (
        <CardContainer marginTop={marginTop}>
            <NameContainer>
                <TitleContainer>{name}</TitleContainer>
                <TitleContainer>v{version}</TitleContainer>
            </NameContainer>
            <ContentContainer>
                <DescriptionContainer >
                    {description}
                </DescriptionContainer>
                <Flex marginBottom="8px">
                    <Spacer />
                    <Center>
                        <RuleStatusTag status={status} />
                    </Center>
                </Flex>
                {isDisplayButton && (
                    <Button
                        size="xs"
                        variant="emptySmall"
                        disabled={!isApprover}
                        onClick={() =>
                            navigate(`${routes.submitApproval}/${id}`)
                        }
                        rightIcon={<ArrowRightLongIcon boxSize="12px" />}
                    >
                        View Details
                    </Button>
                )}
            </ContentContainer>
        </CardContainer>
    )
}
