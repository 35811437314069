import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const PercentIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 490.615 490.615"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g>
                <path
                    d="M0,475.876L475.525,0.351l14.425,14.425L14.425,490.301L0,475.876z"
                    fill="currentColor"
                />
                <path
                    d="m106.915,212.908c58.7,0 106.3-47.6 106.3-106.3s-47.6-106.3-106.3-106.3-106.3,47.6-106.3,106.3 47.6,106.3 106.3,106.3zm0-192.5c47.6,1.42109e-14 86.2,38.6 86.2,86.2s-38.6,86.2-86.2,86.2-86.2-38.6-86.2-86.2 38.6-86.2 86.2-86.2z"
                    fill="currentColor"
                />
                <path
                    d="m384.315,277.708c-58.7,0-106.3,47.6-106.3,106.3s47.6,106.3 106.3,106.3 106.3-47.6 106.3-106.3-47.6-106.3-106.3-106.3zm0,192.5c-47.6,0-86.2-38.6-86.2-86.2s38.6-86.2 86.2-86.2 86.2,38.6 86.2,86.2-38.6,86.2-86.2,86.2z"
                    fill="currentColor"
                />
            </g>
        </Icon>
    )
}
