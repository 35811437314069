import { Flex, Text } from '@chakra-ui/layout'
import { chakra } from 'utils/chakra'

export const ShowFiltersBox = chakra(Flex, {
    flexDirection: 'column',
    position: 'absolute',
    top: '35px',
    right: '-7px',
    zIndex: '1',
    width: '560px',
    minHeight: '332px',
    maxHeight: '640px',
    padding: '26px 24px',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.45)',
    backgroundColor: 'blueWhite.900',
    color: 'color.100',
    overflowY: 'auto',
})

export const HeaderBox = chakra(Flex, {
    justifyContent: 'space-between',
    height: '16px',
    width: '100%',
    marginBottom: '8px',
})

export const CollapseBox = chakra(Flex, {
    alignItems: 'center',
    cursor: 'pointer',
    margin: '8px 0',
})

export const ContentBox = chakra(Flex, {
    flexDirection: 'column',
})

export const DatePickersBox = chakra(Flex, {
    justifyContent: 'space-between',
})

export const DatePickerBox = chakra(Flex, {
    flexDirection: 'column',
})

export const CheckboxesBox = chakra(Flex, {
    flexDirection: 'column',
})

export const BottomBox = chakra(Flex, {
    justifyContent: 'right',
    marginTop: '16px',
})

export const HeaderText = chakra(Text, {
    variant: 'h4',
    color: 'color.100',
})

export const DetectedChangesText = chakra(Text, {
    variant: 'smalllink',
    color: 'red.500',
})

export const FilterText = chakra(Text, {
    variant: 'h4LineHeight',
    color: 'color.100',
    textTransform: 'capitalize',
})
