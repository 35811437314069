import { makeAutoObservable } from 'mobx'
import { WorkflowsService } from 'services/workflows'
import { WorkflowsFilters } from 'types/api'
import { ShortWorkflow, UUID } from 'types/core'

const defaultFilters: WorkflowsFilters = {
    page: 1,
    size: 1000,
    utcModifiedFrom: '',
    utcModifiedTo: '',
    statuses: '',
    filter: '',
    workflowHeaderId: '',
    workflowVersion: 0,
    isInWow: false,
}

export class WorkflowsStore {
    public workflows: ShortWorkflow[] = []
    public filters: WorkflowsFilters = defaultFilters
    public pages: number = 0
    public items: number = 0

    constructor() {
        makeAutoObservable(this)
    }

    public async createNewWorkflow(name: string, description: string) {
        return await WorkflowsService.add({ name, description })
    }

    public async fetch() {
        try {
            const { items = [], totalPages = 1, totalItems = 0 } = await WorkflowsService.getAll(this.filters)

            this.setUpPages(totalPages, totalItems)
            this.setUpWorkflows(items)

            return items
        } catch (err) {
            this.setUpWorkflows([])
        }

        return []
    }

    public async deleteWorkflowById(id: UUID) {
        try {
            await WorkflowsService.remove(id)
            await this.fetch()
        } catch (err) {
            throw err
        }
    }

    public async setFilters(filters: Partial<WorkflowsFilters>): Promise<void> {
        if (!filters.page) {
            this.filters = { ...this.filters, ...filters, page: 1 }
        } else {
            this.filters = { ...this.filters, ...filters }
        }
        await this.fetch()
    }

    private setUpPages(pages: number, items: number) {
        this.items = items
        this.pages = pages
    }

    private setUpWorkflows(ws: ShortWorkflow[]) {
        this.workflows = ws
    }

    public clean() {
        this.filters = defaultFilters
        this.workflows = []
    }
}
