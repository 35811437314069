import React, { ChangeEvent, useEffect, useState } from 'react'
import { Checkbox, Flex, Text } from '@chakra-ui/react'
import { Button } from '@chakra-ui/button'
import { observer } from 'mobx-react-lite'
import { ShowStatusFilter } from './ShowStatusFilter'
import { Input } from './Input'
import { FilterIcon } from 'icons'
import { workflowVersionsStore } from 'stores'
import { FiltersBox, AssignedBox, StatusText } from '../styles'

interface IFilters {
    isRuleVersionSelection?: boolean
}

export const Filters = observer(({isRuleVersionSelection = false}: IFilters) => {
    const [isFiltersOpen, setIsFiltersOpen] = useState(false)
    const [isChecked, setIsChecked] = useState(false)

    const isInWow = workflowVersionsStore.filters.isInWow

    const onChecked = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
        const value = e.target.checked

        setIsChecked(value)
        workflowVersionsStore.setFilters({ isInWow: value })
        isRuleVersionSelection ? await workflowVersionsStore.fetchWorkflowByHeaderId() : await workflowVersionsStore.fetch()
    }

    const handleOpen = (): void => setIsFiltersOpen(prev => !prev)

    useEffect(() => {
        setIsChecked(isInWow)
    }, [isInWow])

    return (
        <FiltersBox
            marginBottom={workflowVersionsStore.isFiltersApplied() ? '15px' : '62px'}
        >
            <Input isRuleVersionSelection={isRuleVersionSelection}/>
            <Flex position="relative">
                <AssignedBox>
                    <Checkbox
                        marginLeft="15px"
                        isChecked={isChecked}
                        onChange={onChecked}
                    >
                        <Text variant="paragraphsmall">
                            Assigned
                        </Text>
                    </Checkbox>
                </AssignedBox>
                <Button
                    variant="outlineWhiteBlue"
                    onClick={handleOpen}
                >
                    <FilterIcon boxSize="12px" color="color.100" />
                    <StatusText>
                        Status
                    </StatusText>
                </Button>
                <ShowStatusFilter
                    isOpen={isFiltersOpen}
                    setOpen={setIsFiltersOpen}
                    isRuleVersionSelection={isRuleVersionSelection}
                />
            </Flex>
        </FiltersBox>
    )
})
