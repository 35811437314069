import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const PlainArrowTopIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 10 6"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M5.0002 1.44843L8.77593 5.22417C9.01025 5.45848 9.39014 5.45848 9.62446 5.22417C9.85877 4.98985 9.85877 4.60995 9.62446 4.37564L5.42446 0.175639C5.19014 -0.0586758 4.81025 -0.0586758 4.57593 0.175639L0.375931 4.37564C0.141617 4.60995 0.141617 4.98985 0.375931 5.22417C0.610246 5.45848 0.990145 5.45848 1.22446 5.22417L5.0002 1.44843Z"
                fill="currentColor"
            />
        </Icon>
    )
}
