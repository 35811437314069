import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const CategoryDistSourcesIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 12 11"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g filter="url(#filter0_d)">
                <path
                    d="M10.75 6.11111H10.5V4.23438C10.5 4.01389 10.4203 3.80208 10.2797 3.64583L8.71875 1.91146C8.57812 1.75521 8.3875 1.66667 8.18906 1.66667H7.5V0.833333C7.5 0.373264 7.16406 0 6.75 0H1.75C1.33594 0 1 0.373264 1 0.833333V6.38889C1 6.84896 1.33594 7.22222 1.75 7.22222H2C2 8.14236 2.67188 8.88889 3.5 8.88889C4.32812 8.88889 5 8.14236 5 7.22222H7C7 8.14236 7.67188 8.88889 8.5 8.88889C9.32812 8.88889 10 8.14236 10 7.22222H10.75C10.8875 7.22222 11 7.09722 11 6.94444V6.38889C11 6.23611 10.8875 6.11111 10.75 6.11111ZM3.5 8.05556C3.08594 8.05556 2.75 7.68229 2.75 7.22222C2.75 6.76215 3.08594 6.38889 3.5 6.38889C3.91406 6.38889 4.25 6.76215 4.25 7.22222C4.25 7.68229 3.91406 8.05556 3.5 8.05556ZM8.5 8.05556C8.08594 8.05556 7.75 7.68229 7.75 7.22222C7.75 6.76215 8.08594 6.38889 8.5 6.38889C8.91406 6.38889 9.25 6.76215 9.25 7.22222C9.25 7.68229 8.91406 8.05556 8.5 8.05556ZM9.75 4.44444H7.5V2.5H8.18906L9.75 4.23438V4.44444Z"
                    fill="url(#paint0_linear)"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d"
                    x="0"
                    y="0"
                    width="12"
                    height="10.8889"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        result="shape"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear"
                    x1="6"
                    y1="0"
                    x2="6"
                    y2="8.88889"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#EFEFEF" />
                </linearGradient>
            </defs>
        </Icon>
    )
}
