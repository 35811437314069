import React, { FC } from 'react'
import { RulesTitleBox, RulesHeaderItem } from './styles'

export const RulesSetsHeader: FC = () => {
    return (
        <RulesTitleBox>
            <RulesHeaderItem flexGrow={1}>Name</RulesHeaderItem>
            <RulesHeaderItem flexGrow={2.5}>Order Source</RulesHeaderItem>
            <RulesHeaderItem
                display="flex"
                justifyContent="center"
                flexGrow={0.5}
            >
                Actions
            </RulesHeaderItem>
        </RulesTitleBox>
    )
}
