import { FC } from 'react'
import { CellProps } from 'react-table'

export const HttpMethodsCell: FC<CellProps<string[]>> = ({ value })  => {
    if (!value.length) return null
    if (value.length === 1) return value[0]
    if (value.length > 1) return value.join(', ')
}

export default HttpMethodsCell
