import { Flex, Text } from '@chakra-ui/layout'
import { CrossSmallIcon } from 'icons'
import { chakra } from 'utils/chakra'

export const BlueDetails = chakra(Text, {
    variant: 'h6',
    color: 'blue.300',
    marginRight: '8px',
})

export const CardsBox = chakra(Flex, {
    justifyContent: 'center',
})

export const CrossSmallIconStyled = chakra(CrossSmallIcon, {
    boxSize: '10px',
    color: 'color.100',
    cursor: 'pointer',
})

export const HeaderBox = chakra(Flex, {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 'fit-content',
    wordBreak: 'break-all',
})

export const InfoBox = chakra(Flex, {
    flexDirection: 'column',
    width: '100px',
    textAlign: 'start',
})

export const MiddleBox = chakra(Flex, {
    flexDirection: 'column',
    margin: '8px 0',
})

export const HeadingText = chakra(Text, {
    variant: 'paragraphsmall',
    color: 'color.400',
})

export const InfoText = chakra(Text, {
    variant: 'paragraph',
    textTransform: 'capitalize',
})

export const CardText = chakra(Text, {
    variant: 'paragraph',
    maxWidth: '105px',
    overflow: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    style: {
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
    },
})

export const CardBox = chakra(Flex, {
    margin: '0 6px',
    flexDirection: 'column',
    textAlign: 'start',
    alignItems: 'flex-start',
    padding: '4px 8px',
    maxWidth: '118px',
    minWidth: '100px',
    minHeight: '105px',
    background: 'blueWhite.950',
    borderRadius: '6px',
    border: '1px solid',
    borderColor: 'blueWhite.980',
})
