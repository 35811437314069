import { Text, StyleSheet, View } from '@react-pdf/renderer'
import { performanceByTotalOdersColumns } from '../ReportsTable/columnMoc'
import { dateOnlyFormatter } from '../utils'
import { Cell1 } from './Cellstyle/cell'
import { IOrderPerformance } from 'types/core'

export const performanceBytotalOrdersStyles = StyleSheet.create({
    row: {
        fontSize: '7',
        display: 'flex',
        flexDirection: 'row',
    },
    cell1: {
        width: '20%',
    },
})

export const performanceBytotalOrdersHeader = (
    <View style={performanceBytotalOrdersStyles.row}>
        {!!performanceByTotalOdersColumns.length &&
            performanceByTotalOdersColumns.map(column => (
                <Text key={column.Header} style={column.style}>
                    {column.Header}
                </Text>
            ))}
    </View>
)

export const performanceBytotalOrdersRow = (data: IOrderPerformance) => {
    const performanceRow = [
        dateOnlyFormatter(data.orderDate),
        data.quantityReceived,
        data.quantityCancelled,
        data.quantityOpen,
        data.quantityCompleted,
    ]
    return (
        <View style={performanceBytotalOrdersStyles.row}>
            {!!performanceRow?.length &&
                performanceRow?.map(performanceItem => {
                    return (
                        <Cell1
                            cell1={performanceBytotalOrdersStyles.cell1}
                            data={performanceItem}
                        />
                    )
                })}
        </View>
    )
}
