import { Dispatch, FC, useState } from 'react'
import { observer } from 'mobx-react-lite'
import DateFnsUtils from '@date-io/date-fns'
import { useColorMode } from '@chakra-ui/react'
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { makeStyles } from '@mui/styles'
import { getThemeColor } from 'utils/getThemeColor'
import { DatePickersBox, DatePickerBox, FlexBox } from './styles'

interface IDatePickersProps {
    getfromDate: Dispatch<Date>
    getToDate: Dispatch<Date>
}

export const DatePickers: FC<IDatePickersProps> = observer(
    ({ getfromDate, getToDate }) => {
        const [fromDate, setFromDate] = useState<MaterialUiPickersDate>(null)
        const [toDate, setToDate] = useState<MaterialUiPickersDate>(null)
        const { colorMode } = useColorMode()

        const changeFromDate = (date: MaterialUiPickersDate) => {
            setFromDate(date)
            getfromDate(date as Date)
        }

        const changeToDate = (date: MaterialUiPickersDate) => {
            setToDate(date)
            getToDate(date as Date)
        }

        const useInputStyles = makeStyles({
            root: {
                backgroundColor: getThemeColor(colorMode, 'color.900'),
                borderRadius: '5px',
                height: '26.5px',
                fontSize: '14px',
                border: `0.2px solid ${getThemeColor(colorMode, 'color.400')}`,
                width: '137px',

                '& .MuiIconButton-root': {
                    padding: 0,
                    fontSize: '10px',
                    borderRadius: '10px',
                    color: getThemeColor(colorMode, 'color.100'),
                },
                '& .MuiInputBase-input': {
                    marginLeft: '10px',
                    fontSize: '14px',
                    paddingLeft: 0,
                    color: getThemeColor(colorMode, 'color.100'),
                },
            },
        })

        const inputClasses = useInputStyles()

        return (
            <DatePickersBox>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <FlexBox>
                        <DatePickerBox>
                            <KeyboardDatePicker
                                autoOk
                                inputVariant="standard"
                                format="MM/dd/yyyy"
                                value={fromDate}
                                InputProps={{ classes: inputClasses }}
                                placeholder="From"
                                size="small"
                                onChange={date => changeFromDate(date)}
                            />
                        </DatePickerBox>
                        <DatePickerBox>
                            <KeyboardDatePicker
                                autoOk
                                inputVariant="standard"
                                format="MM/dd/yyyy"
                                value={toDate}
                                minDate={fromDate}
                                InputProps={{ classes: inputClasses }}
                                placeholder="To"
                                size="small"
                                onChange={date => changeToDate(date)}
                            />
                        </DatePickerBox>
                    </FlexBox>
                </MuiPickersUtilsProvider>
            </DatePickersBox>
        )
    }
)
