import { ModalBody } from '@chakra-ui/react'
import { Text } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/button'
import { chakra } from 'utils/chakra'

export const ModalBodyStyled = chakra(ModalBody, {
    padding: '0 15px',
    variant: 'modal',
    color: 'color.100',
})

export const ModalCloseButton = chakra(Button, {
    variant: 'modal',
    color: 'color.100',
    _hover: {
        background: 'grayBlue.720'
    }
})

export const ModalConfirmButton = chakra(Button, {
    variant: 'modal',
    marginLeft: '5px',
    width: 'auto',
    _hover: {
        background: 'grayBlue.720'
    }
})

export const ModalHeaderText = chakra(Text, {
    variant: 'h3',
    textAlign: 'center',
})
