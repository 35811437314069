import React, { FC, useEffect, useState } from 'react'
import { Button, Stack, Text } from '@chakra-ui/react'
import { toJS } from 'mobx'
import Tree from 'rc-tree'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { EventDataNode, Key } from 'rc-tree/lib/interface'
import { TreeNodeProps } from 'rc-tree/lib/TreeNode'
import { observer } from 'mobx-react-lite'
import { CreateTenantModal } from './createTenantModal'
import { DeleteTenantModal } from './deleteTenantModal'
import { Circle, CircleDot, MinusIcon, PlusIcon, TreeArrow } from 'icons'
import { tenantsStore, userStore } from 'stores'
import { routes } from 'navigation/routes'
import { DividerStyled, TreeBox } from './style'
import 'rc-tree/assets/index.css'
import './rc-tree-override.css'

interface ITenantTree {
    showManagementButtons?: boolean
}

export const TenantTree: FC<ITenantTree> = observer(
    ({ showManagementButtons = false }) => {
        const [isOpen, setCreateIsOpen] = useState(false)
        const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
        const navigate = useNavigate()
        const location = useLocation()
        const { id = '' } = useParams()
        const tenantsTree = toJS(tenantsStore.tenantsHierarchy)
        const selectedTenant = toJS(tenantsStore.selectedTenant)
        const expandedKeys = toJS(tenantsStore.expandedKeys)
        const { isTenantAdmin, isSuperAdmin } = toJS(userStore.currentRoles)
        const isAdmin = isTenantAdmin || isSuperAdmin

        const getTenantRoutingRule = (tenantId: string) => {
            if (location.pathname === routes.tenants)
                tenantsStore.getTenantRoutingRule(tenantId)
        }

        const onSelect = (selectedKeys: Key[]) => {
            const selectedKey = selectedKeys[0]
            if (selectedKey) {
                const selectedId = selectedKey ? selectedKey.toString() : ''
                tenantsStore.setSelectedTenant(selectedId)
                getTenantRoutingRule(selectedId)
                if (id) navigate(routes.users)
            } else {
                tenantsStore.setSelectedTenant('')
                getTenantRoutingRule('')
            }
        }

        const onLoadData = (treeNode: EventDataNode) => {
            return tenantsStore.getTenantChildrenTenants(treeNode.key)
        }

        const switcherIcon = (treeNode: TreeNodeProps) => {
            return <TreeArrow color="color.100" isOpen={!!treeNode.expanded} />
        }

        const treeImage = (treeNode: TreeNodeProps) => {
            if (treeNode.data?.children?.length)
                return <CircleDot color="blue.300" />
            return <Circle color="blue.300" />
        }

        const onExpand = (expandedKeys: Key[]) => {
            tenantsStore.setExpandedKeys(expandedKeys)
        }

        useEffect(() => {
            if (!tenantsTree.length) {
                tenantsStore.fetch()
            } else {
                tenantsStore.setSelectedTenant(
                    selectedTenant.id
                        ? selectedTenant.id
                        : (tenantsTree[0].key as string)
                )
                getTenantRoutingRule(
                    selectedTenant.id
                        ? selectedTenant.id
                        : (tenantsTree[0].key as string)
                )
            }
            // Need for improvement. Warnings console
            /* eslint-disable */
        }, [tenantsTree.length])

        return (
            <>
                {showManagementButtons && (
                    <Stack spacing={4} direction="row" align="center">
                        <Button
                            variant="blue"
                            size="sm"
                            onClick={() => setCreateIsOpen(!isOpen)}
                            disabled={!isAdmin}
                        >
                            <PlusIcon w={3} h={3} color="white" />
                        </Button>
                        <Button
                            variant="blue"
                            size="sm"
                            onClick={() => setDeleteModalIsOpen(true)}
                            disabled={!selectedTenant.id || !isAdmin}
                        >
                            <MinusIcon w={3} h={3} color="white" />
                        </Button>
                    </Stack>
                )}
                <DividerStyled orientation="horizontal" />

                {tenantsTree && !!tenantsTree.length && (
                    <TreeBox>
                        <Tree
                            showLine
                            icon={treeImage}
                            treeData={tenantsTree}
                            loadData={onLoadData}
                            onExpand={onExpand}
                            defaultExpandedKeys={expandedKeys}
                            onSelect={selectedKey => onSelect(selectedKey)}
                            selectedKeys={
                                selectedTenant.id ? [selectedTenant.id] : []
                            }
                            switcherIcon={switcherIcon}
                        />
                    </TreeBox>
                )}
                {!tenantsTree?.length && <Text>Loading ...</Text>}
                {showManagementButtons && (
                    <>
                        <CreateTenantModal
                            isOpen={isOpen}
                            onClose={() => setCreateIsOpen(false)}
                            headerText="Create new tenant"
                            buttonText="Yes, Create"
                        />
                        <DeleteTenantModal
                            isOpen={deleteModalIsOpen}
                            tenantId={selectedTenant.id}
                            bodyText="Are you sure?"
                            onClose={() => setDeleteModalIsOpen(false)}
                            headerText="Delete tenant"
                            buttonText="Delete"
                        />
                    </>
                )}
            </>
        )
    }
)
