import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'

export const ArrowRightCardIcon: FC<IconProps> = props => {
    return (
        <Icon
            viewBox="0 0 13 9"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                transform="rotate(-180 6.5 4)"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.9785 3.00073C12.5308 3.00073 12.9785 3.44844 12.9785 4.00073C12.9785 4.55301 12.5308 5.00073 11.9785 5.00073L4.47248 5.00073L5.99941 6.50071C6.39655 6.89955 6.39655 7.54618 5.99941 7.94502C5.60226 8.34385 4.95837 8.34385 4.56122 7.94502L1.36833 4.79305C1.13124 4.61019 0.978516 4.3233 0.978516 4.00073C0.978516 3.66437 1.14458 3.36679 1.3992 3.18552L4.56122 0.299848C4.95836 -0.0989851 5.60226 -0.098985 5.9994 0.299848C6.39654 0.698682 6.39654 1.34532 5.9994 1.74415L4.58228 3.00073L11.9785 3.00073Z"
                fill="currentColor"
            />
        </Icon>
    )
}
