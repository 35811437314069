import { typographyVariants } from './typography'

export const Text = {
    baseStyle: {
        color: 'color.100',
    },
    variants: typographyVariants,
    defaultProps: {
        variant: 'paragraph',
    },
}
