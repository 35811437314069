import React, { FC } from 'react'
import Icon, { IconProps } from '@chakra-ui/icon'
import { useColorMode } from '@chakra-ui/react'
import { Theme } from 'types/core'

export const ListIcon: FC<IconProps> = props => {
    const { colorMode } = useColorMode()
    return (
        <Icon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M0 6C0 2.68629 2.68629 0 6 0H18C21.3137 0 24 2.68629 24 6V18C24 21.3137 21.3137 24 18 24H6C2.68629 24 0 21.3137 0 18V6Z"
                fill={colorMode === Theme.Dark ? '#F0F0F0' : '#EAF4FE'}
            />
            <path
                d="M15.5714 14.8571V15.1429C15.5714 15.6163 15.1877 16 14.7143 16H7.85714C7.38375 16 7 15.6163 7 15.1429V10.5714C7 10.098 7.38375 9.71429 7.85714 9.71429H8.14286V13.4286C8.14286 14.2163 8.78371 14.8571 9.57143 14.8571H15.5714ZM17.2857 13.4286V8.85714C17.2857 8.38375 16.902 8 16.4286 8H9.57143C9.09804 8 8.71429 8.38375 8.71429 8.85714V13.4286C8.71429 13.902 9.09804 14.2857 9.57143 14.2857H16.4286C16.902 14.2857 17.2857 13.902 17.2857 13.4286ZM11.5714 9.71429C11.5714 10.1877 11.1877 10.5714 10.7143 10.5714C10.2409 10.5714 9.85714 10.1877 9.85714 9.71429C9.85714 9.24089 10.2409 8.85714 10.7143 8.85714C11.1877 8.85714 11.5714 9.24089 11.5714 9.71429ZM9.85714 12.2857L10.8485 11.2944C10.9322 11.2107 11.0678 11.2107 11.1515 11.2944L11.8571 12L14.2771 9.58009C14.3607 9.49641 14.4964 9.49641 14.5801 9.58009L16.1429 11.1429V13.1429H9.85714V12.2857Z"
                fill={colorMode === Theme.Dark ? '#3A3A3A' : '#027AFF'}
            />
        </Icon>
    )
}
