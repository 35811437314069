import { useColorMode } from '@chakra-ui/react'
import EChartsReact from 'echarts-for-react'
import { observer } from 'mobx-react-lite'
import { ChartBox } from './styles'
import { toJS } from 'mobx'
import { dailyOrderStore } from 'stores'
import { myOptions } from './utils'


export const Chart = observer(() => {

    const { colorMode } = useColorMode()
    const seriesData = toJS(dailyOrderStore?.dailyCountGraph)
    const timeIntervals = toJS(dailyOrderStore?.xaxisTimeInterevels)

    return (

        <ChartBox>
            <EChartsReact option={myOptions(timeIntervals, seriesData, colorMode)} />
        </ChartBox>
        
    )
})