import { Menu, Button, MenuItem, MenuButton, MenuList } from '@chakra-ui/react'
import { CSVExport } from './csvExport'
import { PDFExport } from './pdfExport'
import {
    IPPSalesOverDuration,
    IProductPerformanceItem,
    IReportData,
    IReportStyles,
} from 'types/core'
import { DownloadIcon } from 'icons'
import { ExportText, menuButtonStyles, menuListStyle } from './styles'

interface IExportProps<Data> {
    data?: IReportData[]
    performanceByTotalOrderData?: IProductPerformanceItem[]
    productSalesOverDuration?: IPPSalesOverDuration[]
    onExport?: () => void
    reportName: string
    fileName: string
    curDate: string
    totalItems: number
    columns: JSX.Element
    pdfStyles: IReportStyles
    rowStyles: (data: Data | any) => JSX.Element
}

export const ExportButton = function <Data>({
    data,
    performanceByTotalOrderData,
    productSalesOverDuration,
    onExport,
    reportName,
    fileName,
    curDate,
    totalItems,
    columns,
    pdfStyles,
    rowStyles,
}: IExportProps<Data>): JSX.Element {
    return (
        <Menu matchWidth>
            <MenuButton
                as={Button}
                style={menuButtonStyles}
                onClick={onExport}
                rightIcon={<DownloadIcon boxSize="11px" color="color.100" />}
            >
                Export
            </MenuButton>
            <MenuList style={menuListStyle}>
                <MenuItem>
                    <ExportText>
                        <CSVExport
                            data={data || []}
                            performanceByTotalOrderData={
                                performanceByTotalOrderData || []
                            }
                            productSalesOverDuration={
                                productSalesOverDuration || []
                            }
                            fileName={fileName}
                            totalItems={totalItems}
                            reportName={reportName}
                        />
                    </ExportText>
                </MenuItem>
                <MenuItem>
                    <ExportText>
                        <PDFExport
                            data={data || []}
                            performanceByTotalOrderData={
                                performanceByTotalOrderData || []
                            }
                            productSalesOverDuration={
                                productSalesOverDuration || []
                            }
                            reportName={reportName}
                            fileName={fileName}
                            curDate={curDate}
                            totalItems={totalItems}
                            columns={columns}
                            pdfStyles={pdfStyles}
                            rowStyles={rowStyles}
                        />
                    </ExportText>
                </MenuItem>
            </MenuList>
        </Menu>
    )
}
