import React, { FC, useEffect, useState } from 'react'
import { toJS } from 'mobx'
import { Box } from '@chakra-ui/layout'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { CustomAccordion } from 'components/baseComponents/Accordion'
import { AccordionRulesEngineButton } from '../AccordionRulesEngineButton'
import { StepInfo } from '../../StepInfo'
import { auditOrderStore } from 'stores'
import { IWorkflowStep, UUID, IWorkflow } from 'types/core'
import { AccordionPanelBox, AccordionStyled } from 'pages/OrderDetailsPage/styles'

interface AccordionPanelProps {
    isOpened: boolean
}

export const AccordionPanel: FC<AccordionPanelProps> = observer(({ isOpened }) => {
    const [opened, setOpened] = useState<number[]>([])

    const { id } = useParams()

    const isMatch = (workflowType: IWorkflow["type"]) =>
        workflowType === null || workflowType === 'Rules'

    const auditWorkflowRuns = toJS(auditOrderStore.workflowRuns).filter(item => isMatch(item.workflow.type))

    const renderAccordionPanel = (steps: IWorkflowStep[], workflowId: UUID, isParentOpen: boolean) => (
        isParentOpen ? (
            <Box backgroundColor="color.900">
                {steps.map((step) =>
                    <StepInfo
                        key={step.id}
                        step={step}
                    />
                )}
            </Box>
        ) : <div>Something went wrong...</div>
    )

    useEffect(() => {
        if (!isOpened) setOpened([])
    }, [isOpened])

    useEffect(() => {
        if (id && !auditWorkflowRuns.length) auditOrderStore.getWorkflowRuns(id)
    }, [id, auditWorkflowRuns.length])

    return (
        <AccordionPanelBox>
            <AccordionStyled
                allowToggle
                allowMultiple
                index={opened}
                onChange={(expandedIndex: number[]) => setOpened(expandedIndex)}
            >
                {auditWorkflowRuns.map(({ workflow, steps }, index) => (
                    <CustomAccordion
                        key={workflow.id}
                        width="100%"
                        margin="8px 0"
                        AccordionButtonContent={<AccordionRulesEngineButton workflow={workflow} />}
                        AccordionPanelContent={renderAccordionPanel(steps, workflow.id, opened.includes(index))}
                    />
                ))}
            </AccordionStyled>
        </AccordionPanelBox>
    )
})
