import { StyleSheet } from '@react-pdf/renderer'

export const headerStyles = StyleSheet.create({
    page: {
        fontSize: '9',
        padding: '20pt 20pt 40pt 20pt',
    },
    table: {
        width: '100%',
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
    },
    version: {
        textAlign: 'left',
    },
    name: {
        textAlign: 'center',
        flex: 1,
    },
    info: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: '16pt',
    },
    location: {
        paddingRight: '50pt',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 16,
        borderBottom: '1px solid black',
        width: '100%',
    },
})

export const footerStyles = StyleSheet.create({
    end: {
        textAlign: 'center',
        marginTop: '16px',
    },
    username: {
        textAlign: 'left',
        position: 'absolute',
        bottom: '20',
        left: '20',
    },
    pageNumber: {
        textAlign: 'right',
        position: 'absolute',
        bottom: '20',
        right: '20',
    },
})
