import { makeAutoObservable } from 'mobx'
import { InternalParamStore } from 'components/diagrams/nodes/NodeLayout/NodeParameter/params/Select/Internal/internalStore'
import { ExternalParamStore } from 'components/diagrams/nodes/NodeLayout/NodeParameter/params/Select/External/externalStore'
import { UUID } from 'types/core'
import { selectParamStores } from './index'

interface IParamStore {
    [key: UUID]: {
        external?: {
            [key: UUID]: ExternalParamStore
        },
        internal?: {
            [key: UUID]: InternalParamStore
        },
    }
}

export class SelectParamStores {
    public stores: IParamStore = {}

    constructor() {
        makeAutoObservable(this)
    }

    public async setStore (isExternal: boolean, id: UUID, stepId: UUID, defaultSelected: string) {
        if (isExternal && !this.stores[stepId]?.external?.[id]) {
            const externalStore = { [id]: new ExternalParamStore(id, stepId, defaultSelected) }
            const external = this.stores[stepId]?.external ? {
                ...this.stores[stepId].external,
                ...externalStore,
            } : externalStore

            this.stores = { ...this.stores, [stepId]: { external } }

            await this.stores[stepId].external?.[id].init()
        }
        if (!isExternal && !this.stores[stepId]?.internal?.[id]) {
            const internalStore = { [id]: new InternalParamStore(id, stepId, defaultSelected) }
            const internal = this.stores[stepId]?.internal ? {
                ...this.stores[stepId].internal,
                ...internalStore,
            } : internalStore

            this.stores = { ...this.stores, [stepId]: { internal } }

            await this.stores[stepId].internal?.[id].init()
        }
    }

    public getSelectedLabel (stepMetadataId: UUID): string {
        const getStore = selectParamStores.stores[stepMetadataId]
            && (selectParamStores.stores[stepMetadataId].internal || selectParamStores.stores[stepMetadataId].external)

        if (getStore) {
            const getValue = Object.values(getStore)[0]

            if (getValue?.value?.label) {
                return getValue.value.label
            }
        }

        return ''
    }
}
