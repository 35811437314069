import React, { useEffect, useState, useRef } from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { Box, Container, Stack } from '@chakra-ui/layout'
import { CustomAccordion } from 'components/baseComponents/Accordion'
import { AccordionsHeader } from 'components/Accordion/AccordionsHeader'
import { CustomAccordionButton } from 'components/Accordion/AccordionButton'
import { CustomAccordionPanel } from 'components/Accordion/AccordionPanel'
import { NumericPagination } from 'components/NumericPagination'
import { OrderFilters } from './Filters'
import { orderStore } from 'stores'
import { accordionHeaderOptions } from 'pages/HomePage/mock'
import { IOrder } from 'types/core'
import { AccordionStyled, BoxStyled } from 'pages/HomePage/styles'

const manualRetryChecked = 1
const orderPageType = 'orderStatusTracking'

export const OrderStatusTracking = observer(() => {
    const [openedId, setOpenedId] = useState<string[]>([])
    const [isFiltersOpen, setIsFiltersOpen] = useState(false)
    const [retryOrders, setRetryOrders] = useState([''])
    const refreshTimeoutRef = useRef<number | undefined>(undefined)

    const orders = toJS(orderStore.orders)
    const totalItems = toJS(orderStore.totalItems)
    const openTabItems = toJS(orderStore.openTabDetails)
    const isDasboard = true

    const handleFiltersOpen = (isChecked: boolean) => {
        setIsFiltersOpen(isChecked)
    }

    const toRetry = (orderID: string) => {
        return retryOrders.includes(orderID)
    }
    const handleCheckChange = (orderID: string) => {
        const index = !!retryOrders && retryOrders.indexOf(orderID)
        index >= 0 ? retryOrders.splice(index, 1) : setRetryOrders(prev => [...prev, orderID])

        const checkedOrders = retryOrders.length + manualRetryChecked

        orderStore.checkCountErrorStatus(checkedOrders)
    }
    const onSubmitRetry = (reason: string) => {
        orderStore.retryOrders(retryOrders, reason)
    }
    const childOrderHandler = (order: IOrder) => orderStore.splitOrderHierarchy(order, orderPageType)

    useEffect(() => {
        setRetryOrders([])
    }, [isFiltersOpen])

    useEffect(() => {
        orderStore.setLocalStorageFilters()
        orderStore.setLocalFilters()
        orderStore.getOrderSources()
        orderStore.getFilters()
        orderStore.search()

        // const interval = setInterval(() => {
        //     orderStore.search()
        //     orderStore.getSelectedTabSpliteOrders()
        // }, 5000)

        refreshTimeoutRef.current = window.setTimeout(async () => {
          await refreshOrders()
        }, 10000)

        return () => {
            orderStore.clean()
            window.clearTimeout(refreshTimeoutRef.current)
            // clearInterval(interval)
        }
    }, [])
    useEffect(() => {
        orderStore.initStatus(isDasboard)
    }, [])

    useEffect(() => {
        orderStore.search()
        orderStore.splitCounter(0)
    }, [orderStore.isSplitCounter])

    const refreshOrders = async () => {
      await orderStore.search()
      await orderStore.getSelectedTabSpliteOrders()

      refreshTimeoutRef.current = window.setTimeout(async () => {
        await refreshOrders()
      }, 10000)
    }

    const opendIndex = () => {
        let openItem: number[] = []
        openedId?.forEach(item => {
            orders?.forEach((order, index) => {
                if (item === order.id) openItem?.push(index)
            })
        })
        return openItem
    }

    const tabHandleClick = (orderId: string) => {
        if (openedId?.includes(orderId)) {
            setOpenedId(openedId?.filter(openId => openId !== orderId))
            orderStore.getTabIndex(
                openTabItems?.filter(opeTab => opeTab?.childId !== orderId)
            )
        } else {
            setOpenedId(pre => [...pre, orderId])
            orderStore.getTabIndex([
                ...openTabItems,
                { childId: orderId, childrenData: [] },
            ])
        }
    }

    return (
        <Container maxWidth="100%" minWidth="1xl" margin="0" padding="0">
            <Stack
                as={Box}
                spacing={{ base: 8, md: 14 }}
                py={{ base: 20, md: 4 }}
            >
                <Stack>
                    <BoxStyled>
                        <OrderFilters
                            handleFiltersOpen={handleFiltersOpen}
                            onSubmitRetry={onSubmitRetry}
                        />
                        {!!orders.length && (
                            <>
                                <AccordionsHeader
                                    options={accordionHeaderOptions}
                                    setRetryOrders={setRetryOrders}
                                    isFiltersOpen={isFiltersOpen}
                                />
                                <AccordionStyled
                                    allowMultiple
                                    allowToggle
                                    index={opendIndex()}
                                >
                                    {orders.map((order, index) => {
                                        const isOnlyOne = orders.length === 1
                                        const isFirst = index === 0
                                        const isLast = index === orders.length - 1

                                        return (
                                            <Box
                                                key={order.id}
                                                onClick={() =>
                                                    tabHandleClick(order.id)
                                                }
                                            >
                                                <CustomAccordion
                                                    key={order.id}
                                                    AccordionButtonContent={
                                                        <CustomAccordionButton
                                                            order={order}
                                                            isOpen={opendIndex()?.includes(index)}
                                                            toRetry={toRetry}
                                                            handleCheckChange={handleCheckChange}
                                                            isFiltersOpen={isFiltersOpen}
                                                            isIconDashboard
                                                            isOnlyOne={isOnlyOne}
                                                            isFirst={isFirst}
                                                            isLast={isLast}
                                                            childOrderHandler={childOrderHandler}
                                                        />
                                                    }
                                                    AccordionPanelContent={
                                                        <CustomAccordionPanel
                                                            order={order}
                                                            splitOrderChildData={order}
                                                            toRetry={toRetry}
                                                            handleCheckChange={handleCheckChange}
                                                            isFiltersOpen={isFiltersOpen}
                                                            childOrderHandler={childOrderHandler}
                                                        />
                                                    }
                                                />
                                            </Box>
                                        )
                                    })}
                                </AccordionStyled>
                                <NumericPagination
                                    marginTop="24px"
                                    pages={orderStore.pages}
                                    totalItems={totalItems}
                                    items={orders.length}
                                    activePage={orderStore.localFilters.page}
                                    onChangePage={page =>
                                        orderStore.setPages({ page })
                                    }
                                />
                            </>
                        )}
                    </BoxStyled>
                </Stack>
            </Stack>
        </Container>
    )
})


