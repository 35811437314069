import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { Box } from '@chakra-ui/layout'
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    TableCaption,
    TableContainer,
    Button
} from '@chakra-ui/react'
import { NumericPagination } from 'components/NumericPagination'
import { ConfirmModal } from 'components/Modal/ConfirmModal'
import { UsersListItem } from 'pages/AdminPages/Users/UsersPage/content/UsersListItem'
import { PlusIcon } from 'icons'
import { routes } from 'navigation/routes'
import { tenantsStore, tenantUsersStore } from 'stores'
import { BoxStyled, HeadingStyled, ButtonBoxStyled } from '../styles'
import { AuthType } from 'api/models'
import { IUserModel } from 'stores/models'

export const UsersList = observer(() => {
    const navigate = useNavigate()

    const [isActivateOpen, setIsActivateOpen] = useState(false)
    const [isDisableOpen, setIsDisableOpen] = useState(false)
    const [selectedUser, setSelectedUser] = useState<IUserModel | null>(null)

    const selectedTenant = toJS(tenantsStore.selectedTenant)
    const tenantUsers = toJS(tenantUsersStore.users)
    const paging = toJS(tenantUsersStore.paging)

    const isEditable = selectedTenant?.authenticationType === AuthType.Password

    const openDisableModal = (user: IUserModel) => {
        setSelectedUser(user)
        setIsDisableOpen(true)
    }
    const closeDisableModal = () => setIsDisableOpen(false)

    const openActivateModal = (user: IUserModel) => {
        setSelectedUser(user)
        setIsActivateOpen(true)
    }
    const closeActivateModal = () => setIsActivateOpen(false)

    const handleUpdate = (user: IUserModel) => navigate(`${routes.users}/${user.id}`)
    const handleCreate = () => navigate(`${routes.users}/new`)
    const handleActivate = async () => {
        if (!!selectedUser) {
            await tenantUsersStore.activate(selectedUser)
        }
        closeActivateModal()
    }
    const handleDisable = async () => {
        if (!!selectedUser) {
            await tenantUsersStore.disable(selectedUser)
        }
        closeDisableModal()
    }

    useEffect(() => {
        if (selectedTenant.id && !!selectedTenant.authenticationType) {
            tenantUsersStore.fetch(selectedTenant.id, selectedTenant.authenticationType)
        } else {
            tenantUsersStore.resetState()
        }
    }, [selectedTenant.id, selectedTenant.authenticationType, paging.pageIndex])

    return (
        <Box>
            <BoxStyled>
                <HeadingStyled>
                    Users
                </HeadingStyled>
                <ButtonBoxStyled>
                    <Button
                        variant="blue"
                        size="sm"
                        onClick={handleCreate}
                        disabled={!isEditable}
                    >
                        <PlusIcon />
                    </Button>
                </ButtonBoxStyled>
            </BoxStyled>
            <TableContainer>
                <Table variant="simple">
                    <TableCaption>
                        <NumericPagination
                            marginTop="24px"
                            pages={paging.totalPages}
                            activePage={paging.pageIndex}
                            onChangePage={
                                page => tenantUsersStore.setPaging({ ...paging, pageIndex: page })
                            }
                        />
                    </TableCaption>
                    <Thead>
                        <Tr>
                            <Th>#</Th>
                            <Th>Name</Th>
                            <Th>E-mail</Th>
                            <Th>State</Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {tenantUsers && !!selectedTenant.authenticationType && tenantUsers.map(item =>
                            <UsersListItem
                                key={item.id}
                                item={item}
                                onDisableRequested={openDisableModal}
                                onActivateRequested={openActivateModal}
                                onUpdateRequested={handleUpdate}
                            />
                        )}
                    </Tbody>
                </Table>
            </TableContainer>

            <ConfirmModal
                isOpen={isActivateOpen}
                onClose={closeActivateModal}
                confirmFunc={handleActivate}
                headerText="Activate"
                bodyText={`Are you sure you want to activate the user ${selectedUser?.displayName}`}
                buttonText="Activate"
                colorButton="white"
            />

            <ConfirmModal
                isOpen={isDisableOpen}
                onClose={closeDisableModal}
                confirmFunc={handleDisable}
                headerText="Disable"
                bodyText={`Are you sure you want to disable the user ${selectedUser?.displayName}`}
                buttonText="Disable"
                colorButton="red.500"
            />

        </Box>
    )
})
