import React, { FC } from 'react'
import { Box, Center, HStack, Spacer, Text } from '@chakra-ui/layout'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import { userStore } from 'stores'
import { RuleStatusTag } from 'components/RuleStatusTag'
import { DoubleMenuDotsIcon } from 'icons'
import { WorkflowStatus } from 'types/core'
import {
    OutlinedButton,
    StyledButton,
    CenterOrder,
    PrioritizeItemBox,
    CrossSmallIconStyled,
    InfoBox,
} from '../styles'

interface PrioritizeItemProps {
    order: number
    description: string
    version: number
    status: WorkflowStatus
    onDiagramViewClick: () => void
    onOpenRule: () => void
    onRemoveItem: () => void
}

export const PrioritizeItem: FC<PrioritizeItemProps> = observer(({
    description,
    order,
    onDiagramViewClick,
    onRemoveItem,
    onOpenRule,
    version,
    status,
}) => {
    const { isCreator } = toJS(userStore.currentRoles)

    const handleRemove = () => {
        if (isCreator) return onRemoveItem
    }

    return (
        <HStack spacing="10px" width="100%" height="48px">
            <CenterOrder>
                {order})
            </CenterOrder>
            <PrioritizeItemBox>
                <DoubleMenuDotsIcon
                    boxSize="20px"
                    color={isCreator ? 'color.100' : 'blueWhite.25'}
                />
                <InfoBox>
                    <Center marginLeft="5px">
                        <Text>{description}</Text>
                    </Center>
                    <Center>
                        <Text marginRight="20px">Version {version}</Text>
                        <RuleStatusTag status={status} />
                    </Center>
                </InfoBox>
                <Spacer minWidth="5px" />
                <Center>
                    <OutlinedButton height="33px" onClick={onDiagramViewClick}>
                        Details
                    </OutlinedButton>
                    <StyledButton onClick={onOpenRule}>
                        Choose Version
                    </StyledButton>
                </Center>
            </PrioritizeItemBox>
            <Box>
                <CrossSmallIconStyled
                    color={isCreator ? 'color.100' : 'blueWhite.25'}
                    onClick={handleRemove()}
                />
            </Box>
        </HStack>
    )
})
