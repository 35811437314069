import React, { FC, useEffect, useState } from 'react'
import { Grid, Text } from '@chakra-ui/layout'
import { dateRangeStore } from '../store'
import { CenterYearsStyled } from '../../styles'

interface CalendarProps {
    onYearClick: () => void
    direction: string
}

const yearsLength = 15

export const YearsBody: FC<CalendarProps> = ({ onYearClick, direction }) => {
    const [years, setYears] = useState<number[]>([])

    const currentYear = new Date().getFullYear()

    const onYearCellClick = (year: number) => {
        direction === 'Left'
            ? dateRangeStore.updateLeftYear(year)
            : dateRangeStore.updateRightYear(year)
        onYearClick()
    }

    useEffect(() => {
        const yearsList = Array.from(
            { length: yearsLength },
            (_, index) => currentYear - index
        )
        setYears(yearsList)
    }, [currentYear])

    return (
        <Grid templateColumns="repeat(4, 56px)">
            {years.map(year => (
                <CenterYearsStyled
                    key={year}
                    onClick={() => onYearCellClick(year)}
                >
                    <Text variant="paragraphsmall">{year}</Text>
                </CenterYearsStyled>
            ))}
        </Grid>
    )
}
