import { IconStatus } from 'components/StatusTag/mock'
import { OrderStatus, WorkflowRunStatus, WorkflowStatus } from 'types/core'

export function mapWorkflowStatus(
    status: WorkflowStatus | WorkflowRunStatus | OrderStatus
): IconStatus {
    switch (status) {
        case WorkflowRunStatus.Started:
        case WorkflowStatus.Draft:
        case WorkflowStatus.Approved:
        case WorkflowStatus.Created:
        case WorkflowRunStatus.Success:
            return 'success'
        case WorkflowRunStatus.Processing:
        case WorkflowRunStatus.Skipped:
        case WorkflowStatus.Approval:
            return 'warning'
        case WorkflowRunStatus.Failed:
        case WorkflowStatus.Rejected:
            return 'error'
        default:
            return 'error'
    }
}
