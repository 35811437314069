import { useEffect, useState } from 'react'
import { SourceData } from './SourceData'
import { Box, Flex, Spacer, Text, VStack } from '@chakra-ui/layout'
import { CustomSelectContainer } from './Select'
import { CardContainer, ContentContainer, NameContainer } from './styles'
import { Center } from '@chakra-ui/react'
import { kpiStore } from 'stores'
import { toJS } from 'mobx'
import { ExportMenuOption } from '../ExportMenu'
import { observer } from 'mobx-react-lite'

interface SelectOption {
    value: string
    label: string
}

export const ShippingRateCard = observer(() => {
    const timeFrames: SelectOption[] = [
        {
            value: 'W',
            label: 'This week vs last week',
        },
        {
            value: 'M',
            label: 'This month vs last month',
        },
        {
            value: 'Y',
            label: 'This year vs last year',
        },
    ]

    const [updateTimeFrame, setUpdateTimeFrame] = useState<string>(
        timeFrames?.length ? timeFrames[0].value : ''
    )

    useEffect(() => {
        toJS(kpiStore.getOrderSources(updateTimeFrame))
    }, [updateTimeFrame])

    const sourceData = toJS(kpiStore.orderSources)

    return (
        <CardContainer>
            <NameContainer>
                <Flex>
                    <Text color="white" variant="h5">
                        Orders Received by Sources
                    </Text>
                    <Spacer />
                    <Center>
                        <ExportMenuOption
                            data={sourceData}
                            fileName="Ordersources"
                            id="ordersources"
                        />
                    </Center>
                </Flex>
            </NameContainer>
            <ContentContainer id="ordersources">
                <Flex marginBottom="8px">
                    <VStack>
                        <Box width="100%" marginTop="8px" marginLeft="8px">
                            {' '}
                            <CustomSelectContainer
                                placeholder=""
                                options={timeFrames}
                                onChange={setUpdateTimeFrame}
                            />
                        </Box>
                        <Spacer />
                        <SourceData />
                    </VStack>
                </Flex>
            </ContentContainer>
        </CardContainer>
    )
})
