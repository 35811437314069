import { useEffect, useState } from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { DatePickers } from 'pages/ReportsPage/DatePickers'
import { ReportsData } from 'pages/ReportsPage/ReportsTable'
import { ExportButton } from 'pages/ReportsPage/ExportButton'
import { NumericPagination } from 'components/NumericPagination'
import { getCurrentDate } from 'pages/ReportsPage/utils'
import {
    errorStatusReportHeader,
    errorStatusReportRow,
    errorStatusReportStyles,
} from 'pages/ReportsPage/PDFStyles/errorReportPDFStyle'
import { ReportType } from 'types/core'
import { errorReportStore } from 'stores'
import {
    FlexFilter,
    GridContainer,
    GridFilter,
    GridItems,
    GridPagination,
    GridTable,
    SubmitButton,
    TextItems,
    TextRecords,
} from '../styles'

export const ErrorStatusReport = observer(() => {
    const [fromDate, setFromDate] = useState<Date | null>(null)
    const [toDate, setToDate] = useState<Date | null>(null)
    const [status, setStatus] = useState<Boolean>(false)
    const errorStatusData = toJS(errorReportStore?.errorReport)
    const exportItem = toJS(errorReportStore?.errorReportExport)

    const errorSubmitDetails = async () => {
        errorReportStore.reloadPages()
        await errorReportStore.getErrorStatusReportData(
            fromDate?.toLocaleDateString() || '',
            toDate?.toLocaleDateString() || '',
            false
        )
        setStatus(true)
    }

    useEffect(() => {
        return () => {
            errorReportStore.clean()
        }
    }, [])

    const exportDetails = async () => {
        await errorReportStore.getErrorStatusReportData(
            fromDate?.toLocaleDateString() || '',
            toDate?.toLocaleDateString() || '',
            true
        )
    }

    const onExport = () => {
        exportDetails()
    }

    return (
        <GridContainer>
            <GridFilter>
                <FlexFilter>
                    <DatePickers
                        getfromDate={setFromDate}
                        getToDate={setToDate}
                    />
                    <SubmitButton onClick={errorSubmitDetails}>
                        Submit
                    </SubmitButton>
                    <ExportButton
                        data={exportItem}
                        onExport={onExport}
                        reportName={ReportType.errorStatusReport}
                        fileName={`Error_Status_Report__${
                            fromDate?.toLocaleDateString() || '--'
                        } to ${toDate?.toLocaleDateString() || '--'}__(${
                            getCurrentDate() || '--'
                        })`}
                        curDate={getCurrentDate()}
                        totalItems={errorReportStore?.totalItems}
                        columns={errorStatusReportHeader}
                        pdfStyles={errorStatusReportStyles}
                        rowStyles={errorStatusReportRow}
                    />
                </FlexFilter>
            </GridFilter>
            <GridTable>
                {status &&
                    (!!errorStatusData?.length ? (
                        <ReportsData
                            reportType={ReportType.errorStatusReport}
                        />
                    ) : (
                        <TextRecords>No Records Found</TextRecords>
                    ))}
            </GridTable>
            <GridItems>
                {status && !!errorStatusData?.length && (
                    <TextItems>{errorReportStore?.totalItems} Items</TextItems>
                )}
            </GridItems>
            <GridPagination>
                <NumericPagination
                    pages={errorReportStore.pages}
                    activePage={errorReportStore.filters.page}
                    onChangePage={page => {
                        errorReportStore.setFilters({ page })
                        errorReportStore.getErrorStatusReportData(
                            fromDate?.toLocaleDateString() || '',
                            toDate?.toLocaleDateString() || '',
                            false
                        )
                    }}
                />
            </GridPagination>
        </GridContainer>
    )
})
