import type { SystemStyleObject } from "@chakra-ui/theme-tools"

const baseStyle: SystemStyleObject = {
    _focus: {
        boxShadow: 'none',
    },
}

export const Link = {
    baseStyle,
}
