import { SystemStyleObject } from '@chakra-ui/styled-system'

const baseStyle: SystemStyleObject = {
    boxShadow: 'none',
    _focus: {
        boxShadow: 'none',
    },
}

const variantSolid: SystemStyleObject = {
    backgroundColor: 'buttonBg',
    color: 'buttonSolidColor',
    _hover: {
        backgroundColor: 'mintgreen.600',
        _disabled: {
            backgroundColor: 'gray.400',
        },
    },
    _active: {
        backgroundColor: 'mintgreen.700',
        _hover: {
            backgroundColor: 'mintgreen.600',
        },
    },
    _disabled: {
        backgroundColor: 'gray.400',
    },
}

const variantOutline: SystemStyleObject = {
    borderStyle: 'solid',
    borderWeight: '2px',
    borderColor: 'blue.200',
    color: 'buttonOutlineColor',
    _hover: {
        backgroundColor: 'blue.200',
        borderColor: 'blue.200',
        color: 'gray.900',
        _disabled: {
            background: 'gray.500',
        },
    },
    _disabled: {
        cursor: 'default',
        background: 'gray.500',
        color: 'gray.800',
        border: 0,
    },
}

const variantOutlineWhite: SystemStyleObject = {
    padding: '0 7px',
    height: '25px',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
    color: 'color.100',
    border: '2px solid',
    borderColor: 'blueWhite.200',
    _hover: {
        borderColor: 'orderTracking.150',
        color: 'orderTracking.150',
        _disabled: {
            cursor: 'default',
            backgroundColor: 'blue.800',
            color: 'gray.990',
            border: 0,
        },
    },
    _disabled: {
        cursor: 'default',
        backgroundColor: 'blue.800',
        color: 'gray.990',
        border: 0,
    },
}

const variantOutlineSmall: SystemStyleObject = {
    variantOutline,
    border: '1px solid',
    borderColor: 'blue.200',
    height: '24px',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '16px',
    padding: '0 7px',
    color: 'grayBlue.400',
    _hover: {
        borderColor: 'blue.400',
    },
}

const variantTransparent: SystemStyleObject = {
    backgroundColor: 'transparent',
    height: '32px',
    color: 'blue.300',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '17px',
    _hover: {
        color: 'blue.400',
        borderBottom: '2px solid',
        borderColor: 'blue.400',
        borderRadius: '0',
        marginTop: '1px',
    },
}

const variantUnderline: SystemStyleObject = {
    backgroundColor: 'transparent',
    height: '18px',
    maxWidth: '56px',
    minWidth: '56px',
    color: 'blue.300',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '17px',
    borderBottom: '1px solid',
    borderColor: 'blue.300',
    borderRadius: '0',
    marginTop: '9px',
    padding: '0',
    _hover: {
        color: 'blue.400',
        borderColor: 'blue.400',
    },
}

const variantBlue: SystemStyleObject = {
    backgroundColor: 'blue.200',
    height: '32px',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '16px',
    _hover: {
        backgroundColor: 'blue.400',
        _disabled: {
            backgroundColor: 'gray.400',
        }
    },
    _disabled: {
        backgroundColor: 'gray.500',
    }
}

const variantBlueBottom: SystemStyleObject = {
    ...variantBlue,
    height: '40px',
    width: '158px',
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '17px',
    marginTop: 'auto',
}

const variantBlueSmall: SystemStyleObject = {
    ...variantBlue,
    height: '24px',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '16px',
}

const variantBlueBig: SystemStyleObject = {
    ...variantBlue,
    height: '40px',
    width: '240px',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '20px',
}

const variantBlueFixed: SystemStyleObject = {
    backgroundColor: 'blue.200',
    height: '40px',
    width: '276px',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '16px',
    borderRadius: '6px',
    _hover: {
        backgroundColor: 'blue.400',
        _disabled: {
            backgroundColor: 'gray.400',
        }
    },
    _disabled: {
        backgroundColor: 'gray.500',
    }
}

const variantOutlineWhiteBlack: SystemStyleObject = {
    height: '32px',
    minWidth: '130px',
    maxWidth: '165px',
    marginRight: '8px',
    padding: '2px 10px',
    border: '1px solid',
    borderColor: 'blueBlack.75',
    backgroundColor: 'color.900',
    _hover: {
        borderColor: 'blue.300',
        _disabled: {
            background: 'gray.500',
        },
    },
    _disabled: {
        cursor: 'default',
        background: 'gray.500',
        color: 'gray.800',
        border: 0,
    },
}

const variantOutlineWhiteBlue: SystemStyleObject = {
    ...variantOutlineWhiteBlack,
    minWidth: '94px',
    maxWidth: '94px',
    borderRadius: '6px',
    backgroundColor: 'blueWhite.60',
    borderColor: 'blue.300',
}

const variantFlipArrows: SystemStyleObject = {
    width: '32px',
    minWidth: '32px',
    height: '32px',
    background: 'blueWhite.955',
    borderRadius: '16px',
    alignSelf: 'center',
    margin: '0 15px',
    _hover: {
        background: 'gray.450',
    },
}

const variantPaginationArrows: SystemStyleObject = {
    minWidth: '32px',
    maxWidth: '32px',
    minHeight: '32px',
    maxHeight: '32px',
    borderRadius: '6px',
    _hover: {
        background: 'blueWhite.960',
    },
}

const variantModal: SystemStyleObject = {
    width: '50px',
    height: '25px',
    fontSize: '12px',
    lineHeight: '16px',
    _hover: {
        background: 'gray.600',
    },
}

const variantEllipse: SystemStyleObject = {
    width: '23px',
    height: '23px',
}

const variantHoveredSmallWhite: SystemStyleObject = {
    minWidth: '18px',
    maxWidth: '18px',
    minHeight: '18px',
    maxHeight: '18px',
    borderRadius: '50%',
    color: 'color.100',
    _hover: {
        color: 'gray.900',
        backgroundColor: 'gray.300',
    },
}

const variantHoveredMediumWhite: SystemStyleObject = {
    ...variantHoveredSmallWhite,
    minWidth: '25px',
    maxWidth: '25px',
    minHeight: '25px',
    maxHeight: '25px',
}

const variantHoveredMediumGray: SystemStyleObject = {
    ...variantHoveredMediumWhite,
    _hover: {
        backgroundColor: 'blueWhite.915',
    },
}

const variantSquircleActiveIcon: SystemStyleObject = {
    minWidth: '35px',
    maxWidth: '35px',
    minHeight: '35px',
    maxHeight: '35px',
    color: 'blue.200',
    backgroundColor: 'blueWhite.910',
    _hover: {
        color: 'blue.200',
    },
}

const variantSquircleWhiteIcon: SystemStyleObject = {
    minWidth: '37px',
    maxWidth: '37px',
    minHeight: '37px',
    maxHeight: '37px',
    color: 'color.100',
    _hover: {
        color: 'blue.200',
        backgroundColor: 'blueWhite.910',
        borderRadius: '6px',
    },
}

const variantSquircleWhiteBlueIcon: SystemStyleObject = {
    minWidth: '37px',
    maxWidth: '37px',
    minHeight: '37px',
    maxHeight: '37px',
    color: 'color.100',
    _hover: {
        backgroundColor: 'blueWhite.910',
        borderRadius: '6px',
    },
}

const variantSquircleGrayIcon: SystemStyleObject = {
    ...variantSquircleWhiteIcon,
    color: 'color.360',
    _hover: {
        color: 'sidebarIconHover',
        backgroundColor: 'blueWhite.910',
        borderRadius: '6px',
    },
}

const variantSidebarOpenActiveIcon: SystemStyleObject = {
    minWidth: '226px',
    maxWidth: '226px',
    minHeight: '35px',
    maxHeight: '35px',
    justifyContent: 'start',
    color: 'blue.200',
    backgroundColor: 'blueWhite.910',
    margin: '0 21px 0 24px',
    _hover: {
        color: 'blue.300',
    },
}

const variantSidebarOpenGrayIcon: SystemStyleObject = {
    ...variantSquircleGrayIcon,
    minWidth: '226px',
    maxWidth: '226px',
    minHeight: '35px',
    maxHeight: '35px',
    justifyContent: 'start',
    margin: '0 21px 0 24px',
}

const variantSearchIcon: SystemStyleObject = {
    minWidth: '42px',
    maxWidth: '42px',
    minHeight: '32px',
    maxHeight: '32px',
    color: 'blue.300',
    _hover: {
        color: 'white',
        backgroundColor: 'blue.300',
    },
}

const variantEmpty: SystemStyleObject = {
    color: 'blue.200',
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '16px',
    padding: '0',
    _hover: {
        color: 'blue.400'
    },
}

const variantEmptySmall: SystemStyleObject = {
    ...variantEmpty,
    fontSize: '12px',
    lineHeight: '14px',
}

const variants = {
    solid: variantSolid,
    outline: variantOutline,
    outlineWhite: variantOutlineWhite,
    outlineSmall: variantOutlineSmall,
    blue: variantBlue,
    blueBottom: variantBlueBottom,
    blueSmall: variantBlueSmall,
    blueBig: variantBlueBig,
    blueFixed: variantBlueFixed,
    outlineWhiteBlack: variantOutlineWhiteBlack,
    outlineWhiteBlue: variantOutlineWhiteBlue,
    modal: variantModal,
    transparent: variantTransparent,
    ellipse: variantEllipse,
    searchIcon: variantSearchIcon,
    flipArrows: variantFlipArrows,
    hoveredSmallWhite: variantHoveredSmallWhite,
    hoveredMediumWhite: variantHoveredMediumWhite,
    hoveredMediumGray: variantHoveredMediumGray,
    squircleGrayIcon: variantSquircleGrayIcon,
    squircleWhiteIcon: variantSquircleWhiteIcon,
    squircleWhiteBlueIcon: variantSquircleWhiteBlueIcon,
    squircleActiveIcon: variantSquircleActiveIcon,
    sidebarOpenActiveIcon: variantSidebarOpenActiveIcon,
    sidebarOpenGrayIcon: variantSidebarOpenGrayIcon,
    paginationArrows: variantPaginationArrows,
    underline: variantUnderline,
    empty: variantEmpty,
    emptySmall: variantEmptySmall,
}

export const Button = {
    baseStyle,
    variants,
}
