import { radioAnatomy as parts } from "@chakra-ui/anatomy"
import { PartsStyleObject } from '@chakra-ui/theme-tools'

const baseStyle: PartsStyleObject<typeof parts> = {
    control: {
        borderColor: 'blue.800',
        borderStyle: 'solid',
        borderWidth: "1px",
        backgroundColor: 'blueWhite.950',
        _hover: {
            borderColor: "denim",
            boxShadow: '0 0 0 1px #3182CE'
        },
        _checked: {
            borderColor: 'blue.800',
            borderStyle: 'solid',
            borderWidth: "1px",
            backgroundColor: 'blueWhite.950',
            _hover: {
                backgroundColor: "transparent",
            },
            _before: {
                content: `""`,
                display: "inline-block",
                position: "relative",
                width: "70%",
                height: "70%",
                borderRadius: "50%",
                backgroundColor: 'blue.800'
            }
        },
        _focus: {
            boxShadow: '0 0 0 1px #3182CE'
        }
    },
    label: {
        color: 'white',
    }
}

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
    md: {
        label: {
            fontSize: '14px',
            lineHeight: '17px'
        }
    },
    sm: {
        control: {
            width: "16px",
            height: "16px"
        },
        label: {
            marginTop: '2px',
            fontSize: '12px',
            lineHeight: '14px',
        }
    }
}

export const Radio = {
    parts: parts.keys,
    baseStyle,
    sizes
}
