import React, {
    ChangeEvent,
    Dispatch,
    SetStateAction,
    useEffect,
    useState,
} from 'react'
import { observer } from 'mobx-react-lite'
import { mappingStore } from 'stores'
import { toJS } from 'mobx'
import { Input, Select } from '@chakra-ui/react'
import { Box } from '@chakra-ui/layout'
import { ParamsEditModal } from './ParamsEditModal'
import {
    AddModalButton,
    EditModalBox,
    EditModalBoxInvested,
    EditModalErrorBox,
    EditModalGlobalBox,
    EditModalText,
} from '../styles'

interface IAddItemComponent {
    onClose: () => void
    onCloseSave: () => void
    arrKeys: string[]
    jsonData: object
    value: any
    handleSave: (value: object) => void
    handleSaveCopy: (value: object) => void
    setJsonData: Dispatch<SetStateAction<object>>
}

export interface IEditData {
    name?: string
    type: string
    alias: string
    expression?: IExpression
}

interface IExpression {
    parameters: object | null
    type: string
    value: string
}

const type = ['object', 'array', 'value', 'group']
export const expType = ['value', 'function', 'path']

export const EditItemComponent = observer(({
                                               arrKeys,
                                               value,
                                               onClose,
                                               jsonData,
                                               setJsonData,
                                               handleSave,
                                               handleSaveCopy,
                                               onCloseSave,
                                           }: IAddItemComponent) => {
    const func = toJS(mappingStore.function)
    const [editData, setEditData] = useState<IEditData>({ type: 'object', alias: 'null' })
    const [isPathError, setPathError] = useState(false)
    const [isAliasError, setAliasError] = useState(false)

    useEffect(() => {
        mappingStore.getMappingsFunc()
    }, [])

    useEffect(() => {
        setEditData(value)
    }, [value])

    const handleChangeValue = (value?: IEditData) => {
        const copyObj: { [key: string]: unknown } = { ...jsonData }
        if (arrKeys.length) {
            [...arrKeys].reduce((acc: any, path, index) => {
                if (
                    index === arrKeys.length - 1 &&
                    acc instanceof Object &&
                    acc.constructor === Object
                ) {
                    acc[path] = value
                    return acc
                }
                if (acc instanceof Object && acc.constructor === Object) {
                    return acc[path]
                }
                return acc
            }, copyObj)
        }
        setJsonData(copyObj)
        handleSave(copyObj)

        value && setEditData(value)
    }

    const updParams = (updateData: string, isFirstItem?: boolean) => {
        const objParam = isFirstItem ? func[0] : func.find(el => el.name === updateData)
        const updateParams = objParam?.parameters?.map((el) => {
            return { name: el.name, expression: { parameters: null, type: 'value', value: '' } }
        })
        return updateParams
    }

    const handleChangeType = (updateData: string) => {
        if (updateData === 'array') {
            setEditData({ ...editData, type: updateData, alias: '' })
            setAliasError(true)
        } else {
            setEditData({ ...editData, type: updateData, alias: 'null' })
            setAliasError(false)
        }
    }

    const handleChangeAlias = (updateData: string) => {
        setEditData({ ...editData, alias: updateData })
        if (updateData === '') {
            setAliasError(true)
        } else {
            setAliasError(false)
        }
    }

    const handleChangeValueFuncExp = (updateData: string) => {
        const updateParams = updParams(updateData)
        if (editData?.expression && updateParams) {
            setEditData({
                ...editData,
                expression: { ...editData.expression, value: updateData, parameters: updateParams },
            })
        }
    }

    const handleChangeValueExp = (updateData: string) => {
        if (editData?.expression?.type === 'path') {
            const result = updateData.match(/^[$.]{2}[a-z0-9._-]{1,100}$/)
            setPathError(result === null)
            setEditData({
                ...editData,
                expression: { ...editData.expression, value: updateData },
            })
        } else {
            setPathError(false)
            if (editData.expression) {
                setEditData({
                    ...editData,
                    expression: { ...editData.expression, value: updateData },
                })
            }
        }
    }

    const handleChangeTypeExp = (updateData: string) => {
        if (updateData === 'function') {
            const updateParams = updParams(updateData, true)
            if (updateParams) {
                setPathError(false)
                setEditData({
                    ...editData,
                    expression: {
                        ...editData.expression,
                        parameters: updateParams,
                        type: updateData,
                        value: func[0].name,
                    },
                })
                handleSaveModal({
                    ...editData,
                    expression: {
                        ...editData.expression,
                        type: updateData,
                        parameters: updateParams,
                        value: func[0].name,
                    },
                })
            }
        } else {
            setPathError(false)
            setEditData({
                ...editData,
                expression: { ...editData.expression, parameters: null, type: updateData, value: '' },
            })
        }
    }

    const handleSaveModal = (param?: IEditData) => {
        const copyObj: { [key: string]: unknown } = { ...jsonData }
        if (arrKeys.length) {
            [...arrKeys].reduce((acc: any, path, index) => {
                if (
                    index === arrKeys.length - 1 &&
                    acc instanceof Object &&
                    acc.constructor === Object
                ) {
                    acc[path] = param ? param : editData
                    return acc
                }
                if (acc instanceof Object && acc.constructor === Object) {
                    return acc[path]
                }
                return acc
            }, copyObj)
        }
        setJsonData(copyObj)
        handleSave(copyObj)
        !param && handleSaveCopy(copyObj)
        !param && onCloseSave()
    }

    return (
        <Box>
            <EditModalGlobalBox>
                <Box paddingBottom='10px' textAlign='left'>
                    Object data:
                </Box>
                <EditModalBox>
                    <Box paddingRight='5px'>Type:</Box>
                    <Select
                        value={editData?.type?.toLowerCase()}
                        onChange={value => handleChangeType(value.target.value)}
                    >
                        {type.map((el: string) => (
                            <option key={el} value={el}>
                                {el.toLowerCase()}
                            </option>
                        ))}
                    </Select>
                </EditModalBox>
                <EditModalBox>
                    <Box paddingRight='5px'>Alias:</Box>
                    <EditModalErrorBox>
                        <Input
                            disabled={editData?.type?.toLowerCase() !== 'array'}
                            size='sm'
                            value={editData?.alias}
                            placeholder='key'
                            onChange={(value: ChangeEvent<HTMLInputElement>) => handleChangeAlias(value.target.value)}
                        />
                        {isAliasError && (
                            <Box color='red.500'>
                                This field must not empty.
                            </Box>
                        )}
                    </EditModalErrorBox>
                </EditModalBox>
                <EditModalText>
                    Expression:
                </EditModalText>
                {editData?.type?.toLowerCase() !== 'object' ? (
                    <Box paddingLeft='20px'>
                        <EditModalBoxInvested>
                            <Box paddingRight='5px'>Value:</Box>
                            <EditModalErrorBox>
                                {editData?.expression?.type?.toLowerCase() ===
                                'function' ? (
                                    <Select
                                        value={editData?.expression?.value}
                                        onChange={value => handleChangeValueFuncExp(value.target.value)}
                                    >
                                        {func.map((el: { name: string }) => (
                                                <option key={el.name} value={el.name}>
                                                    {el.name}
                                                </option>
                                            ),
                                        )}
                                    </Select>
                                ) : (
                                    <Input
                                        size='sm'
                                        value={editData?.expression?.value}
                                        placeholder='key'
                                        onChange={(
                                            value: ChangeEvent<HTMLInputElement>,
                                        ) => handleChangeValueExp(value.target.value)}
                                    />
                                )}

                                {isPathError && (
                                    <Box color='red.500'>
                                        This field must start with "$.".
                                    </Box>
                                )}
                            </EditModalErrorBox>
                        </EditModalBoxInvested>
                        <EditModalBoxInvested>
                            <Box paddingRight='5px'>Type:</Box>
                            <Select
                                value={editData?.expression?.type?.toLowerCase()}
                                onChange={value => handleChangeTypeExp(value.target.value)}
                            >
                                {expType.map((el: string) => (
                                    <option key={el} value={el}>
                                        {el.toLowerCase()}
                                    </option>
                                ))}
                            </Select>
                        </EditModalBoxInvested>
                        <EditModalBoxInvested>
                            <Box paddingRight='5px'>Parameters:</Box>
                            {editData?.expression?.type !== 'function' && <Box>null</Box>}
                        </EditModalBoxInvested>
                        {editData?.expression &&
                            Array.isArray(editData.expression.parameters) &&
                            editData.expression.parameters.map((el) =>
                                <ParamsEditModal
                                    baseData={editData}
                                    arrKeysParam={[el.name]}
                                    data={el}
                                    arrKeysExpressions={[editData?.expression?.value, el.name]}
                                    setData={() => handleChangeValue()}
                                />,
                            )}
                    </Box>
                ) : (
                    <EditModalBoxInvested>
                        null
                    </EditModalBoxInvested>
                )}
            </EditModalGlobalBox>

            <Box display='flex' flexDirection='row-reverse'>
                <AddModalButton
                    variant="blue"
                    size='sm'
                    onClick={() => {
                        handleSaveModal()
                    }}
                >
                    Save
                </AddModalButton>
                <AddModalButton
                    type='button'
                    variant="blue"
                    onClick={onClose}
                >
                    Cancel
                </AddModalButton>
            </Box>
        </Box>
    )
})
